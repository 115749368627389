import { JATOIcon, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledWelcomInfo } from './WelcomeInfo.styles'

export const WelcomeInfo: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const WelcomeInfoSubtitle = translations.JNT_WelcomeInfoSubtitle
  const WelcomeInfoQ1 = translations.JNT_WelcomeInfoQ1
  const WelcomeInfoQ2 = translations.JNT_WelcomeInfoQ2
  const WelcomeInfoQ3 = translations.JNT_WelcomeInfoQ3
  const WelcomeInfoQ4 = translations.JNT_WelcomeInfoQ4
  const WelcomeInfoQ5 = translations.JNT_WelcomeInfoQ5
  const WelcomeInfoQ6 = translations.JNT_WelcomeInfoQ6
  const WelcomeInfoQ7 = translations.JNT_WelcomeInfoQ7
  const WelcomeInfoQ8 = translations.JNT_WelcomeInfoQ8
  const WelcomeInfoA1 = translations.JNT_WelcomeInfoA1
  const WelcomeInfoA2 = translations.JNT_WelcomeInfoA2
  const WelcomeInfoA3 = translations.JNT_WelcomeInfoA3
  const WelcomeInfoA4 = translations.JNT_WelcomeInfoA4
  const WelcomeInfoA5 = translations.JNT_WelcomeInfoA5
  const WelcomeInfoA6 = translations.JNT_WelcomeInfoA6
  const WelcomeInfoA7 = translations.JNT_WelcomeInfoA7
  const WelcomeInfoA8 = translations.JNT_WelcomeInfoA8

  function scrollTop(ElementId: string): void {
    document.getElementById(ElementId)?.scrollIntoView()
  }
  return (
    <>
      <StyledWelcomInfo>
        <div
          className="modal-body"
          style={{ height: '400px', overflow: 'auto' }}
        >
          <div>{WelcomeInfoSubtitle}</div>
          <hr className="margin0top margin5bottom" />

          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('10')}
            >
              {WelcomeInfoQ1}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('11')}
            >
              {WelcomeInfoQ2}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('12')}
            >
              {WelcomeInfoQ3}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('13')}
            >
              {WelcomeInfoQ4}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('14')}
            >
              {WelcomeInfoQ5}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('15')}
            >
              {WelcomeInfoQ6}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('16')}
            >
              {WelcomeInfoQ7}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              style={{ color: '#4392b5', display: 'inline', margin: 2 }}
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a
              style={{
                color: '#4392b5',
                cursor: 'pointer',
                display: 'inline',
              }}
              //className="link-text"
              onClick={() => scrollTop('17')}
            >
              {WelcomeInfoQ8}
            </a>
          </div>

          <hr className="margin0top margin5bottom" />
          <div id="10">
            <h4>
              <strong>{WelcomeInfoQ1}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA1 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="11">
            <h4>
              <strong>{WelcomeInfoQ2}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA2 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="12">
            <h4>
              <strong>{WelcomeInfoQ3}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA3 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="13">
            <h4>
              <strong>{WelcomeInfoQ4}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA4 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="14">
            <h4>
              <strong>{WelcomeInfoQ5}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA5 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="15">
            <h4>
              <strong>{WelcomeInfoQ6}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA6 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="16">
            <h4>
              <strong>{WelcomeInfoQ7}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA7 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="17">
            <h4>
              <strong>{WelcomeInfoQ8}?</strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WelcomeInfoA8 }} />
          </div>
        </div>
        {/* </ModalComponent> */}
      </StyledWelcomInfo>
    </>
  )
}
