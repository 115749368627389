import styled from 'styled-components'
import Theme from 'theme'

export const StyledAccordion = styled.div`
  .accordion {
    background-color: ${Theme.colors.midGrey};
    font-weight: 500;
    width: 100%;
    cursor: pointer;
  }

  .accordionTitle {
    position: sticky;
    left: 20px;
    display: flex;
    align-items: center;
  }

  .accordionTitleIcon {
    display: inline-block;
    padding: 6px 0px;
  }

  .accordionTitleText {
    display: inline-block;
    padding-left: 8px;
  }
`
