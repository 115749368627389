import { IMappingObject } from 'helper/IMappingObject'
export class AppTextTranslations implements IMappingObject {
  [key: string]: string
  JNT_Nav_MyJatonet = 'My Page - N'
  JNT_Nav_News = 'News - N'
  JNT_Nav_Specs = 'Specifications - N'
  JNT_Nav_Volumes = 'Volumes - N'
  JNT_Nav_MonthlyPayments = 'Monthly Payments - NT'
  JNT_WelcomeInfo = 'JNT_WelcomeInfo - N'
  JNT_Help_HelpTag = 'Help - N'
  JNT_Help_Tips = 'Tips - N'
  JNT_Help_SalesInquiry = 'Sale Enquiry - N'
  JNT_Help_Howtovideo = 'JNT_Help_Howtovideo - N'
  JNT_WelcomeInfoQ1 = 'JNT_WelcomeInfoQ1 - N'
  JNT_WelcomeInfoQ2 = 'JNT_WelcomeInfoQ2 - N'
  JNT_WelcomeInfoQ3 = 'JNT_WelcomeInfoQ3 - N'
  JNT_WelcomeInfoQ4 = 'JNT_WelcomeInfoQ4 - N'
  JNT_WelcomeInfoQ5 = 'JNT_WelcomeInfoQ5 - N'
  JNT_WelcomeInfoQ6 = 'JNT_WelcomeInfoQ6 - N'
  JNT_WelcomeInfoQ7 = 'JNT_WelcomeInfoQ7 - N'
  JNT_WelcomeInfoQ8 = 'JNT_WelcomeInfoQ8 - N'
  JNT_WelcomeInfoA1 = 'JNT_WelcomeInfoA1 - N'
  JNT_WelcomeInfoA2 = 'JNT_WelcomeInfoA2 - N'
  JNT_WelcomeInfoA3 = 'JNT_WelcomeInfoA3 - N'
  JNT_WelcomeInfoA4 = 'JNT_WelcomeInfoA4 - N'
  JNT_WelcomeInfoA5 = 'JNT_WelcomeInfoA5 - N'
  JNT_WelcomeInfoA6 = 'JNT_WelcomeInfoA6 - N'
  JNT_WelcomeInfoA7 = 'JNT_WelcomeInfoA7 - N'
  JNT_WelcomeInfoA8 = 'JNT_WelcomeInfoA8 - N'
  JNT_WelcomeInfoSubtitle = 'JNT_WelcomeInfoSubtitle - N'
  JNT_Help_MPFaq = 'JNT_Help_MPFaq - N'
  JNT_DownLoad = ''
  JNT_userset = 'JNT_UserSettings_Title - N'
  JNT_currency = 'JNT_Currency - N'
  JNT_local = 'JNT_DisplayLocalVehicleNames - N'
  JNT_WPrice = 'JNT_HideVehiclesWithoutPrice - N'
  JNT_excludeold = 'JNT_ViewOutgoingModelYearVehicles - N'
  JNT_Submit = 'JNT_Submit - N'
  JNT_Close = 'JNT_Close - N'
  JNT_Select = 'Select - N'
  JNT_BasePrice = 'JNT_BasePrice - N'
  JNT_RetailPrice = 'JNT_RetailPrice - N'
  JNT_Country_Specific_Price = 'JNT_Country_Specific_Price'
  JNT_RetailDeliveryPrice = 'JNT_RetailDeliveryPrice'
  JNT_FAQTitle = 'JNT_FAQTitle - N'
  JNT_FAQ1 = 'JNT_FAQ1 - N'
  JNT_FAQ2 = 'JNT_FAQ2 - N'
  JNT_FAQ3 = 'JNT_FAQ3 - N'
  JNT_FAQ4 = 'JNT_FAQ4 - N'
  JNT_FAQ5 = 'JNT_FAQ5 - N'
  JNT_FAQ6 = 'JNT_FAQ6 - N'
  JNT_FAQAnswer1 = 'JNT_FAQAnswer1 - N'
  JNT_FAQAnswer2 = 'JNT_FAQAnswer2 - N'
  JNT_FAQAnswer3 = 'JNT_FAQAnswer3 - N'
  JNT_FAQAnswer4 = 'JNT_FAQAnswer4 - N'
  JNT_FAQAnswer5_1 = 'JNT_FAQAnswer5_1 - N'
  JNT_FAQAnswer5_2 = 'JNT_FAQAnswer5_2 - N'
  JNT_FAQAnswer6_1 = 'JNT_FAQAnswer6_1 - N'
  JNT_FAQAnswer6_2 = 'JNT_FAQAnswer6_2 - N'
  JNT_JATOSupport = 'JNT_JATOSupport - N'
  JNT_DefinitionOfSpecifications = 'JNT_DefinitionOfSpecifications - N'
  JNT_Help_SupportCentre = 'JATO Support Centre - N'
  JNT_Help_KnowledgeArticleSearch = 'Knowledge article search - N'
  JNT_Help_OpenSupportCase = 'Open a support case - N'
  JNT_make = 'Make - N'
  JNT_model = 'Model - N'
  JNT_ModelYear = 'Model Year - N'
  JNT_Versions = 'Versions - N'
  JNT_body = 'Body type - N'
  JNT_trans = 'Transmission - N'
  JNT_trimlevel = 'Trim level - N'
  JNT_fueltype = 'Fuel type - N'
  JNT_Enginelit = 'Engine litres - N'
  JNT_PKW = 'Power KW - N'
  JNT_CO2 = 'CO2 - N'
  JNT_Wltp_Combined_CO2 = 'WLTP Combined CO2 - N'
  JNT_Drivewheel = 'Drive wheel - N'
  JNT_price = 'Price - N'
  JNT_Help_WltpFaq = 'JNT_Help_WltpFaq - N'
  JNT_WLTP_STATUS_MESSAGE = 'JNT_WLTP_STATUS_MESSAGE - N'
  JNT_FAQ = 'JNT_FAQ - N'
  JNT_WLTP = 'WLTP'
  JNT_WLTP_FAQ_1 = 'JNT_WLTP_FAQ_1 - N'
  JNT_WLTP_FAQ_2 = 'JNT_WLTP_FAQ_2 - N'
  JNT_WLTP_FAQ_3 = 'JNT_WLTP_FAQ_3 - N'
  JNT_WLTP_FAQ_4 = 'JNT_WLTP_FAQ_4 - N'
  JNT_WLTP_FAQ_5 = 'JNT_WLTP_FAQ_5 - N'
  JNT_WLTP_FAQ_HEADING = 'JNT_WLTP_FAQ_HEADING - N'
  JNT_WLTP_FAQ_ANS_1 = 'JNT_WLTP_FAQ_ANS_1 - N'
  JNT_WLTP_FAQ_ANS_2 = 'JNT_WLTP_FAQ_ANS_2 - N'
  JNT_WLTP_FAQ_ANS_3_1 = 'JNT_WLTP_FAQ_ANS_3_1 - N'
  JNT_WLTP_FAQ_ANS_3_2 = 'JNT_WLTP_FAQ_ANS_3_2 - N'
  JNT_WLTP_FAQ_ANS_3_3 = 'JNT_WLTP_FAQ_ANS_3_3 - N'
  JNT_WLTP_FAQ_ANS_3_4 = 'JNT_WLTP_FAQ_ANS_3_4 - N'
  JNT_WLTP_FAQ_ANS_3_5 = 'JNT_WLTP_FAQ_ANS_3_5 - N'
  JNT_WLTP_FAQ_ANS_4_1 = 'JNT_WLTP_FAQ_ANS_4_1 - N'
  JNT_WLTP_FAQ_ANS_4_2 = 'JNT_WLTP_FAQ_ANS_4_2 - N'
  JNT_WLTP_FAQ_ANS_5_1 = 'JNT_WLTP_FAQ_ANS_5_1 - N'
  JNT_WLTP_FAQ_ANS_5_2 = 'JNT_WLTP_FAQ_ANS_5_2 - N'
  JNT_WLTP_FAQ_ANS_5_2_1 = 'JNT_WLTP_FAQ_ANS_5_2_1 - N'
  JNT_WLTP_BUTTON_TEXT = 'JNT_WLTP_BUTTON_TEXT - N'
  JNT_Market = 'Market - N'
  JNT_NumOfDoor = 'No.of doors - N'
  JNT_Segment = 'Segmentation - N'
  JNT_PtrainType = 'Powertrain type - N'
  JNT_WheelBase = 'Wheel Base - N'
  JNT_PHP = 'Power HP/PS - N'
  JNT_VSelVersion = 'View Selected Version - N'
  JNT_Result = 'Result'
  JNT_vehicles = 'vehicles'
  JNT_AdvanceSearch = 'Advance Filter'
  JNT_reset = 'Reset Filter'
  JNT_Filter = 'Apply Filter'
  JNT_StdAndOptions = 'JNT_StdAndOptions - N'
  JNT_OptionsOnly = 'JNT_OptionsOnly-N'
  JNT_Std = 'Std'
  JNT_Nofilteroptions = 'JNT_Nofilteroptions -N'
  JNT_changeFilter = 'JNT_changeFilter - N'
  JNT_NoAccess_SignIn_Text = 'JNT_NoAccess_SignIn_Text - N'
  JNT_NoAccess_Your_Company = 'JNT_NoAccess_Your_Company - N'
  JNT_NoAccess_Lets_Talk = 'JNT_NoAccess_Lets_Talk - N'
  JNT_NoAccess_Complete_Your_Request = 'JNT_NoAccess_Complete_Your_Request - N'
  JNT_NoAccess_Sales_Enquiry = 'JNT_NoAccess_Sales_Enquiry - N'
  JNT_Showroom = 'JNT_Showroom - N'
  JNT_optionstext = 'JNT_optionstext - N'
  JNT_Comparison = 'JNT_Comparison - N'
  JNT_packages = 'JNT_packages - N'
  JNT_colors = 'JNT_colors - N'
  JNT_baseoptions = 'JNT_BaseOptions - N'
  JNT_advantages = 'JNT_advantages - N'
  JNT_disadvantages = 'JNT_disadvantages - N'
  JNT_AddVeh = 'JNT_Addvehicles - N'
  JNT_NewSel = 'JNT_NewSelection - N'
  JNT_ReplaceVeh = 'JNT_ReplaceVehicle - N'
  JNT_RemoveVeh = 'JNT_RemoveVehicle - N'
  JNT_view = 'JNT_view - N'
  JNT_Photo = 'JNT_Photo - N'
  JNT_standard = 'JNT_standard - N'
  JNT_replacebtn = 'JNT_replacebtn - N'
  JNT_remove = 'JNT_remove - N'
  JNT_option = 'JNT_option - N'
  JNT_notavailable = 'JNT_notavailable - N'
  JNT_ExcOption = 'JNT_ExcOption - N'
  JNT_added = 'JNT_added - N'
  JNT_Brochure = 'JNT_Brochure - N'
  JNT_WLTP_Emssions_Combustion = 'JNT_WLTP_Emssions_Combustion - N'
  JNT_WLTP_Consumption_Combustion = 'JNT_WLTP_Consumption_Combustion - N'
  JNT_WLTP_Emssions_Charge_Depleting = 'JNT_WLTP_Emssions_Charge_Depleting - N'
  JNT_WLTP_Consumption_Charge_Depleting =
    'JNT_WLTP_Consumption_Charge_Depleting - N'
  JNT_WLTP_Emssions_Charge_Sustaining =
    'JNT_WLTP_Emssions_Charge_Sustaining - N'
  JNT_WLTP_Consumption_Charge_Sustaining =
    'JNT_WLTP_Consumption_Charge_Sustaining - N'
  JNT_WLTP_Emssions_UF_Weighted = 'JNT_WLTP_Emssions_UF_Weighted - N'
  JNT_WLTP_Consumption_UF_Weighted = 'JNT_WLTP_Consumption_UF_Weighted - N'
  JNT_WLTP_HEV_Consumption_Charge_Depleting =
    'JNT_WLTP_HEV_Consumption_Charge_Depleting - N'
  JNT_WLTP_PEV_HEV_Consumption = 'JNT_WLTP_PEV_HEV_Consumption - N'
  JNT_WLTP_HEV_UF_Weighted = 'JNT_WLTP_HEV_UF_Weighted - N'
  JNT_WLTP_HEV_All = 'JNT_WLTP_HEV_All - N'
  JNT_WLTP_HEV_Equivalent = 'JNT_WLTP_HEV_Equivalent - N'
  JNT_WLTP_PEV_Range = 'JNT_WLTP_PEV_Range - N'
  JNT_WLTP_Combined_high = 'JNT_WLTP_Combined_high - N'
  JNT_WLTP_Combined = 'JNT_WLTP_Combined - N'
  JNT_WLTP_LOW_MEDIUM = 'JNT_WLTP_LOW_MEDIUM - N'
  JNT_WLTP_Combined_High_Consumption = 'JNT_WLTP_Combined_High_Consumption - N'
  JNT_WLTP_Combined_Consumption = 'JNT_WLTP_Combined_Consumption - N'
  JNT_WLTP_LOW_MEDIUM_Combined = 'JNT_WLTP_LOW_MEDIUM_Combined - N'
  JNT_WLTP_Combined_High_Consumption_KWH_KM =
    'JNT_WLTP_Combined_High_Consumption_KWH_KM - N'
  JNT_WLTP_Combined_Consumption_KWH_KM =
    'JNT_WLTP_Combined_Consumption_KWH_KM - N'
  JNT_WLTP_CITY_LOW_MEDIUM_Combined = 'JNT_WLTP_CITY_LOW_MEDIUM_Combined - N'
  JNT_WLTP_Combined_High_Consumption_KM =
    'JNT_WLTP_Combined_High_Consumption_KM - N'
  JNT_WLTP_Combined_Consumption_KM = 'JNT_WLTP_Combined_Consumption_KM - N'
  JNT_WLTP_CITY_LOW_MEDIUM_Combined_KM =
    'JNT_WLTP_CITY_LOW_MEDIUM_Combined_KM - N'
  JNT_OEMErrorMessage = 'JNT_OEMErrorMessage - N'
  JNT_Requires = 'Requires:'
  JNT_Includes = 'Includes:'
  JNT_Excludes = 'Excludes:'
  JNT_SingleOr = 'SingleOr:'
  JNT_LowercaseAnd = 'LowercaseAnd:'
  JNT_Build = 'Build'
  JNT_NoBuiltOptions = 'No built options'
  JNT_BenchmarkAdv = 'JNT_BenchmarkAdvantages - N'
  JNT_Benchmark = 'JNT_Benchmark - N'
  JNT_AsBenchmark = 'JNT_AsBenchmark - N'
  JNT_BenchmarkDisadv = 'JNT_BenchmarkDisadvantages - N'
  JNT_UserBasket = 'JNT_UserBasket - N'
  JNT_MyBasket = 'JNT_MyBasket - N'
  JNT_basshare = 'JNT_basshare - N'
  JNT_Open = 'JNT_Open - N'
  JNT_save = 'JNT_save - N'
  JNT_SaveBasket = 'JNT_SaveBasket - N'
  JNT_BasketNameExist = 'JNT_BasketNameExist - N'
  JNT_name = 'JNT_name - N'
  JNT_qrycreate = 'JNT_qrycreate - N'
  JNT_lastran = 'JNT_lastran - N'
  JNT_rename = 'JNT_rename - N'
  JNT_share = 'JNT_share - N'
  JNT_delete = 'JNT_delete - N'
  JNT_EnterBasketName = 'JNT_EnterBasketName - N'
  JNT_DeletedSuccess = 'JNT_DeletedSuccess - N'
  JNT_UpdatedSuccess = 'JNT_UpdatedSuccess - N'
  JNT_SavedSuccess = 'JNT_SavedSuccess - N'
  JNT_NoColor = 'JNT_NoColor - N'
  JNT_NoPackage = 'JNT_NoPackage - N'
  JNT_GridSortMoreClick = 'Use CTRL+click to sort by more than one column - N'
  JNT_PagingPreviousText = 'Previous - N'
  JNT_PagingNextText = 'Next - N'
  JNT_ExcelExport = 'Export to Excel - N '
  JNT_DoNotShowMeThisTipAgain = 'JNT_DoNotShowTipAgain - N'
  JNT_VehicleFiltersHelpText = 'JNT_VehicleFiltersHelpText - N'
  JNT_VehicleFiltersHelpTitle = 'JNT_VehicleFilters - N'
  JNT_ShowroomHelpTitle = 'JNT_ShowroomHelpTitle - N'
  JNT_ShowroomHelpText = 'JNT_ShowroomHelpText - N'
  JNT_WelcomeHelpTitle = 'JNT_WelcomeHelpTitle - N'
  JNT_WelcomeHelpText = 'JNT_WelcomeHelpText - N'
  JNT_News_NoAccess_Your_Company =
    'You or your company doesn’t have any News subscription. - N'
  JNT_NEWS_NewVehicles = 'New Vehicles - N'
  JNT_HD_SecPricingspec = 'Pricing & Specifications - N'
  JNT_ART_TopStory = 'Industry News - N'
  JNT_HD_SecPromotions = 'Promotions & Incentives - N'
  JNT_ART_Author = 'Author: - N'
  JNT_ART_Country = 'Country: - N'
  JNT_NWS_Date = 'Date - N'
  JNT_TLE_ViewAll = 'View all - N'
  JNT_News_ReadMore = 'Read more - N'
  JNT_ART_AllCountry = 'All Countries - N'
  JNT_WelcomeHelpText1 = 'JNT_WelcomeHelpText1 - N'
  JNT_WelcomeHelpText2 = 'JNT_WelcomeHelpText2 - N'
  JNT_WelcomeHelpText3 = 'JNT_WelcomeHelpText3 - N'
  JNT_WelcomeHelpText4 = 'JNT_WelcomeHelpText4 - N'
  JNT_WelcomeHelpText5 = 'JNT_WelcomeHelpText5 - N'
  JNT_WelcomeHelpText6 = 'JNT_WelcomeHelpText6 - N'
  JNT_WelcomeHelpText7 = 'JNT_WelcomeHelpText7 - N'
  JNT_WelcomeHelpText8 = 'JNT_WelcomeHelpText8 - N'
  JNT_WelcomeHelpText9 = 'JNT_WelcomeHelpText9 - N'
  JNT_WelcomeHelpText10 = 'JNT_WelcomeHelpText10 - N'
  JNT_SpecsPageTitle = 'JATO Specifications - OUR KNOWLEDGE IS YOUR POWER'
  JNT_MPPageTitle = 'JATO Monthly Payments - OUR KNOWLEDGE IS YOUR POWER'
  JNT_MonthlyPaymentsFiltersTipTitle = 'JNT_MonthlyPaymentsFiltersTipTitle - N'
  JNT_MonthlyPaymentsFiltersTipText = 'JNT_MonthlyPaymentsFiltersTipText - N'
  JNT_ART_Related = 'JNT_ART_Related - N'
  JNT_Export_Pdf = 'Export to PDF'
  JNT_Export_Word = 'JNT_Export_Word - N'
  JNT_News_PostedBy = 'JNT_News_PostedBy - N'
  JNT_Login_NewCopyright = 'JNT_Login_NewCopyright -N'
  JNT_Manufacturer = 'Manufacturer - N'
  JNT_Headline = 'Headline - N'
  JNT_Author = 'Author - N'
  JNT_Action = 'Action - N'
  JNT_Country = 'Country - N'
  JNT_ART_Print = 'JNT_ART_Print - N'
  JNT_Unshared = 'JNT_Unshared - N'
  JNT_Link_Cornerstone = 'JNT_Link_Cornerstone -N'
  JNT_Link_WorkflowArt = 'JNT_Link_WorkflowArt -N'
  JNT_Favourite = 'JNT_Favourite - N'
  JNT_UnFavourite = 'JNT_UnFavourite - N'
  JNT_SharedArticles = 'JNT_SharedArticles -N'
  JNT_Favourites = 'JNT_Favourites -N'
  JNT_SharedBy = 'JNT_SharedBy -N'
  JNT_ArticleSharedWithMe = 'JNT_ArticleSharedWithMe -N'
  JNT_ArticleIHaveShared = 'JNT_ArticleIHaveShared - N'
  JNT_SUBJ_VSRE = 'Volumes / Sales / Registrations - N'
  JNT_SRCH_NoArticlesFound = 'No articles found - N'
  JNT_Refine_Your_Filters = 'Please refine your filters and try again - N'
  JNT_News_Category = 'News category - N'
  JNT_Last_3_Months = 'Last 3 Months - N'
  JNT_Last_6_Months = 'Last 6 Months - N'
  JNT_Last_12_Months = 'Last 12 Months - N'
  JNT_Select_Range = 'Select a range - N'
  JNT_Filters = 'Filters - N'
  JNT_Reset_All = 'Reset all - N'
  JNT_PubDate = 'Published Date - N'
  JNT_Create_New_Alert = 'Create new alert - N'
  JNT_Create_Alert = 'Create alert - N'
  JNT_Alert_Required_Fields = 'Fields marked with * are required - N'
  JNT_Language = 'Language - N'
  JNT_AlertFrequency = 'Alert frequency - N'
  JNT_AlertStartDate = 'Alert start date - N'
  JNT_AlertName = 'Alert Name - N'
  JNT_EnterAlertName = 'Enter Alert Name - N'
  JNT_Cancel = 'Cancel - N'
  JNT_SRCH_SearchResultsFor = 'Search results for: - N'
  JNT_Article = 'Article - N'
  JNT_Refine_Your_Search = 'Please refine your search and try again - N'
  JNT_SRCH_Search = 'Search - N'
  JNT_View_selection = 'View selection - N'
  JNT_selected = 'selected - N'
  JNT_Save_search = 'Save search - N'
  JNT_Save_search_and_create_alert = 'Save search and create alert - N'
  JNT_SRCH_SavebyMe = 'JNT_SRCH_SavebyMe - N'
  JNT_SRCH_SavebyGroup = 'JNT_SRCH_SavebyGroup - N'
  JNT_MJN_QrySDesc = 'JNT_MJN_QrySDesc -N'
  JNT_SRCH_EmailSubscription = 'JNT_SRCH_EmailSubscription - N'
  JNT_SavedSearches = 'JNT_SavedSearches - N'
  JNT_NewsAlerts = 'JNT_NewsAlerts - N'
  JNT_Status = 'JNT_Status -N'
  JNT_COM_DAILY = 'JNT_COM_DAILY -N'
  JNT_COM_WEEKLY = 'JNT_COM_WEEKLY - N'
  JNT_COM_UPON = 'JNT_COM_UPON -N'
  JNT_NewAlert = 'New Alert - N'
  JNT_NoSavedSearch = 'No searches have been set up. Start adding searches. - N'
  JNT_NoAlerts = 'No alerts have been set up. Start adding alerts. - N'
  JNT_AllMakes = 'All makes - N'
  JNT_AllModels = 'All models - N'
  JNT_Exact_match = 'Exact match - N'
  JNT_AllCategories = 'All categories - N'
  JNT_RemoveAlertMessage =
    'Are you sure you wish to delete this alert? You won’t be able to undo this action. - N'
  JNT_RemoveSearchMessage =
    'Are you sure you wish to delete this search? You won’t be able to undo this action. - N'
  JNT_COM_CANCEL = 'Cancel -N'
  JNT_Editors_Choice = "Editor's Choice - N"
  JNT_NewsPageTitle = 'JATO News - OUR KNOWLEDGE IS YOUR POWER -N'
  JNT_EditAlert = 'Edit Alert - N'
  JNT_EditSearch = 'Edit Search - N'
  JNT_SaveAlert = 'Save Alert - N'
  JNT_Name_ = 'Name - N'
  JNT_Name_your_search = 'Name your search - N'
  JNT_Share_in_your_organisation = 'Share in your organisation - N'
  JNT_Create_alert_from_search = 'Create alert from search - N'
  JNT_Success = 'Success - N'
  JNT_Error = 'Error - N'
  JNT_Warning = 'Warning - N'
  JNT_Search_saved = 'Search saved - N'
  JNT_Alert_saved = 'Alert saved - N'
  JNT_Name_already_exists = 'Name already exists - N'
  JNT_Advanced_search = 'Advanced search - N'
  JNT_Search_results = 'Search results - N'
  JNT_Search_text = 'Search text - N'
  JNT_Date_range = 'Date range - N'
  JNT_Last_1_Year = 'Last 1 Year - N'
  JNT_Clear_all = 'Clear all - N'
  JNT_Search_Name = 'Search Name - N'
  JNT_Search_Start_Date = 'Search Start Date - N'
  JNT_Convert_Search_To_Alert = 'Convert Search to Alert - N'
  JNT_Showing = 'Showing - N'
  JNT_of = 'of - N'
  JNT_International = 'International - N'
  JNT_International_Volumes = 'International Volumes - N'
  JNT_Type_here_to_search = 'Type here to search... - N'
  JNT_Share_Status_Error = 'Error updating share status - N'
  JNT_Favourites_Error = 'Error updating favourites - N'
  JNT_SRCH_AlertFAQ = 'News FAQ - N'
  JNT_Most_relevant = 'Most relevant - N'
  JNT_NewsWelcomeTitle = 'JNT_NewsWelcomeTitle - N'
  JNT_NewsWelcomeText1 = 'JNT_NewsWelcomeText1 - N'
  JNT_NewsWelcomeText2 = 'JNT_NewsWelcomeText2 - N'
  JNT_NewsWelcomeText2_1 = 'JNT_NewsWelcomeText2_1 - N'
  JNT_NewsWelcomeText2_2 = 'JNT_NewsWelcomeText2_2 - N'
  JNT_NewsWelcomeText2_3 = 'JNT_NewsWelcomeText2_3 - N'
  JNT_NewsWelcomeText3 = 'JNT_NewsWelcomeText3 - N'
  JNT_NewsWelcomeText4 = 'JNT_NewsWelcomeText4 - N'
  JNT_NewsWelcomeText5 = 'JNT_NewsWelcomeText5 - N'
  JNT_NewsWelcomeText6 = 'JNT_NewsWelcomeText6 - N'
  JNT_NewsWelcomeText7 = 'JNT_NewsWelcomeText7 - N'
  JNT_NewsWelcomeText8 = 'JNT_NewsWelcomeText8 - N'
  JNT_Do_not_show_me_this_again = 'Do not show me this again - N'
  JNT_New = 'NEW - N'
  JNT_CustomRange = 'Custom Range - N'
  JNT_NoRecordsToDisplay = 'No records to display - N'
  JNT_FilterHomepage = 'Filter Homepage - N'
  JNT_NewsAlertActivated = 'Alert activated - N'
  JNT_NewsAlertDeactivated = 'Alert deactivated - N'
  JNT_News_FAQ_Q1 = 'JNT_News_FAQ_Q1 - N'
  JNT_News_FAQ_A1 = 'JNT_News_FAQ_A1 - N'
  JNT_News_FAQ_Q2 = 'JNT_News_FAQ_Q2 - N'
  JNT_News_FAQ_A2 = 'JNT_News_FAQ_A2 - N'
  JNT_News_FAQ_Q3 = 'JNT_News_FAQ_Q3 - N'
  JNT_News_FAQ_A3 = 'JNT_News_FAQ_A3 - N'
  JNT_News_FAQ_Q4 = 'JNT_News_FAQ_Q4 - N'
  JNT_News_FAQ_A4 = 'JNT_News_FAQ_A4 - N'
  JNT_News_FAQ_Q5 = 'JNT_News_FAQ_Q5 - N'
  JNT_News_FAQ_A5 = 'JNT_News_FAQ_A5 - N'
  JNT_News_FAQ_Q6 = 'JNT_News_FAQ_Q6 - N'
  JNT_News_FAQ_A6 = 'JNT_News_FAQ_A6 - N'
  JNT_News_FAQ_Q7 = 'JNT_News_FAQ_Q7 - N'
  JNT_News_FAQ_A7 = 'JNT_News_FAQ_A7 - N'
  JNT_News_FAQ_Q8 = 'JNT_News_FAQ_Q8 - N'
  JNT_News_FAQ_A8 = 'JNT_News_FAQ_A8 - N'
  JNT_News_FAQ_Q9 = 'JNT_News_FAQ_Q9 - N'
  JNT_News_FAQ_A9 = 'JNT_News_FAQ_A9 - N'
  JNT_News_FAQ_Q10 = 'JNT_News_FAQ_Q10 - N'
  JNT_News_FAQ_A10 = 'JNT_News_FAQ_A10 - N'
  JNT_News_FAQ_Q11 = 'JNT_News_FAQ_Q11 - N'
  JNT_News_FAQ_A11 = 'JNT_News_FAQ_A11 - N'
  JNT_News_FAQ_Q12 = 'JNT_News_FAQ_Q12 - N'
  JNT_News_FAQ_A12 = 'JNT_News_FAQ_A12 - N'
  JNT_VehicleType = 'Vehicle Type - N'
  JNT_AllTypes = 'All types - N'
  JNT_Latest_News = 'Latest News - N'
  JNT_NEWS_VHT_CAR = 'Car - N'
  JNT_NEWS_VHT_COM = 'Commercial - N'

  //new Volumes tags
  JNT_Volumes_NoAccess_Your_Company =
    'You or your company doesn’t have any Volumes subscription. - N'
  JNT_Volumes_Start = 'Start - N'
  JNT_Volumes_Invalid_query_id = 'Invalid query id - N'
  JNT_Volumes_Outdated_Query_Error =
    'The Query was created many years ago and is now out-of-date. Please re-create a new query. - N'
  JNT_Volumes_My_country_grouping = 'My country grouping - N'
  JNT_Volumes_MQ_SearchName = 'Search name - N'
  JNT_Volumes_MQ_DateCreated = 'Date created - N'
  JNT_Volumes_MQ_DateModified = 'Date modified - N'
  JNT_Volumes_MQ_DateLastRun = 'Date last run - N'
  JNT_Volumes_MQ_Share = 'Share - N'
  JNT_Volumes_MQ_Delete = 'Delete - N'
  JNT_Volumes_MQ_Rename = 'Rename - N'
  JNT_Volumes_MQ_Query_Name_Exists = 'Query name exists - N'
  JNT_Volumes_MQ_Query_Updated = 'Query updated - N'
  JNT_Volumes_MQ_Query_Delete_Confirmation =
    'Are you sure you want to delete this query? - N'
  JNT_Volumes_MQ_Query_Your_Queries = 'Your Queries - N'
  JNT_Volumes_MQ_Query_Queries_Shared_With_You = 'Queries shared with you - N'
  JNT_Volumes_Country_group_name = 'Country group name - N'
  JNT_Volumes_Country_group_name_exists =
    'Country group name already exists - N'
  JNT_Volumes_Country_group_delete_confirmation =
    'Are you sure you want to delete country group? - N'
  JNT_Volumes_Edit = 'Edit - N'
  JNT_Volumes_Return_to_default = 'Return to default - N'
  JNT_Volumes_QD_QueryDescription = 'Query description - N'
  JNT_Volumes_QD_DatabaseType = 'Database type - N'
  JNT_Volumes_QD_Countries = 'Countries - N'
  JNT_Volumes_QD_VehicleAttributes = 'Vehicle Attributes - N'
  JNT_Volumes_QD_Periods = 'Periods - N'
  JNT_Volumes_QD_AllCountries = 'All countries - N'
  JNT_Volumes_MaxValuesToShowOnClientSide =
    'Please refine your filters to see specific values. Maximum values to show on client side is: - N'
  JNT_Volumes_ElectricOverview = 'Electric overview - N'
  JNT_Volumes_MakeModelOverview = 'Make & Model overview - N'
  JNT_Volumes_SegmentFuelReport = 'Segment & Fuel report - N'
  JNT_Volumes_VersionsRevenueReport = 'Versions & Revenue report - N'
  JNT_Volumes_LatestElectricCarRegistrations =
    'Latest electric car registrations - N'
  JNT_Volumes_NewsArticles = 'Country Reports - N'

  //old Volumes tags
  JNT_Volumes_QG_X = 'Model Mix Volumes with specifications - N'
  JNT_Volumes_QG4_X_DESC =
    'Version level Volumes coupled with Specifications data to show item availability, pricing information and the number of sold and/or registered units associated with each version - N'
  JNT_Volumes_QG_M = 'Model Mix Volumes - N'
  JNT_Volumes_QG4_M_DESC =
    'Enhanced Volumes data to version level linked to pricing information, showing one price per version. The Cars Subject Group is enhanced with SPGM Forecast data to constantly allow cross-country queries on the current and next month, ahead of official data being released. (shown in green) - N'
  JNT_Volumes_QG_V = 'Source data Volumes - N'
  JNT_Volumes_QG4_V_DESC =
    "Sales and/or registration data as reported by the official Volumes authorities. This data is harmonized across markets to be reported uniformly according to JATO's standardized item definitions and naming conventions - N"
  JNT_Volumes_QG4_WelcomeMsg = 'Start/reset - N'
  JNT_Volumes_QG_TabQuerySelections = 'Query definition - N'
  JNT_Volumes_QG_TabPeriodSelections = 'Period selections - N'
  JNT_Volumes_QG4_Final = 'Report - N'
  JNT_Volumes_Nav_QueryManager = 'Query manager - N'
  JNT_Volumes_Nav_SegmentManager = 'Segment manager - N'
  JNT_Volumes_MJ_QueryName = 'My Volumes queries - N'
  JNT_Volumes_QG_Dataset = 'Vehicle/Volumes type - N'
  JNT_Volumes_QM_CountrySelectMessage = 'Select Country - N'
  JNT_Volumes_QIO_ChooseMethod = 'Choose one or more countries - N'
  JNT_Volumes_QG_UsingCTYGRP = 'Country groups - N'
  JNT_Volumes_QG_SetQuerySelections = 'Select All - N'
  JNT_Volumes_QG_ClearSelections = 'Clear choices - N'
  JNT_Volumes_QG_Next = 'Next - N'
  JNT_Volumes_MJN_tabMyQuery = 'My queries - N'
  JNT_Volumes_QRYIO_GroupQry = 'Group queries - N'
  JNT_Volumes_QIO_AddCTYGRP = 'Add country group - N'
  JNT_Volumes_QIO_EditCTYGRP = 'Edit country group - N'
  JNT_Volumes_QG_DQ_Dataset = 'Vehicle type - N'
  JNT_Volumes_PS_PeriodSelections = 'Period selections - N'
  JNT_Volumes_PS_SelectAPeriod = 'Select one or more time periods - N'
  JNT_Volumes_PS_SelectAPeriod2 = 'Where a period is displayed: - N'
  JNT_Volumes_PS_SelectAPeriod3 = 'Black = actual data - N'
  JNT_Volumes_PS_SelectAPeriod4 = 'Green = Nowcast data - N'
  JNT_Volumes_PS_SelectAPeriod5 =
    'Red = selection contains data with and without Nowcast - N'
  JNT_Volumes_PS_SelectAPeriod6 =
    'Use Shift+click to select multiple periods quickly - N'
  JNT_Volumes_PS_MaxPeriodsSectionsAllowedError =
    'Please refine your filters. Maximum periods allowed is: - N'
  JNT_Volumes_QIO_QueryDefinitionInfo =
    'Choose your items, attributes and set conditions - N'
  JNT_Volumes_QG_NB_Invert = 'Invert - N'
  JNT_Volumes_QG_NB_And = 'and - N'
  JNT_Volumes_QG_NB_Equals = 'Equals - N'
  JNT_Volumes_QG_NB_GreaterThan = 'Greater than - N'
  JNT_Volumes_QG_NB_LessThan = 'Less than - N'
  JNT_Volumes_QG_NB_Between = 'Between - N'
  JNT_Volumes_QG_NB_Equal_NOT = 'Does not equal - N'
  JNT_Volumes_QG_NB_Between_NOT = 'Not between - N'
  JNT_Volumes_Video_Tutorial = 'Video tutorial - N'
  JNT_Volumes_FP_SaveQuery = 'Save query - N'
  JNT_Volumes_FP_AddQuery = 'Add query - N'
  JNT_Volumes_FP_TypeHere = 'Type here - N'
  JNT_Volumes_FP_QueryNameExists = 'Query name already exists - N'
  JNT_Volumes_FP_ManageQueryReport = 'Save, export and analyse your report - N'
  JNT_Volumes_FP_AnalysisOptions = 'Analysis options - N'
  JNT_Volumes_FP_ReportShare = 'Report Share - N'
  JNT_Volumes_FP_JatoGlobalSegmentShare = 'JATO Global Segment Share - N'
  JNT_Volumes_FP_VehicleColumnsTypeShare = 'Vehicle Columns Type Share - N'
  JNT_Volumes_FP_NumericPercentageChange = 'Numeric % Change - N'
  JNT_Volumes_FP_WarningA = 'Maximum rows to show on client side is: - N'
  JNT_Volumes_FP_WarningB =
    'Please click the excel button to export the whole report. -N'
  JNT_Most_recent = 'Most recent - N'
  JNT_Volumes_BAS_Exportreport =
    'Your excel file is ready for download. Please - N'
  JNT_Volumes_BAS_Clickheretext = 'Click here - N'
  JNT_Volumes_CountryHoverText = 'Select one or more countries - N'
  JNT_Volumes_CountryGrpHoverText = 'Select one pre-determined grouping -N'
  JNT_Specs_NoDataDefinitionAvailable = 'No data definition available - N'
  JNT_Specs_Loading = 'Loading - N'
  JNT_Specs_ReadMoreHere = 'read more here - N'
  JNT_Specs_ClickForDefinition = 'Click for definition - N'
  JNT_Help_VolumesFaq = 'JATO Volumes FAQ - N'
  JNT_Volumes_FAQ_Q1 = 'JNT_Volumes_FAQ_Q1 - N'
  JNT_Volumes_FAQ_A1 = 'JNT_Volumes_FAQ_A1 - N'
  JNT_Volumes_FAQ_A1_2 = 'JNT_Volumes_FAQ_A1_2 - N'
  JNT_Volumes_FAQ_A1_3 = 'JNT_Volumes_FAQ_A1_3 - N'
  JNT_Volumes_FAQ_A1_4 = 'JNT_Volumes_FAQ_A1_4 - N'
  JNT_Volumes_FAQ_Q2 = 'JNT_Volumes_FAQ_Q2 - N'
  JNT_Volumes_FAQ_A2 = 'JNT_Volumes_FAQ_A2 - N'
  JNT_Volumes_FAQ_A2_2 = 'JNT_Volumes_FAQ_A2_2 - N'
  JNT_Volumes_FAQ_A2_3 = 'JNT_Volumes_FAQ_A2_3 - N'
  JNT_Volumes_FAQ_A2_4 = 'JNT_Volumes_FAQ_A2_4 - N'
  JNT_Volumes_FAQ_Q3 = 'JNT_Volumes_FAQ_Q3 - N'
  JNT_Volumes_FAQ_A3 = 'JNT_Volumes_FAQ_A3 - N'
  JNT_Volumes_FAQ_Q4 = 'JNT_Volumes_FAQ_Q4 - N'
  JNT_Volumes_FAQ_A4 = 'JNT_Volumes_FAQ_A4 - N'
  JNT_Volumes_FAQ_Q5 = 'JNT_Volumes_FAQ_Q5 - N'
  JNT_Volumes_FAQ_A5 = 'JNT_Volumes_FAQ_A5 - N'
  JNT_Volumes_FAQ_A5_2 = 'JNT_Volumes_FAQ_A5_2 - N'
  JNT_Volumes_FAQ_A5_3 = 'JNT_Volumes_FAQ_A5_3 - N'
  JNT_Volumes_FAQ_A5_4 = 'JNT_Volumes_FAQ_A5_4 - N'
  JNT_Volumes_FAQ_A5_5 = 'JNT_Volumes_FAQ_A5_5 - N'
  JNT_Volumes_FAQ_A5_6 = 'JNT_Volumes_FAQ_A5_6 - N'
  JNT_Volumes_FAQ_A5_7 = 'JNT_Volumes_FAQ_A5_7 - N'
  JNT_Volumes_FAQ_Q6 = 'JNT_Volumes_FAQ_Q6 - N'
  JNT_Volumes_FAQ_A6 = 'JNT_Volumes_FAQ_A6 - N'
  JNT_Volumes_FAQ_Q7 = 'JNT_Volumes_FAQ_Q7 - N'
  JNT_Volumes_FAQ_A7 = 'JNT_Volumes_FAQ_A7 - N'
  JNT_Volumes_FAQ_A7_2 = 'JNT_Volumes_FAQ_A7_2 - N'
  JNT_Volumes_FAQ_Q8 = 'JNT_Volumes_FAQ_Q8 - N'
  JNT_Volumes_FAQ_A8 = 'JNT_Volumes_FAQ_A8 - N'
  JNT_Volumes_FAQ_A8_2 = 'JNT_Volumes_FAQ_A8_2 - N'
  JNT_Volumes_FAQ_Q9 = 'JNT_Volumes_FAQ_Q9 - N'
  JNT_Volumes_FAQ_A9 = 'JNT_Volumes_FAQ_A9 - N'
  JNT_Volumes_FAQ_A9_2 = 'JNT_Volumes_FAQ_A9_2 - N'
  JNT_Volumes_FAQ_A9_3 = 'JNT_Volumes_FAQ_A9_3 - N'
  JNT_Volumes_FAQ_Q10 = 'JNT_Volumes_FAQ_Q10 - N'
  JNT_Volumes_FAQ_A10 = 'JNT_Volumes_FAQ_A10 - N'
  JNT_Volumes_FAQ_Q11 = 'JNT_Volumes_FAQ_Q11 - N'
  JNT_Volumes_FAQ_A11 = 'JNT_Volumes_FAQ_A11 - N'
  JNT_Volumes_FAQ_A11_2 = 'JNT_Volumes_FAQ_A11_2 - N'
  JNT_Volumes_FAQ_A11_3 = 'JNT_Volumes_FAQ_A11_3 - N'
  JNT_Volumes_FAQ_A11_4 = 'JNT_Volumes_FAQ_A11_4 - N'
  JNT_Volumes_FAQ_A11_5 = 'JNT_Volumes_FAQ_A11_5 - N'
  JNT_Volumes_FAQ_A11_6 = 'JNT_Volumes_FAQ_A11_6 - N'
  JNT_Volumes_WelcomeHelpText1 = 'JNT_Volumes_WelcomeHelpText1 - N'
  JNT_Volumes_WelcomeHelpText2 = 'JNT_Volumes_WelcomeHelpText2 - N'
  JNT_Volumes_WelcomeHelpText3 = 'JNT_Volumes_WelcomeHelpText3 - N'
  JNT_Volumes_WelcomeHelpText4 = 'JNT_Volumes_WelcomeHelpText4 - N'
  JNT_Volumes_WelcomeHelpText5 = 'JNT_Volumes_WelcomeHelpText5 - N'
  JNT_Volumes_WelcomeHelpText6 = 'JNT_Volumes_WelcomeHelpText6 - N'
  JNT_Volumes_WelcomeHelpText7 = 'JNT_Volumes_WelcomeHelpText7 - N'
  JNT_Volumes_WelcomeHelpText8 = 'JNT_Volumes_WelcomeHelpText8 - N'
  JNT_Volumes_WelcomeHelpTitle = 'JNT_Volumes_WelcomeHelpTitle - N'
  JNT_Volumes_MoreThan_Versions =
    'More than one country selected, so unable to display the Versions. - N'
  JNT_Volumes_AllVersions_Selected =
    'All Versions have been selected for report output. - N'
  JNT_Volumes_Academy_JATONet =
    'Boost your JATO Net proficiency in minutes. - N'
  JNT_Volumes_Academy_Clickhere =
    "Click here to access JATO Academy's free, all-inclusive eLearning course. -N"
  JNT_News_MP_Disclaimer_Title = 'Monthly Payments - N'
  JNT_News_MP_Disclaimer_Text =
    'The raw data underpinning JATO’s Monthly Payment intelligence solution is subject to retrospective revisions and additional new data being included at any time.' +
    '<br><br>JATO does not warrant the complete accuracy or market coverage of the data or that it is fit for purpose for your particular use. JATO explicitly disclaims all liability for any loss or damage that may arise from the use or interpretation of the data or insights presented in this alert article. You agree that your use of Monthly Payment News Alerts and any reliance you place on its contents is solely at your own risk.' +
    '<br><br>By using the service, you accept this disclaimer in full; accordingly, if you disagree with this disclaimer or any part of it, you must not use the data contained here. - N'
  JNT_News_MP_Disclaimer_Copyright =
    'Copyright © and database right: JATO Dynamics Limited 1990 – %CURRENT_YEAR%. All Rights Reserved.  JATO’s software and databases are protected by UK and international copyright and database laws.  Infringement of its rights may result in civil or criminal action being taken against you. - N'
  JNT_NewsMonthlyPaymentsWelcomeTitle =
    'Your free finance news alerts are here. - N'
  JNT_NewsMonthlyPaymentsWelcomeText1 =
    'As a valued JATO user, we have listened to our customers and as a result we have created a new service that offers to help you stay informed of changes in the automotive finance market. - N'
  JNT_NewsMonthlyPaymentsWelcomeText2 =
    'This new feature – a blend of JATO News alerting capability and Monthly Payments finance intelligence – is now available to you as part of your current JATO data licence. - N'
  JNT_NewsMonthlyPaymentsWelcomeText3 = 'Key highlights include: - N'
  JNT_NewsMonthlyPaymentsWelcomeText4_1 = 'Convenience and efficiency. - N'
  JNT_NewsMonthlyPaymentsWelcomeText4_2 =
    'Each alert is sent to your inbox acting as a call to action, saving time and resource on manual research and monitoring. - N'
  JNT_NewsMonthlyPaymentsWelcomeText5_1 = 'Timely updates - N'
  JNT_NewsMonthlyPaymentsWelcomeText5_2 =
    'in a form of customisable email notifications sent directly to you in real-time. - N'
  JNT_NewsMonthlyPaymentsWelcomeText6_1 = 'Comprehensive understanding - N'
  JNT_NewsMonthlyPaymentsWelcomeText6_2 =
    'of your local finance market and how it evolves over time. - N'
  JNT_NewsMonthlyPaymentsWelcomeText7 = 'Enjoy the new service. - N'
  JNT_NewsMonthlyPaymentsWelcomeText8 = 'Regards, - N'
  JNT_NewsMonthlyPaymentsWelcomeText9 = 'Team JATO - N'
  JNT_Specs_Not_Available = 'Not Available - N'
  JNT_MpAlertsBeta_HomePageTitle = 'Finance Market News - N'
  JNT_MpAlertsBeta_ToggleText = 'Monthly Payments Alerts Access - N'
  JNT_MpAlertsBeta_ActivateLinkText =
    'Activate your complementary Monthly Payments Alerts here - N'
  JNT_MpAlertsBeta_ActivationPopupTitle =
    "Congratulations! You've been selected for complementary access of our exclusive Monthly Payment Alerts service! - N"
  JNT_MpAlertsBeta_ActivationPopupText =
    'You now have the opportunity for you and your organisation to benefit from the power of Monthly Payment alerts —a dynamic blend of JATO News and finance insights. Get instant notifications whenever your competitors adjust their finance offers. <br><br>Ready to set up your first alert? - N'
  JNT_MpAlertsBeta_TCsPopupTitle = 'Terms and Conditions - N'
  JNT_MpAlertsBeta_TCsPopupText =
    "The raw data underpinning JATO's Monthly Payment intelligence solution is subject to retrospective revisions and additional new data being included at any time.<br><br>JATO does not warrant the complete accuracy or market coverage of the data or that it is fit for purpose for your particular use. JATO explicitly disclaims all liability for any loss or damage that may arise from the use or interpretation of the data or insights presented in this alert article. You agree that your use of Monthly Payment News Alerts and any reliance you place on its contents is solely at your own risk.<br><br>By using the service, you accept this disclaimer in full; accordingly, if you disagree with this disclaimer or any part of it, you must not use the data contained here. - N"
  JNT_Accept = 'Accept - N'
  JNT_Decline = 'Decline - N'
  JNT_MpAlertsBeta_WelcomePopupTitle = 'Complementary Access Activated - N'
  JNT_MpAlertsBeta_WelcomePopupText1 =
    'Keeping up with the latest in automotive finance just got easier!<br><br> With Monthly Payments alerts—combining JATO News with finance insights—you’ll get timely updates delivered straight to your inbox.<br><br> <b>Key highlights:</b> <ul> <li><b>Convenience and efficiency.</b> Receive actionable alerts and save time on manual research.</li> <li><b>Real-time updates.</b> Customised notifications keep you informed as changes happen.</li> <li><b>In-depth understanding.</b> Gain a clear view of your local finance market and how it evolves.</li> </ul> - N'
  JNT_MpAlertsBeta_WelcomePopupText2 =
    'Stay informed and in control with Monthly Payments alerts.<br><br> Team JATO - N'
  JNT_MpAlertsBeta_WelcomePopupLink =
    "Click here to access JATO Academy's free, all-inclusive eLearning course - N"
  JNT_Confirmation = 'Confirmation - N'
  JNT_Alert = 'Alert - N'
  JNT_MpAlertsBeta_AnnouncementStartTitle = 'Complementary Access Activated - N'
  JNT_MpAlertsBeta_AnnouncementStart =
    'Your Complementary Access is now active! Set up your bespoke alerts and stay informed. - N'
  JNT_MpAlertsBeta_AnnouncementMidTitle = 'Important - N'
  JNT_MpAlertsBeta_AnnouncementMid =
    "You have %COUNT_DAYS% days left to explore your Monthly Payments Alerts. Don't miss out - learn how to maximise your experience today! - N"
  JNT_MpAlertsBeta_AnnouncementEndTitle = 'Important - N'
  JNT_MpAlertsBeta_AnnouncementEnd =
    'Your complementary Monthly Payments alerts access has ended. Enjoyed the benefits? - N'
  JNT_MpAlertsBeta_AnnouncementEndLink = 'Request a quote now - N'
  JNT_MpAlertsBeta_HelpLink =
    'How to get the most out of Monthly Payments alerts - N'
  JNT_Portal_Login_Un = 'JNT_Portal_Login_Un - N'
  JNT_Portal_Login_Pwd = 'JNT_Portal_Login_Pwd - N'
  JNT_Portal_LOG_SignIn = 'JNT_Portal_LOG_SignIn - N'
  JNT_Portal_Login_Forgot = 'JNT_Portal_Login_Forgot - N'
  JNT_Portal_Login_Register = 'JNT_Portal_Login_Register - N'
  JNT_Portal_ForgotPass_Title = 'JNT_Portal_ForgotPass_Title - N'
  JNT_Portal_CustomerRegistration_Heading =
    'JNT_Portal_CustomerRegistration_Heading - N'
  JNT_Portal_NAV_personal = 'My Settings - N'
  JNT_Portal_Login_Welcome = 'Welcome - N'
  JNT_Portal_MJ_YourQueries = 'My Volumes - N'
  JNT_Portal_MJN_SharedQueries = 'Queries shared to you - N'
  JNT_Portal_NAV_mySearches = 'My News - N'
  JNT_Portal_MJ_YourBaskets = 'My Specifications - N'
  JNT_Portal_BAS_share = 'Baskets shared to you - N'
  JNT_Portal_NAV_portal = 'My Portal settings - N'
  JNT_Portal_MJ_ChangePassword = 'Change Password - N'
  JNT_Portal_MJ_ChangeLanguage = 'Change language - N'
  JNT_Portal_Login_sales = 'Sales enquiry - N'
  JNT_Portal_MJ_TechnicalSupport = 'Customer support - N'
  JNT_Portal_MJ_YourComments = 'Your Comments - N'
  JNT_Portal_ModPwd_Title = 'Change your password - N'
  JNT_Portal_SalesEnquiry_Heading = 'JATO Net - Sales enquiry - N'
  JNT_Portal_TechSupport_Heading = 'JATO Net - Customer support - N'
  JNT_Portal_Login_DoYouHaveAccount = 'JNT_Portal_Login_DoYouHaveAccount -N'
  JNT_Portal_Login_Support = 'JNT_Portal_Login_Support - N'
  JNT_Portal_CookieConsent_Message = 'JNT_Portal_CookieConsent_Message - N'
  JNT_Portal_CookieConsent_LinkText = 'JNT_Portal_CookieConsent_LinkText - N'
  JNT_Portal_CookieConsent_Dismiss = 'JNT_Portal_CookieConsent_Dismiss - N'
  JNT_Portal_CustReg_Title = 'JNT_Portal_CustReg_Title - N'
  JNT_Portal_CustReg_Name = 'JNT_Portal_CustReg_Name - N'
  JNT_Portal_CustReg_Surname = 'JNT_Portal_CustReg_Surname - N'
  JNT_Portal_CustReg_Email = 'JNT_Portal_CustReg_Email - N'
  JNT_Portal_CustReg_Cntry = 'JNT_Portal_CustReg_Cntry - N'
  JNT_Portal_CustReg_Company = 'JNT_Portal_CustReg_Company - N'
  JNT_Portal_CustReg_Position = 'JNT_Portal_CustReg_Position - N'
  JNT_Portal_CustReg_Address = 'JNT_Portal_CustReg_Address - N'
  JNT_Portal_CustReg_Lang = 'JNT_Portal_CustReg_Lang - N'
  JNT_Portal_CustReg_Industry = 'JNT_Portal_CustReg_Industry - N'
  JNT_Portal_CustReg_TelNumber = 'JNT_Portal_CustReg_TelNumber - N'
  JNT_Portal_CustReg_UseJatoNetFor = 'JNT_Portal_CustReg_UseJatoNetFor - N'
  JNT_Portal_Login_PasswordConfirm = 'JNT_Portal_Login_PasswordConfirm - N'
  JNT_Portal_CustReg_Info = 'JNT_Portal_CustReg_Info - N'
  JNT_Portal_CustReg_btnReg = 'JNT_Portal_CustReg_btnReg - N'
  JNT_Portal_CustReg_Desc = 'JNT_Portal_CustReg_Desc - N'
  JNT_Portal_Form_Field_Required = 'JNT_Portal_Form_Field_Required -N'
  JNT_Portal_Register_Success = 'JNT_Portal_Register_Success - N'
  JNT_Portal_CustReg_UserName_Exist_Message =
    'CustReg_UserName_Exist_Message -N'
  JNT_Portal_PopError_RegFailure = 'JNT_Portal_PopError_RegFailure - N'
  JNT_Portal_Pop_regSuccess10 = 'JNT_Portal_Pop_regSuccess10 - N'
  JNT_Portal_Form_Email_Field_Required =
    'JNT_Portal_Form_Email_Field_Required -N'
  JNT_Portal_Sales_News = 'JNT_Portal_Sales_News - N'
  JNT_Portal_Sales_Volumes = 'JNT_Portal_Sales_Volumes -N'
  JNT_Portal_Sales_SpecsCars = 'JNT_Portal_Sales_SpecsCars - N'
  JNT_Portal_Sales_Incent = 'JNT_Portal_Sales_Incent - N'
  JNT_Portal_Sales_Company = 'JNT_Portal_Sales_Company -N'
  JNT_Portal_Sales_JobTitle = 'JNT_Portal_Sales_JobTitle -N'
  JNT_Portal_Sales_Countries = 'JNT_Portal_Sales_Countries - N'
  JNT_Portal_Sales_OtherRequest = 'JNT_Portal_Sales_OtherRequest - N'
  JNT_Portal_Sales_btnSubmit = 'JNT_Portal_Sales_btnSubmit -N'
  JNT_Portal_Sales_Desc = 'JNT_Portal_Sales_Desc -N'
  JNT_Portal_Pop_QueryUpdated = 'JNT_Portal_Pop_QueryUpdated -N'
  JNT_Portal_PopError_Failure = 'JNT_Portal_PopError_Failure - N'
  JNT_Portal_Invalid_Phone_Number = 'JNT_Portal_Invalid_Phone_Number - N'
  JNT_Portal_Support_Desc = 'JNT_Portal_Support_Desc -N'
  JNT_Portal_Support_Browser = 'JNT_Portal_Support_Browser - N'
  JNT_Portal_Support_Os = 'JNT_Portal_Support_Os - N'
  JNT_Portal_ForgotPass_Desc = 'JNT_Portal_ForgotPass_Desc - N'
  JNT_Portal_ForgotPassword_Email_Sent =
    'JNT_Portal_ForgotPassword_Email_Sent - N'
  JNT_Portal_ForgotPassword_Confirm_Message =
    'JNT_Portal_ForgotPassword_Confirm_Message - N'
  JNT_Portal_ResetPassword_Reset_Label =
    'JNT_Portal_ResetPassword_Reset_Label -N'
  JNT_Portal_ModPwd_Desc = 'JNT_Portal_ModPwd_Desc -N'
  JNT_Portal_ForgotPass_Reset = 'JNT_Portal_ForgotPass_Reset -N'
  JNT_Portal_Term_Heading = 'JNT_Portal_Term_Heading -N'
  JNT_Portal_Term_AgreementHeadline = 'JNT_Portal_Term_AgreementHeadline -N'
  JNT_Portal_Term_Agreement = 'JNT_Portal_Term_Agreement -N'
  JNT_Portal_Term_Heading1 = 'JNT_Portal_Term_Heading1 -N'
  JNT_Portal_Term_Heading1Text = 'JNT_Portal_Term_Heading1Text -N'
  JNT_Portal_Term_Heading2 = 'JNT_Portal_Term_Heading2 -N'
  JNT_Portal_Term_Heading2Text = 'JNT_Portal_Term_Heading2Text -N'
  JNT_Portal_Term_Heading3 = 'JNT_Portal_Term_Heading3 -N'
  JNT_Portal_Term_Heading3_1Text = 'JNT_Portal_Term_Heading3_1Text - N'
  JNT_Portal_Term_Heading3_1_1Text = 'JNT_Portal_Term_Heading3_1_1Text - N'
  JNT_Portal_Term_Heading3_1_2Text = 'JNT_Portal_Term_Heading3_1_2Text -N'
  JNT_Portal_Term_Heading3_2Text = 'JNT_Portal_Term_Heading3_2Text - N'
  JNT_Portal_Term_Heading4 = 'JNT_Portal_Term_Heading4 -N'
  JNT_Portal_Term_Heading4_1Text = 'JNT_Portal_Term_Heading4_1Text -N'
  JNT_Portal_Term_Heading4_2Text = 'JNT_Portal_Term_Heading4_2Text -N'
  JNT_Portal_Term_Heading4_3Text = 'JNT_Portal_Term_Heading4_3Text -N'
  JNT_Portal_Term_Heading4_4Text = 'JNT_Portal_Term_Heading4_4Text -N'
  JNT_Portal_Term_Heading4_5Text = 'JNT_Portal_Term_Heading4_5Text -N'
  JNT_Portal_Term_Heading4_6Text = 'JNT_Portal_Term_Heading4_6Text - N'
  JNT_Portal_Term_Heading5 = 'JNT_Portal_Term_Heading5 -N'
  JNT_Portal_Term_Heading5Text = 'JNT_Portal_Term_Heading5Text -N'
  JNT_Portal_Term_Heading6 = 'JNT_Portal_Term_Heading6 -N'
  JNT_Portal_Term_Heading6Text = 'JNT_Portal_Term_Heading6Text - N'
  JNT_Portal_Term_Heading7 = 'JNT_Portal_Term_Heading7 -N'
  JNT_Portal_Term_Heading7Text = 'JNT_Portal_Term_Heading7Text - N'
  JNT_Portal_Term_Heading8 = 'JNT_Portal_Term_Heading8 -N'
  JNT_Portal_Term_Heading8Text = 'JNT_Portal_Term_Heading8Text -N'
  JNT_Portal_Term_Heading9 = 'JNT_Portal_Term_Heading9 -N'
  JNT_Portal_Term_Heading9Text = 'JNT_Portal_Term_Heading9Text -N'
  JNT_Portal_Term_Heading10 = 'JNT_Portal_Term_Heading10 -N'
  JNT_Portal_Term_Heading10Text = 'JNT_Portal_Term_Heading10Text -N'
  JNT_Portal_Term_Heading11 = 'JNT_Portal_Term_Heading11'
  JNT_Portal_Term_Heading11Text = 'JNT_Portal_Term_Heading11Text -N'
  JNT_Portal_Term_Heading12 = 'JNT_Portal_Term_Heading12 -N'
  JNT_Portal_Term_Heading12Text = 'JNT_Portal_Term_Heading12Text -N'
  JNT_Portal_Term_Heading13 = 'JNT_Portal_Term_Heading13 -N'
  JNT_Portal_Term_Heading13Text = 'JNT_Portal_Term_Heading13Text -N'
  JNT_Portal_Term_Heading14 = 'JNT_Portal_Term_Heading14 -N'
  JNT_Portal_Term_Heading14Text = 'JNT_Portal_Term_Heading14Text -N'
  JNT_Portal_Term_Heading15 = 'JNT_Portal_Term_Heading15 -N'
  JNT_Portal_Term_Heading15Text = 'JNT_Portal_Term_Heading15Text - N'
  JNT_Portal_Login_DoYouAcceptTerm = 'JNT_Portal_login_DoYouAcceptTerm - N'
  JNT_Portal_Login_MustAcceptTermText = 'JNT_Portal_login_MustAcceptTermText -N'
  JNT_Portal_Login_OverviewHead = 'JNT_Portal_Login_OverviewHead - N'
  JNT_Portal_Login_OverviewSubHead = 'JNT_Portal_Login_OverviewSubHead -N'
  JNT_Portal_Login_OverviewSubHeadText =
    'JNT_Portal_Login_OverviewSubHeadText - N'
  JNT_Portal_Login_OverviewSubHead1 = 'JNT_Portal_Login_OverviewSubHead1 -N'
  JNT_Portal_Login_OverviewSubHead1TextHtml =
    'JNT_Portal_Login_OverviewSubHead1TextHtml -N'
  JNT_Portal_Login_OverviewSubHead1Text =
    'JNT_Portal_Login_OverviewSubHead1Text _N'
  JNT_Portal_Login_OverviewSubHead2 = 'JNT_Portal_Login_OverviewSubHead2 -N'
  JNT_Portal_Login_OverviewSubHead2TextHtml =
    'JNT_Portal_Login_OverviewSubHead2TextHtml -N'
  JNT_Portal_Login_OverviewSubHead3 = 'JNT_Portal_Login_OverviewSubHead3 -N'
  JNT_Portal_Login_OverviewSubHead3TextHtml =
    'JNT_Portal_Login_OverviewSubHead3TextHtml -N'
  JNT_Portal_Login_OverviewSubHead3TextHtml1 =
    'JNT_Portal_Login_OverviewSubHead3TextHtml1 -N'
  JNT_Portal_Login_OverviewSubHead4 = 'JNT_Portal_Login_OverviewSubHead4 -N'
  JNT_Portal_Login_OverviewSubHead4Text =
    'JNT_Portal_Login_OverviewSubHead4Text -N'
  JNT_Portal_Login_PrivacyHead = 'JNT_Portal_Login_PrivacyHead -N'
  JNT_Portal_Login_PrivacySubHead = 'JNT_Portal_Login_PrivacySubHead - N'
  JNT_Portal_Login_PrivacyText = 'JNT_Portal_Login_PrivacyText -N'
  JNT_Portal_Login_PrivacySubHead1 = 'JNT_Portal_Login_PrivacySubHead1 -N'
  JNT_Portal_Login_PrivacyText1 = 'JNT_Portal_Login_PrivacyText1 -N'
  JNT_Portal_Login_PrivacyText2 = 'JNT_Portal_Login_PrivacyText2 -N'
  JNT_Portal_Login_PrivacyText3Html = 'JNT_Portal_Login_PrivacyText3Html -N'
  JNT_Portal_Login_PrivacyText4Html = 'JNT_Portal_Login_PrivacyText4Html -N'
  JNT_Portal_Login_PrivacyText5Html = 'JNT_Portal_Login_PrivacyText5Html - N'
  JNT_Portal_Login_PrivacySubHead2 = 'JNT_Portal_Login_PrivacySubHead2 -N'
  JNT_Portal_Login_PrivacySub2Text = 'JNT_Portal_Login_PrivacySub2Text -N'
  JNT_Portal_Login_PrivacySubHead3 = 'JNT_Portal_Login_PrivacySubHead3 -N'
  JNT_Portal_Login_PrivacySub3Text = 'JNT_Portal_Login_PrivacySub3Text -N'
  JNT_Portal_Login_PrivacySub3TextHtml =
    'JNT_Portal_Login_PrivacySub3TextHtml -N'
  JNT_Portal_Login_PrivacySubHead4 = 'JNT_Portal_Login_PrivacySubHead4 - N'
  JNT_Portal_Login_PrivacySub4Text = 'JNT_Portal_Login_PrivacySub4Text -N'
  JNT_Portal_Login_PrivacySub4TextHtml =
    'JNT_Portal_Login_PrivacySub4TextHtml - N'
  JNT_Portal_Login_PrivacySub4TextHtml1 =
    'JNT_Portal_Login_PrivacySub4TextHtml1 - N'
  JNT_Portal_Login_PrivacySub4TextHtml2 =
    'JNT_Portal_Login_PrivacySub4TextHtml2 - N'
  JNT_Portal_Login_PrivacyTab = 'JNT_Portal_Login_PrivacyTab - N'
  JNT_Portal_Login_MarketingHead = 'JNT_Portal_Login_MarketingHead -N'
  JNT_Portal_Login_MarketingSubHead = 'JNT_Portal_Login_MarketingSubHead -N'
  JNT_Portal_Login_MarketingSubHeadText =
    'JNT_Portal_Login_MarketingSubHeadText -N'
  JNT_Portal_Login_MarketingSubHead1 = 'JNT_Portal_Login_MarketingSubHead1 -N'
  JNT_Portal_Login_MarketingSubHead1Text =
    'JNT_Portal_Login_MarketingSubHead1Text -N'
  JNT_Portal_Login_MarketingSubHead2 = 'JNT_Portal_Login_MarketingSubHead2 -N'
  JNT_Portal_Login_MarketingSubHead2Text =
    'JNT_Portal_Login_MarketingSubHead2Text -N'
  JNT_Portal_Login_CookieHead = 'JNT_Portal_Login_CookieHead -N'
  JNT_Portal_Login_CookieSubHead = 'JNT_Portal_Login_CookieSubHead -N'
  JNT_Portal_Login_CookieSubHeadText = 'JNT_Portal_Login_CookieSubHeadText -N'
  JNT_Portal_Login_CookieSubHead1 = 'JNT_Portal_Login_CookieSubHead1 -N'
  JNT_Portal_Login_CookieSubHead1Text = 'JNT_Portal_Login_CookieSubHead1Text -N'
  JNT_Portal_Login_DataHead = 'JNT_Portal_Login_DataHead -N'
  JNT_Portal_Login_DataSubHead = 'JNT_Portal_Login_DataSubHead -N'
  JNT_Portal_Login_DataSubHeadText = 'JNT_Portal_Login_DataSubHeadText -N'
  JNT_Portal_Login_DataSubHead1 = 'JNT_Portal_Login_DataSubHead1 -N'
  JNT_Portal_Login_DataSubHead1Text = 'JNT_Portal_Login_DataSubHead1Text -N'
  JNT_Portal_Login_DataSubHead1TextHtml =
    'JNT_Portal_Login_DataSubHead1TextHtml -N'
  JNT_Portal_Login_DataSubHead2 = 'JNT_Portal_Login_DataSubHead2 -N'
  JNT_Portal_Login_DataSubHead2Text = 'JNT_Portal_Login_DataSubHead2Text -N'
  JNT_Portal_Login_DataSubHead3 = 'JNT_Portal_Login_DataSubHead3 - N'
  JNT_Portal_Login_DataSubHead3TextHtml =
    'JNT_Portal_Login_DataSubHead3TextHtml - N'
  JNT_Portal_Login_DataSubHead3Text = 'JNT_Portal_Login_DataSubHead3Text -N'
  JNT_Portal_Login_DataSubHead4 = 'JNT_Portal_Login_DataSubHead4 - N'
  JNT_Portal_Login_DataSubHead4Text = 'JNT_Portal_Login_DataSubHead4Text - N'
  JNT_Portal_Login_DataSubHead4Text1 = 'JNT_Portal_Login_DataSubHead4Text1 -N'
  JNT_Portal_Login_DataSubHead4TextHtml =
    'JNT_Portal_Login_DataSubHead4TextHtml -N'
  JNT_Portal_Login_DataSubHead4TextHtml1 =
    'JNT_Portal_Login_DataSubHead4TextHtml1 -N'
  JNT_Portal_Login_DataSubHead5 = 'JNT_Portal_Login_DataSubHead5 - N'
  JNT_Portal_Login_DataSubHead5Text = 'JNT_Portal_Login_DataSubHead5Text -N'
  JNT_Portal_Login_DataSubHead6 = 'JNT_Portal_Login_DataSubHead6 -N'
  JNT_Portal_Login_DataSubHead7 = 'JNT_Portal_Login_DataSubHead7 -N'
  JNT_Portal_Login_DataSubHead7Text = 'JNT_Portal_Login_DataSubHead7Text -N'
  JNT_Portal_Login_GlossaryHead = 'JNT_Portal_Login_GlossaryHead -N'
  JNT_Portal_Login_GlossarySubHead = 'JNT_Portal_Login_GlossarySubHead -N'
  JNT_Portal_Login_GlossarySubHeadTextHtml =
    'JNT_Portal_Login_GlossarySubHeadTextHtml -N'
  JNT_Portal_Login_GlossarySubHead1 = 'JNT_Portal_Login_GlossarySubHead1 -N'
  JNT_Portal_Login_GlossarySubHead1TextHtml =
    'JNT_Portal_Login_GlossarySubHead1TextHtml -N'
  JNT_Portal_Login_GlossarySubHead2 = 'JNT_Portal_Login_GlossarySubHead2 -N'
  JNT_Portal_Login_GlossarySubHead2TextHtml =
    'JNT_Portal_Login_GlossarySubHead2TextHtml -N'
  JNT_Portal_Login_GlossarySubHead2TextHtml1 =
    'JNT_Portal_Login_GlossarySubHead2TextHtml1 -N'
  JNT_Portal_CustReg_Password_MinLength_Message =
    'JNT_Portal_CustReg_Password_MinLength_Message -N'
  JNT_Portal_CustReg_Password_UpperCase_Message =
    'JNT_Portal_CustReg_Password_UpperCase_Message - N'
  JNT_Portal_CustReg_Password_LowerCase_Message =
    'JNT_Portal_CustReg_Password_LowerCase_Message - N'
  JNT_Portal_CustReg_Password_MinNumber_Message =
    'JNT_Portal_CustReg_Password_MinNumber_Message - N'
  JNT_Portal_CustReg_Password_NoMatch_Message =
    'JNT_Portal_CustReg_Password_NoMatch_Message - N'
  JNT_Portal_Login_AccountLocked = 'JNT_Portal_Login_AccountLocked -N'
  JNT_Portal_LicenceExpired_Message = 'JNT_Portal_LicenceExpired_Message -N'
  JNT_Portal_Customer_Support_Text = 'JNT_Portal_Customer_Support_Text -N'
  JNT_Portal_Login_Locked = 'JNT_Portal_Login_Locked -N'
  JNT_Portal_Login_AuthenticationError =
    'JNT_Portal_Login_AuthenticationError -N'
  JNT_Portal_Activate_Account_Link_Expired =
    'JNT_Portal_Activate_Account_Link_Expired -N'
  JNT_Portal_Login_LicenceAgreement = 'JNT_Portal_Login_LicenceAgreement -N'
  JNT_COM_CLICK = 'JNT_COM_CLICK -N'
  JNT_Portal_PS_Lang = 'Language preference - N'
  JNT_Portal_PS_Curr = 'Currency preference - N'
  JNT_Portal_Suggest_Desc =
    'Feedback and suggestions are greatly appreciated and help keep JATO Net in good shape. - N'
  JNT_Portal_Suggest_Name = 'Your Name: - N'
  JNT_Portal_Suggest_Email = 'Your Email Address: - N'
  JNT_Portal_Suggest_Cntry = 'Country: - N'
  JNT_Portal_Suggest_Selection = 'Select one - N'
  JNT_Portal_Suggest_Comments = 'Comments: - N'
  JNT_Portal_Suggest_Submit = 'Submit - N'
  JNT_Portal_Form_Company_Mail_Required =
    'JNT_Portal_Form_Company_Mail_Required - N'
  JNT_Portal_Hide_Password = 'JNT_Portal_Hide_Password - N'
  JNT_Portal_Show_Password = 'JNT_Portal_Show_Password - N'
  JNT_Portal_CustReg_Password_Criteria_Label =
    'At least 3 of the following: - N'
  JNT_Portal_CustReg_Password_SpecialChars_Message =
    'One special characters (e.g.*&^%$£"!@~#/?<>+) - N'
  JNT_Portal_Form_Required_Placeholder = 'Required - N'
  JNT_Portal_ModPwd_OldPwd = 'Old password - N'
  JNT_Portal_ModPwd_NewPwd = 'New password - N'
  JNT_Portal_ModPwd_RePwd = 'Re-enter new password - N'
  JNT_Portal_ChangePassword_UsedBefore_Message =
    'Password has been used before. Please enter a new password. - N'
  JNT_Portal_PopError_InvalidUN = 'JNT_Portal_PopError_InvalidUN -N'
  JNT_Mp_Fully_Estimated_Data = 'JNT_Mp_Fully_Estimated_Data - N'
  JNT_Mp_Actual_Data = 'JNT_Mp_Actual_Data - N'
}
