import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { ExcelPageDataRequest } from 'models/excelExport/ExcelPageDataRequest'
import {
  SpecsExcelStyleType,
  getDefaultOptionText,
  getExcelVehicleName,
  getMaximumRowsLengthForOptionsExcel,
  getSpecsHeader,
} from '../excelExportHelper'
import {
  OptionIconType,
  OptionsPageResponse,
  StatelessCategoryOption,
} from 'models/Specifications/Options/OptionsPageResponse'
import { BuiltOption } from 'models/Specifications/BrochureResponse'

class OptionsExcelTemplate {
  catCount: number
  formateVehicle: any
  formateVehicleData: any
  constructor() {
    this.catCount = 0
    this.formateVehicleData = []
  }
  private getItemRow = (value: any, style: any): any => ({
    value: value,
    styleName: style,
  })

  private getOptions = (
    maximumOptionRows: number,
    vehicleId: number,
    buildOptionList: BuiltOption[],
    columnNumber: number,
    translations: AppTextTranslations
  ): any => {
    const optionRows = []
    let primaryOptionInfos
    let secondaryOptionInfos
    let numberOfOptionPerVehicle = 0
    let difference
    let i
    let j
    if (maximumOptionRows > 0) {
      if (columnNumber === 1) {
        optionRows.push(
          this.getItemRow(
            translations.JNT_added,
            SpecsExcelStyleType.CategoryHeader
          )
        )
      } else {
        optionRows.push(this.getItemRow('', ''))
      }

      primaryOptionInfos = buildOptionList.filter(
        (vehicle: BuiltOption) => vehicle.vehicleId === vehicleId
      )
      for (i = 0; i < primaryOptionInfos.length; i++) {
        numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
        optionRows.push(
          this.getItemRow(
            `[${primaryOptionInfos[i].optionCode}] ${primaryOptionInfos[i].optionName} ${primaryOptionInfos[i].displayPrice}`
              .replace('\r', '')
              .replace('\n', ''),
            SpecsExcelStyleType.PrimaryOption
          )
        )
        secondaryOptionInfos = primaryOptionInfos[i].secondaryOptions
        for (j = 0; j < secondaryOptionInfos.length; j++) {
          numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
          optionRows.push(
            this.getItemRow(
              `[${secondaryOptionInfos[j].optionCode}] ${secondaryOptionInfos[j].optionName} ${secondaryOptionInfos[j].displayPrice}`
                .replace('\r', '')
                .replace('\n', ''),
              SpecsExcelStyleType.SecondaryOption
            )
          )
        }
      }
      if (numberOfOptionPerVehicle < maximumOptionRows) {
        difference = maximumOptionRows - numberOfOptionPerVehicle
        for (i = 0; i < difference; i++) {
          optionRows.push(this.getItemRow('', ''))
        }
      }
    }
    return optionRows
  }

  private getOptionsPageNew = (
    uniqueCategories: StatelessCategoryOption[],
    vehicleOptionsItems: OptionsPageResponse | undefined,
    vehicleId: number,
    columnNumber: number,
    translations: AppTextTranslations
  ): any => {
    const itemRows = []

    for (let i = 0; i < uniqueCategories.length; i++) {
      // get maximum number of rows within all vehicles
      const numbersForVehicles: number[] | undefined =
        vehicleOptionsItems?.vehicles.map((vehicleOptions) =>
          vehicleOptions.rows
            .flatMap((options) => options)
            .filter(
              (row) =>
                row.translatedCategoryName ===
                uniqueCategories[i].translatedCategoryName
            )
            .map((row) => row.content.content.length + 1)
            .reduce((a, b) => a + b, 0)
        )

      const numberOfRows = Math.max(...numbersForVehicles!)

      const vehicleOptions = vehicleOptionsItems!.vehicles
        .filter((optionsItems) => optionsItems.header.vehicleId === vehicleId)
        .flatMap((optionRows) => optionRows.rows)
        .filter(
          (rows) =>
            rows.translatedCategoryName ===
            uniqueCategories[i].translatedCategoryName
        )

      if (vehicleOptions.length > 0) {
        if (columnNumber > 1) {
          itemRows.push(this.getItemRow('', SpecsExcelStyleType.ItemTitle))
        } else {
          itemRows.push(
            this.getItemRow(
              uniqueCategories[i].translatedCategoryName,
              SpecsExcelStyleType.ItemTitle
            )
          )
        }

        let rowsForOption = 0

        for (let j = 0; j < vehicleOptions!.length; j++) {
          rowsForOption += vehicleOptions[j].content.content.length + 1
          const optionMessage = `${vehicleOptions[j].optionName} ${
            vehicleOptions[j].displayPrice
          } ${
            vehicleOptions[j].status !== OptionIconType.Option
              ? ` (${getDefaultOptionText(
                  vehicleOptions[j].status,
                  translations
                )})`
              : ''
          }`

          itemRows.push(
            this.getItemRow(
              optionMessage,
              SpecsExcelStyleType.OptionsItemHighlight
            )
          )

          for (let y = 0; y < vehicleOptions[j].content.content.length; y++) {
            itemRows.push(
              this.getItemRow(
                vehicleOptions[j].content.content[y],
                SpecsExcelStyleType.OptionsItem
              )
            )
          }
        }

        if (rowsForOption < numberOfRows!) {
          const difference = numberOfRows! - rowsForOption
          for (let p = 0; p < difference; p++) {
            itemRows.push(this.getItemRow('', ''))
          }
        }
      }
    }
    return itemRows
  }
  private getColumnPerCar = (
    request: any,
    translations: AppTextTranslations
  ): any => {
    const column = {
      column: request.columnNumber,
      rows: [],
    }

    Array.prototype.push.apply(
      column.rows,
      getSpecsHeader(request.vehicleName, request.displayPrice)
    )
    Array.prototype.push.apply(
      column.rows,
      this.getOptions(
        request.maximumOptionRows,
        request.vehicleId,
        request.buildOptionList,
        request.columnNumber,
        translations
      )
    )

    Array.prototype.push.apply(
      column.rows,
      this.getOptionsPageNew(
        request.uniqueCategories,
        request.optionsItems,
        request.vehicleId,
        request.columnNumber,
        translations
      )
    )
    return column
  }

  public excelOptionsBody = (
    sheetName: string,
    optionsItems: OptionsPageResponse,
    excelPageDataRequest: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    this.catCount = 0
    const table: any[] = []
    const body = {
      sheetName,
      table: table,
    }
    let request = {}
    const maximumOptionRows = getMaximumRowsLengthForOptionsExcel(
      excelPageDataRequest.brochureResponse?.brochureVehicles
    )
    let columnNumber

    for (
      let i = 0;
      i < excelPageDataRequest.brochureResponse!.brochureVehicles.length;
      i++
    ) {
      if (optionsItems.uniqueCategories.length === 0) {
        return [body]
      }

      columnNumber = i + 1
      request = {
        columnNumber: columnNumber,
        maximumOptionRows: maximumOptionRows,
        buildOptionList:
          excelPageDataRequest.brochureResponse!.brochureVehicles[i]
            .builtOptions,
        optionsItems: optionsItems,
        vehicleId:
          excelPageDataRequest.brochureResponse!.brochureVehicles[i]
            .vehicleHeaderInfo.vehicleId,
        vehicleName: getExcelVehicleName(
          excelPageDataRequest.brochureResponse!.brochureVehicles[i]
            .vehicleHeaderInfo,
          excelPageDataRequest.isLocal
        ),
        displayPrice:
          excelPageDataRequest.brochureResponse!.brochureVehicles[i]
            .vehicleHeaderInfo.displayPrice,
        uniqueCategories: optionsItems.uniqueCategories,
      }
      body.table.push(this.getColumnPerCar(request, translations))
    }

    return [body]
  }
}

const optionsExcelTemplate = new OptionsExcelTemplate()
export default optionsExcelTemplate
