import { JATOIcon, JATOTheme } from '@jato/ui-component-library'
import {
  knowledgeArticleVolumesModelMixNowcastUrl,
  knowledgeArticleVolumesReportUrl,
} from 'config'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledVolumesFaq } from './VolumesFaq.styles'

interface FaqSection {
  title: string
  content: { text: string; url?: string; inlineText?: boolean }[]
}

export const VolumesFaq: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const faqSections: FaqSection[] = [
    {
      title: translations.JNT_Volumes_FAQ_Q1,
      content: [
        { text: translations.JNT_Volumes_FAQ_A1 },
        { text: translations.JNT_Volumes_FAQ_A1_2 },
        { text: translations.JNT_Volumes_FAQ_A1_3 },
        { text: translations.JNT_Volumes_FAQ_A1_4 },
      ],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q2,
      content: [
        { text: translations.JNT_Volumes_FAQ_A2 },
        { text: translations.JNT_Volumes_FAQ_A2_2 },
        {
          text: translations.JNT_Volumes_FAQ_A2_3,
          url: knowledgeArticleVolumesReportUrl,
          inlineText: true,
        },
        { text: translations.JNT_Volumes_FAQ_A2_4, inlineText: true },
      ],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q3,
      content: [{ text: translations.JNT_Volumes_FAQ_A3 }],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q4,
      content: [{ text: translations.JNT_Volumes_FAQ_A4 }],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q5,
      content: [
        { text: translations.JNT_Volumes_FAQ_A5 },
        { text: translations.JNT_Volumes_FAQ_A5_2 },
        { text: translations.JNT_Volumes_FAQ_A5_3 },
        { text: translations.JNT_Volumes_FAQ_A5_4 },
        { text: translations.JNT_Volumes_FAQ_A5_5 },
        { text: translations.JNT_Volumes_FAQ_A5_6 },
        { text: translations.JNT_Volumes_FAQ_A5_7 },
      ],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q6,
      content: [{ text: translations.JNT_Volumes_FAQ_A6 }],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q7,
      content: [
        { text: translations.JNT_Volumes_FAQ_A7 },
        { text: translations.JNT_Volumes_FAQ_A7_2 },
      ],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q8,
      content: [
        { text: translations.JNT_Volumes_FAQ_A8 },
        { text: translations.JNT_Volumes_FAQ_A8_2 },
      ],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q9,
      content: [
        { text: translations.JNT_Volumes_FAQ_A9 },
        { text: translations.JNT_Volumes_FAQ_A9_2 },
        { text: translations.JNT_Volumes_FAQ_A9_3 },
      ],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q10,
      content: [{ text: translations.JNT_Volumes_FAQ_A10 }],
    },
    {
      title: translations.JNT_Volumes_FAQ_Q11,
      content: [
        { text: translations.JNT_Volumes_FAQ_A11 },
        { text: translations.JNT_Volumes_FAQ_A11_2 },
        { text: translations.JNT_Volumes_FAQ_A11_3 },
        { text: translations.JNT_Volumes_FAQ_A11_4 },
        { text: translations.JNT_Volumes_FAQ_A11_5 },
        {
          text: translations.JNT_Volumes_FAQ_A11_6,
          url: knowledgeArticleVolumesModelMixNowcastUrl,
          inlineText: true,
        },
      ],
    },
  ]

  function scrollTop(ElementId: string): void {
    document.getElementById(ElementId)?.scrollIntoView()
  }

  return (
    <StyledVolumesFaq>
      <hr />
      {faqSections.map((s, index) => (
        <div key={index}>
          <JATOIcon
            iconName="custom_help_outline"
            className="icon-style"
            iconSize={JATOTheme.fontSizes[3]}
          />
          <a
            id={'volumesFaqSectionLink_' + index}
            className="link-text"
            onClick={() => scrollTop(`${index}`)}
          >
            {s.title}
          </a>
        </div>
      ))}
      <hr />
      {faqSections.map((s, index) => (
        <div key={index} id={`${index}`}>
          <h4>
            <strong>
              <span className="custom-span">{s.title}</span>
            </strong>
          </h4>
          <div className="block-content">
            {s.content.map((c, i) => (
              <span key={i}>
                {i > 0 && !c.inlineText && <><br /><br/></>}
                {c.url ? (
                  <a
                    href={c.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-link"
                    dangerouslySetInnerHTML={{ __html: ' ' + c.text }}
                  />
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: c.text }} />
                )}
              </span>
            ))}
          </div>
          <hr />
        </div>
      ))}
    </StyledVolumesFaq>
  )
}
