import styled from 'styled-components'

export const StyledTerms = styled.div`
  padding: 10px;
  .termHeadLine {
    font-size: 22px;
    font-style: normal;
  }
  .termHeading {
    border-bottom: 1px solid rgb(198, 198, 198);
    font-size: 36px;
    font-weight: 700;
  }
`
