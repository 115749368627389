import {
  JATOButton,
  JATOButtonGroup,
  JATOButtonIcon,
  JATOGroup,
  JATOHeading,
  JATOModal,
  JATOText,
  JATOTextInput,
  JATOTheme
} from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import {
  NewsAlertSubscriptionType,
  englishLanguageId,
  getMakesValueList,
  getModelValueListForSelectedMake,
  getSubjects,
  newsInternationalCode,
} from 'helper/newsHelper'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'

import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { gtmLogNewsSearchAlertCreateEdit } from 'helper/gtm'
import {
  useGetLanguagesByCountryCode,
  useGetMakesModelsByCountryCode,
  useSaveSearchAndAlert,
  useUpdateSearchAndAlert,
} from 'hooks/news'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import { NewsLanguageByCountryCode } from 'models/News/NewsLanguageByCountryCode'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsCategoriesSelect from '../Alerts/NewsCategoriesSelect'
import NewsCountriesSelect from '../Alerts/NewsCountriesSelect'
import NewsLanguageSelect from '../Alerts/NewsLanguageSelect'
import NewsMultiSelect from '../Alerts/NewsMultiSelect'
import { NewsSearchAlertName } from '../Alerts/NewsSearchAlertName/NewsSearchAlertName'
import NewsStartDateSelect from '../Alerts/NewsStartDateSelect'
import NewsSubscriptionTypeSelection from '../Alerts/NewsSubscriptionTypeSelection'
import NewsVehicleTypeSelect from '../Alerts/NewsVehicleTypeSelect'
import { StyledNewsSearchAlertEditor } from './NewsSearchAlertEditor.styles'

interface INewsSearchAlertEditorProps {
  showWindow: boolean
  setWindowState: (state: boolean) => void
  searchIndexRequest: SearchIndexRequest
}

export const NewsSearchAlertEditor: React.FC<INewsSearchAlertEditorProps> = ({
  showWindow,
  setWindowState,
  searchIndexRequest,
}: INewsSearchAlertEditorProps) => {
  const translations = useAppSelector(getTranslations)

  // UI states
  const [convertSearchToAlert, setConvertSearchToAlert] =
    useState<boolean>(false)
  const [searchAlert, setSearchAlert] =
    useState<SearchIndexRequest>(searchIndexRequest)
  const isSearch =
    searchIndexRequest.subscriptionType === NewsAlertSubscriptionType.None

  // Search/Alert data
  const { mutateAsync: saveSearchAndAlert } = useSaveSearchAndAlert()
  const { mutateAsync: updateSearchAndAlert } = useUpdateSearchAndAlert()
  const { data: languageResponse } = useGetLanguagesByCountryCode()
  const { data: makesModelsResponse, isFetching: isFetchingMakeAndModels } =
    useGetMakesModelsByCountryCode(searchAlert.countryList)

  // UI functions
  const handleCountryChange = (value: string): void => {
    setSearchAlert({
      ...searchAlert,
      languageId: englishLanguageId,
      countryList: value ? value.split(',') : [],
    })
  }

  const onCloseButton = (): void => {
    setWindowState(false)
  }

  const onSuccess = (): void => {
    showSuccessToast(
      translations.JNT_Success,
      searchAlert.isSubscribed
        ? translations.JNT_Alert_saved
        : translations.JNT_Search_saved
    )
    onCloseButton()
  }

  const onConfirmChangesClick = async (): Promise<void> => {
    const updatedSearchAlert: SearchIndexRequest = { ...searchAlert }

    if (convertSearchToAlert) {
      updatedSearchAlert.isSubscribed = true
      updatedSearchAlert.status = true
    }

    const subjects = getSubjects(updatedSearchAlert.subjectCategoryCode)

    const payload = { ...updatedSearchAlert, subjectList: subjects }

    const response = updatedSearchAlert.id
      ? await updateSearchAndAlert(payload)
      : await saveSearchAndAlert(payload)

    if (response.isSuccess) {
      gtmLogNewsSearchAlertCreateEdit(payload)
      onSuccess()
    } else {
      showErrorToast(
        translations.JNT_Error,
        translations.JNT_Name_already_exists
      )
    }
  }

  const isConfirmChangesButtonDisabled = (): boolean =>
    searchAlert.description === '' ||
    searchAlert.languageId === 0 ||
    JSON.stringify(searchAlert) === JSON.stringify(searchIndexRequest)

  const headerLabel = convertSearchToAlert
    ? translations.JNT_Create_New_Alert
    : !isSearch
      ? searchAlert.id !== ''
        ? translations.JNT_EditAlert
        : translations.JNT_Create_New_Alert
      : translations.JNT_EditSearch

  const confirmButtonLabel = convertSearchToAlert
    ? translations.JNT_Create_Alert
    : !isSearch
      ? searchAlert.id !== ''
        ? translations.JNT_SaveAlert
        : translations.JNT_Create_Alert
      : translations.JNT_EditSearch

  const getLanguages = (): NewsLanguageByCountryCode[] => {
    const countryCode =
      searchAlert.countryList.length === 1
        ? searchAlert.countryList[0]
        : newsInternationalCode
    return languageResponse?.filter((x) => x.countryCode == countryCode) ?? []
  }

  return (
    <StyledNewsSearchAlertEditor>
      <JATOModal
        isOpen={showWindow}
        maxWidth={'sm'}
        dismissable={true}
        header={() => (
          <>
            <JATOHeading
              className="textAlign"
              as="h4"
              fontWeight="medium"
              display="inline"
            >
              {headerLabel}
              <JATOText className="textAlign" as="p" fontSize={14}>
                {translations.JNT_Alert_Required_Fields}
              </JATOText>
            </JATOHeading>
            <JATOButtonIcon iconName="baseline_close" onClick={onCloseButton} />
          </>
        )}
        footer={() => (
          <>
            <JATOButtonGroup justifyContent={'flex-end'}>
              <JATOButton
                id="newsSearchAlertEditorCloseButton"
                variant="secondary"
                size="large"
                onClick={onCloseButton}
              >
                {translations.JNT_COM_CANCEL}
              </JATOButton>
              <JATOButton
                id="newsSearchAlertEditorConfirmButton"
                variant="primary"
                size="large"
                disabled={isConfirmChangesButtonDisabled()}
                onClick={onConfirmChangesClick}
              >
                {confirmButtonLabel}
              </JATOButton>
            </JATOButtonGroup>
          </>
        )}
      >
        {!searchIndexRequest.isSubscribed && (
          <JATOGroup>
            <CheckboxWithGtm
              id="newsSearchAlertEditorConvertSearchToAlertCheckbox"
              label={translations.JNT_Convert_Search_To_Alert}
              checked={convertSearchToAlert}
              onChange={() => {
                const newValue = !convertSearchToAlert
                setSearchAlert({
                  ...searchAlert,
                  subscriptionType: newValue
                    ? NewsAlertSubscriptionType.UponPublication
                    : NewsAlertSubscriptionType.None,
                })
                setConvertSearchToAlert(newValue)
              }}
            >
              <JATOText
                className="textAlign"
                marginRight={JATOTheme.space[3]}
                as="p"
                fontSize={14}
              >
                {translations.JNT_Convert_Search_To_Alert}
              </JATOText>
            </CheckboxWithGtm>
          </JATOGroup>
        )}
        <div className="searchContainer">
          <NewsSearchAlertName
            onChange={(value) =>
              setSearchAlert({ ...searchAlert, description: value })
            }
            defaultName={searchAlert.description}
            label={
              searchAlert.subscriptionType === NewsAlertSubscriptionType.None
                ? translations.JNT_Search_Name
                : translations.JNT_AlertName
            }
          />
          <JATOGroup flexDirection="row">
            <JATOTextInput
              className="textAlign"
              label={translations.JNT_Search_text}
              id=""
              value={searchAlert.searchQuery}
              width={520}
              onChange={(e) => {
                setSearchAlert({
                  ...searchAlert,
                  searchQuery: e.currentTarget.value,
                })
              }}
            />
            <div
              style={{
                paddingTop: '40px',
              }}
            >
              <CheckboxWithGtm
                id="newsSearchAlertEditorExactMatchCheckbox"
                label={translations.JNT_Exact_match}
                checked={searchAlert.isExactSearch}
                onChange={() => {
                  setSearchAlert({
                    ...searchAlert,
                    isExactSearch: !searchAlert.isExactSearch,
                  })
                }}
              >
                {translations.JNT_Exact_match}
              </CheckboxWithGtm>
            </div>
          </JATOGroup>
        </div>
        <JATOGroup flexDirection="row">
          <NewsCategoriesSelect
            onChange={(value) =>
              setSearchAlert({
                ...searchAlert,
                subjectCategoryCode: value,
              })
            }
            value={searchAlert.subjectCategoryCode}
          />
          <NewsCountriesSelect
            onChange={handleCountryChange}
            value={searchAlert.countryList.join(',')}
          />
        </JATOGroup>
        <JATOGroup flexDirection="row">
          <NewsMultiSelect
            id="newsSearchAlertEditorMakesSelect"
            onChange={(value) =>
              setSearchAlert({
                ...searchAlert,
                makeList: value ? value.split(',') : [],
              })
            }
            placeholder={translations.JNT_AllMakes}
            label={`${translations.JNT_make}`}
            dropdownOptions={getMakesValueList(makesModelsResponse)}
            value={searchAlert.makeList.join(',')}
          />
          <NewsMultiSelect
            id="newsSearchAlertEditorModelsSelect"
            label={translations.JNT_model}
            placeholder={translations.JNT_AllModels}
            onChange={(value) =>
              setSearchAlert({
                ...searchAlert,
                modelList: value ? value.split(',') : [],
              })
            }
            dropdownOptions={getModelValueListForSelectedMake(
              makesModelsResponse,
              searchAlert.makeList
            )}
            disabled={searchAlert.makeList.length === 0}
            value={searchAlert.modelList.join(',')}
          />
        </JATOGroup>
        <JATOGroup flexDirection="row">
          <NewsVehicleTypeSelect
            value={searchAlert.vehicleTypeList.join(',')}
            onChange={(value) =>
              setSearchAlert({
                ...searchAlert,
                vehicleTypeList: value ? value.split(',') : [],
              })
            }
          />
        </JATOGroup>
        {searchAlert.subscriptionType !== NewsAlertSubscriptionType.None && (
          <>
            <JATOGroup flexDirection="row">
              <NewsStartDateSelect
                onChange={(value) =>
                  setSearchAlert({ ...searchAlert, startDate: value })
                }
                selectedDate={
                  searchAlert.startDate
                    ? new Date(searchAlert.startDate)
                    : new Date()
                }
                label={translations.JNT_AlertStartDate}
              />
              <NewsLanguageSelect
                onChange={(value) =>
                  setSearchAlert({ ...searchAlert, languageId: value })
                }
                languages={getLanguages()}
                selectedLanguage={searchAlert.languageId.toString()}
              />
            </JATOGroup>
            <NewsSubscriptionTypeSelection
              onChange={(value) =>
                setSearchAlert({ ...searchAlert, subscriptionType: value })
              }
              value={searchAlert.subscriptionType}
              isRequired={true}
            />
          </>
        )}
      </JATOModal>
      <LoaderModal isOpen={isFetchingMakeAndModels} />
    </StyledNewsSearchAlertEditor>
  )
}
