import { LoaderModal } from 'components/Loader/LoaderModal'
import { useGetBrochureVehicles } from 'hooks/carSpecs'
import { BrochureReturnObject } from 'models/Specifications/BrochureResponse'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getSpecsVehicles } from 'redux/specifications/specificationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledSpecificationsReportHeader } from './SpecificationsReportHeader.styles'
import { SpecificationsReportHeaderVehicle } from './SpecificationsReportHeaderVehicle'
import { SpecsHeaderOptionsDisplay } from './SpecsHeaderOptionsDisplay'

export const SpecificationsReportHeader: React.FC = () => {
  const isShowroom = location.pathname.includes(RoutePaths.SpecsShowroom)
  const isComparisons = location.pathname.includes(RoutePaths.SpecsComparisons)

  const pageClass = isShowroom
    ? 'showroomPage'
    : isComparisons
      ? 'comparisonsPage'
      : 'optionsPage'

  const [displayVehicleImage, setDisplayVehicleImage] = useState(false)
  const [displayOptionBuildInfo, setDisplayOptionBuildInfo] = useState(false)

  const { benchmarkVehicleId } = useAppSelector(getSpecsVehicles)
  const { data: brochureResponse, isFetching: isLoadingBrochure } =
    useGetBrochureVehicles()

  const showOptionDisplay =
    brochureResponse &&
    brochureResponse.brochureVehicles.flatMap((v) => v.builtOptions).length > 0

  const benchmarkVehicle = brochureResponse?.brochureVehicles.find(
    (v) => v.vehicleHeaderInfo.vehicleId == benchmarkVehicleId
  )

  return (
    <StyledSpecificationsReportHeader
      style={{ position: isComparisons ? 'unset' : 'sticky' }}
    >
      <div className={pageClass}>
        <div className="comparisonRow">
          {benchmarkVehicle && (
            <>
              <SpecificationsReportHeaderVehicle
                vehicle={benchmarkVehicle}
                isBenchmark={true}
                setDisplayVehicleImages={setDisplayVehicleImage}
                displayVehicleImages={displayVehicleImage}
              />
              {showOptionDisplay && (
                <SpecsHeaderOptionsDisplay
                  isBenchmark={true}
                  builtOptions={benchmarkVehicle.builtOptions}
                  setDisplayOptionBuildInfo={setDisplayOptionBuildInfo}
                  displayOptionBuildInfo={displayOptionBuildInfo}
                />
              )}
            </>
          )}
        </div>
        <div className="vehiclesRow">
          <div className="emptySpace" />
          {brochureResponse &&
            brochureResponse.brochureVehicles.map(
              (vehicle: BrochureReturnObject, index: number) => (
                <SpecificationsReportHeaderVehicle
                  key={index}
                  vehicle={vehicle}
                  isBenchmark={vehicle == benchmarkVehicle}
                  setDisplayVehicleImages={setDisplayVehicleImage}
                  displayVehicleImages={displayVehicleImage}
                />
              )
            )}
        </div>
        {showOptionDisplay && (
          <div className="optionsRow">
            <div className="emptySpace" />
            {brochureResponse &&
              brochureResponse.brochureVehicles.map((vehicle, index) => (
                <SpecsHeaderOptionsDisplay
                  key={index}
                  isBenchmark={
                    vehicle.vehicleHeaderInfo.vehicleId == benchmarkVehicleId
                  }
                  builtOptions={vehicle.builtOptions}
                  setDisplayOptionBuildInfo={setDisplayOptionBuildInfo}
                  displayOptionBuildInfo={displayOptionBuildInfo}
                />
              ))}
          </div>
        )}
      </div>
      <LoaderModal isOpen={isLoadingBrochure} />
    </StyledSpecificationsReportHeader>
  )
}
