import React, { useEffect } from 'react'
import { StyledLayout } from './styles'
import Footer from 'components/Footer'
import { Container, Row, Col } from 'react-grid-system'
import { LoginHeaderContainer } from 'components/LoginHeader/LoginHeaderContainer'
import { JATOCookie } from 'components/Cookie/JATOCookie'
import {
  selectCommonUserData,
  setCommonUserState,
} from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { useLocation } from 'react-router-dom'
import { useEnableRtl } from 'hooks/useEnableRtl'
interface IProps {
  children: React.ReactNode
}

/**
 * This layout includes no decoration at all and can be used before a user has
 * logged in.
 */
export const HomeLayout: React.FC<IProps> = ({ children }: IProps) => {
  const enableRtl = useEnableRtl()
  const dispatch = useAppDispatch()
  const commonUserData = useAppSelector(selectCommonUserData)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const emailLanguage = searchParams.get('Navlanguage') ?? ''

  useEffect(() => {
    enableRtl
      ? document.body.classList.add('rtl')
      : document.body.classList.remove('rtl')

    // Language change based on email article language
    if (emailLanguage) {
      dispatch(
        setCommonUserState({
          ...commonUserData,
          languageId: +emailLanguage.replace(/[^\d]/g, ''),
        })
      )
    }
  }, [enableRtl])

  useEffect(() => {
    // Language change based on email article language
    if (emailLanguage) {
      dispatch(
        setCommonUserState({
          ...commonUserData,
          languageId: +emailLanguage.replace(/[^\d]/g, ''),
        })
      )
    }
  }, [emailLanguage])

  return (
    <StyledLayout>
      <LoginHeaderContainer />
      <Container xl lg md sm xs>
        <Row>
          <Col md={12} lg={12}>
            {children}
          </Col>
        </Row>
        <Row>
          <JATOCookie />
          <Footer />
        </Row>
      </Container>
    </StyledLayout>
  )
}

export default HomeLayout
