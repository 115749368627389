import {
  JATOAnnouncement,
  JATOLink,
  JATOLoadingButton,
  JATOText,
  JATOTextInput,
  JATOTheme,
} from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { salesEnquiryEmail } from 'config'
import { useFormik } from 'formik'
import { gtmLogEmailDomain } from 'helper/gtm'
import { LicenceExpiredStatus } from 'helper/portalHelper'
import { showErrorToast } from 'helper/toastHelper'
import { usePortalLogin, useValidateUserGuid } from 'hooks/portal'
import { LoginObject } from 'models/Login/LoginObject'
import { LoginRequest } from 'models/Login/LoginRequest'
import { PortalLoginResponse } from 'models/Login/PortalLogin/PortalLoginResponse'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  selectCommonUserData,
  setCommonUserState,
} from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesUserState,
  setVolumesUserState,
} from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import signalRService from 'services/SignalRService'
import * as Yup from 'yup'
import { AcceptJATOTermAndCondition } from './AcceptJATOTermAndCondition'
import { CustomerRegistration } from './CustomerRegistration'
import { ForgotPasswordLink } from './ForgotPasswordLink'
import { StyledSignIn } from './SignIn.styles'

export const SignIn: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const returnUrl = searchParams.get('returnUrl')
  const emailArticleId = searchParams.get('artId') ?? ''
  const emailLinkVersion = searchParams.get('ver') ?? ''
  const activationGuid = searchParams.get('actguid') ?? ''
  const commonUserData = useAppSelector(selectCommonUserData)
  const translations = useAppSelector(getTranslations)
  const { acceptLicence } = useAppSelector(getVolumesUserState)
  const [modalForgotPassword, setModalForgotPassword] = useState(false)
  const [modalCustomerRegistration, setModalCustomerRegistration] =
    useState(false)
  const [modalSalesEnquiry, setModalSalesEnquiry] = useState(false)

  const [showAcceptTermAndCondition, setShowAcceptTermAndCondition] =
    useState(false)

  const { mutateAsync: portalLoginAsync, isLoading } = usePortalLogin()
  const {
    mutateAsync: validateUserGuidAsync,
    isLoading: isLoadingValidateUserGuid,
  } = useValidateUserGuid()

  const RedirectToMainSite = (guid: string): void => {
    switch (returnUrl) {
      case RoutePaths.MonthlyPayments:
      case RoutePaths.News:
      case RoutePaths.Specifications:
      case RoutePaths.Volumes:
        history.push(
          `${RoutePaths.Autologin}/${guid}/${commonUserData.languageId}${returnUrl}`
        )
        break
      default:
        {
          const newsBaseUrl = `${RoutePaths.Autologin}/${guid}/${commonUserData.languageId}/news`

          if (!emailLinkVersion) {
            history.push(RoutePaths.MyPage)
          } else if (emailLinkVersion === 'new') {
            history.push(
              `${newsBaseUrl}?redirectUrl=/articles/${emailArticleId}`
            )
          } else {
            history.push(`${newsBaseUrl}?redirectUrl=/Alerts`)
          }
        }

        break
    }
  }

  const fetchPortalUserData = async (values: LoginRequest): Promise<void> => {
    const loginRequest: LoginObject = {
      username: values.username,
      password: values.password,
      languageId: values.languageId,
      guid: values.guid,
      isJatoNet: false,
      isJatoSpecs: false,
    }

    const portalResponse: PortalLoginResponse | undefined =
      await portalLoginAsync(loginRequest)

    if (portalResponse?.volumesUserState) {
      const data = portalResponse?.volumesUserState

      if (data.isAuthenticated) {
        if (data.licenceStatus === LicenceExpiredStatus) {
          JATOAnnouncement.Create({
            title: '',
            children: (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    translations.JNT_Portal_LicenceExpired_Message.replace(
                      '{0}',
                      data.firstName
                    )
                      .replace('{1}', data.lastName)
                      .replace(
                        '{2}',
                        `<a href='mailto:${salesEnquiryEmail}'>
                          
                          ${salesEnquiryEmail}
                        </a>`
                      ),
                }}
              />
            ),
          })
          return
        }
        signalRService.setUpSignalRConnection(data.userId)
        data.languageId = loginRequest.languageId
        dispatch(
          setCommonUserState({
            ...commonUserData,
            guid: data.guid,
          })
        )

        dispatch(setVolumesUserState(data))

        gtmLogEmailDomain(data.userName)

        if (data.acceptLicence === 0) {
          setShowAcceptTermAndCondition(true)
        } else {
          RedirectToMainSite(data.guid)
        }
      } else {
        if (!data.isAuthenticated && data.responseMessage) {
          showErrorToast('Authentication error', data.responseMessage)
          return
        }
      }
    }
  }
  const onCloseJATOAcceptTermAndCondition = (acceptLicence: boolean): void => {
    setShowAcceptTermAndCondition(false)
    acceptLicence && history.push(RoutePaths.MyPage)
  }

  useEffect(() => {
    if (commonUserData.guid && acceptLicence === 1) {
      const validateUserGuid = async (): Promise<void> => {
        const isValidGuid = await validateUserGuidAsync(commonUserData.guid)
        if (isValidGuid) {
          RedirectToMainSite(commonUserData.guid)
        }
      }
      validateUserGuid()
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      isJatoNet: false,
      guid: activationGuid,
      isJatoSpecs: true,
      languageId: commonUserData.languageId,
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email(translations.JNT_Portal_PopError_InvalidUN)
        .required(translations.JNT_Portal_Form_Field_Required),
      password: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
    }),
    onSubmit: (values: LoginRequest) => fetchPortalUserData(values),
  })
  return (
    <StyledSignIn>
      {isLoadingValidateUserGuid && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <JATOTextInput
          id="username"
          width="100%"
          label={translations.JNT_Portal_Login_Un}
          title={translations.JNT_Portal_Login_Un}
          type="text"
          placeholder={translations.JNT_Portal_Login_Un}
          value={formik.values.username}
          onChange={formik.handleChange}
        />
        {formik.touched.username && formik.errors.username ? (
          <JATOText
            as="span"
            fontSize={JATOTheme.fontSizes[1]}
            color={JATOTheme.colors.primary}
            display="flex"
            marginTop={JATOTheme.space[2]}
          >
            {formik.errors.username}
          </JATOText>
        ) : null}
        <JATOTextInput
          id="password"
          width="100%"
          label={translations.JNT_Portal_Login_Pwd}
          title={translations.JNT_Portal_Login_Pwd}
          type="password"
          placeholder={translations.JNT_Portal_Login_Pwd}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        {formik.touched.password && formik.errors.password ? (
          <JATOText
            as="span"
            fontSize={JATOTheme.fontSizes[1]}
            color={JATOTheme.colors.primary}
            display="flex"
            marginTop={JATOTheme.space[2]}
          >
            {formik.errors.password}
          </JATOText>
        ) : null}

        <JATOLoadingButton
          variant="secondary"
          isLoading={isLoading}
          onClick={formik.submitForm}
        >
          {translations.JNT_Portal_LOG_SignIn}
        </JATOLoadingButton>
      </form>
      <div>
        <JATOLink
          id="signInForgotPasswordLink"
          textTransform="none"
          onClick={() => setModalForgotPassword(true)}
        >
          {translations.JNT_Portal_Login_Forgot}
        </JATOLink>
      </div>
      <div className="SignUp">
        <JATOText as="div" fontWeight="light" fontSize={16}>
          {translations.JNT_Portal_Login_DoYouHaveAccount}
          <JATOLink
            id="signInCustomerRegistrationLink"
            textTransform="none"
            onClick={() => setModalCustomerRegistration(true)}
          >
            {translations.JNT_Portal_Login_Register}
          </JATOLink>
        </JATOText>
      </div>

      <AcceptJATOTermAndCondition
        showAcceptTermAndCondition={showAcceptTermAndCondition}
        onCloseEvent={onCloseJATOAcceptTermAndCondition}
      />
      <CommonModal
        closeButton
        dismissable={false}
        isOpen={modalForgotPassword}
        maxWidth="md"
        onClose={() => setModalForgotPassword(false)}
        title={translations.JNT_Portal_ForgotPass_Title}
      >
        <ForgotPasswordLink onClose={() => setModalForgotPassword(false)} />
      </CommonModal>
      <CommonModal
        closeButton
        dismissable={false}
        isOpen={modalCustomerRegistration}
        maxWidth="lg"
        onClose={() => setModalCustomerRegistration(false)}
        title={translations.JNT_Portal_CustomerRegistration_Heading}
      >
        <CustomerRegistration
          onClose={(showSalesEnquiry?: boolean) => {
            setModalCustomerRegistration(false)
            showSalesEnquiry && setModalSalesEnquiry(true)
          }}
        />
      </CommonModal>
     </StyledSignIn>
  )
}
