import { JATOAcademyPopUp } from 'components/Modals/JATOAcademyPopUp/JATOAcademyPopUp'
import { useUpdateUserSettings } from 'hooks/specsUserSettings'
import { ISpecsUserSettings } from 'models/Specifications/SpecsUserSettings'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getSpecsUserState } from 'redux/specifications/specificationsSlice'
import { StyledSpecsJATOAcademyPopUp } from './SpecsJATOAcademyPopUp.styles'

export const SpecsJATOAcademyPopUp: React.FC = () => {
  const specsUserState = useAppSelector(getSpecsUserState)

  const [showJatoAcademyVideo, setShowJatoAcademyVideo] = useState(
    !specsUserState.settings.hideJATOAcademyPopUp
  )

  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const onCloseJatoAcademyButton = (doNotShowAgain: boolean): void => {
    const updatedSettings: ISpecsUserSettings = {
      ...specsUserState.settings,
      hideJATOAcademyPopUp: true,
    }

    doNotShowAgain && updateUserSettings(updatedSettings)
    setShowJatoAcademyVideo(false)
  }

  return (
    <StyledSpecsJATOAcademyPopUp>
      <JATOAcademyPopUp
        id="specsJatoAcademyPopup"
        showJATOAcademyVideo={showJatoAcademyVideo}
        onCloseJatoAcademyButton={onCloseJatoAcademyButton}
      />
    </StyledSpecsJATOAcademyPopUp>
  )
}
