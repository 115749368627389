import { JATOVerticalSpacer } from '@jato/ui-component-library'
import hideVehicle from 'assets/icons/icon_toggle_vehicle_off.svg'
import displayVehicle from 'assets/icons/icon_toggle_vehicle_on.svg'
import { BuiltOption } from 'models/Specifications/BrochureResponse'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { SpecificationsReportHeaderOptions } from './SpecificationsReportHeaderOptions'

interface IHeaderVehicleProps {
  isBenchmark: boolean
  builtOptions: BuiltOption[]
  setDisplayOptionBuildInfo: (displayOptionBuildInfo: boolean) => void
  displayOptionBuildInfo: boolean
}

export const SpecsHeaderOptionsDisplay: React.FC<IHeaderVehicleProps> = ({
  isBenchmark,
  builtOptions,
  setDisplayOptionBuildInfo,
  displayOptionBuildInfo,
}: IHeaderVehicleProps) => {
  const translations = useAppSelector(getTranslations)

  return (
    <>
      <div
        className="optionsContainer"
        id={isBenchmark ? 'benchmarkVehicleOption' : ''}
      >
        <JATOVerticalSpacer height={10} />
        <div
          id="specsReportHeaderDisplayOptionsButton"
          className="displayOptionBuildInfo"
          onClick={() => {
            setDisplayOptionBuildInfo(!displayOptionBuildInfo)
          }}
        >
          <img
            src={displayOptionBuildInfo ? hideVehicle : displayVehicle}
            width="20"
          />
          <span style={{ paddingLeft: '5px' }}>
            {builtOptions.length > 0
              ? translations.JNT_added
              : translations.JNT_NoBuiltOptions}
          </span>
        </div>
        {builtOptions.length > 0 && displayOptionBuildInfo && (
          <SpecificationsReportHeaderOptions builtOptions={builtOptions} />
        )}
      </div>
    </>
  )
}
