import styled from 'styled-components'

export const StyledMonthlyPaymentsReportHeader = styled.div`
  border-bottom: 1px solid rgb(198, 198, 198);
  .tableStyle {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    table-layout: fixed;
    width: 100%;
    background-color: #ffffff;
    border-spacing: unset;
  }
  .tableRow {
    display: table-row;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  .tableCell {
    display: table-cell;
    box-sizing: border-box;
    padding: 0;
    background-color: #ffffff;
    vertical-align: middle;
    text-align: left;
    width: 220px;
  }

  .tableValue {
    padding: 8px 16px;
  }

  .tableCell:first-child {
    width: 300px;
  }

  .removeVehicle {
    cursor: pointer;
    float: left;
  }

  .viewGallery {
    cursor: pointer;
    padding-left: 10px;
    margin-top: 1px;
    float: left;
  }

  .viewNews {
    cursor: pointer;
    padding-left: 10px;
    float: left;
  }

  .expandMoreScenariosContainer {
    cursor: pointer;
    padding-left: 7px;
    float: left;
  }

  .expandMoreScenarios {
    cursor: pointer;
    padding-left: 2px;
    padding-top: 3px;
    float: left;
  }

  .scenariosError {
    color: #c40005;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 3px;
    float: left;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: red;
  }
`
