import {
  JATOAnnouncement,
  JATOBasicCard,
  JATOLink,
} from '@jato/ui-component-library'
import { queryClient } from 'app'
import { AxiosResponse } from 'axios'
import { LoaderModal } from 'components/Loader/LoaderModal'
import MonthlyPaymentsReport from 'components/MonthlyPayments/MonthlyPaymentsReport'
import { MonthlyPaymentsReportNavigation } from 'components/MonthlyPayments/MonthlyPaymentsReport/MonthlyPaymentsReportNavigation'
import MpFilterContainer from 'components/MonthlyPayments/MpFiltersContainer'
import { MpNavigation } from 'components/MonthlyPayments/MpNavigation/MpNavigation'
import {
  additionalFacetsForSliders,
  defaultMpSearchFilterCriteria,
  facetsForMonthlyPaymentFields,
  mpVehicleSelectionLimit,
} from 'config'
import {
  getMPEmailSubject,
  getMessageBody,
} from 'helper/excelExport/emailHelper'
import { gtmLogMonthlyPaymentsReportView } from 'helper/gtm'
import { filterScenarios } from 'helper/searchQueryHelper'
import { showErrorToast, showInfoToast } from 'helper/toastHelper'
import { LoginResponse } from 'models/Login/LoginResponse'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import { MpVehiclesResponse } from 'models/VehicleSelection/MpVehiclesResponse'
import { IMpVehiclesSearchRequest } from 'models/VehicleSelection/MpVehiclesSearchRequest'
import { ExcelExportMailMessage } from 'models/excelExport/ExcelExportMailRequest'
import { ExcelExportMailResponse } from 'models/excelExport/ExcelExportMailResponse'
import { ExcelExportRequest } from 'models/excelExport/ExcelExportRequest'
import { ExcelExportResponse } from 'models/excelExport/ExcelExportResponse'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  mpVehicles,
  setMpVehicles,
} from 'redux/monthlypayments/mpVehiclesSlice'
import { selectedFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import monthlyPaymentsExcelStyleService from 'services/MonthlyPaymentsExcelReport/MonthlyPaymentsExcelStyleService'
import { MonthlyPaymentsPrepareExcelData } from 'services/MonthlyPaymentsExcelReport/MonthlyPaymentsPrepareExcelData'
import monthlyPaymentsReportService from 'services/MonthlyPaymentsReport/MonthlyPaymentsReportService'
import excelExportService from 'services/common/ExcelExportService'
import { StyledMonthlyPaymentsReport } from './MonthlyPaymentReportContainer.styles'

export const MonthlyPaymentReportContainer: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const mpVehicleData: MpVehicle[] = useAppSelector(mpVehicles)
  const userData: LoginResponse = useAppSelector(selectUserData)
  const selectedFiltersData = useAppSelector(selectedFilters)

  if (mpVehicleData.length === 0) {
    history.push(RoutePaths.MonthlyPayments)
  }

  const uniqueIdentityList = mpVehicleData
    .map((vehicle) => vehicle.vehicleId)
    .sort()

  function addMoreMpVehicles(): void {
    history.push(RoutePaths.MonthlyPayments)
  }

  useEffect(() => {
    gtmLogMonthlyPaymentsReportView(mpVehicleData)
  }, [])

  const mpReportMetaData = queryClient.getQueryData(['mpReportMetaData'])

  const mpVehiclesSearchRequest: IMpVehiclesSearchRequest = {
    select: [],
    search: '*',
    filter: defaultMpSearchFilterCriteria,
    facets: facetsForMonthlyPaymentFields,
    orderBy: [],
    skip: 0,
    top: mpVehicleSelectionLimit,
    country: `${userData.user.settings.lastSelectedMarket?.countryCode}`,
  }

  const { data: mpVehiclesResponse, isFetching } = useQuery<MpVehiclesResponse>(
    ['mpVehiclesResponsev1', uniqueIdentityList],
    async () => {
      if (uniqueIdentityList.length > 0) {
        const pipedUidname = uniqueIdentityList.join('|')
        mpVehiclesSearchRequest.filter = `search.in(vehicleId, '${pipedUidname}', '|') `
      }

      const response = await monthlyPaymentsReportService.getMpVehicleData(
        mpVehiclesSearchRequest
      )

      const facetResponse = await monthlyPaymentsReportService.getMpVehicleData(
        {
          ...mpVehiclesSearchRequest,
          top: 1,
          facets: additionalFacetsForSliders,
        }
      )

      Object.keys(facetResponse.facets).forEach(
        (key) =>
          (response.facets[key] = [
            ...response.facets[key],
            ...facetResponse.facets[key],
          ])
      )

      const missingVehicles = mpVehicleData
        .filter(
          (vehicle) =>
            !response.vehicles.some(
              (v: any) => vehicle.vehicleId === v.vehicleId
            )
        )
        .map((vehicle) => ({ ...vehicle, scenarios: [] }))
      const mergedVehicles = [...response.vehicles, ...missingVehicles]

      return { ...response, vehicles: mergedVehicles }
    },
    {
      onSuccess: (data) => {
        dispatch(setMpVehicles(data.vehicles))
      },
      onError: (error) =>
        showErrorToast(
          translations.JNT_Error,
          (error as any)?.response?.data?.message ?? translations.JNT_Error
        ),
    }
  )

  const filteredScenariosVehicles = mpVehiclesResponse
    ? filterScenarios(mpVehiclesResponse.vehicles, selectedFiltersData)
    : []

  const exportMpReportToExcel = (): void => {
    const hasScenarios = filteredScenariosVehicles.some(
      (v) => v.scenarios.length > 0
    )

    if (!hasScenarios) {
      showInfoToast(
        'Scenarios not found',
        'Matching monthly payment scenarios were not found for the vehicle'
      )
      return
    }

    const mpReportMetaData = queryClient.getQueryData(['mpReportMetaData'])
    const body = MonthlyPaymentsPrepareExcelData(
      filteredScenariosVehicles,
      mpReportMetaData,
      'Monthly Payment - NT'
    )

    const excelExportRequest: ExcelExportRequest = {
      guid: userData.user.guid,
      userId: userData.user.userId,
      userName: userData.user.userName,
      email: userData.user.userName,
      styles: monthlyPaymentsExcelStyleService.styles(
        userData.user.settings.lastSelectedMarket.countryCode
      ),
      body,
      rtlEnabled: false,
    }

    excelExportService
      .microserviceExportjsontoExcel(excelExportRequest)
      .then((excelResponse: AxiosResponse<ExcelExportResponse>) => {
        const notifyTrans = translations.JNT_DownLoad
        const res = notifyTrans.split('.')
        const excelExportMailMessage: ExcelExportMailMessage = {
          mail: {
            to: userData.user.userName,
            subject: getMPEmailSubject(),
            body: getMessageBody(excelResponse.data.url),
          },
        }

        if (excelResponse.data.url !== undefined) {
          excelExportService
            .sendMail(excelExportMailMessage)
            .then(
              (
                excelExportMailResponse: AxiosResponse<ExcelExportMailResponse>
              ) => {
                excelExportMailResponse.status === 200
                  ? JATOAnnouncement.Create({
                      title: '',
                      children: (
                        <div>
                          {res[0]}
                          <JATOLink
                            id="monthlyPaymentsReportDownloadExcelFileLink"
                            style={{ color: 'white', paddingLeft: '10px' }}
                            href={excelResponse.data.url}
                          >
                            {res[1]}
                          </JATOLink>
                        </div>
                      ),
                    })
                  : JATOAnnouncement.Create({
                      title: 'Problem while sending email with excel file!!',
                    })
              }
            )
            .catch((error) => {
              JATOAnnouncement.Create({
                title: `Problem while preparing excel file!! - ${error}`,
              })
            })
        } else {
          JATOAnnouncement.Create({
            title: `Problem while preparing excel file!!`,
          })
        }
      })
      .catch((error) => {
        JATOAnnouncement.Create({
          title: `Problem while preparing excel file!! - ${error}`,
        })
      })
  }

  return (
    <StyledMonthlyPaymentsReport>
      <Container xl lg md sm xs style={{ padding: 0 }}>
        <Row>
          <Col md={3} lg={3} style={{ padding: '0 10px' }}>
            <MpNavigation />
            <div className="scrollable-container">
              <JATOBasicCard>
                <MpFilterContainer
                  metaData={mpReportMetaData}
                  facetsData={mpVehiclesResponse?.facets}
                />
              </JATOBasicCard>
            </div>
          </Col>
          <Col md={9} lg={9} style={{ padding: 0 }}>
            <MonthlyPaymentsReportNavigation
              onExportToExcel={exportMpReportToExcel}
              translations={translations}
              onAddVehicleClick={addMoreMpVehicles}
            />
            {!isFetching && (
              <div className="ej-mpreport-panel">
                <MonthlyPaymentsReport vehicles={filteredScenariosVehicles} />
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <LoaderModal isOpen={isFetching} />
    </StyledMonthlyPaymentsReport>
  )
}
