import {
  JATOButton,
  JATOButtonGroup,
  JATOHeading,
  JATOIcon,
  JATOModal,
  JATOTheme,
} from '@jato/ui-component-library'
import { useUpdateUserSettings } from 'hooks/volumes'
import { VolumesUserSettingsRequest } from 'models/Volumes/VolumesUserSettings'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'

export const VolumesWelcomePopup: React.FC = () => {
  const volumesUserSettings = useAppSelector(getVolumesUserState)
  const translations = useAppSelector(getTranslations)

  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const [showWelcomeInfo, setShowWelcomeInfo] = useState(
    !volumesUserSettings.hideWelcomeInfo
  )

  const onCloseButton = (): void => {
    const updatedSettings: VolumesUserSettingsRequest = {
      languageId: volumesUserSettings.languageId,
      currency: volumesUserSettings.currency,
      hideJATOAcademyPopUp: volumesUserSettings.hideJATOAcademyPopUp,
      hideWelcomeInfo: true,
    }

    updateUserSettings(updatedSettings)

    setShowWelcomeInfo(false)
  }

  return (
    <JATOModal
      isOpen={showWelcomeInfo}
      maxWidth={'sm'}
      dismissable={false}
      header={() => (
        <>
          <JATOHeading
            as="h4"
            fontWeight="medium"
            display="flex"
            color={JATOTheme.colors.blue}
          >
            {translations.JNT_Volumes_WelcomeHelpTitle}
          </JATOHeading>
          <JATOIcon style={{ paddingLeft: '10px' }} iconName="baseline_info" />
        </>
      )}
      footer={() => (
        <>
          <JATOButtonGroup justifyContent={'flex-end'}>
            <JATOButton
              id="volumesWelcomePopupCloseButton"
              variant="primary"
              size="large"
              onClick={onCloseButton}
            >
              {translations.JNT_Close}
            </JATOButton>
          </JATOButtonGroup>
        </>
      )}
    >
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Volumes_WelcomeHelpText1,
          }}
        />
      </p>
      <p>
        <strong>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_Volumes_WelcomeHelpText2,
            }}
          />
        </strong>
      </p>
      <ul>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_Volumes_WelcomeHelpText3,
            }}
          />
        </li>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_Volumes_WelcomeHelpText4,
            }}
          />
        </li>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_Volumes_WelcomeHelpText5,
            }}
          />
        </li>
      </ul>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Volumes_WelcomeHelpText6,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Volumes_WelcomeHelpText7,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Volumes_WelcomeHelpText8,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText9,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText10,
          }}
        />
      </p>
    </JATOModal>
  )
}
