import {
  mpFieldForCurrencyFormatting,
  mpFieldForDateFormatting,
  mpFieldForNumberFormatting,
  mpFieldForPercentageFormatting,
  mpReportSpecsSection,
} from 'config'
import { GroupMetaData } from 'config/GroupMetaData'
import { IMappingObject } from 'helper/IMappingObject'
import { getReferencePrice } from 'helper/getReferencePrice'
import { VehicleHeaderInfo } from 'models/Specifications/GetBuiltVehiclesResponse'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import { ExcelPageDataRequest } from 'models/excelExport/ExcelPageDataRequest'
import moment from 'moment'
import brochureExcelTemplate from './Specifications/brochureExcelTemplate'
import comparisonExcelTemplate from './Specifications/comparisonExcelTemplate'
import optionsExcelTemplate from './Specifications/optionsExcelTemplate'
import {
  BrochureItem,
  BrochureReturnObject,
  BuiltOption,
} from 'models/Specifications/BrochureResponse'
import { GetVehicleComparisonResponse } from 'models/Specifications/GetVehicleComparisonResponse'
import {
  OptionIconType,
  OptionsPageResponse,
} from 'models/Specifications/Options/OptionsPageResponse'

export enum ExcelExportStyle {
  Item = 'item',
  FullyEstimatedItem = 'fullyEstimatedItem',
  PercentageItemFullyEstimated = 'percentageItemFullyEstimated',
  CurrencyItemFullyEstimated = 'currencyItemFullyEstimated',
  PercentageItem = 'percentageItem',
  CurrencyItem = 'currencyItem',
  ItemTitle = 'itemTitle',
  ItemValue = 'itemValue',
  Header = 'header',
  HeaderVersion = 'headerVersion',
  HeaderPrice = 'headerPrice',
}

export enum SpecsExcelStyleType {
  SaveId = 'saveId',
  SaveIdValues = 'saveIdValues',
  Header = 'header',
  HeaderPrice = 'headerPrice',
  ItemTitle = 'itemTitle',
  OptionsRowItem = 'optionsRowItem',
  ItemComparisonTitle = 'itemComparisonTitle',
  Item = 'item',
  OptionsItem = 'optionsItem',
  OptionsItemBold = 'optionsItemBold',
  OptionsItemHighlight = 'optionsItemHighlight',
  PrimaryOption = 'primaryOption',
  SecondaryOption = 'secondaryOption',
  CategoryHeader = 'categoryHeader',
  ItemValue = 'itemValue',
}

export const getItemRow = (value: string, style: string): any => ({
  value: value,
  styleName: style,
})

const getTitles = (
  specFieldNames: string[],
  rowMetaData: GroupMetaData[],
  mpReportMetaData: any
): any => {
  const titleRows = getSpecSectionTitles(specFieldNames, mpReportMetaData)

  for (let i = 0; i < rowMetaData.length; i++) {
    if (rowMetaData[i].isCategoryTitleRow) {
      titleRows.push(
        getItemRow(rowMetaData[i].metadataRowName, ExcelExportStyle.ItemTitle)
      )
    } else {
      if (
        mpReportMetaData.metadata[rowMetaData[i].metadataRowName] !== undefined
      ) {
        titleRows.push(
          getItemRow(
            mpReportMetaData.metadata[rowMetaData[i].metadataRowName].attribute,
            ExcelExportStyle.ItemValue
          )
        )
      } else {
        titleRows.push(
          getItemRow(rowMetaData[i].metadataRowName, ExcelExportStyle.ItemValue)
        )
      }
    }
  }
  return titleRows
}

const getSpecSectionTitles = (
  specFieldNames: string[],
  mpReportMetaData: any
): any => {
  const titleRow = getItemRow(
    mpReportSpecsSection.title,
    ExcelExportStyle.ItemTitle
  )
  const valueRows = specFieldNames.map((name) =>
    getItemRow(
      mpReportMetaData.metadata[name]?.attribute ?? name,
      ExcelExportStyle.ItemValue
    )
  )
  return [titleRow].concat(valueRows)
}

export const getColumnTitles = (
  specFieldNames: string[],
  rowMetaData: GroupMetaData[],
  mpReportMetaData: any
): any => {
  const column = {
    column: 1,
    rows: [
      getItemRow('', ExcelExportStyle.ItemValue),
      getItemRow('', ExcelExportStyle.ItemValue),
      getItemRow('', ExcelExportStyle.ItemValue),
    ],
  }

  Array.prototype.push.apply(
    column.rows,
    getTitles(specFieldNames, rowMetaData, mpReportMetaData)
  )
  return column
}

const getHeaders = (vehicle: MpVehicle): any => [
  getItemRow(`${vehicle.make} ${vehicle.model}`, ExcelExportStyle.Header),
  getItemRow(vehicle.versionName, ExcelExportStyle.HeaderVersion),
  getItemRow(getReferencePrice(vehicle), ExcelExportStyle.HeaderPrice),
]

const getStyle = (
  fieldName: string,
  isFullyEstimatedData: boolean
): ExcelExportStyle => {
  if (mpFieldForPercentageFormatting.includes(fieldName)) {
    return isFullyEstimatedData
      ? ExcelExportStyle.PercentageItemFullyEstimated
      : ExcelExportStyle.PercentageItem
  } else if (mpFieldForCurrencyFormatting.includes(fieldName)) {
    return isFullyEstimatedData
      ? ExcelExportStyle.CurrencyItemFullyEstimated
      : ExcelExportStyle.CurrencyItem
  }
  return isFullyEstimatedData
    ? ExcelExportStyle.FullyEstimatedItem
    : ExcelExportStyle.Item
}

const getAllowedValue = (metadata: any, fieldName: string, value: any): any => {
  if (mpFieldForDateFormatting.includes(fieldName)) {
    return moment(Date.parse(value)).utcOffset(0, true).format('DD-MM-yyyy')
  } else if (mpFieldForPercentageFormatting.includes(fieldName)) {
    return value / 100
  } else if (mpFieldForNumberFormatting.includes(fieldName)) {
    return Number(value)
  }
  return metadata[fieldName]?.allowedValues[value] ?? value
}

const getMonthlyPaymentsDataPerColumn = (
  vehicle: MpVehicle,
  scenarios: any,
  spec: any,
  specFieldNames: string[],
  rowMetaData: GroupMetaData[],
  mpReportMetaData: any
): any => {
  const itemRows = []
  const metadata = mpReportMetaData.metadata

  itemRows.push(getItemRow('', ExcelExportStyle.Item))
  for (let i = 0; i < specFieldNames.length; i++) {
    const fieldName = specFieldNames[i]
    const value = spec[fieldName]
    const allowedValue = getAllowedValue(metadata, fieldName, value)
    const style = getStyle(fieldName, false)

    itemRows.push(getItemRow(allowedValue, style))
  }

  if (rowMetaData.length > 0) {
    for (let i = 0; i < rowMetaData.length; i++) {
      const fieldName = rowMetaData[i].metadataRowName
      const value = scenarios[fieldName]
      const allowedValue = getAllowedValue(metadata, fieldName, value)
      const isFullyEstimatedData = scenarios['dataSource'] === 'F'
      const style = getStyle(fieldName, isFullyEstimatedData)

      itemRows.push(getItemRow(allowedValue, style))
    }
  }

  return itemRows
}

export const getColumnPerCar = (
  columnNumber: number,
  vehicle: MpVehicle,
  scenarios: any,
  spec: any,
  specFieldNames: string[],
  rowsMetaData: GroupMetaData[],
  mpReportMetaData: any
): any => {
  const column = {
    column: columnNumber,
    rows: [],
  }

  Array.prototype.push.apply(column.rows, getHeaders(vehicle))

  Array.prototype.push.apply(
    column.rows,
    getMonthlyPaymentsDataPerColumn(
      vehicle,
      scenarios,
      spec,
      specFieldNames,
      rowsMetaData,
      mpReportMetaData
    )
  )
  return column
}

export const getMaximumRowsLengthForOptionsExcel = (
  vehicles: BrochureReturnObject[] | undefined
): number => {
  let maximumRowsLength = 0
  let primaryOptionInfos = []
  let totalRows = 0
  let i = 0
  let j = 0
  for (i = 0; i < vehicles!.length; i++) {
    primaryOptionInfos = vehicles![i].builtOptions.filter(
      (option: BuiltOption) =>
        option.vehicleId === vehicles![i].vehicleHeaderInfo.vehicleId
    )
    totalRows = primaryOptionInfos.length
    for (j = 0; j < primaryOptionInfos.length; j++) {
      totalRows = totalRows + primaryOptionInfos[j].secondaryOptions.length
    }
    maximumRowsLength =
      maximumRowsLength > totalRows ? maximumRowsLength : totalRows
  }
  return maximumRowsLength
}

const schemaFilterDataValue = (
  vehicleHeaderInfo: VehicleHeaderInfo,
  schemaId: any
): any => {
  const index = vehicleHeaderInfo.vehicleHeaders
    .map((v: any) => v.schemaId)
    .indexOf(schemaId)
  if (index > -1) {
    return vehicleHeaderInfo.vehicleHeaders[index].dataValue
  }
}

export const createCategoryRowList = (advantageData: any): any => {
  let categoryList: any[] = []
  const vehicleList = Object.keys(advantageData)
  for (let i = 0; i < vehicleList.length; i++) {
    const advantage = advantageData[vehicleList[i]]
    const cat = Object.keys(advantage)
    categoryList = categoryList.concat(cat)
  }

  const uniqueItems = Array.from(new Set(categoryList))
  uniqueItems.sort(function (a, b) {
    return a - b
  })
  // find maximum number of rows in each category
  const categoryRowList = []
  for (let t = 0; t < uniqueItems.length; t++) {
    let catCount = 0
    let categoryName = ''
    for (let i = 0; i < vehicleList.length; i++) {
      const advantage = advantageData[vehicleList[i]]
      const checkKeys = Object.keys(advantage)
      if (checkKeys.indexOf(uniqueItems[t]) !== -1) {
        const cat = advantage[uniqueItems[t]]
        if (cat.length > catCount) {
          catCount = cat.length
          categoryName = advantage[uniqueItems[t]][0].categoryName
        }
      }
    }
    categoryRowList.push({
      catId: uniqueItems[t],
      categoryName: categoryName,
      catRows: catCount,
    })
  }
  return categoryRowList
}

const createNewlyFormattedData = (
  categoryRowList: any,
  advantageData: GetVehicleComparisonResponse
): any => {
  const formattedDictionary: any = {}
  const vehicleList = Object.keys(advantageData)
  for (let i = 0; i < vehicleList.length; i++) {
    const categoryDictionary: any = {}
    for (let u = 0; u < categoryRowList.length; u++) {
      const defaultObject: any = {
        keyAdvantageText: '',
        isBenchmark: false,
        categoryName: '',
        optionCode: null,
        optionName: null,
        hoverOverText: null,
        isAdvantageFromOption: false,
        isBuildableOption: false,
        optionId: 0,
        recordId: 0,
        isPresent: true,
        conFormat: '',
        conSchemaIdVal: '',
        advantageRule: '',
      }
      const defaultRows: any = addCategoryRow(
        defaultObject,
        categoryRowList[u].catRows,
        categoryRowList[u].categoryName
      )
      categoryDictionary[categoryRowList[u].catId] = defaultRows
    }
    formattedDictionary[vehicleList[i]] = categoryDictionary
  }
  for (let i = 0; i < vehicleList.length; i++) {
    const newAdvantageData = formattedDictionary[vehicleList[i]]
    const validCategoryKeys = Object.keys(advantageData[vehicleList[i]])
    for (let b = 0; b < categoryRowList.length; b++) {
      if (validCategoryKeys.indexOf(categoryRowList[b].catId) !== -1) {
        const newCategoryList = []
        for (
          let d = 0;
          d < advantageData[vehicleList[i]][categoryRowList[b].catId].length;
          d++
        ) {
          newCategoryList.push(
            advantageData[vehicleList[i]][categoryRowList[b].catId][d]
          )
        }
        newAdvantageData[categoryRowList[b].catId] = newCategoryList
        const defaultObject: any = {
          keyAdvantageText: '',
          isBenchmark: false,
          categoryName: '',
          optionCode: null,
          optionName: null,
          hoverOverText: null,
          isAdvantageFromOption: false,
          isBuildableOption: false,
          optionId: 0,
          recordId: 0,
          isPresent: true,
          conFormat: '',
          conSchemaIdVal: '',
          advantageRule: '',
        }
        newAdvantageData[categoryRowList[b].catId] = newCategoryList.concat(
          addCategoryRow(
            defaultObject,
            categoryRowList[b].catRows - newCategoryList.length,
            categoryRowList[b].categoryName
          )
        )
      }
    }
  }

  return formattedDictionary
}

const addCategoryRow = (
  comparisonObject: any,
  catRowsNumber: any,
  categoryName: any
): any => {
  let i = 0
  const keyAdvantageList = []
  while (i < catRowsNumber) {
    comparisonObject.categoryName = categoryName
    keyAdvantageList.push(comparisonObject)
    i++
  }
  return keyAdvantageList
}

export const formatKeyAdvantageData = (
  comparisonData: GetVehicleComparisonResponse,
  builtVehicles: BrochureReturnObject[]
): any => {
  const keyAdvantages = createNewlyFormattedData(
    createCategoryRowList(comparisonData),
    comparisonData
  )
  const comparisonVehicles: any[] = []
  builtVehicles.forEach((vehicle: BrochureReturnObject) => {
    const newVehicle: any = { ...vehicle }
    if (newVehicle) {
      newVehicle.vehicleHeaderInfo = vehicle.vehicleHeaderInfo
    }

    const keyAdvData = keyAdvantages[vehicle.vehicleHeaderInfo.vehicleId]
    if (keyAdvData) {
      newVehicle.keyAdvData = keyAdvantages[vehicle.vehicleHeaderInfo.vehicleId]
      comparisonVehicles.push(newVehicle)
    }
  })

  return comparisonVehicles
}

export const getOptionsExcelDataForAllOptionTypes = (
  data: ExcelPageDataRequest,
  optionsMappingObject: IMappingObject<OptionsPageResponse>,
  translations: AppTextTranslations,
  consolidatedBody: any
): any => {
  Object.keys(optionsMappingObject).forEach((optionName: string) => {
    const optionsItems: OptionsPageResponse = optionsMappingObject[optionName]
    const optionsData = optionsExcelTemplate.excelOptionsBody(
      translations[optionName],
      optionsItems,
      data,
      translations
    )
    if (optionsData.length > 0 && optionsData[0].table.length > 0) {
      consolidatedBody.push(optionsData[0])
    }
  })

  return consolidatedBody
}

export const getVehicleComparisonExcelData = (
  data: ExcelPageDataRequest,
  comparisonDataMappingObject: IMappingObject<GetVehicleComparisonResponse>,
  translations: AppTextTranslations,
  consolidatedBody: any
): any => {
  Object.keys(comparisonDataMappingObject).forEach(
    (comparisonTypeName: string) => {
      const comparisonData: GetVehicleComparisonResponse =
        comparisonDataMappingObject[comparisonTypeName]
      data.comparisonDataList = formatKeyAdvantageData(
        comparisonData,
        data.brochureResponse!.brochureVehicles.filter(
          (brochureReturnObject: BrochureReturnObject) =>
            brochureReturnObject.vehicleHeaderInfo.vehicleId !==
            data.benchmarkVehicleId
        )
      )

      const comparisonResult =
        comparisonExcelTemplate.excelVehiclesComparisonBody(
          translations[comparisonTypeName],
          data,
          translations
        )

      if (comparisonResult.length > 0 && comparisonResult[0].table.length > 0) {
        consolidatedBody.push(comparisonResult[0])
      }
    }
  )

  return consolidatedBody
}

export const getExcelVehicleName = (
  vehicleHeaderInfo: VehicleHeaderInfo,
  isLocal: boolean
): string => {
  const make = isLocal
    ? schemaFilterDataValue(vehicleHeaderInfo, 128)
    : schemaFilterDataValue(vehicleHeaderInfo, 111)
  const model = isLocal
    ? schemaFilterDataValue(vehicleHeaderInfo, 129)
    : schemaFilterDataValue(vehicleHeaderInfo, 112)
  const version = isLocal
    ? schemaFilterDataValue(vehicleHeaderInfo, 131)
    : schemaFilterDataValue(vehicleHeaderInfo, 302)
  const modelYear = schemaFilterDataValue(vehicleHeaderInfo, 108)

  return `${make} ${model} - ${modelYear} ${version}`
}

export const getSpecsHeader = (
  vehicleName: string,
  displayPrice: string
): any => [
  getItemRow(vehicleName, SpecsExcelStyleType.Header),
  getItemRow(displayPrice, SpecsExcelStyleType.HeaderPrice),
]

export const getAllConsolidatedDataForExcel = (
  data: ExcelPageDataRequest,
  optionsMappingObject: IMappingObject<OptionsPageResponse>,
  comparisonMappingObject: IMappingObject<GetVehicleComparisonResponse>,
  translations: AppTextTranslations
): any => {
  let consolidatedBody = brochureExcelTemplate.excelBrochureBody(
    data,
    translations
  )

  consolidatedBody = getOptionsExcelDataForAllOptionTypes(
    data,
    optionsMappingObject,
    translations,
    consolidatedBody
  )

  consolidatedBody = getVehicleComparisonExcelData(
    data,
    comparisonMappingObject,
    translations,
    consolidatedBody
  )

  return consolidatedBody
}

export const getDefaultTextForBrochure = (
  item: BrochureItem,
  translations: AppTextTranslations
): string | undefined => {
  if (item.dataValue == '-') return translations.JNT_notavailable

  if (
    item.conFormat == 'A' ||
    item.conFormat == '@' ||
    item.conFormat == 'A|S'
  ) {
    if (!item.dataValue) return translations.JNT_notavailable
    if (item.optionId === 0) return translations.JNT_standard
    if (item.hasBuildableOption) return translations.JNT_option
    if (item.isAddedByOption) return translations.JNT_added
  }
}

export const getDefaultOptionText = (
  status: OptionIconType,
  translations: AppTextTranslations
): string => {
  switch (status) {
    case OptionIconType.Standard:
      return translations.JNT_standard
    case OptionIconType.Unavailable:
      return translations.JNT_notavailable
    case OptionIconType.Option:
      return translations.JNT_option
    case OptionIconType.AddedBuilt:
      return translations.JNT_added
    case OptionIconType.ExcludedOption:
      return translations.JNT_ExcOption
    default:
      return translations.JNT_notavailable
  }
}
