import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { NewsBreadcrumbsItem } from 'models/News/NewsBreadcrumbsItem'
import { NewsPage, getNewsPageTranslations } from 'models/News/NewsPageType'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'

interface INewsBreadcrumbsProps {
  title: string
  newsPage: NewsPage
}

export const NewsArticleBreadcrumbs: React.FC<INewsBreadcrumbsProps> = ({
  title,
  newsPage,
}: INewsBreadcrumbsProps) => {
  const location = useLocation()
  const translations = useAppSelector(getTranslations)
  const newsPageTranslations = getNewsPageTranslations(translations)
  const parentPageTitle = newsPageTranslations[newsPage] ?? newsPage

  const breadcrumbsItemList: NewsBreadcrumbsItem[] = [
    {
      iconName: 'home_outline',
      title: `JATO ${translations.JNT_Nav_News}`,
      url: RoutePaths.News,
    },
    {
      title: parentPageTitle,
      url: `${RoutePaths.News}/${newsPage}`,
    },
    { title: title, url: location.pathname },
  ]

  return <Breadcrumbs breadcrumbItemList={breadcrumbsItemList} />
}
