import { JATOToggleSwitch } from '@jato/ui-component-library'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useUpdateSearchAndAlert } from 'hooks/news'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsAlertStatusToggleButton } from './NewsAlertStatusToggleButton.styles'

export interface INewsAlertStatusToggleButtonProps {
  searchRequest: SearchIndexRequest
}

export const NewsAlertStatusToggleButton: React.FC<
  INewsAlertStatusToggleButtonProps
> = ({ searchRequest }: INewsAlertStatusToggleButtonProps) => {
  const translations = useAppSelector(getTranslations)
  const { mutateAsync: updateSearchAndAlert } = useUpdateSearchAndAlert()

  const onStatusChangeClick = async (): Promise<void> => {
    const response = await updateSearchAndAlert({
      ...searchRequest,
      isSubscribed: !searchRequest.isSubscribed,
    })

    if (response.isSuccess) {
      showSuccessToast(
        translations.JNT_Success,
        searchRequest.isSubscribed
          ? translations.JNT_NewsAlertDeactivated
          : translations.JNT_NewsAlertActivated
      )
    } else {
      showErrorToast(translations.JNT_Error, translations.JNT_Favourites_Error)
    }
  }

  return (
    <StyledNewsAlertStatusToggleButton>
      <JATOToggleSwitch
        id={'newsAlertStatusToggle_' + searchRequest.id}
        label={translations.JNT_Status}
        defaultChecked={searchRequest.isSubscribed}
        onChange={onStatusChangeClick}
        switchColor={'#1677FF'}
      >
        {''}
      </JATOToggleSwitch>
    </StyledNewsAlertStatusToggleButton>
  )
}
