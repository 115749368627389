import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsAlertActions } from './NewsAlertActions.styles'
import { NewsAlertEditButton } from './NewsAlertEditButton'
import { NewsSearchAlertRemoveButton } from './NewsSearchAlertRemoveButton'

export interface INewsAlertActionsProps {
  searchRequest: SearchIndexRequest
}

export const NewsAlertActions: React.FC<INewsAlertActionsProps> = ({
  searchRequest,
}) => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledNewsAlertActions>
      <NewsAlertEditButton searchRequest={searchRequest} />
      <NewsSearchAlertRemoveButton
        searchAlert={searchRequest}
        removeMessage={translations.JNT_RemoveAlertMessage}
      />
    </StyledNewsAlertActions>
  )
}
