import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesMyCountryGrouping = styled.div`
  background-color: ${JATOTheme.colors.white};
  margin: 0 -15px;

  .volumesContainer {
    height: calc(100vh - 190px);
    overflow-y: auto;
    padding: 30px;
    padding-bottom: 0;
  }

  .headerPanel {
    background-color: ${JATOTheme.colors.midOffGrey};
    padding: 8px 0 0 24px;
    justify-content: space-between;
  }
`
