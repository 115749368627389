import React from 'react'
import { StyledFlag } from './Flag.styles'

interface IFlagProps {
  countryCode?: string
  countryDescription?: string
  countryFlagPath?: string
}

export const Flag: React.FC<IFlagProps> = ({
  countryCode,
  countryDescription,
  countryFlagPath,
}: IFlagProps) => {
  let countryFlagUrl = ''
  if (countryCode) {
    countryFlagUrl = `https://sslphotos.jato.com/Flags/${countryCode}.png`
  } else if (countryFlagPath) {
    countryFlagUrl = countryFlagPath
  }

  return (
    <StyledFlag>
      <span>
        <img
          src={countryFlagUrl}
          alt={countryDescription}
          title={countryDescription}
        />
      </span>
      {countryDescription ? (
        <span style={{ paddingLeft: '8px' }}>{countryDescription}</span>
      ) : (
        <></>
      )}
    </StyledFlag>
  )
}
