import { JATOTooltip } from '@jato/ui-component-library'
import { Accordion } from 'components/Accordion/Accordion'
import { AccordionToggle } from 'components/AccordionToggle/AccordionToggle'
import { InfoPopup } from 'components/Modals/InfoPopup/InfoPopup'
import { removeDuplicates } from 'helper/arrayHelper'
import { getCharacteristics } from 'helper/carSpecsHelper'
import { mapIcon } from 'helper/specificationsBuildRequestHelper'
import { useGetBrochureVehicles } from 'hooks/carSpecs'
import { InfoPopupType } from 'models/InfoPopup/InfoPopup'
import { BrochureItem } from 'models/Specifications/BrochureResponse'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import { SpecificationsOptionBuildButton } from '../SpecificationsOptions/SpecificationsOptionBuildButton'
import { SpecificationsDataDefinitionTooltip } from './SpecificationsDataDefinitionTooltip'
import { StyledSpecificationsReportBody } from './SpecificationsReportBody.styles'
import { WltpSection } from './WltpSection'

export const SpecificationsReportBody: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const { data: brochureResponse } = useGetBrochureVehicles()

  const getCategories = (): string[] =>
    brochureResponse?.uniqueCategories.map((c) => c.translatedCategoryName) ??
    []

  const getBrochureItems = (
    categoryName: string,
    characteristicName: string
  ): BrochureItem[][] =>
    brochureResponse?.brochureVehicles.map((v) =>
      v.brochureRows
        .filter(
          (br) =>
            br.translatedCategoryName == categoryName &&
            br.ebrochureText == characteristicName
        )
        .toSorted((a, b) => a.lineOrderId - b.lineOrderId)
    ) ?? []

  const getFormattedValues = (brochureItems: BrochureItem[]): JSX.Element[] =>
    brochureItems.map((item) => renderFormattedValue(item))

  const renderFormattedValue = (item: BrochureItem): JSX.Element => {
    const iconSrc = mapIcon(item)
    return (
      <span key={uuid()}>
        {iconSrc ? (
          item.hasBuildableOption || item.isAddedByOption ? (
            <SpecificationsOptionBuildButton
              categoryName={item.translatedCategoryName}
              optionId={item.optionId}
              vehicleHeader={brochureResponse?.brochureVehicles
                .map((v) => v.vehicleHeaderInfo)
                .find((v) => v.vehicleId == item.vehicleId)}
              iconSrc={iconSrc}
              addedAsOption={item.isAddedByOption}
            />
          ) : (
            <img src={iconSrc} />
          )
        ) : (
          item.dataValue
        )}
        {item.conText}
      </span>
    )
  }

  const getSchemaIds = (
    categoryName: string,
    characteristicName: string
  ): number[] =>
    brochureResponse?.brochureVehicles[0].brochureRows
      .filter(
        (br) =>
          br.translatedCategoryName == categoryName &&
          br.ebrochureText == characteristicName
      )
      .map((br) => br.schemaId)
      .filter(removeDuplicates) ?? []

  const [openStatus, setOpenStatus] = useState(false)
  const [selectedCharacteristic, setSelectedCharacteristic] = useState<string>()

  return (
    <StyledSpecificationsReportBody>
      <AccordionToggle
        id="specsReportAccordionToggle"
        titleText={translations.JNT_Brochure}
        isOpen={openStatus}
        titleHeaderWidth="72vw"
        onClick={() => setOpenStatus(!openStatus)}
      />
      <WltpSection />
      {getCategories().map((categoryName: string, index) => (
        <Accordion
          id="specsReportAccordion"
          titleText={categoryName}
          key={index}
          isOpen={openStatus}
          titleHeaderWidth="72vw"
        >
          <table className="tableStyle" key={index}>
            <tbody>
              {getCharacteristics(
                categoryName,
                brochureResponse?.brochureVehicles[0]
              ).map((characteristicName, index) => (
                <tr className="tableRow" key={index}>
                  <td key={index} className="tableCell sticky">
                    <JATOTooltip
                      className="tooltipDataDefinition"
                      showOnlyOnOverflow={false}
                      trigger="click"
                      placement="top-start"
                      interactive={true}
                      textAlign="left"
                      offset={[300, -20]}
                      maxWidth={600}
                      title={
                        selectedCharacteristic === characteristicName && (
                          <SpecificationsDataDefinitionTooltip
                            schemaIds={getSchemaIds(
                              categoryName,
                              characteristicName
                            )}
                          />
                        )
                      }
                    >
                      <div
                        id="specsReportDataDefinitionTooltip"
                        className="characteristic"
                        title={translations.JNT_Specs_ClickForDefinition}
                        onClick={() =>
                          setSelectedCharacteristic(characteristicName)
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: characteristicName,
                          }}
                        />
                      </div>
                    </JATOTooltip>
                  </td>
                  {getBrochureItems(categoryName, characteristicName).map(
                    (brochureItems, idx) => (
                      <td key={idx} className="tableCell">
                        <div className="tableValue">
                          {getFormattedValues(brochureItems)}
                        </div>
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Accordion>
      ))}
      <InfoPopup
        id="specsReportShowroomInfoPopup"
        infoPopupType={InfoPopupType.Showroom}
      />
    </StyledSpecificationsReportBody>
  )
}
