import styled from 'styled-components'

export const StyledVolumesReportActions = styled.div`
  .dropdown {
    -webkit-animation: EbufM 0.15s ease-out;
    -webkit-animation: EbufM 0.15s ease-out;
    animation: EbufM 0.15s ease-out;
    display: none;
    display: block;
    width: 125%;
  }

  .dropdownText {
    color: #1677ff;
  }
`
