import {
  getWltpBrochureDataForExcel,
  getWltpItems,
  getWltpTitlesForExcel,
  getWltpVehicleData,
  WltpVehicleItem,
} from 'helper/specificationsBuildRequestHelper'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { ExcelPageDataRequest } from 'models/excelExport/ExcelPageDataRequest'
import {
  SpecsExcelStyleType,
  getDefaultTextForBrochure,
  getExcelVehicleName,
  getItemRow,
  getMaximumRowsLengthForOptionsExcel,
  getSpecsHeader,
} from '../excelExportHelper'
import {
  BrochureItem,
  BrochureResponse,
  BrochureReturnObject,
  BuiltOption,
  OrderedCategory,
} from 'models/Specifications/BrochureResponse'
import { getCharacteristics } from 'helper/carSpecsHelper'

class BrochureExcelTemplate {
  private getOptionRows = (
    maximumOptionRows: any,
    builtOptionsTitle: any
  ): any => {
    const optionRows = []
    // let i;
    if (maximumOptionRows > 0) {
      optionRows.push(
        getItemRow(builtOptionsTitle, SpecsExcelStyleType.CategoryHeader)
      )

      for (let i = 0; i < maximumOptionRows; i++) {
        optionRows.push(getItemRow('', ''))
      }
    }
    return optionRows
  }

  private getTitles = (brochureResponse: BrochureResponse | undefined): any => {
    const titleRows = []

    for (let i = 0; i < brochureResponse!.uniqueCategories.length; i++) {
      titleRows.push(
        getItemRow(
          brochureResponse!.uniqueCategories[i].translatedCategoryName,
          SpecsExcelStyleType.ItemTitle
        )
      )

      getCharacteristics(
        brochureResponse!.uniqueCategories[i].translatedCategoryName,
        brochureResponse!.brochureVehicles[0]
      ).map((characteristicName: string) =>
        titleRows.push(
          getItemRow(
            characteristicName
              .replace('<sub>', '')
              .replace('</sub>', '')
              .replace('<sup>', '')
              .replace('</sup>', ''),
            SpecsExcelStyleType.ItemValue
          )
        )
      )
    }

    return titleRows
  }

  private getColumnTitles = (
    maximumOptionRows: any,
    pageDataRequest: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    const column = {
      column: 1,
      rows: [
        getItemRow('', SpecsExcelStyleType.ItemValue),
        getItemRow('', SpecsExcelStyleType.ItemValue),
      ],
    }
    Array.prototype.push.apply(
      column.rows,
      this.getOptionRows(maximumOptionRows, translations.JNT_added)
    )

    // before adding the titles we need to know if there are values to display for that section

    // WLTP section
    if (pageDataRequest.wltpBrochureItems.length > 0) {
      const wltpItems: WltpVehicleItem[] = getWltpItems(
        getWltpVehicleData(
          pageDataRequest.brochureResponse!.brochureVehicles,
          pageDataRequest.wltpBrochureItems
        )
      )
      Array.prototype.push.apply(
        column.rows,
        getWltpTitlesForExcel(wltpItems[0], translations)
      )
    }

    Array.prototype.push.apply(
      column.rows,
      this.getTitles(pageDataRequest.brochureResponse)
    )
    return column
  }

  private getOptions = (
    maximumOptionRows: any,
    vehicleId: any,
    buildOptionList: BuiltOption[]
  ): any => {
    const optionRows = []
    let primaryOptionInfos
    let secondaryOptionInfos
    let numberOfOptionPerVehicle = 0
    let difference
    if (maximumOptionRows > 0) {
      optionRows.push(getItemRow('', ''))

      primaryOptionInfos = buildOptionList.filter(
        (vehicle: BuiltOption) => vehicle.vehicleId === vehicleId
      )
      for (let i = 0; i < primaryOptionInfos.length; i++) {
        numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
        optionRows.push(
          getItemRow(
            `[${primaryOptionInfos[i].optionCode}] ${primaryOptionInfos[i].optionName} ${primaryOptionInfos[i].displayPrice}`
              .replace('\r', '')
              .replace('\n', ''),
            SpecsExcelStyleType.PrimaryOption
          )
        )
        secondaryOptionInfos = primaryOptionInfos[i].secondaryOptions
        for (let j = 0; j < secondaryOptionInfos.length; j++) {
          numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
          optionRows.push(
            getItemRow(
              `[${secondaryOptionInfos[j].optionCode}] ${secondaryOptionInfos[j].optionName} ${secondaryOptionInfos[j].displayPrice}`
                .replace('\r', '')
                .replace('\n', ''),
              SpecsExcelStyleType.SecondaryOption
            )
          )
        }
      }
      if (numberOfOptionPerVehicle < maximumOptionRows) {
        difference = maximumOptionRows - numberOfOptionPerVehicle
        for (let i = 0; i < difference; i++) {
          optionRows.push(getItemRow('', ''))
        }
      }
    }
    return optionRows
  }

  private getBrochure = (
    uniqueCategories: OrderedCategory[],
    brochureReturnObject: BrochureReturnObject | undefined,
    translations: AppTextTranslations
  ): any => {
    const itemRows: any[] = []
    for (let i = 0; i < uniqueCategories.length; i++) {
      itemRows.push(getItemRow('', SpecsExcelStyleType.ItemTitle))

      getCharacteristics(
        uniqueCategories[i].translatedCategoryName,
        brochureReturnObject
      ).map((characteristicName: string) => {
        const brochureItemRows: string[] = []
        brochureReturnObject!.brochureRows
          .filter(
            (br) =>
              br.translatedCategoryName ==
                uniqueCategories[i].translatedCategoryName &&
              br.ebrochureText == characteristicName
          )
          .toSorted((a, b) => a.lineOrderId - b.lineOrderId)
          .map((brochureItem: BrochureItem) => {
            const brochureText =
              getDefaultTextForBrochure(brochureItem, translations) ??
              `${brochureItem.dataValue} `
            brochureItemRows.push(`${brochureText} ${brochureItem.conText}`)
          })
        itemRows.push(
          getItemRow(brochureItemRows.join(''), SpecsExcelStyleType.Item)
        )
      })
    }
    return itemRows
  }

  private getColumnPerCar = (
    columnNumber: number,
    maximumOptionRows: number,
    vehicleIndex: number,
    pageDataRequest: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    const column = {
      column: columnNumber,
      rows: [],
    }

    const brochureReturnObject: BrochureReturnObject | undefined =
      pageDataRequest.brochureResponse?.brochureVehicles[vehicleIndex]

    Array.prototype.push.apply(
      column.rows,
      getSpecsHeader(
        getExcelVehicleName(
          brochureReturnObject!.vehicleHeaderInfo,
          pageDataRequest.isLocal
        ),
        brochureReturnObject!.vehicleHeaderInfo.displayPrice
      )
    )

    Array.prototype.push.apply(
      column.rows,
      this.getOptions(
        maximumOptionRows,
        brochureReturnObject!.vehicleHeaderInfo.vehicleId,
        pageDataRequest.buildOptionList
      )
    )

    // wltp values retrieved.
    if (pageDataRequest.wltpBrochureItems.length > 0) {
      const wltpIVehicleItems: WltpVehicleItem[] = getWltpItems(
        getWltpVehicleData(
          pageDataRequest.brochureResponse!.brochureVehicles,
          pageDataRequest.wltpBrochureItems
        )
      )
      Array.prototype.push.apply(
        column.rows,
        getWltpBrochureDataForExcel(
          vehicleIndex,
          wltpIVehicleItems,
          translations
        )
      )
    }

    Array.prototype.push.apply(
      column.rows,
      this.getBrochure(
        pageDataRequest.brochureResponse!.uniqueCategories,
        brochureReturnObject,
        translations
      )
    )

    return column
  }

  public excelBrochureBody = (
    data: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    let i
    const table: any[] = []
    const body = {
      sheetName: translations.JNT_Brochure,
      table: table,
    }
    const maximumOptionRows = getMaximumRowsLengthForOptionsExcel(
      data.brochureResponse?.brochureVehicles
    )
    let columnNumber

    // only rows without the wltp values
    body.table.push(this.getColumnTitles(maximumOptionRows, data, translations))

    for (i = 0; i < data.brochureResponse!.brochureVehicles.length; i++) {
      columnNumber = i + 2

      body.table.push(
        this.getColumnPerCar(
          columnNumber,
          maximumOptionRows,
          i,
          data,
          translations
        )
      )
    }

    return [body]
  }
}

const brochureExcelTemplate = new BrochureExcelTemplate()
export default brochureExcelTemplate
