import { queryClient } from 'app'
import { IMappingObject } from 'helper/IMappingObject'
import { getVehicleComparisonRequest } from 'helper/carSpecsHelper'
import specsExcelStyles from 'helper/excelExport/Specifications/specsExcelStyles'
import {
  getMessageBody,
  getSpecsEmailSubject,
} from 'helper/excelExport/emailHelper'
import { getAllConsolidatedDataForExcel } from 'helper/excelExport/excelExportHelper'
import { gtmLogSpecsOptionBuild, gtmLogSpecsOptionUnbuild } from 'helper/gtm'
import { getSiteDirectionForMarket } from 'helper/rtlHelper'
import {
  getBuildOptionListForExcel,
  getWltpRequestObject,
  sortVehicles,
} from 'helper/specificationsBuildRequestHelper'
import {
  AdvanceFiltersRequest,
  IOptionFilterType,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import {
  AdvanceFilterInfoObject,
  FilterValue,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersResponse'
import { BrochureResponse } from 'models/Specifications/BrochureResponse'

import { GetVehicleComparisonResponse } from 'models/Specifications/GetVehicleComparisonResponse'
import { WltpRequest, WltpResponse } from 'models/Specifications/GetWltpRequest'
import {
  DetailedAction,
  InstructionType,
} from 'models/Specifications/Options/DetailedAction'
import { OptionsBuildRuleResponse } from 'models/Specifications/Options/OptionsBuildRuleResponse'
import { OptionsPageResponse } from 'models/Specifications/Options/OptionsPageResponse'
import { OptionsBuildRuleRequest } from 'models/Specifications/Options/optionsBuildRuleRequest'
import { VehicleBrochureRequest } from 'models/Specifications/VehicleBrochureRequest'
import { ExcelExportMailMessage } from 'models/excelExport/ExcelExportMailRequest'
import { ExcelExportRequest } from 'models/excelExport/ExcelExportRequest'
import {
  ExcelExportResult,
  getErrorResult,
  getSuccessResult,
} from 'models/excelExport/ExcelExportResult'
import { ExcelPageDataRequest } from 'models/excelExport/ExcelPageDataRequest'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query'
import { useDispatch } from 'react-redux'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import {
  getCombinedActiveFilters,
  getSpecsUserState,
  getSpecsVehicles,
  resetFiltersToDefaults,
  resetSpecsWltpItems,
  resetVehicles,
  setSpecsWltpItems,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { ReportPageType } from 'router/RoutePaths'
import newCarSpecsService from 'services/Specifications/CarSpecsService'
import excelExportService from 'services/common/ExcelExportService'

export const GetBrochureVehiclesQueryKey = 'getBrochureVehicles'
export const GetOptionBuildRulesQueryKey = 'getOptionBuildRulesQuery'
export const GetOptionalsQueryKey = 'getOptionalsQuery'
export const GetAdvantagesQueryKey = 'fetchComparisonAdvantages'
export const GetDisadvantagesQueryKey = 'fetchComparisonDisadvantages'
export const GetRecipesQueryKey = 'getRecipes'

export const useGetAdvanceFilterList = (): UseQueryResult<
  AdvanceFilterInfoObject[]
> => {
  const specsUserData = useAppSelector(getSpecsUserState)

  return useQuery(
    ['getAdvanceFiltersList', specsUserData.jatoCompanyId],
    async () => {
      const response = await newCarSpecsService.getAdvanceFilterList({
        companyId: specsUserData.jatoCompanyId,
        defaultLanguage: specsUserData.languageId,
      })

      return response.filterList
    }
  )
}

export const useGetAdvanceFilterAllowedValueList = (
  filterInfo: AdvanceFilterInfoObject,
  optionFilterType: IOptionFilterType
): UseQueryResult<FilterValue[]> => {
  const specsUserState = useAppSelector(getSpecsUserState)
  const combinedAdvancedFilters = useAppSelector(getCombinedActiveFilters)
  const activeFilters = combinedAdvancedFilters.filter(
    (f) => f.filterName !== filterInfo.applicationText
  )

  const advancedFilterRequest: AdvanceFiltersRequest = {
    companyId: specsUserState.jatoCompanyId,
    languageId: specsUserState.languageId,
    specsDatabase: [
      { specsDbName: specsUserState.settings.lastSelectedMarket },
    ],
    activeFilters: activeFilters,
    optionsFilterType: optionFilterType.value,
    selectedFilterItems: [filterInfo],
    isFilterVehicle: false,
    userSettings: specsUserState.settings,
  }

  return useQuery<FilterValue[]>(
    ['getAllowedFilterValues', filterInfo, optionFilterType, activeFilters],
    async () => {
      const response = await newCarSpecsService.getAdvanceFilterInfo(
        advancedFilterRequest
      )

      return response?.filterValues[0]?.filterValues
    }
  )
}

export const useGetBrochureVehicles = (): UseQueryResult<
  BrochureResponse,
  unknown
> => {
  const specsVehicles = useAppSelector(getSpecsVehicles)
  const specsUserData = useAppSelector(getSpecsUserState)
  const databaseName = specsUserData?.settings?.lastSelectedMarket ?? ''
  const { selectedVehicles, benchmarkVehicleId } = specsVehicles

  const request: VehicleBrochureRequest[] = selectedVehicles
    .map((v) => ({
      isBenchmark: benchmarkVehicleId == v.vehicleId,
      vehicleId: v.vehicleId,
      specsDatabase: databaseName,
    }))
    .toSorted(
      (a, b) =>
        (b.vehicleId == benchmarkVehicleId ? 1 : 0) -
        (a.vehicleId == benchmarkVehicleId ? 1 : 0)
    )

  return useQuery<BrochureResponse>(
    [GetBrochureVehiclesQueryKey, request],
    async (): Promise<BrochureResponse> => {
      const response = await newCarSpecsService.getBrochure(request)
      return {
        ...response,
        brochureVehicles: sortVehicles(
          response.brochureVehicles,
          (x) => x.vehicleHeaderInfo.vehicleId,
          benchmarkVehicleId
        ),
      }
    },
    { enabled: selectedVehicles.length > 0 }
  )
}

export const useWltpData = (): UseMutationResult<
  WltpResponse,
  unknown,
  void,
  unknown
> => {
  const dispatch = useDispatch()
  const commonUserData = useAppSelector(selectCommonUserData)

  const { languageId } = commonUserData

  const { data: brochureResponse } = useGetBrochureVehicles()

  const wltpRequest: WltpRequest[] | undefined = getWltpRequestObject(
    brochureResponse,
    languageId
  )

  return useMutation({
    mutationFn: () => newCarSpecsService.getWltpData(wltpRequest),
    onSuccess: (response: WltpResponse) => {
      const wltpItemResponse = {
        wltpBrochureItems: response.ebrochureRows ?? [],
        wltpErrors: response.wltpErrorMessages ?? [],
      }

      dispatch(setSpecsWltpItems(wltpItemResponse))
    },
  })
}

const useVehicleComparisonAdvantages =
  (): UseQueryResult<GetVehicleComparisonResponse> => {
    const { benchmarkVehicleId } = useAppSelector(getSpecsVehicles)
    const { data: brochureResponse } = useGetBrochureVehicles()
    const specsUserData = useAppSelector(getSpecsUserState)
    const vehicleComparisonRequest = getVehicleComparisonRequest(
      brochureResponse?.brochureVehicles.map((v) => v.vehicleHeaderInfo) ?? [],
      benchmarkVehicleId,
      brochureResponse?.brochureVehicles.flatMap((v) => v.builtOptions),
      specsUserData
    )

    return useQuery<GetVehicleComparisonResponse>(
      [GetAdvantagesQueryKey, vehicleComparisonRequest],
      async (): Promise<GetVehicleComparisonResponse> =>
        await newCarSpecsService.loadBiased(vehicleComparisonRequest)
    )
  }

const useVehicleComparisonDisadvantages =
  (): UseQueryResult<GetVehicleComparisonResponse> => {
    const { benchmarkVehicleId } = useAppSelector(getSpecsVehicles)
    const { data: brochureResponse } = useGetBrochureVehicles()
    const specsUserData = useAppSelector(getSpecsUserState)
    const vehicleComparisonRequest = getVehicleComparisonRequest(
      brochureResponse?.brochureVehicles.map((v) => v.vehicleHeaderInfo) ?? [],
      benchmarkVehicleId,
      brochureResponse?.brochureVehicles.flatMap((v) => v.builtOptions),
      specsUserData
    )

    return useQuery<GetVehicleComparisonResponse>(
      [GetDisadvantagesQueryKey, vehicleComparisonRequest],
      async (): Promise<GetVehicleComparisonResponse> =>
        await newCarSpecsService.loadCompetitorAdvantages(
          vehicleComparisonRequest
        )
    )
  }

export const useVehicleComparisons = (
  comparisonType: string
): UseQueryResult<GetVehicleComparisonResponse> => {
  if (comparisonType == ReportPageType.Advantages) {
    return useVehicleComparisonAdvantages()
  } else {
    return useVehicleComparisonDisadvantages()
  }
}

export const useAddRecipeAndUpdateBrochure = (): UseMutationResult<
  boolean,
  unknown,
  DetailedAction,
  unknown
> => {
  const dispatch = useDispatch()

  const specsUserData = useAppSelector(getSpecsUserState)

  return useMutation({
    mutationFn: (recipeToAdd: DetailedAction) => {
      if (recipeToAdd.optionId) {
        switch (recipeToAdd.userInstruction) {
          case InstructionType.BuildOption:
            gtmLogSpecsOptionBuild(recipeToAdd.optionId)
            break
          case InstructionType.UnbuildOption:
            gtmLogSpecsOptionUnbuild(recipeToAdd.optionId)
            break
        }
      }

      return newCarSpecsService.addRecipe({
        ...recipeToAdd,
        databaseName:
          recipeToAdd.databaseName ?? specsUserData.settings.lastSelectedMarket,
      })
    },
    onSuccess: async (isSuccess: boolean) => {
      if (isSuccess) {
        queryClient.invalidateQueries(GetOptionalsQueryKey)
        queryClient.invalidateQueries(GetAdvantagesQueryKey)
        queryClient.invalidateQueries(GetDisadvantagesQueryKey)
        queryClient.invalidateQueries(GetBrochureVehiclesQueryKey)
        queryClient.invalidateQueries(GetRecipesQueryKey)
        dispatch(resetSpecsWltpItems())
      }
    },
  })
}

export const useGetRecipes = (): UseQueryResult<DetailedAction[], unknown> =>
  useQuery<DetailedAction[]>(
    [GetRecipesQueryKey],
    async (): Promise<DetailedAction[]> => newCarSpecsService.getRecipes()
  )

export const useGetOptionBuildRules = (): UseMutationResult<
  OptionsBuildRuleResponse,
  unknown,
  OptionsBuildRuleRequest,
  unknown
> =>
  useMutation({
    mutationFn: (optionsBuildRuleRequest: OptionsBuildRuleRequest) =>
      newCarSpecsService.getOptionBuildRules(optionsBuildRuleRequest),
  })

export const useGetOptionBuildRulesQuery = (
  optionId: number,
  vehicleId: number
): UseQueryResult<OptionsBuildRuleResponse, unknown> => {
  const optionsBuildRuleRequest: OptionsBuildRuleRequest = {
    optionId,
    vehicleId,
  }
  return useQuery<OptionsBuildRuleResponse>(
    [GetOptionBuildRulesQueryKey, optionId, vehicleId],
    () => newCarSpecsService.getOptionBuildRules(optionsBuildRuleRequest)
  )
}

export const useGetOptionals = (
  optionsPage: ReportPageType
): UseQueryResult<OptionsPageResponse> => {
  const { selectedVehicles, benchmarkVehicleId } =
    useAppSelector(getSpecsVehicles)
  const specsUserData = useAppSelector(getSpecsUserState)
  const databaseName = specsUserData?.settings?.lastSelectedMarket ?? ''
  const request: VehicleBrochureRequest[] = selectedVehicles.map((v) => ({
    isBenchmark: benchmarkVehicleId == v.vehicleId,
    vehicleId: v.vehicleId,
    specsDatabase: databaseName,
  }))

  return useQuery<OptionsPageResponse>(
    [GetOptionalsQueryKey, optionsPage, request],
    async (): Promise<OptionsPageResponse> => {
      let response: OptionsPageResponse
      switch (optionsPage) {
        case ReportPageType.Options:
          response = await newCarSpecsService.getOptionPage(request)
          break
        case ReportPageType.Packages:
          response = await newCarSpecsService.getPackagesPage(request)
          break
        case ReportPageType.Colors:
          response = await newCarSpecsService.getColorsPage(request)
          break
        case ReportPageType.BaseOptions:
          response = await newCarSpecsService.getBasePage(request)
          break
        default:
          response = await newCarSpecsService.getOptionPage(request)
          break
      }
      return {
        ...response,
        vehicles: sortVehicles(
          response.vehicles,
          (x) => x.header.vehicleId,
          benchmarkVehicleId
        ),
      }
    }
  )
}

export const useExportToExcel = (): UseMutationResult<
  ExcelExportResult,
  unknown,
  void,
  unknown
> => {
  const translations = useAppSelector(getTranslations)
  const specsVehicles = useAppSelector(getSpecsVehicles)
  const { selectedVehicles, benchmarkVehicleId, wltpBrochureItems } =
    specsVehicles
  const { data: brochureResponse } = useGetBrochureVehicles()
  const specsUserData = useAppSelector(getSpecsUserState)

  const { settings } = useAppSelector(getSpecsUserState)
  const { languageId } = useAppSelector(selectCommonUserData)

  const databaseName = specsUserData?.settings?.lastSelectedMarket ?? ''
  const request: VehicleBrochureRequest[] = selectedVehicles.map((v) => ({
    isBenchmark: benchmarkVehicleId == v.vehicleId,
    vehicleId: v.vehicleId,
    specsDatabase: databaseName,
  }))

  const rtlEnabled =
    getSiteDirectionForMarket(
      String(languageId),
      settings.lastSelectedMarket
    ) === 'rtl'

  return useMutation({
    mutationFn: async () => {
      const vehicleComparisonRequest = getVehicleComparisonRequest(
        brochureResponse?.brochureVehicles.map((v) => v.vehicleHeaderInfo) ??
          [],
        benchmarkVehicleId,
        brochureResponse?.brochureVehicles.flatMap((v) => v.builtOptions),
        specsUserData
      )

      const advantages = await newCarSpecsService.loadBiased(
        vehicleComparisonRequest
      )
      const disadvantages = await newCarSpecsService.loadCompetitorAdvantages(
        vehicleComparisonRequest
      )

      const optionPageData: OptionsPageResponse =
        await newCarSpecsService.getOptionPage(request)

      const packagePageData: OptionsPageResponse =
        await newCarSpecsService.getPackagesPage(request)

      const colorPageData: OptionsPageResponse =
        await newCarSpecsService.getColorsPage(request)

      const baseOptionsPageData: OptionsPageResponse =
        await newCarSpecsService.getBasePage(request)

      const optionsMappingObject: IMappingObject<OptionsPageResponse> = {
        JNT_optionstext: optionPageData,
        JNT_packages: packagePageData,
        JNT_colors: colorPageData,
        JNT_baseoptions: baseOptionsPageData,
      }

      const comparisonMappingObject: IMappingObject<GetVehicleComparisonResponse> =
        {
          JNT_BenchmarkAdv: advantages,
          JNT_BenchmarkDisadv: disadvantages,
        }

      const data: ExcelPageDataRequest = {
        userId: specsUserData.userId,
        guid: specsUserData.guid,
        userName: specsUserData.userName,
        email: specsUserData.userName,
        rtlEnabled,
        benchmarkVehicleId,
        brochureResponse: brochureResponse,
        buildOptionList: getBuildOptionListForExcel(
          brochureResponse!.brochureVehicles
        ),
        comparisonDataList: [],
        wltpBrochureItems,
        isLocal: specsUserData.settings.isLocalOptions,
      }

      const consolidatedBody = getAllConsolidatedDataForExcel(
        data,
        optionsMappingObject,
        comparisonMappingObject,
        translations
      )

      const excelExportRequest: ExcelExportRequest = {
        guid: specsUserData.guid,
        userId: specsUserData.userId,
        userName: specsUserData.userName,
        email: specsUserData.userName,
        styles: specsExcelStyles.styles(),
        body: consolidatedBody,
        rtlEnabled: rtlEnabled,
      }

      try {
        const excelResponse =
          await excelExportService.microserviceExportjsontoExcel(
            excelExportRequest
          )

        if (excelResponse.data.url === undefined) {
          return getErrorResult('Problem while preparing excel file!!')
        }

        const excelExportMailMessage: ExcelExportMailMessage = {
          mail: {
            to: specsUserData.userName,
            subject: getSpecsEmailSubject(),
            body: getMessageBody(excelResponse.data.url),
          },
        }

        const excelExportMailResponse = await excelExportService.sendMail(
          excelExportMailMessage
        )

        return excelExportMailResponse.status === 200
          ? getSuccessResult(excelResponse.data.url)
          : getErrorResult('Problem while sending email with excel file!!')
      } catch (error) {
        return getErrorResult(`Problem while preparing excel file!! - ${error}`)
      }
    },
  })
}

export const useResetBuiltVehiclesAndFilters = (): UseMutationResult<
  any,
  unknown,
  void,
  unknown
> => {
  const dispatch = useDispatch()
  const { carSpecsUserGuid } = useAppSelector(getSpecsUserState)

  return useMutation({
    mutationFn: async () =>
      newCarSpecsService.resetBuiltVehicle(carSpecsUserGuid),
    onSuccess: () => {
      dispatch(resetFiltersToDefaults())
      dispatch(resetVehicles())
    },
  })
}

export const useRemoveVehicle = (): UseMutationResult<
  any,
  unknown,
  number,
  unknown
> =>
  useMutation({
    mutationFn: async (vehicleId: number) =>
      newCarSpecsService.removeVehicle(vehicleId),
  })
