import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledMyPageChangePasswordModal = styled.div`
  .showPasswordButton {
    position: absolute;
    right: -30px;
    top: 40px;
  }

  .neutralIcon {
    height: 12px;
    width: 12px;
    background-color: ${JATOTheme.colors.grey};
    display: inline-block;
    border-radius: 6px;
    vertical-align: middle;
    margin: 0 6px;
  }
`
