import { JATOIcon, JATOTooltip } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

export const MonthlyPaymentDataSourceInfo: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  return (
    <JATOTooltip
      title={
        <div>
          {translations.JNT_Mp_Fully_Estimated_Data} <br />
          {translations.JNT_Mp_Actual_Data}
        </div>
      }
      arrow={true}
      placement="left-start"
      showOnlyOnOverflow={false}
    >
      <JATOIcon
        style={{ cursor: 'pointer' }}
        iconName="baseline_info"
        iconSize={24}
      />
    </JATOTooltip>
  )
}
