import Pagination from 'components/Pagination'
import React from 'react'

export interface IPagination {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

export const NewsPagination: React.FC<IPagination> = ({
  currentPage,
  totalPages,
  onPageChange,
}: IPagination) => {
  const onPageChangeInternal = (page: number): void => {
    onPageChange(page)

    document.getElementById('newsContainer')?.scrollTo(0, 0)
  }

  return (
    <Pagination
      id="newsPagination"
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChangeInternal}
    />
  )
}
