import { AxiosResponse } from 'axios'
import { VehicleNewsRequest } from 'models/VehicleNews/VehicleNewsRequest'
import { VehicleNewsResponse } from 'models/VehicleNews/VehicleNewsResponse'
import { newsApi } from 'services/apiWithAuth'

class VehicleNewsService {
  public getLatesVehicleNewsArticles(
    vehicleNewsRequest: VehicleNewsRequest
  ): Promise<AxiosResponse<VehicleNewsResponse>> {
    const vehicleNewsResponse = newsApi.post<
      VehicleNewsRequest,
      AxiosResponse<VehicleNewsResponse>
    >(`/getcplarticlelist/load`, vehicleNewsRequest)
    return vehicleNewsResponse
  }
}

const vehicleNewsService = new VehicleNewsService()
export default vehicleNewsService
