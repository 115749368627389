import { AxiosResponse } from 'axios'
import { ExcelExportMailMessage } from 'models/excelExport/ExcelExportMailRequest'
import { ExcelExportMailResponse } from 'models/excelExport/ExcelExportMailResponse'
import { ExcelExportRequest } from 'models/excelExport/ExcelExportRequest'
import { ExcelExportResponse } from 'models/excelExport/ExcelExportResponse'
import { microserviceApi } from 'services/api'

class ExcelExportService {
  public microserviceExportjsontoExcel(
    excelExportRequest: ExcelExportRequest
  ): Promise<AxiosResponse<ExcelExportResponse>> {
    const excelExportResponse = microserviceApi.post<
      ExcelExportRequest,
      AxiosResponse<ExcelExportResponse>
    >(`jsonExcelExportService`, excelExportRequest)
    return excelExportResponse
  }

  public sendMail(
    excelExportMailMessage: ExcelExportMailMessage
  ): Promise<AxiosResponse<ExcelExportMailResponse>> {
    const excelExportResponse = microserviceApi.post<
      ExcelExportMailMessage,
      AxiosResponse<ExcelExportMailResponse>
    >(`SendMail`, excelExportMailMessage)
    return excelExportResponse
  }
}

const excelExportService = new ExcelExportService()
export default excelExportService
