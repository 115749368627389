import { AxiosResponse } from 'axios'
import { countryCodeMapper } from 'helper/countryCodeMapper'
import { MPUserShipLog, SpecsUserShipLog } from 'models/UserShipLog/UserShipLog'
import { UserShipLogRequest } from 'models/UserShipLog/UserShipLogRequest'
import { specsWithAuthApi } from 'services/apiWithAuth'

const LOG_TYPE_MONTHLY_PAYMENTS_CLICK = 17
const LOG_TYPE_SPECIFICATIONS_CLICK = 3

class UserShipLogService {
  public logViewMonthlyPaymentsClick(
    log: MPUserShipLog
  ): Promise<AxiosResponse<boolean>> {
    const countryCode = countryCodeMapper(log.countryCode)
    const dbName = `SSC${countryCode}_CS2002`
    const vehicleIds = log.vehicles.map((v) => v.uniqueIdentity).join(',')

    const userShipLogRequest: UserShipLogRequest = {
      userIpAddress: '',
      uslType: LOG_TYPE_MONTHLY_PAYMENTS_CLICK,
      uslWhat: `11:${dbName}:guid=${log.user.guid}&screen=monthlyPayments&category=all&languageid=${log.user.languageId}&addvehicle=${vehicleIds}`,
    }

    return specsWithAuthApi.post(`/UsershipLog`, userShipLogRequest)
  }

  public async logSpecsViewSelectedVersionClick(
    log: SpecsUserShipLog
  ): Promise<AxiosResponse<boolean>> {
    const vehicleIds = log.vehicles ? log.vehicles.map((v) => v) : []

    const userShipLogRequest: UserShipLogRequest = {
      userIpAddress: '',
      uslType: LOG_TYPE_SPECIFICATIONS_CLICK,
      uslWhat: `11:${log.user.settings.lastSelectedMarket}:guid=${
        log.user.guid
      }&screen=brochure&category=all&languageid=${
        log.user.languageId
      }&addvehicle=${vehicleIds.toString()}`,
    }
    return specsWithAuthApi.post(`/UsershipLog`, userShipLogRequest)
  }

  public async logSpecsBuildOptionClick(
    log: SpecsUserShipLog
  ): Promise<AxiosResponse<boolean>> {
    let vehicleIds: any = ''
    if (log.optionInfo !== undefined) {
      vehicleIds =
        log.optionInfo.vehicleId + '[' + log.optionInfo.optionId + ']'
    }

    const userShipLogRequest: UserShipLogRequest = {
      userIpAddress: '',
      uslType: LOG_TYPE_SPECIFICATIONS_CLICK,
      uslWhat: `11:${log.user.settings.lastSelectedMarket}:guid=${log.user.guid}&screen=brochure&category=all&languageid=${log.user.languageId}&addoption=${vehicleIds}`,
    }
    return specsWithAuthApi.post(`/UsershipLog`, userShipLogRequest)
  }
}

const userShipLogService = new UserShipLogService()
export default userShipLogService
