import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsMonthlyPaymentsFooter } from './NewsMonthlyPaymentsFooter.styles'

const CurrentYearPlaceholder = '%CURRENT_YEAR%'

export const NewsMonthlyPaymentsFooter: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledNewsMonthlyPaymentsFooter>
      <div className="productName">{`JATO ${translations.JNT_Nav_News}`}</div>
      <span
        dangerouslySetInnerHTML={{
          __html: translations.JNT_News_MP_Disclaimer_Text,
        }}
      />
      <br />
      <br />
      <span
        dangerouslySetInnerHTML={{
          __html: translations.JNT_News_MP_Disclaimer_Copyright.replace(
            CurrentYearPlaceholder,
            new Date().getFullYear().toString()
          ),
        }}
      />
    </StyledNewsMonthlyPaymentsFooter>
  )
}
