import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledPasswordValidationMessage = styled.div`
  display: flex;
  flex-direction: column;
  .neutralIcon {
    height: 12px;
    width: 12px;
    background-color: ${JATOTheme.colors.grey};
    display: inline-block;
    border-radius: 6px;
    vertical-align: middle;
    margin: 0 6px;
  }
`
