import Loader from 'components/Loader'
import { defaultCardsNumber } from 'helper/newsHelper'
import { INewsArticle } from 'models/News/INewsArticle'
import React from 'react'
import { NewsCard } from '../NewsCard/NewsCard'
import NewsPagination from '../NewsPagination'
import { StyledNewsCards } from './NewsCards.styles'

interface INewsCardsProps {
  articles: INewsArticle[]
  currentPage: number
  totalNumber: number
  onPageChange: (currentPage: number) => void
  allowSelection?: boolean
  isFetching: boolean
}

export const NewsCards: React.FC<INewsCardsProps> = ({
  articles,
  currentPage,
  totalNumber,
  onPageChange,
  allowSelection = false,
  isFetching,
}: INewsCardsProps) => {
  const totalPages = Math.ceil(totalNumber / defaultCardsNumber)

  const pagination = (
    <NewsPagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={(page) => onPageChange(page)}
    />
  )

  return (
    <StyledNewsCards>
      {articles && (
        <>
          {pagination}
          {isFetching ? (
            <Loader style={{ minHeight: '70vh' }} />
          ) : (
            <div className="newsCardsContainer">
              {articles.map((article, index) => (
                <NewsCard
                  key={index}
                  article={article}
                  allowSelection={allowSelection}
                />
              ))}
            </div>
          )}
          {pagination}
        </>
      )}
    </StyledNewsCards>
  )
}
