import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledMpAlertsBetaHomePageComponent = styled.span`
  grid-column: 1 / span 4;

  .mpAlertsLoader {
    min-height: 377px;
  }

  .mpAlertsHeading {
    font-family: 'Helvetica Neue LT W1G';
    font-weight: 700;
  }

  .mpAlerts {
    display: flex;
    gap: 20px;
  }

  .mpAlerts .newsCardFooter {
    background-color: ${JATOTheme.colors.blue};
  }

  .mpAlerts .newsCardFooterLink {
    color: ${JATOTheme.colors.white};
  }

  .mpAlerts button {
    background-color: ${JATOTheme.colors.primary};
    color: ${JATOTheme.colors.white};
  }
`
