import { JATOText } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import MpAlertsBetaHomePageComponent from 'components/MpAlertsBeta/MpAlertsBetaHomePageComponent'
import NewsCard from 'components/News/NewsCard'
import NewsCarousel from 'components/News/NewsCarousel'
import { NewsMonthlyPaymentsWelcomePopup } from 'components/News/NewsMonthlyPaymentsWelcomePopup/NewsMonthlyPaymentsWelcomePopup'
import { NewsNoItemFound } from 'components/News/NewsNoItemFound/NewsNoItemFound'
import NewsSelectedFilters from 'components/News/NewsSelectedFilters'
import { mpAlertsBetaEndDate, mpAlertsBetaStartDate } from 'config'
import {
  carouselArticlesNumber,
  homePageArticlesNumber,
} from 'helper/newsHelper'
import { useGetMpAlertsBetaState } from 'hooks/mpAlertsBeta'
import {
  useGetArticlesByCategory,
  useGetInfiniteHomepageArticles,
  useGetTopStoryArticle,
} from 'hooks/news'
import { NewsCategoryType } from 'models/News/NewsCategoryType'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNews } from './News.styles'

export const News: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const { data: mpAlertsBeta } = useGetMpAlertsBetaState()

  const { data: newVehicles, isLoading: carouselLoading } =
    useGetArticlesByCategory(
      NewsCategoryType.NewVehicles,
      carouselArticlesNumber,
      1,
      true
    )
  const hasCarouselArticles =
    !carouselLoading && newVehicles && newVehicles.articles.length > 0

  const { data: topStory, isLoading: topStoryLoading } =
    useGetTopStoryArticle(true)
  const hasTopStory = !!topStory

  // if there is no carousel or top story articles, fetch more articles to take up their space in the first row
  const occupiedSpaceCount =
    (hasCarouselArticles ? 3 : 0) + (hasTopStory ? 1 : 0)
  const numOfArticles = homePageArticlesNumber - occupiedSpaceCount

  // enable main query after first 2 are finished to have accurate number of articles we need to load
  const mainQueryEnabled = !carouselLoading && !topStoryLoading

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching: mainQueryFetching,
    isFetchingNextPage,
    isRefetching,
  } = useGetInfiniteHomepageArticles(numOfArticles, mainQueryEnabled)

  const { articles, totalNumber } = data?.pages[0] ?? {
    articles: [],
    totalNumber: 0,
  }

  const filteredArticles = mpAlertsBeta?.isEnabled
    ? articles.filter(
        (a) =>
          !a.categories.includes(NewsCategoryType.MonthlyPayments) ||
          articles
            .filter((a) =>
              a.categories.includes(NewsCategoryType.MonthlyPayments)
            )
            .indexOf(a) >= 4
      )
    : articles

  const topRowArticlesCount = 4 - occupiedSpaceCount
  const topRowArticles = filteredArticles.slice(0, topRowArticlesCount)
  const restOfFirstPageArticles = filteredArticles.slice(
    topRowArticlesCount,
    numOfArticles
  )

  const observer = useRef(null)

  useEffect(() => {
    if (!observer.current) return

    const options = {
      threshold: 1.0,
    }

    const handleObserver = (entities: any[]): void => {
      const target = entities[0]
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    }

    const observerInstance = new IntersectionObserver(handleObserver, options)
    observerInstance.observe(observer.current)

    return () => {
      if (observer.current) observerInstance.unobserve(observer.current)
    }
  }, [hasNextPage, isFetchingNextPage])

  const showNoItemsFound =
    mainQueryEnabled && !mainQueryFetching && totalNumber == 0

  const showCarouselLoader =
    carouselLoading ||
    (!hasCarouselArticles && mainQueryFetching) ||
    (!hasCarouselArticles && topStoryLoading)

  const showCarousel = !carouselLoading && hasCarouselArticles

  const showTopRowArticles =
    !carouselLoading && !topStoryLoading && !mainQueryFetching

  const showTopStoryLoader =
    topStoryLoading ||
    (!hasTopStory && mainQueryFetching) ||
    (!hasTopStory && carouselLoading)

  const showTopStory = !topStoryLoading && hasTopStory

  const showMainQueryLoader = !mainQueryEnabled || mainQueryFetching

  const showRefetchingLoader = isRefetching && !isFetchingNextPage

  const checkDates = (): boolean => {
    const startDate = mpAlertsBetaStartDate
    const endDate = mpAlertsBeta?.betaEndDateForUser ?? mpAlertsBetaEndDate

    return moment().isBetween(moment(startDate).startOf('day'), moment(endDate))
  }

  return (
    <StyledNews>
      <NewsSelectedFilters />
      {showNoItemsFound ? (
        <NewsNoItemFound
          iconName="custom_filter"
          text={`${translations.JNT_SRCH_NoArticlesFound}. <br />${translations.JNT_Refine_Your_Filters}`}
        />
      ) : (
        <>
          <div className="cardsContainer">
            {showCarouselLoader && <Loader className="newsCarouselLoader" />}
            {showCarousel && <NewsCarousel articles={newVehicles.articles} />}
            {showTopRowArticles &&
              topRowArticles.map((article) => (
                <NewsCard key={article.id} article={article} />
              ))}
            {showTopStoryLoader && <Loader className="topStoryLoader" />}
            {showTopStory && (
              <div className="topStory">
                <NewsCard article={topStory} />
              </div>
            )}
            {mpAlertsBeta?.isEnabled && checkDates() && (
              <MpAlertsBetaHomePageComponent />
            )}
            <JATOText
              as="div"
              fontWeight="medium"
              fontSize={21}
              className="latestNewsHeading"
            >
              {translations.JNT_Latest_News}
            </JATOText>
            {restOfFirstPageArticles.map((article) => (
              <NewsCard key={article.id} article={article} />
            ))}
            {data?.pages.slice(1).map((page, index) => (
              <React.Fragment key={index}>
                {page.articles.map((article) => (
                  <NewsCard key={article.id} article={article} />
                ))}
              </React.Fragment>
            ))}
            {showMainQueryLoader && <Loader className="mainLoader" />}
          </div>
          <div ref={observer} />
        </>
      )}
      <LoaderModal isOpen={showRefetchingLoader} />
      <NewsMonthlyPaymentsWelcomePopup />
    </StyledNews>
  )
}
