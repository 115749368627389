import AddedAsOptionIcon from 'assets/icons/AddedAsOptionIcon.svg'
import AvailableAsOptionIcon from 'assets/icons/AvailableAsOptionIcon.svg'
import ExcludedOptionIcon from 'assets/icons/ExcludedOptionIcon.svg'
import NotAvailableIcon from 'assets/icons/NotAvailable.svg'
import viewGalleryIcon from 'assets/icons/PhotoGallery.svg'
import StandardIcon from 'assets/icons/Standard.svg'
import ReplaceVehicleIcon from 'assets/icons/icon_replace_vehicle.svg'
import DeleteVehicleIcon from 'assets/icons/icon_trash.svg'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledSpecificationsReportFooter } from './SpecificationsReportFooter.styles'

export const SpecificationsReportFooter: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const Replace = translations.JNT_replacebtn
  const Remove = translations.JNT_remove
  const PhotoGallery = translations.JNT_Photo
  const Standard = translations.JNT_standard
  const NotAvailable = translations.JNT_notavailable
  const AvailableAsOption = translations.JNT_option
  const AddedAsOption = translations.JNT_added
  const ExcludedOption = translations.JNT_ExcOption

  return (
    <StyledSpecificationsReportFooter>
      <table className="tableStyle">
        <tbody>
          <tr className="tableRow">
            <td className="tableCell sticky">
              <img src={ReplaceVehicleIcon} className="image-icon" />
              <span className="textMargin"> {Replace} </span>
              <img src={DeleteVehicleIcon} className="image-icon" />
              <span className="textMargin"> {Remove} </span>
              <img src={viewGalleryIcon} />
              <span className="textMargin"> {PhotoGallery} </span>
              <img src={StandardIcon} />
              <span className="textMargin"> {Standard} </span>
              <img src={NotAvailableIcon} />
              <span className="textMargin"> {NotAvailable} </span>
              <img src={AvailableAsOptionIcon} />
              <span className="textMargin"> {AvailableAsOption} </span>
              <img src={AddedAsOptionIcon} />
              <span className="textMargin"> {AddedAsOption} </span>
              <img src={ExcludedOptionIcon} />
              <span className="textMargin"> {ExcludedOption} </span>
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </StyledSpecificationsReportFooter>
  )
}
