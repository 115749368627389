import { JATOVerticalSpacer } from '@jato/ui-component-library'
import benchmarkDeselectedIcon from 'assets/icons/icon_benchmark_deselected.svg'
import benchmarkSelectedIcon from 'assets/icons/icon_benchmark_selected.svg'
import viewGalleryIcon from 'assets/icons/icon_image.svg'
import newsIcon from 'assets/icons/icon_news.svg'
import replaceVehicleIcon from 'assets/icons/icon_replace_vehicle.svg'
import hideVehicle from 'assets/icons/icon_toggle_vehicle_off.svg'
import displayVehicle from 'assets/icons/icon_toggle_vehicle_on.svg'
import trashIcon from 'assets/icons/icon_trash.svg'
import { Flag } from 'components/Flag/Flag'
import { ModalComponent } from 'components/ModalComponent'
import OpenWltpErrorsButton from 'components/Modals/OpenWltpErrorsButton'
import { getSchemaDataValue } from 'helper/specificationsBuildRequestHelper'
import { useGetBrochureVehicles, useRemoveVehicle } from 'hooks/carSpecs'
import { CommonNewsVehicle } from 'models/News/Vehicle/CommonNewsVehicle'
import { BrochureReturnObject } from 'models/Specifications/BrochureResponse'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  getSpecsVehicles,
  setSpecsVehicles,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { v4 as uuid } from 'uuid'
import { SpecsVehicleNews } from '../News/SpecsVehicleNews'
import { SpecsPhotoGallery } from '../SpecsPhotoGallery/SpecsPhotoGallery'

interface IHeaderVehicleProps {
  vehicle: BrochureReturnObject
  isBenchmark: boolean
  setDisplayVehicleImages: (displayVehicleImages: boolean) => void
  displayVehicleImages: boolean
}

export const SpecificationsReportHeaderVehicle: React.FC<
  IHeaderVehicleProps
> = ({
  vehicle,
  isBenchmark,
  setDisplayVehicleImages,
  displayVehicleImages,
}: IHeaderVehicleProps) => {
  const translations = useAppSelector(getTranslations)
  const [modalNewsVisible, setModalNewsVisible] = useState(false)
  const [modalGalleryVisible, setModalGalleryVisible] = useState(false)
  const specsUserData = useAppSelector(getSpecsUserState)
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { mutateAsync: removeVehicle } = useRemoveVehicle()

  const specsVehicles = useAppSelector(getSpecsVehicles)
  const { benchmarkVehicleId, wltpErrors, selectedVehicles } = specsVehicles
  const { data: brochureResponse } = useGetBrochureVehicles()
  const showTrashButton =
    brochureResponse && brochureResponse.brochureVehicles.length > 1
  const errors = wltpErrors.filter(
    (e) => e.vehicleId === vehicle.vehicleHeaderInfo.vehicleId
  )

  const getNewsCountryCode = (): string => {
    const specsMarket = specsUserData.settings.lastSelectedMarket?.split('_')
    if (specsMarket) {
      return `CTY_${specsMarket[0].substring(3)}`
    }
    return ''
  }

  const make = specsUserData.settings.isLocalOptions
    ? getSchemaDataValue(vehicle, 128)
    : getSchemaDataValue(vehicle, 111)

  const model = specsUserData.settings.isLocalOptions
    ? getSchemaDataValue(vehicle, 129)
    : getSchemaDataValue(vehicle, 112)

  const versionLevel = specsUserData.settings.isLocalOptions
    ? getSchemaDataValue(vehicle, 131)
    : getSchemaDataValue(vehicle, 302)

  const yearModel = vehicle.vehicleHeaderInfo.vehiclePhotoPath.yearModel

  const commonNewsVehicle: CommonNewsVehicle = {
    make: make,
    model: model,
    vehicleId: vehicle.vehicleHeaderInfo.vehicleId,
    countryCode: getNewsCountryCode(),
    specsDb: specsUserData.settings.lastSelectedMarket ?? '',
    isMonthlyPayments: false,
  }
  const vehicleMakeModelYear = `${make} ${model} - ${yearModel}`
  const vehicleMakeModelVersionYear = `${make} ${model} ${versionLevel} - ${yearModel}`

  const replaceVehicle = (vehicle: BrochureReturnObject): void => {
    trashVehicle(vehicle)
    history.push(RoutePaths.Specifications)
  }

  const benchmarkVehicle = (vehicle: BrochureReturnObject): void => {
    if (isBenchmark) {
      return
    }

    const vehiclesUpdate = {
      ...specsVehicles,
      benchmarkVehicleId: vehicle.vehicleHeaderInfo.vehicleId,
    }
    dispatch(setSpecsVehicles(vehiclesUpdate))

    // Scroll to first vehicle in showroom list
    document
      .getElementById('benchmarkVehicle')
      ?.scrollIntoView({ inline: 'end', block: 'end', behavior: 'smooth' })
  }

  const trashVehicle = async (vehicle: BrochureReturnObject): Promise<void> => {
    await removeVehicle(vehicle.vehicleHeaderInfo.vehicleId)
    const newBenchmarkId = isBenchmark
      ? brochureResponse?.brochureVehicles.find(
          (v) => v.vehicleHeaderInfo.vehicleId != benchmarkVehicleId
        )?.vehicleHeaderInfo.vehicleId ?? 0
      : benchmarkVehicleId

    const selectedVehiclesUpdate = selectedVehicles.filter(
      (v) => v.vehicleId !== vehicle.vehicleHeaderInfo.vehicleId
    )

    const vehiclesUpdate = {
      ...specsVehicles,
      benchmarkVehicleId: newBenchmarkId,
      selectedVehicles: selectedVehiclesUpdate,
    }

    dispatch(setSpecsVehicles(vehiclesUpdate))
  }

  const handleOnClose = (): void => {
    setModalNewsVisible(false)
    setModalGalleryVisible(false)
  }

  const getCountryTooltipText = (
    vehicle: BrochureReturnObject,
    schemaId: number
  ): string => {
    const index = vehicle.vehicleHeaderInfo.vehicleHeaders
      .map((v: any) => v.schemaId)
      .indexOf(schemaId)
    if (index > -1) {
      const fullText = vehicle.vehicleHeaderInfo.vehicleHeaders[index].fullText
      return fullText ? `${fullText} ${translations.JNT_vehicles}` : ''
    }
    return ''
  }

  return (
    <>
      <div
        className="vehicle"
        key={uuid()}
        id={isBenchmark ? 'benchmarkVehicle' : ''}
      >
        <div>
          <div title={getCountryTooltipText(vehicle, 109)}>
            <Flag countryFlagPath={vehicle.vehicleHeaderInfo.countryFlagPath} />
            <span className="vehicleMakeAndModelText">
              {vehicleMakeModelYear}
            </span>
          </div>
          <div
            className="benchmark"
            title={
              isBenchmark
                ? translations.JNT_Benchmark
                : translations.JNT_AsBenchmark
            }
            id="specsReportBenchmarkButton"
            onClick={() => benchmarkVehicle(vehicle)}
          >
            <img
              src={
                isBenchmark ? benchmarkSelectedIcon : benchmarkDeselectedIcon
              }
              width="20"
              style={{
                cursor: 'pointer',
              }}
            />
            <span className="vehicleVersionLevelText">{versionLevel}</span>
          </div>
        </div>
        <div>
          <div
            className="displayVehicleImage"
            title={translations.JNT_Photo}
            id="specsReportDisplayVehicleImageButton"
            onClick={() => {
              setDisplayVehicleImages(!displayVehicleImages)
            }}
          >
            <img
              src={displayVehicleImages ? hideVehicle : displayVehicle}
              width="20"
              style={{
                cursor: 'pointer',
              }}
            />
            <span className="vehiclePriceText">
              {`${vehicle.vehicleHeaderInfo.displayPrice}`}
            </span>
          </div>
          <div className="price" id="specsReportDisplayVehicleImageButton">
            <img
              src={vehicle.vehicleHeaderInfo.vehiclePhotoPath.photoPath}
              alt="bridge"
              className="vehiclePhotoGalleryImage"
              hidden={!displayVehicleImages}
            />
          </div>
          <JATOVerticalSpacer height={20} />
          <div className="vehicleActionSection">
            <div
              className="replaceVehicle"
              title={translations.JNT_ReplaceVeh}
              id="specsReportReplaceVehicleButton"
              onClick={() => {
                replaceVehicle(vehicle)
              }}
            >
              <img
                src={replaceVehicleIcon}
                width="20"
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {showTrashButton ? (
              <div
                className="removeVehicle"
                title={translations.JNT_RemoveVeh}
                id="specsReportRemoveVehicleButton"
                onClick={() => trashVehicle(vehicle)}
              >
                <img
                  src={trashIcon}
                  width="16"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            <div
              className="viewGallery"
              title={translations.JNT_Photo}
              id="specsReportViewGalleryButton"
              onClick={() => setModalGalleryVisible(true)}
            >
              <img
                src={viewGalleryIcon}
                width="22"
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
            <div
              className="viewNews"
              title={translations.JNT_view}
              id="specsReportViewNewsButton"
              onClick={() => setModalNewsVisible(true)}
            >
              <img
                src={newsIcon}
                width="20"
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {errors.length > 0 && (
              <div className="wltpErrorsButton">
                <OpenWltpErrorsButton
                  modalTitle={`WLTP Error List: ${make} ${model} ${versionLevel}`}
                  errors={errors}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        modalVisible={modalNewsVisible}
        closeButton
        title={vehicleMakeModelVersionYear}
        onSecondaryClick={handleOnClose}
      >
        <SpecsVehicleNews vehicle={commonNewsVehicle} />
      </ModalComponent>
      <ModalComponent
        modalVisible={modalGalleryVisible}
        closeButton
        title={vehicleMakeModelVersionYear}
        onSecondaryClick={handleOnClose}
      >
        <SpecsPhotoGallery builtVehicle={vehicle} />
      </ModalComponent>
    </>
  )
}
