import styled from 'styled-components'
import Theme from 'theme'

export const StyledNewsMonthlyPaymentsFooter = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 112;
  background-color: ${Theme.colors.ultraLightGrey};
  color: ${Theme.colors.offGrey};
  border-top: ${Theme.borderWidths[0]}px solid ${Theme.colors.midGrey};
  font-family: ${Theme.fonts.body};
  font-size: ${Theme.fontSizes[0]}px;
  padding: 16px 24px;

  .productName {
    font-size: ${Theme.fontSizes[2]}px;
    font-weight: ${Theme.fontWeights.bold};
    padding-bottom: ${Theme.space[2]}px;
  }
`
