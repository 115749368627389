import {
  JATOLink,
  JATOList,
  JATOListItem,
  JATOSpinner,
} from '@jato/ui-component-library'
import { vehicleNewsArticleBaseUrl } from 'config'
import { CommonNewsVehicle } from 'models/News/Vehicle/CommonNewsVehicle'
import { VehicleNewsRequest } from 'models/VehicleNews/VehicleNewsRequest'
import { VehicleNewsResponse } from 'models/VehicleNews/VehicleNewsResponse'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getSpecsUserState } from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import vehicleNewsService from 'services/VehicleNews/VehicleNewsService'
import { SpecsQuadricycleNewsArticleLink } from './SpecsQuadricycleNewsArticleLink'
import {
  LogoNewsLoader,
  StyledSpecsVehicleNews,
} from './SpecsVehicleNews.styles'

interface ISpecsVehicleNews {
  vehicle: CommonNewsVehicle
}

export const SpecsVehicleNews: React.FC<ISpecsVehicleNews> = ({
  vehicle,
}: ISpecsVehicleNews) => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const translations = useAppSelector(getTranslations)
  const { isQuadricycleCustomer } = useAppSelector(getSpecsUserState)
  const vehicleNewsRequest: VehicleNewsRequest = {
    guid: commonUserData.guid,
    languageId: commonUserData.languageId,
    specsDb: vehicle.specsDb,
    vehicleId: vehicle.vehicleId,
    topCount: 5,
    isMonthlyPayment: vehicle.isMonthlyPayments,
  }

  const { data: vehicleNewsResponse, isLoading } =
    useQuery<VehicleNewsResponse>(
      ['fetchLatestNewsData', vehicle.vehicleId],
      async () => {
        const { data } =
          await vehicleNewsService.getLatesVehicleNewsArticles(
            vehicleNewsRequest
          )
        return data
      }
    )

  const viewAllNewsLink = `${vehicleNewsArticleBaseUrl}/${vehicleNewsRequest.guid}/${vehicleNewsRequest.languageId}/news?redirectUrl=/searchViewAll/${vehicle.make}/${vehicle.model}/${vehicle.countryCode}`

  const getVehicleNewsArticleUrl = (articleId: number): string =>
    `${vehicleNewsArticleBaseUrl}/${vehicleNewsRequest.guid}/${vehicleNewsRequest.languageId}/news?redirectUrl=/articles/${articleId}`

  return (
    <StyledSpecsVehicleNews>
      {isLoading ? (
        <LogoNewsLoader>
          <JATOSpinner spinnerSize={64} />
        </LogoNewsLoader>
      ) : (
        vehicleNewsResponse && (
          <div id="vehicle-grid-container">
            {vehicleNewsResponse.articleData.length > 0 ? (
              <>
                <JATOList>
                  {vehicleNewsResponse.articleData.map((article, index) => (
                    <JATOListItem key={index} justifyContent="space-between">
                      {isQuadricycleCustomer ? (
                        <SpecsQuadricycleNewsArticleLink
                          title={article.headline}
                          request={article}
                        />
                      ) : (
                        <JATOLink
                          id="specsReportNewsArticleLink"
                          href={getVehicleNewsArticleUrl(article.id)}
                          target="_blank"
                        >
                          {article.headline}
                        </JATOLink>
                      )}
                      <span>
                        {moment(Date.parse(article.concludeDate))
                          .utcOffset(0, true)
                          .format('DD-MM-yyyy')}
                      </span>
                    </JATOListItem>
                  ))}
                </JATOList>
                <div style={{ paddingLeft: '318px' }}>
                  {!isQuadricycleCustomer && (
                    <JATOLink
                      id="specsReportViewAllNewsLink"
                      href={viewAllNewsLink}
                      target="_blank"
                    >
                      {translations.JNT_TLE_ViewAll}
                    </JATOLink>
                  )}
                </div>
              </>
            ) : (
              vehicleNewsResponse.responseMessage
            )}
          </div>
        )
      )}
    </StyledSpecsVehicleNews>
  )
}
