import {
  JATOButtonGroup,
  JATOLink,
  JATOTooltip,
} from '@jato/ui-component-library'
import { L10n } from '@syncfusion/ej2-base'
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  Inject,
  Resize,
  RowDeselectEventArgs,
  RowSelectEventArgs,
  Selection,
} from '@syncfusion/ej2-react-grids'
import Loader from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { arraysEqual } from 'helper/arrayHelper'
import {
  defaultArticlesInGridNumber,
  getCountryByCode,
} from 'helper/newsHelper'
import { useUpdateBookmarkStatus, useUpdateSharedStatus } from 'hooks/news'
import { INewsArticle } from 'models/News/INewsArticle'
import { getNewsCategoryTranslations } from 'models/News/NewsCategoryType'
import { NewsPage } from 'models/News/NewsPageType'
import React, { useEffect } from 'react'
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useSelectorRef } from 'redux/hook'
import {
  getNewsCountries,
  getNewsSelectedArticles,
  setNewsSelectedArticles,
} from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import NewsBookmarkButton from '../Buttons/NewsBookmarkButton'
import { NewsRemoveBookmarkButton } from '../Buttons/NewsBookmarkButton/NewsRemoveBookmarkButton'
import NewsShareButton from '../Buttons/NewsShareButton'
import { NewsRemoveShareButton } from '../Buttons/NewsShareButton/NewsRemoveShareButton'
import NewsPagination from '../NewsPagination'
import { StyledNewsGrid } from './NewsGrid.styles'

export enum NewsGridColumnType {
  Id,
  NewsCategory,
  Manufacturer,
  Headline,
  Article,
  Author,
  Country,
  SharedBy,
  ReleaseDate,
  BookMarkDate,
  SharedDate,
  Makes,
  Models,
  Actions,
  RemoveShareAction,
  RemoveBookmarkAction,
}

export interface INewsGridProps {
  articles: INewsArticle[]
  currentPage: number
  totalNumber: number
  onPageChange: (currentPage: number) => void
  columns: NewsGridColumnType[]
  allowSelection?: boolean
  isFetching: boolean
}

export const NewsGrid: React.FC<INewsGridProps> = ({
  articles,
  currentPage,
  totalNumber,
  onPageChange,
  columns,
  allowSelection = false,
  isFetching,
}: INewsGridProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { newsPage } = useParams<{ newsPage: NewsPage }>()
  const translations = useAppSelector(getTranslations)
  const countries = useAppSelector(getNewsCountries)
  const selectedArticlesRef = useSelectorRef(getNewsSelectedArticles)
  const categoryTranslations = getNewsCategoryTranslations(translations)

  const { mutate: updateSharedStatus, isLoading: isUpdatingShareStatus } =
    useUpdateSharedStatus()
  const { mutate: updateBookmarkStatus, isLoading: isUpdatingBookmarkStatus } =
    useUpdateBookmarkStatus()

  let gridInstance: Grid | null
  const gridData = { result: articles, count: totalNumber }

  useEffect(() => {
    if (gridInstance) {
      gridInstance.dataSource = { result: articles, count: totalNumber }
    }
  })

  const totalPages = Math.ceil(totalNumber / defaultArticlesInGridNumber)

  const pageOptions = { pageSize: defaultArticlesInGridNumber }

  L10n.load({
    translatedTags: {
      grid: {
        EmptyRecord: translations.JNT_NoRecordsToDisplay,
      },
    },
  })

  const paginationComponent = (
    <NewsPagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={(page) => onPageChange(page)}
    />
  )

  const getCountryName = (_field: string, data: object): string => {
    const { countryCode } = data as INewsArticle
    const { name } = getCountryByCode(countryCode, countries, translations)

    return name
  }

  const getFormattedDate = (_field: string, data: object): string => {
    const { releaseDate, sharedDate, bookMarkDate } = data as INewsArticle
    switch (_field) {
      case 'releaseDate':
        return releaseDate.substring(0, 10)
      case 'sharedDate':
        if (sharedDate) {
          return new Date(sharedDate).toLocaleDateString('en-GB')
        }
        return ''
      case 'bookMarkDate':
        if (bookMarkDate) {
          return new Date(bookMarkDate).toLocaleDateString('en-GB')
        }
        return ''
      default:
        return ''
    }
  }

  const queryCellInfo = (args: any): void => {
    const article = args.data as INewsArticle

    switch (args.column.field) {
      case 'category':
        render(categoryTemplate(article), args.cell)
        break
      case 'headline':
        render(headlineLinkTemplate(article), args.cell)
        break
      case 'articleLink':
        render(articleLinkTemplate(article), args.cell)
        break
      case 'actions':
        render(actionsTemplate(article), args.cell)
        break
      case 'removeShare':
        render(shareRemoveTemplate(article), args.cell)
        break
      case 'removeBookmark':
        render(bookMarkRemoveTemplate(article), args.cell)
        break
    }
  }

  const categoryTemplate = (article: INewsArticle): JSX.Element => {
    const { categories } = article
    const categoryNames = categories
      .map((category) => categoryTranslations[category] ?? category)
      .join(', ')
    const firstCategory = categoryTranslations[categories[0]] ?? categories[0]
    return (
      <JATOTooltip title={categoryNames} showOnlyOnOverflow={false}>
        {firstCategory}
      </JATOTooltip>
    )
  }

  const headlineLinkTemplate = (article: INewsArticle): JSX.Element => {
    const parentPage = newsPage ?? article.categories[0]
    return (
      <JATOLink
        id="newsGridHeadlineLink"
        onClick={() =>
          history.push(`${RoutePaths.NewsArticles}/${parentPage}/${article.id}`)
        }
        className="articleLink"
      >
        <div dangerouslySetInnerHTML={{ __html: article.headline }} />
      </JATOLink>
    )
  }

  const articleLinkTemplate = (article: INewsArticle): JSX.Element => {
    const parentPage = newsPage ?? article.categories[0]
    return (
      <JATOLink
        id="newsGridArticleLink"
        onClick={() =>
          history.push(`${RoutePaths.NewsArticles}/${parentPage}/${article.id}`)
        }
        className="articleLink"
      >
        <div dangerouslySetInnerHTML={{ __html: article.headline }} />
        <div className="subHeadlineLink">{article.synopsis}</div>
      </JATOLink>
    )
  }

  const actionsTemplate = (article: INewsArticle): JSX.Element => {
    const { isShared, isBookMarked } = article
    const { JNT_UnFavourite, JNT_Favourite } = translations

    return (
      <JATOButtonGroup space={3}>
        <NewsShareButton
          article={article}
          title={isShared ? translations.JNT_Unshared : translations.JNT_share}
          onChange={() => updateSharedStatus(article)}
        />
        <NewsBookmarkButton
          article={article}
          title={isBookMarked ? JNT_UnFavourite : JNT_Favourite}
          onChange={() => updateBookmarkStatus(article)}
        />
      </JATOButtonGroup>
    )
  }

  const shareRemoveTemplate = (article: INewsArticle): JSX.Element => (
    <NewsRemoveShareButton onChange={() => updateSharedStatus(article)} />
  )

  const bookMarkRemoveTemplate = (article: INewsArticle): JSX.Element => (
    <NewsRemoveBookmarkButton onChange={() => updateBookmarkStatus(article)} />
  )

  const defaultColumnWidth = '80'
  const defaultColumnAlign = 'Left'
  const defaultClipMode = 'Clip'

  const columnDefinitions = [
    { id: NewsGridColumnType.Id, field: 'id', isPrimaryKey: true },
    {
      id: NewsGridColumnType.NewsCategory,
      field: 'category',
      headerText: translations.JNT_News_Category,
    },
    {
      id: NewsGridColumnType.Manufacturer,
      field: 'vehicle.make',
      headerText: translations.JNT_Manufacturer,
    },
    {
      id: NewsGridColumnType.Headline,
      field: 'headline',
      headerText: translations.JNT_Headline,
      width: '160',
    },
    {
      id: NewsGridColumnType.Article,
      field: 'articleLink',
      headerText: translations.JNT_Article,
      width: '160',
    },
    {
      id: NewsGridColumnType.Author,
      field: 'author',
      headerText: translations.JNT_Author,
    },
    {
      id: NewsGridColumnType.Country,
      field: 'countryCode',
      headerText: translations.JNT_Country,
      valueAccessor: getCountryName,
    },
    {
      id: NewsGridColumnType.SharedBy,
      field: 'sharedBy',
      headerText: translations.JNT_SharedBy,
    },
    {
      id: NewsGridColumnType.ReleaseDate,
      field: 'releaseDate',
      headerText: translations.JNT_NWS_Date,
      valueAccessor: getFormattedDate,
    },
    {
      id: NewsGridColumnType.SharedDate,
      field: 'sharedDate',
      headerText: `${translations.JNT_share} ${translations.JNT_NWS_Date}`,
      valueAccessor: getFormattedDate,
    },
    {
      id: NewsGridColumnType.BookMarkDate,
      field: 'bookMarkDate',
      headerText: `${translations.JNT_Favourite} ${translations.JNT_NWS_Date}`,
      valueAccessor: getFormattedDate,
    },
    {
      id: NewsGridColumnType.Makes,
      field: 'makesList',
      headerText: translations.JNT_make,
    },
    {
      id: NewsGridColumnType.Models,
      field: 'modelsList',
      headerText: translations.JNT_model,
    },
    {
      id: NewsGridColumnType.Actions,
      field: 'actions',
      headerText: translations.JNT_Action,
      width: '40',
    },
    {
      id: NewsGridColumnType.RemoveShareAction,
      field: 'removeShare',
      headerText: translations.JNT_Action,
      width: '40',
    },
    {
      id: NewsGridColumnType.RemoveBookmarkAction,
      field: 'removeBookmark',
      headerText: translations.JNT_Action,
      width: '40',
    },
  ]

  useEffect(() => {
    gridInstance && gridInstance.refresh()
  }, [columns])

  const rowSelected = (row: RowSelectEventArgs): void => {
    if (row.isInteracted && row.data) {
      const selectedArticles = selectedArticlesRef?.current ?? []
      const articles = [row.data as INewsArticle]
      const ids = articles.map((a) => a.id)
      const selectedArticlesUpdate = [...selectedArticles, ...ids]

      dispatch(setNewsSelectedArticles(selectedArticlesUpdate))
    }
  }

  const rowDeselected = (row: RowDeselectEventArgs): void => {
    if (row.isInteracted && row.data) {
      const selectedArticles = selectedArticlesRef?.current ?? []
      const articles = [row.data as INewsArticle]
      const ids = articles.map((a) => a.id)
      const selectedArticlesUpdate = selectedArticles.filter(
        (articleId) => !ids.includes(articleId)
      )

      dispatch(setNewsSelectedArticles(selectedArticlesUpdate))
    }
  }

  const dataBound = (): void => {
    if (gridInstance) {
      const selectedArticles = selectedArticlesRef?.current ?? []
      const selectedInState: number[] = []

      gridInstance.currentViewData.forEach((data, index) => {
        const { id } = data as INewsArticle
        if (selectedArticles.includes(id)) {
          selectedInState.push(index)
        }
      })

      const selectedInGrid = gridInstance.getSelectedRowIndexes()

      if (!arraysEqual(selectedInState, selectedInGrid)) {
        gridInstance.selectRows(selectedInState)
      }
    }
  }

  return (
    <StyledNewsGrid>
      {paginationComponent}
      {isFetching ? (
        <Loader style={{ minHeight: '70vh' }} />
      ) : (
        <GridComponent
          ref={(g) => (gridInstance = g)}
          id="grid"
          locale="translatedTags"
          dataSource={gridData}
          loadingIndicator={{ indicatorType: 'Shimmer' }}
          allowResizing={true}
          allowSelection={allowSelection}
          pageSettings={pageOptions}
          rowSelected={rowSelected}
          rowDeselected={rowDeselected}
          dataBound={dataBound}
          queryCellInfo={queryCellInfo}
          selectionSettings={{ persistSelection: true }}
        >
          <ColumnsDirective>
            {allowSelection && <ColumnDirective type="checkbox" width="20" />}
            {columnDefinitions.map((c, index) => (
              <ColumnDirective
                key={index}
                field={c.field}
                headerText={c.headerText}
                width={c.width ?? defaultColumnWidth}
                textAlign={defaultColumnAlign}
                clipMode={defaultClipMode}
                isPrimaryKey={c.isPrimaryKey ?? false}
                visible={columns.includes(c.id)}
                valueAccessor={c.valueAccessor}
              />
            ))}
          </ColumnsDirective>
          <Inject services={[Selection, Resize]} />
        </GridComponent>
      )}
      {paginationComponent}
      <LoaderModal isOpen={isUpdatingShareStatus || isUpdatingBookmarkStatus} />
    </StyledNewsGrid>
  )
}
