import styled from 'styled-components'

export const StyledSpecsQuadricycleNewsArticleLink = styled.div``
export const StyledSpecsQuadricycleNewsArticle = styled.div`
  .jato-breadcrumbs,
  .newsCategoryAndArticleActions,
  .newsMetadataBlock,
  .newsRelatedArticles {
    display: none !important;
  }
`
