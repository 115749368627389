import {
  JATOBreadcrumbs,
  JATOBreadcrumbsItem,
  JATOButtonIcon,
  JATODropdown,
  JATODropdownMenu,
  JATONavBar,
  JATONavGroup,
  JATONavItem,
  JATOText,
  JATOTheme,
} from '@jato/ui-component-library'
import CarIcon from 'assets/icons/icon_car.svg'
import EarthIcon from 'assets/icons/icon_earth.svg'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { VolumesMenuItemButton } from '../VolumesMenuItemButton/VolumesMenuItemButton'
import { StyledVolumesNavigation } from './VolumesNavigation.styles'

interface IVolumesNavigationProps {
  title: string
}

export const VolumesNavigation: React.FC<IVolumesNavigationProps> = ({
  title,
}: IVolumesNavigationProps) => {
  const history = useHistory()
  const { pathname } = useLocation()

  const translations = useAppSelector(getTranslations)

  const isVolumesHome =
    pathname === RoutePaths.Volumes ||
    pathname.startsWith(RoutePaths.VolumesWizard)

  return (
    <StyledVolumesNavigation>
      <JATONavBar
        left={() =>
          isVolumesHome ? (
            <JATONavGroup flexDirection="row" space={16}>
              <JATONavItem>
                <JATOText
                  as="div"
                  fontSize={14}
                  marginTop={6}
                  marginBottom={6}
                  marginLeft={16}
                  marginRight={16}
                >
                  {title}
                </JATOText>
              </JATONavItem>
            </JATONavGroup>
          ) : (
            <JATOBreadcrumbs>
              <JATOBreadcrumbsItem
                className="volumesBreadcrumbsItem_Home"
                iconName="home_outline"
                title={translations.JNT_Nav_Volumes}
                url={RoutePaths.Volumes}
              />
              <JATOBreadcrumbsItem
                className="volumesBreadcrumbsItem"
                title={title}
                url={pathname}
              />
            </JATOBreadcrumbs>
          )
        }
        right={() => (
          <JATODropdown
            trigger={() => (
              <JATOButtonIcon
                id="volumesMainMenuButton"
                className="volumesMenuButton"
                iconName="baseline_more_horiz"
                iconFill={JATOTheme.colors.darkGrey}
              />
            )}
          >
            <JATODropdownMenu className="dropdown">
              <VolumesMenuItemButton
                title={translations.JNT_Volumes_MJ_QueryName}
                id="volumesMainMenuMyQueriesButton"
                iconName="custom_search"
                onClick={() => history.push(RoutePaths.VolumesMyQueries)}
              />
              <VolumesMenuItemButton
                title={translations.JNT_Volumes_QG_Dataset}
                id="volumesMainMenuVehicleVolumesTypeButton"
                iconSrc={CarIcon}
                onClick={() =>
                  history.push(RoutePaths.VolumesVehiclesVolumesType)
                }
              />
              <VolumesMenuItemButton
                title={translations.JNT_Volumes_My_country_grouping}
                id="volumesMainMenuMyCountryGroupingButton"
                iconSrc={EarthIcon}
                onClick={() =>
                  history.push(RoutePaths.VolumesMyCountryGrouping)
                }
              />
            </JATODropdownMenu>
          </JATODropdown>
        )}
      />
    </StyledVolumesNavigation>
  )
}
