import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsCarousel = styled.div`
grid-column: 1 / span 3;

.newsCarousel {
  height: 377px;
  width: calc(75vw - 40.25px);
}

.newsCarousel > div {
  padding: 0;
}

.e-carousel-navigators .e-btn {
  background-color: ${JATOTheme.colors.darkGrey} !important;
  opacity: 0.2;
}


.e-carousel .e-carousel-indicators.e-default .e-indicator-bar.e-active .e-indicator div {
  background-color: ${JATOTheme.colors.primary};
  border-color: ${JATOTheme.colors.primary};
`
