import { JATOIcon } from '@jato/ui-component-library'
import React, { useState } from 'react'
import { StyledAccordionToggle } from './AccordionToggle.styles'

interface IAccordionProps {
  id?: string
  titleText: string
  titleHeaderWidth: string
  isOpen: boolean
  onClick: () => void
}

export const AccordionToggle: React.FC<IAccordionProps> = ({
  id = 'accordionToggle',
  titleText,
  titleHeaderWidth,
  isOpen,
  onClick,
}: IAccordionProps) => {
  const [accordionStatus, setAccordionStatus] = useState<boolean>(isOpen)

  const iconName = accordionStatus
    ? 'baseline_expand_more'
    : 'baseline_navigate_next'

  const onClickInternal = (): void => {
    setAccordionStatus(!accordionStatus)
    onClick()
  }

  return (
    <StyledAccordionToggle>
      <div id={id} className="accordion" onClick={onClickInternal}>
        <div className="accordionTitle" style={{ width: titleHeaderWidth }}>
          <div className="accordionTitleIcon">
            <JATOIcon iconName={iconName} iconSize={18} />
          </div>
          <div className="accordionTitleText">{titleText}</div>
        </div>
      </div>
    </StyledAccordionToggle>
  )
}
