import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesQueryDefinitionStep = styled.div`
  padding: 0 60px;

  .jato-basic-card {
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.24),
      0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  button {
    font-weight: 500;
  }

  .queryStepHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -24px;
  }

  .queryStepContainer {
    height: calc(100vh - 406px);
    overflow: auto;
    margin: 0 -24px -24px;
    gap: 20px;
  }

  .queryStepContainer > div {
    width: calc(50% - 10px);
    margin: 0;
    font-size: 14px;
  }

  .selectionPanel {
    height: calc(100vh - 464px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 22px;
  }

  .subheader {
    background-color: ${JATOTheme.colors.midOffGrey};
    padding: 8px 24px;
    font-weight: 500;
    min-height: 18px;
  }
`
