import Loader from 'components/Loader'
import { VolumesQueriesGrid } from 'components/Volumes/VolumesQueriesGrid'
import { VolumesQueryColumnType } from 'helper/volumesHelper'
import { useLoadAllQueries } from 'hooks/volumes'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import { StyledMyPageMyVolumes } from './MyPageMyVolumes.styles'

export const MyPageMyVolumes: React.FC = () => {
  const userData = useAppSelector(getVolumesUserState)

  const { data: queries, isFetching } = useLoadAllQueries()

  const myQueries = queries?.filter((x) => x.qryOwnerId == userData.userId)

  return (
    <StyledMyPageMyVolumes>
      {isFetching ? (
        <Loader style={{ minHeight: '275px' }} />
      ) : (
        <VolumesQueriesGrid
          queries={myQueries}
          columns={[
            VolumesQueryColumnType.SearchName,
            VolumesQueryColumnType.LastRan,
          ]}
        />
      )}
    </StyledMyPageMyVolumes>
  )
}
