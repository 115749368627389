import styled from 'styled-components'

export const StyledNewsSearchOrderButtonSwitch = styled.div`
  .e-btn-group .e-btn:active,
  .e-btn-group input:active + label.e-btn,
  .e-btn-group input:checked + label.e-btn,
  .e-css.e-btn-group .e-btn:active,
  .e-css.e-btn-group input:active + label.e-btn,
  .e-css.e-btn-group input:checked + label.e-btn {
    background: rgb(68, 68, 68);
    color: white;
    outline: #fafafa 0 solid;
    outline-offset: 0;
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .e-btn {
    text-transform: none !important;
    font-weight: 400 !important;
  }
`
