import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledMyPageNavigation = styled.div`
  background-color: ${JATOTheme.colors.midOffGrey};
  padding-left: 15px;

  .jato-nav-bar {
    align-items: center;
    height: 40px;
  }

  .jato-nav-item > :first-child {
    padding-bottom: 6px;
  }
`
