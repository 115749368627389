import {
  JATOAnnouncement,
  JATOButton,
  JATOGroup,
  JATOSelect,
  JATOText,
  JATOTextarea,
  JATOTextInput,
  JATOTheme
} from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { useFormik } from 'formik'
import { CountryState } from 'models/Portal/CustomerRegistration/CountryState'
import { RegisterInfor } from 'models/Portal/CustomerRegistration/RegistorInfr'
import React from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import * as Yup from 'yup'

import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { IMappingObject } from 'helper/IMappingObject'
import {
  useLoadSalesEnquiryInfoData,
  useSubmitSalesEnquiry,
} from 'hooks/portal'
import { SalesEnquiryRequest } from 'models/Portal/SalesEnquiry/SalesEnquiryModel'
import moment from 'moment'
import { Col, Container, Row } from 'react-grid-system'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { JATOFormScreen } from './JATOFormScreen'
import { StyledSalesEnquiry } from './SalesEnquiry.styles'

export interface ISalesEnquiryProps {
  onClose: () => void
}

export const SalesEnquiry: React.FC<ISalesEnquiryProps> = ({
  onClose,
}: ISalesEnquiryProps) => {
  const translations = useAppSelector(getTranslations)
  const { languageId } = useAppSelector(selectCommonUserData)

  const { mutateAsync: submitSalesEnquiry } = useSubmitSalesEnquiry()

  const { data: salesEnquiryInfoData, isLoading } =
    useLoadSalesEnquiryInfoData()

  const getProductList: IMappingObject<any[]> = {
    ['JNT_Portal_Sales_News']: salesEnquiryInfoData?.newsCountries
      ? salesEnquiryInfoData?.newsCountries.map(
          (country: CountryState) => country.countryCode
        )
      : [],

    ['JNT_Portal_Sales_Volumes']: salesEnquiryInfoData?.volumesCountries
      ? salesEnquiryInfoData?.volumesCountries.map(
          (country: CountryState) => country.countryCode
        )
      : [],
    ['JNT_Portal_Sales_SpecsCars']: salesEnquiryInfoData?.carsCountries
      ? salesEnquiryInfoData?.carsCountries.map(
          (country: CountryState) => country.countryCode
        )
      : [],
    ['JNT_Portal_Sales_Incent']: salesEnquiryInfoData?.incentiveCountries
      ? salesEnquiryInfoData?.incentiveCountries.map(
          (country: CountryState) => country.countryCode
        )
      : [],
  }

  const formik = useFormik<SalesEnquiryRequest>({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      companyCountry: '',
      companyName: '',
      title: '',
      languageId: languageId,
      companyAddress: '',
      industry: '',
      telNumber: '',
      newsCountry: [],
      incentsCountry: [],
      carsCountry: [],
      volumesCountry: [],
      reqDate: moment().format('YYYY-MM-DD'),
      productKeys: [],
      otherHear: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(translations.JNT_Portal_Form_Field_Required),
      firstName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      lastName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      emailAddress: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .email(translations.JNT_Portal_Form_Email_Field_Required),
      companyName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      companyAddress: Yup.string()
        .max(200, ({ max }) => `only ${max} characters allowed`)
        .required(translations.JNT_Portal_Form_Field_Required),
      companyCountry: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      industry: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      telNumber: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .test(
          'PhoneCheck',
          translations.JNT_Portal_Invalid_Phone_Number,
          function (value) {
            return isValidPhoneNumber(value)
          }
        ),
      otherHear: Yup.string().max(
        600,
        ({ max }) => `only ${max} characters allowed`
      ),
    }),
    onSubmit: async (values: SalesEnquiryRequest) => {
      const updatedValues: SalesEnquiryRequest = {
        ...values,
        incentsCountry: values.productKeys.includes('JNT_Portal_Sales_Incent')
          ? getProductList['JNT_Portal_Sales_Incent']
          : [],
        volumesCountry: values.productKeys.includes('JNT_Portal_Sales_Volumes')
          ? getProductList['JNT_Portal_Sales_Volumes']
          : [],
        newsCountry: values.productKeys.includes('JNT_Portal_Sales_News')
          ? getProductList['JNT_Portal_Sales_News']
          : [],
        carsCountry: values.productKeys.includes('JNT_Portal_Sales_SpecsCars')
          ? getProductList['JNT_Portal_Sales_SpecsCars']
          : [],
      }

      const salesEnquiryResponse = await submitSalesEnquiry(updatedValues)
      if (salesEnquiryResponse.isSalesEnquirySuccess) {
        JATOAnnouncement.Create({
          title: '',
          children: (
            <div
              dangerouslySetInnerHTML={{
                __html: salesEnquiryResponse.responseMessage,
              }}
            />
          ),
        })
        onClose()
      }
    },
  })

  return (
    <StyledSalesEnquiry>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Container xl lg md sm xs style={{ padding: '0px 10px' }}>
            <Row>
              <Col md={3} lg={3} style={{ padding: '0px 0px' }}>
                <JATOFormScreen />
              </Col>
              <Col md={9} lg={9} style={{ paddingLeft: 12 }}>
                {formik.isSubmitting && <LoaderModal isOpen={true} />}
                <div
                  style={{ paddingBottom: '30px' }}
                  dangerouslySetInnerHTML={{
                    __html: translations.JNT_Portal_Sales_Desc,
                  }}
                />
                <form onSubmit={formik.handleSubmit}>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="firstName"
                        name="firstName"
                        label={translations.JNT_Portal_CustReg_Name}
                        title={translations.JNT_Portal_CustReg_Name}
                        type="text"
                        width={400}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.firstName}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOTextInput
                        id="lastName"
                        name="lastName"
                        label={translations.JNT_Portal_CustReg_Surname}
                        title={translations.JNT_Portal_CustReg_Surname}
                        type="text"
                        width={400}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.lastName}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="emailAddress"
                        name="emailAddress"
                        label={translations.JNT_Portal_CustReg_Email}
                        title={translations.JNT_Portal_CustReg_Email}
                        type="text"
                        width={400}
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.emailAddress &&
                      formik.errors.emailAddress ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.emailAddress}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOSelect
                        id="companyCountry"
                        label={translations.JNT_Portal_CustReg_Cntry}
                        dataSrc={
                          salesEnquiryInfoData?.countries
                            ? salesEnquiryInfoData?.countries.map(
                                (l: CountryState) => ({
                                  value: l.countryCode,
                                  displayValue: l.countryName,
                                })
                              )
                            : []
                        }
                        value={formik.values.companyCountry}
                        onChange={(value) =>
                          formik.setFieldValue('companyCountry', value)
                        }
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="400px"
                      />
                      {formik.touched.companyCountry &&
                      formik.errors.companyCountry ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyCountry}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="companyName"
                        name="companyName"
                        label={translations.JNT_Portal_Sales_Company}
                        title={translations.JNT_Portal_Sales_Company}
                        type="text"
                        width={400}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyName}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOTextInput
                        id="title"
                        name="title"
                        label={translations.JNT_Portal_Sales_JobTitle}
                        title={translations.JNT_Portal_Sales_JobTitle}
                        type="text"
                        width={400}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.title && formik.errors.title ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.title}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>

                  <JATOGroup flexDirection="row">
                    <div style={{ width: '48%' }}>
                      <JATOTextarea
                        id="companyAddress"
                        name="companyAddress"
                        label={translations.JNT_Portal_CustReg_Address}
                        title={translations.JNT_Portal_CustReg_Address}
                        rows={3}
                        value={formik.values.companyAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.companyAddress &&
                      formik.errors.companyAddress ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyAddress}
                        </JATOText>
                      ) : null}
                    </div>

                    <div>
                      <JATOSelect
                        id="industry"
                        label={translations.JNT_Portal_CustReg_Industry}
                        dataSrc={
                          salesEnquiryInfoData?.industry
                            ? salesEnquiryInfoData?.industry.map(
                                (l: RegisterInfor) => ({
                                  value: l.description,
                                  displayValue: l.description,
                                })
                              )
                            : []
                        }
                        value={formik.values.industry}
                        onChange={(value) =>
                          formik.setFieldValue('industry', value)
                        }
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="400px"
                      />
                      {formik.touched.industry && formik.errors.industry ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.industry}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <div className="label">
                        {translations.JNT_Portal_CustReg_TelNumber}
                      </div>

                      <PhoneInput
                        international
                        id="telNumber"
                        name="telNumber"
                        placeholder="Enter phone number"
                        defaultCountry="GB"
                        value={formik.values.telNumber}
                        type="text"
                        style={{ width: '400px' }}
                        onChange={(e) => formik.setFieldValue('telNumber', e)}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.telNumber && formik.errors.telNumber ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          display="flex"
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.telNumber}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>

                  <JATOGroup flexDirection="row">
                    <div>
                      <div className="label" style={{ width: '100%' }}>
                        {translations.JNT_Portal_Sales_Countries}
                      </div>
                      <div style={{ display: 'flex', gap: '40px' }}>
                        {Object.keys(getProductList).map(
                          (productKey, index) => (
                            <CheckboxWithGtm
                              key={index}
                              id="salesEnquiryProductCheckbox"
                              name="productKeys"
                              label={translations[productKey]}
                              title={translations[productKey]}
                              type="checkbox"
                              width={400}
                              value={productKey}
                              onChange={formik.handleChange}
                            >
                              {translations[productKey]}
                            </CheckboxWithGtm>
                          )
                        )}
                      </div>
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div style={{ width: '100%' }}>
                      <JATOTextarea
                        id="otherHear"
                        name="otherHear"
                        rows={3}
                        label={translations.JNT_Portal_Sales_OtherRequest}
                        title={translations.JNT_Portal_Sales_OtherRequest}
                        value={formik.values.otherHear}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.otherHear && formik.errors.otherHear ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.otherHear}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup
                    flexDirection="row"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <JATOButton
                      id="btnSalesEnquiry"
                      size="large"
                      variant="primary"
                      type="submit"
                      disabled={!(formik.dirty && formik.isValid)}
                    >
                      {translations.JNT_Portal_Sales_btnSubmit}
                    </JATOButton>
                  </JATOGroup>
                </form>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </StyledSalesEnquiry>
  )
}
