import {
  JATOButton,
  JATOButtonGroup,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { MpFilters } from 'components/MonthlyPayments/Filters/MpFilters'
import { fluid, mpFieldForCurrencyFormatting } from 'config'
import { formatCurrency } from 'helper/formatNumberValues'
import { getDisplayValue } from 'helper/getDisplayValue'
import {
  fieldNamesByUnits,
  specFilterNamesToFields,
} from 'helper/searchQueryHelper'
import {
  IFilterCategory,
  IFilterContainerProps,
} from 'models/Filters/FilterGroups'
import { FilterNameType } from 'models/Filters/FilterNameType'
import {
  IFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import { FilterType, FilterValueType } from 'models/Filters/FilterType'
import { MileageUnits } from 'models/Filters/FilterUnits'
import { ValueWithUnit } from 'models/Filters/ValueWithUnit'
import { IMpLicensedCountries } from 'models/Login/IMpLicensedCountries'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import {
  defaultSelectedDistanceUnits,
  getCachedOptions,
  selectedFilters,
} from 'redux/monthlypayments/selectedFiltersSlice'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { RoutePaths } from 'router/RoutePaths'
import { v4 as uuid } from 'uuid'

enum FiltersView {
  SpecFilters = 'SpecFilters',
  MpFilters = 'MpFilters',
}

export const MpFilterContainer: React.FC<IFilterContainerProps> = (params) => {
  const location = useLocation()
  const userData = useAppSelector(selectUserData)
  const currentPathName = location.pathname
  const filtersData = useAppSelector(selectedFilters)
  const cachedOptions = useAppSelector(getCachedOptions)
  const selectedFiltersList = filtersData

  const getSelectedFilterForCategory = (
    filterName: string
  ): ISelectedFilterOption | undefined => {
    const item: ISelectedFilterOption | undefined = filtersData.find(
      (item) => item.filterName === filterName
    )

    return item
  }

  //#region Filter Data

  const countryFilterName = FilterNameType.Country
  const makeFilterName = FilterNameType.Make
  const modelFilterName = FilterNameType.Model
  const modelYearFilterName = FilterNameType.ModelYear
  const timeRangeFilterName = FilterNameType.TimeRange
  const transmissionFilterName = FilterNameType.Transmission
  const powerTrainFilterName = FilterNameType.PowerTrain
  const fuelFilterName = FilterNameType.Fuel
  const numberOfDoorsFilterName = FilterNameType.NumberOfDoors
  const typeFilterName = FilterNameType.BodyType
  const drivenWheelsFilterName = FilterNameType.DrivenWheels
  const segmentationFilterName = FilterNameType.Segmentation
  const contractTypeFilterName = FilterNameType.ContractType
  const productNameFilterName = FilterNameType.ProductName
  const contractLengthFilterName = FilterNameType.ContractLength
  const milesKilometersFilterName = FilterNameType.MilesKilometers
  const yearlyMileageFilterName = FilterNameType.YearlyMileage
  const totalContractMileageFilterName = FilterNameType.TotalContractMileage
  const depositFilterName = FilterNameType.Deposit
  const numberOfMonthlyInstalmentsFilterName =
    FilterNameType.NumberOfMonthlyInstalments
  const regularMonthlyInstalmentAmountFilterName =
    FilterNameType.RegularMonthlyInstalmentAmount
  const insuranceFilterName = FilterNameType.Insurance

  const isCountryExists = (
    selectedFiltersData: ISelectedFilterOption
  ): boolean => selectedFiltersData.filterName === FilterNameType.Country

  const defaultSelectedCountryFilter = {
    value:
      filtersData.filter(isCountryExists).length > 0
        ? filtersData.filter(isCountryExists)[0]?.value
        : 'GB',
    key: uuid(),
    filterName: countryFilterName,
    displayValue:
      filtersData.filter(isCountryExists).length > 0
        ? filtersData.filter(isCountryExists)[0]?.displayValue
        : 'Great Britain',
    singularFilterCategory: true,
  }

  const getCountryOptions = (): IFilterOption[] => {
    const allowedValues = params.metaData?.metadata.country?.allowedValues
    const options: IFilterOption[] = userData.user.mpLicensedCountries
      .map((country: IMpLicensedCountries) => ({
        key: uuid(),
        value: country.countryCode,
        displayValue:
          (allowedValues && allowedValues[country.countryCode]) ??
          country.countryName,
        filterName: countryFilterName,
      }))
      .sort(compareByDisplayValue)

    return options
  }

  const compareNumbers = (a: { value: string }, b: { value: string }): number =>
    parseFloat(a.value) - parseFloat(b.value)

  function compareByDisplayValue(a: IFilterOption, b: IFilterOption): number {
    if (a.displayValue < b.displayValue) {
      return -1
    }
    if (a.displayValue > b.displayValue) {
      return 1
    }
    return 0
  }

  const getFormattedDisplayValue = (
    metadataName: string,
    allowedValues: any,
    value: string
  ): string =>
    mpFieldForCurrencyFormatting.includes(metadataName)
      ? formatCurrency(
          value,
          userData.user.settings.lastSelectedMarket.countryCode,
          2
        )
      : getDisplayValue(allowedValues, value)

  const getFilterOptions = (filterName: string): IFilterOption[] => {
    const hasCachedOptions = cachedOptions[0]?.filterName === filterName
    if (hasCachedOptions) {
      return cachedOptions
    }

    const fieldName = specFilterNamesToFields[filterName]
    if (!fieldName) {
      return []
    }

    const metadataName = fieldName.split('/').at(-1) ?? ''

    const facets = params?.facetsData && params.facetsData[fieldName]
    const metadata =
      params?.metaData?.metadata && params.metaData.metadata[metadataName]

    const options: IFilterOption[] =
      facets?.map((facet: any) => ({
        key: uuid(),
        value: facet.value.toString(),
        displayValue: getFormattedDisplayValue(
          metadataName,
          metadata?.allowedValues,
          facet.value
        ),
        filterName: filterName,
        count: facet.count,
        type:
          typeof facet.value === 'number'
            ? FilterValueType.Number
            : FilterValueType.String,
      })) ?? []

    // add options which are already selected but missing in facets
    selectedFiltersList
      .filter(
        (selectedFilter) =>
          selectedFilter.filterName === filterName &&
          !options.some((o) => o.value === selectedFilter.value)
      )
      .forEach((selectedFilter) => {
        options.push({ ...selectedFilter, key: uuid(), count: 0 })
      })

    const compareFn =
      options.length > 0 && options[0].type == FilterValueType.Number
        ? compareNumbers
        : compareByDisplayValue

    return options.sort(compareFn)
  }

  const getMakeModelOptions = (): IFilterOption[] =>
    getFilterOptions(FilterNameType.Model).map((option) => {
      const makeModel = option.value && option.value.split(':')

      return makeModel && makeModel.length === 2
        ? { ...option, group: `${makeModel[0]}  `, displayValue: makeModel[1] }
        : option
    })

  const getMultiUnitsOptions = (filterName: string): IFilterOption[] => {
    const facetNamesByUnits = fieldNamesByUnits[filterName] ?? {}
    const facetValues = Object.keys(facetNamesByUnits)
      .map((unitName) => {
        const facetName = facetNamesByUnits[unitName]
        const facets = params?.facetsData && params.facetsData[facetName]
        return (
          facets?.sort(compareNumbers).map((facet: any) => ({
            value: facet.value,
            unitName,
            count: facet.count,
          })) ?? []
        )
      })
      .flat()

    const options = facetValues.map(({ value, unitName: unit, count }) => ({
      key: uuid(),
      value: new ValueWithUnit(value, unit).toString(),
      displayValue: value.toString(),
      filterName: filterName,
      count: count,
      type: FilterValueType.ValueWithUnit,
    }))

    return options
  }

  const countryFilter: IFilterCategory = {
    name: countryFilterName,
    filterType: FilterType.Market,
    id: countryFilterName,
    selectedFilter: { ...defaultSelectedCountryFilter },
    options: getCountryOptions(),
  }

  const jatoRegionalSegment: IFilterCategory = {
    name: segmentationFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: segmentationFilterName,
    options: getFilterOptions(segmentationFilterName),
  }

  const modelYearFilter: IFilterCategory = {
    name: modelYearFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: modelYearFilterName,
    options: getFilterOptions(modelYearFilterName),
  }

  const makeFilter: IFilterCategory = {
    name: makeFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: makeFilterName,
    options: getFilterOptions(makeFilterName),
  }

  const modelFilter: IFilterCategory = {
    name: modelFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: modelFilterName,
    options: getMakeModelOptions(),
  }

  const transmissionFilter: IFilterCategory = {
    name: transmissionFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: transmissionFilterName,
    options: getFilterOptions(transmissionFilterName),
  }

  const powerTrainFilter: IFilterCategory = {
    name: powerTrainFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: powerTrainFilterName,
    options: getFilterOptions(powerTrainFilterName),
  }

  const fuelFilter: IFilterCategory = {
    name: fuelFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: fuelFilterName,
    options: getFilterOptions(fuelFilterName),
  }

  const numOfDoorsFilter: IFilterCategory = {
    name: numberOfDoorsFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: numberOfDoorsFilterName,
    options: getFilterOptions(numberOfDoorsFilterName),
  }

  const bodyTypeFilter: IFilterCategory = {
    name: typeFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: typeFilterName,
    options: getFilterOptions(typeFilterName),
  }

  const drivenWheelsFilter: IFilterCategory = {
    name: drivenWheelsFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: drivenWheelsFilterName,
    options: getFilterOptions(drivenWheelsFilterName),
  }
  const uIdFilter = {
    name: FilterNameType.Uid,
    filterType: FilterType.SearchBoxInput,
    id: FilterNameType.Uid,
    options: [],
  }

  const specsFilters: IFilterCategory[] = [
    countryFilter,
    jatoRegionalSegment,
    modelYearFilter,
    makeFilter,
    modelFilter,
    powerTrainFilter,
    fuelFilter,
    transmissionFilter,
    bodyTypeFilter,
    numOfDoorsFilter,
    drivenWheelsFilter,
    uIdFilter,
  ]

  const contractLengthDurationFilter = {
    name: contractLengthFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: contractLengthFilterName,
    options: getFilterOptions(contractLengthFilterName),
  }

  const contractTypeFilter = {
    name: contractTypeFilterName,
    filterType: FilterType.DropdownWithSearch,
    id: contractTypeFilterName,
    options: getFilterOptions(contractTypeFilterName),
  }

  const depositMSRPFilter = {
    name: depositFilterName,
    filterType: FilterType.Slider,
    id: depositFilterName,
    options: getFilterOptions(depositFilterName),
  }

  const productNameFilter = {
    name: productNameFilterName,
    id: productNameFilterName,
    filterType: FilterType.DropdownWithSearch,
    options: getFilterOptions(productNameFilterName),
  }

  const regularMonthlyInstalmentAmountFilter = {
    name: regularMonthlyInstalmentAmountFilterName,
    filterType: FilterType.Slider,
    id: regularMonthlyInstalmentAmountFilterName,
    options: getFilterOptions(regularMonthlyInstalmentAmountFilterName),
  }

  const yearlyMileageFilter: IFilterCategory = {
    name: yearlyMileageFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: yearlyMileageFilterName,
    options: getMultiUnitsOptions(yearlyMileageFilterName),
  }

  const totalContractMileageFilter: IFilterCategory = {
    name: totalContractMileageFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: totalContractMileageFilterName,
    options: getMultiUnitsOptions(totalContractMileageFilterName),
  }

  const numberOfMonthlyInstalmentsFilter = {
    name: numberOfMonthlyInstalmentsFilterName,
    filterType: FilterType.MultiSelect,
    singularCategory: false,
    id: numberOfMonthlyInstalmentsFilterName,
    options: getFilterOptions(numberOfMonthlyInstalmentsFilterName),
  }

  const mileageKmsFilter: IFilterCategory = {
    name: milesKilometersFilterName,
    filterType: FilterType.MilesKilometersFilter,
    id: milesKilometersFilterName,
    singularCategory: true,
    selectedFilter: { ...defaultSelectedDistanceUnits },
    options: [
      defaultSelectedDistanceUnits,
      {
        displayValue: MileageUnits.Kilometres,
        value: MileageUnits.Kilometres,
        key: uuid(),
        filterName: milesKilometersFilterName,
      },
    ],
    childFilters: [yearlyMileageFilter, totalContractMileageFilter],
  }

  const insuranceFilter = {
    name: insuranceFilterName,
    filterType: FilterType.Insurance,
    id: insuranceFilterName,
    singularCategory: true,
    options: [],
  }

  const mpfilters: IFilterCategory[] = [
    contractTypeFilter,
    contractLengthDurationFilter,
    productNameFilter,
    mileageKmsFilter,
    depositMSRPFilter,
    numberOfMonthlyInstalmentsFilter,
    regularMonthlyInstalmentAmountFilter,
    insuranceFilter,
  ]

  const getFiltersValue = (filter: IFilterCategory): string => {
    const concatenatedValues = selectedFiltersList
      .filter((f) => f.filterName === filter.name)
      .map((f) => f.value)
      .join(',')

    return concatenatedValues
  }

  const [filtersView, setFiltersView] = useState(
    currentPathName === RoutePaths.MonthlyPaymentsReport
      ? FiltersView.MpFilters
      : FiltersView.SpecFilters
  )

  const filters =
    filtersView === FiltersView.SpecFilters ? specsFilters : mpfilters

  return (
    <Container fluid={fluid}>
      <Row>
        <Col>
          <div id="filter-container">
            <JATOButtonGroup justifyContent={'center'}>
              <JATOButton
                id="monthlyPaymentsSpecFiltersButton"
                variant={
                  filtersView === FiltersView.MpFilters
                    ? 'secondary'
                    : 'primary'
                }
                display="inline-flex"
                size={'medium'}
                onClick={() => setFiltersView(FiltersView.SpecFilters)}
                disabled={currentPathName === RoutePaths.MonthlyPaymentsReport}
              >
                SPEC FILTERS
              </JATOButton>
              <JATOButton
                id="monthlyPaymentsMpFiltersButton"
                variant={
                  filtersView === FiltersView.MpFilters
                    ? 'primary'
                    : 'secondary'
                }
                display="inline-flex"
                size={'medium'}
                onClick={() => setFiltersView(FiltersView.MpFilters)}
              >
                MP FILTERS
              </JATOButton>
            </JATOButtonGroup>
            <JATOVerticalSpacer height={10} />
            {filters.map((filter: IFilterCategory) => (
              <MpFilters
                key={filter.id}
                id={'monthlyPayments' + filtersView}
                name={filter.name}
                placeholder={filter.placeholder}
                filterType={filter.filterType}
                options={filter.options}
                value={getFiltersValue(filter)}
                selectedFilter={getSelectedFilterForCategory(filter.name)}
                singularCategory={filter.singularCategory}
                childFilters={filter.childFilters}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
