import { JATOTextInput } from '@jato/ui-component-library'
import React from 'react'
interface IUidFilter {
  id: string
  label?: string
  value?: string
  onChange: (value: string) => void
}

export const UniqueIdentityFilter: React.FC<IUidFilter> = ({
  id,
  label,
  value,
  onChange,
}: IUidFilter) => {
  const onChangeUid = (value: string): void => {
    if (value.length > 0) {
      onChange(value)
    }
  }
  const onBlurUid = (event: React.FocusEvent<HTMLInputElement>): void => {
    onChangeUid(event.currentTarget.value)
  }

  const onKeyPressUid = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      onChangeUid(event.currentTarget.value)
    }
  }

  return (
    <>
      <div>
        <label>{label}</label>
      </div>
      <JATOTextInput
        onBlur={(event: any) => onBlurUid(event)}
        onKeyPress={onKeyPressUid}
        type="text"
        width="100%"
        defaultValue={value}
        placeholder="Unique Identity e.g. 8273576,8025979"
        id={id}
        title="Unique Identity e.g. 8273576,8025979"
      />
    </>
  )
}
