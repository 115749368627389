import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesMyQueries = styled.div`
  background-color: ${JATOTheme.colors.white};
  margin: 0 -15px;

  .volumesContainer {
    position: relative;
    height: calc(100vh - 190px);
    overflow-y: auto;
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 20px;
  }
`
