import React from 'react'
import { StyledAcceptJATOTermAndCondition } from './AcceptJATOTermAndCondition.styles'
import {
  JATOModal,
  JATOHeading,
  JATOTheme,
  JATOIcon,
  JATOButtonGroup,
  JATOButton,
} from '@jato/ui-component-library'
import { useUpdateAcceptLicenceData } from 'hooks/portal'
import { Terms } from './Terms'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

export interface IAcceptJATOTermAndCondition {
  showAcceptTermAndCondition: boolean
  onCloseEvent: (showAcceptTermAndCondition: boolean) => void
}

export const AcceptJATOTermAndCondition: React.FC<
  IAcceptJATOTermAndCondition
> = ({
  showAcceptTermAndCondition,
  onCloseEvent,
}: IAcceptJATOTermAndCondition) => {
  const translations = useAppSelector(getTranslations)
  const { mutateAsync: updateAcceptLicenceSetting } =
    useUpdateAcceptLicenceData()

  const onCloseButton = async (acceptLicence: boolean): Promise<void> => {
    acceptLicence && (await updateAcceptLicenceSetting())

    onCloseEvent(acceptLicence)
  }

  return (
    <StyledAcceptJATOTermAndCondition>
      <JATOModal
        isOpen={showAcceptTermAndCondition}
        maxWidth={'md'}
        dismissable={false}
        header={() => (
          <>
            <JATOHeading
              as="h4"
              fontWeight="medium"
              display="flex"
              color={JATOTheme.colors.blue}
            >
              {translations.JNT_Portal_Login_LicenceAgreement}
            </JATOHeading>
            <JATOIcon
              style={{ paddingLeft: '10px' }}
              iconName="baseline_info"
            />
          </>
        )}
        footer={() => (
          <>
            <JATOButtonGroup justifyContent={'flex-end'}>
              <JATOButton
                id="closeWelcomePopup"
                variant="primary"
                size="large"
                onClick={() => onCloseButton(true)}
              >
                {'Yes'}
              </JATOButton>
              <JATOButton
                id="closeWelcomePopup"
                variant="primary"
                size="large"
                onClick={() => onCloseButton(false)}
              >
                {'No'}
              </JATOButton>
            </JATOButtonGroup>
          </>
        )}
      >
        <Terms />
      </JATOModal>
    </StyledAcceptJATOTermAndCondition>
  )
}
