import { ResetMonthlyPaymentsNavItem } from 'components/MonthlyPayments/ResetMonthlyPaymentsNavItem/ResetMonthlyPaymentsNavItem'
import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import React from 'react'
import { BasketNavItem } from '../BasketNavItem/BasketNavItem'

export const MpNavigation: React.FC = () => (
  <NavigationContainer
    left={
      <>
        <BasketNavItem />
      </>
    }
    right={
      <>
        <ResetMonthlyPaymentsNavItem />
      </>
    }
  />
)
