import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { NewsBreadcrumbsItem } from 'models/News/NewsBreadcrumbsItem'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'

interface INewsBreadcrumbsProps {
  title: string
}

export const NewsBreadcrumbs: React.FC<INewsBreadcrumbsProps> = ({
  title,
}: INewsBreadcrumbsProps) => {
  const location = useLocation()
  const translations = useAppSelector(getTranslations)
  const breadcrumbsItemList: NewsBreadcrumbsItem[] = [
    {
      iconName: 'home_outline',
      title: `JATO ${translations.JNT_Nav_News}`,
      url: RoutePaths.News,
    },
    { title: title, url: location.pathname },
  ]

  return <Breadcrumbs breadcrumbItemList={breadcrumbsItemList} />
}
