export const formatString = (str: string, ...args: string[]): string =>
  args.reduce((s, v) => s.replace('%s', v), str)

export const commaSeparatedStringToStringArray: (
  commaSeparatedString: string
) => string[] = (commaSeparatedString) =>
  // Split the string by comma and map each string to trim any leading or trailing whitespace
  commaSeparatedString.split(',').map((element) => element.trim())

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
