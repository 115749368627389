import { rtlLanguageIds, rtlMarkets } from 'config'
import { SiteRootType } from 'helper/newsHelper'
import { getSiteDirectionForMarket } from 'helper/rtlHelper'
import { useLocation } from 'react-router-dom'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getNewsSelectedFilters, getNewsUserState } from 'redux/news/newsSlice'
import { getSpecsUserState } from 'redux/specifications/specificationsSlice'

export const useEnableRtl = (): boolean => {
  const location = useLocation()
  const { settings } = useAppSelector(getSpecsUserState)
  const { userSpecificSettings } = useAppSelector(getNewsUserState)
  const newsSelectedFilters = useAppSelector(getNewsSelectedFilters)
  const routeName = location.pathname.split('/')[1]
  const countryList = newsSelectedFilters['country']
  const { languageId } = useAppSelector(selectCommonUserData)

  const getLastSelectedMarket = (routeName: string): string | undefined => {
    switch (routeName) {
      case SiteRootType.Specifications.toString():
        return settings.lastSelectedMarket
      case SiteRootType.News.toString():
        if (
          countryList &&
          countryList.length === 1 &&
          rtlMarkets.includes(countryList[0])
        ) {
          return countryList[0]
        }
        return userSpecificSettings.countryCode
      default:
        return ''
    }
  }

  const lastSelectedMarket = getLastSelectedMarket(routeName)

  const direction = [
    SiteRootType.Specifications.toString(),
    SiteRootType.News.toString(),
  ].includes(routeName)
    ? getSiteDirectionForMarket(String(languageId), lastSelectedMarket)
    : [SiteRootType.Home.toString(), SiteRootType.MyPage.toString()].includes(
          routeName
        ) && rtlLanguageIds.includes(languageId.toString())
      ? 'rtl'
      : 'ltr'

  return direction === 'rtl'
}
