import styled from 'styled-components'

export const StyledForgotPasswordReset = styled.div`
  .jato-button-icon:focus {
    box-shadow: none;
  }
  .jato-button-icon:enabled {
    background-color: white;
  }
  .showPasswordButton {
    padding-left: 10px;
    padding-top: 20px;
  }
`
