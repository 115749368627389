import { JATOButton, JATOIcon, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { StyledVolumesMenuItemButton } from './VolumesMenuItemButton.styles'

export interface IVolumesMenuItemButtonProps {
  title: string
  id: string
  iconSrc?: string
  iconName?: string
  disabled?: boolean
  onClick: () => void
}

export const VolumesMenuItemButton: React.FC<IVolumesMenuItemButtonProps> = ({
  title,
  id,
  disabled,
  iconSrc,
  iconName,
  onClick,
}: IVolumesMenuItemButtonProps) => (
  <StyledVolumesMenuItemButton>
    <JATOButton
      disabled={disabled}
      id={id}
      variant="ghost"
      display="flex"
      className="menuHeaderItem"
      onClick={onClick}
    >
      <div className="menuHeaderIcon">
        {iconSrc ? (
          <img src={iconSrc} />
        ) : iconName ? (
          <JATOIcon iconName={iconName} iconFill={JATOTheme.colors.darkGrey} />
        ) : (
          <></>
        )}
      </div>
      {title}
    </JATOButton>
  </StyledVolumesMenuItemButton>
)
