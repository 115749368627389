import { StepConnector, stepConnectorClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

export const VolumesStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'rgb(196, 0, 5)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'rgb(196, 0, 5)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

export const VolumesStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  ...(ownerState.active && {
    backgroundColor: 'rgb(196, 0, 5)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    cursor: 'pointer',
    pointerEvents: 'all',
  }),
  ...(ownerState.completed && {
    backgroundColor: 'rgb(196, 0, 5)',
    cursor: 'pointer',
    pointerEvents: 'all',
  }),
}))
