import {
  createToast,
  JATOGroup,
  JATOTextInput,
} from '@jato/ui-component-library'
import { Loader } from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { CommonModal, THandleClose } from 'components/Modals/CommonModal'
import { useGetRecipes } from 'hooks/carSpecs'
import {
  useGetBaskets,
  useSaveBasket,
  useUpdateBasket,
} from 'hooks/specsBasketManagement'
import { SaveBasketResponse } from 'models/Specifications/Basket/SaveBasketResponse'
import { UpdateBasketRequest } from 'models/Specifications/Basket/UpdateBasketRequest'
import {
  DetailedAction,
  InstructionType,
} from 'models/Specifications/Options/DetailedAction'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getSpecsUserState } from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'

interface ISaveBasketProps {
  isOpen: boolean
  onClose: () => void
  basketId?: string
  isRename: boolean
}

export const SaveBasketModal: React.FC<ISaveBasketProps> = ({
  isOpen,
  onClose,
  basketId,
  isRename,
}: ISaveBasketProps) => {
  const translations = useAppSelector(getTranslations)
  const [basketName, setBasketName] = useState('')
  const errorText = translations.JNT_BasketNameExist
  const userData = useAppSelector(getSpecsUserState)

  const { data: baskets, isLoading: isLoading } = useGetBaskets()
  const { data: recipes, isFetching: isFetchingRecipes } = useGetRecipes()
  const basket = baskets?.find((b) => b.id === basketId)

  useEffect(() => {
    basket && setBasketName(basket.basketName)
  }, [basket])

  const { mutateAsync: saveBasket, isLoading: isSaving } = useSaveBasket()
  const { mutateAsync: updateBasket, isLoading: isUpdating } = useUpdateBasket()

  const basketNameExists = (basketName: string): boolean =>
    baskets?.some(
      (b) =>
        b.id !== basket?.id &&
        b.basketName.trim().toLowerCase() === basketName.trim().toLowerCase()
    ) ?? false

  const addOrUpdateBasket = (): Promise<SaveBasketResponse> => {
    if (!!basket && isRename) {
      const basketUpdate: UpdateBasketRequest = {
        basketData: { ...basket, basketName: basketName.trim() },
        renamekey: true,
      }
      return updateBasket(basketUpdate)
    } else {
      return saveBasket({
        name: basketName.trim(),
        id: basket?.id,
        detailedActionOptionBuildInfoList: recipes?.filter(
          (detailedAction: DetailedAction) =>
            detailedAction.userInstruction === InstructionType.BuildOption
        ),
      })
    }
  }

  const successMessage = !!basket
    ? translations.JNT_UpdatedSuccess
    : translations.JNT_SavedSuccess

  const handleSaveBasket = (): Promise<void> =>
    addOrUpdateBasket().then((response) => {
      if (response.isSaved) {
        createToast({
          children: <div>{successMessage}</div>,
        })
      } else {
        createToast({
          children: <div>Error</div>,
        })
      }
    })

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const basketName = event.currentTarget.value
    setBasketName(basketName)
  }

  const hasError = (): boolean => basketNameExists(basketName)

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      closeButton
      maxWidth="sm"
      title={!!basket ? 'Edit basket' : 'Add Basket'}
      primaryButtonName={translations.JNT_SaveBasket}
      disablePrimaryButton={
        hasError() ||
        basketName.length === 0 ||
        (isRename && basketName === basket?.basketName)
      }
      onPrimaryClick={({ handleClose }: THandleClose) =>
        handleSaveBasket().then(handleClose)
      }
    >
      {isLoading && isFetchingRecipes ? (
        <Loader style={{ minHeight: '100%' }} />
      ) : (
        <JATOGroup flexDirection="row">
          <JATOTextInput
            id=""
            width={430}
            placeholder={translations.JNT_EnterBasketName}
            onChange={onChange}
            hasError={hasError()}
            value={basketName}
            validateText={hasError() ? errorText : ''}
          />
        </JATOGroup>
      )}
      <LoaderModal isOpen={isSaving || isUpdating} />
    </CommonModal>
  )
}
