import React from 'react'
import { StyledGuestLayout } from './styles'

interface IProps {
  children: React.ReactNode
}

/**
 * This layout includes no decoration at all and can be used before a user has
 * logged in.
 */
export const GuestLayout: React.FC<IProps> = ({ children }: IProps) => (
  <StyledGuestLayout>{children}</StyledGuestLayout>
)

export default GuestLayout
