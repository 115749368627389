import Loader from 'components/Loader'
import { useGetBaskets } from 'hooks/specsBasketManagement'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import { MyPageSpecsBasketsGrid } from '../MyPageSpecsBasketsGrid'
import { StyledMyPageMySpecifications } from './MyPageMySpecifications.styles'

export const MyPageMySpecifications: React.FC = () => {
  const userData = useAppSelector(getVolumesUserState)

  const { data: baskets, isFetching } = useGetBaskets()

  const userBaskets = baskets?.filter((b) => b.userId === userData.userId) ?? []

  return (
    <StyledMyPageMySpecifications>
      {isFetching ? (
        <Loader style={{ minHeight: '275px' }} />
      ) : (
        <MyPageSpecsBasketsGrid baskets={userBaskets} />
      )}
    </StyledMyPageMySpecifications>
  )
}
