export enum DataLayerVariableName {
  EmailDomain = 'emailDomain',
  LanguageId = 'languageId',
  LastSelectedMarket = 'lastSelectedMarket',
  ElementId = 'elementId',
  ElementLabel = 'elementLabel',
  ElementParentLabel = 'elementParentLabel',
  ElementValue = 'elementValue',
  IsSelected = 'isSelected',
  SearchText = 'searchText',
  ExactMatch = 'exactMatch',
  Makes = 'makes',
  Models = 'models',
  Countries = 'countries',
  Categories = 'categories',
  Subjects = 'subjects',
  Group = 'group',
  IsShared = 'isShared',
  IsAlert = 'isAlert',
  SubscriptionType = 'subscriptionType',
  DatabaseType = 'databaseType',
  DataSets = 'dataSets',
  Periods = 'periods',
  FilterName = 'filterName',
  Values = 'values',
  DisplayValues = 'displayValues',
  OptionId = 'optionId',
}
