import { MileageUnits } from 'models/Filters/FilterUnits'

export const distanceMapper = (countryCode?: string): any => {
  switch (countryCode ?? 'GB') {
    case 'GB':
      return MileageUnits.Miles
    default:
      return MileageUnits.Kilometres
  }
}
