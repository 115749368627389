import React from 'react'

export interface ImageButtonNavItemProps {
  src: string
  title: string
  id: string
  onClick?: () => void
}

export const ImageButtonNavItem: React.FC<ImageButtonNavItemProps> = ({
  src,
  title,
  id,
  onClick,
}: ImageButtonNavItemProps) => (
  <img
    onClick={onClick}
    id={id}
    src={src}
    title={title}
    width="22"
    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
  />
)
