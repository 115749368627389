import styled from 'styled-components'

export const StyledNewsCards = styled.div`
  .newsCardsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 5px 0;
  }

  .newsCardFooterLink {
    display: none;
  }

  .newsCardFooter {
    justify-content: flex-end;
  }
`
