export enum TrainingVideoType {
  News = 0,
  Volumes = 1,
  Specifications = 2,
  OesVolumes = 3,
  MonthlyPayments = 4,
  NewsWelcome = 5,
  NewsMonthlyPaymentsWelcome = 6,
  JATOAcademyTrainingVideo = 7,
}
