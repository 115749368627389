import {
  JATOButton,
  JATODropdown,
  JATODropdownMenu,
  JATOIcon,
  JATOTooltip
} from '@jato/ui-component-library'
import SaveIcon from 'assets/icons/icon_save2.svg'
import exportFileIcon from 'assets/icons/icon_xml_file_fill.svg'
import { ReportAnalysisOptions } from 'models/Volumes/ReportAnalysisOptions'

import { ImageButtonNavItem } from 'components/CommonNavItems/ImageButtonNavItem/ImageButtonNavItem'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useVolumesExportToExcel } from 'hooks/volumes'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  resetExcelExportResponseState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { StyledVolumesReportActions } from './VolumesReportAction.styles'
import { VolumesSaveQueryModal } from './VolumesSaveQueryModal'

export const VolumesReportActions: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const queryState = useAppSelector(getVolumesQueryState)

  const dispatch = useAppDispatch()

  const [showSaveQueryModal, setShowSaveModal] = useState(false)

  const { mutateAsync: exportToExcelAsync } = useVolumesExportToExcel()

  const onSaveClick = () => {
    setShowSaveModal(true)
  }

  const onAnalyseOptionsClick = (option: ReportAnalysisOptions) => {
    dispatch(resetExcelExportResponseState())

    dispatch(
      setVolumesQueryState({
        ...queryState,
        analysisOptions: queryState.analysisOptions?.includes(option)
          ? queryState.analysisOptions?.filter((x) => x !== option)
          : [...(queryState.analysisOptions || []), option],
      })
    )
  }

  const exportVolumesReportToExcel = async (): Promise<void> => {
    const result = await exportToExcelAsync()
    result.success
      ? showSuccessToast('', result.message)
      : showErrorToast('', result.message)
  }

  return (
    <StyledVolumesReportActions>
      <JATODropdown
        className="analyseDropdown"
        trigger={() => (
          <JATOButton
            variant="ghost"
            id="volumesReportStepAnalysisOptionsMenuButton"
            title={translations.JNT_Volumes_FP_AnalysisOptions}
            className="dropdownText"
          >
            {translations.JNT_Volumes_FP_AnalysisOptions}
            <JATOIcon iconName="custom_down" iconSize={16} />
          </JATOButton>
        )}
      >
        <JATODropdownMenu className="dropdown">
          <CheckboxWithGtm
            id="volumesReportStepAnalysisOptionsMenuReportShareCheckbox"
            label={translations.JNT_Volumes_FP_ReportShare}
            checked={queryState.analysisOptions?.includes(
              ReportAnalysisOptions.ReportShare
            )}
            onChange={() =>
              onAnalyseOptionsClick(ReportAnalysisOptions.ReportShare)
            }
          >
            {translations.JNT_Volumes_FP_ReportShare}
          </CheckboxWithGtm>
          <CheckboxWithGtm
            id="volumesReportStepAnalysisOptionsMenuJatoGlobalSegmentShareCheckbox"
            label={translations.JNT_Volumes_FP_JatoGlobalSegmentShare}
            checked={queryState.analysisOptions?.includes(
              ReportAnalysisOptions.JATOGlobalSegmentShare
            )}
            onChange={() =>
              onAnalyseOptionsClick(
                ReportAnalysisOptions.JATOGlobalSegmentShare
              )
            }
          >
            {translations.JNT_Volumes_FP_JatoGlobalSegmentShare}
          </CheckboxWithGtm>
          <CheckboxWithGtm
            id="volumesReportStepAnalysisOptionsMenuVehicleColumnsTypeShareCheckbox"
            label={translations.JNT_Volumes_FP_VehicleColumnsTypeShare}
            checked={queryState.analysisOptions?.includes(
              ReportAnalysisOptions.VehicleColumnsTypeShare
            )}
            onChange={() =>
              onAnalyseOptionsClick(
                ReportAnalysisOptions.VehicleColumnsTypeShare
              )
            }
          >
            {translations.JNT_Volumes_FP_VehicleColumnsTypeShare}
          </CheckboxWithGtm>
          <CheckboxWithGtm
            id="volumesReportStepAnalysisOptionsMenuNumericPercentageChangeCheckbox"
            label={translations.JNT_Volumes_FP_NumericPercentageChange}
            checked={queryState.analysisOptions?.includes(
              ReportAnalysisOptions.NumericPercentageChange
            )}
            onChange={() =>
              onAnalyseOptionsClick(
                ReportAnalysisOptions.NumericPercentageChange
              )
            }
          >
            {translations.JNT_Volumes_FP_NumericPercentageChange}
          </CheckboxWithGtm>
        </JATODropdownMenu>
      </JATODropdown>
      <ImageButtonNavItem
        id="volumesReportStepExcelExportButton"
        onClick={exportVolumesReportToExcel}
        src={exportFileIcon}
        title={translations.JNT_ExcelExport}
      />
      <img
        id="volumesReportStepSaveQueryButton"
        src={SaveIcon}
        onClick={onSaveClick}
        title={translations.JNT_Volumes_FP_SaveQuery}
        style={{
          cursor: 'pointer',
          marginBottom: '5px',
          marginLeft: '5px',
        }}
      />
      <JATOTooltip
        title={translations.JNT_Volumes_FP_ManageQueryReport}
        arrow={true}
        placement="left-start"
        showOnlyOnOverflow={false}
      >
        <JATOIcon iconName="baseline_info" />
      </JATOTooltip>

      {showSaveQueryModal && (
        <VolumesSaveQueryModal
          isOpen={showSaveQueryModal}
          onClose={() => setShowSaveModal(false)}
        />
      )}
    </StyledVolumesReportActions>
  )
}
