import styled from 'styled-components'
export const StyledVolumesFaq = styled.div`
  padding: 15px;
  height: 400px;
  overflow: auto;

  .icon-style {
    color: #4392b5;
  }

  .link-text {
    color: #4392b5;
    cursor: pointer;
  }

  .custom-span {
    font-family: 'Roboto Light';
    color: #c40005;
  }

  .inline-link {
    display: inline;
  }

  .block-content {
    display: block;
  }
`
