import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { gtmLogMarketChange } from 'helper/gtm'
import { LoginRequest } from 'models/Login/LoginRequest'
import { LoginResponse } from 'models/Login/LoginResponse'
import { UserData } from 'models/Login/UserData'
import { IMpLicensedCountry } from 'models/UserSettings/UserSettings'
import { RootState } from 'redux/store'
import authService from 'services/AuthService'

const initialState: LoginResponse = {
  isSuccess: false,
  user: {
    guid: '',
    userId: 0,
    usrgrpid: 0,
    userName: '',
    languageId: 0,
    settings: {
      id: '',
      lastSelectedMarket: { countryCode: '', countryName: '' },
      userId: 0,
      userGroupId: 0,
      lastAccessDate: undefined,
      updateDate: undefined,
    },
    mpLicensedCountries: [],
  },
  responseMessage: '0',
  accessTokenResponse: {
    accessToken: '',
    scope: '',
    expiresIn: 0,
    tokenType: '',
    tokenValidUntil: 0,
  },
}

export const fetchUserDataAsync = createAsyncThunk(
  'userData/fetchUserData',
  async (loginRequest: LoginRequest) => {
    const userData = await authService.getJatonetUserData(loginRequest)
    return userData
  }
)

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    resetLoginStatus(state, { payload }: PayloadAction<LoginResponse>) {
      return { ...state, ...payload }
    },
    resetLastSelectedMarket(
      state,
      { payload }: PayloadAction<IMpLicensedCountry>
    ) {
      gtmLogMarketChange(payload.countryCode)
      state.user.settings.lastSelectedMarket = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDataAsync.pending, (state) => {
        state.responseMessage = 'loading'
      })
      .addCase(fetchUserDataAsync.fulfilled, (state, action) => {
        state.responseMessage = 'fullfilled'
        state.isSuccess = action.payload.data.isSuccess
        state.user = action.payload.data.user as UserData
      })
      .addCase(fetchUserDataAsync.rejected, (state) => {
        state.responseMessage = 'failed'
      })
  },
})

export const { resetLoginStatus, resetLastSelectedMarket } =
  userDataSlice.actions

export const selectUserData = (state: RootState): LoginResponse =>
  state.monthlypayments.userData

export default userDataSlice.reducer
