import { AxiosResponse } from 'axios'
import { logTrace } from 'helper/loggingService'
import { LoginObject } from 'models/Login/LoginObject'
import { LoggedUserInfo } from 'models/Login/NewsLogin/LoggedUserInfo'
import { NewsArticleResponse } from 'models/News/Article/NewsArticleResponse'
import {
  NewsArticleRequest,
  NewsArticlesResponse,
  NewsCountry,
  NewsSavedSearchRequest,
  SaveNewsSearchResult,
  SearchIndexRequest,
} from 'models/News/Homepage/NewsArticleRequest'
import {
  MyPageLoadArticleRequest,
  MyPageNewsGetAllRequest,
} from 'models/News/MyPage/MyPageNewsRequest'
import {
  MyPageLoadArticleResponse,
  MyPageNewsResponse,
} from 'models/News/MyPage/MyPageNewsResponse'
import { NewsArticleBookmarkRequest } from 'models/News/NewsArticleBookmarkRequest'
import { NewsArticleShareRequest } from 'models/News/NewsArticleShareRequest'
import { NewsLanguagesByCountryCode } from 'models/News/NewsLanguageByCountryCode'
import {
  NewsMakeModelsRequest,
  NewsMakeModelsResponse,
} from 'models/News/NewsMakeModelsResponse'
import { NewsResponseBase } from 'models/News/NewsResponseBase'
import { SearchNewsRequest } from 'models/News/Search/SearchNewsRequest'
import { SearchNewsResponse } from 'models/News/Search/SearchNewsResponse'
import { Source } from 'models/News/Source'
import { UpdateCountryCodeRequest } from 'models/News/UserSettings/UpdateCountryCodeRequest'
import { UpdateHideMonthlyPaymentsPopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentsPopupRequest'
import { UpdateHideMonthlyPaymentsTrialWelcomePopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentsTrialWelcomePopupRequest'
import { UpdateHideMonthlyPaymentsWelcomePopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentsWelcomePopupRequest'
import { UpdateHideMonthlyPaymentTrialEndPopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentTrialEndPopupRequest'
import { UpdateHideWelcomeInfoRequest } from 'models/News/UserSettings/UpdateHideWelcomeInfoRequest'
import { UpdatePageViewRequest } from 'models/News/UserSettings/UpdatePageViewRequest'
import { newsApi } from 'services/apiWithAuth'

class NewsService {
  public login(loginRequest: LoginObject): Promise<LoggedUserInfo> {
    return newsApi
      .post<LoginObject, AxiosResponse<LoggedUserInfo>>(
        `/authentication/login`,
        loginRequest
      )
      .then((response) => {
        logTrace(
          'Successful login call',
          `username: ${loginRequest.username}`,
          `userId: ${response.data?.user?.userId}`
        )

        return response.data
      })
  }

  public searchArticles(
    request: SearchNewsRequest
  ): Promise<SearchNewsResponse> {
    return newsApi
      .post<SearchNewsRequest, AxiosResponse<SearchNewsResponse>>(
        `/searchnewsindex`,
        request
      )
      .then((response) => {
        logTrace(
          'Successful search articles call',
          ``,
          `num of articles - ${response.data?.articles?.length}`
        )

        return response.data
      })
  }

  public getArticleDetailByArticleId(
    newsArticleRequest: NewsArticleRequest
  ): Promise<NewsArticleResponse> {
    return newsApi
      .post<NewsArticleRequest, AxiosResponse<NewsArticleResponse>>(
        `/articlebyid/load`,
        newsArticleRequest
      )
      .then((response) => {
        logTrace(
          'Successful get article detail by article id call',
          ``,
          `articleHeadline: ${response.data?.article?.headline}`
        )

        return response.data
      })
  }

  public getCountries(
    newsCountriesRequest: NewsArticleRequest
  ): Promise<NewsCountry[]> {
    return newsApi
      .post<NewsArticleRequest, AxiosResponse<NewsCountry[]>>(
        `/getcountrybylanguage/load`,
        newsCountriesRequest
      )
      .then((response) => {
        logTrace(
          'Successful get countries call',
          '',
          `NumOfCountries: ${response.data?.length}`
        )

        return response.data
      })
  }

  public updateCountryCode(
    request: UpdateCountryCodeRequest
  ): Promise<boolean> {
    return newsApi
      .post<UpdateCountryCodeRequest, AxiosResponse<boolean>>(
        `/userspecificsettings/updatecode`,
        request
      )
      .then((response) => {
        logTrace('Successful country code update', '', response.data.toString())

        return response.data
      })
  }

  public updatePageView(request: UpdatePageViewRequest): Promise<boolean> {
    return newsApi
      .post<UpdatePageViewRequest, AxiosResponse<boolean>>(
        `/userspecificsettings/updatepageview`,
        request
      )
      .then((response) => {
        logTrace('Update page view successful', '', response.data.toString())

        return response.data
      })
  }

  public updateHideWelcomeInfo(
    request: UpdateHideWelcomeInfoRequest
  ): Promise<boolean> {
    return newsApi
      .post<UpdateHideWelcomeInfoRequest, AxiosResponse<boolean>>(
        `/userspecificsettings/updateHideWelcomeInfo`,
        request
      )
      .then((response) => {
        logTrace(
          'Update hide welcome info successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public shareNewsArticle(request: NewsArticleShareRequest): Promise<boolean> {
    return newsApi
      .post<NewsArticleShareRequest, AxiosResponse<boolean>>(
        `/sharenewsarticle/add`,
        request
      )
      .then((response) => {
        logTrace('Share news article successful', '', response.data.toString())

        return response.data
      })
  }

  public unshareNewsArticle(
    request: NewsArticleShareRequest
  ): Promise<boolean> {
    return newsApi
      .post<NewsArticleShareRequest, AxiosResponse<boolean>>(
        `/sharenewsarticle/remove`,
        request
      )
      .then((response) => {
        logTrace(
          'Unshare news article successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public addNewsBookmark(
    request: NewsArticleBookmarkRequest
  ): Promise<boolean> {
    return newsApi
      .post<NewsArticleBookmarkRequest, AxiosResponse<boolean>>(
        `/managenewsbookmark/add`,
        request
      )
      .then((response) => {
        logTrace('Add news bookmark successful', '', response.data.toString())

        return response.data
      })
  }

  public removeNewsBookmark(
    request: NewsArticleBookmarkRequest
  ): Promise<boolean> {
    return newsApi
      .post<NewsArticleBookmarkRequest, AxiosResponse<boolean>>(
        `/managenewsbookmark/remove`,
        request
      )
      .then((response) => {
        logTrace(
          'Remove news bookmark successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public getBookMarkArticles(
    newsCategoryRequest: NewsArticleRequest
  ): Promise<NewsArticlesResponse> {
    return newsApi
      .post<NewsArticleRequest, AxiosResponse<NewsArticlesResponse>>(
        `/managenewsbookmark/load`,
        newsCategoryRequest
      )
      .then((response) => {
        logTrace(
          'Get bookmark articles successful',
          '',
          `NumOfArticles: ${response.data?.articleList?.length}`
        )

        return response.data
      })
  }

  public getSharedArticles(
    newsCategoryRequest: NewsArticleRequest
  ): Promise<NewsArticlesResponse> {
    return newsApi
      .post<NewsArticleRequest, AxiosResponse<NewsArticlesResponse>>(
        `/sharenewsarticle/load`,
        newsCategoryRequest
      )
      .then((response) => {
        logTrace(
          'Get shared articles successful',
          '',
          `NumOfArticles: ${response.data?.articleList?.length}`
        )

        return response.data
      })
  }

  public getMakesModelsByCountryCode(
    newsMakeModelsRequest: NewsMakeModelsRequest
  ): Promise<NewsMakeModelsResponse> {
    return newsApi
      .post<NewsMakeModelsRequest, AxiosResponse<NewsMakeModelsResponse>>(
        `/getMakesModelsByCountryCode`,
        newsMakeModelsRequest
      )
      .then((response) => {
        logTrace(
          'Get makes models by country code successful',
          '',
          `NumOfMakeModels: ${response.data?.makeModelGroups?.length}`
        )

        return response.data
      })
  }

  public getLanguagesByCountryCode(): Promise<NewsLanguagesByCountryCode> {
    return newsApi.get(`getlanguagesbycountrycode`).then((response) => {
      logTrace(
        'Get languages by country code successful',
        ``,
        'Languages retrieved'
      )

      return response.data
    })
  }

  public getUsersNewsAlerts(): Promise<SearchIndexRequest[]> {
    return newsApi
      .get<number, AxiosResponse<SearchIndexRequest[]>>(
        `/managenewsalert/getUserNewsAlerts`
      )
      .then((response) => {
        logTrace(
          'Get user news alerts successful',
          ``,
          `numOfList: ${response.data?.length}`
        )

        return response.data
      })
  }

  public getUserSavedSearches(
    newsSavedSearchRequest: NewsSavedSearchRequest
  ): Promise<SearchIndexRequest[]> {
    return newsApi
      .post<NewsSavedSearchRequest, AxiosResponse<SearchIndexRequest[]>>(
        `/savenewssearch/getall`,
        newsSavedSearchRequest
      )
      .then((response) => {
        logTrace(
          'Get user saved search successful',
          ``,
          `numOfList: ${response.data?.length}`
        )

        return response.data
      })
  }

  public removeSearchAndAlert(
    searchRequest: SearchIndexRequest
  ): Promise<boolean> {
    return newsApi
      .post<SearchIndexRequest, AxiosResponse<boolean>>(
        `/savenewssearch/remove`,
        searchRequest
      )
      .then((response) => {
        logTrace('Remove searchAlert successful', '', response.data.toString())

        return response.data
      })
  }

  public updateSearchAndAlert(
    searchRequest: SearchIndexRequest
  ): Promise<SaveNewsSearchResult> {
    return newsApi
      .post<SearchIndexRequest, AxiosResponse<SaveNewsSearchResult>>(
        `/savenewssearch/update`,
        searchRequest
      )
      .then((response) => {
        logTrace(
          'Update search and alert successful',
          '',
          response.data.responseMessage
        )

        return response.data
      })
  }

  public saveSearchAndAlert(
    searchRequest: SearchIndexRequest
  ): Promise<SaveNewsSearchResult> {
    return newsApi
      .post<SearchIndexRequest, AxiosResponse<SaveNewsSearchResult>>(
        `/savenewssearch/save`,
        searchRequest
      )
      .then((response) => {
        logTrace(
          'Save search and alert successful',
          '',
          response.data.responseMessage
        )

        return response.data
      })
  }

  public getSearchById(id: string): Promise<SearchIndexRequest> {
    return newsApi
      .get<string, AxiosResponse<SearchIndexRequest>>(
        `/savenewssearch/getbyId/${id}`
      )
      .then((response) => {
        logTrace('Get search by id successful', id.toString(), ``)

        return response.data
      })
  }

  public getVehicleTypes(): Promise<NewsResponseBase<Source[]>> {
    return newsApi
      .get<NewsResponseBase<Source[]>>(`searchnewsindex/groups`)
      .then((response) => response.data)
  }

  public updateHideMonthlyPaymentsPopup(
    request: UpdateHideMonthlyPaymentsPopupRequest
  ): Promise<boolean> {
    return newsApi
      .post<UpdateHideMonthlyPaymentsPopupRequest, AxiosResponse<boolean>>(
        `/userspecificsettings/updateHideMonthlyPaymentsPopup`,
        request
      )
      .then((response) => {
        logTrace(
          'Update hide Monthly Payments Popup successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public updateHideMonthlyPaymentsWelcomePopup(
    request: UpdateHideMonthlyPaymentsWelcomePopupRequest
  ): Promise<boolean> {
    return newsApi
      .post<
        UpdateHideMonthlyPaymentsWelcomePopupRequest,
        AxiosResponse<boolean>
      >(`/userspecificsettings/updateHideMonthlyPaymentsLicensePopup`, request)
      .then((response) => {
        logTrace(
          'Update hide Monthly Payments Welcome Popup successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public updateHideMonthlyPaymentsTrialWelcomePopup(
    request: UpdateHideMonthlyPaymentsTrialWelcomePopupRequest
  ): Promise<boolean> {
    return newsApi
      .post<
        UpdateHideMonthlyPaymentsTrialWelcomePopupRequest,
        AxiosResponse<boolean>
      >(
        `/userspecificsettings/updateHideMonthlyPaymentsTrialWelcomePopup`,
        request
      )
      .then((response) => {
        logTrace(
          'Update hide Monthly Payments Trial Welcome Popup successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public updateHideMonthlyPaymentTrialEndPopup(
    request: UpdateHideMonthlyPaymentTrialEndPopupRequest
  ): Promise<boolean> {
    return newsApi
      .post<
        UpdateHideMonthlyPaymentTrialEndPopupRequest,
        AxiosResponse<boolean>
      >(`/userspecificsettings/updateHideMonthlyPaymentTrialEndPopup`, request)
      .then((response) => {
        logTrace(
          'Update hide Monthly Payments Trial End Popup successful',
          '',
          response.data.toString()
        )

        return response.data
      })
  }

  public getMyPageNews(
    request: MyPageNewsGetAllRequest
  ): Promise<MyPageNewsResponse> {
    return newsApi
      .post<MyPageNewsGetAllRequest, AxiosResponse<MyPageNewsResponse>>(
        `/getelectricvehiclenewslist/getall`,
        request
      )
      .then((response) => response.data)
  }

  public getMyPageNewsArticle(
    request: MyPageLoadArticleRequest
  ): Promise<MyPageLoadArticleResponse> {
    return newsApi
      .post<MyPageLoadArticleRequest, AxiosResponse<MyPageLoadArticleResponse>>(
        `/getelectricvehiclenewslist/loadArticle`,
        request
      )
      .then((response) => response.data)
  }
}

const newsService = new NewsService()
export default newsService
