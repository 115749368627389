import { JATOButton, JATOIcon } from '@jato/ui-component-library'
import React from 'react'
import { StyledNewsArticleMenuItemButton } from './NewsArticleMenuItemButton.styles'

export interface INewsArticleMenuItemButtonProps {
  title: string
  id: string
  iconSrc?: string
  iconName?: string
  disabled?: boolean
  onClick: () => void
}

export const NewsArticleMenuItemButton: React.FC<
  INewsArticleMenuItemButtonProps
> = ({
  title,
  id,
  disabled,
  iconSrc,
  iconName,
  onClick,
}: INewsArticleMenuItemButtonProps) => (
  <StyledNewsArticleMenuItemButton>
    <JATOButton
      disabled={disabled}
      id={id}
      variant="ghost"
      display="flex"
      className="menuHeaderItem"
      onClick={onClick}
    >
      <div className="menuHeaderIcon">
        {iconSrc ? (
          <img src={iconSrc} width="22" />
        ) : iconName ? (
          <JATOIcon iconName={iconName} />
        ) : (
          <></>
        )}
      </div>
      {title}
    </JATOButton>
  </StyledNewsArticleMenuItemButton>
)
