import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsIconButton = styled.div<{ isActive?: boolean }>`
  button {
    background-color: ${({ isActive }) =>
      isActive ? JATOTheme.colors.primary : JATOTheme.colors.midGrey};
    width: 44px;
    height: 44px;
    border-radius: 6px;
  }
`
