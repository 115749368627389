import { JATOVerticalSpacer } from '@jato/ui-component-library'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { StyledNewsArticleImages } from './NewsArticleImages.styles'

interface INewsArticleImagesProps {
  imageArticleList: string[]
}

export const NewsArticleImages: React.FC<INewsArticleImagesProps> = ({
  imageArticleList,
}: INewsArticleImagesProps) => (
  <StyledNewsArticleImages>
    {imageArticleList && imageArticleList.length > 0 && (
      <>
        <JATOVerticalSpacer height={5} />
        <div className="articleImages">
          {imageArticleList.map((imageUrl: string) => (
            <div key={uuid()} style={{ padding: '5px' }}>
              <img src={imageUrl} />
            </div>
          ))}
        </div>
      </>
    )}
  </StyledNewsArticleImages>
)
