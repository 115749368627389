import { JATOSelect } from '@jato/ui-component-library'
import { NewsLanguagesByCountryCode } from 'models/News/NewsLanguageByCountryCode'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsLanguageAlert as StyledNewsLanguageSelect } from './NewsLanguageSelectstyles'

interface INewsLanguageSelectProps {
  onChange: (value: number) => void
  languages: NewsLanguagesByCountryCode | undefined
  selectedLanguage: string
}

export const NewsLanguageSelect: React.FC<INewsLanguageSelectProps> = ({
  onChange,
  languages,
  selectedLanguage,
}: INewsLanguageSelectProps) => {
  const translations = useAppSelector(getTranslations)
  return (
    <StyledNewsLanguageSelect>
      <JATOSelect
        id="newsLanguageSelect"
        label={`${translations.JNT_Language}*`}
        width={'320px'}
        dataSrc={
          languages !== undefined
            ? languages.map((l) => ({
                value: l.languageId.toString(),
                displayValue: l.languageName,
              }))
            : []
        }
        value={selectedLanguage}
        onChange={(value) => onChange(Number(value))}
        isMultiSelect={false}
        clearAfterSelect={true}
      />
    </StyledNewsLanguageSelect>
  )
}
