import { JATOGroup, JATOTextInput } from '@jato/ui-component-library'
import { Loader } from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { CommonModal } from 'components/Modals/CommonModal'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useGetCountryGroups, useSaveCountryGroup } from 'hooks/volumes'
import { CountryGroup } from 'models/Volumes/CountryGroup'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

interface IVolumesSaveCountryGroupProps {
  isOpen: boolean
  onClose: (isSuccess: boolean) => void
  countryGroup: CountryGroup
}

export const VolumesSaveCountryGroupModal: React.FC<
  IVolumesSaveCountryGroupProps
> = ({ isOpen, onClose, countryGroup }: IVolumesSaveCountryGroupProps) => {
  const translations = useAppSelector(getTranslations)

  const [model, setModel] = useState(countryGroup)

  const { data: countryGroups, isFetching } = useGetCountryGroups(
    countryGroup.productGroup
  )

  const { mutateAsync: saveCountryGroup, isLoading: isSaving } =
    useSaveCountryGroup()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.currentTarget.value
    setModel({ ...model, name })
  }

  const onSaveClick = async (): Promise<void> => {
    const isSuccess = await saveCountryGroup(model)
    if (isSuccess) {
      showSuccessToast(translations.JNT_Success, translations.JNT_SavedSuccess)
      onClose(true)
    } else {
      showErrorToast(translations.JNT_Error, translations.JNT_Error)
    }
  }

  const hasError =
    !!countryGroups &&
    countryGroups.some((c) => c.id !== model.id && c.name === model.name)

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeButton
      maxWidth="sm"
      title={
        model.id
          ? translations.JNT_Volumes_QIO_EditCTYGRP
          : translations.JNT_Volumes_QIO_AddCTYGRP
      }
      primaryButtonName={translations.JNT_save}
      disablePrimaryButton={hasError || model.name.length === 0}
      onPrimaryClick={onSaveClick}
    >
      {isFetching ? (
        <Loader style={{ minHeight: '100%' }} />
      ) : (
        <JATOGroup flexDirection="row">
          <JATOTextInput
            id=""
            width={500}
            placeholder={translations.JNT_Volumes_Country_group_name}
            onChange={onChange}
            hasError={hasError}
            value={model.name}
            validateText={
              hasError ? translations.JNT_Volumes_Country_group_name_exists : ''
            }
          />
        </JATOGroup>
      )}
      <LoaderModal isOpen={isSaving} />
    </CommonModal>
  )
}
