import { JATOButtonIcon } from '@jato/ui-component-library'
import { ModalComponent } from 'components/ModalComponent'
import React, { useState } from 'react'

export interface IVolumesGridDeleteButtonProps {
  id?: string
  item: any
  deleteItem: (item: any) => Promise<void>
  name: string
  removeMessage: string
  translations: any
}

export const VolumesDeleteButton: React.FC<IVolumesGridDeleteButtonProps> = ({
  id = 'volumesDeleteButton',
  item,
  deleteItem,
  name,
  removeMessage,
  translations,
}: IVolumesGridDeleteButtonProps) => {
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] =
    useState(false)

  return (
    <>
      <JATOButtonIcon
        id={id}
        iconName="baseline_delete_outline"
        onClick={() => {
          setIsConfirmDeleteModalVisible(true)
        }}
      />
      <ModalComponent
        modalVisible={isConfirmDeleteModalVisible}
        closeButton
        title={`${translations.JNT_delete} - ${name}`}
        primaryButtonName={translations.JNT_delete}
        onSecondaryClick={() => setIsConfirmDeleteModalVisible(false)}
        secondaryButtonName=""
        onPrimaryClick={() => {
          deleteItem(item)
          setIsConfirmDeleteModalVisible(false)
        }}
        isPrimaryButtonLoading={false}
        primaryButton
      >
        {removeMessage}
      </ModalComponent>
    </>
  )
}
