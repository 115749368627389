import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledMyPageNewsCard = styled.div`
  cursor: pointer;

  .newsCard {
    margin-top: 20px;
  }

  .newsCard > div {
    display: flex;
    align-items: center;
    gap: 30px;
    height: 170px;
  }

  .newsCardImage {
    max-width: 15vw;
  }

  .newsHeadline {
    font-size: 28px;
    font-weight: ${JATOTheme.fontWeights.medium};
    letter-spacing: -0.5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
  }

  .newsSubHeadline {
    font-size: 14px;
    font-weight: ${JATOTheme.fontWeights.medium};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
