import { BuiltVehicle } from 'models/Specifications/GetBuiltVehiclesResponse'
import {
  GetVehicleComparisonResponse,
  VehicleComparisonInfo,
} from 'models/Specifications/GetVehicleComparisonResponse'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { createCategoryRowList } from './excelExport/excelExportHelper'

export type OrganizedComparisons = Map<
  string,
  {
    vehicleInfo: Map<number, VehicleComparisonInfo[]>
  }
>

export const organizeComparisonsByCategory = (
  response: GetVehicleComparisonResponse | undefined,
  nonBenchmarkVehicleIds: number[]
): OrganizedComparisons => {
  if (response === undefined) return new Map()

  const organizedData: OrganizedComparisons = new Map()

  // Create a set for constant-time lookups
  const vehicleIdsSet = new Set(nonBenchmarkVehicleIds)

  createCategoryRowList(response).forEach((orderedCategory: any) => {
    Object.entries(response).forEach(([vehicleIdStr, vehicleToCompare]) => {
      const vehicleId = Number(vehicleIdStr)

      Object.values(vehicleToCompare).forEach((vehicleComparisonInfos) => {
        vehicleComparisonInfos.forEach((info) => {
          const categoryName = info.categoryName
          if (categoryName === orderedCategory.categoryName) {
            if (!organizedData.has(categoryName)) {
              organizedData.set(categoryName, {
                vehicleInfo: new Map(),
              })
            }

            const categoryData = organizedData.get(categoryName)

            // Even if a vehicle doesn't have comparison text in a category, we still want to display an empty column
            vehicleIdsSet.forEach((id) => {
              if (!categoryData?.vehicleInfo.has(id)) {
                categoryData?.vehicleInfo.set(id, [])
              }
            })

            if (vehicleIdsSet.has(vehicleId)) {
              const vehicleData = categoryData?.vehicleInfo.get(vehicleId)
              vehicleData?.push(info)
            }
          }
        })
      })
    })
  })

  return organizedData
}

export const getComparisonsTitleText = (comparisonPage: string): string => {
  const translations = useAppSelector(getTranslations)

  if (comparisonPage == 'advantages') {
    return translations.JNT_BenchmarkAdv
  } else {
    return translations.JNT_BenchmarkDisadv
  }
}

export const getVehilcleByVehicleId = (
  builtVehicles: BuiltVehicle[],
  vehicleId: number
): BuiltVehicle | undefined =>
  builtVehicles.find((vehicle: BuiltVehicle) => vehicle.vehicleId === vehicleId)
