import ShareIcon from 'assets/icons/icon_share.svg'
import UnshareIcon from 'assets/icons/icon_unshare.svg'
import ImgButton from 'components/CommonButtons/ImgButton'
import { VolumesQuery } from 'models/Volumes/VolumesQuery'
import React from 'react'
export interface IVolumesGridShareButtonProps {
  id?: string
  query: VolumesQuery
  onClick: (query: VolumesQuery) => Promise<void>
}

export const VolumesGridShareButton: React.FC<IVolumesGridShareButtonProps> = ({
  id = 'volumesGridShareButton',
  query,
  onClick,
}: IVolumesGridShareButtonProps) => (
  <ImgButton
    id={id}
    src={query.qryShared ? UnshareIcon : ShareIcon}
    title={query.queryShortDescription}
    onClick={() => {
      const updatedQuery = {
        ...query,
        qryShared: !query.qryShared,
      }
      onClick(updatedQuery)
    }}
  />
)
