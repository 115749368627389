import AddedAsOptionIcon from 'assets/icons/AddedAsOptionIcon.svg'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { useAddRecipeAndUpdateBrochure } from 'hooks/carSpecs'
import { BuiltOption } from 'models/Specifications/BrochureResponse'
import { InstructionType } from 'models/Specifications/Options/DetailedAction'
import React from 'react'
import { v4 as uuid } from 'uuid'

interface IHeaderOptionsProps {
  builtOptions: BuiltOption[]
}

export const SpecificationsReportHeaderOptions: React.FC<
  IHeaderOptionsProps
> = ({ builtOptions }: IHeaderOptionsProps) => {
  const {
    mutateAsync: addRecipeAndUpdateBrochure,
    isLoading: isLoadingBuildOptions,
  } = useAddRecipeAndUpdateBrochure()

  const unbuildOption = (option: BuiltOption): void => {
    addRecipeAndUpdateBrochure({
      userInstruction: InstructionType.UnbuildOption,
      optionId: option.optionId,
      vehicleId: option.vehicleId,
    })
  }

  const getOptionBuildInfoJSXElement = (option: BuiltOption): JSX.Element => (
    <div style={{ clear: 'both' }}>
      <div style={{ float: 'left', paddingRight: '5px' }}>
        <img
          style={{ marginTop: '3px' }}
          src={AddedAsOptionIcon}
          key={uuid()}
        />
      </div>
      <div style={{ paddingLeft: '3px' }}>
        <span>
          [{option.optionCode}] {option.optionName} {option.displayPrice}
        </span>
      </div>
    </div>
  )

  return (
    <div style={{ backgroundColor: 'lightgray', padding: '10px' }}>
      {builtOptions.map((option) => (
        <div key={uuid()}>
          <div
            id="specsReportHeaderOptionUnbuildButton"
            onClick={() => unbuildOption(option)}
            style={{ cursor: 'pointer' }}
          >
            {getOptionBuildInfoJSXElement(option)}
          </div>
          {option.secondaryOptions.map((secondaryOption) => (
            <div
              key={uuid()}
              style={{ paddingLeft: '15px', paddingTop: '10px' }}
            >
              {getOptionBuildInfoJSXElement(secondaryOption)}
            </div>
          ))}
        </div>
      ))}
      <LoaderModal isOpen={isLoadingBuildOptions} />
    </div>
  )
}
