import { JATOVerticalSpacer } from '@jato/ui-component-library'
import { PhotoGallery } from 'components/PhotoGallery/PhotoGallery'
import React from 'react'

interface INewsArticlePhotoGallery {
  imageUrlList: string[]
}

export const NewsArticlePhotoGallery: React.FC<INewsArticlePhotoGallery> = ({
  imageUrlList,
}: INewsArticlePhotoGallery) => (
  <>
    <PhotoGallery id="newsArticlePhotoGallery" imageUrlList={imageUrlList} />
    <JATOVerticalSpacer />
  </>
)
