import {
  JATOGroup,
  JATOSelect,
  JATOText,
  JATOTextarea,
  JATOTextInput,
} from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { useGetCountries, useSubmitComments } from 'hooks/portal'
import { YourCommentsState } from 'models/Portal/YourCommentsState'
import React, { useState } from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledMyPageYourCommentsModal } from './MyPageYourCommentsModal.styles'

interface IModalProps {
  isOpen?: boolean
  onClose?: () => void
}

export const MyPageYourCommentsModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
}: IModalProps) => {
  const translations = useAppSelector(getTranslations)
  const { languageId } = useAppSelector(selectCommonUserData)

  const { data, isFetching } = useGetCountries(languageId.toString())

  const { mutate: submitComments } = useSubmitComments()

  const countryOptions =
    data?.map((c) => ({
      value: c.countryCode,
      displayValue: c.countryName,
    })) ?? []

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [comments, setComments] = useState('')

  const onSubmitClick = (): void => {
    const request: YourCommentsState = {
      yourName: name,
      emailAddress: email,
      countryCode: selectedCountry,
      comments: comments,
      addDate: new Date(),
    }
    submitComments(request)

    onClose && onClose()
  }

  return (
    <CommonModal
      closeButton
      isOpen={isOpen}
      onClose={onClose}
      title={translations.JNT_Portal_MJ_YourComments}
      primaryButtonName={translations.JNT_Portal_Suggest_Submit}
      onPrimaryClick={onSubmitClick}
      disablePrimaryButton={!selectedCountry}
    >
      <StyledMyPageYourCommentsModal>
        {isFetching ? (
          <Loader style={{ minHeight: '448px' }} />
        ) : (
          <JATOGroup>
            <JATOText as="div" fontSize={14}>
              {translations.JNT_Portal_Suggest_Desc}
            </JATOText>
            <JATOTextInput
              id="myPageCommentsNameInput"
              width="100%"
              label={translations.JNT_Portal_Suggest_Name}
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <JATOTextInput
              id="myPageCommentsEmailInput"
              width="100%"
              label={translations.JNT_Portal_Suggest_Email}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <JATOSelect
              id="myPageCommentsCountryDropdown"
              label={'* ' + translations.JNT_Portal_Suggest_Cntry}
              width="100%"
              dataSrc={countryOptions}
              value={selectedCountry}
              onChange={(item) => setSelectedCountry(item)}
              placeholder={translations.JNT_Portal_Suggest_Selection}
            />
            <JATOTextarea
              id="myPageCommentsCommentsTextarea"
              label={translations.JNT_Portal_Suggest_Comments}
              value={comments}
              onChange={(e) => setComments(e.currentTarget.value)}
              rows={5}
            />
          </JATOGroup>
        )}
      </StyledMyPageYourCommentsModal>
    </CommonModal>
  )
}
