import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsMainMenuButton = styled.div`
  position: relative;

  .button {
    background-color: ${JATOTheme.colors.midGrey};
    width: 44px;
    height: 44px;
    border-radius: 6px;
  }

  button[disabled] {
    background-color: ${JATOTheme.colors.midDarkGrey};
    color: #666666;
    cursor: not-allowed;
  }

  .dropdown {
    -webkit-animation: EbufM 0.15s ease-out;
    -webkit-animation: EbufM 0.15s ease-out;
    animation: EbufM 0.15s ease-out;
    display: none;
    outline: none;
    position: absolute;
    top: calc(100% + 4px);
    left: -202px;
    right: -27px;
    display: block;
    font-size: 12px;
    padding: 0;
  }

  .rtl .dropdown {
    position: relative;
    top: 5px;
    left: 0px;
    right: 0px;
    direction: rtl;
  }

  .stackOrderStyle {
    z-index: 6;
  }

  .appliedFilterNotification {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: ${JATOTheme.colors.primary};
    right: -8px;
    top: -8px;
    border-radius: 100%;
    text-align: center;
    color: ${JATOTheme.colors.white};
    line-height: 24px;
    font-size: 14px;
    z-index: 7;
  }
`
