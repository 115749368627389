import { JATOIcon } from '@jato/ui-component-library'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import Loader from 'components/Loader'
import {
  defaultExpandedNodes,
  getAllValuesDataPoint,
  hideCheckboxNodes,
  VolumesDataPointGroup,
} from 'helper/volumesHelper'
import { useLoadQueryDefinitionTree } from 'hooks/volumes'
import { JATONetTreeview } from 'models/Volumes/JATONetTreeview'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getVolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { StyledVolumesQueryTreePanel } from './VolumesQueryTreePanel.styles'

export interface IVolumesQuerySelectionPanelProps {
  onSelectItem: (item: JATONetTreeview) => void
  onCheckedChange: (item: JATONetTreeview) => void
}

export const VolumesQueryTreePanel: React.FC<
  IVolumesQuerySelectionPanelProps
> = ({
  onSelectItem,
  onCheckedChange: onChildCheckedChange,
}: IVolumesQuerySelectionPanelProps) => {
  const dispatch = useAppDispatch()
  const queryState = useAppSelector(getVolumesQueryState)
  const { data: tree, isFetching } = useLoadQueryDefinitionTree()

  const getNodeId = (node: JATONetTreeview): string =>
    node.dataPointName ?? node.dataPointGroup

  const getChildCheckedState = (item: JATONetTreeview): boolean =>
    (item.dataPointGroup === VolumesDataPointGroup.Country ||
    item.dataPointGroup === VolumesDataPointGroup.VehicleType
      ? queryState.outputPoints?.some((o) => o.id === item.dataPointGroup)
      : queryState.vehicleAttributes?.some(
          (dp) => dp.id === item.dataPointName
        )) ?? false

  const getParentCheckedState = (item: JATONetTreeview): boolean =>
    queryState.vehicleAttributes?.some((dp) =>
      item.children.some((childItem) => dp.id === childItem.dataPointName)
    ) ?? false

  const onParentCheckedChange = (item: JATONetTreeview): void => {
    const isChecked = getParentCheckedState(item)

    const otherDataPoints =
      queryState.vehicleAttributes?.filter(
        (p) =>
          !item.children.some((childItem) => p.id === childItem.dataPointName)
      ) ?? []

    const newDataPoints = isChecked
      ? []
      : item.children.map((childItem) => getAllValuesDataPoint(childItem))

    dispatch(
      setVolumesQueryState({
        ...queryState,
        vehicleAttributes: [...otherDataPoints, ...newDataPoints],
      })
    )
  }

  const getCheckedState = (item: JATONetTreeview): boolean =>
    item.children ? getParentCheckedState(item) : getChildCheckedState(item)

  const onCheckedChange = (item: JATONetTreeview): void =>
    item.children ? onParentCheckedChange(item) : onChildCheckedChange(item)

  const renderItem = (item: JATONetTreeview): JSX.Element => (
    <TreeItem
      key={getNodeId(item)}
      nodeId={getNodeId(item)}
      label={
        <GtmWrapper id="volumesQueryTreeItem" label={item.longText}>
          <div className="itemLabel">
            {!hideCheckboxNodes.includes(getNodeId(item)) && (
              <CheckboxWithGtm
                id="volumesQueryTreePanelCheckbox"
                value={getNodeId(item)}
                label={item.longText}
                checked={getCheckedState(item)}
                onChange={() => onCheckedChange(item)}
              />
            )}
            <span>{item.longText}</span>
          </div>
        </GtmWrapper>
      }
    >
      {item.children?.map((childItem) => renderItem(childItem))}
    </TreeItem>
  )

  const findInTree = (
    nodes: JATONetTreeview[] | undefined,
    nodeId: string
  ): JATONetTreeview | undefined => {
    if (!nodes) return undefined

    let found = nodes.find((node) => nodeId == getNodeId(node))
    if (found) return found

    for (const node of nodes) {
      found = findInTree(node.children, nodeId)
      if (found) return found
    }
  }

  const handleSelect = (nodeId: string): void => {
    const node = findInTree(tree, nodeId)
    node && onSelectItem(node)
  }

  return (
    <StyledVolumesQueryTreePanel>
      {isFetching ? (
        <Loader style={{ minHeight: 'calc(100vh - 460px)' }} />
      ) : (
        tree && (
          <TreeView
            defaultCollapseIcon={
              <span id="volumesQueryTreePanelCollapseButton">
                <JATOIcon iconName="baseline_add_circle" />
              </span>
            }
            defaultExpandIcon={
              <span id="volumesQueryTreePanelExpandButton">
                <JATOIcon iconName="baseline_add_circle_outline" />
              </span>
            }
            defaultExpanded={defaultExpandedNodes}
            onNodeSelect={(_, nodeId) => handleSelect(nodeId)}
          >
            {tree.map((item) => renderItem(item))}
          </TreeView>
        )
      )}
    </StyledVolumesQueryTreePanel>
  )
}
