import { NewsBookMarkArticleViewAll } from 'components/News/NewsBookMarkArticle/NewsBookMarkArticleViewAll'
import { NewsAlertsViewAll } from 'components/News/NewsSavedSearchAndAlert/NewsAlertsViewAll'
import { NewsSavedSearchViewAll } from 'components/News/NewsSavedSearchAndAlert/NewsSavedSearchViewAll'
import { NewsSharedArticleViewAll } from 'components/News/NewsSharedArticle/NewsSharedArticleViewAll'
import { NewsPage } from 'models/News/NewsPageType'
import NewsSearchResults from 'pages/NewsSearchResults'
import NewsViewAll from 'pages/NewsViewAll'
import React from 'react'
import { useParams } from 'react-router-dom'

export const NewsPageComponent: React.FC = () => {
  const { newsPage } = useParams<{ newsPage: NewsPage }>()

  const getComponent = (): JSX.Element => {
    switch (newsPage) {
      case NewsPage.SharedArticles:
        return <NewsSharedArticleViewAll />
      case NewsPage.BookMarkArticles:
        return <NewsBookMarkArticleViewAll />
      case NewsPage.SearchResults:
        return <NewsSearchResults />
      case NewsPage.SavedSearches:
        return <NewsSavedSearchViewAll />
      case NewsPage.Alerts:
        return <NewsAlertsViewAll />
      default:
        return <NewsViewAll />
    }
  }

  return getComponent()
}
