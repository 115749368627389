import Stack from '@mui/material/Stack'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'

import { StepLabel } from '@mui/material'
import { getActiveStep } from 'helper/volumesHelper'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesQueryState } from 'redux/volumes/volumesSlice'
import { VolumesStepIconComponent } from './VolumesStepIconComponent'
import { StyledVolumesStepper } from './VolumesStepper.styles'
import { VolumesStepConnector } from './VolumesStepperMaterial.styles'

export const VolumesStepper: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const queryState = useAppSelector(getVolumesQueryState)

  const steps = [
    translations.JNT_Volumes_QG4_WelcomeMsg,
    translations.JNT_Country,
    translations.JNT_Volumes_QG_TabQuerySelections,
    translations.JNT_Volumes_QG_TabPeriodSelections,
    translations.JNT_Volumes_QG4_Final,
  ]

  return (
    <StyledVolumesStepper>
      <Stack spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={getActiveStep(queryState)}
          connector={<VolumesStepConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={VolumesStepIconComponent}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </StyledVolumesStepper>
  )
}
