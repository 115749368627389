import {
  IHelperTextPosition,
  JATOBasicCard,
  JATOSpinner,
  JATOSuggestiveSearch,
} from '@jato/ui-component-library'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import { LogoNewsLoader } from 'components/MonthlyPayments/News/VehicleNews.styles'
import { useGetAdvanceFilterList } from 'hooks/carSpecs'
import { Nullable } from 'models/Common/Nullable'
import { IOptionFilterType } from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import { AdvanceFilterInfoObject } from 'models/Specifications/AdvanceFilters/AdvanceFiltersResponse'
import React, { useState } from 'react'
import { SpecsAdvanceFilter } from './SpecsAdvanceFilter'
import { StyledSpecsAdvanceFilters } from './SpecsAdvanceFiltersContainer.styles'
import { SpecsAdvanceOptionFilter } from './SpecsAdvanceOptionFilter'

export const SpecsAdvanceFiltersContainer: React.FC = () => {
  const [selectedFilterInfo, setSelectedFilterInfo] =
    useState<Nullable<AdvanceFilterInfoObject>>(null)

  const [optionFilterType, setOptionFilterType] =
    useState<Nullable<IOptionFilterType>>(null)

  const { data: filterInfos, isFetching } = useGetAdvanceFilterList()

  const filterInfoOptions =
    filterInfos &&
    filterInfos.map((filterInfo) => ({
      value: `${JSON.stringify(filterInfo)}`,
      displayValue: `${filterInfo.applicationText}`,
    }))

  const onChangeFilterInfo = (item: string): void => {
    const selectedFilter: AdvanceFilterInfoObject = JSON.parse(item)
    setSelectedFilterInfo(selectedFilter)
  }

  return (
    <StyledSpecsAdvanceFilters>
      <JATOBasicCard>
        {isFetching ? (
          <LogoNewsLoader>
            <JATOSpinner spinnerSize={64} />
          </LogoNewsLoader>
        ) : (
          <div style={{ padding: '0 15px', minHeight: '25vh' }}>
            {filterInfoOptions && (
              <GtmWrapper id="specsAdvancedFiltersFilterSelect">
                <JATOSuggestiveSearch
                  clearAfterSelect
                  id="advanceSearch"
                  label="Advance Filter"
                  dataSrc={filterInfoOptions}
                  listHeight={300}
                  onChange={onChangeFilterInfo}
                  placeholder="Search"
                  width="100%"
                  ignoreCase={true}
                  helperTextPosition={IHelperTextPosition.TOP}
                />
              </GtmWrapper>
            )}

            <SpecsAdvanceOptionFilter onChange={setOptionFilterType} />

            {selectedFilterInfo && optionFilterType && (
              <SpecsAdvanceFilter
                filterInfo={selectedFilterInfo}
                optionFilterType={optionFilterType}
              />
            )}
          </div>
        )}
      </JATOBasicCard>
    </StyledSpecsAdvanceFilters>
  )
}
