import styled from 'styled-components'

export const StyledTrainingVideos = styled.div`
  .thumbnailcontainer {
    display: flex;
    padding: 10px 30px 10px 42px;
  }

  .item {
    position: relative;
    display: block;
    flex: 1 1 0px;
    transition: transform 500ms;
    cursor: pointer;
  }

  .thumbnailcontainer:focus-within .item,
  .thumbnailcontainer:hover .item {
    transform: translateX(-25%);
  }

  .item:focus ~ .item,
  .item:hover ~ .item {
    transform: translateX(25%);
  }

  .thumbnailcontainer .item:focus,
  .thumbnailcontainer .item:hover {
    transform: scale(1.5);
    z-index: 1;
  }

  .item img {
    display: block;
    max-width: 100%;
    cursor: 'pointer';
  }
  .tabcontent {
    display: none;
    justify-content: center;
    padding: 20px 20px;
    border-top: none;
  }
  
  .bmpui-ui-watermark {
    display: none;
  }
`
export const LogoVideosLoader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 25vh;
`
