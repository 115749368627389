import { JATOIcon, JATOText, JATOTheme } from '@jato/ui-component-library'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import React, { FunctionComponent, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import {
  IDatePickerStyles,
  StyledDatePicker,
  StyledLabel,
} from './DatePickerContainer.styles'
export enum HelperTextPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export const DatePickerStyle = createGlobalStyle`

  .e-calendar .e-content td.e-selected span.e-day {
    background-color: ${JATOTheme.colors.primary} !important
  }

  .e-calendar .e-content td.e-today.e-selected span.e-day {
    color: ${JATOTheme.colors.white} !important
  }

  .e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
    color: ${JATOTheme.colors.primary} !important
  }

  .e-calendar .e-content td.e-today span.e-day {
    color: ${JATOTheme.colors.primary} !important
    border: ${JATOTheme.borderWidths[0]}px solid ${JATOTheme.colors.primary} !important
  }

  /* impacts calendar icon within JATOBasicCard component */
  .propertyInputDatePicker .calendar-icon {
    top: -3px !important
  }
`

interface IDatePickerProps extends IDatePickerStyles {
  /** Initially selected date */
  initialDate?: Date
  /** Defines minimum date for date ranges */
  minDate?: Date
  /** A callback function to call with the selected date */
  onDateSelected?: (date: Date) => void
  /** The label to display below the input for validation errors */
  validateText?: string
  /** The icon to display alongside validateText */
  validateTextIcon?: string
  /** The label to display above the datepicker */
  label?: string
  /** Datepicket input placeholder */
  placeholder?: string
  /** onChange event that takes an event and returns nothing */
  onChange?: (event: Date | null) => void
  /** A unique id for the date picker */
  id: string
  //** A unique class name for the date picker */
  className?: string
}
export const DatePickerContainer: FunctionComponent<IDatePickerProps> = ({
  initialDate,
  onDateSelected,
  isDisabled = false,
  validateText,
  validateTextIcon,
  label,
  onChange,
  className,
  minDate,
  placeholder = 'Enter date - DD/MM/YYYY',
  id,
}: IDatePickerProps) => {
  const [startDate, setStartDate] = React.useState<Date | undefined>(
    initialDate
  )
  const minDateDefault = new Date(
    'Wed Mar 16 1997 00:00:00 GMT+0000 (Greenwich Mean Time)'
  )

  useEffect(() => {
    const replaceWithMinDate = minDate && initialDate && minDate > initialDate
    setStartDate(replaceWithMinDate ? minDate : initialDate)
  }, [initialDate, minDate])

  return (
    <>
      <DatePickerStyle />
      <StyledDatePicker isDisabled={isDisabled} className={className}>
        <StyledLabel htmlFor={id}>
          {label && (
            <JATOText
              as="div"
              fontSize={JATOTheme.fontSizes[1]}
              color="inherit"
              marginBottom={JATOTheme.space[2]}
            >
              {label}
            </JATOText>
          )}
          <div
            style={{
              display: 'inline-flex',
              width: '100%',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <DatePickerComponent
              className="datePicker"
              id={id}
              placeholder={placeholder}
              openOnFocus
              format="dd/MM/yyyy"
              value={startDate}
              strictMode
              min={minDate || minDateDefault}
              change={(date = {}) => {
                const { value } = date
                if (value || value === null) {
                  onDateSelected && onDateSelected(value)
                }
                value && onChange && onChange(value)
              }}
            />
            <JATOIcon
              className="calendar-icon"
              iconName="custom_calendar"
              iconFill={JATOTheme.colors.darkGrey}
            />
          </div>
        </StyledLabel>

        {validateText && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: JATOTheme.space[2],
            }}
          >
            {validateTextIcon && (
              <JATOIcon
                iconName={validateTextIcon}
                iconSize={JATOTheme.fontSizes[2]}
                iconFill={JATOTheme.colors.primary}
              />
            )}
            <JATOText
              as="p"
              fontSize={JATOTheme.fontSizes[1]}
              color={JATOTheme.colors.primary}
              marginLeft={JATOTheme.space[1]}
              marginTop={JATOTheme.space[1]}
            >
              {validateText}
            </JATOText>
          </div>
        )}
      </StyledDatePicker>
    </>
  )
}
