import addVehicleIcon from 'assets/icons/icon_add_vehicle_fill.svg'
import exportFileIcon from 'assets/icons/icon_xml_file_fill.svg'
import { ImageButtonNavItem } from 'components/CommonNavItems/ImageButtonNavItem/ImageButtonNavItem'
import { TextNavItem } from 'components/CommonNavItems/TextNavItem/TextNavItem'
import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import React from 'react'
import { MonthlyPaymentDataSourceInfo } from './MonthlyPaymentDataSourceInfo'

interface IProps {
  onExportToExcel: () => void
  translations: AppTextTranslations
  onAddVehicleClick: () => void
}

export const MonthlyPaymentsReportNavigation: React.FC<IProps> = ({
  onExportToExcel,
  translations,
  onAddVehicleClick,
}: IProps) => (
  <NavigationContainer
    left={
      <>
        <TextNavItem text="Monthly Payments" />
      </>
    }
    right={
      <>
        <MonthlyPaymentDataSourceInfo />
        <ImageButtonNavItem
          onClick={onAddVehicleClick}
          id="monthlyPaymentsReportAddVehicleButton"
          src={addVehicleIcon}
          title={translations.JNT_AddVeh}
        />
        <ImageButtonNavItem
          id="monthlyPaymentsReportExcelExportButton"
          onClick={onExportToExcel}
          src={exportFileIcon}
          title="Export excel"
        />
      </>
    }
  />
)
