import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { JATOIcon } from '@jato/ui-component-library'
import viewGalleryIcon from 'assets/icons/icon_image_fill.svg'
import { Flag } from 'components/Flag/Flag'
import { ModalComponent } from 'components/ModalComponent'
import { countryCodeMapper, getCountryCode } from 'helper/countryCodeMapper'
import { getReferencePriceFormatted } from 'helper/getReferencePrice'
import { getVehicleReportFilter } from 'helper/getVehicleReportFilter'
import { showInfoToast } from 'helper/toastHelper'
import { CommonNewsVehicle } from 'models/News/Vehicle/CommonNewsVehicle'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { removeMpVehicle } from 'redux/monthlypayments/mpVehiclesSlice'
import { getSelectedCountry } from 'redux/monthlypayments/selectedFiltersSlice'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { RoutePaths } from 'router/RoutePaths'
import { v4 as uuid } from 'uuid'
import VehicleNews from '../News'
import VehiclePhotoGallery from '../PhotoGallery'
import { StyledMonthlyPaymentsReportHeader } from './MonthlyPaymentsReportHeader.styles'

interface IMonthlyPaymentsReportHeader {
  vehicles: MpVehicle[]
}

export const MonthlyPaymentsReportHeader: React.FC<
  IMonthlyPaymentsReportHeader
> = ({ vehicles }: IMonthlyPaymentsReportHeader) => {
  const reportRef = useRef<any>(null)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [modalNewsVisible, setModalNewsVisible] = useState(false)
  const [modalGalleryVisible, setModalGalleryVisible] = useState(false)
  const [vehicle, setVehicle] = useState<CommonNewsVehicle>({
    make: '',
    model: '',
    vehicleId: 0,
    countryCode: '',
    specsDb: '',
    isMonthlyPayments: true,
  })
  const [vehicleForGallery, setVehicleForGallery] = useState<any>(null)
  const [modalVehicleTitle, setModalVehicleTitle] = useState<string>('')

  const selectedCountry = useAppSelector(getSelectedCountry)
  const userData = useAppSelector(selectUserData)

  const getLatestNewsArticles = (vehicle: MpVehicle): void => {
    setModalNewsVisible(true)
    const vehicleName = `${vehicle.make} ${vehicle.model} ${vehicle.versionName}`
    setModalVehicleTitle(vehicleName)
    setVehicle({
      make: vehicle.make,
      model: vehicle.model,
      vehicleId: +vehicle.uniqueIdentity,
      countryCode: `CTY_${countryCodeMapper(
        userData.user.settings.lastSelectedMarket?.countryCode
      )}`,
      specsDb: getSpecsDbForSelectedMarket() ?? '',
      isMonthlyPayments: true,
    })
  }

  const getGalleryImages = (vehicle: MpVehicle): void => {
    const vehicleName = `${vehicle.make} ${vehicle.model} ${vehicle.versionName}`
    setModalVehicleTitle(vehicleName)
    setModalGalleryVisible(true)
    setVehicleForGallery(vehicle)
  }

  const handleOnClose = (): void => {
    setModalNewsVisible(false)
    setModalGalleryVisible(false)
  }

  const handlePriceClick = (): void => {
    console.log('clicked')
  }

  const checkForValidScenerios = (vehicle: MpVehicle): boolean =>
    !!vehicles &&
    vehicles.length > 0 &&
    vehicles.filter((response: MpVehicle) =>
      getVehicleReportFilter(response, vehicle)
    ).length > 0 &&
    vehicles.filter((response: MpVehicle) =>
      getVehicleReportFilter(response, vehicle)
    )[0].scenarios.length > 0

  const goToScenerioaPage = (vehicle: MpVehicle): void => {
    const { uniqueIdentity, dataDate } = vehicle
    const cnt = getSceneriosCount(vehicle)
    history.push(
      `${RoutePaths.MonthlyPaymentsReport}/${uniqueIdentity}/${cnt}/${dataDate}`
    )
  }

  const getSceneriosCount = (vehicle: MpVehicle): number =>
    vehicles?.filter((response: any) =>
      getVehicleReportFilter(response, vehicle)
    )[0].scenarios.length ?? 0

  const getSpecsDbForSelectedMarket = (): string =>
    `SSC${countryCodeMapper(
      userData.user.settings.lastSelectedMarket?.countryCode
    )}_CS2002`

  useEffect(() => {
    reportRef.current?.focus()
  }, [])

  return (
    <StyledMonthlyPaymentsReportHeader
      style={{ position: 'sticky', top: 0, zIndex: 1 }}
    >
      <table className="tableStyle">
        <tbody>
          <tr className="tableRow">
            <td
              key={uuid()}
              className="tableCell"
              style={{ position: 'sticky', left: '0' }}
            >
              <div style={{ height: '90px', backgroundColor: 'white' }} />
            </td>
            {vehicles.map((vehicle: MpVehicle, index: number) => (
              <td
                className="tableCell"
                key={index}
                style={{
                  zIndex: 3,
                }}
              >
                <div className="tableValue">
                  <div>
                    <Flag countryCode={getCountryCode(selectedCountry)} />
                    <span style={{ paddingLeft: '8px', fontWeight: 700 }}>
                      {`${vehicle.make} ${vehicle.model}`}
                    </span>
                  </div>
                  <div style={{ fontWeight: 400 }}>{vehicle.versionName}</div>
                  <div style={{ fontWeight: 400 }}>
                    <span ref={reportRef} onClick={handlePriceClick}>
                      {getReferencePriceFormatted(vehicle)}
                    </span>
                  </div>
                  <div style={{ paddingTop: '5px' }}>
                    {vehicles.length > 1 ? (
                      <div
                        className="removeVehicle"
                        title="Remove Vehicle"
                        id="monthlyPaymentsReportRemoveVehicleButton"
                        onClick={() => {
                          dispatch(removeMpVehicle(vehicle))
                        }}
                      >
                        <JATOIcon
                          iconName="baseline_delete_outline"
                          iconSize={24}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className="viewGallery"
                      title="View Gallery"
                      id="monthlyPaymentsReportViewImageGalleryButton"
                      onClick={() => {
                        getGalleryImages(vehicle)
                      }}
                    >
                      <img
                        src={viewGalleryIcon}
                        width="22"
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                    <div
                      className="viewNews"
                      title="View News"
                      id="monthlyPaymentsReportViewNewsButton"
                      onClick={() => {
                        getLatestNewsArticles(vehicle)
                      }}
                    >
                      <JATOIcon iconName="newspaper_outline" iconSize={24} />
                    </div>
                    {checkForValidScenerios(vehicle) ? (
                      <>
                        <div
                          className="expandMoreScenariosContainer"
                          title="Expand more scenerios"
                          id="monthlyPaymentsReportExpandMoreScenariosButton"
                          onClick={() => goToScenerioaPage(vehicle)}
                        >
                          <JATOIcon
                            iconName="baseline_fullscreen"
                            iconSize={24}
                          />
                        </div>
                        <div className="expandMoreScenarios">
                          {'('}
                          {getSceneriosCount(vehicle)}
                          {')'}
                        </div>
                      </>
                    ) : (
                      <div
                        className="scenariosError"
                        title="Scenarios not found"
                        onClick={() =>
                          showInfoToast(
                            'Scenarios not found',
                            'Matching monthly payment scenarios were not found for the vehicle'
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon="triangle-exclamation"
                          size="lg"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <ModalComponent
        modalVisible={modalNewsVisible}
        closeButton
        title={modalVehicleTitle}
        primaryButtonName=""
        onSecondaryClick={handleOnClose}
        secondaryButtonName=""
        onPrimaryClick={handleOnClose}
      >
        <VehicleNews vehicle={vehicle} />
      </ModalComponent>
      <ModalComponent
        modalVisible={modalGalleryVisible}
        closeButton
        title={modalVehicleTitle}
        primaryButtonName=""
        onSecondaryClick={handleOnClose}
        secondaryButtonName=""
        onPrimaryClick={handleOnClose}
      >
        <VehiclePhotoGallery mpVehicle={vehicleForGallery} />
      </ModalComponent>
    </StyledMonthlyPaymentsReportHeader>
  )
}
