import { Accordion } from 'components/Accordion/Accordion'
import { mpReportSpecsSection } from 'config'
import { GroupMetaData } from 'config/GroupMetaData'
import { getMonthlyPaymentDataGroupByCategoryName } from 'helper/getGroupedMetaDataRows'
import { getTransalatedDataForMpReportV2 } from 'helper/getTransalatedDataForMpReport'
import { LoginResponse } from 'models/Login/LoginResponse'
import { JatoDataErrorDetails } from 'models/Reports/JatoDataErrorDetails'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { StyledMonthlyPaymentsReportBody } from './MonthlyPaymentsReportBody.styles'

interface IMonthlyPaymentsReportBody {
  vehicles: MpVehicle[]
  mpReportMetaData: any
}

export const MonthlyPaymentsReportBody: React.FC<
  IMonthlyPaymentsReportBody
> = ({ vehicles, mpReportMetaData }: IMonthlyPaymentsReportBody) => {
  const hasScenarios =
    vehicles &&
    vehicles.length > 0 &&
    vehicles.some((v) => v.scenarios.length > 0)

  const getSingleScenerioData = (vehicles: MpVehicle[]): MpVehicle[] => {
    const singleScenerioResponse = vehicles.map(
      (mpValidResponse: MpVehicle) => {
        const singleScenerio: MpVehicle = {
          ...mpValidResponse,
          scenarios: mpValidResponse.scenarios.slice(0, 1),
        }
        return singleScenerio
      }
    )
    return singleScenerioResponse
  }

  const rowsMetaData = hasScenarios
    ? getMonthlyPaymentDataGroupByCategoryName(getSingleScenerioData(vehicles))
    : undefined

  const userData: LoginResponse = useAppSelector(selectUserData)
  const getTranslatedAllowedDataValue = (
    vehicle: MpVehicle,
    rowMetaData: string
  ): string => {
    const data = getTransalatedDataForMpReportV2(
      mpReportMetaData,
      vehicles,
      vehicle,
      rowMetaData,
      userData.user.settings.lastSelectedMarket?.countryCode
    )
    return data
  }

  const getTranslatedMetadata = (rowMetaData: string): string => {
    const data =
      mpReportMetaData.metadata[rowMetaData] !== undefined
        ? mpReportMetaData.metadata[rowMetaData].attribute
        : rowMetaData
    return data
  }

  const noSceneriosFound: JatoDataErrorDetails = {
    details:
      'Matching monthly payment scenarios were not found for the vehicle.',
    jatoErrorCode: 'JE2001',
    message:
      'Request to JATO Monthly Payments Service cannot be completed, as JATO does not have data for the requested vehicle.',
  }

  const specDataRows = mpReportSpecsSection.metadataRowNames
    .map((metadataRowName: string) => ({
      label: getTranslatedMetadata(metadataRowName),
      displayValues: vehicles.map((vehicle) =>
        getTranslatedAllowedDataValue(vehicle, metadataRowName)
      ),
    }))
    .filter((row) => row.displayValues.some((val) => val != null))

  return (
    <StyledMonthlyPaymentsReportBody>
      {!hasScenarios ? (
        noSceneriosFound.details
      ) : (
        <>
          <Accordion
            id={
              'monthlyPaymentsReport' +
              mpReportSpecsSection.title.replace(/ /g, '') +
              'Accordion'
            }
            titleText={mpReportSpecsSection.title}
            isOpen={mpReportSpecsSection.isOpen}
            titleHeaderWidth="72vw"
          >
            <table className="tableStyle">
              <tbody>
                {specDataRows.map((specDataRow, index) => (
                  <tr className="tableRow" key={index}>
                    <td key={index} className="tableCell sticky">
                      <div style={{ paddingLeft: '30px' }}>
                        {specDataRow.label}
                      </div>
                    </td>
                    {specDataRow.displayValues.map((displayValue, index) => (
                      <td key={index} className="tableCell">
                        <div className="tableValue">{displayValue}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Accordion>
          {rowsMetaData &&
            rowsMetaData.map((groupMetaDataRow: any, index: number) => (
              <Accordion
                id={
                  'monthlyPaymentsReport' +
                  groupMetaDataRow.key.replace(/ /g, '') +
                  'Accordion'
                }
                titleText={groupMetaDataRow.key}
                key={index}
                isOpen={true}
                titleHeaderWidth="72vw"
              >
                <table className="tableStyle" key={index}>
                  <tbody>
                    {groupMetaDataRow.value
                      .filter((data: GroupMetaData) => !data.isCategoryTitleRow)
                      .map((rowData: GroupMetaData, index: number) => (
                        <tr className="tableRow" key={index}>
                          <td
                            key={index}
                            className="tableCell sticky"
                            style={{
                              fontWeight: rowData.isStyleBold ? 600 : 400,
                            }}
                          >
                            <div style={{ paddingLeft: '30px' }}>
                              {getTranslatedMetadata(rowData.metadataRowName)}
                            </div>
                          </td>
                          {vehicles.map((vehicle, index) => (
                            <td
                              key={index}
                              className="tableCell"
                              style={{
                                fontWeight: rowData.isStyleBold ? 600 : 400,
                              }}
                            >
                              <div
                                style={{
                                  color:
                                    vehicle.scenarios[0]['dataSource'] === 'F'
                                      ? 'brown'
                                      : 'black',
                                }}
                                className="tableValue"
                              >
                                {getTranslatedAllowedDataValue(
                                  vehicle,
                                  rowData.metadataRowName
                                )}
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Accordion>
            ))}
        </>
      )}
    </StyledMonthlyPaymentsReportBody>
  )
}
