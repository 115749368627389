import {
  JATOText,
  JATOTheme,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import { OptionTextInfo } from 'models/Specifications/Options/OptionsBuildRuleResponse'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { StyledSpecificationsOptionBuild } from './SpecificationsOptionBuild.styles'

interface ISpecificationsOptionsLevel {
  optionLevelText: string
  optionLevelClassName: string
  optionInfos: OptionTextInfo[]
  parentOption?: OptionTextInfo
  selectedOptionId?: number
  setSelectedOptionId?: (selectedOptionId: number) => void
  isRadioButtonOptionAvailable: boolean
}
export const SpecificationsOptionsLevel: React.FC<
  ISpecificationsOptionsLevel
> = ({
  optionLevelText,
  optionLevelClassName,
  optionInfos,
  parentOption,
  selectedOptionId,
  setSelectedOptionId,
  isRadioButtonOptionAvailable,
}: ISpecificationsOptionsLevel) => (
  <StyledSpecificationsOptionBuild key={uuid()}>
    <div key={uuid()} className={optionLevelClassName}>
      <div key={uuid()}>
        <strong>{optionLevelText}</strong>
      </div>
      <JATOVerticalSpacer height={10} />
      {optionInfos.length > 1 && isRadioButtonOptionAvailable
        ? optionInfos.map((option) => (
            <div key={uuid()}>
              <div>
                <RadioWithGtm
                  checked={option.optionId === selectedOptionId}
                  onChange={() =>
                    setSelectedOptionId && setSelectedOptionId(option.optionId)
                  }
                  key={uuid()}
                  id="specsReportOptionsLevelRadioButton"
                  value={option.optionCode}
                  label={option.translatedOptionText}
                  parentLabel={parentOption?.optionCode}
                >
                  <JATOText
                    marginRight={JATOTheme.space[3]}
                    as="p"
                    fontSize={14}
                    key={uuid()}
                  >
                    {option.optionCode} - {option.translatedOptionText} -{' '}
                    {option.optionPrices.defaultMarketDisplayPrice}
                  </JATOText>
                </RadioWithGtm>
              </div>
              <JATOVerticalSpacer height={10} />
            </div>
          ))
        : optionInfos.map((option: any) => (
            <div key={uuid()} style={{ paddingBottom: '6px' }}>
              {option.optionCode} - {option.translatedOptionText} -{' '}
              {option.optionPrices.defaultMarketDisplayPrice}
            </div>
          ))}
    </div>
  </StyledSpecificationsOptionBuild>
)
