import React from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { RedirectToLogin } from './RedirectToLogin'
import Route, { IRouteProps } from './Route'

export const PrivateRoute: React.FC<IRouteProps> = (props: IRouteProps) => {
  const { guid } = useAppSelector(selectCommonUserData)

  const isAuthenticated = !!guid

  return isAuthenticated ? <Route {...props} /> : <RedirectToLogin />
}

export default PrivateRoute
