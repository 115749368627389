import { ArticleContentType } from 'helper/newsHelper'
import { ArticleTabs, ContentParts } from 'models/News/Article/NewsArticle'
import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { NewsArticleChart } from './NewsArticleChart'
import { StyledNewsArticleContent } from './NewsArticleContent.styles'
import { NewsArticleTabHeader } from './NewsArticleTabHeader'

interface INewsArticleContentProps {
  contentParts: ContentParts[]
}

export const NewsArticleContent: React.FC<INewsArticleContentProps> = ({
  contentParts,
}: INewsArticleContentProps) => {
  const [articleContentParts, setArticleContentParts] =
    useState<ContentParts[]>(contentParts)

  const hasTabs = (contentParts: ContentParts[]): boolean =>
    contentParts.some((contentPart: ContentParts) => contentPart.tabTitle)

  const onChangeTabs = (tabs: ArticleTabs): void => {
    setArticleContentParts(
      contentParts.filter(
        (content: ContentParts) =>
          content.tabTitle === tabs.tabTitle &&
          content.subTabTitle === tabs.subTabTitle
      )
    )
  }

  return (
    <StyledNewsArticleContent>
      {hasTabs(contentParts) && (
        <NewsArticleTabHeader
          contentParts={contentParts}
          onChangeTabs={onChangeTabs}
        />
      )}
      <div className={hasTabs(contentParts) ? 'contentBoxShadow' : ''}>
        {articleContentParts.map((articleContent: ContentParts) =>
          articleContent.type === ArticleContentType.Image ? (
            <NewsArticleChart key={uuid()} imageData={articleContent.image} />
          ) : (
            <div
              key={uuid()}
              dangerouslySetInnerHTML={{ __html: articleContent.copy }}
            />
          )
        )}
      </div>
    </StyledNewsArticleContent>
  )
}
