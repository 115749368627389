import { JATOTheme } from '@jato/ui-component-library'
import { Accordion } from 'components/Accordion/Accordion'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import Loader from 'components/Loader'
import { VehicleType } from 'helper/newsHelper'
import { getCountryName } from 'helper/volumesHelper'
import {
  useGetCountries,
  useGetVehicleVolumeTypes,
  useNewsLogin,
} from 'hooks/volumes'
import { VolumesNewsArticleRequest } from 'models/Volumes/VolumesNewsArticleRequest'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { setNewsUserState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesQueryState } from 'redux/volumes/volumesSlice'
import VolumesNewsArticleLink from '../VolumesNewsArticleLink'
import { StyledVolumesNewsArticles } from './VolumesNewsArticles.styles'

enum VolumesNewsCategory {
  EV = 'EV',
  MA = 'MA',
  EH = 'EH',
  MM = 'MM',
}
interface NewsLinkData {
  title: string
  request: VolumesNewsArticleRequest
}

interface NewsLinkCategoryData {
  category: VolumesNewsCategory
  newsLinkDataList: NewsLinkData[]
}

export const VolumesNewsArticles: React.FC = () => {
  const dispatch = useAppDispatch()
  const queryState = useAppSelector(getVolumesQueryState)
  const translations = useAppSelector(getTranslations)

  const { data: newsUserInfo, isFetching: isNewsLoginFetching } = useNewsLogin()
  useEffect(() => {
    if (newsUserInfo) {
      dispatch(setNewsUserState(newsUserInfo.user))
    }
  }, [newsUserInfo])

  const { data: countries, isFetching: isFetchingCountries } = useGetCountries(
    queryState.databaseType,
    !queryState.countries
  )
  const { data: datasets, isFetching: isFetchingDatasets } =
    useGetVehicleVolumeTypes(!queryState.dataSetIds)
  const isFetching =
    isNewsLoginFetching || isFetchingCountries || isFetchingDatasets

  const categoryTitles: { [key: string]: string } = {
    [VolumesNewsCategory.EV]: translations.JNT_Volumes_ElectricOverview,
    [VolumesNewsCategory.MA]: translations.JNT_Volumes_MakeModelOverview,
    [VolumesNewsCategory.EH]: translations.JNT_Volumes_SegmentFuelReport,
    [VolumesNewsCategory.MM]: translations.JNT_Volumes_VersionsRevenueReport,
  }

  const getExcludedCountryByCategory: { [key: string]: string[] } = {
    [VolumesNewsCategory.EV]: [
      'CY',
      'EE',
      'LV',
      'LT',
      'CN1',
      'CS',
      'PRI',
      'GRR',
    ],
    [VolumesNewsCategory.MA]: ['CY', 'CS', 'PRI', 'GRR', 'CN1'],
    [VolumesNewsCategory.EH]: ['CY', 'CS', 'PRI', 'GRR', 'CN1'],
    [VolumesNewsCategory.MM]: [
      'CY',
      'EE',
      'LV',
      'LT',
      'LUX',
      'CS',
      'SGP',
      'PRI',
      'GRR',
      'CN1',
    ],
  }

  const getEVNewsArticleLinks = (): NewsLinkCategoryData => ({
    category: VolumesNewsCategory.EV,
    newsLinkDataList:
      queryState.countries
        ?.filter(
          (c) =>
            !getExcludedCountryByCategory[VolumesNewsCategory.EV].includes(c)
        )
        .map((c) => ({
          title: `${getCountryName(c, countries)} ${
            translations.JNT_Volumes_LatestElectricCarRegistrations
          }`,
          request: {
            countryCode: c,
            quLevel: VolumesNewsCategory.EV,
            group: VehicleType.Cars,
          },
        })) ?? [],
  })

  const getNonEVNewsArticleLinks = (): NewsLinkCategoryData[] => {
    const data: any = Object.keys(categoryTitles)
      .filter((categoryKey: any) => categoryKey !== VolumesNewsCategory.EV)
      .map((category: any) => ({
        category: category,
        newsLinkDataList:
          datasets
            ?.filter(
              (d) =>
                d.pgProductGroup == queryState.databaseType &&
                queryState.dataSetIds?.includes(d.dstId) &&
                d.dsVehicleType != 'H' &&
                !getExcludedCountryByCategory[category].includes(d.pCtyId)
            )
            .map((d) => ({
              title: `${d.ctyDescrip} ${d.dbJoinDescrip}`,
              request: {
                countryCode: d.pCtyId,
                quLevel: category,
                group:
                  d.dsVehicleType == 'C'
                    ? VehicleType.Cars
                    : VehicleType.Commercial,
                dataSetType: d.dsDataType,
              },
            })) ?? [],
      }))

    return data
  }

  const getNewsLinkData: NewsLinkCategoryData[] = [
    getEVNewsArticleLinks(),
    ...getNonEVNewsArticleLinks(),
  ]

  return isFetching ? (
    <Loader style={{ minHeight: '150px' }} />
  ) : (
    <>
      {getNewsLinkData.some(
        (newsCategoryData: NewsLinkCategoryData) =>
          newsCategoryData.newsLinkDataList.length > 0
      ) ? (
        <StyledVolumesNewsArticles>
          <div className="accordion-container">
            <Accordion
              id="volumesNewsArticlesAccordion"
              titleText={translations.JNT_Volumes_NewsArticles}
              isOpen={true}
              titleHeaderWidth="100%"
              titleBackgroundColour={JATOTheme.colors.grey}
            >
              <div className="accordion-content ">
                {Object.keys(categoryTitles).map((category, index) =>
                  getNewsLinkData.some(
                    (newsCategoryData: NewsLinkCategoryData) =>
                      newsCategoryData.category === category &&
                      newsCategoryData.newsLinkDataList.length > 0
                  ) ? (
                    <div key={index}>
                      <div className="itemTitle">
                        {categoryTitles[category]}
                      </div>
                      <div className="itemContent">
                        {getNewsLinkData
                          .find(
                            (newsCategoryData: NewsLinkCategoryData) =>
                              newsCategoryData.category === category
                          )
                          ?.newsLinkDataList.map((d, index) => (
                            <GtmWrapper
                              key={index}
                              id="volumesNewsArticleLink"
                              label={d.title}
                              parentLabel={categoryTitles[category]}
                              value={`${d.request.quLevel}:${d.request.countryCode}:${d.request.group}:${d.request.dataSetType}`}
                            >
                              <VolumesNewsArticleLink
                                title={d.title}
                                request={d.request}
                              />
                            </GtmWrapper>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </div>
            </Accordion>
          </div>
        </StyledVolumesNewsArticles>
      ) : (
        <></>
      )}
    </>
  )
}
