import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsNoAlertFound = styled.div`
  height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;

  .notFoundImage {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: ${JATOTheme.colors.lightGrey};
    width: 130px;
    height: 130px;
  }
`
