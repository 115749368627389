import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { gtmLogLanguageId } from 'helper/gtm'
import { RootState } from 'redux/store'

export interface ICommonUserState {
  guid: string
  languageId: number
  invalidPasswordAttempts: number
}

const initialState: ICommonUserState = {
  guid: '',
  languageId: 19,
  invalidPasswordAttempts: 0,
}

const commonUserDataSlice = createSlice({
  name: 'commonUserData',
  initialState,
  reducers: {
    setCommonUserState(state, { payload }: PayloadAction<ICommonUserState>) {
      gtmLogLanguageId(payload.languageId)
      return { ...state, ...payload }
    },
    resetCommonUserState(state) {
      return { ...state, guid: '', invalidPasswordAttempts: 0 }
    },
    setInvalidPasswordAttempts(state, { payload }: PayloadAction<number>) {
      return { ...state, invalidPasswordAttempts: payload }
    },
    resetInvalidPasswordAttempts(state) {
      return { ...state, invalidPasswordAttempts: 0 }
    },
  },
})

export const {
  setCommonUserState,
  resetCommonUserState,
  setInvalidPasswordAttempts,
  resetInvalidPasswordAttempts,
} = commonUserDataSlice.actions

export const selectCommonUserData = (state: RootState): ICommonUserState =>
  state.commonUserData

export default commonUserDataSlice.reducer
