import { DataPointRequest } from '../../Volumes/DataPointRequest'
import { DpPoint } from '../../Volumes/DpPoint'
import { OutputPoint } from '../../Volumes/OutputPoint'
import { PeriodDef } from '../../Volumes/PeriodDef'
import { Region } from '../../Volumes/Region'

export interface VolumesUserState {
  userId: number
  guid: string
  firstName: string
  lastName: string
  userName: string
  password: string
  userGroup: number | null
  alreadyLoggedIn: boolean
  isAuthenticated: boolean
  role: boolean
  languageId: number
  currency: string
  textTag: string
  textTags: string[]
  isSuperUser: boolean
  isSalesperson: boolean
  captchaError: string
  isLicencedNews: boolean
  licenceStatus: string
  isAdmin: boolean
  acceptLicence: number
  responseMessage: string
  coM_Country: string
  coM_MainSalesName: string
  coM_MainSalesEmail: string
  coM_MainSalesTele: string
  releaseDateStart: string
  releaseDateEnd: string
  isVolumesLicenecM: boolean
  isVolumesLicenecV: boolean
  isVolumesLicenecX: boolean
  isVolumesLicenecF: boolean
  isSpecsLicence: boolean
  isMexicoIncentives: boolean
  dataSetIds: string[]
  dataPointName: string
  segmentIds: string[]
  rowFilter: string
  vehicleTypeList: string[]
  dataPointAllowId: number
  registationTypes: string[]
  dataPointRequest: DataPointRequest
  isExcelExportCall: boolean
  isUserLocked: boolean
  lastSelectedNewsMarket: string
  isOesCustomer: boolean
  isFirstTimeLogin: boolean
  hideWelcomeInfo: boolean | null
  hideJATOAcademyPopUp: boolean | null
}

// Default values for the custom interfaces
const defaultDpPoint: DpPoint = {
  state: false,
  id: '',
  iddesc: '',
  value: '',
  valuedesc: '',
  part: 0,
  minValue: 0,
  maxValue: 0,
  optype: '',
}

const defaultOutputPoint: OutputPoint = {
  id: '',
  iddesc: '',
  optype: '',
}

const defaultPeriodDef: PeriodDef = {
  periodIdentifier: '',
  periodName: '',
  concatString: '',
  periodSequence: 0,
  periodGroup: '',
}

const defaultRegion: Region = {
  regionLevel: 0,
  regionChecked: false,
  regionSelectedData: [],
}

// Default DataPointRequest
export const defaultDataPointRequest: DataPointRequest = {
  segment_SegmentList: [],
  segment_Checked: false,
  segment_GroupID: 0,
  bandingCurrency: '',
  pricepointlist: [defaultDpPoint],

  chartType: '',
  chartHeader: '',
  chartYAxisTitle: '',
  chartXAxisTitle: '',
  chartHeight: '',
  chartWidth: '',

  productGroup: '',

  vehicleTypeList: [],
  segment_segSource: '',

  registrationTypeList: [],

  outPutLangaugeId: 0,

  dataPointName: '',
  dataPointGroup: '',
  datePointUnit: '',
  dataSetIds: [],

  countryList: [],
  dataSetId: [],
  modelList: [],
  dataSetPersist: [],

  outputPoints: [defaultOutputPoint],
  dataPoints: [defaultDpPoint],

  regionPoints: [defaultRegion],

  periodGroup: '',

  periodPoints: [defaultPeriodDef],

  analysisOptions: [],
  outputOptions: '',
  hasRegions: false,
  isNewQuery: '',
}

export const defaultVolumesUserState: VolumesUserState = {
  userId: 0,
  guid: '',
  firstName: '',
  lastName: '',
  userName: '',
  password: '',
  userGroup: null,
  alreadyLoggedIn: false,
  isAuthenticated: false,
  role: false,
  languageId: 0,
  currency: '',
  textTag: '',
  textTags: [],
  isSuperUser: false,
  isSalesperson: false,
  captchaError: '',
  isLicencedNews: false,
  licenceStatus: '',
  isAdmin: false,
  acceptLicence: 0,
  responseMessage: '',
  coM_Country: '',
  coM_MainSalesName: '',
  coM_MainSalesEmail: '',
  coM_MainSalesTele: '',
  releaseDateStart: '',
  releaseDateEnd: '',
  isVolumesLicenecM: false,
  isVolumesLicenecV: false,
  isVolumesLicenecX: false,
  isVolumesLicenecF: false,
  isSpecsLicence: false,
  isMexicoIncentives: false,
  dataSetIds: [],
  dataPointName: '',
  segmentIds: [],
  rowFilter: '',
  vehicleTypeList: [],
  dataPointAllowId: 0,
  registationTypes: [],
  dataPointRequest: defaultDataPointRequest,
  isExcelExportCall: false,
  isUserLocked: false,
  lastSelectedNewsMarket: '',
  isOesCustomer: false,
  isFirstTimeLogin: false,
  hideWelcomeInfo: false,
  hideJATOAcademyPopUp: false,
}
