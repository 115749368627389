import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { getDatasetFields } from 'helper/volumesHelper'
import {
  useGetVehicleVolumeGroups,
  useGetVehicleVolumeTypes,
} from 'hooks/volumes'
import { GroupedDatasets } from 'models/Volumes/GroupedDatasets'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getVolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { IVolumesQuerySelectionPanelRef } from './VolumesQuerySelectionPanel'

const VolumesQueryDatasetSelectionPanel: React.ForwardRefRenderFunction<
  IVolumesQuerySelectionPanelRef,
  unknown
> = (_: unknown, ref) => {
  const dispatch = useAppDispatch()

  useImperativeHandle(ref, () => ({
    onSelectAll,
    onClearChoices,
  }))

  const queryState = useAppSelector(getVolumesQueryState)

  const { data: datasets, isFetching: datasetsFetching } =
    useGetVehicleVolumeTypes()
  const { data: datasetGroups, isFetching: datasetGroupsFetching } =
    useGetVehicleVolumeGroups()

  const isFetching = datasetGroupsFetching || datasetsFetching

  const [displayedGroups, setDisplayedGroups] = useState<GroupedDatasets[]>([])
  const [selectedGroups, setSelectedGroups] = useState<GroupedDatasets[]>([])

  useEffect(() => {
    if (datasets && datasetGroups) {
      const groups = getGroupedDatasets()
      const selectedGroups = getSelectedGroups(groups)
      setDisplayedGroups(groups)
      console.log(groups)
      setSelectedGroups(selectedGroups)
    }
  }, [datasets, datasetGroups])

  const onSelectedGroupsChange = (group: GroupedDatasets): void => {
    const newSelection = selectedGroups.includes(group)
      ? selectedGroups.filter((d) => d !== group)
      : [...selectedGroups, group]

    setSelectedGroups(newSelection)

    if (newSelection.length > 0) {
      const selectedDatasets = newSelection.flatMap((g) => g.datasetIds)
      dispatch(
        setVolumesQueryState({
          ...queryState,
          periods: [],
          ...getDatasetFields(selectedDatasets, datasets),
        })
      )
    }
  }

  const onSelectAll = (): void => {
    setSelectedGroups(displayedGroups)

    const selectedDatasets = displayedGroups.flatMap((g) => g.datasetIds)
    dispatch(
      setVolumesQueryState({
        ...queryState,
        ...getDatasetFields(selectedDatasets, datasets),
      })
    )
  }

  const onClearChoices = (): void => {
    setSelectedGroups([])
  }

  const getGroupedDatasets = (): GroupedDatasets[] => {
    if (!datasets) return []

    const dataSetIds =
      datasetGroups
        ?.find((g) => g.productGroup === queryState.databaseType)
        ?.dataSetItems.map((d) => d.dataSetItemId) ?? []

    const groupsMap = datasets
      .filter(
        (d) =>
          dataSetIds.includes(d.dstId) &&
          d.pgProductGroup === queryState.databaseType &&
          queryState.countries?.includes(d.pCtyId)
      )
      .reduce((acc, d) => {
        const [group, date] = d.dsDataGroupType.slice(0, -1).split(' (')
        const obj = acc.get(group)
        if (obj) {
          obj.date = Date.parse(obj.date) > Date.parse(date) ? obj.date : date
          obj.datasetIds.push(d.dstId)
          return acc.set(group, obj)
        } else {
          const newObj = { group, date, datasetIds: [d.dstId] }
          return acc.set(group, newObj)
        }
      }, new Map<string, GroupedDatasets>())

    return Array.from(groupsMap.values())
  }

  const getSelectedGroups = (groups: GroupedDatasets[]): GroupedDatasets[] =>
    groups.filter((g) =>
      g.datasetIds.some((d) => queryState.dataSetIds?.includes(d))
    )

  return (
    <>
      {displayedGroups.map((d) => (
        <CheckboxWithGtm
          key={d.group}
          id="volumesQueryDatasetSelectionPanelCheckbox"
          value={d.datasetIds.join()}
          label={d.group}
          checked={selectedGroups.includes(d)}
          onChange={() => onSelectedGroupsChange(d)}
        >
          {`${d.group} (${d.date})`}
        </CheckboxWithGtm>
      ))}

      <LoaderModal isOpen={isFetching} />
    </>
  )
}

export default React.forwardRef(VolumesQueryDatasetSelectionPanel)
