import { JATOSpinner } from '@jato/ui-component-library'
import { MultiSelectWithGtm } from 'components/Gtm/MultiSelectWithGtm'
import { SliderWithGtm } from 'components/Gtm/SliderWithGtm'
import { SliderValue } from 'components/MonthlyPayments/Filters/SliderFilter/SliderFilter'
import { UniqueIdentityFilter } from 'components/MonthlyPayments/Filters/UniqueIdentityFilter'
import { gtmLogSpecsAdvancedFiltersChange } from 'helper/gtm'
import { useGetAdvanceFilterAllowedValueList } from 'hooks/carSpecs'
import { IFilterOption } from 'models/Filters/FilterOption'
import { FilterParentType } from 'models/Filters/FilterType'
import {
  AdvancedFilterType,
  IOptionFilterType,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import {
  AdvanceFilterInfoObject,
  FilterValue,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersResponse'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getAdvancedFilters,
  setAdvancedFilters,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import { StyledSpecsAdvanceFilterBasicCard } from './SpecsAdvanceFilter.styles'

export interface ISpecsAdvanceFilterProps {
  filterInfo: AdvanceFilterInfoObject
  optionFilterType: IOptionFilterType
}

export const SpecsAdvanceFilter: React.FC<ISpecsAdvanceFilterProps> = ({
  filterInfo,
  optionFilterType,
}: ISpecsAdvanceFilterProps) => {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const specsAdvancedFilters = useAppSelector(getAdvancedFilters)

  const filterName = filterInfo.applicationText
  const filterLabel = `${filterInfo.applicationText} (${optionFilterType.text})`

  const { data: filterValues, isFetching } =
    useGetAdvanceFilterAllowedValueList(filterInfo, optionFilterType)

  const validateFilterValues = (
    values: FilterValue[] | undefined,
    type: AdvancedFilterType
  ): boolean => {
    if (!values) {
      return false
    }

    switch (type) {
      case AdvancedFilterType.Range:
        return values.length > 1 && values[0].value !== values[1].value
      case AdvancedFilterType.List:
        return values.length > 0
      case AdvancedFilterType.FreeSearch:
        return true
      default:
        return false
    }
  }

  const getSliderValue = (): SliderValue | undefined => {
    const values =
      (specsAdvancedFilters.filters[filterName] &&
        specsAdvancedFilters.filters[filterName][0]?.value
          .split('|')
          .map((value) => Number(value))) ??
      (filterValues && filterValues.map((fv) => Number(fv.value)))

    return values && { min: values[0], max: values[1] }
  }

  const getFilterValue = (): string =>
    specsAdvancedFilters.filters[filterName]?.map((f) => f.value).join(',')

  const getMultiSelectOptions = (): IFilterOption[] =>
    filterValues?.map((f) => ({
      key: uuid(),
      value: f.value,
      displayValue: f.label,
      filterName: filterName,
      filterParentType: FilterParentType.SpecAdvancedFilters,
    })) ?? []

  const onChangeSlider = (value: number[]): void => {
    const [min, max] = value

    const selectedRangeFilter = {
      key: uuid(),
      displayValue: `${min} | ${max}`,
      value: `${min}|${max}`,
      filterName: filterName,
      filterParentType: FilterParentType.SpecAdvancedFilters,
      max: max,
      min: min,
    }

    setFiltersState([selectedRangeFilter])
  }

  const onChangeUid = (value: string): void => {
    const selectedUidFilter = {
      key: uuid(),
      displayValue: value,
      value: value,
      filterName: filterName,
      filterParentType: FilterParentType.SpecAdvancedFilters,
    }
    setFiltersState([selectedUidFilter])
  }

  const setFiltersState = (selectedOptions: IFilterOption[]): void => {
    const updatedFilters = {
      ...specsAdvancedFilters.filters,
      [filterName]: selectedOptions.map((option) => ({
        ...option,
        label: filterLabel,
        optionFilterType: optionFilterType.value,
        schemaId: filterInfo.schemaId,
        filteringType: filterInfo.filterType,
      })),
    }

    const specFiltersUpdate = {
      ...specsAdvancedFilters,
      filters: updatedFilters,
    }

    dispatch(setAdvancedFilters(specFiltersUpdate))

    gtmLogSpecsAdvancedFiltersChange(updatedFilters)
  }

  const renderSwitch = (): JSX.Element => {
    switch (filterInfo.filterType) {
      case AdvancedFilterType.Range:
        return (
          <SliderWithGtm
            id="specsAdvancedFilters"
            label={filterName}
            value={getSliderValue()}
            filterName={filterName}
            onChange={onChangeSlider}
          />
        )
      case AdvancedFilterType.List:
        return (
          <MultiSelectWithGtm
            id="specsAdvancedFilters"
            label={filterName}
            filterName={filterName}
            value={getFilterValue()}
            options={getMultiSelectOptions()}
            showCount={false}
            isDisabled={isFetching}
            handleOnChange={setFiltersState}
          />
        )
      case AdvancedFilterType.FreeSearch:
        return (
          <UniqueIdentityFilter
            id="specsAdvancedFilters_UID"
            label={filterName}
            value={getFilterValue()}
            onChange={onChangeUid}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <StyledSpecsAdvanceFilterBasicCard style={{ marginTop: '10px' }}>
      {isFetching ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <JATOSpinner spinnerSize={64} />
        </div>
      ) : validateFilterValues(filterValues, filterInfo.filterType) ? (
        renderSwitch()
      ) : (
        <div style={{ color: 'red' }}>{translations.JNT_Nofilteroptions}</div>
      )}
    </StyledSpecsAdvanceFilterBasicCard>
  )
}
