import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsResultsCount } from './NewsResultsCount.styles'

interface INewsResultsCountProps {
  currentPage: number
  pageSize: number
  totalNumber: number
}

export const NewsResultsCount: React.FC<INewsResultsCountProps> = ({
  currentPage,
  pageSize,
  totalNumber,
}: INewsResultsCountProps) => {
  const translations = useAppSelector(getTranslations)

  const start = (currentPage - 1) * pageSize + 1
  const end =
    currentPage * pageSize > totalNumber ? totalNumber : currentPage * pageSize

  return (
    <StyledNewsResultsCount>
      {`${translations.JNT_Showing} `}
      <b>{`${start}-${end}`}</b>
      {` ${translations.JNT_of} `}
      <b>{totalNumber}</b>
    </StyledNewsResultsCount>
  )
}
