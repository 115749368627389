import { FilterNameType } from 'models/Filters/FilterNameType'
import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import { FilterValueType } from 'models/Filters/FilterType'
import { MileageUnits } from 'models/Filters/FilterUnits'
import { ValueWithUnit } from 'models/Filters/ValueWithUnit'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import moment from 'moment'
import { IMappingObject } from './IMappingObject'

const scenariosPrefix = 'scenarios/'

export const specFilterNamesToFields: IMappingObject = {
  [FilterNameType.Make]: 'make',
  [FilterNameType.Model]: 'makeModel',
  [FilterNameType.BodyType]: 'bodyType',
  [FilterNameType.NumberOfDoors]: 'numberOfDoors',
  [FilterNameType.Transmission]: 'transmissionDescription',
  [FilterNameType.PowerTrain]: 'powertrainType',
  [FilterNameType.Fuel]: 'fuelType',
  [FilterNameType.ModelYear]: 'modelYear',
  [FilterNameType.DrivenWheels]: 'drivenWheels',
  [FilterNameType.Segmentation]: 'jatoRegionalSegment',
  [FilterNameType.TimeRange]: 'timeRange',
  [FilterNameType.ContractLength]: 'scenarios/contractDurationMonths',
  [FilterNameType.ContractType]: 'scenarios/monthlyPaymentType',
  [FilterNameType.Deposit]: 'scenarios/depositMsrp',
  [FilterNameType.NumberOfMonthlyInstalments]:
    'scenarios/numberOfMonthlyInstalments',
  [FilterNameType.RegularMonthlyInstalmentAmount]:
    'scenarios/regularMonthlyInstalmentAmountMsrp',
  [FilterNameType.ProductName]: 'scenarios/productName',
  [FilterNameType.YearlyMileage]: 'scenarios/',
  [FilterNameType.TotalContractMileage]: 'scenarios/',
  [FilterNameType.Insurance]: 'scenarios/insurance',
}

export const fieldNamesByUnits: IMappingObject<IMappingObject> = {
  [FilterNameType.YearlyMileage]: {
    [MileageUnits.Miles]: 'scenarios/yearlyMileageMiles',
    [MileageUnits.Kilometres]: 'scenarios/yearlyMileageKm',
  },
  [FilterNameType.TotalContractMileage]: {
    [MileageUnits.Miles]: 'scenarios/totalContractMileageMiles',
    [MileageUnits.Kilometres]: 'scenarios/totalContractMileageKm',
  },
}

export const buildSearchQuery = (
  selectedFiltersData: ISelectedFilterOption[],
  namesToFieldsMapping: IMappingObject
): string => {
  const queriesByField = Object.keys(namesToFieldsMapping).map((filterName) => {
    const filters = selectedFiltersData.filter(
      (f) => f.filterName === filterName
    )

    if (filters.length == 0) {
      return null
    }
    const field = namesToFieldsMapping[filterName]
    const fieldQuery = getFieldQuery(filters, field)

    return field.startsWith('scenarios')
      ? `scenarios/any(scenarios:${fieldQuery})`
      : fieldQuery
  })

  // display vehicles current vehicles only
  queriesByField.push(`concludeDate eq 9999-12-31T00:00:00.000Z`)

  const result = queriesByField.filter((q) => q).join(' and ')

  return result
}

const getFieldQuery = (
  filters: ISelectedFilterOption[],
  field: string
): string => {
  switch (filters[0].type) {
    case FilterValueType.Number:
      return `(${filters.map((f) => `${field} eq ${f.value}`).join(' or ')})`
    case FilterValueType.ValueWithUnit:
      return `(${filters.map((f) => getValueWithUnitQuery(f)).join(' or ')})`
    case FilterValueType.Range:
      return `(${filters
        .map((f) => `${field} ge ${f.min} and ${field} le ${f.max}`)
        .join(' or ')})`
    default:
      return `search.in(${field}, '${filters.map((f) => f.value).join()}',',')`
  }
}

const getValueWithUnitQuery = (filter: ISelectedFilterOption): string => {
  const { value, unit } = ValueWithUnit.parse(filter.value)
  const field = fieldNamesByUnits[filter.filterName][unit]

  return `${field} eq ${value}`
}

export const filterScenarios = (
  vehicles: MpVehicle[],
  selectedFilters: ISelectedFilterOption[]
): MpVehicle[] => {
  const predicates = getScenarioPredicates(selectedFilters)
  return vehicles.map((v) => ({
    ...v,
    scenarios: v.scenarios.filter((scenario) =>
      predicates.every((predicate) => predicate(scenario))
    ),
  }))
}

const getScenarioPredicates = (
  selectedFilters: ISelectedFilterOption[]
): ((scenario: any) => boolean)[] =>
  Object.entries(specFilterNamesToFields)
    .filter(
      ([filterName, field]) =>
        field.startsWith(scenariosPrefix) &&
        selectedFilters.some((f) => f.filterName === filterName)
    )
    .map(([filterName, field]) => {
      const filters = selectedFilters.filter((f) => f.filterName === filterName)
      let filterValues = filters.map((f) => f.value)
      const filterType = filters[0].type
      if (filterType === FilterValueType.ValueWithUnit) {
        const { unit } = ValueWithUnit.parse(filterValues[0])
        filterValues = filterValues.map((v) => ValueWithUnit.parse(v).value)
        field = fieldNamesByUnits[filterName][unit]
      }

      const fieldName = field.replace(scenariosPrefix, '')

      switch (filterType) {
        case FilterValueType.Range:
          const [min, max] = filterValues[0].split('|')
          return (scenario: any) =>
            scenario[fieldName] >= Number(min) &&
            scenario[fieldName] <= Number(max)

        default:
          return (scenario: any) =>
            filterValues.includes(scenario[fieldName]?.toString())
      }
    })
