import {
  JATOButton,
  JATOButtonIcon,
  JATODropdown,
  JATODropdownMenu,
  JATOIcon,
  JATOTheme,
} from '@jato/ui-component-library'
import tipsIcon from 'assets/icons/tips.svg'
import { ModalComponent } from 'components/ModalComponent'
import { CommonModal } from 'components/Modals/CommonModal'
import { MonthlyPaymentsFaq } from 'components/MonthlyPayments/MonthlyPaymentsFaq'
import MyPageSalesEnquiryModal from 'components/MyPage/MyPageSalesEnquiryModal'
import NewsFaq from 'components/News/NewsFaq'
import { SpecificationsFaqModal } from 'components/Specifications/SpecificationsFaq/SpecificationsFaqModal'
import { TrainingVideosContainer } from 'components/TrainingVideos/TrainingVideosContainer'
import VolumesFaq from 'components/Volumes/VolumesFaq'
import { WelcomeInfo } from 'components/WelcomeInfo'
import {
  knowledgeArticleSearchUrl,
  openSupportCaseUrl,
  technicalSupportUrl,
} from 'config'
import { getKnowledgeArticleSearchUrl } from 'helper/newsHelper'
import { useRootPathResolver } from 'hooks/useRootPathResolver'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledHelpMenu } from './HelpMenu.styles'

export const HelpMenu: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const [modalVideosVisible, setModalVideosVisible] = useState(false)
  const [modalWelcomeInfoVisible, setModalWelcomeInfoVisible] = useState(false)
  const [modalMPFaqVisible, setModalMPFaqVisible] = useState(false)
  const [modalWltpFaqVisible, setModalWltpFaqVisible] = useState(false)
  const [modalNewsFaqVisible, setModalNewsFaqVisible] = useState(false)
  const [modalVolumesFaqVisible, setModalVolumesFaqVisible] = useState(false)
  const [isSalesEnquiryModalOpen, setSalesEnquiryModalOpen] = useState(false)
  const { isNews, isVolumes, isSpecifications, isMonthlyPayments, routeName } =
    useRootPathResolver()
  return (
    <StyledHelpMenu>
      <JATODropdown
        trigger={() => (
          <JATOButtonIcon
            id="headerHelpMenuButton"
            iconName="custom_help_outline"
            iconFill={JATOTheme.colors.primary}
          />
        )}
      >
        <JATODropdownMenu className="dropdown">
          <JATOButton
            variant="ghost"
            display="flex"
            disabled
            className="menuHeaderItem"
          >
            <img src={tipsIcon} width="22" className="menuHeaderIcon" />
            {translations.JNT_Help_Tips}
          </JATOButton>
          <JATOButton
            id="headerHelpMenuWelcomeInfoButton"
            variant="ghost"
            display="flex"
            className="menuItem"
            onClick={() => setModalWelcomeInfoVisible(true)}
          >
            {translations.JNT_WelcomeInfo}
          </JATOButton>
          <JATOButton
            id="headerHelpMenuHowToVideosButton"
            variant="ghost"
            display="flex"
            className="menuItem"
            onClick={() => setModalVideosVisible(true)}
          >
            {translations.JNT_Help_Howtovideo}
          </JATOButton>
          {isNews && (
            <JATOButton
              id="headerHelpMenuNewsFaqButton"
              variant="ghost"
              display="flex"
              className="menuItem"
              onClick={() => setModalNewsFaqVisible(true)}
            >
              {translations.JNT_SRCH_AlertFAQ}
            </JATOButton>
          )}
          {isVolumes && (
            <JATOButton
              id="headerHelpMenuVolumesFaqButton"
              variant="ghost"
              display="flex"
              className="menuItem"
              onClick={() => setModalVolumesFaqVisible(true)}
            >
              {translations.JNT_Help_VolumesFaq}
            </JATOButton>
          )}
          {isSpecifications && (
            <JATOButton
              id="headerHelpMenuWltpFaqButton"
              variant="ghost"
              display="flex"
              className="menuItem"
              onClick={() => setModalWltpFaqVisible(true)}
            >
              {translations.JNT_Help_WltpFaq}
            </JATOButton>
          )}
          {isMonthlyPayments && (
            <JATOButton
              id="headerHelpMenuMonthlyPaymentsFaqButton"
              variant="ghost"
              display="flex"
              className="menuItem"
              onClick={() => setModalMPFaqVisible(true)}
            >
              {translations.JNT_Help_MPFaq}
            </JATOButton>
          )}
          <JATOButton
            variant="ghost"
            display="flex"
            disabled
            className="menuHeaderItem"
          >
            <JATOIcon
              iconName="custom_help"
              iconSize={22}
              iconFill={JATOTheme.colors.primary}
              className="menuHeaderIcon"
            />
            {translations.JNT_Help_HelpTag}
          </JATOButton>
          <JATOButton
            id="headerHelpMenuSalesInquiryButton"
            variant="ghost"
            display="flex"
            className="menuItem"
            onClick={() => setSalesEnquiryModalOpen(true)}
          >
            {translations.JNT_Help_SalesInquiry}
          </JATOButton>
          <a
            id="headerHelpMenuSupportCentreLink"
            href={technicalSupportUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="menuLinkItem"
          >
            <JATOButton   variant="ghost" display="flex" className="menuItem">
              {translations.JNT_Help_SupportCentre}
            </JATOButton>
          </a>
          <a
            id="headerHelpMenuKnowledgeArticleSearchLink"
            href={
              getKnowledgeArticleSearchUrl()[routeName] ??
              knowledgeArticleSearchUrl
            }
            target="_blank"
            rel="noopener noreferrer"
            className="menuLinkItem"
          >
            <JATOButton  variant="ghost" display="flex" className="menuItem">
              {translations.JNT_Help_KnowledgeArticleSearch}
            </JATOButton>
          </a>
          <a
            id="headerHelpMenuOpenSupportCaseLink"
            href={openSupportCaseUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="menuLinkItem"
          >
            <JATOButton  variant="ghost" display="flex" className="menuItem">
              {translations.JNT_Help_OpenSupportCase}
            </JATOButton>
          </a>
        </JATODropdownMenu>
      </JATODropdown>

      <ModalComponent
        modalVisible={modalVideosVisible}
        closeButton
        title={translations.JNT_Help_Howtovideo}
        primaryButtonName=""
        onSecondaryClick={() => setModalVideosVisible(false)}
        secondaryButtonName=""
        onPrimaryClick={() => setModalVideosVisible(true)}
        isPrimaryButtonLoading={false}
      >
        <TrainingVideosContainer />
      </ModalComponent>
      <ModalComponent
        modalVisible={modalWelcomeInfoVisible}
        closeButton
        title={translations.JNT_WelcomeInfo}
        primaryButtonName=""
        onSecondaryClick={() => setModalWelcomeInfoVisible(false)}
        secondaryButtonName=""
        onPrimaryClick={() => setModalWelcomeInfoVisible(true)}
        isPrimaryButtonLoading={false}
      >
        <WelcomeInfo />
      </ModalComponent>
      <ModalComponent
        modalVisible={modalMPFaqVisible}
        closeButton
        title={translations.JNT_FAQTitle}
        primaryButtonName=""
        onSecondaryClick={() => setModalMPFaqVisible(false)}
        secondaryButtonName=""
        onPrimaryClick={() => setModalMPFaqVisible(false)}
        isPrimaryButtonLoading={false}
      >
        <MonthlyPaymentsFaq />
      </ModalComponent>
      <SpecificationsFaqModal
        isOpen={modalWltpFaqVisible}
        onClose={() => setModalWltpFaqVisible(false)}
      />
      <CommonModal
        closeButton
        isOpen={modalNewsFaqVisible}
        onClose={() => setModalNewsFaqVisible(false)}
        title={translations.JNT_SRCH_AlertFAQ}
      >
        <NewsFaq />
      </CommonModal>
      <CommonModal
        closeButton
        isOpen={modalVolumesFaqVisible}
        onClose={() => setModalVolumesFaqVisible(false)}
        title={translations.JNT_Help_VolumesFaq}
      >
        <VolumesFaq />
      </CommonModal>
      {isSalesEnquiryModalOpen && (
        <MyPageSalesEnquiryModal
          isOpen={isSalesEnquiryModalOpen}
          onClose={() => setSalesEnquiryModalOpen(false)}
        />
      )}
    </StyledHelpMenu>
  )
}
