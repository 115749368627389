import { JATOTag } from '@jato/ui-component-library'
import {
  defaultToDate,
  getCountryByCode,
  getDateRangeFilterValue,
  NewsFilterName,
  threeMonthFromDateOption,
} from 'helper/newsHelper'
import { useGetVehicleTypes } from 'hooks/news'
import { getNewsCategoryTranslations } from 'models/News/NewsCategoryType'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/hook'
import {
  getNewsCountries,
  getNewsFilters,
  setNewsSelectedFilter,
} from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import { StyledNewsSelectedFilters } from './NewsSelectedFilters.styles'

interface ISelectedFilter {
  filterName: string
  value: string
  displayValue: string
}

export const NewsSelectedFilters: React.FC = () => {
  const dispatch = useDispatch()
  const translations = useAppSelector(getTranslations)
  const newsFilters = useAppSelector(getNewsFilters)
  const countries = useAppSelector(getNewsCountries)
  const categoryTranslations = getNewsCategoryTranslations(translations)
  const { data: vehicleTypes } = useGetVehicleTypes()

  const getDisplayValue = (value: string, filterName: string): string => {
    switch (filterName) {
      case NewsFilterName.Category:
        return categoryTranslations[value] ?? value
      case NewsFilterName.Country:
        return getCountryByCode(value, countries, translations).name
      case NewsFilterName.Group:
        return vehicleTypes?.find((v) => v.key === value)?.sourceText ?? value
      default:
        return value
    }
  }

  const appliedFiltersCount = Object.values(newsFilters.selectedFilters).flat()
    .length

  const selectedFiltersFlat: ISelectedFilter[] = Object.entries(
    newsFilters.selectedFilters
  )
    .filter(([filterName]) => filterName !== NewsFilterName.ReleaseDate)
    .flatMap(([filterName, values]) =>
      values.map((value) => ({
        filterName: filterName,
        value: value,
        displayValue: getDisplayValue(value, filterName),
      }))
    )

  const releaseDateFilterValues = getDateRangeFilterValue(
    newsFilters.selectedFilters[NewsFilterName.ReleaseDate]
  )
  const fromDate = moment(releaseDateFilterValues[0])
  const toDate = moment(releaseDateFilterValues[1])

  const getReleaseDateFilterDisplayValue = (): string =>
    `${fromDate.format('DD/MM/YYYY')} - ${toDate.format('DD/MM/YYYY')}`

  const isDefaultReleaseDateValue =
    fromDate.isSame(threeMonthFromDateOption()) &&
    toDate.isSame(defaultToDate())

  const handleRemoveReleaseDateFilter = (): void => {
    dispatch(
      setNewsSelectedFilter({
        [NewsFilterName.ReleaseDate]: [],
      })
    )
  }

  const handleRemoveNewsFilter = (filterName: string, value: string): void => {
    const options =
      newsFilters.selectedFilters[filterName]?.filter((v) => v !== value) ?? []

    dispatch(
      setNewsSelectedFilter({
        [filterName]: options,
      })
    )
  }

  return appliedFiltersCount > 0 ? (
    <StyledNewsSelectedFilters>
      <JATOTag
        id="newsSelectedFiltersTag"
        isDismissible={!isDefaultReleaseDateValue}
        onClick={handleRemoveReleaseDateFilter}
        variant="secondary"
      >
        {getReleaseDateFilterDisplayValue()}
      </JATOTag>
      {selectedFiltersFlat.map((f) => (
        <JATOTag
          id="newsSelectedFiltersTag"
          key={uuid()}
          isDismissible
          onClick={() => handleRemoveNewsFilter(f.filterName, f.value)}
          variant="secondary"
        >
          {f.displayValue}
        </JATOTag>
      ))}
    </StyledNewsSelectedFilters>
  ) : (
    <></>
  )
}
