import { AxiosResponse } from 'axios'
import { VehiclePhotosRequest } from 'models/VehiclePhotos/VehiclePhotosRequest'
import { newsApi } from 'services/apiWithAuth'

class VehiclePhotosService {
  public getVehiclesPhotos(
    vehiclePhotosRequest: VehiclePhotosRequest
  ): Promise<AxiosResponse<string[]>> {
    const vehiclePhotosResponse = newsApi.post<
      VehiclePhotosRequest,
      AxiosResponse<string[]>
    >(`/getmonthlypaymentsphotos/getall`, vehiclePhotosRequest)
    return vehiclePhotosResponse
  }
}

const vehiclePhotosService = new VehiclePhotosService()
export default vehiclePhotosService
