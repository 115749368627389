import {
  IMenuItem,
  JATONavGroup,
  JATONavItem,
  JATONavMenuDropdown,
} from '@jato/ui-component-library'
import { useGetBrochureVehicles } from 'hooks/carSpecs'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledSpecsReportNavMenu } from './SpecsReportNavMenu.styles'

export const SpecsReportNavMenu: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const location = useLocation()
  const history = useHistory()
  const { data: { brochureVehicles } = { brochureVehicles: [] } } =
    useGetBrochureVehicles()
  const isShowroom = location.pathname.includes(RoutePaths.SpecsShowroom)
  const isAdvantages = location.pathname.includes(RoutePaths.SpecsAdvantages)
  const isDisadvantages = location.pathname.includes(
    RoutePaths.SpecsDisadvantages
  )
  const isColorsOptions = location.pathname.includes(RoutePaths.SpecsColors)
  const isOptions = location.pathname.includes(RoutePaths.SpecsOptionsOptions)
  const isPackages = location.pathname.includes(RoutePaths.SpecsPackages)
  const isBaseOptions = location.pathname.includes(RoutePaths.SpecsBaseOptions)

  const handleRouting = (menuItem: IMenuItem): IMenuItem => ({
    ...menuItem,
    // this is shown when item is hovered, also used as a key inside JATONavMenuDropdown
    path: `${process.env.PUBLIC_URL}${menuItem.path}`,
    // workaround to be able to use client side routing with JATONavMenuDropdown
    onClick: (e) => {
      e.preventDefault()
      history.push({
        pathname: menuItem.path,
        search: location.search, // preserve query parameters when navigating between pages
      })
    },
  })

  const optionsDropdownItems: IMenuItem[] = [
    {
      id: 'specsOptionsMenuOptionsButton',
      content: translations.JNT_optionstext,
      path: RoutePaths.SpecsOptionsOptions,
      isActive: isOptions,
    },
    {
      id: 'specsOptionsMenuPackagesButton',
      content: translations.JNT_packages,
      path: RoutePaths.SpecsPackages,
      isActive: isPackages,
    },
    {
      id: 'specsOptionsMenuColorsButton',
      content: translations.JNT_colors,
      path: RoutePaths.SpecsColors,
      isActive: isColorsOptions,
    },
    {
      id: 'specsOptionsMenuBaseOptionsButton',
      content: translations.JNT_baseoptions,
      path: RoutePaths.SpecsBaseOptions,
      isActive: isBaseOptions,
    },
  ].map(handleRouting)

  const comparisonDropdownItems: IMenuItem[] = [
    {
      id: 'specsComparisonsMenuAdvantagesButton',
      content: translations.JNT_advantages,
      path: RoutePaths.SpecsAdvantages,
      isActive: isAdvantages,
      disabled: brochureVehicles.length < 2,
    },
    {
      id: 'specsComparisonsMenuDisadvantagesButton',
      content: translations.JNT_disadvantages,
      path: RoutePaths.SpecsDisadvantages,
      isActive: isDisadvantages,
      disabled: brochureVehicles.length < 2,
    },
  ].map(handleRouting)

  return (
    <StyledSpecsReportNavMenu>
      <JATONavGroup flexDirection="row" space={16}>
        <JATONavItem isActive={isShowroom}>
          <Link
            id="specsShowroomLink"
            to={{
              pathname: RoutePaths.SpecsShowroom,
              search: location.search,
            }}
          >
            {translations.JNT_Showroom}
          </Link>
        </JATONavItem>
        <JATONavMenuDropdown
          items={optionsDropdownItems}
          trigger={{
            content: translations.JNT_optionstext,
          }}
          variant="desktop"
        />
        <JATONavMenuDropdown
          items={comparisonDropdownItems}
          trigger={{
            content: translations.JNT_Comparison,
          }}
          variant="desktop"
        />
      </JATONavGroup>
    </StyledSpecsReportNavMenu>
  )
}
