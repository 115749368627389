import React, { useState } from 'react'
import { LoginHeader } from './LoginHeader'

export const LoginHeaderContainer: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>(`19`)

  const handleChangeLanguage = (language: string): void => {
    setLanguage(language)
  }

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <LoginHeader
      handleChangeLanguage={handleChangeLanguage}
      handleToggleDrawer={handleToggleDrawer}
      isDrawerOpen={isDrawerOpen}
      language={language}
      navRoutes={[]}
    />
  )
}
