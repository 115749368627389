import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsFlatMetadata = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 5px;

  .newsMetadataBlock {
    height: 24px;
    background-color: ${JATOTheme.colors.white};
    font-size: 12px;
    font-weight: ${JATOTheme.fontWeights.medium};
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;
    align-items: center;
    padding: 0px 10px 0px 0px;
  }

  .newsMetadataBlock span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .newsMetadataBlock img {
    width: 14px;
  }
`
