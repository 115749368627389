import { JATOBasicCard, JATOButton } from '@jato/ui-component-library'
import { Flag } from 'components/Flag/Flag'
import Loader from 'components/Loader'
import { InfoPopup } from 'components/Modals/InfoPopup/InfoPopup'
import MpFilterContainer from 'components/MonthlyPayments/MpFiltersContainer'
import { MpNavigation } from 'components/MonthlyPayments/MpNavigation/MpNavigation'
import SelectedFilters from 'components/MonthlyPayments/SelectedFilter'
import VehicleGrid from 'components/MonthlyPayments/VehicleGrid'
import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import {
  additionalFacetsForSliders,
  defaultMpSearchFilterCriteria,
  mpSearchFacetFields,
  mpSelectVehicleFields,
} from 'config'
import { getCountryCode, getCountryDescription } from 'helper/countryCodeMapper'
import { getDisplayValue } from 'helper/getDisplayValue'
import { getReferencePriceFormatted } from 'helper/getReferencePrice'
import { validateMetadata } from 'helper/mpMetadataHelper'
import {
  buildSearchQuery,
  specFilterNamesToFields,
} from 'helper/searchQueryHelper'
import { showErrorToast } from 'helper/toastHelper'
import { FilterNameType } from 'models/Filters/FilterNameType'
import { InfoPopupType } from 'models/InfoPopup/InfoPopup'
import { MPUserShipLog } from 'models/UserShipLog/UserShipLog'
import { MpMetadataResponse } from 'models/VehicleSelection/MpMetadata'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import { MpVehiclesResponse } from 'models/VehicleSelection/MpVehiclesResponse'
import { IMpVehiclesSearchRequest } from 'models/VehicleSelection/MpVehiclesSearchRequest'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { mpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import {
  getOrderBy,
  getPageSize,
  getPageSkip,
  getSelectedCountry,
  removeFilter,
  selectedFilters,
} from 'redux/monthlypayments/selectedFiltersSlice'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import monthlyPaymentsReportService from 'services/MonthlyPaymentsReport/MonthlyPaymentsReportService'
import userShipLogService from 'services/UserShipLog/UserShipLogService'
import { StyledMonthlyPayments } from './MonthlyPayments.styles'

export const MonthlyPayments: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const translations = useAppSelector(getTranslations)
  const userData = useAppSelector(selectUserData)
  const mpVehicleData = useAppSelector(mpVehicles)
  const selectedFiltersData = useAppSelector(selectedFilters)
  const pageSkip = useAppSelector(getPageSkip)
  const pageSize = useAppSelector(getPageSize)
  const orderBy = useAppSelector(getOrderBy)

  const selectedCountry = useAppSelector(getSelectedCountry)

  const displayedSelectedFilters = selectedFiltersData.filter(
    (f) =>
      f.filterName !== FilterNameType.Country &&
      f.filterName !== FilterNameType.MilesKilometers
  )

  const mpVehiclesSearchRequest: IMpVehiclesSearchRequest = {
    select: mpSelectVehicleFields,
    search: '*',
    filter: defaultMpSearchFilterCriteria,
    facets: mpSearchFacetFields,
    orderBy: orderBy,
    skip: pageSkip,
    top: pageSize,
    country: `${userData.user.settings?.lastSelectedMarket?.countryCode}`,
  }

  const { data: mpReportMetaData } = useQuery<MpMetadataResponse>(
    ['mpReportMetaData'],
    async () => await monthlyPaymentsReportService.getMpReportMetaData(),
    {
      onSuccess: (data) => {
        validateMetadata(data?.metadata)
      },
      onError: (error: any) => {
        const errorMessage = error
          ? `${error?.status}: ${error?.message}`
          : translations.JNT_Error
        showErrorToast(translations.JNT_Error, errorMessage)
      },
      staleTime: Infinity,
    }
  )

  const { data: mpVehiclesResponse, isFetching } = useQuery<MpVehiclesResponse>(
    [
      'mpVehiclesResponse',
      selectedFiltersData,
      pageSkip,
      pageSize,
      orderBy,
      mpReportMetaData,
    ],
    async () => {
      if (selectedFiltersData.length > 0) {
        mpVehiclesSearchRequest.filter = buildSearchQuery(
          selectedFiltersData,
          specFilterNamesToFields
        )
        for (let i = 0; i < selectedFiltersData.length; i++) {
          if (selectedFiltersData[i].filterName === FilterNameType.Uid) {
            if (mpVehiclesSearchRequest.filter.length === 0) {
              mpVehiclesSearchRequest.filter = `search.in(uniqueIdentity,'${selectedFiltersData[i].value}')`
            } else {
              mpVehiclesSearchRequest.filter = `${mpVehiclesSearchRequest.filter} and  search.in(uniqueIdentity,'${selectedFiltersData[i].value}') `
            }
          }
        }
      }

      const data = await monthlyPaymentsReportService.getMpVehicleData(
        mpVehiclesSearchRequest
      )

      const facetData = await monthlyPaymentsReportService.getMpVehicleData({
        ...mpVehiclesSearchRequest,
        top: 1,
        facets: additionalFacetsForSliders,
      })

      Object.keys(facetData.facets).forEach(
        (key) =>
          (data.facets[key] = [...data.facets[key], ...facetData.facets[key]])
      )

      // Convert values to display values based on API metadata mapping
      data.vehicles.forEach((vehicle: MpVehicle) => {
        const fuelTypeDisplayValue = getDisplayValue(
          mpReportMetaData?.metadata.fuelType?.allowedValues,
          vehicle.fuelType
        )
        const bodyTypeDisplayValue = getDisplayValue(
          mpReportMetaData?.metadata.bodyType?.allowedValues,
          vehicle.bodyType
        )

        const drivenWheelsDisplayValue = getDisplayValue(
          mpReportMetaData?.metadata.drivenWheels?.allowedValues,
          vehicle.drivenWheels
        )

        if (fuelTypeDisplayValue != undefined) {
          vehicle.fuelTypeDisplayValue = fuelTypeDisplayValue.toUpperCase()
        }
        if (bodyTypeDisplayValue != undefined) {
          vehicle.bodyTypeDisplayValue = bodyTypeDisplayValue.toUpperCase()
        }
        if (drivenWheelsDisplayValue != undefined) {
          vehicle.drivenWheelsDisplayValue =
            drivenWheelsDisplayValue.toUpperCase()
        }

        vehicle.priceDisplayValue = getReferencePriceFormatted(vehicle)
      })

      return data
    },
    {
      onError: (error: any) => {
        const errorMessage = error
          ? `${error?.status}: ${error?.message}`
          : translations.JNT_Error
        showErrorToast(translations.JNT_Error, errorMessage)
      },
    }
  )

  const { mutate: logMonthlyPaymentsClick } = useMutation(
    (log: MPUserShipLog) => userShipLogService.logViewMonthlyPaymentsClick(log)
  )

  function getMonthlyPaymentsDetail(): void {
    logMonthlyPaymentsClick({
      user: userData.user,
      vehicles: mpVehicleData,
      countryCode: userData.user.settings.lastSelectedMarket.countryCode,
    })

    history.push(RoutePaths.MonthlyPaymentsReport)
  }

  return (
    <StyledMonthlyPayments>
      <Container xl lg md sm xs style={{ padding: 0 }}>
        <Row>
          <Col md={3} lg={3} style={{ padding: '0 10px', minHeight: '400' }}>
            <MpNavigation />
            <div className="ej-panel scrollable-container">
              <JATOBasicCard>
                <MpFilterContainer
                  facetsData={mpVehiclesResponse?.facets}
                  metaData={mpReportMetaData}
                />
              </JATOBasicCard>
            </div>
          </Col>
          <Col md={9} lg={9} style={{ padding: 0 }}>
            <NavigationContainer
              left={
                <Flag
                  countryCode={getCountryCode(selectedCountry)}
                  countryDescription={getCountryDescription(selectedCountry)}
                />
              }
              right={
                <JATOButton
                  size="medium"
                  onClick={() => getMonthlyPaymentsDetail()}
                  disabled={!(mpVehicleData.length > 0)}
                  id="monthlyPaymentsViewMonthlyPaymentsButton"
                >
                  View Monthly Payments
                </JATOButton>
              }
            />
            <div className="ej-panel scrollable-container" id="ej-panel">
              <Container xl lg md sm xs style={{ padding: '0 20px' }}>
                <Row>
                  <Col>
                    <SelectedFilters
                      id="monthlyPaymentsSelectedFilters"
                      selectedFilters={displayedSelectedFilters}
                      handleRemoveFilter={(f) => dispatch(removeFilter(f))}
                    />
                  </Col>
                </Row>
              </Container>
              <Container xl lg md sm xs style={{ padding: '0 20px 3px' }}>
                <Row>
                  <Col md={12}>
                    {isFetching ? (
                      <Loader />
                    ) : (
                      mpVehiclesResponse && (
                        <div id="vehicle-grid-container">
                          <VehicleGrid
                            MpVehiclesResponse={mpVehiclesResponse}
                          />
                        </div>
                      )
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <InfoPopup
        id="monthlyPaymentsFiltersInfoPopup"
        infoPopupType={InfoPopupType.MonthlyPaymentsFilters}
      />
    </StyledMonthlyPayments>
  )
}
