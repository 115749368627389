import { JATOButtonIcon, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { StyledNewsIconButton } from './NewsIconButton.styles'

export interface INewsIconButtonProps {
  id?: string
  iconName: string
  isActive?: boolean
  onClick: () => void
}

export const NewsIconButton: React.FC<INewsIconButtonProps> = ({
  id,
  iconName,
  isActive,
  onClick,
}: INewsIconButtonProps) => (
  <StyledNewsIconButton isActive={isActive}>
    <JATOButtonIcon
      id={id}
      iconName={iconName}
      iconFill={isActive ? JATOTheme.colors.white : 'currentcolor'}
      backgroundHover={
        isActive ? JATOTheme.colors.primary : JATOTheme.colors.ultraLightGrey
      }
      onClick={isActive ? undefined : onClick}
    />
  </StyledNewsIconButton>
)
