import { hiddenMetaDataRowList, mpReportGroupingAndOrderLayout } from 'config'
import { GroupMetaData } from 'config/GroupMetaData'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'

export const getGroupedMetaDataRows = (
  vehicles: MpVehicle[]
): GroupMetaData[] => {
  const finalHiddenMetaDataRowList = hiddenMetaDataRowList.concat(
    getEmptyDataRows(vehicles)
  )

  const scenario =
    vehicles.find((v) => v.scenarios.length > 0)?.scenarios[0] ?? {}
  const sceneriosMetaDataList = Object.keys(scenario)
  const orderedGroupedList: GroupMetaData[] = []
  for (let i = 0; i < mpReportGroupingAndOrderLayout.length; i++) {
    if (
      !(
        finalHiddenMetaDataRowList.indexOf(
          mpReportGroupingAndOrderLayout[i].metadataRowName
        ) !== -1
      )
    ) {
      if (mpReportGroupingAndOrderLayout[i].isCategoryTitleRow) {
        orderedGroupedList.push(mpReportGroupingAndOrderLayout[i])
      } else {
        if (
          sceneriosMetaDataList.indexOf(
            mpReportGroupingAndOrderLayout[i].metadataRowName
          ) !== -1
        ) {
          orderedGroupedList.push(mpReportGroupingAndOrderLayout[i])
        }
      }
    }
  }

  const finalGroupingList: GroupMetaData[] = []

  for (let i = 0; i < orderedGroupedList.length; i++) {
    if (orderedGroupedList[i].isCategoryTitleRow) {
      const checkCategoryItemList = orderedGroupedList.filter(
        (orderedGroup: GroupMetaData) =>
          orderedGroup.categoryName === orderedGroupedList[i].categoryName
      )
      if (checkCategoryItemList.length > 1) {
        for (let j = 0; j < checkCategoryItemList.length; j++) {
          finalGroupingList.push(checkCategoryItemList[j])
        }
      }
    }
  }

  return finalGroupingList
}

const getEmptyDataRows = (vehicles: MpVehicle[]): string[] => {
  const scenarios = vehicles.flatMap((r: MpVehicle) => r.scenarios)

  const rowNames = mpReportGroupingAndOrderLayout
    .filter((grp: GroupMetaData) => !grp.isCategoryTitleRow)
    .map((l) => l.metadataRowName)

  return rowNames.filter((name) =>
    scenarios.every((s: any) => {
      const value = s[name]
      return value === null || value === '' || value === undefined
    })
  )
}

export const getMonthlyPaymentDataGroupByCategoryName = (
  vehicles: MpVehicle[]
): any => {
  const result = getGroupedMetaDataRows(vehicles)
  const finalResult = result.reduce(
    (acc: Map<string, GroupMetaData[]>, value: GroupMetaData) => {
      acc.set(
        value.categoryName,
        result.filter(
          (groupData: GroupMetaData) =>
            groupData.categoryName === value.categoryName
        )
      )
      return acc
    },
    new Map<string, GroupMetaData[]>()
  )
  // return finalResult
  const groupResult: any = []
  finalResult.forEach((value, key) => {
    groupResult.push({ key: key, value: value })
  })

  return groupResult
}
