import { DataDefinitionRequest } from 'models/Specifications/DataDefinitionsRequest'
import { DataDefinitionResponse } from 'models/Specifications/DataDefinitionsResponse'
import { useQuery, UseQueryResult } from 'react-query'
import specificationsService from 'services/Specifications/SpecificationsService'

export const useRequestDataDefinitions = (schemaIds: number[]): UseQueryResult<
  DataDefinitionResponse
> => {
  const dataDefinitionRequest: DataDefinitionRequest = {
    schemaIds: schemaIds,
  }

  return useQuery<DataDefinitionResponse>(
    ['fetchDataDefinitions', dataDefinitionRequest],
    async (): Promise<DataDefinitionResponse> =>
      await specificationsService.requestDataDefinitions(dataDefinitionRequest)
  )
}
