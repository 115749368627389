import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsAdvancedSearchLink = styled.div`
  margin: 10px;

  .advancedSearchLink {
    text-transform: none;
    color: ${JATOTheme.colors.darkGrey};
    letter-spacing: 1px;
  }
`
