export class ValueWithUnit {
  private static readonly separator: string = '|'

  value: string
  unit: string

  constructor(value: string, unit: string) {
    this.value = value
    this.unit = unit
  }

  static parse(strValue: string): ValueWithUnit {
    const parsed = strValue.split(ValueWithUnit.separator)

    return parsed.length == 2
      ? new ValueWithUnit(parsed[0], parsed[1])
      : new ValueWithUnit('', '')
  }

  toString(): string {
    return `${this.value}${ValueWithUnit.separator}${this.unit}`
  }
}
