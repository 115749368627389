import styled from 'styled-components'

export const StyledVolumesQueryTreePanel = styled.div`
  height: calc(100vh - 460px);
  overflow: auto;
  padding: 10px 20px;

  .itemLabel {
    min-height: 32px;
    display: flex;
    align-items: center;
  }

  .Mui-selected {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
`
