import { AxiosResponse } from 'axios'
import {
  BasketInfo,
  BasketInfoRequest,
} from 'models/Specifications/Basket/BasketInfo'
import { SaveBasketResponse } from 'models/Specifications/Basket/SaveBasketResponse'
import { UpdateBasketRequest } from 'models/Specifications/Basket/UpdateBasketRequest'
import { DataDefinitionRequest } from 'models/Specifications/DataDefinitionsRequest'
import { DataDefinitionResponse } from 'models/Specifications/DataDefinitionsResponse'
import { GetLicenceInfoRequest } from 'models/Specifications/GetLicenceInfoRequest'
import { GetLicenceInfoResponse } from 'models/Specifications/GetLicenceInfoResponse'
import { GetVehiclesRequest } from 'models/Specifications/GetVehiclesRequest'
import { GetVehiclesResponse } from 'models/Specifications/GetVehiclesResponse'
import {
  ISpecsUserSettings,
  SpecsUserSettingsRequest,
} from 'models/Specifications/SpecsUserSettings'
import { specsWithAuthApi } from 'services/apiWithAuth'

class SpecificationsService {
  public getLicenceInfo(
    getLicenceInfoRequest: GetLicenceInfoRequest
  ): Promise<GetLicenceInfoResponse> {
    return specsWithAuthApi
      .post<GetLicenceInfoRequest, AxiosResponse<GetLicenceInfoResponse>>(
        `/licencemanager`,
        getLicenceInfoRequest
      )
      .then((resp) => resp.data)
  }

  public getVehicles(
    getVehiclesRequest: GetVehiclesRequest
  ): Promise<GetVehiclesResponse> {
    return specsWithAuthApi
      .post<GetVehiclesRequest, AxiosResponse<GetVehiclesResponse>>(
        `/vehicles`,
        getVehiclesRequest
      )
      .then((resp) => resp.data)
  }

  public updateUserSettings(
    userSettings: SpecsUserSettingsRequest
  ): Promise<ISpecsUserSettings> {
    return specsWithAuthApi
      .post<SpecsUserSettingsRequest, AxiosResponse<ISpecsUserSettings>>(
        `/ManageUserSettings/update`,
        userSettings
      )
      .then((resp) => resp.data)
  }

  public getBaskets(): Promise<BasketInfo[]> {
    return specsWithAuthApi
      .post<unknown, AxiosResponse<BasketInfo[]>>(`/ManageBasketData/load`)
      .then((resp) => resp.data)
  }

  public removeBasket(basket: BasketInfoRequest): Promise<number> {
    return specsWithAuthApi
      .post<BasketInfoRequest, AxiosResponse<number>>(
        `/ManageBasketData/removebyid`,
        basket
      )
      .then((resp) => resp.data)
  }

  public saveBasket(basket: BasketInfoRequest): Promise<SaveBasketResponse> {
    return specsWithAuthApi
      .post<BasketInfoRequest, AxiosResponse<SaveBasketResponse>>(
        `/ManageBasketData/savebrochurebasket`,
        basket
      )
      .then((resp) => resp.data)
  }

  public updateBasket(
    updateBasketRequest: UpdateBasketRequest
  ): Promise<SaveBasketResponse> {
    return specsWithAuthApi
      .post<UpdateBasketRequest, AxiosResponse<SaveBasketResponse>>(
        `/ManageBasketData/updatebyid`,
        updateBasketRequest
      )
      .then((resp) => resp.data)
  }

  public requestDataDefinitions(
    dataDefinitionRequest: DataDefinitionRequest
  ): Promise<DataDefinitionResponse> {
    return specsWithAuthApi
      .post<DataDefinitionRequest, AxiosResponse<DataDefinitionResponse>>(
        `/dataDefinitions`,
        dataDefinitionRequest
      )
      .then((resp) => resp.data)
  }
}

const specificationsService = new SpecificationsService()
export default specificationsService
