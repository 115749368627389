import {
  JATOTag,
  JATOText,
  JATOTextInput,
  JATOTheme,
} from '@jato/ui-component-library'
import { DropdownWithGtm } from 'components/Gtm/DropdownWithGtm'
import { MultiSelectWithGtm } from 'components/Gtm/MultiSelectWithGtm'
import { SliderWithGtm } from 'components/Gtm/SliderWithGtm'
import { MilesKilometersFilter } from 'components/MonthlyPayments/Filters/MilesKilometersFilter'
import { currencyCodeMapper } from 'helper/currencyCodeMapper'
import { formatCurrency } from 'helper/formatNumberValues'
import { IFilterCategory } from 'models/Filters/FilterGroups'
import { FilterNameType } from 'models/Filters/FilterNameType'
import { LoginResponse } from 'models/Login/LoginResponse'
import {
  IMpLicensedCountry,
  IUserSettings,
} from 'models/UserSettings/UserSettings'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { resetMpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import {
  addFilter,
  removeFilter,
  resetFilters,
  saveOptions,
  selectedFilters,
} from 'redux/monthlypayments/selectedFiltersSlice'
import {
  resetLastSelectedMarket,
  selectUserData,
} from 'redux/monthlypayments/userDataSlice'
import userSettingsManagerService from 'services/UserSettingsManager/UserSettingsManagerService'
import { v4 as uuid } from 'uuid'
import {
  IFilterOption,
  ISelectedFilterOption,
} from '../../../models/Filters/FilterOption'
import { FilterType, FilterValueType } from '../../../models/Filters/FilterType'
import { StyledFilters } from './MpFilters.styles'
import { MpInsuranceFilter } from './MpInsuranceFilter'
import { SliderValue } from './SliderFilter/SliderFilter'

export const MpFilters: React.FC<IFilterCategory> = (params) => {
  const dispatch = useAppDispatch()
  const userData: LoginResponse = useAppSelector(selectUserData)
  const filtersData: ISelectedFilterOption[] = useAppSelector(selectedFilters)

  const handleSelectedValueFromSearchDropdown = (value: string): void => {
    const item: IFilterOption | undefined = params.options?.find(
      (item) => item.value === value
    )
    item && handleAddNewFilter(item)
  }

  const handleAddNewFilter = (filter: IFilterOption): void => {
    if (filter.filterName === FilterNameType.Country) {
      const lastSelectedMarket: IMpLicensedCountry = {
        countryCode: filter.value,
        countryName: filter.displayValue,
      }
      const settings: IUserSettings = {
        id: userData.user.settings.id,
        userId: userData.user.settings.userId,
        userGroupId: userData.user.settings.userGroupId,
        lastSelectedMarket: lastSelectedMarket,
        lastAccessDate: userData.user.settings.lastAccessDate,
        updateDate: userData.user.settings.updateDate,
      }
      dispatch(resetLastSelectedMarket(lastSelectedMarket))
      dispatch(resetFilters(lastSelectedMarket))

      userSettingsManagerService.updateUserSettingsData(settings)
      dispatch(resetMpVehicles(false))
    }

    dispatch(saveOptions(params.options))

    dispatch(
      addFilter({
        ...filter,
        singularFilterCategory: params.singularCategory ?? true,
      })
    )
  }

  const handleRemoveFilter = (filter: IFilterOption): void => {
    const selectedFilter = {
      ...filter,
      singularFilterCategory: params.singularCategory ?? true,
    }

    dispatch(removeFilter(selectedFilter))
  }

  const handleUiDChangeEvent = (event: any): void => {
    if (event.currentTarget.value.length > 0) {
      const selectedUidFilter: IFilterOption = {
        key: uuid(),
        displayValue: event.currentTarget.value,
        value: event.currentTarget.value,
        filterName: FilterNameType.Uid,
      }
      handleAddNewFilter(selectedUidFilter)
    }
  }

  const onBlurUid = (event: React.FocusEvent<HTMLInputElement>): void => {
    handleUiDChangeEvent(event)
  }

  const onKeyPressUid = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === 'Enter') {
      handleUiDChangeEvent(event)
    }
  }

  const handleFilterRemoveClick = (filter: ISelectedFilterOption): void => {
    dispatch(removeFilter(filter))
  }

  const getSliderOptions = (): SliderValue | undefined => {
    const values = params.selectedFilter?.value
      .split('|')
      .map((value) => Number(value))

    const min =
      params.options.length > 0 && params.options.length < 500
        ? Number(params.options[0].value)
        : 0
    const max =
      params.options.length > 0 ? Number(params.options.at(-1)?.value) : 0

    return (values && { min: values[0], max: values[1] }) ?? { min, max }
  }

  const onChangeSlider = (value: number[]): void => {
    const [min, max] = value
    const countryCode = userData.user.settings.lastSelectedMarket.countryCode
    const formattedMin = formatCurrency(min, countryCode, 2)
    const formattedMax = formatCurrency(max, countryCode, 2)

    const selectedRangeFilter = {
      key: uuid(),
      displayValue: `${formattedMin} | ${formattedMax}`,
      value: `${min}|${max}`,
      filterName: params.name,
      max: max,
      min: min,
      type: FilterValueType.Range,
    }

    handleAddNewFilter(selectedRangeFilter)
  }

  const tooltipFormatter = (value: SliderValue): string => {
    const countryCode = userData.user.settings.lastSelectedMarket.countryCode
    const min = formatCurrency(value.min, countryCode, 2)
    const max = formatCurrency(value.max, countryCode, 2)
    return `${min} - ${max}`
  }

  const onChangeMultiSelect = (selectedOptions: IFilterOption[]): void => {
    const selectedFilters = filtersData

    const appliedFilters = selectedFilters.filter(
      (item) => item.filterName === params.name
    )

    const itemToAdd = selectedOptions?.find(
      (o) => !appliedFilters.some((f) => f.value == o.value)
    )

    itemToAdd && handleAddNewFilter(itemToAdd)

    const itemToRemove = appliedFilters.find(
      (f) => !selectedOptions.some((o) => o.value === f.value)
    )
    itemToRemove && handleRemoveFilter(itemToRemove)
  }

  const isFilterDisabled = (): boolean =>
    //disable Model filter if no Makes are selected
    params.name === FilterNameType.Model &&
    !filtersData.some((f) => f.filterName === FilterNameType.Make)

  //#endregion

  // Filter Types to load for category
  switch (params.filterType) {
    case FilterType.Market:
      return (
        <DropdownWithGtm
          label={params.name}
          filterName={params.name}
          id={params.id}
          options={params.options}
          value={params.selectedFilter?.value}
          onChange={handleSelectedValueFromSearchDropdown}
          showSelectedValuesAsTags={false}
          onRemoveFilter={handleFilterRemoveClick}
        />
      )
    case FilterType.DropdownWithSearch:
      return (
        <DropdownWithGtm
          label={params.name}
          filterName={params.name}
          id={params.id}
          options={params.options}
          value={params.selectedFilter?.value}
          onChange={handleSelectedValueFromSearchDropdown}
          showSelectedValuesAsTags={true}
          onRemoveFilter={handleFilterRemoveClick}
          showCount={true}
        />
      )
    case FilterType.MultiSelect:
      return (
        <MultiSelectWithGtm
          id={params.id}
          label={params.label}
          filterName={params.name}
          value={params.value}
          options={params.options}
          showCount={true}
          isDisabled={isFilterDisabled()}
          handleOnChange={onChangeMultiSelect}
        />
      )

    case FilterType.Insurance:
      return (
        <MpInsuranceFilter
          id={params.id}
          filterName={params.name}
          value={params.value}
          handleAddFilter={(filter) => dispatch(addFilter(filter))}
          handleRemoveFilter={(filter) => dispatch(removeFilter(filter))}
        />
      )
    case FilterType.MilesKilometersFilter:
      return (
        <>
          <JATOText as="p" key={uuid()}>
            {params.name}
          </JATOText>
          <div style={{ marginTop: JATOTheme.space[2] }}>
            <MilesKilometersFilter
              id={params.id}
              options={params.options}
              singularCategory={params.singularCategory}
              title="Select from"
              handleAddFilter={(filter) => dispatch(addFilter(filter))}
              handleRemoveFilter={(filter) => dispatch(removeFilter(filter))}
              childFilters={params.childFilters}
              isRadio={true}
            />
          </div>
        </>
      )

    case FilterType.Slider:
      return (
        <>
          <SliderWithGtm
            id={params.id}
            key={params.name}
            label={`${params.name} (${currencyCodeMapper(
              userData.user.settings.lastSelectedMarket.countryCode
            )})`}
            filterName={params.name}
            value={getSliderOptions()}
            tooltipFormatter={tooltipFormatter}
            onChange={(value) => onChangeSlider(value)}
          />
          {params.selectedFilter && (
            <JATOTag
              key={params.selectedFilter.key}
              isDismissible
              variant="secondary"
              value={params.selectedFilter.value}
              onClick={() =>
                params.selectedFilter &&
                handleFilterRemoveClick(params.selectedFilter)
              }
            >
              {params.selectedFilter.displayValue}
            </JATOTag>
          )}
        </>
      )
    case FilterType.SearchBoxInput:
      return (
        <StyledFilters>
          <div>
            <label>{params.name}</label>
          </div>
          <JATOTextInput
            onBlur={(event: any) => onBlurUid(event)}
            onKeyPress={(event: any) => onKeyPressUid(event)}
            type="text"
            width="100%"
            defaultValue={params.selectedFilter?.value}
            placeholder="Unique Identity e.g. 8273576,8025979"
            id={params.id + '_UID'}
            title="Unique Identity e.g. 8273576,8025979"
          />
        </StyledFilters>
      )
    default:
      return <></>
  }
}
