import { queryClient } from 'app'
import { firstOrDefault } from 'helper/arrayHelper'
import {
  articlesMaxResults,
  defaultToDate,
  getDateRangeFilterValue,
  hiddenVehicleTypes,
  NewsFilterName,
  toNewsArticle,
} from 'helper/newsHelper'
import { showErrorToast } from 'helper/toastHelper'
import { UserState } from 'models/Login/NewsLogin/UserState'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import { NewsArticleResponse } from 'models/News/Article/NewsArticleResponse'
import {
  ArticleDetailRequest,
  NewsArticleRequest,
  NewsArticlesResponse,
  NewsCountry,
  NewsSavedSearchRequest,
  SaveNewsSearchResult,
  SearchIndexRequest,
} from 'models/News/Homepage/NewsArticleRequest'
import { INewsArticle } from 'models/News/INewsArticle'
import {
  emptyNewsArticleResult,
  INewsArticleResult,
} from 'models/News/INewsArticleResult'
import {
  MyPageLoadArticleRequest,
  MyPageNewsGetAllRequest,
} from 'models/News/MyPage/MyPageNewsRequest'
import { MyPageNewsArticleList } from 'models/News/MyPage/MyPageNewsResponse'
import { NewsArticleBookmarkRequest } from 'models/News/NewsArticleBookmarkRequest'
import { NewsArticleShareRequest } from 'models/News/NewsArticleShareRequest'
import {
  categoryToSubjectsMapping,
  NewsCategoryType,
  selectableCategories,
  workflowSubjects,
} from 'models/News/NewsCategoryType'
import { NewsLanguagesByCountryCode } from 'models/News/NewsLanguageByCountryCode'
import { NewsMakeModelsResponse } from 'models/News/NewsMakeModelsResponse'
import { getQuLevelForNewsPage, NewsPage } from 'models/News/NewsPageType'
import { NewsPageViewType } from 'models/News/NewsPageViewType'
import { SearchNewsRequest } from 'models/News/Search/SearchNewsRequest'
import { Source } from 'models/News/Source'
import { UpdateCountryCodeRequest } from 'models/News/UserSettings/UpdateCountryCodeRequest'
import { UpdateHideMonthlyPaymentsPopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentsPopupRequest'
import { UpdateHideMonthlyPaymentsTrialWelcomePopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentsTrialWelcomePopupRequest'
import { UpdateHideMonthlyPaymentsWelcomePopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentsWelcomePopupRequest'
import { UpdateHideMonthlyPaymentTrialEndPopupRequest } from 'models/News/UserSettings/UpdateHideMonthlyPaymentTrialEndPopupRequest'
import { UpdateHideWelcomeInfoRequest } from 'models/News/UserSettings/UpdateHideWelcomeInfoRequest'
import { UpdatePageViewRequest } from 'models/News/UserSettings/UpdatePageViewRequest'
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getNewsFilters,
  getNewsSearchState,
  getNewsUserState,
  setNewsCountries,
  setNewsUserState,
} from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import newsService from 'services/News/NewsService'

export const HomepageArticlesQueryKey = 'getHomepageArticles'
const TopStoryArticleQueryKey = 'topStoryArticle'
export const ArticlesByCategoryQueryKey = 'getArticlesByCategory'
export const SearchArticlesQueryKey = 'getSearchArticles'
const ArticleByIdQueryKey = 'getNewsArticleByArticleId'
const SharedArticlesQueryKey = 'getSharedArticles'
const BookMarkArticlesQueryKey = 'getBookMarkArticles'
const MakeModelsQueryKey = 'getMakesModelsByCountryCode'
const LanguageByCountryCodeQueryKey = 'getlanguagesbycountrycode'
const SavedSearchQueryKey = 'getSavedSearches'
const NewsAlertQueryKey = 'getNewsAlerts'
const VehicleTypesQueryKey = 'getVehicleTypes'
const MyPageNewsQueryKey = 'myPageNews'

export const useGetInfiniteHomepageArticles = (
  numOfArticles: number,
  enabled: boolean
): UseInfiniteQueryResult<INewsArticleResult, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const newsFilters = useAppSelector(getNewsFilters)

  const fetchHomepageArticles = async (
    pageParam: number
  ): Promise<INewsArticleResult> => {
    const dateFilterValues = getDateRangeFilterValue(
      newsFilters.selectedFilters[NewsFilterName.ReleaseDate]
    )

    const getSubjectsFilter = (): string[] => {
      let selectedCategories =
        newsFilters.selectedFilters[NewsFilterName.Category] ?? []
      // if no categories selected, use all except New Vehicles
      if (selectedCategories.length === 0) {
        selectedCategories = selectableCategories.filter(
          (c) => c !== NewsCategoryType.NewVehicles
        )
      }
      return selectedCategories.flatMap(
        (category) => categoryToSubjectsMapping[category]
      )
    }

    const getArticlesRequest: SearchNewsRequest = {
      languageId: commonUserData.languageId,
      countryCodes: newsFilters.selectedFilters[NewsFilterName.Country] ?? [],
      makes: newsFilters.selectedFilters[NewsFilterName.Make],
      models: newsFilters.selectedFilters[NewsFilterName.Model],
      group: firstOrDefault(
        newsFilters.selectedFilters[NewsFilterName.Group],
        undefined
      ),
      subjects: getSubjectsFilter(),
      fromDate: dateFilterValues[0],
      toDate: dateFilterValues[1],
      numOfArticles: numOfArticles,
      currentPage: pageParam,
      orderBy: ['releaseDate desc'],
    }

    const response = await newsService.searchArticles(getArticlesRequest)
    return {
      articles: response.articles.map(toNewsArticle),
      totalNumber: response.totalNumber,
    }
  }

  return useInfiniteQuery(
    [HomepageArticlesQueryKey, commonUserData, newsFilters, numOfArticles],
    ({ pageParam = 1 }) => fetchHomepageArticles(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil(lastPage.totalNumber / numOfArticles)

        return allPages.length < totalPages ? allPages.length + 1 : undefined // No more pages
      },
      enabled: enabled,
    }
  )
}

export const useGetTopStoryArticle = (
  applyCategoryFilters: boolean
): UseQueryResult<INewsArticle | undefined, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const newsFilters = useAppSelector(getNewsFilters)
  const dateFilterValues = getDateRangeFilterValue(
    newsFilters.selectedFilters[NewsFilterName.ReleaseDate]
  )
  const selectedCategories =
    newsFilters.selectedFilters[NewsFilterName.Category] ?? []
  const noValidCategory =
    applyCategoryFilters &&
    selectedCategories.length > 0 &&
    !selectedCategories.some((c) => c === NewsCategoryType.EditorsChoice)

  const getArticlesRequest: SearchNewsRequest = {
    languageId: commonUserData.languageId,
    countryCodes: newsFilters.selectedFilters[NewsFilterName.Country] ?? [],
    makes: newsFilters.selectedFilters[NewsFilterName.Make],
    models: newsFilters.selectedFilters[NewsFilterName.Model],
    group: firstOrDefault(
      newsFilters.selectedFilters[NewsFilterName.Group],
      undefined
    ),
    isTopStory: true,
    fromDate: dateFilterValues[0],
    toDate: dateFilterValues[1],
    numOfArticles: 1,
    orderBy: ['releaseDate desc'],
  }

  return useQuery<INewsArticle | undefined>(
    [TopStoryArticleQueryKey, commonUserData, newsFilters],
    async () => {
      if (noValidCategory) {
        return undefined
      }
      const response = await newsService.searchArticles(getArticlesRequest)
      const result =
        response.articles.length > 0
          ? toNewsArticle(response.articles[0])
          : undefined

      return result
    }
  )
}

export const useGetArticlesByCategory = (
  category: NewsPage,
  numOfArticles: number,
  currentPage: number,
  applyCategoryFilters: boolean,
  orderBy?: string[]
): UseQueryResult<INewsArticleResult, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const newsFilters = useAppSelector(getNewsFilters)
  const countryFilter =
    newsFilters.selectedFilters[NewsFilterName.Country] ?? []
  const isWorkflowOrCornerStoneArticles =
    category == NewsPage.WorkflowReports ||
    category === NewsPage.CornerstoneReports
  const dateFilterValues = getDateRangeFilterValue(
    newsFilters.selectedFilters[NewsFilterName.ReleaseDate]
  )
  const selectedCategories =
    newsFilters.selectedFilters[NewsFilterName.Category] ?? []
  const noValidCategory =
    applyCategoryFilters &&
    selectedCategories.length > 0 &&
    !selectedCategories.some((c) => c === category)

  const getArticlesRequest: SearchNewsRequest = {
    languageId: commonUserData.languageId,
    countryCodes: isWorkflowOrCornerStoneArticles ? [] : countryFilter,
    makes: newsFilters.selectedFilters[NewsFilterName.Make],
    models: newsFilters.selectedFilters[NewsFilterName.Model],
    group: firstOrDefault(
      newsFilters.selectedFilters[NewsFilterName.Group],
      undefined
    ),
    isTopStory: category === NewsCategoryType.EditorsChoice ? true : undefined,
    fromDate: dateFilterValues[0],
    toDate: dateFilterValues[1],
    subjects: isWorkflowOrCornerStoneArticles
      ? workflowSubjects
      : categoryToSubjectsMapping[category],
    numOfArticles: numOfArticles,
    currentPage: currentPage,
    quLevel: getQuLevelForNewsPage(category),
    isWorkflowOrCornerStoneArticles: isWorkflowOrCornerStoneArticles,
    orderBy: orderBy ?? ['releaseDate desc'],
  }

  return useQuery<INewsArticleResult>(
    [
      ArticlesByCategoryQueryKey,
      commonUserData,
      newsFilters,
      category,
      numOfArticles,
      currentPage,
      orderBy,
    ],
    async () => {
      if (noValidCategory) {
        return emptyNewsArticleResult
      }
      const response = await newsService.searchArticles(getArticlesRequest)
      const result: INewsArticleResult = {
        articles: response.articles.map(toNewsArticle),
        totalNumber: response.totalNumber,
      }
      return result
    }
  )
}

export const useSearchArticles = (
  numOfArticles: number
): UseQueryResult<INewsArticleResult, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const { searchFields, isExactMatch, orderBy, currentPage } =
    useAppSelector(getNewsSearchState)

  const [startDate, endDate] = JSON.parse(
    firstOrDefault(searchFields[NewsFilterName.ReleaseDate], '[]')
  ) as Date[]

  const getSubjectsList = (): string[] => {
    if (searchFields[NewsFilterName.Subjects]?.length > 0) {
      return searchFields[NewsFilterName.Subjects]
    }

    const selectedCategories = searchFields[NewsFilterName.Category] ?? []

    return selectedCategories?.flatMap(
      (category) => categoryToSubjectsMapping[category]
    )
  }

  const getArticlesRequest: SearchNewsRequest = {
    languageId: commonUserData.languageId,
    countryCodes: searchFields[NewsFilterName.Country] ?? [],
    subjects: getSubjectsList(),
    makes: searchFields[NewsFilterName.Make],
    models: searchFields[NewsFilterName.Model],
    group: firstOrDefault(searchFields[NewsFilterName.Group], undefined),
    searchText: searchFields[NewsFilterName.SearchText]?.join(' '),
    isExactMatch: isExactMatch,
    fromDate: startDate,
    toDate: endDate ?? defaultToDate(),
    numOfArticles: numOfArticles,
    currentPage: currentPage,
    orderBy: orderBy,
  }

  return useQuery<INewsArticleResult>(
    [
      SearchArticlesQueryKey,
      commonUserData,
      searchFields,
      isExactMatch,
      numOfArticles,
      currentPage,
      orderBy,
    ],
    async () => {
      const response = await newsService.searchArticles(getArticlesRequest)
      const result: INewsArticleResult = {
        articles: response.articles.map(toNewsArticle),
        totalNumber: response.totalNumber,
      }
      return result
    }
  )
}

export const useSearchById = (
  id: string
): UseQueryResult<SearchIndexRequest, unknown> =>
  useQuery<SearchIndexRequest>([ArticleByIdQueryKey, id], () =>
    newsService.getSearchById(id)
  )

export const useUpdateCountryCode = (): UseMutationResult<
  boolean,
  unknown,
  string,
  unknown
> => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)

  const mutation = useMutation({
    mutationFn: (countryCode: string) => {
      const request: UpdateCountryCodeRequest = {
        countryCode: countryCode,
      }
      return newsService.updateCountryCode(request)
    },
    onSuccess: (isSuccess: boolean, countryCode: string) => {
      if (isSuccess) {
        const userStateUpdate: UserState = {
          ...newsUserState,
          userSpecificSettings: {
            ...newsUserState.userSpecificSettings,
            countryCode: countryCode,
          },
        }

        dispatch(setNewsUserState(userStateUpdate))
      }
    },
  })

  return mutation
}

export const useUpdatePageView = (): UseMutationResult<
  boolean,
  unknown,
  NewsPageViewType,
  unknown
> => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)

  const mutation = useMutation({
    mutationFn: (pageViewType: NewsPageViewType) => {
      const request: UpdatePageViewRequest = {
        pageViewType: pageViewType,
      }
      return newsService.updatePageView(request)
    },
    onSuccess: (isSuccess: boolean, pageViewType: NewsPageViewType) => {
      if (isSuccess) {
        const userStateUpdate: UserState = {
          ...newsUserState,
          userSpecificSettings: {
            ...newsUserState.userSpecificSettings,
            pageViewType: pageViewType,
          },
        }

        dispatch(setNewsUserState(userStateUpdate))
      }
    },
  })

  return mutation
}

export const useUpdateHideWelcomeInfo = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)

  const mutation = useMutation({
    mutationFn: (hideWelcomeInfo: boolean) => {
      const request: UpdateHideWelcomeInfoRequest = {
        hideWelcomeInfo: hideWelcomeInfo,
      }
      return newsService.updateHideWelcomeInfo(request)
    },
    onSuccess: (isSuccess: boolean, hideWelcomeInfo: boolean) => {
      if (isSuccess) {
        const userStateUpdate: UserState = {
          ...newsUserState,
          userSpecificSettings: {
            ...newsUserState.userSpecificSettings,
            hideWelcomeInfo: hideWelcomeInfo,
          },
        }

        dispatch(setNewsUserState(userStateUpdate))
      }
    },
  })

  return mutation
}

export const useGetNewsArticleByArticleId = (
  article: ArticleDetailRequest,
  isVolumesNewsArticle?: boolean
): UseQueryResult<NewsArticleResponse, unknown> => {
  const getArticleRequest: NewsArticleRequest = {
    article: article,
    isVolumesNewsArticle: isVolumesNewsArticle,
  }

  return useQuery<NewsArticleResponse>(
    [ArticleByIdQueryKey, getArticleRequest],
    () => newsService.getArticleDetailByArticleId(getArticleRequest)
  )
}

export const useSharedArticles = (
  isShareByCustomerUserGroup: boolean,
  numberofArticles: number,
  currentPage: number,
  orderBy: string[]
): UseQueryResult<NewsArticlesResponse, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)

  const getArticlesRequest: NewsArticleRequest = {
    take: articlesMaxResults,
    numberofArticles: numberofArticles,
    currentPage: currentPage,
    isShareByCustomerUserGroup: isShareByCustomerUserGroup,
    languageId: commonUserData.languageId,
    orderBy: orderBy,
  }

  return useQuery<NewsArticlesResponse>(
    [
      SharedArticlesQueryKey,
      commonUserData,
      numberofArticles,
      currentPage,
      isShareByCustomerUserGroup,
      orderBy,
    ],
    async () => {
      const response = await newsService.getSharedArticles(getArticlesRequest)
      return response
    }
  )
}

export const useSavedSearch = (
  isSharedByOrganization: boolean
): UseQueryResult<SearchIndexRequest[], unknown> => {
  const getNewsSavedSearchRequest: NewsSavedSearchRequest = {
    isSharedByOrganization: isSharedByOrganization,
  }

  return useQuery<SearchIndexRequest[]>(
    [SavedSearchQueryKey, getNewsSavedSearchRequest],
    async () => {
      const response = await newsService.getUserSavedSearches(
        getNewsSavedSearchRequest
      )

      return response
    }
  )
}

export const useNewsAlert = (): UseQueryResult<SearchIndexRequest[], unknown> =>
  useQuery<SearchIndexRequest[]>(
    [NewsAlertQueryKey],
    async () => {
      const response = await newsService.getUsersNewsAlerts()

      return response
    },
    { staleTime: Infinity }
  )

export const useBookMarkArticles = (
  numberofArticles: number,
  currentPage: number,
  orderBy: string[]
): UseQueryResult<NewsArticlesResponse, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)

  const getArticlesRequest: NewsArticleRequest = {
    take: articlesMaxResults,
    numberofArticles: numberofArticles,
    currentPage: currentPage,
    languageId: commonUserData.languageId,
    orderBy: orderBy,
  }

  return useQuery<NewsArticlesResponse>(
    [
      BookMarkArticlesQueryKey,
      commonUserData,
      numberofArticles,
      currentPage,
      orderBy,
    ],
    async () => {
      const response = await newsService.getBookMarkArticles(getArticlesRequest)
      return response
    }
  )
}

export const useUpdateSharedStatus = (): UseMutationResult<
  boolean,
  unknown,
  NewsArticle,
  unknown
> => {
  const translations = useAppSelector(getTranslations)

  const mutation = useMutation({
    mutationFn: (newsArticle: NewsArticle) => {
      const isShared = !newsArticle.isShared

      const request: NewsArticleShareRequest = {
        article: { ...newsArticle, isShared },
      }

      return isShared
        ? newsService.shareNewsArticle(request)
        : newsService.unshareNewsArticle(request)
    },
    onSuccess: (isSuccess: boolean) => {
      if (isSuccess) {
        queryClient.invalidateQueries(ArticleByIdQueryKey)
        queryClient.invalidateQueries(ArticlesByCategoryQueryKey)
        queryClient.invalidateQueries(SearchArticlesQueryKey)
        queryClient.invalidateQueries(HomepageArticlesQueryKey)
        queryClient.invalidateQueries(TopStoryArticleQueryKey)
        queryClient.invalidateQueries(SharedArticlesQueryKey)
        queryClient.invalidateQueries(BookMarkArticlesQueryKey)
      } else {
        showErrorToast(
          translations.JNT_Error,
          translations.JNT_Share_Status_Error
        )
      }
    },
  })

  return mutation
}

export const useRemoveSearchAndAlert = (): UseMutationResult<
  boolean,
  unknown,
  SearchIndexRequest,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: (searchRequest: SearchIndexRequest) =>
      newsService.removeSearchAndAlert(searchRequest),
    onSuccess: (isSuccess: boolean) => {
      if (isSuccess) {
        queryClient.invalidateQueries(SavedSearchQueryKey)
        queryClient.invalidateQueries(NewsAlertQueryKey)
      }
    },
  })

  return mutation
}

export const useUpdateSearchAndAlert = (): UseMutationResult<
  SaveNewsSearchResult,
  unknown,
  SearchIndexRequest,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: (searchRequest: SearchIndexRequest) =>
      newsService.updateSearchAndAlert(searchRequest),
    onSuccess: (isSuccess: SaveNewsSearchResult) => {
      if (isSuccess) {
        queryClient.invalidateQueries(SavedSearchQueryKey)
        queryClient.invalidateQueries(NewsAlertQueryKey)
      }
    },
  })

  return mutation
}

export const useSaveSearchAndAlert = (): UseMutationResult<
  SaveNewsSearchResult,
  unknown,
  SearchIndexRequest,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: (searchRequest: SearchIndexRequest) =>
      newsService.saveSearchAndAlert(searchRequest),
    onSuccess: (result: SaveNewsSearchResult) => {
      if (result.isSuccess) {
        queryClient.invalidateQueries(SavedSearchQueryKey)
        queryClient.invalidateQueries(NewsAlertQueryKey)
      }
    },
  })

  return mutation
}

export const useUpdateBookmarkStatus = (): UseMutationResult<
  boolean,
  unknown,
  NewsArticle,
  unknown
> => {
  const translations = useAppSelector(getTranslations)

  const mutation = useMutation({
    mutationFn: (newsArticle: NewsArticle) => {
      const isBookMarked = !newsArticle.isBookMarked

      const request: NewsArticleBookmarkRequest = {
        bookMarkDescription: newsArticle.headline,
        bookMarkEntityId: newsArticle.id,
        languageId: newsArticle.languageId,
      }
      return isBookMarked
        ? newsService.addNewsBookmark(request)
        : newsService.removeNewsBookmark(request)
    },
    onSuccess: (isSuccess: boolean) => {
      if (isSuccess) {
        queryClient.invalidateQueries(ArticleByIdQueryKey)
        queryClient.invalidateQueries(ArticlesByCategoryQueryKey)
        queryClient.invalidateQueries(SearchArticlesQueryKey)
        queryClient.invalidateQueries(HomepageArticlesQueryKey)
        queryClient.invalidateQueries(TopStoryArticleQueryKey)
        queryClient.invalidateQueries(SharedArticlesQueryKey)
        queryClient.invalidateQueries(BookMarkArticlesQueryKey)
      } else {
        showErrorToast(
          translations.JNT_Error,
          translations.JNT_Favourites_Error
        )
      }
    },
  })

  return mutation
}

export const useGetMakesModelsByCountryCode = (
  countryCodes: string[]
): UseQueryResult<NewsMakeModelsResponse, unknown> =>
  useQuery<NewsMakeModelsResponse>(
    [MakeModelsQueryKey, countryCodes],
    async () => {
      const response = await newsService.getMakesModelsByCountryCode({
        countryCodes: countryCodes,
      })
      return response
    },
    { staleTime: Infinity }
  )

export const useGetLanguagesByCountryCode = (): UseQueryResult<
  NewsLanguagesByCountryCode,
  unknown
> =>
  useQuery<NewsLanguagesByCountryCode>(
    [LanguageByCountryCodeQueryKey],
    async () => {
      const response = await newsService.getLanguagesByCountryCode()
      return response
    },
    { staleTime: Infinity }
  )

export const useGetVehicleTypes = (): UseQueryResult<Source[], unknown> =>
  useQuery<Source[]>(
    [VehicleTypesQueryKey],
    async () => {
      const response = await newsService.getVehicleTypes()
      return response.result.filter((s) => !hiddenVehicleTypes.includes(s.key))
    },
    { staleTime: Infinity }
  )

export const clearArticlesByCategoryCache = (): Promise<void> =>
  queryClient.invalidateQueries(ArticlesByCategoryQueryKey)

export const clearSearchArticlesCache = (): Promise<void> =>
  queryClient.invalidateQueries(SearchArticlesQueryKey)

export const useUpdateHideMonthlyPaymentsPopup = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)

  const mutation = useMutation({
    mutationFn: (hideMonthlyPaymentsPopup: boolean) => {
      const request: UpdateHideMonthlyPaymentsPopupRequest = {
        hideMonthlyPaymentsPopup: hideMonthlyPaymentsPopup,
      }
      return newsService.updateHideMonthlyPaymentsPopup(request)
    },
    onSuccess: (isSuccess: boolean, hideMonthlyPaymentsPopup: boolean) => {
      if (isSuccess) {
        const userStateUpdate: UserState = {
          ...newsUserState,
          userSpecificSettings: {
            ...newsUserState.userSpecificSettings,
            hideMonthlyPaymentsPopup: hideMonthlyPaymentsPopup,
          },
        }

        dispatch(setNewsUserState(userStateUpdate))
      }
    },
  })

  return mutation
}

export const useUpdateHideMonthlyPaymentsWelcomePopup = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)

  const mutation = useMutation({
    mutationFn: (hideMonthlyPaymentsWelcomePopup: boolean) => {
      const request: UpdateHideMonthlyPaymentsWelcomePopupRequest = {
        hideMonthlyPaymentsLicensePopup: hideMonthlyPaymentsWelcomePopup,
      }
      return newsService.updateHideMonthlyPaymentsWelcomePopup(request)
    },
    onSuccess: (
      isSuccess: boolean,
      hideMonthlyPaymentsWelcomePopup: boolean
    ) => {
      if (isSuccess) {
        const userStateUpdate: UserState = {
          ...newsUserState,
          userSpecificSettings: {
            ...newsUserState.userSpecificSettings,
            hideMonthlyPaymentsLicensePopup: hideMonthlyPaymentsWelcomePopup,
          },
        }

        dispatch(setNewsUserState(userStateUpdate))
      }
    },
  })

  return mutation
}

export const useUpdateHideMonthlyPaymentsTrialWelcomePopup =
  (): UseMutationResult<boolean, unknown, boolean, unknown> => {
    const dispatch = useAppDispatch()
    const newsUserState = useAppSelector(getNewsUserState)

    const mutation = useMutation({
      mutationFn: (hideMonthlyPaymentsTrialWelcomePopup: boolean) => {
        const request: UpdateHideMonthlyPaymentsTrialWelcomePopupRequest = {
          hideMonthlyPaymentsTrialWelcomePopup:
            hideMonthlyPaymentsTrialWelcomePopup,
        }
        return newsService.updateHideMonthlyPaymentsTrialWelcomePopup(request)
      },
      onSuccess: (
        isSuccess: boolean,
        hideMonthlyPaymentsTrialWelcomePopup: boolean
      ) => {
        if (isSuccess) {
          const userStateUpdate: UserState = {
            ...newsUserState,
            userSpecificSettings: {
              ...newsUserState.userSpecificSettings,
              hideMonthlyPaymentsTrialWelcomePopup:
                hideMonthlyPaymentsTrialWelcomePopup,
            },
          }

          dispatch(setNewsUserState(userStateUpdate))
        }
      },
    })

    return mutation
  }

export const useUpdateHideMonthlyPaymentTrialEndPopup = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)

  const mutation = useMutation({
    mutationFn: (hideMonthlyPaymentsTrialEndPopup: boolean) => {
      const request: UpdateHideMonthlyPaymentTrialEndPopupRequest = {
        hideMonthlyPaymentsTrialEndPopup: hideMonthlyPaymentsTrialEndPopup,
      }
      return newsService.updateHideMonthlyPaymentTrialEndPopup(request)
    },
    onSuccess: (
      isSuccess: boolean,
      hideMonthlyPaymentsTrialEndPopup: boolean
    ) => {
      if (isSuccess) {
        const userStateUpdate: UserState = {
          ...newsUserState,
          userSpecificSettings: {
            ...newsUserState.userSpecificSettings,
            hideMonthlyPaymentsTrialEndPopup: hideMonthlyPaymentsTrialEndPopup,
          },
        }

        dispatch(setNewsUserState(userStateUpdate))
      }
    },
  })

  return mutation
}

export const useGetMyPageNews = (): UseQueryResult<
  MyPageNewsArticleList,
  unknown
> => {
  const translations = useAppSelector(getTranslations)
  const userData = useAppSelector(getVolumesUserState)

  const request: MyPageNewsGetAllRequest = {
    languageId: 19, // for English only
    userName: userData.userName,
  }

  return useQuery([MyPageNewsQueryKey, userData.userName], async () => {
    const { isSuccess, articleList, responseMessage } =
      await newsService.getMyPageNews(request)

    !isSuccess && showErrorToast(translations.JNT_Error, responseMessage)

    return articleList
  })
}

export const useGetNewsCountries = (): UseQueryResult<
  NewsCountry[],
  unknown
> => {
  const dispatch = useAppDispatch()
  const commonUserData = useAppSelector(selectCommonUserData)

  const countriesRequest = {
    languageId: commonUserData.languageId,
  }

  return useQuery([commonUserData.languageId], async () => {
    const countries = await newsService.getCountries(countriesRequest)
    dispatch(setNewsCountries(countries))
    return countries
  })
}

export const useGetMyPageNewsArticle = (
  articleId: number,
  enabled: boolean
): UseQueryResult<NewsArticle, unknown> => {
  const translations = useAppSelector(getTranslations)
  const commonUserData = useAppSelector(selectCommonUserData)
  const userData = useAppSelector(getVolumesUserState)

  const request: MyPageLoadArticleRequest = {
    articleId: articleId,
    languageId: commonUserData.languageId,
    userName: userData.userName,
  }

  return useQuery(
    [articleId, commonUserData.languageId, userData.userName],
    async () => {
      const { isSuccess, article, responseMessage } =
        await newsService.getMyPageNewsArticle(request)

      !isSuccess && showErrorToast(translations.JNT_Error, responseMessage)

      return article
    },
    { enabled: enabled }
  )
}
