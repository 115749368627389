import {
  JATOTextInput,
  JATOVerticalSpacer
} from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import {
  getMakesValueList,
  getModelValueListForSelectedMake,
} from 'helper/newsHelper'
import { useGetMakesModelsByCountryCode } from 'hooks/news'
import { AdvancedSearchModel } from 'models/News/AdvancedSearchModel'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsCategoriesSelect from '../Alerts/NewsCategoriesSelect'
import NewsCountriesSelect from '../Alerts/NewsCountriesSelect'
import NewsDateRangePicker from '../Alerts/NewsDateRangePicker'
import NewsMultiSelect from '../Alerts/NewsMultiSelect'
import NewsVehicleTypeSelect from '../Alerts/NewsVehicleTypeSelect'
import { StyledNewsAdvancedSearch } from './NewsAdvancedSearch.styles'

export interface INewsAdvancedSearchProps {
  model: AdvancedSearchModel
  onChange: (model: AdvancedSearchModel) => void
}

export const NewsAdvancedSearch: React.FC<INewsAdvancedSearchProps> = ({
  model,
  onChange,
}: INewsAdvancedSearchProps) => {
  const translations = useAppSelector(getTranslations)

  const { data: makesModelsResponse, isFetching: isFetchingMakesModels } =
    useGetMakesModelsByCountryCode(
      model.countries ? model.countries.split(',') : []
    )

  return (
    <StyledNewsAdvancedSearch>
      <div className="searchText">
        <JATOTextInput
          label={translations.JNT_Search_text}
          id=""
          width={457}
          value={model.searchText}
          onChange={(e) =>
            onChange({ ...model, searchText: e.currentTarget.value })
          }
        />
        <CheckboxWithGtm
          id="newsAdvancedSearchExactMatchCheckbox"
          label={translations.JNT_Exact_match}
          checked={model.isExactMatch}
          onChange={() =>
            onChange({ ...model, isExactMatch: !model.isExactMatch })
          }
        >
          {translations.JNT_Exact_match}
        </CheckboxWithGtm>
      </div>
      <NewsCategoriesSelect
        value={model.categories}
        onChange={(value) => onChange({ ...model, categories: value })}
      />
      <NewsCountriesSelect
        value={model.countries}
        onChange={(value) => onChange({ ...model, countries: value })}
      />
      <NewsDateRangePicker
        value={model.dateRange}
        onChange={(value) => onChange({ ...model, dateRange: value })}
      />
      <NewsMultiSelect
        id="newsAdvancedSearchMakesSelect"
        label={`${translations.JNT_make}`}
        value={model.makes}
        onChange={(value) => onChange({ ...model, makes: value })}
        dropdownOptions={getMakesValueList(makesModelsResponse)}
        placeholder={translations.JNT_AllMakes}
      />
      <NewsVehicleTypeSelect
        value={model.group}
        onChange={(value) => onChange({ ...model, group: value })}
      />
      <NewsMultiSelect
        id="newsAdvancedSearchModelsSelect"
        label={translations.JNT_model}
        value={model.models}
        onChange={(value) => onChange({ ...model, models: value })}
        dropdownOptions={getModelValueListForSelectedMake(
          makesModelsResponse,
          model.makes.split(',')
        )}
        disabled={model.makes.length === 0}
        placeholder={translations.JNT_AllModels}
      />
      <JATOVerticalSpacer height={60} />
      <LoaderModal isOpen={isFetchingMakesModels} />
    </StyledNewsAdvancedSearch>
  )
}
