import styled from 'styled-components'
export const StyledSpecificationsReportFooter = styled.div`
  border-bottom: 1px solid rgb(198, 198, 198);
  position: sticky;
  bottom: 0;
  zindex: 1;
  .tableStyle {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    table-layout: fixed;
    width: 100%;
    background-color: #e0e0e0;
  }
  .tableRow {
    display: table-row;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    background-color: #e0e0e0;
    width: 100%;
  }

  .tableCell {
    display: table-cell;
    box-sizing: border-box;
    padding: 8px 16px;
    background-color: #e0e0e0;
    vertical-align: middle;
    text-align: left;
    width: 1200px;
  }
  .textMargin {
    margin-right: 10px;
    margin-left: 2px;
    font-size: 12px;
  }
  img,
  svg {
    vertical-align: middle;
  }
  .image-icon {
    width: 15px;
    height: 15px;
  }
  .sticky {
    position: sticky;
    left: 0;
  }
`
