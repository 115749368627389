import styled from 'styled-components'

export const StyledCustomerRegistration = styled.div`
  .PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    display: block;
    box-sizing: border-box;
    color: inherit;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 2;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    white-space: nowrap;
    transition: box-shadow 0.1s linear;
    padding: 8px 16px;
  }
  .label {
    position: relative;
    display: inline-block;
    flex-direction: column;
    -webkit-box-pack: end;
    justify-content: flex-end;
    color: rgb(68, 68, 68);
    font-family: Roboto, sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    font-size: 14px;
    color: inherit;
    margin: 0px 0px 8px;
    text-transform: none;
    border-bottom-width: initial;
    border-bottom-style: none;
    border-bottom-color: inherit;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
  }
  .jato-textarea {
    border: 1px solid lightgrey;
  }
  .fiaqht {
    border-bottom: none;
  }
  .jato-button-icon:focus {
    box-shadow: none;
  }
  .jato-button-icon:enabled {
    background-color: white;
  }
`
