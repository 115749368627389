import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'
import Theme from 'theme'

export const StyledDrawerHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & + .profile {
    h5 {
      font-size: ${Theme.fontSizes[1]}px !important;
    }
  }
`

export const StyledHorizontalNav = styled.header`
  background-color: ${Theme.colors.white};
  border-bottom: 1px solid ${Theme.colors.grey};
  height: var(--app-header-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${Theme.zIndices.header};
`

export const StyledHorizontalNavAction = styled.div`
  height: ${Theme.space[4]}px;
  margin-right: ${Theme.space[2]}px;
  width: ${Theme.space[4]}px;
  .jato-button-icon {
    margin: 0;
  }
`

export const StyledHorizontalNavBrand = styled.div`
  display: flex;
  margin-right: ${Theme.space[4]}px;
  @media (min-width: ${Theme.breakpoints[2]}px) {
    align-self: flex-end;
    margin-bottom: ${Theme.space[1] * 7}px;
  }
`

export const StyledHorizontalNavInner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  @media (min-width: ${Theme.breakpoints[2]}px) {
    ${StyledHorizontalNavAction} {
      display: none;
    }
  }
`

export const StyledHorizontalNavItems = styled.div`
  display: none;
  @media (min-width: ${Theme.breakpoints[2]}px) {
    align-self: flex-end;
    display: flex;
    flex: 1 1 auto;
    padding-left: ${Theme.space[4]}px;
  }

  .jato-nav-bar {
    min-height: ${Theme.space[6]}px;
    align-items: end;
  }

  .jato-nav-bar > div:last-child {
    align-self: center;
  }

  .profile-menu {
    button {
      &:disabled {
        background-color: transparent;
        cursor: default;
        h5 {
          font-size: ${Theme.fontSizes[1]}px;
        }
      }
      width: 100%;
    }
  }

  .nav-item-container {
    position: relative;
  }

  .new-badge {
    position: absolute;
    top: -16px;
    right: -14px;
    width: auto;
    height: 20px;
    background-color: ${JATOTheme.colors.black};
    border-radius: 5px;
    padding: 0 4px;
    text-align: center;
    color: ${JATOTheme.colors.white};
    line-height: 20px;
    font-size: 12px;
  }

  .rightNavGroup {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`
