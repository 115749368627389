import { NewsPage } from 'models/News/NewsPageType'

const MonthlyPayments = '/monthlypayments'
const Specifications = '/specifications'
const News = '/news'
const Volumes = '/volumes'
const MyPage = '/myPage'
const Wizard = '/wizard'
const Query = '/query'
const Comparisons = '/comparisons'
const Options = '/options'
const Baskets = '/baskets'
const NoLicense = '/nolicense'
const Home = '/login'
const Logout = '/logout'
const Terms = '/terms'
const PrivacyPolicy = '/privacypolicy'
const Autologin = '/autologin'
const Autologout = '/autologout'

export enum ReportPageType {
  Advantages = 'advantages',
  DisAdvantages = 'disadvantages',
  Options = 'options',
  Packages = 'packages',
  Colors = 'colors',
  BaseOptions = 'baseOptions',
  Showroom = 'showroom',
}

export enum VolumesPageType {
  StartReset = 'start',
  Country = 'country',
  QueryDefinition = 'query',
  PeriodSelections = 'periods',
  Report = 'report',
}

export const RoutePaths = {
  MonthlyPayments,
  Specifications,
  News,
  Volumes,
  MyPage,
  MonthlyPaymentsReport: `${MonthlyPayments}/monthlypaymentsReport`,
  SpecsShowroom: `${Specifications}/showroom`,
  SpecsComparisons: `${Specifications}${Comparisons}`,
  SpecsAdvantages: `${Specifications}${Comparisons}/${ReportPageType.Advantages}`,
  SpecsDisadvantages: `${Specifications}${Comparisons}/${ReportPageType.DisAdvantages}`,
  SpecsOptions: `${Specifications}${Options}`,
  SpecsOptionsOptions: `${Specifications}${Options}/${ReportPageType.Options}`,
  SpecsPackages: `${Specifications}${Options}/${ReportPageType.Packages}`,
  SpecsColors: `${Specifications}${Options}/${ReportPageType.Colors}`,
  SpecsBaseOptions: `${Specifications}${Options}/${ReportPageType.BaseOptions}`,
  SpecsBaskets: `${Specifications}${Baskets}`,
  NewsArticles: `${News}/articles`,
  NewsSharedArticles: `${News}/${NewsPage.SharedArticles}`,
  NewsSavedSearches: `${News}/${NewsPage.SavedSearches}`,
  NewsAlerts: `${News}/${NewsPage.Alerts}`,
  NewsBookMarkArticles: `${News}/${NewsPage.BookMarkArticles}`,
  NewsSearchResults: `${News}/${NewsPage.SearchResults}`,
  NewsWorkflowReports: `${News}/${NewsPage.WorkflowReports}`,
  NewsCornerstoneReports: `${News}/${NewsPage.CornerstoneReports}`,
  NewsSearchById: `${News}/search`,
  NewsSearchViewAll: `${News}/searchViewAll`,
  VolumesWizard: `${Volumes}${Wizard}`,
  VolumesStartReset: `${Volumes}${Wizard}/${VolumesPageType.StartReset}`,
  VolumesCountry: `${Volumes}${Wizard}/${VolumesPageType.Country}`,
  VolumesQueryDefinition: `${Volumes}${Wizard}/${VolumesPageType.QueryDefinition}`,
  VolumesPeriodSelections: `${Volumes}${Wizard}/${VolumesPageType.PeriodSelections}`,
  VolumesReport: `${Volumes}${Wizard}/${VolumesPageType.Report}`,
  VolumesQuery: `${Volumes}${Query}`,
  VolumesSegmentManager: `${Volumes}/segmentManager`,
  VolumesMyQueries: `${Volumes}/myQueries`,
  VolumesVehiclesVolumesType: `${Volumes}/vehiclesVolumesType`,
  VolumesMyCountryGrouping: `${Volumes}/myCountryGrouping`,
  MPNoLicense: `${MonthlyPayments}${NoLicense}`,
  SpecsNoLicense: `${Specifications}${NoLicense}`,
  NewsNoLicense: `${News}${NoLicense}`,
  VolumesNoLicense: `${Volumes}${NoLicense}`,
  Home,
  Terms,
  PrivacyPolicy,
  Logout,
  Autologin,
  Autologout,
}
