import pdfIcon from 'assets/icons/icon_pdf.svg'
import { NewsArticlePdfExportModal } from 'components/News/NewsArticlePdfExport/NewsArticlePdfExportModal'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsImgButton from '../../../CommonButtons/ImgButton'
import { StyledNewsExportPdfButton } from './NewsExportPdfButton.styles'

export interface INewsExportPdfButtonProps {
  article: NewsArticle
}

export const NewsExportPdfButton: React.FC<INewsExportPdfButtonProps> = ({
  article,
}: INewsExportPdfButtonProps) => {
  const translations = useAppSelector(getTranslations)
  const [newsPdfModalVisible, setNewsPdfModalVisible] = useState(false)

  return (
    <StyledNewsExportPdfButton>
      <NewsImgButton
        id="newsExportPdfButton"
        src={pdfIcon}
        title={translations.JNT_Export_Pdf}
        onClick={() => setNewsPdfModalVisible(true)}
      />

      <NewsArticlePdfExportModal
        isOpen={newsPdfModalVisible}
        onClose={() => setNewsPdfModalVisible(false)}
        article={article}
        title={article.headline}
      />
    </StyledNewsExportPdfButton>
  )
}
