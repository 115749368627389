import { ModalComponent } from 'components/ModalComponent'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { SpecificationsFaq } from './SpecificationsFaq'

interface IModalProps {
  isOpen: boolean
  onClose: () => void
}

export const SpecificationsFaqModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
}: IModalProps) => {
  const translations = useAppSelector(getTranslations)

  return (
    <ModalComponent
      modalVisible={isOpen}
      closeButton
      title={translations.JNT_Help_WltpFaq}
      onSecondaryClick={onClose}
    >
      <SpecificationsFaq />
    </ModalComponent>
  )
}
