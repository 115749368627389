import { BuiltOption, VehicleHeaderInfo } from '../BrochureResponse'

export interface OptionsPageResponse {
  vehicles: OptionResponse[]
  uniqueCategories: StatelessCategoryOption[]
}

export interface StatelessCategoryOption {
  translatedCategoryName: string
  optionOrderId: number
}

export interface OptionResponse {
  header: VehicleHeaderInfo
  rows: StatelessOptionRow[]
  vehiclePhotos: string[]
  builtOptions: BuiltOption[]
}

export interface StatelessOptionRow {
  vehicleId: number
  rowId: number
  optionId: number
  optionCode: string
  optionName: string
  optionType: string
  price: number | null
  status: OptionIconType
  translatedCategoryName: string
  catOrderId: number
  price902: number | null
  price903: number | null
  content: VisibleContent
  schemaIds: number[]
  displayPrice: string
  currencyCode: string
}

export enum OptionIconType {
  Standard,
  Unavailable,
  Added,
  AddedBuilt,
  DeletedCross,
  ExcludedDeleted,
  ExcludedOption,
  IncludedOption,
  RequiredAdded,
  RequiresOption,
  Option,
}

export interface VisibleContent {
  content: string[]
  type: string
  visible: boolean
}
