import Loader from 'components/Loader'
import { VolumesQueriesGrid } from 'components/Volumes/VolumesQueriesGrid'
import { VolumesQueryColumnType } from 'helper/volumesHelper'
import { useLoadAllQueries } from 'hooks/volumes'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import { StyledMyPageQueriesSharedToYou } from './MyPageQueriesSharedToYou.styles'

export const MyPageQueriesSharedToYou: React.FC = () => {
  const userData = useAppSelector(getVolumesUserState)

  const { data: queries, isFetching } = useLoadAllQueries()

  const groupQueries = queries?.filter(
    (x) => x.qryShared && x.qryOwnerId != userData.userId
  )

  return (
    <StyledMyPageQueriesSharedToYou>
      {isFetching ? (
        <Loader style={{ minHeight: '275px' }} />
      ) : (
        <VolumesQueriesGrid
          queries={groupQueries}
          columns={[
            VolumesQueryColumnType.SearchName,
            VolumesQueryColumnType.LastRan,
          ]}
        />
      )}
    </StyledMyPageQueriesSharedToYou>
  )
}
