import { JATOAcademyPopUp } from 'components/Modals/JATOAcademyPopUp/JATOAcademyPopUp'
import { useUpdateUserSettings } from 'hooks/volumes'
import { VolumesUserSettingsRequest } from 'models/Volumes/VolumesUserSettings'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import { StyledVolumesJATOAcademyPopUp } from './VolumesJATOAcademyPopUp.styles'

export const VolumesJATOAcademyPopUp: React.FC = () => {
  const volumesUserSettings = useAppSelector(getVolumesUserState)

  const [showJatoAcademyVideo, setShowJatoAcademyVideo] = useState(
    !volumesUserSettings.hideJATOAcademyPopUp
  )

  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const onCloseJatoAcademyButton = (doNotShowAgain: boolean): void => {
    const updatedSettings: VolumesUserSettingsRequest = {
      languageId: volumesUserSettings.languageId,
      currency: volumesUserSettings.currency,
      hideWelcomeInfo: volumesUserSettings.hideWelcomeInfo,
      hideJATOAcademyPopUp: true,
    }

    doNotShowAgain && updateUserSettings(updatedSettings)
    setShowJatoAcademyVideo(false)
  }

  return (
    <StyledVolumesJATOAcademyPopUp>
      <JATOAcademyPopUp
        id="volumesJatoAcademyPopup"
        showJATOAcademyVideo={showJatoAcademyVideo}
        onCloseJatoAcademyButton={onCloseJatoAcademyButton}
      />
    </StyledVolumesJATOAcademyPopUp>
  )
}
