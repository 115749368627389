import {
  JATOButton,
  JATOButtonGroup,
  JATONavBar,
  JATONavGroup,
  JATONavItem,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import { ArticleTabs, ContentParts } from 'models/News/Article/NewsArticle'
import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { StyledNewsArticleTabHeader } from './NewsArticleTabHeader.styles'

interface INewsArticleTabHeaderProps {
  contentParts: ContentParts[]
  onChangeTabs: (tabs: ArticleTabs) => void
}

export const NewsArticleTabHeader: React.FC<INewsArticleTabHeaderProps> = ({
  contentParts,
  onChangeTabs,
}: INewsArticleTabHeaderProps) => {
  const getTabTitleValues = (contentParts: ContentParts[]): any => {
    const allValues = contentParts
      .map((content: ContentParts) => content.tabTitle)
      .filter((tab: string) => tab)
    return Array.from(new Set(allValues))
  }

  const getSubTabTitleValues = (
    contentParts: ContentParts[],
    tabTitle: string
  ): any => {
    const allValues = contentParts
      .filter(
        (content: ContentParts) =>
          content.tabTitle === tabTitle && content.subTabTitle
      )
      .map((content: ContentParts) => content.subTabTitle)
    return Array.from(new Set(allValues))
  }

  const [selectedMainTab, setMainTab] = useState<string>(
    getTabTitleValues(contentParts)[0]
  )

  const [selectedSubTab, setSubTab] = useState<string>(
    getSubTabTitleValues(contentParts, selectedMainTab)[0]
  )

  const setMainTabAndSubTabValues = (tabTitle: string): void => {
    setMainTab(tabTitle)
    setSubTab(getSubTabTitleValues(contentParts, tabTitle)[0])
  }

  const isActiveSubTitle = (subTitle: string): boolean =>
    subTitle === selectedSubTab

  useEffect(() => {
    onChangeTabs({ tabTitle: selectedMainTab, subTabTitle: selectedSubTab })
  }, [selectedMainTab, selectedSubTab])

  return (
    <StyledNewsArticleTabHeader>
      <JATOVerticalSpacer height={5} />
      <JATONavBar
        left={() => (
          <JATONavGroup flexDirection="row" space={16}>
            {getTabTitleValues(contentParts).map((tab: string) => (
              <GtmWrapper key={uuid()} id="newsArticleTabButton" label={tab}>
                <JATONavItem size="medium" isActive={tab === selectedMainTab}>
                  <div onClick={() => setMainTabAndSubTabValues(tab)}>
                    {tab}
                  </div>
                </JATONavItem>
              </GtmWrapper>
            ))}
          </JATONavGroup>
        )}
        right={() => (
          <JATOButtonGroup space={1} className="buttonGroup">
            {getSubTabTitleValues(contentParts, selectedMainTab).map(
              (subTab: string) => (
                <GtmWrapper
                  key={uuid()}
                  id="newsArticleSubTabButton"
                  label={subTab}
                  parentLabel={selectedMainTab}
                >
                  <JATOButton
                    variant={'ghost'}
                    size={'medium'}
                    className={
                      isActiveSubTitle(subTab)
                        ? 'buttonActiveStatus'
                        : 'buttonStatus'
                    }
                  >
                    <div onClick={() => setSubTab(subTab)}>{subTab}</div>
                  </JATOButton>
                </GtmWrapper>
              )
            )}
          </JATOButtonGroup>
        )}
      />
      <JATOVerticalSpacer height={10} />
    </StyledNewsArticleTabHeader>
  )
}
