import { JATOGroup, JATOTextInput } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { CommonModal } from 'components/Modals/CommonModal'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useLoadAllQueries, useSaveQuery } from 'hooks/volumes'
import { VolumesUserState } from 'models/Login/VolumesLogin/VolumesUserState'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'

interface ISaveQueryProps {
  isOpen: boolean
  onClose: () => void
}

export const VolumesSaveQueryModal: React.FC<ISaveQueryProps> = ({
  isOpen,
  onClose,
}: ISaveQueryProps) => {
  const translations = useAppSelector(getTranslations)
  const userData: VolumesUserState = useAppSelector(getVolumesUserState)

  const [queryName, setQueryName] = useState('')

  const { mutateAsync: saveQuery, isLoading: isSaving } =
    useSaveQuery(queryName)

  const { data } = useLoadAllQueries()

  const queries = data?.filter(
    (x) => !x.qryShared && x.qryOwnerId == userData.userId
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.currentTarget.value
    setQueryName(name)
  }

  const onSaveClick = async (): Promise<void> => {
    const isSuccess = await saveQuery()
    if (isSuccess) {
      showSuccessToast(translations.JNT_Success, translations.JNT_SavedSuccess)
      onClose()
    } else {
      showErrorToast(translations.JNT_Error, translations.JNT_Error)
    }
  }

  const hasError =
    !!queries &&
    queryName !== '' &&
    queries.some((q) => q.queryShortDescription === queryName)

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      closeButton
      maxWidth="sm"
      title={translations.JNT_Volumes_FP_SaveQuery}
      primaryButtonName={translations.JNT_Volumes_FP_SaveQuery}
      disablePrimaryButton={hasError || queryName.length === 0}
      onPrimaryClick={onSaveClick}
    >
      {isSaving ? (
        <Loader style={{ minHeight: '100%' }} />
      ) : (
        <JATOGroup flexDirection="row">
          <JATOTextInput
            id=""
            width={430}
            placeholder={translations.JNT_Volumes_FP_TypeHere}
            value={queryName}
            hasError={hasError}
            onChange={onChange}
            validateText={
              hasError ? translations.JNT_Volumes_FP_QueryNameExists : ''
            }
          />
        </JATOGroup>
      )}
      <LoaderModal isOpen={isSaving} />
    </CommonModal>
  )
}
