import { DropdownWithSearch } from 'components/MonthlyPayments/Filters/DropdownWithSearch'
import { IDropdownWithSearch } from 'components/MonthlyPayments/Filters/DropdownWithSearch/DropdownWithSearch'
import { gtmLogSelectedValueChange } from 'helper/gtm'
import React from 'react'
import { GtmWrapper } from './GtmWrapper'

interface IGtmDropdownProps extends IDropdownWithSearch {
  id: string
}

export const DropdownWithGtm: React.FC<IGtmDropdownProps> = (
  props: IGtmDropdownProps
) => {
  const onChangeInternal = (value: string): void => {
    const { id, filterName, options, onChange } = props
    const { displayValue } = options.find((o) => o.value === value) ?? {}

    gtmLogSelectedValueChange(id, displayValue, filterName, value, true)

    onChange(value)
  }

  return (
    <GtmWrapper skip>
      <DropdownWithSearch {...props} onChange={onChangeInternal} />
    </GtmWrapper>
  )
}
