import {
  JATOButton,
  JATOHeading,
  JATOIcon,
  JATOModal,
  JATOText,
  JATOTheme,
} from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { useUpdateHideMonthlyPaymentsWelcomePopup } from 'hooks/news'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getNewsUserState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { NewsWelcomePopupVideo } from '../NewsWelcomePopupVideo/NewsWelcomePopupVideo'

export const NewsMonthlyPaymentsWelcomePopup: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const {
    isMonthlyPayments,
    userSpecificSettings: { hideMonthlyPaymentsLicensePopup },
  } = useAppSelector(getNewsUserState)

  const hasMonthlyPaymentsLicense = isMonthlyPayments

  const { mutate: updateHideMonthlyPaymentsWelcomePopup } =
    useUpdateHideMonthlyPaymentsWelcomePopup()

  const onCloseButton = (): void => {
    doNotShowAgain && updateHideMonthlyPaymentsWelcomePopup(true)
  }

  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  return (
    <JATOModal
      isOpen={hasMonthlyPaymentsLicense && !hideMonthlyPaymentsLicensePopup}
      maxWidth={'sm'}
      dismissable={false}
      header={() => (
        <>
          <JATOHeading
            as="h4"
            fontWeight="medium"
            display="flex"
            color={JATOTheme.colors.blue}
          >
            {translations.JNT_NewsMonthlyPaymentsWelcomeTitle}
          </JATOHeading>
          <JATOIcon style={{ paddingLeft: '10px' }} iconName="baseline_info" />
        </>
      )}
      footer={({ handleClose }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <CheckboxWithGtm
            checked={doNotShowAgain}
            onChange={() => setDoNotShowAgain(!doNotShowAgain)}
            id="newsMonthlyPaymentsWelcomePopupDoNotShowAgainCheckbox"
            label={translations.JNT_Do_not_show_me_this_again}
          >
            <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
              {translations.JNT_Do_not_show_me_this_again}
            </JATOText>
          </CheckboxWithGtm>
          <JATOButton
            id="newsMonthlyPaymentsWelcomePopupCloseButton"
            variant="primary"
            size="large"
            onClick={() => {
              onCloseButton()
              handleClose && handleClose()
            }}
          >
            {translations.JNT_Close}
          </JATOButton>
        </div>
      )}
    >
      <NewsWelcomePopupVideo />
      <p>{translations.JNT_NewsMonthlyPaymentsWelcomeText1}</p>
      <p>{translations.JNT_NewsMonthlyPaymentsWelcomeText2}</p>
      <p>{translations.JNT_NewsMonthlyPaymentsWelcomeText3}</p>
      <ul>
        <li>
          <strong>{translations.JNT_NewsMonthlyPaymentsWelcomeText4_1}</strong>{' '}
          {translations.JNT_NewsMonthlyPaymentsWelcomeText4_2}
        </li>
        <li>
          <strong>{translations.JNT_NewsMonthlyPaymentsWelcomeText5_1}</strong>{' '}
          {translations.JNT_NewsMonthlyPaymentsWelcomeText5_2}
        </li>
        <li>
          <strong>{translations.JNT_NewsMonthlyPaymentsWelcomeText6_1}</strong>{' '}
          {translations.JNT_NewsMonthlyPaymentsWelcomeText6_2}
        </li>
      </ul>
      <p>{translations.JNT_NewsMonthlyPaymentsWelcomeText7}</p>
      <p>{translations.JNT_NewsMonthlyPaymentsWelcomeText8}</p>
      <p>{translations.JNT_NewsMonthlyPaymentsWelcomeText9}</p>
    </JATOModal>
  )
}
