import { AxiosResponse } from 'axios'
import { logTrace } from 'helper/loggingService'
import { AdvanceFiltersRequest } from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import {
  AdvanceFilterAllowedValuesResponse,
  AdvanceFiltersResponse,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersResponse'
import { BrochureResponse } from 'models/Specifications/BrochureResponse'
import { GetVehicleComparisonRequest } from 'models/Specifications/GetVehicleComparisonRequest'
import { GetVehicleComparisonResponse } from 'models/Specifications/GetVehicleComparisonResponse'
import { WltpRequest, WltpResponse } from 'models/Specifications/GetWltpRequest'
import { DetailedAction } from 'models/Specifications/Options/DetailedAction'
import { OptionsBuildRuleRequest } from 'models/Specifications/Options/optionsBuildRuleRequest'
import { OptionsBuildRuleResponse } from 'models/Specifications/Options/OptionsBuildRuleResponse'
import { OptionsPageResponse } from 'models/Specifications/Options/OptionsPageResponse'
import { VehicleBrochureRequest } from 'models/Specifications/VehicleBrochureRequest'
import { carSpecsApi } from 'services/apiWithAuth'

class CarSpecsService {
  public getAdvanceFilterList(
    advanceFiltersRequest: AdvanceFiltersRequest
  ): Promise<AdvanceFiltersResponse> {
    return carSpecsApi
      .post<AdvanceFiltersRequest, AxiosResponse<AdvanceFiltersResponse>>(
        `/genericfiltersjatonet/getfilterCache`,
        advanceFiltersRequest
      )
      .then((response) => {
        logTrace(
          'Get advance filter successful',
          `Id: ${advanceFiltersRequest.userSettings?.id}`,
          `Advance filters count: ${response.data?.filterList?.length}`
        )

        return response.data
      })
  }

  public getAdvanceFilterInfo(
    advanceFiltersRequest: AdvanceFiltersRequest
  ): Promise<AdvanceFilterAllowedValuesResponse> {
    return carSpecsApi
      .post<
        AdvanceFiltersRequest,
        AxiosResponse<AdvanceFilterAllowedValuesResponse>
      >(
        `/genericfiltersjatonet/getAdvanceActiveFilterInfo`,
        advanceFiltersRequest
      )
      .then((response) => {
        logTrace(
          'Get advance filter info successful',
          `Id: ${advanceFiltersRequest.userSettings?.id}`,
          `filterValues length: ${response.data?.filterValues?.length}`
        )

        return response.data
      })
  }

  public getWltpData(
    wltpRequest: WltpRequest[] | undefined
  ): Promise<WltpResponse> {
    return carSpecsApi
      .post<WltpRequest[], AxiosResponse<WltpResponse>>(
        `/wltp/VehicleData`,
        wltpRequest
      )
      .then((response) => response.data)
  }

  public getBrochure(
    getBrochureRequest: VehicleBrochureRequest[]
  ): Promise<BrochureResponse> {
    return carSpecsApi
      .post<VehicleBrochureRequest[], AxiosResponse<BrochureResponse>>(
        `/brochure`,
        getBrochureRequest
      )
      .then((response) => response.data)
  }

  public loadCompetitorAdvantages(
    payloadRequest: GetVehicleComparisonRequest
  ): Promise<GetVehicleComparisonResponse> {
    return carSpecsApi
      .post<
        GetVehicleComparisonRequest,
        AxiosResponse<GetVehicleComparisonResponse>
      >(`/KeyAdvantages/loadCompetitorAdvantages`, payloadRequest)
      .then((response) => {
        logTrace(
          'Load competitor advantages call successful',
          `Request length: ${payloadRequest?.length}`,
          `Competitor advantages length: ${response.data?.competitorAdvantages?.length}`
        )

        return response.data
      })
  }

  public loadBiased(
    payloadRequest: GetVehicleComparisonRequest
  ): Promise<GetVehicleComparisonResponse> {
    return carSpecsApi
      .post<
        GetVehicleComparisonRequest,
        AxiosResponse<GetVehicleComparisonResponse>
      >(`/KeyAdvantages/loadBiased`, payloadRequest)
      .then((response) => {
        logTrace(
          'Load biased call successful',
          `Payload request length: ${payloadRequest.length}`,
          `Response length: ${response.data?.competitorAdvantages?.length}`
        )

        return response.data
      })
  }

  public resetBuiltVehicle(userGuid: string | undefined): Promise<void> {
    return carSpecsApi
      .post<any, AxiosResponse<any>>(`/cleanup/reset`, { userGuid: userGuid })
      .then((response) => response.data)
  }

  public removeVehicle(vehicleId: number): Promise<void> {
    return carSpecsApi
      .post<any, AxiosResponse<any>>(`/cache/recipe/remove`, vehicleId)
      .then((response) => response.data)
  }

  public getOptionBuildRules(
    optionsBuildRuleRequest: OptionsBuildRuleRequest
  ): Promise<OptionsBuildRuleResponse> {
    return carSpecsApi
      .get<OptionsBuildRuleRequest, AxiosResponse<OptionsBuildRuleResponse>>(
        `/Buildoptions/v3/rules`,
        { params: optionsBuildRuleRequest }
      )
      .then((response) => {
        logTrace(
          'Get option build rules call successful',
          `VehicleId: ${optionsBuildRuleRequest.vehicleId}`,
          `Ledgers: ${response.data.ledger}`
        )

        return response.data
      })
  }

  public addRecipe(recipe: DetailedAction): Promise<boolean> {
    return carSpecsApi
      .post<DetailedAction, AxiosResponse<boolean>>(`/cache/recipe/add`, recipe)
      .then((response) => response.data)
  }

  public getRecipes(): Promise<DetailedAction[]> {
    return carSpecsApi
      .get<DetailedAction, AxiosResponse<DetailedAction[]>>(`/cache/recipe`)
      .then((response) => response.data)
  }

  public getOptionPage(
    getBrochureRequest: VehicleBrochureRequest[]
  ): Promise<OptionsPageResponse> {
    return carSpecsApi
      .post<VehicleBrochureRequest[], AxiosResponse<OptionsPageResponse>>(
        `/Optionals/OptionPage`,
        getBrochureRequest
      )
      .then((response) => response.data)
  }

  public getPackagesPage(
    getBrochureRequest: VehicleBrochureRequest[]
  ): Promise<OptionsPageResponse> {
    return carSpecsApi
      .post<VehicleBrochureRequest[], AxiosResponse<OptionsPageResponse>>(
        `/Optionals/PackagesPage`,
        getBrochureRequest
      )
      .then((response) => response.data)
  }

  public getColorsPage(
    getBrochureRequest: VehicleBrochureRequest[]
  ): Promise<OptionsPageResponse> {
    return carSpecsApi
      .post<VehicleBrochureRequest[], AxiosResponse<OptionsPageResponse>>(
        `/Optionals/ColorsPage`,
        getBrochureRequest
      )
      .then((response) => response.data)
  }

  public getBasePage(
    getBrochureRequest: VehicleBrochureRequest[]
  ): Promise<OptionsPageResponse> {
    return carSpecsApi
      .post<VehicleBrochureRequest[], AxiosResponse<OptionsPageResponse>>(
        `/Optionals/BasePage`,
        getBrochureRequest
      )
      .then((response) => response.data)
  }
}

const newCarSpecsService = new CarSpecsService()
export default newCarSpecsService
