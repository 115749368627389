import {
  JATOLink,
  JATOText,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { fluid } from 'config'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'
import { StyledNotFound } from './NotFound.styles'

export const NotFound = ({
  location,
}: RouteComponentProps): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <StyledNotFound>
      <Container fluid={fluid}>
        <Row align="center">
          <Col sm={12}>
            <h1>{t('notFound.title')}</h1>
            <JATOText as="p">
              {ReactHtmlParser(
                t('notFound.pageNotFound', {
                  page: location.pathname,
                })
              )}
            </JATOText>
            <JATOVerticalSpacer />
            <Link to="/">
              <JATOLink as="div">{t('notFound.return')}</JATOLink>
            </Link>
          </Col>
        </Row>
      </Container>
    </StyledNotFound>
  )
}
