import { JATOButtonGroup, JATONavBar } from '@jato/ui-component-library'
import { NewsPage } from 'models/News/NewsPageType'
import React from 'react'
import { useLocation } from 'react-router-dom'
import NewsMainMenuButton from '../Buttons/NewsMainMenuButton'
import NewsViewSelection from '../Buttons/NewsViewSelection'
import NewsSearch from '../NewsSearch'
import { StyledNewsNavigation } from './NewsNavigation.styles'

export const NewsNavigation: React.FC = () => {
  const { pathname } = useLocation()
  const isViewSelection = pathname.includes(NewsPage.ViewSelection)

  return (
    <StyledNewsNavigation>
      <JATONavBar
        className="newsNavigation"
        left={() => <NewsSearch />}
        right={() => (
          <JATOButtonGroup space={6}>
            {isViewSelection && <NewsViewSelection />}
            <NewsMainMenuButton />
          </JATOButtonGroup>
        )}
      />
    </StyledNewsNavigation>
  )
}
