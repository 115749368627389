import {
  JATOButtonIcon,
  JATOHeading,
  JATOTheme,
} from '@jato/ui-component-library'
import React from 'react'

interface IProps {
  id?: string
  handleClose?: () => void
  title: string
}

export const MpAlertsBetaPopupHeader: React.FC<IProps> = ({
  id: parentId = 'mpAlertsBetaPopupHeader',
  handleClose,
  title,
}: IProps) => (
  <>
    <JATOHeading
      as="h3"
      fontWeight="medium"
      display="flex"
      color={JATOTheme.colors.blue}
    >
      {title}
    </JATOHeading>
    <JATOButtonIcon
      id={`${parentId}_Close`}
      iconName="baseline_close"
      onClick={handleClose}
    />
  </>
)
