import styled from 'styled-components'

export const StyledSpecificationsReportHeader = styled.div`
  border-bottom: 1px solid rgb(198, 198, 198);
  top: 0;
  z-index: 1;

  .showroomPage,
  .comparisonsPage,
  .optionsPage {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
  }

  .showroomPage .comparisonRow,
  .optionsPage .comparisonRow {
    display: none;
  }

  .comparisonRow {
    border-bottom: 1px solid #c8c8c8;
  }

  .comparisonRow #benchmarkVehicle,
  .comparisonRow #benchmarkVehicleOption {
    position: sticky;
    left: calc(50vw - 200px);
    width: 320px;
  }

  .vehiclesRow,
  .optionsRow {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  .optionsRow {
    align-items: flex-start;
  }

  .comparisonsPage .vehiclesRow #benchmarkVehicle,
  .comparisonsPage .optionsRow #benchmarkVehicleOption {
    display: none;
  }

  .vehicle,
  .optionsContainer {
    display: flex;
    box-sizing: border-box;
    padding: 8px 10px;
    background-color: #ffffff;
    vertical-align: top;
    text-align: left;
    width: 220px;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .emptySpace {
    position: sticky;
    left: 0;
    width: 300px;
    z-index: 1;
    flex-grow: 1.36;
    background-color: #ffffff;
  }

  .optionsPage .emptySpace,
  .comparisonsPage .emptySpace {
    display: none;
  }

  .benchmark {
    margin-left: -2px;
    margin-top: 2px;
  }

  .removeVehicle {
    cursor: pointer;
    float: left;
    padding-left: 14px;
  }

  .replaceVehicle {
    cursor: pointer;
    float: left;
  }

  .displayVehicleImage {
    margin-left: -2px;
    margin-top: 2px;
  }

  .viewGallery {
    cursor: pointer;
    padding-left: 14px;
    margin-top: 1px;
    float: left;
  }

  .viewNews {
    cursor: pointer;
    padding-left: 14px;
    float: left;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: red;
  }

  .vehicleMakeAndModelText {
    padding-left: 10px;
    font-weight: 800;
  }

  .vehicleVersionLevelText {
    padding-left: 8px;
    font-weight: 400;
  }

  .vehiclePriceText {
    padding-left: 8px;
    font-weight: 400;
  }

  .vehiclePhotoGalleryImage {
    max-width: 100%;
  }

  .wltpErrorsButton {
    float: left;
  }

  .wltpErrorsButton > button {
    margin-left: 12px;
    margin-top: -1px;
  }

  .displayOptionBuildInfo {
    cursor: pointer;
    border-color: #ddd;
    background-color: #f5f5f5;
    padding: 5px;
  }

  .vehicleActionSection {
    margin-left: 28px;
    display: table;
    clear: both;
  }
`
