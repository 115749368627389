import { JATOVerticalSpacer } from '@jato/ui-component-library'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import {
  CarouselComponent,
  CarouselItemDirective,
  CarouselItemsDirective,
} from '@syncfusion/ej2-react-navigations'
import { useEnableRtl } from 'hooks/useEnableRtl'
import React from 'react'
import { StyledPhotoGallery } from './PhotoGallery.styles'

interface IPhotoGalleryProps {
  id?: string
  imageUrlList: string[]
}

export const PhotoGallery: React.FC<IPhotoGalleryProps> = ({
  id = 'photoGallery',
  imageUrlList,
}) => {
  const enableRtl = useEnableRtl()
  const noPhotoGalleryMessage = 'Vehicle photos not found'

  const getImageTemplate = (imageUrl: string): React.ReactElement => (
    <figure className="img-container">
      <img
        src={imageUrl}
        alt="bridge"
        style={{ height: '100%', width: '100% ' }}
      />
    </figure>
  )

  const previousButtonTemplate = (): React.ReactElement => (
    <ButtonComponent
      id={id + '_PreviousButton'}
      className="e-btn"
      cssClass="e-flat e-round nav-btn"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        width="40"
        height="40"
      >
        <path d="m13.5 7.01 13 13m-13 13 13-13" />
      </svg>
    </ButtonComponent>
  )

  const nextButtonTemplate = (): React.ReactElement => (
    <ButtonComponent
      id={id + '_NextButton'}
      className="e-btn"
      cssClass="e-flat e-round nav-btn"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        width="40"
        height="40"
      >
        <path d="m13.5 7.01 13 13m-13 13 13-13" />
      </svg>
    </ButtonComponent>
  )

  const indicatorTemplate = (props: any): React.ReactElement => {
    if (imageUrlList)
      return (
        <div className="indicator">
          <img
            // eslint-disable-next-line react/prop-types
            src={imageUrlList[props.index]}
            alt="image"
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      )
    return <></>
  }
  return (
    <StyledPhotoGallery>
      {imageUrlList &&
        (imageUrlList.length > 0 ? (
          <div className="control-pane">
            <div className="control-section template-carousel-section">
              <div className="control carousel-sample">
                <CarouselComponent
                  cssClass="templateCarousel"
                  animationEffect="Fade"
                  buttonsVisibility="Visible"
                  indicatorsTemplate={indicatorTemplate}
                  previousButtonTemplate={previousButtonTemplate}
                  nextButtonTemplate={nextButtonTemplate}
                  enableRtl={enableRtl}
                >
                  <CarouselItemsDirective>
                    {imageUrlList.map((imageUrl, index) => (
                      <CarouselItemDirective
                        key={index}
                        template={() => getImageTemplate(imageUrl)}
                      />
                    ))}
                  </CarouselItemsDirective>
                </CarouselComponent>
              </div>
            </div>
          </div>
        ) : (
          <span>{noPhotoGalleryMessage}</span>
        ))}
      <JATOVerticalSpacer />
    </StyledPhotoGallery>
  )
}
