import {
  JATOButton,
  JATOIcon,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import NewsSearchAlertEditor from 'components/News/NewsSearchAlertEditor'
import { getDefaultNewsAlertOrSearch } from 'helper/newsHelper'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsAddNewAlertButton } from './NewsAddNewAlertButton.styles'

export const NewsAddNewAlertButton: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const defaultSearchIndexRequest: SearchIndexRequest =
    getDefaultNewsAlertOrSearch()
  const [showCreateAlertWindow, setCreateAlertWindowVisible] = useState(false)

  return (
    <StyledNewsAddNewAlertButton>
      <div className="newAlertDiv">
        <JATOButton
          id="newsAddNewAlertButton"
          variant="primary"
          as="div"
          className="newAlertButton"
          onClick={() => setCreateAlertWindowVisible((prevState) => !prevState)}
        >
          <span>{translations.JNT_NewAlert}</span>
          <JATOIcon iconName="baseline_add" />
        </JATOButton>
      </div>
      <JATOVerticalSpacer height={10} />
      {showCreateAlertWindow && (
        <NewsSearchAlertEditor
          showWindow={showCreateAlertWindow}
          setWindowState={setCreateAlertWindowVisible}
          searchIndexRequest={defaultSearchIndexRequest}
        />
      )}
    </StyledNewsAddNewAlertButton>
  )
}
