import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import {
  NewsAlertSubscriptionType,
  getAlertsSubscriptionTypeName,
} from 'helper/newsHelper'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
interface INewsSubscriptionTypeSelectionProps {
  onChange: (value: NewsAlertSubscriptionType) => void
  value: NewsAlertSubscriptionType
  isRequired?: boolean
}

export const NewsSubscriptionTypeSelection: React.FC<
  INewsSubscriptionTypeSelectionProps
> = ({ onChange, value, isRequired }) => {
  const translations = useAppSelector(getTranslations)
  const subscriptionTypeTranslations =
    getAlertsSubscriptionTypeName(translations)

  return (
    <div>
      <JATOText fontSize={14} marginBottom={8} as="p">
        {`${translations.JNT_AlertFrequency}${isRequired ? ' *' : ''}`}
      </JATOText>
      {Array.from(subscriptionTypeTranslations).map(
        ([optionValue, optionText]) => (
          <RadioWithGtm
            checked={optionValue === value}
            onChange={() => onChange(optionValue)}
            key={uuid()}
            id="newsAlertSubscriptionTypeOption"
            value={NewsAlertSubscriptionType[optionValue]}
            label={optionText}
          >
            <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
              {optionText}
            </JATOText>
          </RadioWithGtm>
        )
      )}
    </div>
  )
}
