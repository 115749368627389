import {
  JATOButton,
  JATOButtonIcon,
  JATOGroup,
  JATOSpinner,
  JATOText,
  JATOTextInput,
  JATOTheme,
} from '@jato/ui-component-library'
import { CommonModal } from 'components/Modals/CommonModal'
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useChangePassword } from 'hooks/portal'
import { ChangePasswordRequest } from 'models/Portal/ChangePasswordRequest'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import * as Yup from 'yup'
import { StyledMyPageChangePasswordModal } from './MyPageChangePasswordModal.styles'
import { PasswordValidationMessage } from 'components/Portal/PasswordValidationMessage'
import { minLength, validatePassword } from 'helper/portalHelper'

interface IModalProps {
  isOpen?: boolean
  onClose?: () => void
}

interface ChangePasswordModel {
  oldPassword: string
  newPassword: string
  newPasswordConfirm: string
}

export const MyPageChangePasswordModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
}: IModalProps) => {
  const translations = useAppSelector(getTranslations)
  const userData = useAppSelector(getVolumesUserState)

  const { mutateAsync: changePassword, isLoading } = useChangePassword()

  const handleSubmit = async (values: ChangePasswordModel): Promise<void> => {
    const request: ChangePasswordRequest = {
      username: userData.userName,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    }

    const response = await changePassword(request)

    if (response.isSuccess) {
      showSuccessToast(translations.JNT_Success, translations.JNT_SavedSuccess)
      onClose && onClose()
    } else {
      const errorMessage = response.isValidCredentials
        ? translations.JNT_Portal_ChangePassword_UsedBefore_Message
        : response.responseMessage

      showErrorToast(translations.JNT_Error, errorMessage)
    }
  }

  const schema = Yup.object().shape({
    oldPassword: Yup.string().required(
      translations.JNT_Portal_Form_Field_Required
    ),
    newPassword: Yup.string()
      .required(translations.JNT_Portal_Form_Field_Required)
      .min(
        minLength,
        translations.JNT_Portal_CustReg_Password_MinLength_Message
      )
      .test(
        'validatePassword',
        translations.JNT_Portal_ModPwd_Desc,
        validatePassword
      ),
    newPasswordConfirm: Yup.string()
      .oneOf(
        [Yup.ref('newPassword')],
        translations.JNT_Portal_CustReg_Password_NoMatch_Message
      )
      .required(translations.JNT_Portal_Form_Field_Required),
  })

  const [showPassword, setShowPassword] = useState(false)

  return (
    <CommonModal
      closeButton
      maxWidth="xs"
      isOpen={isOpen}
      onClose={onClose}
      title={translations.JNT_Portal_ModPwd_Title}
    >
      <StyledMyPageChangePasswordModal>
        <JATOText as="div" fontSize={14} marginBottom="15px">
          {translations.JNT_Portal_ModPwd_Desc}
        </JATOText>

        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
          validationSchema={schema}
          onSubmit={async (values: ChangePasswordModel) => {
            await handleSubmit(values)
          }}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form style={{ width: 'calc(100% - 30px)' }}>
              <Field>
                {({ field }: FieldProps) => (
                  <>
                    <div>
                      <JATOTextInput
                        {...field}
                        id="oldPassword"
                        width="100%"
                        label={translations.JNT_Portal_ModPwd_OldPwd}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={
                          translations.JNT_Portal_Form_Required_Placeholder
                        }
                        value={values.oldPassword}
                        onChange={handleChange}
                        hasError={touched.oldPassword && !!errors.oldPassword}
                        isValid={touched.oldPassword && !errors.oldPassword}
                      />
                      <ErrorMessage
                        name="oldPassword"
                        render={() => (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[0]}
                            color={JATOTheme.colors.primary}
                            display="inline-flex"
                            marginTop={JATOTheme.space[1]}
                          >
                            {errors.oldPassword}
                          </JATOText>
                        )}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: JATOTheme.space[4],
                      }}
                    >
                      <div style={{ position: 'relative' }}>
                        <JATOTextInput
                          {...field}
                          width="100%"
                          id="newPassword"
                          label={translations.JNT_Portal_ModPwd_NewPwd}
                          type={showPassword ? 'text' : 'password'}
                          placeholder={
                            translations.JNT_Portal_Form_Required_Placeholder
                          }
                          value={values.newPassword}
                          onChange={handleChange}
                          hasError={touched.newPassword && !!errors.newPassword}
                          isValid={touched.newPassword && !errors.newPassword}
                        />
                        <JATOButtonIcon
                          iconName={
                            !showPassword
                              ? 'baseline_lock'
                              : 'baseline_lock_open'
                          }
                          title={
                            !showPassword
                              ? translations.JNT_Portal_Show_Password
                              : translations.JNT_Portal_Hide_Password
                          }
                          className="showPasswordButton"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                      <ErrorMessage
                        name="newPassword"
                        render={() => (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[0]}
                            color={JATOTheme.colors.primary}
                            display="inline-flex"
                            marginTop={JATOTheme.space[1]}
                          >
                            {errors.newPassword}
                          </JATOText>
                        )}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: JATOTheme.space[4],
                      }}
                    >
                      <JATOTextInput
                        {...field}
                        width="100%"
                        id="newPasswordConfirm"
                        label={translations.JNT_Portal_ModPwd_RePwd}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={
                          translations.JNT_Portal_Form_Required_Placeholder
                        }
                        value={values.newPasswordConfirm}
                        onChange={handleChange}
                        hasError={
                          touched.newPasswordConfirm &&
                          !!errors.newPasswordConfirm
                        }
                        isValid={
                          touched.newPasswordConfirm &&
                          !errors.newPasswordConfirm
                        }
                      />
                      <ErrorMessage
                        name="newPasswordConfirm"
                        render={() => (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[0]}
                            color={JATOTheme.colors.primary}
                            display="inline-flex"
                            marginTop={JATOTheme.space[1]}
                          >
                            {errors.newPasswordConfirm}
                          </JATOText>
                        )}
                      />
                    </div>
                  </>
                )}
              </Field>
              <JATOGroup style={{ margin: `${JATOTheme.space[4]}px 0` }}>
                <PasswordValidationMessage password={values.newPassword} />
              </JATOGroup>
              <JATOButton
                type="submit"
                variant="primary"
                disabled={
                  isLoading ||
                  !!errors.oldPassword ||
                  !!errors.newPassword ||
                  !!errors.newPasswordConfirm
                }
              >
                {isLoading ? (
                  <JATOSpinner spinnerColor={JATOTheme.colors.white} />
                ) : (
                  translations.JNT_save
                )}
              </JATOButton>
            </Form>
          )}
        </Formik>
      </StyledMyPageChangePasswordModal>
    </CommonModal>
  )
}
