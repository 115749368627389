import { JATOVerticalSpacer } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { PhotoGallery } from 'components/PhotoGallery/PhotoGallery'
import { defaultNumberOfPhotos } from 'config'
import { countryCodeMapper } from 'helper/countryCodeMapper'
import { LoginResponse } from 'models/Login/LoginResponse'
import { VehiclePhotosRequest } from 'models/VehiclePhotos/VehiclePhotosRequest'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React from 'react'
import { useQuery } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import vehiclePhotosService from 'services/VehiclePhotos/VehiclePhotosService'

interface IVehiclePhotoGallery {
  mpVehicle: MpVehicle
}

export const MPVehiclePhotoGallery: React.FC<IVehiclePhotoGallery> = ({
  mpVehicle,
}: IVehiclePhotoGallery) => {
  const userData: LoginResponse = useAppSelector(selectUserData)
  const vehiclePhotosRequest: VehiclePhotosRequest = {
    make: mpVehicle.make,
    model: mpVehicle.model,
    market: `SSC${countryCodeMapper(
      userData.user.settings.lastSelectedMarket.countryCode
    )}_CS2002`,
    numberOfDoors: mpVehicle.numberOfDoors.toString(),
    bodyType: mpVehicle.bodyType,
    yearModel: mpVehicle.modelYear,
    isMonthlyPayments: true,
    numberOfPhotos: defaultNumberOfPhotos,
  }
  const { data: mpVehiclePhotosResponse, isLoading } = useQuery<string[]>(
    ['fetchVehiclePhotoGallery', mpVehicle],
    async () => {
      const { data } =
        await vehiclePhotosService.getVehiclesPhotos(vehiclePhotosRequest)
      return data
    }
  )

  return (
    <>
      {isLoading && <Loader />}
      {mpVehiclePhotosResponse && mpVehiclePhotosResponse.length > 0 && (
        <PhotoGallery
          id="monthlyPaymentsReportPhotoGallery"
          imageUrlList={mpVehiclePhotosResponse}
        />
      )}
      <JATOVerticalSpacer />
    </>
  )
}
