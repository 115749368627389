import { queryClient } from 'app'
import { Loader } from 'components/Loader'
import { useLogOutAll } from 'hooks/portal'
import { NewsLoginQueryKey } from 'hooks/volumes'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { resetCommonUserState } from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { resetMpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import { resetFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import {
  resetLoginStatus,
  selectUserData,
} from 'redux/monthlypayments/userDataSlice'
import {
  resetNewsFiltersToDefaults,
  resetNewsUserState,
} from 'redux/news/newsSlice'
import {
  resetFiltersToDefaults,
  resetSpecsUserState,
} from 'redux/specifications/specificationsSlice'
import { resetVolumesUserState } from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'

export const Logout: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const returnUrl = searchParams.get('returnUrl')
  const userData = useAppSelector(selectUserData)

  const { mutateAsync: logOutAllAsync } = useLogOutAll()

  useEffect(() => {
    const logOutAll = async (): Promise<void> => {
      await logOutAllAsync()
      dispatch(
        resetLoginStatus({
          isSuccess: false,
          user: {
            guid: '',
            userId: 0,
            usrgrpid: 0,
            userName: '',
            languageId: 0,
            settings: {
              id: '',
              lastSelectedMarket: { countryCode: '', countryName: '' },
              userId: 0,
              userGroupId: 0,
              lastAccessDate: undefined,
              updateDate: undefined,
            },
            mpLicensedCountries: [],
          },
          responseMessage: '0',
          accessTokenResponse: {
            accessToken: '',
            scope: '',
            expiresIn: 0,
            tokenType: '',
          },
        })
      )

      if (userData.user?.settings !== null) {
        dispatch(resetFilters(userData.user.settings.lastSelectedMarket))
      }

      dispatch(resetMpVehicles(false))
      queryClient.invalidateQueries({ queryKey: ['mpVehiclesResponse'] })

      //reset Specifications
      dispatch(resetFiltersToDefaults())
      dispatch(resetSpecsUserState())

      //reset News
      dispatch(resetNewsFiltersToDefaults())
      dispatch(resetNewsUserState())

      //reset News
      dispatch(resetNewsUserState())
      queryClient.invalidateQueries(NewsLoginQueryKey)

      //reset Volumes
      dispatch(resetVolumesUserState())

      //reset user state
      dispatch(resetCommonUserState())
      history.push(
        `${RoutePaths.Home}${returnUrl ? `?returnUrl=${returnUrl}` : ''}`
      )
    }

    logOutAll()
  }, [])

  return <Loader />
}
