import { useUpdatePageView } from 'hooks/news'
import { NewsPageViewType } from 'models/News/NewsPageViewType'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getNewsUserState } from 'redux/news/newsSlice'
import NewsIconButton from '../NewsIconButton'
import { StyledNewsPageViewSwitch } from './NewsPageViewSwitch.styles'

export const NewsPageViewSwitch: React.FC = () => {
  const newsUserState = useAppSelector(getNewsUserState)
  const { pageViewType } = newsUserState.userSpecificSettings

  const { mutate: updatePageView } = useUpdatePageView()

  return (
    <StyledNewsPageViewSwitch>
      <NewsIconButton
        id="newsCardsPageViewButton"
        iconName="baseline_apps"
        isActive={pageViewType === NewsPageViewType.Cards}
        onClick={() => updatePageView(NewsPageViewType.Cards)}
      />
      <NewsIconButton
        id="newsGridPageViewButton"
        iconName="baseline_list"
        isActive={pageViewType === NewsPageViewType.Grid}
        onClick={() => updatePageView(NewsPageViewType.Grid)}
      />
    </StyledNewsPageViewSwitch>
  )
}
