import { JATOLink, JATOText } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { useGetNewsArticleByArticleId } from 'hooks/news'
import { ArticleData } from 'models/VehicleNews/VehicleNewsResponse'
import NewsArticle from 'pages/NewsArticle'
import React from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  StyledSpecsQuadricycleNewsArticle,
  StyledSpecsQuadricycleNewsArticleLink,
} from './SpecsQuadricycleNewsArticleLink.styles'

interface ISpecsNewsArticleLinkProps {
  title: string
  request: ArticleData
}

export const SpecsQuadricycleNewsArticleLink: React.FC<
  ISpecsNewsArticleLinkProps
> = ({ title, request }: ISpecsNewsArticleLinkProps) => {
  const translations = useAppSelector(getTranslations)
  const commonUserData = useAppSelector(selectCommonUserData)
  const { data: articleResponse, isFetching } = useGetNewsArticleByArticleId({
    id: request.id,
    languageId: commonUserData.languageId,
  })

  const onOpen = (handleOpen: () => void): void => {
    handleOpen()
  }

  return (
    <StyledSpecsQuadricycleNewsArticleLink>
      <CommonModal
        maxWidth="lg"
        closeButton
        title={title}
        trigger={({ handleOpen }) => (
          <JATOLink
            id="specsReportQuadricycleNewsArticleLink"
            onClick={() => onOpen(handleOpen)}
          >
            {title}
          </JATOLink>
        )}
        primaryButtonName={translations.JNT_Close}
        onPrimaryClick={({ handleClose }) => handleClose()}
      >
        {isFetching ? (
          <Loader style={{ minHeight: '150px' }} />
        ) : articleResponse && articleResponse.article ? (
          <StyledSpecsQuadricycleNewsArticle>
            <NewsArticle articleId={articleResponse.article.id} />
          </StyledSpecsQuadricycleNewsArticle>
        ) : (
          <JATOText as="div" textAlign="center">
            {translations.JNT_SRCH_NoArticlesFound}
          </JATOText>
        )}
      </CommonModal>
    </StyledSpecsQuadricycleNewsArticleLink>
  )
}
