import {
  IMultiSelectFilter,
  MultiSelectFilter,
} from 'components/MonthlyPayments/Filters/MultiSelectFilter/MultiSelectFilter'
import { gtmLogSelectedValueChange } from 'helper/gtm'
import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import React from 'react'
import { GtmWrapper } from './GtmWrapper'

interface IGtmMultiSelectProps extends IMultiSelectFilter {
  id: string
}

export const MultiSelectWithGtm: React.FC<IGtmMultiSelectProps> = (
  props: IGtmMultiSelectProps
) => {
  const handleOnChangeInternal = (
    selectedOptions: ISelectedFilterOption[]
  ): void => {
    const { id, filterName, value, options, handleOnChange } = props
    const values = value ? value.split(',') : []

    const addedOption = selectedOptions.find((o) => !values.includes(o.value))

    if (addedOption) {
      const { value, displayValue } = addedOption
      gtmLogSelectedValueChange(id, displayValue, filterName, value, true)
    } else {
      const removedValue = values.find(
        (v) => !selectedOptions.some((o) => o.value === v)
      )
      const removedOption = options.find((o) => o.value === removedValue)

      if (removedOption) {
        const { value, displayValue } = removedOption
        gtmLogSelectedValueChange(id, displayValue, filterName, value, false)
      }
    }

    handleOnChange(selectedOptions)
  }

  return (
    <GtmWrapper skip>
      <MultiSelectFilter {...props} handleOnChange={handleOnChangeInternal} />
    </GtmWrapper>
  )
}
