import { JATOIcon, JATOTheme } from '@jato/ui-component-library'
import { StyledWelcomInfo as StyleMonthlyPaymentFaq } from 'components/WelcomeInfo/WelcomeInfo.styles'
import config from 'config'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

export const MonthlyPaymentsFaq: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const JATOSupport = translations.JNT_JATOSupport
  const DefinitionOfSpecifications = translations.JNT_DefinitionOfSpecifications
  const FAQ1 = translations.JNT_FAQ1
  const FAQ2 = translations.JNT_FAQ2
  const FAQ3 = translations.JNT_FAQ3
  const FAQ4 = translations.JNT_FAQ4
  const FAQ5 = translations.JNT_FAQ5
  const FAQ6 = translations.JNT_FAQ6
  const FAQAnswer1 = translations.JNT_FAQAnswer1
  const FAQAnswer2 = translations.JNT_FAQAnswer2
  const FAQAnswer3 = translations.JNT_FAQAnswer3
  const FAQAnswer4 =
    translations.JNT_FAQAnswer4 +
    `<a href="` +
    config.jatoSupportUrl +
    `"target="_blank"><strong><span style='font-family:"Roboto Light";color:#C40005;'>` +
    JATOSupport +
    `</span></strong></a>`

  const FAQAnswer5_1 = translations.JNT_FAQAnswer5_1

  const FAQAnswer5_2 = translations.JNT_FAQAnswer5_2

  const FAQAnswers5 =
    FAQAnswer5_1 +
    `<a href="` +
    config.jatoSupportUrl +
    `" target="_blank"><strong><span style='font-family:"Roboto Light";color:#C40005;'>` +
    JATOSupport +
    `</span></strong></a>` +
    FAQAnswer5_2

  const FAQAnswer6_1 = translations.JNT_FAQAnswer6_1

  const FAQAnswer6_2 = translations.JNT_FAQAnswer6_2

  const FAQAnswers6 =
    FAQAnswer6_1 +
    `<a href="` +
    config.jatoDefinitionOfSpecsUrl +
    `" target="_blank"><strong><span style='font-family:"Roboto Light";color:#C40005;'>` +
    DefinitionOfSpecifications +
    `</span></strong></a>` +
    FAQAnswer6_2

  function scrollTop(ElementId: string): void {
    document.getElementById(ElementId)?.scrollIntoView()
  }
  return (
    <>
      <StyleMonthlyPaymentFaq>
        <div
          className="modal-body"
          style={{ height: '400px', overflow: 'auto' }}
        >
          <hr className="margin0top margin5bottom" />

          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('10')}>
              {FAQ1}?
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('11')}>
              {FAQ2}?
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('12')}>
              {FAQ3}?
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('13')}>
              {FAQ4}?
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('14')}>
              {FAQ5}?
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('15')}>
              {FAQ6}
            </a>
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="10">
            <h4>
              <strong>
                <span className="custom-span">{FAQ1}?</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: FAQAnswer1 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="11">
            <h4>
              <strong>
                <span className="custom-span">{FAQ2}?</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: FAQAnswer2 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="12">
            <h4>
              <strong>
                <span className="custom-span">{FAQ3}?</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: FAQAnswer3 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="13">
            <h4>
              <strong>
                <span className="custom-span">{FAQ4}?</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: FAQAnswer4 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="14">
            <h4>
              <strong>
                <span className="custom-span">{FAQ5}?</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: FAQAnswers5 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="15">
            <h4>
              <strong>
                <span className="custom-span">{FAQ6}</span>
              </strong>
            </h4>
            <div
              dangerouslySetInnerHTML={{
                __html: FAQAnswers6,
              }}
            />
          </div>
        </div>
      </StyleMonthlyPaymentFaq>
    </>
  )
}
