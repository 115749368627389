import { gtmLogSpecsReportView } from 'helper/gtm'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getSpecsVehicles } from 'redux/specifications/specificationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { SpecificationsComparisonsReport } from './SpecificationsComparisonsReport'
import { SpecificationsOptionsReport } from './SpecificationsOptionsReport'
import { SpecificationsReportBody } from './SpecificationsReportBody'
import { SpecificationsReportFooter } from './SpecificationsReportFooter'
import { SpecificationsReportHeader } from './SpecificationsReportHeader'

export const SpecificationsReport: React.FC = () => {
  const history = useHistory()
  const { selectedVehicles } = useAppSelector(getSpecsVehicles)

  const vehiclesLength = selectedVehicles.length ?? 0
  if (vehiclesLength === 0) {
    history.push(RoutePaths.Specifications)
  }

  useEffect(() => {
    if (vehiclesLength > 0) {
      gtmLogSpecsReportView(selectedVehicles)
    }
  }, [])

  const isShowroom = location.pathname.includes(RoutePaths.SpecsShowroom)
  const isComparisons = location.pathname.includes(RoutePaths.SpecsComparisons)
  const isOptions = location.pathname.includes(RoutePaths.SpecsOptions)

  const minWidth = isShowroom
    ? vehiclesLength * 220 + 300
    : isOptions
      ? vehiclesLength * 220
      : isComparisons
        ? (vehiclesLength - 1) * 220
        : 0

  return (
    <div style={{ minWidth: `${minWidth}px` }}>
      <SpecificationsReportHeader />
      {isShowroom && <SpecificationsReportBody />}
      {isOptions && <SpecificationsOptionsReport />}
      {isComparisons && <SpecificationsComparisonsReport />}
      <SpecificationsReportFooter />
    </div>
  )
}
