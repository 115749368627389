import {
  mpFieldForCurrencyFormatting,
  mpFieldForDateFormatting,
  mpFieldForPercentageFormatting,
} from 'config'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import moment from 'moment'
import { formatCurrency } from './formatNumberValues'
import { getVehicleReportFilter } from './getVehicleReportFilter'

export const getTransalatedDataForMpReport = (
  mpReportMetaData: any,
  rowMetaData: string,
  data: string,
  value: string,
  countryCode: string
): string => {
  if (mpReportMetaData.metadata[rowMetaData] !== undefined) {
    if (rowMetaData === 'webSourceUrl') {
      data = '' // need to revisit it as it is long URL
    }

    if (mpFieldForDateFormatting.indexOf(rowMetaData) !== -1) {
      data = moment(Date.parse(value)).utcOffset(0, true).format('DD-MM-yyyy')
    }

    if (mpFieldForPercentageFormatting.indexOf(rowMetaData) !== -1) {
      if (data !== null) {
        data = `${data}%`
      }
    }

    if (mpFieldForCurrencyFormatting.indexOf(rowMetaData) !== -1) {
      if (data !== null) {
        data = formatCurrency(data, countryCode, 2)
      }
    }

    if (
      Object.keys(mpReportMetaData.metadata[rowMetaData].allowedValues).length >
      0
    ) {
      data = mpReportMetaData.metadata[rowMetaData].allowedValues[value]
    }
  }
  return data
}

export const getTransalatedDataForMpReportV2 = (
  mpReportMetaData: any,
  mpReportData: any,
  mpVehicle: MpVehicle,
  rowMetaData: string,
  countryCode: string
): string => {
  const validVehicleList = mpReportData.filter((response: any) =>
    getVehicleReportFilter(response, mpVehicle)
  )
  let data: any
  if (validVehicleList.length > 0) {
    if (validVehicleList[0].scenarios.length > 0) {
      data =
        validVehicleList[0].scenarios[0][rowMetaData] ??
        validVehicleList[0][rowMetaData]

      if (mpReportMetaData.metadata[rowMetaData] !== undefined) {
        if (rowMetaData === 'webSourceUrl') {
          data = '' // need to revisit it as it is long URL
        }

        if (mpFieldForDateFormatting.indexOf(rowMetaData) !== -1) {
          data = moment(Date.parse(data))
            .utcOffset(0, true)
            .format('DD-MM-yyyy')
        }

        if (mpFieldForPercentageFormatting.indexOf(rowMetaData) !== -1) {
          if ((typeof data === 'number' && data === 0) || data) {
            data = `${data}%`
          }
        }

        if (mpFieldForCurrencyFormatting.indexOf(rowMetaData) !== -1) {
          if ((typeof data === 'number' && data === 0) || data) {
            data = formatCurrency(data, countryCode, 2)
          }
        }

        if (
          Object.keys(mpReportMetaData.metadata[rowMetaData].allowedValues)
            .length > 0
        ) {
          data = mpReportMetaData.metadata[rowMetaData].allowedValues[data]
        }
      }
    }
  }

  return data
}
