import axios, { AxiosResponse } from 'axios'
import { trainingVideoUrl } from 'config'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'

class TrainingVideosService {
  public getTrainingVideoList(): Promise<
    AxiosResponse<TrainingVideoResponse[]>
  > {
    return axios.get(`${trainingVideoUrl}`)
  }
}

const trainingVideosService = new TrainingVideosService()
export default trainingVideosService
