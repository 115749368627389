import { PlayerConfig } from 'bitmovin-player'
import { IMappingObject } from 'helper/IMappingObject'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { GroupMetaData } from './GroupMetaData'

export const authRequired = true
export const basename = process.env.PUBLIC_URL
//https://jatonet-specs-dev.jato.com/api/
//https://jatonet-exportfiles.jato.com/api/
export const exportMicroserviceUrl = window.config.exportMicroservicePath

export const baseUrl = window.config.baseUrl
export const environment = window.config.environment
export const signalRUrl = `${window.config.baseUrl}/jato.volumes.api/excelExportHub`

export const baseSpecsAPIUrl = `${window.config.baseUrl}/jato.vehicle.services/api`

export const newCarSpecsBaseAPIUrl = `${window.config.baseUrl}/jatocarspecsapi/jato.specs`

export const mpbaseAPIUrlNew = window.config.mpbaseAPIUrlNew

//export const mpLatestNewsBaseUrl = `http://localhost:34257/jato.news.api`
export const mpLatestNewsBaseUrl = `${baseUrl}/jato.news.api/jato.news.api`
export const vehicleNewsArticleBaseUrl = `${baseUrl}/autologin`
export const portalUrl = `/jato.volumes.api/api`

let bitmovinPlayerLicenseKey

switch (environment) {
  case 'dev':
    bitmovinPlayerLicenseKey =
      process.env.REACT_APP_BITMOVIN_PLAYER_LICENSE_KEY_DEV
    break
  case 'qa':
    bitmovinPlayerLicenseKey =
      process.env.REACT_APP_BITMOVIN_PLAYER_LICENSE_KEY_QA
    break
  case 'live':
    bitmovinPlayerLicenseKey =
      process.env.REACT_APP_BITMOVIN_PLAYER_LICENSE_KEY_LIVE
    break
}

export const defaultPlayerConfig: PlayerConfig = {
  key: bitmovinPlayerLicenseKey || '',
}

export const splashScreenUrl = window.config.splashScreenUrl
export const technicalSupportUrl = window.config.technicalSupportUrl
export const jatoAcademyUrl = window.config.jatoAcademyUrl
export const knowledgeArticleSearchUrl = window.config.knowledgeArticleSearchUrl
export const knowledgeArticleVolumesReportUrl =
  window.config.knowledgeArticleVolumesReportUrl
export const knowledgeArticleVolumesModelMixNowcastUrl =
  window.config.knowledgeArticleVolumesModelMixNowcastUrl
export const mpKnowledgeArticleSearchUrl =
  window.config.mpKnowledgeArticleSearchUrl
export const newsKnowledgeArticleSearchUrl =
  window.config.newsKnowledgeArticleSearchUrl
export const openSupportCaseUrl = window.config.openSupportCaseUrl
export const salesEnquiryEmail = window.config.salesEnquiryEmail
export const dataDefinitionsUrl = window.config.dataDefinitionsUrl
export const mpJatoSalesEnquiryLink = window.config.mpJatoSalesEnquiryLink
export const jatoSupportUrl = window.config.jatoSupportUrl
export const jatoDefinitionOfSpecsUrl = window.config.jatoDefinitionOfSpecsUrl
export const azureAppInsightsSamplingPercentage =
  window.config.azureAppInsightsSamplingPercentage
export const azureAppInsightsCorrelationHeaderExcludedDomains =
  window.config.azureAppInsightsCorrelationHeaderExcludedDomains
export const azureAppInsightsEnableCustomExceptionsLogging: boolean =
  window.config.azureAppInsightsEnableCustomExceptionsLogging === 'true'
export const azureAppInsightsEnableCustomTraceLogging: boolean =
  window.config.azureAppInsightsEnableCustomTraceLogging === 'true'
export const newsNewBadgeEnabled: boolean =
  window.config.newsNewBadgeEnabled === 'true'
export const maxValuesClientSide = parseInt(window.config.maxValuesClientSide)
export const mpAlertsBetaStartDate = new Date(
  window.config.mpAlertsBetaStartDate
)
export const mpAlertsBetaEndDate = new Date(window.config.mpAlertsBetaEndDate)
export const mpAlertsBetaAnnouncementTimeoutMs = parseInt(
  window.config.mpAlertsBetaAnnouncementTimeoutMs
)
export const mpAlertsBetaAnnouncementDaysShowAfterEnd = parseInt(
  window.config.mpAlertsBetaAnnouncementDaysShowAfterEnd
)

export const rtlLanguageIds = window.config.rtlLanguageIds
export const rtlMarkets = window.config.rtlMarkets

export const newsV2LaunchDate = window.config.newsV2LaunchDate

export const numberOfAllowedAttempts = window.config.numberOfAllowedAttempts

export const termsAndConditionUrl = window.config.termsAndConditionUrl
export const privacyPolicyUrl = window.config.privacyPolicyUrl

export const trainingVideoUrl = `${baseUrl}/jato.news.api/jato.news.api/managetrainingvideo/getvideolist`

export const defaultMissingPhotoUrl =
  'https://sslphotos.jato.com/MissingPhotos/missingphoto_400.gif'
/*
// Use the following to set an environment dependant basename
export const basename = ['development', 'test'].includes(process.env.NODE_ENV)
  ? '' // Without namespacing in dev/test
  : '/spa' // With namespacing everywhere else
*/

// Use fluid layouts
export const fluid = false

export const mpVehicleSelectionLimit = 10
export const specsVehicleSelectionLimit = 20

export const defaultGridPageSize = 30

export const defaultMpSearchFilterCriteria = ''

export const defaultNumberOfPhotos = 40

//concludeDate eq 9999-12-31T00:00:00Z

export const mpReportSpecsSection = {
  title: 'Vehicle Specification',
  isOpen: false,
  metadataRowNames: [
    'uniqueIdentity',
    'versionAvailability',
    'dataDate',
    'modelYear',
    'make',
    'model',
    'versionName',
    'trimLevel',
    'bodyType',
    'numberOfDoors',
    'powertrainType',
    'fuelType',
    'transmissionDescription',
    'drivenWheels',
    'cc',
    'litres',
    'maximumPowerKw',
    'maximumPowerHp',
    'manufacturersCode',
    'jatoRegionalSegment',
    'concludeDate',
    'price',
    'basePrice',
    'onTheRoadPrice',
    'priceIncludingDelivery',
    'scenarios/regularMonthlyInstalmentAmountMsrp',
  ],
}

export const mpReportGroupingAndOrderLayout: GroupMetaData[] = [
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'Monthly Payment Description',
    isCategoryTitleRow: true,
    schemaId: 0,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'vehiclePriceReference',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'currency',
    isCategoryTitleRow: false,
    schemaId: 68404,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'productName',
    isCategoryTitleRow: false,
    schemaId: 68208,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'monthlyPaymentType',
    isCategoryTitleRow: false,
    schemaId: 68207,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'contractDurationMonths',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'numberOfMonthlyInstalments',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'yearlyMileageKm',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'yearlyMileageMiles',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'totalContractMileageKm',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'totalContractMileageMiles',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'startDate',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'endDate',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'researchDate',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'interestRate',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'rateType',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'depositPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'depositMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'additionalFeesMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'firstMonthlyInstalmentAmountMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'regularMonthlyInstalmentAmountMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: true,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'finalPaymentType',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'finalPaymentPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'finalPaymentMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'totalOperationPacksAmountMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'insurance',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'deliveryCostsMsrp',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'monthlyPaymentProviderType',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'Monthly Payment Description',
    metadataRowName: 'monthlyPaymentProviderName',
    isCategoryTitleRow: false,
    schemaId: 68215,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'ATL Incentives',
    isCategoryTitleRow: true,
    schemaId: 68408,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'oemDiscountPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68408,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'oemDiscountMsrp',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'governmentContributionPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'governmentContributionMsrp',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'downpaymentAllowancePercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'downpaymentAllowanceMsrp',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'totalDiscountPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'ATL Incentives',
    metadataRowName: 'totalDiscountMsrp',
    isCategoryTitleRow: false,
    schemaId: 68409,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'Calculated Costs',
    isCategoryTitleRow: true,
    schemaId: 68420,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'sourcedFinancedAmountPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68420,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'sourcedFinancedAmountMsrp',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'calculatedTbdPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'calculatedTbdMsrp',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalContractCostPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalContractCostMsrp',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalAmountOfInstalmentsPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalAmountOfInstalmentsMsrp',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalAmountOfCreditPercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalAmountOfCreditMsrp',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalAmountPayablePercentageOfPrice',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Calculated Costs',
    metadataRowName: 'totalAmountPayableMsrp',
    isCategoryTitleRow: false,
    schemaId: 68402,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'Data Sources',
    isCategoryTitleRow: true,
    schemaId: 68302,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'dataSource',
    isCategoryTitleRow: false,
    schemaId: 68302,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'jatoInternalSource',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'oemWebFinancialConfiguratorSource',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'captiveFinancialConfiguratorSource',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'nonCaptiveFinancialConfigSource',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'bankFinancialConfiguratorSource',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'otherSources',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'sourcedDealerQuote',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
  {
    categoryName: 'Data Sources',
    metadataRowName: 'mpDataOutOfDate',
    isCategoryTitleRow: false,
    schemaId: 68303,
    isStyleBold: false,
  },
]

export const hiddenMetaDataRowList = [
  'productDescription',
  'webSourceUrl',
  'mandatoryDealerContributionPercentagePrice',
  'mandatoryDealerContributionMsrp',
  'residualValuePercentageOfPrice',
  'residualValueMsrp',
]

const facetCount = 500

export const mpSearchFacetFields = [
  `make,count:${facetCount}`,
  `model,count:${facetCount}`,
  `makeModel,count:${facetCount}`,
  `modelYear,count:${facetCount}`,
  `jatoRegionalSegment,count:${facetCount}`,
  `numberOfDoors,count:${facetCount}`,
  `fuelType,count:${facetCount}`,
  `powertrainType,count:${facetCount}`,
  `bodyType,count:${facetCount}`,
  `transmissionDescription,count:${facetCount}`,
  `drivenWheels,count:${facetCount}`,
  `scenarios/contractDurationMonths,count:${facetCount}`,
  `scenarios/monthlyPaymentType,count:${facetCount}`,
  `scenarios/depositMsrp,count:1,sort:value`,
  `scenarios/numberOfMonthlyInstalments,count:${facetCount}`,
  `scenarios/regularMonthlyInstalmentAmountMsrp,count:1,sort:value`,
  `scenarios/productName,count:${facetCount}`,
  `scenarios/yearlyMileageKm,count:${facetCount}`,
  `scenarios/yearlyMileageMiles,count:${facetCount}`,
  `scenarios/totalContractMileageKm,count:${facetCount}`,
  `scenarios/totalContractMileageMiles,count:${facetCount}`,
  `scenarios/insurance,count:${facetCount}`,
  `concludeDate,count:${facetCount}`,
]

export const facetsForMonthlyPaymentFields = [
  `scenarios/contractDurationMonths,count:${facetCount}`,
  `scenarios/monthlyPaymentType,count:${facetCount}`,
  `scenarios/depositMsrp,count:1,sort:value`,
  `scenarios/numberOfMonthlyInstalments,count:${facetCount}`,
  `scenarios/regularMonthlyInstalmentAmountMsrp,count:1,sort:value`,
  `scenarios/productName,count:${facetCount}`,
  `scenarios/yearlyMileageKm,count:${facetCount}`,
  `scenarios/yearlyMileageMiles,count:${facetCount}`,
  `scenarios/totalContractMileageKm,count:${facetCount}`,
  `scenarios/totalContractMileageMiles,count:${facetCount}`,
  `scenarios/insurance,count:${facetCount}`,
]

export const additionalFacetsForSliders = [
  `scenarios/depositMsrp,count:1,sort:-value`,
  `scenarios/regularMonthlyInstalmentAmountMsrp,count:1,sort:-value`,
]

export const mpSelectVehicleFields = [
  'country',
  'dataDate',
  'make',
  'model',
  'makeModel',
  'modelYear',
  'uniqueIdentity',
  'jatoRegionalSegment',
  'numberOfDoors',
  'bodyType',
  'fuelType',
  'litres',
  'price',
  'basePrice',
  'onTheRoadPrice',
  'priceIncludingDelivery',
  'transmissionDescription',
  'drivenWheels',
  'vehicleId',
  'versionName',
  'versionAvailability',
  'scenarios/regularMonthlyInstalmentAmountMsrp',
]

export const mpCountryCodes = {
  France: 'FR',
  Germany: 'DE',
  GreatBritain: 'GB',
  Italy: 'IT',
  Spain: 'ES',
  Belgium: 'BE',
  TheNetherlands: 'NL',
}

export const customGridFields: IMappingObject = {
  bodyTypeDisplayValue: 'bodyType',
  fuelTypeDisplayValue: 'fuelType',
  drivenWheelsDisplayValue: 'drivenWheels',
  priceDisplayValue: 'price',
}

export const customGridFieldsReverse: IMappingObject = {
  bodyType: 'bodyTypeDisplayValue',
  fuelType: 'fuelTypeDisplayValue',
  drivenWheels: 'drivenWheelsDisplayValue',
  price: 'priceDisplayValue',
}

export const mpFieldForCurrencyFormatting = [
  'oemDiscountMsrp',
  'depositMsrp',
  'firstMonthlyInstalmentAmountMsrp',
  'regularMonthlyInstalmentAmountMsrp',
  'finalPaymentMsrp',
  'sourcedFinancedAmountMsrp',
  'totalContractCostMsrp',
  'totalAmountOfInstalmentsMsrp',
  'totalAmountOfCreditMsrp',
  'totalAmountPayableMsrp',
  'totalOperationPacksAmountMsrp',
  'totalDiscountMsrp',
  'residualValueMsrp',
  'calculatedTbdMsrp',
  'additionalFeesMsrp',
  'price',
  'basePrice',
  'onTheRoadPrice',
  'priceIncludingDelivery',
]

export const mpFieldForDateFormatting = [
  'researchDate',
  'startDate',
  'endDate',
  'dataDate',
  'concludeDate',
]

export const mpFieldForPercentageFormatting = [
  'depositPercentageOfPrice',
  'finalPaymentPercentageOfPrice',
  'mandatoryDealerContributionPercentagePrice',
  'oemDiscountPercentageOfPrice',
  'governmentContributionPercentageOfPrice',
  'downpaymentAllowancePercentageOfPrice',
  'sourcedFinancedAmountPercentageOfPrice',
  'totalContractCostPercentageOfPrice',
  'totalAmountOfInstalmentsPercentageOfPrice',
  'totalAmountOfCreditPercentageOfPrice',
  'totalAmountPayablePercentageOfPrice',
  'totalDiscountPercentageOfPrice',
  'residualValuePercentageOfPrice',
  'calculatedTbdPercentageOfPrice',
]

export const mpFieldForNumberFormatting = [
  'uniqueIdentity',
  'modelYear',
  'numberOfDoors',
]

export const translationtaglist = Object.keys(new AppTextTranslations())

// Default exports all constants
export default {
  authRequired,
  basename,
  customGridFields,
  customGridFieldsReverse,
  defaultMpSearchFilterCriteria,
  fluid,
  hiddenMetaDataRowList,
  facetsForMonthlyPaymentFields,
  baseSpecsAPIUrl,
  mpbaseAPIUrlNew,
  translationtaglist,
  mpLatestNewsBaseUrl,
  mpSearchFacetFields,
  mpSelectVehicleFields,
  vehicleNewsArticleBaseUrl,
  jatoSupportUrl,
  jatoDefinitionOfSpecsUrl,
  mpCountryCodes,
  mpFieldForCurrencyFormatting,
  mpFieldForDateFormatting,
  mpFieldForPercentageFormatting,
  mpReportGroupingAndOrderLayout,
  technicalSupportUrl,
  knowledgeArticleSearchUrl,
  openSupportCaseUrl,
}
