import { ExcelExportStyle } from 'helper/excelExport/excelExportHelper'

class MonthlyPaymentsExcelStyleService {
  private getHeader = (): any => ({
    name: ExcelExportStyle.Header,
    width: 65,
    height: 55,
    fontFamily: 'Arial',
    fontSize: 12,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getHeaderVersion = (): any => ({
    name: ExcelExportStyle.HeaderVersion,
    width: 65,
    height: 20,
    fontFamily: 'Arial',
    fontSize: 12,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
  })

  private getHeaderPrice = (countryCode: string): any => ({
    name: ExcelExportStyle.HeaderPrice,
    width: 65,
    height: 20,
    fontFamily: 'Arial',
    fontSize: 12,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
    numFmt: countryCode === 'GB' ? '£#,#' : '#,# €',
  })

  private getItemTitle = (): any => ({
    name: ExcelExportStyle.ItemTitle,
    width: 45,
    height: 28.5,
    fontFamily: 'Arial',
    fontSize: 10.5,
    fontColor: 'ffffff',
    bold: true,
    backgroundColor: '8e8e8e',
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: '',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
  })

  private getItem = (): any => ({
    name: ExcelExportStyle.Item,
    width: 65,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'center',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getItemForFullyEstimated = (): any => ({
    ...this.getItem(),
    name: ExcelExportStyle.FullyEstimatedItem,
    fontColor: 'A52A2A',
  })

  private getPercentageItem = (): any => ({
    ...this.getItem(),
    name: ExcelExportStyle.PercentageItem,
    numFmt: '0.0#%',
  })

  private getPercentageItemForFullyEstimated = (): any => ({
    ...this.getPercentageItem(),
    name: ExcelExportStyle.PercentageItemFullyEstimated,
    fontColor: 'A52A2A',
  })

  private getCurrencyItem = (countryCode: string): any => ({
    ...this.getItem(),
    name: ExcelExportStyle.CurrencyItem,
    numFmt: countryCode === 'GB' ? '£#,0.00' : '#,0.00 €',
  })

  private getCurrencyItemForFullyEstimated = (countryCode: string): any => ({
    ...this.getCurrencyItem(countryCode),
    name: ExcelExportStyle.CurrencyItemFullyEstimated,
    fontColor: 'A52A2A',
  })

  private getItemValue = (): any => ({
    name: ExcelExportStyle.ItemValue,
    width: 45,
    fontFamily: 'Arial',
    fontSize: 9,
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    backgroundColor: 'e6e6e6',
    alignment: {
      vertical: '',
      horizontal: '',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  public styles = (countryCode: string): any => [
    this.getHeader(),
    this.getHeaderVersion(),
    this.getHeaderPrice(countryCode),
    this.getItemTitle(),
    this.getItem(),
    this.getPercentageItem(),
    this.getCurrencyItem(countryCode),
    this.getItemValue(),
    this.getItemForFullyEstimated(),
    this.getCurrencyItemForFullyEstimated(countryCode),
    this.getPercentageItemForFullyEstimated(),
  ]
}

const monthlyPaymentsExcelStyleService = new MonthlyPaymentsExcelStyleService()
export default monthlyPaymentsExcelStyleService
