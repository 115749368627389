import {
  ColumnDirective,
  ColumnsDirective,
  DataStateChangeEventArgs,
  Grid,
  GridComponent,
  Inject,
  Resize,
  RowDataBoundEventArgs,
  RowDeselectEventArgs,
  RowSelectEventArgs,
  Selection,
  Sort,
  SortDescriptorModel,
  SortSettingsModel,
  Sorts,
} from '@syncfusion/ej2-react-grids'
import Pagination from 'components/Pagination'
import { specsVehicleSelectionLimit } from 'config'
import { IMappingObject } from 'helper/IMappingObject'
import {
  mapToOrderDirection,
  mapToSortDirection,
} from 'helper/sortDirectionMapper'
import {
  getColumnsToExclude,
  getSpecificationDisplayPrice,
} from 'helper/specificationsFiltersHelper'
import { useRemoveVehicle } from 'hooks/carSpecs'
import { VehicleSpecification } from 'models/Specifications/VehicleSpecification'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getSpecFilters,
  getSpecsUserState,
  getSpecsVehicles,
  setSpecFilters,
  setSpecsVehicles,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledSpecificationsGrid } from './SpecificationsGrid.styles'

export interface ISpecificationsGridProps {
  items: VehicleSpecification[]
  totalCount: number
}

export const SpecificationsGrid: React.FC<ISpecificationsGridProps> = (
  props: ISpecificationsGridProps
) => {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const specsUserState = useAppSelector(getSpecsUserState)
  const specFilters = useAppSelector(getSpecFilters)
  const specsVehicles = useAppSelector(getSpecsVehicles)
  const selectedVehiclesState = specsVehicles.selectedVehicles

  const { mutateAsync: removeVehicle } = useRemoveVehicle()

  let gridInstance: Grid | null
  const gridData = { result: props.items, count: props.totalCount }
  const totalPages = Math.ceil(props.totalCount / specFilters.pageSize)
  const currentPage = specFilters.pageSkip / specFilters.pageSize + 1

  useEffect(() => {
    if (gridInstance) {
      gridInstance.dataSource = { result: props.items, count: props.totalCount }
    }
  })

  const defaultColumnWidth = '80'
  const defaultColumnAlign = 'Left'
  const defaultClipMode = 'Clip'
  const columnDefinitions = [
    { field: 'vehicleId', isPrimaryKey: true, visible: false },
    { field: 'makeGlobal', headerText: translations.JNT_make },
    { field: 'modelGlobal', headerText: translations.JNT_model },
    { field: 'modelYear', headerText: translations.JNT_ModelYear },
    {
      field: 'derivativeGlobal',
      headerText: translations.JNT_Versions,
      width: '200',
    },
    { field: 'bodyCodeGlobal', headerText: translations.JNT_body },
    { field: 'transmission', headerText: translations.JNT_trans },
    { field: 'trimLevel', headerText: translations.JNT_trimlevel },
    { field: 'fuelType', headerText: translations.JNT_fueltype },
    { field: 'litres', headerText: translations.JNT_Enginelit },
    { field: 'maximumPowerKw', headerText: translations.JNT_PKW },
    { field: 'co2reading', headerText: translations.JNT_CO2 },
    {
      field: 'usaCo2reading',
      headerText: `${translations.JNT_CO2} (Annual Tons)`,
    },
    {
      field: 'wltpCombinedCo2Reading',
      headerText: translations.JNT_Wltp_Combined_CO2,
    },
    { field: 'drivenWheels', headerText: translations.JNT_Drivewheel },
    {
      field: 'priceToDisplay',
      headerText: getSpecificationDisplayPrice(
        specsUserState.settings.vehiclePriceSettings,
        translations
      ),
    },
  ]
  const columnsToExclude = getColumnsToExclude(
    specsUserState.settings.lastSelectedMarket ?? ''
  )
  const filteredColumnDefinitions = columnDefinitions.filter(
    (c) => !columnsToExclude.includes(c.field)
  )

  const sortColumns: IMappingObject = {
    priceToDisplay: 'basePrice',
    usaCo2reading: 'uSACo2reading',
  }

  const fieldToSortColumn = (fieldName?: string): string | undefined =>
    (fieldName && sortColumns[fieldName]) ?? fieldName

  const sortColumnToField = (sortColumn: string): string =>
    Object.keys(sortColumns).find(
      (fieldName) => sortColumns[fieldName] === sortColumn
    ) ?? sortColumn

  const mapToColumns = (orderByField: string): SortDescriptorModel => {
    const columnAndDirection = orderByField.split(' ')
    return {
      field: sortColumnToField(columnAndDirection[0]),
      direction: mapToSortDirection(columnAndDirection[1]),
    }
  }

  const mapSorted = (x: Sorts): string =>
    `${fieldToSortColumn(x.name)} ${mapToOrderDirection(x.direction)}`

  const sortSettings: SortSettingsModel = {
    columns: specFilters.orderBy.map(mapToColumns),
    allowUnsort: false,
  }

  const dataStateChange = (args: DataStateChangeEventArgs): void => {
    if (args.action?.requestType === 'sorting') {
      const orderBy = args.sorted?.map(mapSorted).reverse() ?? []
      const specFiltersUpdate = {
        ...specFilters,
        pageSkip: 0, //reset to first page when sorting changed
        orderBy: orderBy,
      }

      dispatch(setSpecFilters(specFiltersUpdate))
    }
  }

  const onPageChange = (page: number): void => {
    const specFiltersUpdate = {
      ...specFilters,
      pageSkip: (page - 1) * specFilters.pageSize,
    }

    dispatch(setSpecFilters(specFiltersUpdate))
  }

  const rowSelected = (row: RowSelectEventArgs): void => {
    if (row.isInteracted) {
      if (selectedVehiclesState.length + 1 === specsVehicleSelectionLimit) {
        gridInstance && gridInstance.refresh()
      }

      const vehicleData = row.data as VehicleSpecification
      const selectedVehicles = [...selectedVehiclesState, vehicleData]

      updateVehicles(selectedVehicles)
    }
  }

  const rowDeselected = async (row: RowDeselectEventArgs): Promise<void> => {
    if (row.isInteracted) {
      if (selectedVehiclesState.length === specsVehicleSelectionLimit) {
        gridInstance && gridInstance.refresh()
      }

      const vehicleData = row.data as VehicleSpecification
      const vehicleId = vehicleData.vehicleId
      const selectedVehicles = selectedVehiclesState.filter(
        (v) => v.vehicleId !== vehicleId
      )

      await removeVehicle(vehicleId)

      updateVehicles(selectedVehicles)
    }
  }

  const updateVehicles = (selectedVehicles: VehicleSpecification[]): void => {
    const benchmarkVehicleId =
      selectedVehicles.find(
        (v) => v.vehicleId === specsVehicles.benchmarkVehicleId
      )?.vehicleId ?? selectedVehicles.length > 0
        ? selectedVehicles[0].vehicleId
        : 0

    const vehiclesUpdate = {
      ...specsVehicles,
      benchmarkVehicleId,
      selectedVehicles,
    }

    dispatch(setSpecsVehicles(vehiclesUpdate))
  }

  const rowDataBound = (row: RowDataBoundEventArgs): void => {
    const vehicleData = row.data as VehicleSpecification

    if (
      selectedVehiclesState.length >= specsVehicleSelectionLimit &&
      !selectedVehiclesState.some((v) => v.vehicleId === vehicleData.vehicleId)
    ) {
      row.isSelectable = false
    }
  }

  const dataBound = (): void => {
    if (gridInstance) {
      const rowIndexes: number[] = []

      gridInstance.currentViewData.forEach((row, index) => {
        const vehicle = row as VehicleSpecification
        if (
          selectedVehiclesState.some((v) => v.vehicleId === vehicle.vehicleId)
        ) {
          rowIndexes.push(index)
        }
      })

      gridInstance.selectRows(rowIndexes)
    }
  }

  const setTooltip = (): void => {
    const header = document.querySelector('.e-gridheader')
    header?.setAttribute('title', translations.JNT_GridSortMoreClick)
  }

  const paginationComponent = (
    <Pagination
      id="specsGridPagination"
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
    />
  )

  return (
    <StyledSpecificationsGrid>
      <div className="ej-grid-header">
        <span id="multiVehicleResult" className="vehicleCountMessage">
          {translations.JNT_Result}: {props.totalCount}{' '}
          {translations.JNT_vehicles}
        </span>
      </div>
      {paginationComponent}
      <GridComponent
        id="specsGrid"
        ref={(g) => (gridInstance = g)}
        dataSource={gridData}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
        allowSorting={true}
        allowSelection={true}
        allowResizing={true}
        sortSettings={sortSettings}
        dataStateChange={dataStateChange}
        rowSelected={rowSelected}
        rowDeselected={rowDeselected}
        rowDataBound={rowDataBound}
        dataBound={dataBound}
        created={setTooltip}
      >
        <ColumnsDirective>
          <ColumnDirective type="checkbox" width="50" />
          {filteredColumnDefinitions.map((c, index) => (
            <ColumnDirective
              key={index}
              field={c.field}
              headerText={c.headerText}
              width={c.width ?? defaultColumnWidth}
              textAlign={defaultColumnAlign}
              clipMode={defaultClipMode}
              isPrimaryKey={c.isPrimaryKey ?? false}
              visible={c.visible ?? true}
            />
          ))}
        </ColumnsDirective>
        <Inject services={[Sort, Selection, Resize]} />
      </GridComponent>
      {paginationComponent}
    </StyledSpecificationsGrid>
  )
}
