import { JATOButtonIcon, JATOGroup } from '@jato/ui-component-library'
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
  Sort,
  TextAlign,
} from '@syncfusion/ej2-react-grids'
import Loader from 'components/Loader'
import VolumesCountryGroupCreateEdit from 'components/Volumes/VolumesCountryGroupCreateEdit'
import VolumesDatabaseTypeSelection from 'components/Volumes/VolumesDatabaseTypeSelection'
import { VolumesDeleteButton } from 'components/Volumes/VolumesDeleteButton/VolumesDeleteButton'
import VolumesNavigation from 'components/Volumes/VolumesNavigation'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useDeleteCountryGroup, useGetCountryGroups } from 'hooks/volumes'
import { CountryGroup } from 'models/Volumes/CountryGroup'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React, { useState } from 'react'
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledVolumesMyCountryGrouping } from './VolumesMyCountryGrouping.styles'

export const VolumesMyCountryGrouping: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const [databaseType, setDatabaseType] = useState(VolumesDatabaseType.ModelMix)
  const [countryGroupModel, setCountryGroupModel] = useState<CountryGroup>()

  const { data, isFetching } = useGetCountryGroups(databaseType)
  const { mutateAsync: deleteCountryGroup } = useDeleteCountryGroup()

  const defaultColumnWidth = '80'
  const defaultColumnAlign: TextAlign = 'Left'
  const centerColumnAlign: TextAlign = 'Center'
  const defaultClipMode = 'Clip'

  const queryCellInfo = (args: any): void => {
    const countryGroup = args.data as CountryGroup

    switch (args.column.field) {
      case 'deleteAction':
        render(deleteTemplate(countryGroup), args.cell)
        break
      case 'editAction':
        render(editTemplate(countryGroup), args.cell)
        break
    }
  }

  const onDeleteClick = async (countryGroup: CountryGroup): Promise<void> => {
    const isSuccess = await deleteCountryGroup(countryGroup.id!)
    if (isSuccess) {
      showSuccessToast(
        translations.JNT_Success,
        translations.JNT_DeletedSuccess
      )
    } else {
      showErrorToast(translations.JNT_Error, translations.JNT_Error)
    }
  }

  const deleteTemplate = (countryGroup: CountryGroup): JSX.Element => (
    <VolumesDeleteButton
      id="volumesMyCountryGroupingDeleteButton"
      item={countryGroup}
      deleteItem={onDeleteClick}
      name={countryGroup.name}
      removeMessage={translations.JNT_Volumes_Country_group_delete_confirmation}
      translations={translations}
    />
  )

  const editTemplate = (countryGroup: CountryGroup): JSX.Element => (
    <JATOButtonIcon
      id="volumesMyCountryGroupingEditButton"
      title={translations.JNT_Volumes_Edit}
      iconName="custom_edit"
      onClick={() => setCountryGroupModel(countryGroup)}
    />
  )

  const getFormattedDate = (_field: string, data: object): string => {
    const { created, modified } = data as CountryGroup
    switch (_field) {
      case 'created':
        return created ? new Date(created).toLocaleDateString('en-GB') : ''
      case 'modified':
        return modified ? new Date(modified).toLocaleDateString('en-GB') : ''
      default:
        return ''
    }
  }

  const columnDefinitions = [
    { field: 'id', isPrimaryKey: true, visible: false },
    {
      field: 'name',
      headerText: translations.JNT_Volumes_Country_group_name,
      width: '160',
    },
    {
      field: 'created',
      headerText: translations.JNT_qrycreate,
      valueAccessor: getFormattedDate,
    },
    {
      field: 'modified',
      headerText: translations.JNT_Volumes_MQ_DateModified,
      valueAccessor: getFormattedDate,
    },
    {
      field: 'deleteAction',
      headerText: translations.JNT_delete,
      textAlign: centerColumnAlign,
      allowSorting: false,
      width: '40',
    },
    {
      field: 'editAction',
      headerText: translations.JNT_Volumes_Edit,
      textAlign: centerColumnAlign,
      allowSorting: false,
      width: '40',
    },
  ]

  const onCreateClick = (): void => {
    const newCountryGroupModel: CountryGroup = {
      name: '',
      productGroup: databaseType,
      countries: [],
    }

    setCountryGroupModel(newCountryGroupModel)
  }

  return (
    <StyledVolumesMyCountryGrouping>
      <VolumesNavigation title={translations.JNT_Volumes_My_country_grouping} />
      {isFetching ? (
        <Loader style={{ minHeight: 'calc(100vh - 160px)' }} />
      ) : (
        <div className="volumesContainer">
          {countryGroupModel ? (
            <VolumesCountryGroupCreateEdit
              databaseType={databaseType}
              setDatabaseType={setDatabaseType}
              countryGroup={countryGroupModel}
              onClose={() => setCountryGroupModel(undefined)}
            />
          ) : (
            <>
              <JATOGroup flexDirection="row" className="headerPanel">
                <VolumesDatabaseTypeSelection
                  value={databaseType}
                  onChange={setDatabaseType}
                  isCountryGrouping={true}
                />
                <JATOButtonIcon
                  id="volumesMyCountryGroupingAddButton"
                  title={translations.JNT_Volumes_QIO_AddCTYGRP}
                  iconName="baseline_add_circle_outline"
                  onClick={onCreateClick}
                />
              </JATOGroup>
              <GridComponent
                dataSource={data}
                loadingIndicator={{ indicatorType: 'Shimmer' }}
                allowResizing={true}
                allowSelection={false}
                allowSorting={true}
                queryCellInfo={queryCellInfo}
              >
                <ColumnsDirective>
                  {columnDefinitions.map((c, index) => (
                    <ColumnDirective
                      key={index}
                      field={c.field}
                      headerText={c.headerText}
                      width={c.width ?? defaultColumnWidth}
                      textAlign={c.textAlign ?? defaultColumnAlign}
                      clipMode={defaultClipMode}
                      isPrimaryKey={c.isPrimaryKey ?? false}
                      visible={c.visible ?? true}
                      valueAccessor={c.valueAccessor}
                      allowSorting={c.allowSorting ?? true}
                    />
                  ))}
                </ColumnsDirective>
                <Inject services={[Resize, Sort]} />
              </GridComponent>
            </>
          )}
        </div>
      )}
    </StyledVolumesMyCountryGrouping>
  )
}
