import { Accordion } from 'components/Accordion/Accordion'
import { mpReportSpecsSection } from 'config'
import { GroupMetaData } from 'config/GroupMetaData'
import { getMonthlyPaymentDataGroupByCategoryName } from 'helper/getGroupedMetaDataRows'
import { getTransalatedDataForMpReport } from 'helper/getTransalatedDataForMpReport'
import { getVehicleReportFilter } from 'helper/getVehicleReportFilter'
import { LoginResponse } from 'models/Login/LoginResponse'
import { ReportParameters } from 'models/Reports/ReportParameters'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { StyledMonthlyPaymentsSceneriosBody } from './MonthlyPaymentsSceneriosBody.styles'

interface IMonthlyPaymentsReportBody {
  vehicles: MpVehicle[]
  mpReportMetaData: any
}

export const MonthlyPaymentsSceneriosBody: React.FC<
  IMonthlyPaymentsReportBody
> = ({ vehicles, mpReportMetaData }: IMonthlyPaymentsReportBody) => {
  const { uid, dataDate } = useParams<ReportParameters>()

  const rowsMetaData =
    vehicles && vehicles.length > 0
      ? getMonthlyPaymentDataGroupByCategoryName(
          vehicles.filter((vehicle: MpVehicle) =>
            getVehicleReportFilter(
              { uniqueIdentity: uid, dataDate: dataDate },
              vehicle
            )
          )
        )
      : undefined
  const userData: LoginResponse = useAppSelector(selectUserData)

  const getTranslatedAllowedDataValue = (
    value: string,
    rowMetaData: string
  ): string => {
    let data = value
    data = getTransalatedDataForMpReport(
      mpReportMetaData,
      rowMetaData,
      data,
      value,
      userData.user.settings.lastSelectedMarket?.countryCode
    )
    return data
  }

  const getTranslatedMetadata = (rowMetaData: string): string => {
    const data =
      mpReportMetaData.metadata[rowMetaData] !== undefined
        ? mpReportMetaData.metadata[rowMetaData].attribute
        : rowMetaData
    return data
  }

  const currentSpec: any = vehicles.filter((vehicle: MpVehicle) =>
    getVehicleReportFilter({ uniqueIdentity: uid, dataDate: dataDate }, vehicle)
  )[0]

  const specDataRows = mpReportSpecsSection.metadataRowNames
    .map((metadataRowName: string) => ({
      label: getTranslatedMetadata(metadataRowName),
      displayValues: currentSpec.scenarios.map(() =>
        getTranslatedAllowedDataValue(
          currentSpec[metadataRowName],
          metadataRowName
        )
      ),
    }))
    .filter((row) => row.displayValues.some((val: any) => val != null))

  return (
    <StyledMonthlyPaymentsSceneriosBody>
      <Accordion
        id={
          'monthlyPaymentsReportScenarios' +
          mpReportSpecsSection.title.replace(/ /g, '') +
          'Accordion'
        }
        titleText={mpReportSpecsSection.title}
        isOpen={mpReportSpecsSection.isOpen}
        titleHeaderWidth="95vw"
      >
        <table className="tableStyle">
          <tbody>
            {specDataRows.map((specDataRow, index) => (
              <tr className="tableRow" key={index}>
                <td key={index} className="tableCell sticky">
                  <div style={{ paddingLeft: '30px' }}>{specDataRow.label}</div>
                </td>
                {specDataRow.displayValues.map(
                  (displayValue: string, index: number) => (
                    <td key={index} className="tableCell">
                      <div className="tableValue">{displayValue}</div>
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      {vehicles &&
        vehicles.length > 0 &&
        rowsMetaData &&
        rowsMetaData.map((groupMetaDataRow: any, index: number) => (
          <Accordion
            id={
              'monthlyPaymentsReportScenarios' +
              groupMetaDataRow.key.replace(/ /g, '') +
              'Accordion'
            }
            titleText={groupMetaDataRow.key}
            key={index}
            isOpen={true}
            titleHeaderWidth="95vw"
          >
            <table className="tableStyle" key={index}>
              <tbody>
                {groupMetaDataRow.value
                  .filter((data: GroupMetaData) => !data.isCategoryTitleRow)
                  .map((rowData: GroupMetaData, index: number) => (
                    <tr className="tableRow" key={index}>
                      <td
                        key={index}
                        className="tableCell sticky"
                        style={{
                          fontWeight: rowData.isStyleBold ? 600 : 400,
                        }}
                      >
                        <div style={{ paddingLeft: '30px' }}>
                          {getTranslatedMetadata(rowData.metadataRowName)}
                        </div>
                      </td>
                      {currentSpec.scenarios.map(
                        (scenario: any, index: number) => (
                          <td
                            key={index}
                            className="tableCell"
                            style={{
                              fontWeight: rowData.isStyleBold ? 600 : 400,
                            }}
                          >
                            <div
                              style={{
                                color:
                                  scenario['dataSource'] === 'F'
                                    ? 'brown'
                                    : 'black',
                              }}
                              className="tableValue"
                            >
                              {getTranslatedAllowedDataValue(
                                scenario[rowData.metadataRowName] as string,
                                rowData.metadataRowName
                              )}
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </Accordion>
        ))}
    </StyledMonthlyPaymentsSceneriosBody>
  )
}
