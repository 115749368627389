export const EU28PlusWltpCountries = [
  'SSCA_CS2002',
  'NSCA_CS2002',
  'SSCGB_CS2002',
  'NSCGB_CS2002',
  'SSCB_CS2002',
  'NSCB_CS2002',
  'SSCHR_CS2002',
  'NSCHR_CS2002',
  'SSCCZ_CS2002',
  'NSCCZ_CS2002',
  'SSCDK_CS2002',
  'NSCDK_CS2002',
  'SSCEE_CS2002',
  'NSCEE_CS2002',
  'SSCSF_CS2002',
  'NSCSF_CS2002',
  'SSCF_CS2002',
  'NSCF_CS2002',
  'SSCD_CS2002',
  'NSCD_CS2002',
  'SSCGR_CS2002',
  'NSCGR_CS2002',
  'SSCH_CS2002',
  'NSCH_CS2002',
  'SSCIRL_CS2002',
  'NSCIRL_CS2002',
  'SSCI_CS2002',
  'NSCI_CS2002',
  'SSCLV_CS2002',
  'NSCLV_CS2002',
  'SSCLT_CS2002',
  'NSCLT_CS2002',
  'SSCLUX_CS2002',
  'NSCLUX_CS2002',
  'SSCNL_CS2002',
  'NSCNL_CS2002',
  'SSCPL_CS2002',
  'NSCPL_CS2002',
  'SSCP_CS2002',
  'NSCP_CS2002',
  'SSCRO_CS2002',
  'NSCRO_CS2002',
  'SSCSK_CS2002',
  'NSCSK_CS2002',
  'SSCSLO_CS2002',
  'NSCSLO_CS2002',
  'SSCE_CS2002',
  'NSCE_CS2002',
  'SSCS_CS2002',
  'NSCS_CS2002',
  'SSCCH_CS2002',
  'NSCCH_CS2002',
  'SSCN_CS2002',
  'NCSN_CS2002',
  'SSCRK_CS2002',
  'NSCRK_CS2002',
  'SSCJ_CS2002',
  'NSCJ_CS2002',
  'SSCIND_CS2002',
  'NSCIND_CS2002',
  'SSCPRC_CS2002',
  'NSCPRC_CS2002',
]

export const CO2TonnesCountryList = ['SSCUSA_CS2002', 'SSCPRI_CS2002']
