import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { ReportPageType } from 'router/RoutePaths'

export const getOptionsTitleText = (page: string): string => {
  const translations = useAppSelector(getTranslations)

  switch (page) {
    case ReportPageType.Options:
      return translations.JNT_optionstext
    case ReportPageType.Packages:
      return translations.JNT_packages
    case ReportPageType.Colors:
      return translations.JNT_colors
    default:
      return translations.JNT_baseoptions
  }
}

export const getNoOptionsText = (optionsPage: string): string => {
  const translations = useAppSelector(getTranslations)

  switch (optionsPage) {
    case ReportPageType.Packages:
      return translations.JNT_NoPackage
    case ReportPageType.Colors:
      return translations.JNT_NoColor
    default:
      return translations.JNT_notavailable
  }
}
