import { Loader } from 'components/Loader'
import { useAddRecipeAndUpdateBrochure } from 'hooks/carSpecs'
import { useGetBaskets } from 'hooks/specsBasketManagement'
import {
  BasketVehicle,
  MarketInfo,
} from 'models/Specifications/Basket/BasketInfo'
import { ISpecsUserState } from 'models/Specifications/GetLicenceInfoResponse'
import {
  DetailedAction,
  InstructionType,
} from 'models/Specifications/Options/DetailedAction'
import { VehicleSpecification } from 'models/Specifications/VehicleSpecification'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  setSpecsUserState,
  getSpecsVehicles,
  setSpecsVehicles,
} from 'redux/specifications/specificationsSlice'
import { RoutePaths } from 'router/RoutePaths'

export const BasketLoad: React.FC = () => {
  const dispatch = useAppDispatch()
  const { basketId } = useParams<{ basketId: string }>()
  const specsUserData = useAppSelector(getSpecsUserState)
  const specsVehicles = useAppSelector(getSpecsVehicles)
  const history = useHistory()
  const { data: baskets, isStale } = useGetBaskets()
  const { mutateAsync: addRecipeAndUpdateBrochure } =
    useAddRecipeAndUpdateBrochure()

  useEffect(() => {
    const basket = baskets?.find((b) => b.id === basketId)

    if (basket && !isStale) {
      const updateUserState: ISpecsUserState = {
        ...specsUserData,
        settings: {
          ...specsUserData.settings,
          lastSelectedMarket: basket.markets[0].databaseName,
        },
      }

      const detailedActionList = basket.detailedActionOptionBuildInfoList ?? []
      const basketVehicleOptionPromises: Promise<boolean>[] =
        detailedActionList.map((detailedAction: DetailedAction) =>
          addRecipeAndUpdateBrochure(detailedAction)
        )

      const setBenchmarkRecipePromise = addRecipeAndUpdateBrochure({
        vehicleId: basket.benchmarkVehicleId,
        databaseName: basket.markets[0].databaseName,
        userInstruction: InstructionType.SetBenchmark,
      })

      const benchmarkVehicleId = basket.benchmarkVehicleId
      const basketVehicles = basket.markets.flatMap((market: MarketInfo) =>
        market.vehicles.map((basketVehicle: BasketVehicle) => ({
          vehicleId: basketVehicle.vehicleId,
          vehicleUid: basketVehicle.vehicleUid,
          makeGlobal: basketVehicle.makeGlobal,
          modelGlobal: basketVehicle.modelGlobal,
          derivativeGlobal: basketVehicle.derivativeGlobal,
        }))
      )

      const addVehiclesRecipesPromises = basketVehicles.map(
        (vehicle: VehicleSpecification) =>
          addRecipeAndUpdateBrochure({
            vehicleId: vehicle.vehicleId,
            databaseName: basket.markets[0].databaseName,
            userInstruction: InstructionType.AddVehicle,
          })
      )

      Promise.all([
        ...basketVehicleOptionPromises,
        setBenchmarkRecipePromise,
        ...addVehiclesRecipesPromises,
      ]).then(() => {
        const vehiclesUpdate = {
          ...specsVehicles,
          benchmarkVehicleId,
          selectedVehicles: basketVehicles,
        }

        dispatch(setSpecsVehicles(vehiclesUpdate))
        dispatch(setSpecsUserState(updateUserState))

        history.push(`${RoutePaths.SpecsShowroom}/?basketId=${basketId}`)
      })
    }
  }, [baskets])

  return <Loader />
}
