import styled from 'styled-components'

export const StyledNews = styled.div`
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;

  .newsCarouselLoader {
    width: calc(75vw - 40.25px);
    min-height: 377px;
    grid-column: 1 / span 3;
  }

  .topStoryLoader {
    max-width: calc(25vw - 26.75px);
    min-height: 377px;
  }

  .mainLoader {
    min-height: 100%;
    grid-column: 1 / span 4;
    margin-top: 20px;
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 5px 0;
  }

  .topStory {
    grid-column-start: 4;
  }

  .latestNewsHeading {
    grid-column: 1 / span 4;
  }
`
