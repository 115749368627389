import React from 'react'
import { StyledCustomerSupport } from './CustomerSupport.styles'
import {
  JATOAnnouncement,
  JATOGroup,
  JATOTextInput,
  JATOText,
  JATOTheme,
  JATOSelect,
  JATOTextarea,
  JATOButton,
} from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { useFormik } from 'formik'
import {
  useLoadSupportInfoData,
  useSubmitCustomerSupportQuery,
} from 'hooks/portal'
import { CountryState } from 'models/Portal/CustomerRegistration/CountryState'
import { RegisterInfor } from 'models/Portal/CustomerRegistration/RegistorInfr'
import moment from 'moment'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import * as Yup from 'yup'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { CustomerSupportRequest } from 'models/Portal/CustomerSupport/CustomerSupportModel'
import { Container, Row, Col } from 'react-grid-system'
import { JATOFormScreen } from './JATOFormScreen'

export interface ICustomerSupportProps {
  onClose: () => void
}

export const CustomerSupport: React.FC<ICustomerSupportProps> = ({
  onClose,
}: ICustomerSupportProps) => {
  const translations = useAppSelector(getTranslations)
  const { mutateAsync: submitCustomerSupport } = useSubmitCustomerSupportQuery()

  const { data: customerSupportInfoData, isLoading } = useLoadSupportInfoData()

  const formik = useFormik<CustomerSupportRequest>({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      companyCountry: '',
      companyName: '',
      title: '',
      telNumber: '',
      reqDate: moment().format('YYYY-MM-DD'),
      problem: '',
      browser: '',
      os: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(translations.JNT_Portal_Form_Field_Required),
      firstName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      lastName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      emailAddress: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .email(translations.JNT_Portal_Form_Email_Field_Required),
      companyName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      companyCountry: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      browser: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      os: Yup.string().required(translations.JNT_Portal_Form_Field_Required),
      telNumber: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .test(
          'PhoneCheck',
          translations.JNT_Portal_Invalid_Phone_Number,
          function (value) {
            return isValidPhoneNumber(value)
          }
        ),
      problem: Yup.string()
        .max(600, ({ max }) => `only ${max} characters allowed`)
        .required(translations.JNT_Portal_Form_Field_Required),
    }),
    onSubmit: async (values: CustomerSupportRequest) => {
      const customerSupportResponse = await submitCustomerSupport(values)
      if (customerSupportResponse.isCustomerSupportSuccess) {
        JATOAnnouncement.Create({
          title: '',
          children: (
            <div
              dangerouslySetInnerHTML={{
                __html: customerSupportResponse.responseMessage,
              }}
            />
          ),
        })
        onClose()
      }
    },
  })

  return (
    <StyledCustomerSupport>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Container xl lg md sm xs style={{ padding: '0px 10px' }}>
            <Row>
              <Col md={3} lg={3} style={{ padding: '0px 0px' }}>
                <JATOFormScreen />
              </Col>
              <Col md={9} lg={9} style={{ paddingLeft: 20 }}>
                {formik.isSubmitting && <LoaderModal isOpen={true} />}
                <div
                  style={{ paddingBottom: '30px' }}
                  dangerouslySetInnerHTML={{
                    __html: translations.JNT_Portal_Support_Desc,
                  }}
                />
                <form onSubmit={formik.handleSubmit}>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="firstName"
                        name="firstName"
                        label={translations.JNT_Portal_CustReg_Name}
                        title={translations.JNT_Portal_CustReg_Name}
                        type="text"
                        width={400}
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.firstName}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOTextInput
                        id="lastName"
                        name="lastName"
                        label={translations.JNT_Portal_CustReg_Surname}
                        title={translations.JNT_Portal_CustReg_Surname}
                        type="text"
                        width={400}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.lastName}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="emailAddress"
                        name="emailAddress"
                        label={translations.JNT_Portal_CustReg_Email}
                        title={translations.JNT_Portal_CustReg_Email}
                        type="text"
                        width={400}
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.emailAddress &&
                      formik.errors.emailAddress ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.emailAddress}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOSelect
                        id="companyCountry"
                        label={translations.JNT_Portal_CustReg_Cntry}
                        dataSrc={
                          customerSupportInfoData?.countries
                            ? customerSupportInfoData?.countries.map(
                                (l: CountryState) => ({
                                  value: l.countryCode,
                                  displayValue: l.countryName,
                                })
                              )
                            : []
                        }
                        value={formik.values.companyCountry}
                        onChange={(value) =>
                          formik.setFieldValue('companyCountry', value)
                        }
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="400px"
                      />
                      {formik.touched.companyCountry &&
                      formik.errors.companyCountry ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyCountry}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="companyName"
                        name="companyName"
                        label={translations.JNT_Portal_Sales_Company}
                        title={translations.JNT_Portal_Sales_Company}
                        type="text"
                        width={400}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyName}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOTextInput
                        id="title"
                        name="title"
                        label={translations.JNT_Portal_Sales_JobTitle}
                        title={translations.JNT_Portal_Sales_JobTitle}
                        type="text"
                        width={400}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.title && formik.errors.title ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.title}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>

                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOSelect
                        id="browser"
                        label={translations.JNT_Portal_Support_Browser}
                        dataSrc={
                          customerSupportInfoData?.browser
                            ? customerSupportInfoData?.browser.map(
                                (l: RegisterInfor) => ({
                                  value: l.description,
                                  displayValue: l.description,
                                })
                              )
                            : []
                        }
                        value={formik.values.browser}
                        onChange={(value) =>
                          formik.setFieldValue('browser', value)
                        }
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="400px"
                      />
                      {formik.touched.browser && formik.errors.browser ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.browser}
                        </JATOText>
                      ) : null}
                    </div>

                    <div>
                      <JATOSelect
                        id="os"
                        label={translations.JNT_Portal_Support_Os}
                        dataSrc={
                          customerSupportInfoData?.os
                            ? customerSupportInfoData?.os.map(
                                (l: RegisterInfor) => ({
                                  value: l.description,
                                  displayValue: l.description,
                                })
                              )
                            : []
                        }
                        value={formik.values.os}
                        onChange={(value) => formik.setFieldValue('os', value)}
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="400px"
                      />
                      {formik.touched.os && formik.errors.os ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.os}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <div className="label">
                        {translations.JNT_Portal_CustReg_TelNumber}
                      </div>

                      <PhoneInput
                        international
                        id="telNumber"
                        name="telNumber"
                        placeholder="Enter phone number"
                        defaultCountry="GB"
                        value={formik.values.telNumber}
                        type="text"
                        style={{ width: '400px' }}
                        onChange={(e) => formik.setFieldValue('telNumber', e)}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.telNumber && formik.errors.telNumber ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          display="flex"
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.telNumber}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>

                  <JATOGroup flexDirection="row">
                    <div style={{ width: '100%' }}>
                      <JATOTextarea
                        id="problem"
                        name="problem"
                        rows={3}
                        label={translations.JNT_Portal_Sales_OtherRequest}
                        title={translations.JNT_Portal_Sales_OtherRequest}
                        value={formik.values.problem}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.problem && formik.errors.problem ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.problem}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup
                    flexDirection="row"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <JATOButton
                      id="btnTechnicalSupport"
                      size="large"
                      variant="primary"
                      type="submit"
                      disabled={!(formik.dirty && formik.isValid)}
                    >
                      {translations.JNT_Portal_Sales_btnSubmit}
                    </JATOButton>
                  </JATOGroup>
                </form>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </StyledCustomerSupport>
  )
}
