import {
  JATOButton,
  JATODropdown,
  JATODropdownMenu,
} from '@jato/ui-component-library'
import basketIcon from 'assets/icons/icon_basket_fill.svg'
import { ImageButtonNavItem } from 'components/CommonNavItems/ImageButtonNavItem/ImageButtonNavItem'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getSpecsVehicles } from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { BasketsModal } from './BasketsModal'
import { SaveBasketModal } from './SaveBasketModal'

export const BasketNavItem: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const specsVehicles = useAppSelector(getSpecsVehicles)
  const [showBasketsModal, setShowBasketsModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const basketId = searchParams.get('basketId') ?? undefined

  const saveDisabled = specsVehicles.selectedVehicles.length === 0
  const saveTooltip = saveDisabled ? 'No vehicles selected' : ''

  return (
    <>
      <JATODropdown
        trigger={() => (
          <ImageButtonNavItem
            id="specsBasketMenuButton"
            src={basketIcon}
            title={translations.JNT_UserBasket}
          />
        )}
      >
        <JATODropdownMenu>
          <JATOButton
            id="specsBasketMenuOpenButton"
            size="medium"
            onClick={() => setShowBasketsModal(true)}
          >
            {translations.JNT_Open}
          </JATOButton>
          <JATOButton
            id="specsBasketMenuSaveButton"
            size="medium"
            onClick={() => setShowSaveModal(true)}
            disabled={saveDisabled}
            title={saveTooltip}
          >
            {translations.JNT_save}
          </JATOButton>
        </JATODropdownMenu>
      </JATODropdown>
      <BasketsModal
        isOpen={showBasketsModal}
        onClose={() => setShowBasketsModal(false)}
      />
      {showSaveModal && (
        <SaveBasketModal
          basketId={basketId}
          isRename={false}
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
        />
      )}
    </>
  )
}
