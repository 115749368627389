import styled from 'styled-components'

export const StyledNewsWelcomePopupVideo = styled.div`
  width: 325px;

  .bmpui-ui-watermark {
    display: none;
  }
`
export const LogoVideosLoader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 25vh;
`
