import styled from 'styled-components'

export const StyledNewsDateRangePicker = styled.div`
  .dateRangePicker > span {
    width: 296px;
    border: 1px solid #f0f0f0 !important;
    margin: 8px 0 0;
    padding: 6px 8px 6px 16px;
    font-size: 14px;
  }
`
