import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultGridPageSize } from 'config'
import { buildCombinedAdvancedFilters } from 'helper/AdvancedFilters/advanceFiltersHelper'
import { gtmLogMarketChange } from 'helper/gtm'
import { IMappingObject } from 'helper/IMappingObject'
import {
  IAdvancedFilterOption,
  IFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import { IAdvancedFilterModel } from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import {
  BuiltOptionsInfo,
  BuiltVehicle,
  WltpErrorMessage,
} from 'models/Specifications/GetBuiltVehiclesResponse'
import { ISpecsUserState } from 'models/Specifications/GetLicenceInfoResponse'
import { EbrochureItemWithLocation } from 'models/Specifications/GetWltpRequest'
import { Recipe } from 'models/Specifications/Options/OptionsBuildRequest'
import {
  ICurrencyInfo,
  ICurrencyLanguageInfo,
  ILanguageInfo,
} from 'models/Specifications/SpecsUserSettings'

import { VehicleSpecification } from 'models/Specifications/VehicleSpecification'
import { RootState } from 'redux/store'

interface SelectedFilters {
  pageSize: number
  pageSkip: number
  orderBy: string[]
  filters: IMappingObject<ISelectedFilterOption[]>
  cachedOptions: IFilterOption[]
}

interface AdvancedFiltersState {
  lastSelectedOptionFilterType: number
  filters: IMappingObject<IAdvancedFilterOption[]>
}

export interface VehiclesState {
  benchmarkVehicleId: number
  selectedVehicles: VehicleSpecification[]
  builtVehicles: BuiltVehicle[]
  wltpErrors: WltpErrorMessage[]
  displayBuiltOptions: BuiltOptionsInfo[]
  optionBuildRecipes: Recipe[]
  wltpBrochureItems: EbrochureItemWithLocation[]
}

interface SpecificationsState {
  selectedFilters: SelectedFilters
  userState: ISpecsUserState
  vehicles: VehiclesState
  currencyLanguageInfo: ICurrencyLanguageInfo
  advancedFilters: AdvancedFiltersState
}

const defaultSelectedFilters = {
  pageSize: defaultGridPageSize,
  pageSkip: 0,
  orderBy: ['makeGlobal asc', 'modelGlobal asc', 'basePrice asc'],
  filters: {},
  cachedOptions: [],
}

const defaultUserState = {
  guid: '',
  userId: 0,
  usrgrpid: 0,
  userName: '',
  languageId: 0,
  specsDbMarkets: [],
  jatoCompanyId: 0,
  v5ContractId: '',
  isQuadricycleCustomer: false,
  settings: {
    id: '',
    userId: 0,
    userGroupId: 0,
    isLocalOptions: false,
    vehiclePriceSettings: {
      isRetailPrice: false,
      isBasePrice: false,
      isCountrySpecificPrice: true,
      isCountrySpecificPrice2: false,
      isRetailPriceinclDelivery: false,
    },
    useImperialProjectDb: false,
    excludeEmptyPrice: false,
    builtVehicles: false,
    outgoingModelYears: false,
    createdOn: new Date(),
    updatedOn: new Date(),
    currencyCode: '',
    lastSelectedMarket: '',
    wltpValidMarketList: [],
  },
  carspecsDefaultSetting: {
    companyId: 0,
    specsDatabaseId: '',
    languageId: 0,
    currencyId: 0,
    currencyCode: '',
    projectDatabaseId: '',
    vehiclePriceId: '',
    optionPriceId: '',
    modelYearDigits: '',
    countryId: '',
    vehicleNameDisplayOptionId: '',
    showFractionalModelYears: false,
    isLocalMakeModelVersionName: false,
    isHistoricalDatabase: false,
    isMultiCountryDatabase: false,
    authenticateUserBy: '',
    includeAdvance: false,
    includeOutgoing: false,
    includePreBuilt: false,
    showSegments: false,
    allowQuotes: false,
    quoteRedirectUrl: '',
    allowCompEqWithOptions: false,
    portrait: false,
    wlcFuel: false,
    wlcFuelV2: false,
    wlcTax: false,
    wlcInterest: false,
    wlcAnalysis: false,
    wlcLandingPage: false,
    wlcDepreciation: false,
    wlcComparationPage: false,
    wlcIsEnablePurchaseTax: false,
    wlcCustomPrice: false,
    transmissionSchemaId: 0,
    showClickThroughLicense: false,
    clickThroughLicenseUrl: '',
    exportEmail: false,
    numericalDifferences: false,
    showPriceInOptionWindow: false,
    saveCacheFilter: false,
    showOptionsInPdf: false,
    isInSalesmanMode: false,
    customerDefaultLanguageId: 0,
    useCustomerTranslatedCategory: false,
    useImprovedOptions: false,
    useSimpleOptionBuildCheapest: false,
    useSimpleOptionBuildExpensive: false,
    useImprovedCompareEquip: false,
    showCompetitorAdvantages: false,
    showHiddenCompetitorAdvantages: false,
    showSearchByJatoUid: false,
    tabularVersions: false,
    hideSmartAdvantages: false,
    useExportMicroServices: false,
    expandedCategoriesByDefault: false,
    enableAutoRefreshForSearchByFeatures: false,
    quoteRedirectUrlAliases: [],
    surveyKey: '',
    photoTrimLevel: false,
    photoVersionLevel: false,
  },
}

const defaultCurrencyLanguageInfo = {
  currencies: [],
  languages: [],
}

const defaultAdvancedFilters: AdvancedFiltersState = {
  lastSelectedOptionFilterType: 0,
  filters: {},
}

const defaultVehiclesState: VehiclesState = {
  benchmarkVehicleId: 0,
  selectedVehicles: [],
  builtVehicles: [],
  wltpBrochureItems: [],
  wltpErrors: [],
  displayBuiltOptions: [],
  optionBuildRecipes: [],
}

const initialState: SpecificationsState = {
  selectedFilters: defaultSelectedFilters,
  userState: defaultUserState,
  vehicles: defaultVehiclesState,
  currencyLanguageInfo: defaultCurrencyLanguageInfo,
  advancedFilters: defaultAdvancedFilters,
}

export const specificationsSlice = createSlice({
  name: 'specifications',
  initialState,
  reducers: {
    setSpecFilters: (state, { payload }: PayloadAction<SelectedFilters>) => ({
      ...state,
      selectedFilters: payload,
    }),
    resetFiltersToDefaults: (state) => ({
      ...state,
      selectedFilters: defaultSelectedFilters,
      advancedFilters: defaultAdvancedFilters,
      vehicles: defaultVehiclesState,
    }),
    setSpecsUserState: (state, { payload }: PayloadAction<ISpecsUserState>) => {
      payload.settings.lastSelectedMarket &&
        gtmLogMarketChange(payload.settings.lastSelectedMarket)
      return {
        ...state,
        userState: payload,
      }
    },
    setCurrencyLanguageInfoState: (
      state,
      { payload }: PayloadAction<ICurrencyLanguageInfo>
    ) => ({
      ...state,
      currencyLanguageInfo: payload,
    }),
    resetSpecsUserState: (state) => ({
      ...state,
      userState: defaultUserState,
    }),
    setSelectedVehicles: (
      state,
      { payload }: PayloadAction<VehicleSpecification[]>
    ) => ({
      ...state,
      vehicles: {
        ...state.vehicles,
        selectedVehicles: payload,
      },
    }),
    removeVehicle: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      vehicles: {
        ...state.vehicles,
        selectedVehicles: state.vehicles.selectedVehicles.filter(
          (v) => v.vehicleId != payload
        ),
      },
    }),
    resetVehicles: (state) => ({
      ...state,
      vehicles: defaultVehiclesState,
    }),
    setAdvancedFilters: (
      state,
      { payload }: PayloadAction<AdvancedFiltersState>
    ) => ({
      ...state,
      advancedFilters: payload,
    }),
    setSpecsWltpItems: (
      state,
      {
        payload,
      }: PayloadAction<{
        wltpBrochureItems: EbrochureItemWithLocation[]
        wltpErrors: WltpErrorMessage[]
      }>
    ) => ({
      ...state,
      vehicles: { ...state.vehicles, ...payload },
    }),
    resetSpecsWltpItems: (state) => ({
      ...state,
      vehicles: { ...state.vehicles, wltpBrochureItems: [], wltpErrors: [] },
    }),
    setSpecsVehicles: (state, { payload }: PayloadAction<VehiclesState>) => ({
      ...state,
      vehicles: payload,
    }),
  },
})

export const {
  setSpecFilters,
  resetFiltersToDefaults,
  setSpecsUserState,
  setCurrencyLanguageInfoState,
  resetSpecsUserState,
  setSelectedVehicles,
  removeVehicle,
  resetVehicles,
  setAdvancedFilters,
  setSpecsWltpItems,
  resetSpecsWltpItems,
  setSpecsVehicles,
} = specificationsSlice.actions

export const getSpecFilters = (state: RootState): SelectedFilters =>
  state.specifications.selectedFilters

export const getSpecsUserState = (state: RootState): ISpecsUserState =>
  state.specifications.userState

export const getSelectedVehicles = (state: RootState): VehicleSpecification[] =>
  state.specifications.vehicles.selectedVehicles

export const getAdvancedFilters = (state: RootState): AdvancedFiltersState =>
  state.specifications.advancedFilters

export const getCurrencies = (state: RootState): ICurrencyInfo[] =>
  state.specifications.currencyLanguageInfo.currencies

export const getLanguages = (state: RootState): ILanguageInfo[] =>
  state.specifications.currencyLanguageInfo.languages

export const getSpecsVehicles = (state: RootState): VehiclesState =>
  state.specifications.vehicles

// if no advanced filters selected, returns []
export const getCombinedAdvancedFilters = (
  state: RootState
): IAdvancedFilterModel[] => getCombinedFilters(state, true)

// if no advanced filters selected, returns spec filters
export const getCombinedActiveFilters = (
  state: RootState
): IAdvancedFilterModel[] => getCombinedFilters(state, false)

const getCombinedFilters = (
  state: RootState,
  mustIncludeAdvancedFilters: boolean
): IAdvancedFilterModel[] =>
  buildCombinedAdvancedFilters(
    state.specifications.advancedFilters.filters,
    state.specifications.selectedFilters.filters,
    state.specifications.userState.settings.isLocalOptions,
    state.specifications.advancedFilters.lastSelectedOptionFilterType,
    mustIncludeAdvancedFilters
  )

export default specificationsSlice.reducer
