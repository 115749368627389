import { JATOButtonGroup, JATONavBar } from '@jato/ui-component-library'
import { GridComponent } from '@syncfusion/ej2-react-grids'
import { LoaderModal } from 'components/Loader/LoaderModal'
import NewsArticleSelection from 'components/News/Buttons/NewsArticleSelection'
import NewsPageViewSwitch from 'components/News/Buttons/NewsPageViewSwitch'
import NewsBreadcrumbs from 'components/News/NewsBreadcrumbs'
import NewsCards from 'components/News/NewsCards'
import NewsGrid, { NewsGridColumnType } from 'components/News/NewsGrid'
import { NewsNoItemFound } from 'components/News/NewsNoItemFound/NewsNoItemFound'
import NewsResultsCount from 'components/News/NewsResultsCount'
import NewsSaveSearchButton from 'components/News/NewsSaveSearch'
import { NewsSearchOrderButtonSwitch } from 'components/News/NewsSearchOrderButtonSwitch'
import {
  NewsFilterName,
  defaultArticlesInGridNumber,
  defaultCardsNumber,
} from 'helper/newsHelper'
import { clearSearchArticlesCache, useSearchArticles } from 'hooks/news'
import { NewsPageViewType } from 'models/News/NewsPageViewType'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getNewsSearchState,
  getNewsUserState,
  setNewsSearchState,
} from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsSearchResults } from './NewsSearchResults.styles'

export const NewsSearchResults: React.FC = () => {
  const dispatch = useAppDispatch()
  const newsUserState = useAppSelector(getNewsUserState)
  const translations = useAppSelector(getTranslations)
  const newsSearchState = useAppSelector(getNewsSearchState)
  const { searchFields, orderBy, currentPage } = newsSearchState

  const searchText = searchFields[NewsFilterName.SearchText]?.join(' ')

  const { pageViewType } = newsUserState.userSpecificSettings
  const isCardsView = pageViewType === NewsPageViewType.Cards
  const isGridView = pageViewType === NewsPageViewType.Grid

  const numOfArticles = isGridView
    ? defaultArticlesInGridNumber
    : defaultCardsNumber

  const { data: articlesResponse, isFetching } =
    useSearchArticles(numOfArticles)

  const gridColumns = [
    NewsGridColumnType.NewsCategory,
    NewsGridColumnType.Article,
    NewsGridColumnType.Author,
    NewsGridColumnType.Country,
    NewsGridColumnType.ReleaseDate,
    NewsGridColumnType.Makes,
    NewsGridColumnType.Models,
    NewsGridColumnType.Actions,
  ]

  const breadcrumbsTitle = searchText
    ? `${translations.JNT_SRCH_SearchResultsFor} '${searchText}'`
    : translations.JNT_Search_results

  useEffect(() => {
    // reset to first page because results count is changing
    if (currentPage !== 1) {
      setCurrentPage(1)
    }

    if (pageViewType === NewsPageViewType.Grid) {
      refreshGrid()
      clearSearchArticlesCache()
    }
  }, [pageViewType])

  const setCurrentPage = (currentPage: number): void => {
    dispatch(
      setNewsSearchState({
        ...newsSearchState,
        currentPage: currentPage,
      })
    )
  }

  // Used by Most relevant toggle switch
  const setOrderBy = (orderBy: string[]): void => {
    dispatch(
      setNewsSearchState({
        ...newsSearchState,
        orderBy: orderBy,
      })
    )
  }

  const refreshGrid = (): void => {
    const gridInstance: GridComponent = (document.getElementById('grid') as any)
      ?.ej2_instances[0]

    gridInstance && gridInstance.refresh()
  }

  return (
    <StyledNewsSearchResults>
      <Container fluid>
        <Row>
          <Col md={12}>
            <JATONavBar
              left={() => <NewsBreadcrumbs title={breadcrumbsTitle} />}
              right={() => (
                <JATOButtonGroup space={4}>
                  <NewsArticleSelection />
                  <NewsSaveSearchButton />
                  <NewsPageViewSwitch />
                </JATOButtonGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {!isFetching && articlesResponse?.articles?.length == 0 && (
              <NewsNoItemFound
                iconName="custom_search"
                text={`${translations.JNT_SRCH_NoArticlesFound}. <br />${translations.JNT_Refine_Your_Search}`}
              />
            )}
            {articlesResponse && articlesResponse.articles.length > 0 && (
              <div className="topLeftGroup">
                <NewsResultsCount
                  currentPage={currentPage}
                  pageSize={numOfArticles}
                  totalNumber={articlesResponse.totalNumber}
                />

                <NewsSearchOrderButtonSwitch
                  checked={orderBy?.length === 0}
                  onChange={setOrderBy}
                />
              </div>
            )}
            {isGridView &&
              articlesResponse &&
              articlesResponse.articles.length > 0 && (
                <NewsGrid
                  articles={articlesResponse.articles}
                  totalNumber={articlesResponse.totalNumber}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  columns={gridColumns}
                  allowSelection={true}
                  isFetching={isFetching}
                />
              )}
            {isCardsView &&
              articlesResponse &&
              articlesResponse.articles.length > 0 && (
                <NewsCards
                  articles={articlesResponse.articles}
                  totalNumber={articlesResponse.totalNumber}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  allowSelection={true}
                  isFetching={isFetching}
                />
              )}
          </Col>
        </Row>
      </Container>
      <LoaderModal isOpen={isFetching && !articlesResponse} />
    </StyledNewsSearchResults>
  )
}
