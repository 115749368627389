import { JATOButton, JATOButtonGroup } from '@jato/ui-component-library'
import { IBasket } from 'models/Basket/Basket'
import React, { useState } from 'react'
import { Basket } from '../Basket/Basket'

interface IBasketContainerProps {
  baskets: IBasket[] | undefined
  onCloseEvent: () => void
  setBasketTabHandler: (title: string) => void
  selectedBasketTab: string
}

export const BasketContainer: React.FC<IBasketContainerProps> = (
  props: IBasketContainerProps
) => {
  const [selectedTab, setSelectedTab] = useState<number>(
    props.selectedBasketTab == 'User Baskets' ? 0 : 1
  )
  const handleMySpecsBasketsClick = (): void => {
    props.setBasketTabHandler('User Baskets')
    setSelectedTab(0)
  }
  const handleBasketsSharedWithYouClick = (): void => {
    props.setBasketTabHandler('Shared Baskets')
    setSelectedTab(1)
  }

  const handleClose = (): void => {
    props.onCloseEvent()
  }

  return (
    <>
      <JATOButtonGroup justifyContent={'initial'}>
        <JATOButton
          id="monthlyPaymentsBasketsMyBasketsTabButton"
          variant={selectedTab == 0 ? 'primary' : 'ghost'}
          display="inline-flex"
          onClick={handleMySpecsBasketsClick}
          size={'medium'}
        >
          MY BASKETS
        </JATOButton>
        <JATOButton
          id="monthlyPaymentsBasketsSharedBasketsTabButton"
          variant={selectedTab == 1 ? 'primary' : 'ghost'}
          display="inline-flex"
          onClick={handleBasketsSharedWithYouClick}
          size={'medium'}
        >
          BASKETS SHARED WITH YOU
        </JATOButton>
      </JATOButtonGroup>
      <div style={{ width: '100%', height: '400px' }}>
        <Basket
          baskets={props.baskets}
          onCloseEvent={handleClose}
          isUserBaskets={selectedTab == 0 ? true : false}
        />
      </div>
    </>
  )
}
