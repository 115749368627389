import DefaultLayout from 'layouts/Default'
import GuestLayout from 'layouts/Guest'
import HomeLayout from 'layouts/Home'
import NewsLayout from 'layouts/News'
import React, { ComponentType, ReactNode } from 'react'
import { Route as Reroute, RouteComponentProps, RouteProps } from 'react-router'

// Update this object if layouts are added or removed
const layouts = {
  default: DefaultLayout,
  home: HomeLayout,
  guest: GuestLayout,
  news: NewsLayout,
}

export interface IRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>
  layout?: keyof typeof layouts
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RenderComponent = (props: RouteComponentProps<any>) => ReactNode

export const Route: React.FC<IRouteProps> = (props: IRouteProps) => {
  // Set the specified layout or use the default
  const layoutName = Object.keys(layouts).includes(props.layout || '')
    ? props.layout
    : 'default'
  const Layout = layouts[layoutName as keyof typeof layouts]

  // Wrap the route in a layout
  const { component: Component, ...rest }: IRouteProps = props
  const renderComponent: RenderComponent = (props) => (
    <Layout>
      <Component {...props} />
    </Layout>
  )

  // Render the wrapped route component
  return <Reroute {...rest} render={renderComponent} />
}

export default Route
