import { JATOGroup, JATOSelect2 } from '@jato/ui-component-library'
import {
  IFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import React from 'react'
export interface IMultiSelectFilter {
  label?: string
  filterName: string
  value?: string
  options: IFilterOption[]
  showCount: boolean
  isDisabled: boolean
  handleOnChange: (selectedOptions: ISelectedFilterOption[]) => void
}

export const MultiSelectFilter: React.FC<IMultiSelectFilter> = ({
  label,
  filterName,
  value,
  options,
  showCount,
  isDisabled,
  handleOnChange,
}: IMultiSelectFilter) => {
  const optionsWithCount = options.map((option) => ({
    ...option,
    displayValue: `${option.displayValue} (${option.count})`,
  }))

  const onChangeInternal = (value: string): void => {
    const values = value ? value.split(',') : []

    // if all values from a group are selected, values will include group name instead of it's actual values, i.e. 'ALFA ROMEO:GIULIA,AUDI'
    const selectedOptions = options?.filter(
      (option) =>
        (option.group && values.includes(option.group)) ||
        values.includes(option.value)
    )

    handleOnChange(selectedOptions)
  }

  return (
    <JATOGroup aria-label="Brands" role="group">
      <JATOSelect2
        label={label ?? filterName}
        width="100%"
        id={filterName}
        dataSrc={showCount ? optionsWithCount : options}
        value={value}
        onChange={onChangeInternal}
        hideClearSelection={true}
        ignoreCase={true}
        displayOnlyUnselectedOptions={false}
        isMultiSelect={true}
        clearAfterSelect={true}
        displayInlineValues={true}
        disabled={isDisabled}
        setAutoFocus={false}
      />
    </JATOGroup>
  )
}
