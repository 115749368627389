import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { L10n } from '@syncfusion/ej2-base'
import {
  DateRangePickerComponent,
  RangeEventArgs,
} from '@syncfusion/ej2-react-calendars'
import {
  defaultToDate,
  sixMonthFromDateOption,
  threeMonthFromDateOption,
  twelveMonthFromDateOption,
} from 'helper/newsHelper'
import moment from 'moment'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsDateRangePicker } from './NewsDateRangePicker.styles'

interface INewsDateRangePickerProps {
  value: Date[]
  onChange: (value: Date[]) => void
}

export const NewsDateRangePicker: React.FC<INewsDateRangePickerProps> = ({
  value: values,
  onChange,
}: INewsDateRangePickerProps) => {
  const translations = useAppSelector(getTranslations)

  const onChangeInternal = (args: RangeEventArgs): void => {
    const { isInteracted, startDate, endDate } = args

    if (!isInteracted) {
      return
    }

    let result: Date[] = []

    if (startDate && endDate) {
      const fromDate = moment(startDate).startOf('day').toDate()
      const toDate = moment(endDate).endOf('day').toDate()
      result = [fromDate, toDate]
    }

    onChange(result)
  }

  const presets = [
    {
      label: `${translations.JNT_Last_3_Months}`,
      start: threeMonthFromDateOption(),
      end: defaultToDate(),
    },
    {
      label: `${translations.JNT_Last_6_Months}`,
      start: sixMonthFromDateOption(),
      end: defaultToDate(),
    },
    {
      label: `${translations.JNT_Last_12_Months}`,
      start: twelveMonthFromDateOption(),
      end: defaultToDate(),
    },
  ]

  L10n.load({
    translatedTags: {
      daterangepicker: {
        customRange: translations.JNT_CustomRange,
        placeholder: translations.JNT_Select_Range,
      },
    },
  })

  return (
    <StyledNewsDateRangePicker>
      <div className="dateRangePicker">
        <JATOText as="div" fontSize={JATOTheme.fontSizes[1]}>
          {translations.JNT_Date_range}
        </JATOText>
        <DateRangePickerComponent
          id="newsDateRangePicker"
          locale="translatedTags"
          format="dd/MM/yyyy"
          openOnFocus={true}
          presets={presets}
          value={values}
          max={defaultToDate()}
          change={onChangeInternal}
        />
      </div>
    </StyledNewsDateRangePicker>
  )
}
