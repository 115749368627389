import { JATOGroup, JATOSelect2, JATOTag } from '@jato/ui-component-library'
import {
  IFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { selectedFilters } from 'redux/monthlypayments/selectedFiltersSlice'
export interface IDropdownWithSearch {
  label: string
  filterName: string
  options: IFilterOption[]
  value?: string
  onChange: (value: string) => void
  showSelectedValuesAsTags: boolean
  onRemoveFilter?: (filter: ISelectedFilterOption) => void
  showCount?: boolean
}

export const DropdownWithSearch: React.FC<IDropdownWithSearch> = ({
  label,
  filterName,
  options,
  value,
  onChange,
  showSelectedValuesAsTags,
  onRemoveFilter,
  showCount = false,
}: IDropdownWithSearch) => {
  const filtersData: ISelectedFilterOption[] = useAppSelector(selectedFilters)

  const optionsWithCount = options.map((option) => ({
    ...option,
    displayValue: `${option.displayValue} (${option.count})`,
  }))

  const displayValue = options.find((o) => o.value == value)?.displayValue
  return (
    <JATOGroup aria-label="Brands" role="group">
      <JATOSelect2
        label={label}
        width="100%"
        id={filterName}
        dataSrc={showCount ? optionsWithCount : options}
        value={displayValue}
        onChange={onChange}
        hideClearSelection={true}
        ignoreCase={true}
        displayOnlyUnselectedOptions={true}
        clearAfterSelect={showSelectedValuesAsTags}
      />
      {showSelectedValuesAsTags ? (
        <JATOGroup flexDirection="row">
          {filtersData
            ?.filter(
              (filter: ISelectedFilterOption) => filter.filterName == filterName
            )
            .map((filter: ISelectedFilterOption) => (
              <JATOTag
                key={filter.key}
                isDismissible
                variant="secondary"
                value={filter.value}
                onClick={() => onRemoveFilter && onRemoveFilter(filter)}
              >
                {filter.displayValue}
              </JATOTag>
            ))}
        </JATOGroup>
      ) : (
        <></>
      )}
    </JATOGroup>
  )
}
