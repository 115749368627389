import { MpAlertsBetaState } from 'models/Login/NewsLogin/MpAlertsBetaState'
import { newsApi } from 'services/apiWithAuth'

class MpAlertsBetaService {
  public getMpAlertsBetaState(): Promise<MpAlertsBetaState> {
    return newsApi
      .get<MpAlertsBetaState>('mpAlertsBeta')
      .then((response) => response.data)
  }

  public activate(isActivated: boolean): Promise<boolean> {
    return newsApi
      .post(`mpAlertsBeta/activate/${isActivated}`)
      .then(() => true)
      .catch(() => false)
  }

  public enable(isEnabled: boolean): Promise<boolean> {
    return newsApi
      .post(`mpAlertsBeta/enable/${isEnabled}`)
      .then(() => true)
      .catch(() => false)
  }

  public hideWelcomePopup(isHidden: boolean): Promise<boolean> {
    return newsApi
      .post(`mpAlertsBeta/hideWelcomePopup/${isHidden}`)
      .then(() => true)
      .catch(() => false)
  }

  public hideActivationPopup(isHidden: boolean): Promise<boolean> {
    return newsApi
      .post(`mpAlertsBeta/hideActivationPopup/${isHidden}`)
      .then(() => true)
      .catch(() => false)
  }
}

const mpAlertsBetaService = new MpAlertsBetaService()
export default mpAlertsBetaService
