import { JATOTextInput } from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { SaveSearchOptionsModel } from 'models/News/SaveSearchOptionsModel'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsSubscriptionTypeSelection from '../Alerts/NewsSubscriptionTypeSelection'
import { StyledNewsSaveSearch } from './NewsSaveSearch.styles'

export interface INewsSaveSearchProps {
  model: SaveSearchOptionsModel
  onChange: (model: SaveSearchOptionsModel) => void
}

export const NewsSaveSearch: React.FC<INewsSaveSearchProps> = ({
  model,
  onChange,
}: INewsSaveSearchProps) => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledNewsSaveSearch>
      <JATOTextInput
        id=""
        width={500}
        label={translations.JNT_Name_}
        placeholder={
          model.createAlert
            ? translations.JNT_AlertName
            : translations.JNT_Name_your_search
        }
        value={model.name}
        onChange={(e) => onChange({ ...model, name: e.currentTarget.value })}
      />
      {!model.createAlert && (
        <CheckboxWithGtm
          id="newsSaveSearchShareCheckbox"
          label={translations.JNT_Share_in_your_organisation}
          checked={model.isShared}
          onChange={() => onChange({ ...model, isShared: !model.isShared })}
        >
          {translations.JNT_Share_in_your_organisation}
        </CheckboxWithGtm>
      )}
      <CheckboxWithGtm
        id="newsSaveSearchCreateAlertCheckbox"
        label={translations.JNT_Create_alert_from_search}
        checked={model.createAlert}
        onChange={() => onChange({ ...model, createAlert: !model.createAlert })}
      >
        {translations.JNT_Create_alert_from_search}
      </CheckboxWithGtm>
      {model.createAlert && (
        <NewsSubscriptionTypeSelection
          onChange={(value) => onChange({ ...model, subscriptionType: value })}
          value={model.subscriptionType}
        />
      )}
    </StyledNewsSaveSearch>
  )
}
