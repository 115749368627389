import { JATOPagination } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledPagination } from './Pagination.styles'

export interface IPagination {
  id?: string
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

export const Pagination: React.FC<IPagination> = ({
  id = 'pagination',
  currentPage,
  totalPages,
  onPageChange,
}: IPagination) => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledPagination id={id}>
      <JATOPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        previousCTATitle={translations.JNT_PagingPreviousText}
        nextCTATitle={translations.JNT_PagingNextText}
      />
    </StyledPagination>
  )
}
