import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import {
  AllValues,
  getAllValuesDataPoint,
  getSegmentationFields,
  isUserSegment,
} from 'helper/volumesHelper'
import { useGetDataPointValues } from 'hooks/volumes'
import { DataPointValues } from 'models/Volumes/DataPointValues'
import { DpPoint } from 'models/Volumes/DpPoint'
import { JATONetTreeview } from 'models/Volumes/JATONetTreeview'
import React, { useEffect, useImperativeHandle } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  setVolumesQueryState
} from 'redux/volumes/volumesSlice'

export interface IVolumesQuerySelectionPanelProps {
  selectedItem?: JATONetTreeview
}

export interface IVolumesQuerySelectionPanelRef {
  onSelectAll: () => void
  onClearChoices: (item: JATONetTreeview | undefined) => void
}

const VolumesQuerySelectionPanel: React.ForwardRefRenderFunction<
  IVolumesQuerySelectionPanelRef,
  IVolumesQuerySelectionPanelProps
> = ({ selectedItem }: IVolumesQuerySelectionPanelProps, ref) => {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)

  useImperativeHandle(ref, () => ({
    onSelectAll,
    onClearChoices,
  }))

  const queryState = useAppSelector(getVolumesQueryState)

  const enabled =
    (selectedItem?.dataPointName && selectedItem.dataPointName !== 'VERSION') ||
    (selectedItem?.dataPointName === 'VERSION' &&
      queryState.countries?.length === 1)

  const {
    mutate: loadDataPoints,
    data: dataPointValues,
    isLoading,
  } = useGetDataPointValues(selectedItem)

  useEffect(() => {
    enabled && loadDataPoints()
  }, [selectedItem])

  const getSelectedValues = (): string[] => {
    const dataPoint = queryState.vehicleAttributes?.find(
      (p) => p.id === selectedItem?.dataPointName
    )

    return (
      (dataPoint?.valuedesc === AllValues
        ? dataPointValues?.map((v) => v.dataPointValue)
        : dataPoint?.value.split(',')) ?? []
    )
  }
  const selectedValues = getSelectedValues()

  const getDataPoint = (values: string[]): DpPoint =>
    values.length === dataPointValues?.length
      ? getAllValuesDataPoint(selectedItem)
      : {
          id: selectedItem?.dataPointName ?? '',
          iddesc: selectedItem?.longText ?? '',
          value: values.join(','),
          valuedesc:
            dataPointValues
              ?.filter((v) => values.includes(v.dataPointValue))
              .map((v) => v.description)
              .join(', ') ?? '',
          part: 1,
          optype: selectedItem?.dataPointGroup ?? '',
        }

  const onSelectedValueChange = (dp: DataPointValues): void => {
    const newSelection = selectedValues.includes(dp.dataPointValue)
      ? selectedValues.filter((v) => v != dp.dataPointValue)
      : [...selectedValues, dp.dataPointValue]

    const newDataPoints =
      (isUserSegment(selectedItem?.dataPointName)
        ? queryState.vehicleAttributes?.filter((p) => !isUserSegment(p.id))
        : queryState.vehicleAttributes?.filter(
            (p) => p.id !== selectedItem?.dataPointName
          )) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        ...getSegmentationFields(newSelection, selectedItem),
        vehicleAttributes:
          newSelection.length == 0
            ? newDataPoints
            : [...newDataPoints, getDataPoint(newSelection)],
      })
    )
  }

  const onSelectAll = (): void => {
    const newSelection = dataPointValues?.map((v) => v.dataPointValue) ?? []

    const newDataPoints =
      (isUserSegment(selectedItem?.dataPointName)
        ? queryState.vehicleAttributes?.filter((p) => !isUserSegment(p.id))
        : queryState.vehicleAttributes?.filter(
            (p) => p.id !== selectedItem?.dataPointName
          )) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        ...getSegmentationFields(newSelection, selectedItem),
        vehicleAttributes: [
          ...newDataPoints,
          getAllValuesDataPoint(selectedItem),
        ],
      })
    )
  }

  const onClearChoices = (item: JATONetTreeview | undefined): void => {
    const newDataPoints =
      queryState.vehicleAttributes?.filter(
        (p) => p.id !== item?.dataPointName
      ) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        ...getSegmentationFields([], item),
        vehicleAttributes: newDataPoints,
      })
    )
  }

  const getVersionState = (): boolean => {
    if (queryState.vehicleAttributes) {
      return (
        selectedItem?.dataPointName === 'VERSION' &&
        queryState.vehicleAttributes.filter((p) => p.id === 'VERSION').length >
          0
      )
    }
    return false
  }

  return (
    <>
      {!enabled
        ? getVersionState() && (
            <div>
              <p>{translations.JNT_Volumes_AllVersions_Selected}</p>
              <p>{translations.JNT_Volumes_MoreThan_Versions}</p>
            </div>
          )
        : !isLoading &&
          dataPointValues?.map((dp, index) => (
            <CheckboxWithGtm
              key={index}
              id="volumesQuerySelectionPanelCheckbox"
              value={dp.dataPointValue}
              label={dp.description}
              checked={selectedValues.includes(dp.dataPointValue)}
              onChange={() => onSelectedValueChange(dp)}
              parentLabel={selectedItem.longText}
            >
              {dp.description}
            </CheckboxWithGtm>
          ))}
      <LoaderModal isOpen={isLoading} />
    </>
  )
}

export default React.forwardRef(VolumesQuerySelectionPanel)
