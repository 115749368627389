import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsMetadata = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .newsMetadataBlock {
    width: 170px;
    height: 24px;
    border-radius: 100px;
    box-shadow: ${JATOTheme.shadows[1]};
    background-color: ${JATOTheme.colors.white};
    font-size: 12px;
    font-weight: ${JATOTheme.fontWeights.medium};
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;
    align-items: center;
    padding: 0px 10px;
    max-width: calc(12vw - 40px);
  }

  .newsMetadataBlock span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .newsMetadataBlock img {
    width: 14px;
  }
`
