import { JATOLink } from '@jato/ui-component-library'
import {
  ActionEventArgs,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  Edit,
  EditSettingsModel,
  GridComponent,
  Inject,
  PageSettingsModel,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids'
import Loader from 'components/Loader'
import { Pagination } from 'components/Pagination/Pagination'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import {
  VolumesQueryColumnType,
  defaultNumberOfQueries,
} from 'helper/volumesHelper'
import { useDeleteQuery, useRenameQuery, useUpdateQuery } from 'hooks/volumes'
import { VolumesQuery } from 'models/Volumes/VolumesQuery'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { VolumesDeleteButton } from '../VolumesDeleteButton/VolumesDeleteButton'
import { VolumesGridShareButton } from '../VolumesQueryShareButton/VolumesQueryShareButton'
import { StyledVolumesQueriesGrid } from './VolumesQueriesGrid.styles'

export interface IVolumesGridProps {
  id?: string
  queries?: VolumesQuery[]
  columns: VolumesQueryColumnType[]
}

export const VolumesQueriesGrid: React.FC<IVolumesGridProps> = ({
  id = 'volumesQueriesGrid',
  queries,
  columns,
}: IVolumesGridProps) => {
  const translations = useAppSelector(getTranslations)
  const history = useHistory()

  const { mutateAsync: renameQuery } = useRenameQuery()
  const { mutateAsync: deleteQuery, isLoading: isDeleting } = useDeleteQuery()
  const { mutateAsync: shareQuery, isLoading: isSharing } = useUpdateQuery()

  const [currentPage, setCurrentPage] = useState(1)

  const defaultColumnWidthPercentage = 5
  const defaultColumnAlign = 'Left'
  const defaultClipMode = 'Clip'

  const totalPages = queries
    ? Math.ceil(queries.length / defaultNumberOfQueries)
    : 0

  const indexOfLastItem = currentPage * defaultNumberOfQueries
  const indexOfFirstItem = indexOfLastItem - defaultNumberOfQueries
  const currentItems = queries
    ? queries.slice(indexOfFirstItem, indexOfLastItem)
    : []

  const loadQuery = (query: VolumesQuery): void => {
    history.push(`${RoutePaths.VolumesQuery}/${query.queryId}`)
  }

  const getFormattedDate = (_field: string, data: object): string => {
    const { queryCreateDate, queryModifiedDate, qryLastRun } =
      data as VolumesQuery
    switch (_field) {
      case 'qryLastRun':
        return qryLastRun
          ? new Date(qryLastRun).toLocaleDateString('en-GB')
          : ''
      case 'queryCreateDate':
        return queryCreateDate
          ? new Date(queryCreateDate).toLocaleDateString('en-GB')
          : ''
      case 'queryModifiedDate':
        return queryModifiedDate
          ? new Date(queryModifiedDate).toLocaleDateString('en-GB')
          : ''
      default:
        return ''
    }
  }

  const onDeleteClick = async (query: VolumesQuery): Promise<void> => {
    await deleteQuery(query)
  }

  const onShareClick = async (query: VolumesQuery): Promise<void> => {
    const isSuccess = await shareQuery(query)
    if (isSuccess) {
      showSuccessToast(
        translations.JNT_Success,
        translations.JNT_Volumes_MQ_Query_Updated
      )
    } else {
      showErrorToast(translations.JNT_Error, translations.JNT_Error)
    }
  }

  const volumesQueryGridTemplate = (query: VolumesQuery): JSX.Element => (
    <JATOLink
      id={id + 'Link'}
      onClick={() => loadQuery(query)}
      className="queryLink"
      textTransform="inherit"
    >
      {query.queryShortDescription}
    </JATOLink>
  )

  const queryShareTemplate = (query: VolumesQuery): JSX.Element => (
    <VolumesGridShareButton
      id={id + 'ShareButton'}
      query={query}
      onClick={onShareClick}
    />
  )

  const queryDeleteTemplate = (query: VolumesQuery): JSX.Element => (
    <VolumesDeleteButton
      id={id + 'DeleteButton'}
      item={query}
      deleteItem={onDeleteClick}
      name={query.queryShortDescription}
      removeMessage={translations.JNT_Volumes_MQ_Query_Delete_Confirmation}
      translations={translations}
    />
  )

  const editOptions: EditSettingsModel = {
    allowEditing: true,
  }

  const editCommands: CommandModel[] = [
    {
      type: 'Edit',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' },
    },
    {
      type: 'Save',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-update e-icons',
      },
    },
    {
      type: 'Cancel',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' },
    },
  ]

  const autoComplete = (args: ActionEventArgs): void => {
    if (args.requestType === 'save') {
      const query = args.data as VolumesQuery
      if (doesQueryNameExist(query)) {
        showErrorToast(
          translations.JNT_Error,
          translations.JNT_Volumes_MQ_Query_Name_Exists
        )
      } else {
        showSuccessToast(
          translations.JNT_Success,
          translations.JNT_Volumes_MQ_Query_Updated
        )

        renameQuery(query)
      }
    }
  }

  function doesQueryNameExist(editedQuery: VolumesQuery): boolean {
    const duplicateCheck = (query: VolumesQuery): boolean =>
      query.queryShortDescription.trim()?.toLowerCase() ===
      editedQuery.queryShortDescription.trim()?.toLocaleLowerCase()

    const filteredList = queries?.filter(duplicateCheck)
    if (filteredList !== undefined && filteredList.length > 1) {
      return true
    } else {
      return false
    }
  }

  const columnDefinitions = [
    {
      id: VolumesQueryColumnType.QueryId,
      field: 'queryId',
      visible: false,
      isPrimaryKey: true,
    },
    {
      id: VolumesQueryColumnType.SearchName,
      field: 'queryShortDescription',
      headerText: translations.JNT_Volumes_MQ_SearchName,
      template: volumesQueryGridTemplate,
      allowEditing: true,
    },
    {
      id: VolumesQueryColumnType.DateCreated,
      field: 'queryCreateDate',
      headerText: translations.JNT_Volumes_MQ_DateCreated,
      valueAccessor: getFormattedDate,
    },
    {
      id: VolumesQueryColumnType.DateModified,
      field: 'queryModifiedDate',
      headerText: translations.JNT_Volumes_MQ_DateModified,
      valueAccessor: getFormattedDate,
    },
    {
      id: VolumesQueryColumnType.LastRan,
      field: 'qryLastRun',
      headerText: translations.JNT_Volumes_MQ_DateLastRun,
      valueAccessor: getFormattedDate,
    },
    {
      id: VolumesQueryColumnType.Share,
      headerText: translations.JNT_Volumes_MQ_Share,
      width: 3,
      template: queryShareTemplate,
      allowSorting: false,
    },
    {
      id: VolumesQueryColumnType.Delete,
      headerText: translations.JNT_Volumes_MQ_Delete,
      width: 3,
      template: queryDeleteTemplate,
      allowSorting: false,
    },
    {
      id: VolumesQueryColumnType.Rename,
      headerText: translations.JNT_Volumes_MQ_Rename,
      width: 3,
      commands: editCommands,
      allowSorting: false,
    },
  ]

  const pageSettings: PageSettingsModel = {
    pageSize: defaultNumberOfQueries,
  }

  const paginationComponent = (
    <Pagination
      id={id + 'Pagination'}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={setCurrentPage}
    />
  )

  return (
    <StyledVolumesQueriesGrid>
      {paginationComponent}
      {queries && !isDeleting && !isSharing ? (
        <GridComponent
          id={id}
          dataSource={currentItems}
          loadingIndicator={{ indicatorType: 'Shimmer' }}
          allowResizing={true}
          editSettings={editOptions}
          pageSettings={pageSettings}
          allowSorting={true}
          actionComplete={autoComplete}
        >
          <ColumnsDirective>
            {columnDefinitions.map((c, index) => (
              <ColumnDirective
                key={index}
                field={c.field}
                headerText={c.headerText}
                width={c.width ?? defaultColumnWidthPercentage}
                textAlign={defaultColumnAlign}
                clipMode={defaultClipMode}
                isPrimaryKey={c.isPrimaryKey ?? false}
                visible={c.isPrimaryKey ? false : columns.includes(c.id)}
                valueAccessor={c.valueAccessor}
                template={c.template}
                commands={c.commands ?? undefined}
                allowEditing={c.allowEditing ?? false}
                allowSorting={c.allowSorting ?? true}
              />
            ))}
          </ColumnsDirective>
          <Inject services={[Edit, CommandColumn, Resize, Sort]} />
        </GridComponent>
      ) : (
        <Loader style={{ minHeight: 'calc(100vh - 340px)' }} />
      )}
      {paginationComponent}
    </StyledVolumesQueriesGrid>
  )
}
