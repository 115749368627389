import Footer from 'components/Footer'
import { HeaderContainer } from 'components/Header'
import { getPageTitle } from 'helper/newsHelper'
import { useEnableRtl } from 'hooks/useEnableRtl'
import { useRootPathResolver } from 'hooks/useRootPathResolver'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledLayout } from './styles'
import { JATOCookie } from 'components/Cookie/JATOCookie'
import { useLocation } from 'react-router-dom'

interface IProps {
  children: React.ReactNode
}

export const DefaultLayout: React.FC<IProps> = ({ children }: IProps) => {
  const enableRtl = useEnableRtl()

  const translations = useAppSelector(getTranslations)
  const { routeName } = useRootPathResolver()

  const location = useLocation()
  if (location.hash) {
    const queryParams = location.hash.split('?')[1]
    const redirectUrl = `${process.env.PUBLIC_URL}/login`
    window.location.href = queryParams
      ? `${redirectUrl}?${queryParams}`
      : redirectUrl
  }

  useEffect(() => {
    enableRtl
      ? document.body.classList.add('rtl')
      : document.body.classList.remove('rtl')
  }, [enableRtl])

  useEffect(() => {
    document.title = getPageTitle(translations)[routeName] ?? document.title
  }, [translations])

  return (
    <StyledLayout>
      <HeaderContainer />
      <Container xl lg md sm xs>
        <Row>
          <Col md={12} lg={12}>
            {children}
          </Col>
        </Row>
        <Row>
          <JATOCookie />
          <Footer />
        </Row>
      </Container>
    </StyledLayout>
  )
}

export default DefaultLayout
