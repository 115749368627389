import { JATOLink } from '@jato/ui-component-library'
import { CommonModal, THandleClose } from 'components/Modals/CommonModal'
import { gtmLogNewsAdvancedSearch } from 'helper/gtm'
import { IMappingObject } from 'helper/IMappingObject'
import {
  defaultToDate,
  NewsFilterName,
  twelveMonthFromDateOption,
} from 'helper/newsHelper'
import { AdvancedSearchModel } from 'models/News/AdvancedSearchModel'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { setNewsSearchState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { NewsAdvancedSearch } from './NewsAdvancedSearch'
import { StyledNewsAdvancedSearchLink } from './NewsAdvancedSearchLink.styles'

export const NewsAdvancedSearchLink: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)

  const defaultModel: AdvancedSearchModel = {
    searchText: '',
    isExactMatch: false,
    categories: '',
    countries: '',
    dateRange: [twelveMonthFromDateOption(), defaultToDate()],
    makes: '',
    models: '',
    group: '',
  }

  const [model, setModel] = useState(defaultModel)

  const onClearAllClick = (): void => {
    setModel(defaultModel)
  }

  const onSearchClick = ({ handleClose }: THandleClose): void => {
    const searchFields: IMappingObject<string[]> = {
      [NewsFilterName.SearchText]: model.searchText ? [model.searchText] : [],
      [NewsFilterName.Category]: model.categories
        ? model.categories.split(',')
        : [],
      [NewsFilterName.Country]: model.countries
        ? model.countries.split(',')
        : [],
      [NewsFilterName.ReleaseDate]: [JSON.stringify(model.dateRange)],
      [NewsFilterName.Make]: model.makes ? model.makes.split(',') : [],
      [NewsFilterName.Model]: model.models ? model.models.split(',') : [],
      [NewsFilterName.Group]: model.group ? model.group.split(',') : [],
    }

    dispatch(
      setNewsSearchState({
        searchFields: searchFields,
        isExactMatch: model.searchText ? model.isExactMatch : false,
        orderBy:
          model.searchText && !model.isExactMatch ? [] : ['releaseDate desc'],
        currentPage: 1,
      })
    )
    gtmLogNewsAdvancedSearch(model)

    history.push(RoutePaths.NewsSearchResults)
    handleClose()
  }

  return (
    <StyledNewsAdvancedSearchLink>
      <CommonModal
        closeButton
        title={translations.JNT_Advanced_search}
        trigger={({ handleOpen }) => (
          <JATOLink
            id="newsAdvancedSearchLink"
            onClick={handleOpen}
            className="advancedSearchLink"
          >
            {translations.JNT_Advanced_search}
          </JATOLink>
        )}
        secondaryButtonName={translations.JNT_Clear_all}
        onSecondaryClick={onClearAllClick}
        primaryButtonName={translations.JNT_SRCH_Search}
        onPrimaryClick={onSearchClick}
      >
        <NewsAdvancedSearch model={model} onChange={setModel} />
      </CommonModal>
    </StyledNewsAdvancedSearchLink>
  )
}
