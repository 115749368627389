import { StepIconProps } from '@mui/material'
import stepCountryIcon from 'assets/icons/icon_step_country.svg'
import stepPeriodSelectionsIcon from 'assets/icons/icon_step_period.svg'
import stepQueryDefinitionIcon from 'assets/icons/icon_step_query_definition.svg'
import stepReportIcon from 'assets/icons/icon_step_report.svg'
import startResetIcon from 'assets/icons/icon_step_startreset.svg'
import { VolumesSteps, getPathForStep } from 'helper/volumesHelper'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { VolumesStepperIcon } from './VolumesStepperIcon'
import { VolumesStepIconRoot } from './VolumesStepperMaterial.styles'

export const VolumesStepIconComponent = (props: StepIconProps): any => {
  const history = useHistory()
  const { active, completed, className } = props

  const icons: { [index: number]: React.ReactElement } = {
    [VolumesSteps.StartReset]: <VolumesStepperIcon iconName={startResetIcon} />,
    [VolumesSteps.Country]: <VolumesStepperIcon iconName={stepCountryIcon} />,
    [VolumesSteps.QueryDefinition]: (
      <VolumesStepperIcon iconName={stepQueryDefinitionIcon} />
    ),
    [VolumesSteps.PeriodsSelections]: (
      <VolumesStepperIcon iconName={stepPeriodSelectionsIcon} />
    ),
    [VolumesSteps.Report]: <VolumesStepperIcon iconName={stepReportIcon} />,
  }

  const handleActiveStep = (step: number): void =>
    history.push(getPathForStep(step))

  return (
    <VolumesStepIconRoot
      id={
        'volumesStep_' + VolumesSteps[(Number(props.icon) - 1) as VolumesSteps]
      }
      ownerState={{ completed, active }}
      className={className}
      onClick={() => handleActiveStep(Number(props.icon) - 1)}
    >
      {icons[Number(props.icon) - 1]}
    </VolumesStepIconRoot>
  )
}
