import axios from 'axios'
import {
  baseSpecsAPIUrl,
  mpLatestNewsBaseUrl,
  newCarSpecsBaseAPIUrl,
  portalUrl,
} from 'config'
import { logError } from 'helper/loggingService'
import { store } from 'redux/store'
import { RoutePaths } from 'router/RoutePaths'

const carSpecsApi = axios.create({
  baseURL: newCarSpecsBaseAPIUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

carSpecsApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    logError(err.response)
    if (err.response.status === 401) {
      window.location.href =
        `${process.env.PUBLIC_URL}${RoutePaths.Autologout}` +
        (window.location.pathname.includes(RoutePaths.Specifications)
          ? `?returnUrl=${RoutePaths.Specifications}`
          : '')
    }
    return Promise.reject(err)
  }
)

carSpecsApi.interceptors.request.use(
  async (config) => {
    const { carSpecsUserGuid, v5ContractId } =
      store.getState().specifications.userState

    if (carSpecsUserGuid && config.headers) {
      config.headers.Authorization = `Basic ${carSpecsUserGuid}`
      config.headers.JatonetContractId = v5ContractId
    }

    return config
  },

  (error) => Promise.reject(error)
)

const specsWithAuthApi = axios.create({
  baseURL: baseSpecsAPIUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

specsWithAuthApi.interceptors.request.use(
  async (config) => {
    const { guid } = store.getState().commonUserData

    if (guid && config.headers) {
      config.headers.Authorization = `Bearer ${guid}`
    }

    return config
  },

  (error) => Promise.reject(error)
)

specsWithAuthApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (err.response.status === 401) {
      window.location.href =
        `${process.env.PUBLIC_URL}${RoutePaths.Autologout}` +
        (window.location.pathname.includes(RoutePaths.Specifications)
          ? `?returnUrl=${RoutePaths.Specifications}`
          : '')
    }
  }
)

const newsApi = axios.create({
  baseURL: mpLatestNewsBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

newsApi.interceptors.request.use(
  async (config) => {
    const { guid } = store.getState().commonUserData

    if (guid && config.headers) {
      config.headers.Authorization = `Bearer ${guid}`
    }

    return config
  },

  (error) => Promise.reject(error)
)

newsApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (
      err.response.status === 401 &&
      !window.location.pathname.includes(RoutePaths.Volumes)
    ) {
      window.location.href =
        `${process.env.PUBLIC_URL}${RoutePaths.Autologout}` +
        (window.location.pathname.includes(RoutePaths.News)
          ? `?returnUrl=${RoutePaths.News}`
          : '')
    }
    logError(err.response)
    return Promise.reject(err)
  }
)

const volumesApi = axios.create({
  baseURL: portalUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

volumesApi.interceptors.request.use(
  async (config) => {
    const { languageId, guid } = store.getState().commonUserData

    if (config.headers) {
      config.headers['Language-Id'] = languageId.toString()
      config.headers['User-Guid'] = guid
    }

    return config
  },
  (error) => Promise.reject(error)
)

volumesApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    logError(err.response)
    return Promise.reject(err)
  }
)

volumesApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (err.response.status === 401) {
      window.location.href =
        `${process.env.PUBLIC_URL}${RoutePaths.Autologout}` +
        (window.location.pathname.includes(RoutePaths.Volumes)
          ? `?returnUrl=${RoutePaths.Volumes}`
          : '')
    }
  }
)

export { carSpecsApi, newsApi, specsWithAuthApi, volumesApi }

