import viewGalleryIcon from 'assets/icons/image.svg'
import { NewsArticlePhotoGalleryModal } from 'components/News/NewsArticlePhotoGallery/NewsArticlePhotoGalleryModal'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsImgButton from '../../../CommonButtons/ImgButton'

export interface INewsPhotoGalleryButtonProps {
  article: NewsArticle
}

export const NewsPhotoGalleryButton: React.FC<INewsPhotoGalleryButtonProps> = ({
  article,
}: INewsPhotoGalleryButtonProps) => {
  const translations = useAppSelector(getTranslations)
  const [modalPhotoGalleryVisible, setModalPhotoGalleryVisible] =
    useState(false)

  return (
    <>
      <NewsImgButton
        id="newsPhotoGalleryButton"
        src={viewGalleryIcon}
        title={translations.JNT_Photo}
        onClick={() => {
          setModalPhotoGalleryVisible(true)
        }}
      />
      <NewsArticlePhotoGalleryModal
        isOpen={modalPhotoGalleryVisible}
        onClose={() => setModalPhotoGalleryVisible(false)}
        articleImageList={article.imageLibrary}
        title={article.headline}
      />
    </>
  )
}
