import { JATOSelect2 } from '@jato/ui-component-library'

import { getVehicleTypeDescription } from 'helper/newsHelper'
import { useGetVehicleTypes } from 'hooks/news'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsVehicleTypeSelect } from './NewsVehicleTypeSelect.styles'

interface INewsVehicleTypeSelectProps {
  value: string
  onChange: (value: string) => void
  isRequired?: boolean
}

export const NewsVehicleTypeSelect: React.FC<INewsVehicleTypeSelectProps> = ({
  value,
  onChange,
  isRequired,
}: INewsVehicleTypeSelectProps) => {
  const translations = useAppSelector(getTranslations)
  const { data } = useGetVehicleTypes()

  const dropdownOptions =
    data?.map((c) => ({
      value: c.key,
      displayValue: getVehicleTypeDescription(translations)[c.key],
    })) ?? []

  const getDisplayValue = (value: string): string =>
    dropdownOptions.find((x) => x.value === value)?.displayValue ?? value

  return (
    <StyledNewsVehicleTypeSelect>
      <JATOSelect2
        id="newsVehicleTypeSelect"
        label={`${translations.JNT_VehicleType}${isRequired ? ' *' : ''}`}
        value={getDisplayValue(value)}
        placeholder={translations.JNT_AllTypes}
        dataSrc={dropdownOptions}
        onChange={onChange}
        width={320}
        ignoreCase={true}
        displayOnlyUnselectedOptions={false}
      />
    </StyledNewsVehicleTypeSelect>
  )
}
