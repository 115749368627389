import {
  JATOButtonIcon,
  JATODropdown,
  JATODropdownMenu,
  JATOTheme,
} from '@jato/ui-component-library'
import AlertsIcon from 'assets/icons/icon_alerts.svg'
import cornerStoneIcon from 'assets/icons/icon_cornerstone.svg'
import FavouriteIcon from 'assets/icons/icon_favourite.svg'
import SearchIcon from 'assets/icons/icon_search.svg'
import ShareIcon from 'assets/icons/icon_share.svg'
import workflowIcon from 'assets/icons/icon_workflow.svg'
import NewsFiltersPanel from 'components/News/Filters/NewsFiltersPanel'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getNewsFilters, getNewsUserState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { NewsArticleMenuItemButton } from '../NewsArticleMenuItemButton/NewsArticleMenuItemButton'
import { StyledNewsMainMenuButton } from './NewsMainMenuButton.styles'

export const NewsMainMenuButton: React.FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const translations = useAppSelector(getTranslations)
  const { isJatoUser } = useAppSelector(getNewsUserState)
  const newsFilters = useAppSelector(getNewsFilters)

  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const isNewsHome = pathname === RoutePaths.News
  const appliedFiltersCount = Object.values(newsFilters.selectedFilters).flat()
    .length

  return (
    <StyledNewsMainMenuButton>
      {isNewsHome && appliedFiltersCount > 0 && (
        <div className="appliedFilterNotification" />
      )}
      <JATODropdown
        className="stackOrderStyle"
        trigger={() => (
          <JATOButtonIcon
            id="newsMainMenuButton"
            className="button"
            iconName="baseline_more_horiz"
            iconFill={JATOTheme.colors.darkGrey}
          />
        )}
      >
        <JATODropdownMenu className="dropdown">
          <NewsArticleMenuItemButton
            title={translations.JNT_NewsAlerts}
            id="newsMainMenuAlertsButton"
            iconSrc={AlertsIcon}
            onClick={() => history.push(RoutePaths.NewsAlerts)}
          />
          <NewsArticleMenuItemButton
            title={translations.JNT_SavedSearches}
            id="newsMainMenuSavedSearchesButton"
            iconSrc={SearchIcon}
            onClick={() => history.push(RoutePaths.NewsSavedSearches)}
          />
          {isNewsHome && (
            <NewsArticleMenuItemButton
              title={`${
                appliedFiltersCount > 0 ? `(${appliedFiltersCount}) ` : ''
              }${translations.JNT_FilterHomepage}`}
              id="newsMainMenuFilterHomepageButton"
              iconName="custom_filter"
              onClick={() => setFiltersOpen(!isFiltersOpen)}
            />
          )}
          <NewsArticleMenuItemButton
            title={translations.JNT_Favourites}
            id="newsMainMenuFavouritesButton"
            iconSrc={FavouriteIcon}
            onClick={() => history.push(RoutePaths.NewsBookMarkArticles)}
          />
          <NewsArticleMenuItemButton
            title={translations.JNT_SharedArticles}
            id="newsMainMenuSharedArticlesButton"
            iconSrc={ShareIcon}
            onClick={() => history.push(RoutePaths.NewsSharedArticles)}
          />
          {isJatoUser && (
            <NewsArticleMenuItemButton
              title={translations.JNT_Link_Cornerstone}
              id="newsMainMenuCornerstoneReportsButton"
              iconSrc={cornerStoneIcon}
              onClick={() => history.push(RoutePaths.NewsCornerstoneReports)}
            />
          )}
          {isJatoUser && (
            <NewsArticleMenuItemButton
              title={translations.JNT_Link_WorkflowArt}
              id="newsMainMenuWorkflowReportsButton"
              iconSrc={workflowIcon}
              onClick={() => history.push(RoutePaths.NewsWorkflowReports)}
            />
          )}
        </JATODropdownMenu>
      </JATODropdown>
      <NewsFiltersPanel
        isOpen={isFiltersOpen}
        onClose={() => setFiltersOpen(false)}
      />
    </StyledNewsMainMenuButton>
  )
}
