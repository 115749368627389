import { Flag } from 'components/Flag/Flag'
import { getCountryCode } from 'helper/countryCodeMapper'
import { getReferencePriceFormatted } from 'helper/getReferencePrice'
import { getVehicleReportFilter } from 'helper/getVehicleReportFilter'
import { ReportParameters } from 'models/Reports/ReportParameters'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getSelectedCountry } from 'redux/monthlypayments/selectedFiltersSlice'
import { v4 as uuid } from 'uuid'
import { StyledMonthlyPaymentsSceneriosHeader } from './MonthlyPaymentsSceneriosHeader.styles'

interface IMonthlyPaymentsReportHeader {
  vehicles: MpVehicle[]
}

export const MonthlyPaymentsSceneriosHeader: React.FC<
  IMonthlyPaymentsReportHeader
> = ({ vehicles }: IMonthlyPaymentsReportHeader) => {
  const reportRef = useRef<any>(null)
  const { uid, dataDate } = useParams<ReportParameters>()
  const selectedVehicle = vehicles.filter((vehicle: MpVehicle) =>
    getVehicleReportFilter({ uniqueIdentity: uid, dataDate: dataDate }, vehicle)
  )[0]
  const selectedCountry = useAppSelector(getSelectedCountry)

  useEffect(() => {
    reportRef.current?.focus()
  }, [])

  return (
    <StyledMonthlyPaymentsSceneriosHeader
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <table className="tableStyle">
        <tbody>
          <tr className="tableRow">
            <td
              key={uuid()}
              className="tableCell"
              style={{
                position: 'sticky',
                left: '0',
              }}
            >
              <div style={{ height: '70px', backgroundColor: 'white' }} />
            </td>
            {selectedVehicle.scenarios.map((scenario: any, index: number) => (
              <td
                className="tableCell"
                key={index}
                style={{
                  zIndex: 3,
                }}
              >
                <div className="tableValue">
                  <div>
                    <Flag countryCode={getCountryCode(selectedCountry)} />
                    <span style={{ paddingLeft: '5px', fontWeight: 700 }}>
                      {' '}
                      {`${selectedVehicle.make} ${selectedVehicle.model}`}
                    </span>
                  </div>
                  <div style={{ fontWeight: 400 }}>
                    {selectedVehicle.versionName}
                  </div>
                  <div style={{ fontWeight: 400 }}>
                    <span>{getReferencePriceFormatted(selectedVehicle)}</span>
                  </div>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </StyledMonthlyPaymentsSceneriosHeader>
  )
}
