import React from 'react'
import { StyledNewsFlatMetadata } from './NewsFlatMetadata.styles'
import { NewsMetadataAuthor } from './NewsMetadataAuthor'
import { NewsMetadataCountry } from './NewsMetadataCountry'
import { NewsMetadataDate } from './NewsMetadataDate'

interface INewsFlatMetadataProps {
  author: string
  countryCode: string
  date: string
}

export const NewsFlatMetadata: React.FC<INewsFlatMetadataProps> = ({
  author,
  countryCode,
  date,
}: INewsFlatMetadataProps) => (
  <StyledNewsFlatMetadata>
    <div className="newsMetadataBlock">
      <NewsMetadataCountry countryCode={countryCode} />
    </div>
    <div className="newsMetadataBlock">
      <NewsMetadataAuthor author={author} />
    </div>
    <div className="newsMetadataBlock">
      <NewsMetadataDate date={date} />
    </div>
  </StyledNewsFlatMetadata>
)
