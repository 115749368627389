import { JATOBasicCard, JATOButton } from '@jato/ui-component-library'
import { INewsArticle } from 'models/News/INewsArticle'
import { getNewsCategoryTranslations } from 'models/News/NewsCategoryType'
import { NewsPage } from 'models/News/NewsPageType'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import NewsArticleActions from '../Buttons/NewsArticleActions'
import NewsCategory from '../NewsCategory'
import NewsMetadata from '../NewsMetadata'
import { StyledNewsCard } from './NewsCard.styles'

interface INewsCardProps {
  article: INewsArticle
  allowSelection?: boolean
}

export const NewsCard: React.FC<INewsCardProps> = ({
  article,
  allowSelection = false,
}: INewsCardProps) => {
  const history = useHistory()
  const { newsPage } = useParams<{ newsPage: NewsPage }>()
  const translations = useAppSelector(getTranslations)
  const categoryTranslations = getNewsCategoryTranslations(translations)

  const firstCategory = article.categories[0]
  const categoryName = categoryTranslations[firstCategory] ?? firstCategory

  const parentPage = newsPage ?? firstCategory

  return (
    <StyledNewsCard>
      <JATOBasicCard className="newsCard">
        <div className="newsCardHeader">
          <NewsCategory categories={article.categories} />
          <NewsArticleActions
            article={article}
            allowSelection={allowSelection}
          />
        </div>
        <Link
          id="newsCardOpenArticleLink"
          to={`${RoutePaths.NewsArticles}/${parentPage}/${article.id}`}
        >
          <div className="newsCardMain">
            <div className="newsCardTop">
              <div
                className="newsHeadline"
                dangerouslySetInnerHTML={{ __html: article.headline }}
              />
              <div className="newsSubHeadline">{article.synopsis}</div>
            </div>
            <div className="newsCardBottom">
              <img src={article.mainImageUrl} />
              <NewsMetadata
                author={article.author}
                countryCode={article.countryCode}
                date={article.releaseDate}
              />
            </div>
          </div>
        </Link>
        <div className="newsCardFooter">
          <Link
            id="newsCardViewAllLink"
            to={`${RoutePaths.News}/${firstCategory}`}
            className="newsCardFooterLink"
          >
            {translations.JNT_TLE_ViewAll} {categoryName.toLowerCase()}
          </Link>
          <JATOButton
            variant="ghost"
            size="medium"
            className="newsCardFooterButton"
            id="newsCardReadMoreButton"
            onClick={() =>
              history.push(
                `${RoutePaths.NewsArticles}/${parentPage}/${article.id}`
              )
            }
          >
            {translations.JNT_News_ReadMore}
          </JATOButton>
        </div>
      </JATOBasicCard>
    </StyledNewsCard>
  )
}
