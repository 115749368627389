import styled from 'styled-components'

export const StyledPrivatePolicy = styled.div`
  .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
  .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
    color: #c40005;
  }
  .e-tab .e-tab-header .e-indicator {
    background: #c40005;
  }
  .termHeadLine {
    font-size: 22px;
    font-style: normal;
  }
  .tabItem {
    font-size: 20px;
    text-transform: none;
  }
  .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
    display: inherit;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    align-self: auto;
    margin-bottom: 0;
  }
  .linkSection {
    min-width: max-content;
    padding-right: 40px;
    padding-top: 10px;
    font-size: 15px;
  }

  table,
  td,
  th {
    border-spacing: 0px;
    border: 1px solid rgb(198, 198, 198);
  }

  td,
  th {
    padding: 5px;
  }

  tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
`
