import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import { BasketNavItem } from 'components/Specifications/Basket/BasketNavItem'
import { ResetSpecificationsNavItem } from 'components/Specifications/ResetSpecificationsNavItem/ResetSpecificationsNavItem'
import { SpecsUserSettingsNavItem } from 'components/Specifications/SpecsNavItems/SpecsUserSettingsNavItem/SpecsUserSettingsNavItem'
import { SpecsSidePanels } from 'pages/Specifications'
import React from 'react'
import { SpecsAdvanceFilterNavItem } from '../SpecsNavItems/SpecsAdvanceFilterNavItem/SpecsAdvanceFilterNavItem'

export interface ISpecsNavItemProps {
  onChangeActive: (isActive: boolean) => void
  isActive: boolean
}

export interface ISpecsNavProps {
  setActivePanel: (activePanel: SpecsSidePanels) => void
  activePanel: SpecsSidePanels
}

export const SpecsNavigation: React.FC<ISpecsNavProps> = ({
  setActivePanel: setActivePanel,
  activePanel,
}: ISpecsNavProps) => {
  const setDefaultPanel = (): void =>
    setActivePanel(SpecsSidePanels.SpecsFilters)

  return (
    <NavigationContainer
      left={
        <>
          <SpecsUserSettingsNavItem
            onChangeActive={(isActive) =>
              isActive
                ? setActivePanel(SpecsSidePanels.UserSettings)
                : setDefaultPanel()
            }
            isActive={activePanel === SpecsSidePanels.UserSettings}
          />
          <BasketNavItem />
          <SpecsAdvanceFilterNavItem
            onChangeActive={(isActive) =>
              isActive
                ? setActivePanel(SpecsSidePanels.AdvancedFilters)
                : setDefaultPanel()
            }
            isActive={activePanel === SpecsSidePanels.AdvancedFilters}
          />
        </>
      }
      right={
        <>
          <ResetSpecificationsNavItem />
        </>
      }
    />
  )
}
