import { JATOBasicCard } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { NewsFlatMetadata } from 'components/News/NewsMetadata/NewsFlatMetadata'
import { defaultMissingPhotoUrl } from 'config'
import { firstOrDefault } from 'helper/arrayHelper'
import { useGetMyPageNewsArticle } from 'hooks/news'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import MyPageNewsArticle from '../MyPageNewsArticle'
import { StyledMyPageNewsCard } from './MyPageNewsCard.styles'

interface INewsCardProps {
  article: NewsArticle
}

export const MyPageNewsCard: React.FC<INewsCardProps> = ({
  article,
}: INewsCardProps) => {
  const mainImageUrl = firstOrDefault(
    article.imageLibrary,
    defaultMissingPhotoUrl
  )

  const translations = useAppSelector(getTranslations)

  const [isArticleModalOpen, setArticleModalOpen] = useState(false)
  const { data: articleDetails, isFetching: isArticleLoading } =
    useGetMyPageNewsArticle(article.id, isArticleModalOpen)

  return (
    <>
      <StyledMyPageNewsCard id="myPageNewsArticleLink" onClick={() => setArticleModalOpen(true)}>
        <JATOBasicCard className="newsCard">
          <img className="newsCardImage" src={mainImageUrl} />
          <div>
            <NewsFlatMetadata
              author={article.author}
              countryCode={article.countryCode}
              date={article.releaseDate}
            />
            <div
              className="newsHeadline"
              dangerouslySetInnerHTML={{ __html: article.headline }}
            />
            <div className="newsSubHeadline">{article.synopsis}</div>
          </div>
        </JATOBasicCard>
      </StyledMyPageNewsCard>
      {isArticleModalOpen && (
        <CommonModal
          maxWidth="lg"
          closeButton
          title={article.headline}
          isOpen={isArticleModalOpen}
          onClose={() => setArticleModalOpen(false)}
          primaryButtonName={translations.JNT_Close}
          onPrimaryClick={({ handleClose }) => handleClose()}
        >
          {isArticleLoading ? (
            <Loader />
          ) : (
            articleDetails && <MyPageNewsArticle article={articleDetails} />
          )}
        </CommonModal>
      )}
    </>
  )
}
