import {
  JATOButton,
  JATOLink,
  JATOModal,
  JATOSpinner,
  JATOText,
  JATOTheme,
} from '@jato/ui-component-library'
import { BitmovinPlayer } from 'bitmovin-player-react'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LogoVideosLoader } from 'components/TrainingVideos/TrainingVideos.styles'
import { defaultPlayerConfig } from 'config'
import {
  useGetMpAlertsBetaState,
  useHideMpAlertsBetaWelcomePopup,
} from 'hooks/mpAlertsBeta'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import trainingVideosService from 'services/TrainingVideos/TrainingVideosService'
import { MpAlertsBetaPopupHeader } from './Common/MpAlertsBetaPopupHeader'

export const MpAlertsBetaWelcomePopupLink: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const { data: mpAlertsBeta } = useGetMpAlertsBetaState()
  const { hideWelcomePopup } = mpAlertsBeta ?? {}

  const { mutate: hideMpAlertsBetaWelcomePopup } =
    useHideMpAlertsBetaWelcomePopup()

  const { data: trainingVideo, isFetching } = useQuery<TrainingVideoResponse[]>(
    ['trainingVideo'],
    async () => {
      const response = await trainingVideosService.getTrainingVideoList()
      return response.data
    }
  )

  const onCloseButton = (): void => {
    setShowWelcomePopup(false)
    if (doNotShowAgain !== hideWelcomePopup) {
      hideMpAlertsBetaWelcomePopup(true)
    }
  }

  const [doNotShowAgain, setDoNotShowAgain] = useState(!!hideWelcomePopup)

  const [showWelcomePopup, setShowWelcomePopup] = useState(!hideWelcomePopup)

  return (
    <>
      <JATOLink
        id="mpAlertsBetaHelpLink"
        className="mpAlertsBetaHelp"
        textTransform="none"
        onClick={() => setShowWelcomePopup(true)}
      >
        {translations.JNT_MpAlertsBeta_HelpLink}
      </JATOLink>
      <JATOModal
        maxWidth="md"
        isOpen={mpAlertsBeta?.isEnabled && showWelcomePopup}
        onClose={onCloseButton}
        header={(props) => (
          <MpAlertsBetaPopupHeader
            {...props}
            id="mpAlertsBetaWelcomePopupHeader"
            title={translations.JNT_MpAlertsBeta_WelcomePopupTitle}
          />
        )}
        footer={() => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CheckboxWithGtm
              checked={doNotShowAgain}
              onChange={() => setDoNotShowAgain(!doNotShowAgain)}
              id="mpAlertsBetaWelcomePopup_DoNotShowAgain"
              label={translations.JNT_Do_not_show_me_this_again}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {translations.JNT_Do_not_show_me_this_again}
              </JATOText>
            </CheckboxWithGtm>
            <JATOButton
              id="mpAlertsBetaWelcomePopup_Close"
              variant="primary"
              size="large"
              onClick={onCloseButton}
            >
              {translations.JNT_Close}
            </JATOButton>
          </div>
        )}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_MpAlertsBeta_WelcomePopupText1,
          }}
        />
        <div>
          {isFetching ? (
            <LogoVideosLoader>
              <JATOSpinner spinnerSize={64} />
            </LogoVideosLoader>
          ) : (
            trainingVideo && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <div style={{ width: '50%' }}>
                  <BitmovinPlayer
                    config={defaultPlayerConfig}
                    source={{
                      hls: trainingVideo[6].videoList[0]
                        .streamingEndPointAddress,
                    }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <BitmovinPlayer
                    config={defaultPlayerConfig}
                    source={{
                      hls: trainingVideo[8].videoList[0]
                        .streamingEndPointAddress,
                    }}
                  />
                </div>
              </div>
            )
          )}
        </div>
        <br />
        <JATOLink
          id="mpAlertsBetaWelcomePopup_JATOAcademyLink"
          href="https://training.jato.com/learn/course/591/play/1776/monthly-payments-news-introduction;lp=40"
          textTransform="none"
          target="_blank"
        >
          {translations.JNT_MpAlertsBeta_WelcomePopupLink}
        </JATOLink>
        <br />
        <br />
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_MpAlertsBeta_WelcomePopupText2,
          }}
        />
      </JATOModal>
    </>
  )
}
