import {
  JATOBasicCard,
  JATOHeading,
  JATOLink,
  JATOTheme,
} from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { useGetMyPageNews, useGetNewsCountries } from 'hooks/news'
import React from 'react'
import { Col, Row } from 'react-grid-system'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { RoutePaths } from 'router/RoutePaths'
import MyPageNewsCard from '../MyPageNewsCard'
import { StyledMyPageLatestIndustryVolumesInsights } from './MyPageLatestIndustryVolumesInsights.styles'

export const MyPageLatestIndustryVolumesInsights: React.FC = () => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const { guid, languageId } = commonUserData

  const { data, isFetching: isFetchingNews } = useGetMyPageNews()
  const { isFetching: isNewsCountriesFetching } = useGetNewsCountries()
  const isLoading = isFetchingNews || isNewsCountriesFetching

  return (
    <StyledMyPageLatestIndustryVolumesInsights>
      {isLoading ? (
        <Loader />
      ) : (
        data &&
        (data?.MA?.length > 0 || data?.EV?.length > 0) && (
          <>
            <JATOBasicCard>
              <JATOHeading as="h3" fontWeight="light" marginBottom="30px">
                JATO's latest Industry Volumes Insights
              </JATOHeading>
              <Row>
                <Col sm={6}>
                  <JATOHeading as="h3">Country Registrations</JATOHeading>
                  {data?.MA?.map((article, index) => (
                    <MyPageNewsCard key={index} article={article} />
                  ))}
                </Col>
                <Col sm={6}>
                  <JATOHeading as="h3" color={JATOTheme.colors.blue}>
                    Electric Vehicle Registrations
                  </JATOHeading>
                  {data?.EV?.map((article, index) => (
                    <MyPageNewsCard key={index} article={article} />
                  ))}
                </Col>
              </Row>
            </JATOBasicCard>
            <JATOHeading as="h3" fontWeight="light" marginTop="20px">
              Note: The electric volumes above include both BEV and PHEV
              powertrains.
            </JATOHeading>
            <JATOHeading as="h3" fontWeight="light" marginBottom="20px">
              For further news articles click{' '}
              <JATOLink
                id="myPageNewsBottomTextLink"
                className="bottomTextLink"
                textTransform="none"
                href={`${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.News}`}
              >
                here
              </JATOLink>{' '}
              and for detailed volume analyses click{' '}
              <JATOLink
                id="myPageVolumesBottomTextLink"
                className="bottomTextLink"
                textTransform="none"
                href={`${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.Volumes}`}
              >
                here
              </JATOLink>
            </JATOHeading>
          </>
        )
      )}
    </StyledMyPageLatestIndustryVolumesInsights>
  )
}
