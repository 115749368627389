import styled from 'styled-components'
import Theme from 'theme'

export const StyledMonthlyPayments = styled.div`
  #vehicle-grid-container {
    //display: flex;
    gap: 15px;
    flex-flow: row wrap;
    justify-content: center;
    @media (min-width: ${Theme.breakpoints[1]}px) {
      justify-content: start;
    }
  }
  .ej-panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    height: 40px;
  }
  .ej-panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #1b1d1f;
  }

  .ej-panel {
    background-color: #ffffff;
    box-shadow:
      0 3px 6px rgb(0 0 0 / 16%),
      0 3px 6px rgb(0 0 0 / 23%);
  }

  .ej-panel-left {
    display: flex;
    justify-content: flex-end;
  }

  .scrollable-container {
    overflow-y: auto;
    height: calc(100vh - 160px);
  }
`
