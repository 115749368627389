import { MutableRefObject, useRef } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// eslint-disable-next-line
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// useSelector without re-render
export const useSelectorRef = <T = unknown>(
  selectHandler: (state: RootState) => T
): MutableRefObject<T> => {
  const ref = useRef<T>()

  useAppSelector<T>(selectHandler, (_, b) => {
    ref.current = b
    return true
  })

  return ref as MutableRefObject<T>
}
