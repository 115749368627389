import CookieConsent from 'react-cookie-consent'
import { StyledJATOCookie } from './JATOCookie.styles'
import React from 'react'
import { JATOLink } from '@jato/ui-component-library'
import { useTranslations } from 'hooks/translation'
import { privacyPolicyUrl } from 'config'

export const JATOCookie: React.FC = () => {
  const { data: translationsResponse, isFetching } = useTranslations()
  return (
    <StyledJATOCookie>
      {!isFetching && (
        <CookieConsent
          location="bottom"
          buttonText={
            translationsResponse?.appTextTranslation
              .JNT_Portal_CookieConsent_Dismiss
          }
          cookieName="jatonet_cookieconsent_status"
          style={{ background: '#191919', fontSize: '16px' }}
          buttonStyle={{
            background: '#c40005',
            color: '#fff',
            fontSize: '17px',
            borderRadius: '10px',
            padding: '15px 40px',
          }}
        >
          <span style={{ fontSize: '16px', paddingLeft: '100px' }}>
            {
              translationsResponse?.appTextTranslation
                .JNT_Portal_CookieConsent_Message
            }
          </span>
          <span>
            <JATOLink
              href={privacyPolicyUrl}
              target="_blank"
              textTransform="none"
              style={{ paddingLeft: '5px' }}
            >
              {
                translationsResponse?.appTextTranslation
                  .JNT_Portal_CookieConsent_LinkText
              }
            </JATOLink>
          </span>
        </CookieConsent>
      )}
    </StyledJATOCookie>
  )
}
