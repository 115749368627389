import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsBookMarkArticleViewAll = styled.div`
  .articleLink {
    font-size: 14px;
    font-weight: 500;
    color: ${JATOTheme.colors.blue};
  }
`
