import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import {
  azureAppInsightsCorrelationHeaderExcludedDomains,
  azureAppInsightsSamplingPercentage,
  environment,
} from 'config'
import { commaSeparatedStringToStringArray } from 'helper/stringHelper'

const fetchAppInsightsConnectionString = (): string | undefined => {
  let connectionString: string | undefined
  if (environment === 'dev') {
    connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING_DEV
  } else if (environment === 'qa') {
    connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING_QA
  } else if (environment === 'live') {
    connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING_LIVE
  }
  if (!connectionString) {
    console.error('App Insights connection string is not defined!')
  }
  return connectionString
}

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: fetchAppInsightsConnectionString(),
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: commaSeparatedStringToStringArray(
      azureAppInsightsCorrelationHeaderExcludedDomains
    ),
    samplingPercentage: Number(azureAppInsightsSamplingPercentage),
  },
})
appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || []
  env.tags['ai.cloud.role'] = 'JATONetMP'
})

export { reactPlugin, appInsights }
