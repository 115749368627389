import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesMenuItemButton = styled.div`
  .menuHeaderItem {
    font-size: ${JATOTheme.fontSizes[1]}px;
    cursor: pointer;
    text-wrap: wrap;
    text-transform: none;
    padding-right: 8px;
  }

  .menuHeaderIcon {
    margin-right: ${JATOTheme.space[2]}px;
  }

  .menuHeaderIcon img,
  .menuHeaderIcon svg {
    vertical-align: middle;
  }
`
