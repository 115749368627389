import { PDFViewer } from '@react-pdf/renderer'
import { ModalComponent } from 'components/ModalComponent'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getNewsCountries } from 'redux/news/newsSlice'

import { NewsArticlePdfDocument } from './NewsArticlePdfDocument/NewsArticlePdfDocument'
import { StyledNewsArticlePdfExportModal } from './NewsArticlePdfExportModal.styles'
import { getTranslations } from 'redux/translations/translationsSlice'

interface INewsArticlePdfExportModalProps {
  article: NewsArticle
  isOpen: boolean
  title: string
  onClose: () => void
}

export const NewsArticlePdfExportModal: React.FC<
  INewsArticlePdfExportModalProps
> = ({ article, isOpen, title, onClose }: INewsArticlePdfExportModalProps) => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const translations = useAppSelector(getTranslations)
  const countries = useAppSelector(getNewsCountries)

  return (
    <StyledNewsArticlePdfExportModal>
      <ModalComponent
        maxWidth="lg"
        modalVisible={isOpen}
        closeButton
        title={title}
        onSecondaryClick={onClose}
      >
        <PDFViewer showToolbar width="1128px" height="740px">
          <NewsArticlePdfDocument
            article={article}
            countries={countries}
            translations={translations}
            languageId={commonUserData.languageId}
          />
        </PDFViewer>
      </ModalComponent>
    </StyledNewsArticlePdfExportModal>
  )
}
