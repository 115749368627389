import { JATOLogo } from '@jato/ui-component-library'
import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import Theme from 'theme'
import {
  StyledHorizontalNav,
  StyledHorizontalNavBrand,
  StyledHorizontalNavInner,
} from './LoginHeader.styles'
import { LanguageSelection } from 'components/Portal/LanguageSelection'

export interface IRoute {
  /** Route name/label */
  name: string
  /** Route path */
  path: string
  isActive: boolean
  badge?: Badge
}

export interface Badge {
  badgeText: string
  isActive: boolean
}

export interface IHeaderProps {
  /** Callback function to use when the user changes language */
  handleChangeLanguage: (language: string) => void
  /** Callback function to use when the user clicks the drawer button */
  handleToggleDrawer: () => void
  /** Determines if the user is authenticated */
  /** Determines if the drawer is open/closed */
  isDrawerOpen: boolean
  /** Currently selected language */
  language: string
  /** Navigation routes to display in the header */
  navRoutes: IRoute[]
  // navMmRoutes: IRoute[]
}

export const LoginHeader: React.FC<IHeaderProps> = ({
  handleToggleDrawer,
  navRoutes,
}: // navMmRoutes,
IHeaderProps) => {
  const isAuthenticated = true

  return (
    <Fragment>
      {/* Nav bar */}
      <StyledHorizontalNav>
        <Container xl lg md sm xs style={{ height: '100%' }}>
          <Row style={{ height: '100%' }}>
            <Col xs={9} style={{ height: '100%' }}>
              <StyledHorizontalNavInner>
                <StyledHorizontalNavBrand>
                  <JATOLogo
                    style={{
                      zIndex: Theme.zIndices.drawer - 1,
                    }}
                  />
                </StyledHorizontalNavBrand>
              </StyledHorizontalNavInner>
            </Col>
            <Col xs={3} style={{ display: 'flex', justifyContent: 'end' }}>
              <StyledHorizontalNavInner>
                <div className="rightNavGroup">
                  <LanguageSelection />
                </div>
              </StyledHorizontalNavInner>
            </Col>
          </Row>
        </Container>
      </StyledHorizontalNav>
    </Fragment>
  )
}
