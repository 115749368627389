import { LoaderModal } from 'components/Loader/LoaderModal'
import { useNewsAlert } from 'hooks/news'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { NewsAddNewAlertButton } from '../Buttons/NewsAlertButton/NewsAddNewAlertButton'
import NewsBreadcrumbs from '../NewsBreadcrumbs'
import { StyledNewsAlertsViewAll } from './NewsAlertsViewAll.styles'
import { NewsNoAlertFound } from './NewsNoAlertFound'
import {
  NewsSavedSearchAndAlertColumnType,
  NewsSavedSearchAndAlertGrid,
} from './NewsSavedSearchAndAlertGrid'

export const NewsAlertsViewAll: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const { data: savedSearches, isFetching } = useNewsAlert()

  const defaultColumns = [
    NewsSavedSearchAndAlertColumnType.AlertName,
    NewsSavedSearchAndAlertColumnType.Category,
    NewsSavedSearchAndAlertColumnType.Country,
    NewsSavedSearchAndAlertColumnType.Make,
    NewsSavedSearchAndAlertColumnType.Model,
    NewsSavedSearchAndAlertColumnType.VehicleType,
    NewsSavedSearchAndAlertColumnType.Language,
    NewsSavedSearchAndAlertColumnType.Frequency,
    NewsSavedSearchAndAlertColumnType.AlertStartDate,
    NewsSavedSearchAndAlertColumnType.Status,
    NewsSavedSearchAndAlertColumnType.AlertActions,
  ]

  return (
    <StyledNewsAlertsViewAll>
      <Container fluid>
        <Row>
          <Col md={12}>
            <NewsBreadcrumbs title={translations.JNT_NewsAlerts} />
          </Col>
        </Row>
      </Container>
      {savedSearches && savedSearches.length == 0 && (
        <NewsNoAlertFound
          iconName="baseline_notifications_none"
          text={translations.JNT_NoAlerts}
        />
      )}
      {!isFetching && savedSearches && savedSearches.length > 0 && (
        <>
          <Container fluid>
            <Row>
              <Col md={12}>
                <NewsAddNewAlertButton />
              </Col>
            </Row>
          </Container>
          <NewsSavedSearchAndAlertGrid
            savedSearches={savedSearches}
            columns={defaultColumns}
          />
        </>
      )}
      <LoaderModal isOpen={isFetching} />
    </StyledNewsAlertsViewAll>
  )
}
