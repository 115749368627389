import styled from 'styled-components'

export const StyledMpInsuranceFilter = styled.div`
  .radioGroup {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 15px;
  }
`
