export enum FilterNameType {
  Country = 'Country',
  Make = 'Make',
  Model = 'Model',
  ModelYear = 'Model Year',
  TimeRange = 'Time Range',
  Transmission = 'Transmission',
  PowerTrain = 'Powertrain Type',
  Fuel = 'Fuel Type',
  NumberOfDoors = '# of Doors',
  BodyType = 'Body Type',
  DrivenWheels = 'Driven Wheels',
  Segmentation = 'Segmentation',
  DataDateStart = 'Data Date Start',
  DataDateEnd = 'Data Date End',
  ResearchDate = 'Research Date',
  ContractType = 'Contract Type',
  ProductName = 'Product Name',
  ContractLength = 'Contract Length',
  MilesKilometers = 'Miles / Kilometres',
  YearlyMileage = 'Yearly Mileage (%s)',
  TotalContractMileage = 'Total Contract Mileage (%s)',
  Deposit = 'Deposit - MSRP',
  NumberOfMonthlyInstalments = '# of monthly instalments',
  RegularMonthlyInstalmentAmount = 'Regular Monthly Instalment Amount',
  Insurance = 'Insurance',
  Uid = 'UID',
}
