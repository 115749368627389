export const getMPEmailSubject = (): string => 'JATONet Monthly Payments Export'

export const getSpecsEmailSubject = (): string => 'JATONet Specs Export'

export const getMessageBody = (url: string): string => {
  let messageBody = 'Dear user,'
  messageBody += '<br>'
  messageBody += '<br>'
  messageBody += 'Your request for export to file has been successful.'
  messageBody += '<br>'
  messageBody += '<br>'
  messageBody += 'Please click on the following link to download your file.'
  messageBody += '<br>'
  messageBody += "<a href='" + url + "'>" + url + '</a>'
  messageBody += '<br>'
  messageBody += '<br>'
  messageBody += 'Please note:'
  messageBody += '<br>'
  messageBody +=
    'Your file will be accessible for 5 days after your query was run.'
  messageBody += '<br>'
  messageBody += '<br>'
  messageBody += 'Kind regards,'
  messageBody += '<br>'
  messageBody += 'JATONet Team'

  return messageBody
}
