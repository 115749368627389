import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledMyPage = styled.div`
  background-color: ${JATOTheme.colors.white};
  margin: 0 -15px;

  .myPageContainer {
    height: calc(100vh - 160px);
    overflow-y: auto;
  }

  .accordion-container {
    border: 1px solid ${JATOTheme.colors.midGrey};
    font-size: 14px;
    margin: 15px 0;
  }

  .accordion-content {
    height: 275px;
    overflow-y: auto;
  }
`
