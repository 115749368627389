import {
  JATOButton,
  JATODropdown,
  JATODropdownMenu,
} from '@jato/ui-component-library'
import basketIcon from 'assets/icons/icon_basket_fill.svg'
import { ModalComponent } from 'components/ModalComponent'
import { IBasket } from 'models/Basket/Basket'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import basketManagerService from 'services/BasketManager/BasketManagerService'
import BasketContainer from '../../MonthlyPayments/BasketContainer'
import SaveBasket from '../../MonthlyPayments/SaveBasket'

export const BasketNavItem: React.FC = () => {
  const [selectedBasketTab, setSelectedBasketTab] = useState('User Baskets')

  const handleBasketTabChange = (title: string): void => {
    setSelectedBasketTab(title)
  }

  const [modalBasketContainerVisible, setModalBasketContainerVisible] =
    useState(false)
  const [modalSaveBasketVisible, setModalSaveBasketVisible] = useState(false)

  const handleOnOpenBasketContainer = (): void => {
    setModalBasketContainerVisible(true)
  }

  const handleOnSaveBasketPopUp = (): void => {
    setModalSaveBasketVisible(true)
  }

  const handleOnClose = (): void => {
    setModalBasketContainerVisible(false)
    setModalSaveBasketVisible(false)
  }

  const { data: allBasketData }: any = useQuery<IBasket[]>(
    'mpBasketData',
    async () => {
      const { data } = await basketManagerService.getBasketData()
      return data
    },
    {
      enabled: modalBasketContainerVisible || modalSaveBasketVisible,
      staleTime: 2,
      refetchInterval: 0,
      cacheTime: 0,
    }
  )

  return (
    <>
      <JATODropdown
        trigger={() => (
          <span title="Basket" style={{ cursor: 'pointer' }}>
            <JATOButton
              id="monthlyPaymentsBasketMenuButton"
              variant="ghost"
              style={{ position: 'relative', zIndex: -1, marginLeft: '-10px' }}
            >
              <img width="22" src={basketIcon} />
            </JATOButton>
          </span>
        )}
      >
        <JATODropdownMenu className="dropdown">
          <JATOButton
            id="monthlyPaymentsBasketMenuOpenButton"
            size="medium"
            onClick={handleOnOpenBasketContainer}
          >
            Open
          </JATOButton>
          <JATOButton
            id="monthlyPaymentsBasketMenuSaveButton"
            size="medium"
            onClick={handleOnSaveBasketPopUp}
          >
            Save
          </JATOButton>
        </JATODropdownMenu>
      </JATODropdown>
      <ModalComponent
        modalVisible={modalBasketContainerVisible}
        closeButton
        title={selectedBasketTab}
        maxWidth="lg"
        primaryButtonName=""
        onSecondaryClick={handleOnClose}
        secondaryButtonName=""
        onPrimaryClick={handleOnOpenBasketContainer}
        isPrimaryButtonLoading={false}
        dismissable={false}
      >
        <BasketContainer
          baskets={allBasketData}
          onCloseEvent={handleOnClose}
          setBasketTabHandler={handleBasketTabChange}
          selectedBasketTab={selectedBasketTab}
        />
      </ModalComponent>
      <ModalComponent
        modalVisible={modalSaveBasketVisible}
        closeButton
        title="Add Basket"
        primaryButtonName=""
        onSecondaryClick={handleOnClose}
        secondaryButtonName=""
        onPrimaryClick={handleOnSaveBasketPopUp}
        isPrimaryButtonLoading={false}
        dismissable={false}
      >
        <SaveBasket baskets={allBasketData} onCloseEvent={handleOnClose} />
      </ModalComponent>
    </>
  )
}
