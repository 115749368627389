import { defaultArticlesInGridNumber, getArticles } from 'helper/newsHelper'
import { useBookMarkArticles } from 'hooks/news'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsBreadcrumbs from '../NewsBreadcrumbs'

import { JATOButtonGroup, JATONavBar } from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import NewsArticleSelection from '../Buttons/NewsArticleSelection'
import NewsGrid, { NewsGridColumnType } from '../NewsGrid'
import { StyledNewsBookMarkArticleViewAll } from './NewsBookMarkArticleViewAll.styles'

export const NewsBookMarkArticleViewAll: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const translations = useAppSelector(getTranslations)

  const { data: articlesResponse, isFetching } = useBookMarkArticles(
    defaultArticlesInGridNumber,
    currentPage,
    ['bookMarkDate desc']
  )

  const gridColumns = [
    NewsGridColumnType.Headline,
    NewsGridColumnType.Author,
    NewsGridColumnType.Country,
    NewsGridColumnType.ReleaseDate,
    NewsGridColumnType.BookMarkDate,
    NewsGridColumnType.RemoveBookmarkAction,
  ]

  return (
    <StyledNewsBookMarkArticleViewAll>
      <Container fluid>
        <Row>
          <Col md={12}>
            <JATONavBar
              left={() => (
                <NewsBreadcrumbs title={translations.JNT_Favourites} />
              )}
              right={() => (
                <JATOButtonGroup space={4}>
                  <NewsArticleSelection />
                </JATOButtonGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {articlesResponse && (
              <NewsGrid
                articles={getArticles(articlesResponse.articleList)}
                currentPage={currentPage}
                totalNumber={articlesResponse.totalNumber}
                onPageChange={setCurrentPage}
                columns={gridColumns}
                allowSelection={true}
                isFetching={isFetching}
              />
            )}
          </Col>
        </Row>
      </Container>
      <LoaderModal isOpen={isFetching && !articlesResponse} />
    </StyledNewsBookMarkArticleViewAll>
  )
}
