export interface DetailedAction {
  databaseName?: string
  userInstruction: InstructionType
  vehicleId: number
  optionId?: number
  optionCode?: string
  secondaries?: string[]
  basketId?: number
  shelfId?: number
  secondariesData?: SecondaryOptionsData[]
}

export interface SecondaryOptionsData {
  optionId: number
  sourceIsIncludes: boolean
}

export enum InstructionType {
  AddVehicle = 0,
  BuildOption = 1,
  UnbuildOption = 2,
  RemoveVehicle = 3,
  SetBenchmark = 4,
  MasterComparableEquipment = 5,
  ResetAll = 6,
  BuildBasket = 7,
  BuildShelf = 8,
}
