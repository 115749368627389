import { IRadioStyles, JATORadio } from '@jato/ui-component-library'
import { IDefaultProps } from '@jato/ui-component-library/dist/types/types'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { GtmWrapper } from './GtmWrapper'

interface IGtmCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    IDefaultProps,
    IRadioStyles {
  id: string
  name?: string
  value?: string
  label: string
  defaultChecked?: boolean
  checked?: boolean
  onChange?: (event: React.ChangeEvent) => void
  onKeyPress?: (event: React.KeyboardEvent) => void
  parentLabel?: string
}

export const RadioWithGtm: React.FC<IGtmCheckboxProps> = (
  props: IGtmCheckboxProps
) => (
  <GtmWrapper
    id={props.id}
    label={props.label}
    parentLabel={props.parentLabel}
    value={props.value}
  >
    <JATORadio {...props} id={uuid()} />
  </GtmWrapper>
)
