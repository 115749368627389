import FavouriteIcon from 'assets/icons/icon_favourite.svg'
import UnFavouriteIcon from 'assets/icons/icon_unfavourite.svg'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React from 'react'
import NewsImgButton from '../../../CommonButtons/ImgButton'

export interface INewsBookmarkButtonProps {
  article: NewsArticle
  title: string
  onChange: () => void
}

export const NewsBookmarkButton: React.FC<INewsBookmarkButtonProps> = ({
  article,
  title,
  onChange,
}: INewsBookmarkButtonProps) => (
  <NewsImgButton
    id="newsBookmarkButton"
    src={article.isBookMarked ? UnFavouriteIcon : FavouriteIcon}
    title={title}
    onClick={onChange}
  />
)
