export interface OptionsBuildRuleResponse {
  ledger: OptionTextInfo[]
  tree: VisualizeOptionsV3[]
}

export interface OptionTextInfo {
  optionId: number
  optionCode: string
  translatedOptionText: string
  optionDescription: option_text[]
  optionPrices: OptionPriceSchemas
}

export interface OptionPriceSchemas {
  defaultMarketPrice: number | null
  defaultMarketDisplayPrice: string
  id_902: number | null
  id_903: number | null
  id_904: number | null
  id_905: number | null
  id_100902: number | null
  id_100903: number | null
  id_100904: number | null
  id_100905: number | null
}

export interface option_text {
  vehicle_id: number
  language_id: number
  option_id: number
  order_id: number
  content: string
}

export interface VisualizeOptionsV3 {
  optionId: number
  choiceOptions: { [key in RuleTypeKey]: number[][] }
  conditions: ConditionsV2[]
  excludedOptions: number[]
  optionCode: string
  translatedOptionText: string
  optionDescription: option_text[]
  optionPrices: OptionPriceSchemas
}
export enum RuleType {
  Includes = 100003,
  requires = 100006,
  Excludes = 100007,
  NewPrice = 100008,
  Discount = 100009,
}
export type RuleTypeKey = keyof typeof RuleType

export interface ConditionsV2 {
  conditionToShow: string
  conditionToEvaluate: string
  ruleType: RuleType
  permutations: number[][]
}
