import { JATOButton, JATOButtonGroup } from '@jato/ui-component-library'
import { CommonModal } from 'components/Modals/CommonModal'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { Baskets } from './Baskets'

interface IBasketContainerProps {
  isOpen: boolean
  onClose: () => void
}

enum Tabs {
  UserBaskets = 'User Baskets',
  SharedBaskets = 'Shared Baskets',
}

export const BasketsModal: React.FC<IBasketContainerProps> = ({
  isOpen,
  onClose,
}: IBasketContainerProps) => {
  const translations = useAppSelector(getTranslations)
  const [selectedTab, setSelectedTab] = useState(Tabs.UserBaskets)

  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      closeButton
      maxWidth="lg"
      title={selectedTab}
    >
      <JATOButtonGroup justifyContent={'initial'}>
        <JATOButton
          id="specsBasketsUserBasketsTabButton"
          variant={selectedTab === Tabs.UserBaskets ? 'primary' : 'ghost'}
          display="inline-flex"
          onClick={() => setSelectedTab(Tabs.UserBaskets)}
          size={'medium'}
        >
          {translations.JNT_MyBasket}
        </JATOButton>
        <JATOButton
          id="specsBasketsSharedBasketsTabButton"
          variant={selectedTab === Tabs.SharedBaskets ? 'primary' : 'ghost'}
          display="inline-flex"
          onClick={() => setSelectedTab(Tabs.SharedBaskets)}
          size={'medium'}
        >
          {translations.JNT_basshare}
        </JATOButton>
      </JATOButtonGroup>
      <div style={{ width: '100%', minHeight: '561px' }}>
        <Baskets isUserBaskets={selectedTab === Tabs.UserBaskets} />
      </div>
    </CommonModal>
  )
}
