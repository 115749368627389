import Loader from 'components/Loader'
import { baseUrl } from 'config'

import { gtmLogEmailDomain } from 'helper/gtm'
import { showErrorToast } from 'helper/toastHelper'
import { useResetBuiltVehiclesAndFilters } from 'hooks/carSpecs'
import { useTranslations } from 'hooks/translation'
import { LoginObject } from 'models/Login/LoginObject'
import { LoginParameters } from 'models/Login/LoginParameters'
import { LoginRequest } from 'models/Login/LoginRequest'
import { UserState } from 'models/Login/NewsLogin/UserState'
import { NewsArticleRequest } from 'models/News/Homepage/NewsArticleRequest'
import { NewsPageViewType } from 'models/News/NewsPageViewType'
import { GetLicenceInfoRequest } from 'models/Specifications/GetLicenceInfoRequest'
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  selectCommonUserData,
  setCommonUserState,
} from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { resetMpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import { resetFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import { fetchUserDataAsync } from 'redux/monthlypayments/userDataSlice'
import {
  resetNewsUserState,
  setNewsCountries,
  setNewsUserState,
} from 'redux/news/newsSlice'
import {
  resetFiltersToDefaults,
  resetSpecsUserState,
  setCurrencyLanguageInfoState,
  setSpecsUserState,
} from 'redux/specifications/specificationsSlice'
import { setVolumesUserState } from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import newsService from 'services/News/NewsService'
import signalRService from 'services/SignalRService'
import specificationsService from 'services/Specifications/SpecificationsService'
import volumesService from 'services/Volumes/VolumesService'
import MPLoadingImage from '../NoLicense/images/NoLicenseMonthlyPayments.png'
import NewsLoadingImage from '../NoLicense/images/NoLicenseNews.png'
import SpecsLoadingImage from '../NoLicense/images/NoLicenseSpecifications.png'
import VolumesLoadingImage from '../NoLicense/images/NoLicenseVolumes.png'
import MyPageLoadingImage from '../NoLicense/images/mypage_loading.png'
import { StyledAutoLogin } from './AutoLogin.styles'

// Auto Login for both specs and monthly payments pages (i.e. pages under the react project.)
export const AutoLogin: React.FC = () => {
  const history = useHistory()
  const { guid, languageId, path } = useParams<LoginParameters>()
  const commonUserData = useAppSelector(selectCommonUserData)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const redirectUrl = searchParams.get('redirectUrl') ?? ''

  const { isLoading: isLoadingTranslations } = useTranslations()

  const { mutateAsync: resetBuiltVehiclesAndFilters } =
    useResetBuiltVehiclesAndFilters()

  const dispatch = useAppDispatch()

  const fetchMPUserData = (): void => {
    const loginData: LoginRequest = {
      isJatoNet: true,
      guid: guid,
      isJatoSpecs: true,
      languageId: parseInt(languageId),
      username: '',
      password: '',
    }
    dispatch(fetchUserDataAsync(loginData)).then((result: any) => {
      if (result.type === 'userData/fetchUserData/fulfilled') {
        if (result.payload.data.isSuccess) {
          dispatch(
            resetFilters(result.payload.data.user.settings.lastSelectedMarket)
          )
          dispatch(resetMpVehicles(false))

          gtmLogEmailDomain(result.payload.data.user.email)

          history.push(`/${path}${redirectUrl}`)
        } else if (
          !result.payload.data.isSuccess &&
          result.payload.data.accessToken !== null
        ) {
          history.push(RoutePaths.MPNoLicense)
        } else if (
          !result.payload.data.isSuccess &&
          result.payload.data.accessToken === null
        ) {
          window.location.href = `${baseUrl}`
        }
      }
    })
  }

  const fetchSpecsUserData = async (): Promise<void> => {
    await resetBuiltVehiclesAndFilters()
    const getLicenceInfo: GetLicenceInfoRequest = {
      languageId: Number(languageId),
      isJatoNet: true,
      guid: guid,
      isJatoSpecs: true,
    }

    const data = await specificationsService.getLicenceInfo(getLicenceInfo)
    if (data.isSuccess) {
      const defaultMarket =
        data.user?.specsDbMarkets && data.user?.specsDbMarkets[0]?.key

      const userStateUpdate = data.user?.settings?.lastSelectedMarket
        ? data.user
        : {
            ...data.user,
            settings: {
              ...data.user?.settings,
              lastSelectedMarket: defaultMarket,
            },
          }

      dispatch(setSpecsUserState(userStateUpdate))
      dispatch(setCurrencyLanguageInfoState(data.currencyLanguageInfo))

      if (data.user?.specsDbMarkets.length == 0) {
        history.push(RoutePaths.SpecsNoLicense)
        return
      }

      gtmLogEmailDomain(data.user.email)
    }

    history.push(`/${path}${redirectUrl}`)
  }

  const fetchNewsUserData = async (): Promise<void> => {
    const loginRequest: LoginObject = {
      username: '',
      password: '',
      languageId: Number(languageId),
      guid: guid,
      isJatoNet: true,
      isJatoSpecs: false,
    }

    const data = await newsService.login(loginRequest)

    if (!data?.user?.isauthenticated) {
      showErrorToast('Authentication error', data?.user?.responseMessage)
      dispatch(resetNewsUserState())
      history.push(`/${path}${redirectUrl}`)
      return
    }

    if (data.user.isLicencedNews) {
      const updatedUserState: UserState = {
        ...data.user,
        token: data.token,
        userSpecificSettings: {
          ...data.user.userSpecificSettings,
          pageViewType:
            data.user.userSpecificSettings.pageViewType ??
            NewsPageViewType.Cards,
        },
      }
      dispatch(setNewsUserState(updatedUserState))

      gtmLogEmailDomain(data.user.email)

      const countriesRequest: NewsArticleRequest = {
        languageId: Number(languageId),
      }
      const countries = await newsService.getCountries(countriesRequest)
      dispatch(setNewsCountries(countries))

      history.push(`/${path}${redirectUrl}`)
    } else {
      dispatch(setNewsUserState(data.user))
      history.push(RoutePaths.NewsNoLicense)
    }
  }

  const fetchVolumesUserData = async (): Promise<void> => {
    const loginRequest: LoginObject = {
      username: '',
      password: '',
      languageId: Number(languageId),
      guid: guid,
      isJatoNet: true,
      isJatoSpecs: false,
    }

    const data = await volumesService.login(loginRequest)
    data.languageId = loginRequest.languageId

    dispatch(setVolumesUserState(data))

    if (!data.isAuthenticated) {
      showErrorToast('Authentication error', data.responseMessage)
      history.push(`/${path}${redirectUrl}`)
      return
    }

    if (
      data.isVolumesLicenecV ||
      data.isVolumesLicenecM ||
      data.isVolumesLicenecX
    ) {
      gtmLogEmailDomain(data.userName)

      signalRService.setUpSignalRConnection(data.userId)

      history.push(`/${path}${redirectUrl}`)
    } else {
      history.push(RoutePaths.VolumesNoLicense)
    }
  }

  const storeCommonUserData = (): void => {
    dispatch(setCommonUserState({ ...commonUserData, guid: guid }))
  }

  const getLoadingImage = (): string => {
    switch ('/' + path) {
      case RoutePaths.MonthlyPayments:
        return MPLoadingImage
      case RoutePaths.Specifications:
        return SpecsLoadingImage
      case RoutePaths.News:
        return NewsLoadingImage
      case RoutePaths.Volumes:
        return VolumesLoadingImage
      case RoutePaths.MyPage:
        return MyPageLoadingImage
    }

    return MPLoadingImage
  }

  const resetSpecsStateValues = (): void => {
    dispatch(resetFiltersToDefaults())
    dispatch(resetSpecsUserState())
  }

  useEffect(() => {
    storeCommonUserData()
    if (!isLoadingTranslations) {
      switch ('/' + path) {
        case RoutePaths.MonthlyPayments:
          fetchMPUserData()
          break
        case RoutePaths.Specifications:
          resetSpecsStateValues()
          fetchSpecsUserData()
          break
        case RoutePaths.News:
          fetchNewsUserData()
          break
        case RoutePaths.Volumes:
          fetchVolumesUserData()
          break
        case RoutePaths.MyPage:
          fetchVolumesUserData()
      }
    }
  }, [isLoadingTranslations])

  return (
    <StyledAutoLogin loadingImage={getLoadingImage()}>
      <Loader />
    </StyledAutoLogin>
  )
}
