import { dataDefinitionsUrl } from 'config'
import { useRequestDataDefinitions } from 'hooks/specsDataDefinitions'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledSpecificationsDataDefinitionTooltip } from './SpecificationsDataDefinitionTooltip.styles'

interface IProps {
  schemaIds: number[]
}

export const SpecificationsDataDefinitionTooltip: React.FC<IProps> = ({
  schemaIds,
}: IProps) => {
  const translations = useAppSelector(getTranslations)

  const { data, isFetching: isFetchingDataDefinitions } =
    useRequestDataDefinitions(schemaIds)

  return (
    <StyledSpecificationsDataDefinitionTooltip>
      <span>
        {isFetchingDataDefinitions
          ? '...' + translations.JNT_Specs_Loading
          : data?.dataDefinitionResults.length === 0
            ? translations.JNT_Specs_NoDataDefinitionAvailable
            : data?.dataDefinitionResults.map(
                (dataDefinition, dataDefinitionIndex, array) => (
                  <React.Fragment key={dataDefinitionIndex}>
                    {dataDefinition.breadcrumbs
                      .sort((a, b) => a.position - b.position)
                      .map((breadCrumb, breadCrumbIndex) => (
                        <span key={breadCrumbIndex}>
                          {breadCrumb.title}
                          {breadCrumbIndex !==
                          dataDefinition.breadcrumbs.length - 1
                            ? ' > '
                            : ''}
                        </span>
                      ))}
                    <br />
                    <br />
                    {dataDefinition.description + ' '}
                    <a
                      id="specsReportDataDefinitionTooltipReadMoreLink"
                      href={dataDefinitionsUrl + dataDefinition.schemaId}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tooltipReadMoreLink"
                    >
                      {translations.JNT_Specs_ReadMoreHere}
                    </a>
                    {dataDefinitionIndex !== array.length - 1 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </React.Fragment>
                )
              )}
      </span>
    </StyledSpecificationsDataDefinitionTooltip>
  )
}
