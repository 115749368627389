import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getNewsSelectedArticles,
  setNewsSelectedArticles,
} from 'redux/news/newsSlice'

export interface INewsSelectArticleCheckboxProps {
  id: number
}

export const NewsSelectArticleCheckbox: React.FC<
  INewsSelectArticleCheckboxProps
> = ({ id }: INewsSelectArticleCheckboxProps) => {
  const dispatch = useAppDispatch()
  const selectedArticles = useAppSelector(getNewsSelectedArticles)
  const isSelected = selectedArticles.includes(id)

  const onChange = (): void => {
    const selectedArticlesUpdate = isSelected
      ? selectedArticles.filter((articleId) => articleId !== id)
      : [...selectedArticles, id]
    dispatch(setNewsSelectedArticles(selectedArticlesUpdate))
  }
  return (
    <CheckboxWithGtm
      id="newsSelectArticleCheckbox"
      label=""
      value={id.toString()}
      checked={isSelected}
      onChange={onChange}
    />
  )
}
