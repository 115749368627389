import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumes = styled.div`
  background-color: ${JATOTheme.colors.white};
  margin: 0 -15px;

  .volumesContainer {
    height: calc(100vh - 160px);
    overflow-y: auto;
  }

  .volumesSidePanel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 30px;
  }
`
