import {
  ISpecsUserSettings,
  SpecsUserSettingsRequest,
} from 'models/Specifications/SpecsUserSettings'
import { UseMutationResult, useMutation } from 'react-query'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  setSpecsUserState,
} from 'redux/specifications/specificationsSlice'
import specificationsService from 'services/Specifications/SpecificationsService'

export const useUpdateUserSettings = (): UseMutationResult<
  ISpecsUserSettings,
  unknown,
  SpecsUserSettingsRequest,
  unknown
> => {
  const dispatch = useAppDispatch()
  const specsUserState = useAppSelector(getSpecsUserState)

  const mutation = useMutation({
    mutationFn: (userSettings: SpecsUserSettingsRequest) =>
      specificationsService.updateUserSettings(userSettings),
    onSuccess: (userSettingsUpdated: ISpecsUserSettings) => {
      const userStateUpdate = {
        ...specsUserState,
        settings: userSettingsUpdated,
      }

      dispatch(setSpecsUserState(userStateUpdate))
    },
  })

  return mutation
}
