import styled from 'styled-components'

export const StyledSpecificationsOptionBuild = styled.div`
  .requiresLevel {
    background-color: #e8f1ff;
    padding: 10px 10px;
    border: 1px solid #1677ff;
  }

  .excludesLevel {
    background-color: #ffe4c4;
    padding: 10px 10px;
    border: 1px solid #ffa43a;
  }

  .includesLevel {
    background-color: #ccedd5;
    padding: 10px 10px;
    border: 1px solid #12c944;
  }
`
