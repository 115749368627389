import React from 'react'
import { StyledPasswordValidationMessage } from './PasswordValidationMessage.styles'
import { JATOIcon, JATOText } from '@jato/ui-component-library'
import {
  ValidationResult,
  getColor,
  getMinLengthValidationResult,
  getTotalValidationResult,
  getValidationResult,
  hasLowercase,
  hasNumber,
  hasSpecialChars,
  hasUppercase,
} from 'helper/portalHelper'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

export interface IPasswordValidationMessageProps {
  password: string
}

export const PasswordValidationMessage: React.FC<
  IPasswordValidationMessageProps
> = ({ password }: IPasswordValidationMessageProps) => {
  const translations = useAppSelector(getTranslations)
  const renderValidationMessage = (
    validationResult: ValidationResult,
    message: string
  ): JSX.Element => (
    <span>
      {validationResult === ValidationResult.Neutral ? (
        <div className="neutralIcon" />
      ) : (
        <JATOIcon
          iconName={
            validationResult === ValidationResult.Success
              ? 'baseline_check'
              : 'baseline_error'
          }
          style={{
            height: '16px',
            color: getColor(validationResult),
            verticalAlign: 'middle',
          }}
        />
      )}
      <JATOText as="span" color={getColor(validationResult)} fontSize={12}>
        {message}
      </JATOText>
    </span>
  )
  return (
    <StyledPasswordValidationMessage>
      {renderValidationMessage(
        getMinLengthValidationResult(password),
        translations.JNT_Portal_CustReg_Password_MinLength_Message
      )}
      <JATOText
        as="span"
        color={getColor(getTotalValidationResult(password))}
        fontSize={14}
      >
        {translations.JNT_Portal_CustReg_Password_Criteria_Label}
      </JATOText>
      {renderValidationMessage(
        getValidationResult(password, hasUppercase),
        translations.JNT_Portal_CustReg_Password_UpperCase_Message
      )}
      {renderValidationMessage(
        getValidationResult(password, hasLowercase),
        translations.JNT_Portal_CustReg_Password_LowerCase_Message
      )}
      {renderValidationMessage(
        getValidationResult(password, hasNumber),
        translations.JNT_Portal_CustReg_Password_MinNumber_Message
      )}
      {renderValidationMessage(
        getValidationResult(password, hasSpecialChars),
        translations.JNT_Portal_CustReg_Password_SpecialChars_Message
      )}
    </StyledPasswordValidationMessage>
  )
}
