import { JATOIcon, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledSpecificationsFaq } from './SpecificationsFaq.styles'
export const SpecificationsFaq: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const WLTPFAQ1 = translations.JNT_WLTP_FAQ_1
  const WLTPFAQ2 = translations.JNT_WLTP_FAQ_2
  const WLTPFAQ3 = translations.JNT_WLTP_FAQ_3
  const WLTPFAQ4 = translations.JNT_WLTP_FAQ_4
  const WLTPFAQ5 = translations.JNT_WLTP_FAQ_5

  const WLTPFAQAnswer1 = translations.JNT_WLTP_FAQ_ANS_1
  const WLTPFAQAnswer2 = translations.JNT_WLTP_FAQ_ANS_2
  const WLTPFAQAnswer3 =
    `<ul><li>` +
    translations.JNT_WLTP_FAQ_ANS_3_1 +
    `</li><li>` +
    translations.JNT_WLTP_FAQ_ANS_3_2 +
    `</li><li>` +
    translations.JNT_WLTP_FAQ_ANS_3_3 +
    `</li><li>` +
    translations.JNT_WLTP_FAQ_ANS_3_4 +
    `</li><li>` +
    translations.JNT_WLTP_FAQ_ANS_3_5 +
    `</li></ul>`
  const WLTPFAQAnswer4 =
    `<p>` +
    translations.JNT_WLTP_FAQ_ANS_4_1 +
    `</P><P>` +
    translations.JNT_WLTP_FAQ_ANS_4_2 +
    `</P>`

  const WLTPFAQAnswer5_1 = translations.JNT_WLTP_FAQ_ANS_5_1
  const WLTPFAQAnswer5_2 = translations.JNT_WLTP_FAQ_ANS_5_2
  const WLTPFAQAnswer5_2_1 = translations.JNT_WLTP_FAQ_ANS_5_2_1
  const WLTPButtonText = translations.JNT_WLTP_BUTTON_TEXT

  const FAQAnswers5 =
    ` <ul><li>` +
    WLTPFAQAnswer5_1 +
    `</li><li>` +
    WLTPFAQAnswer5_2 +
    ` <button type="button" class="wltpButton">` +
    WLTPButtonText +
    `</button> ` +
    WLTPFAQAnswer5_2_1

  function scrollTop(ElementId: string): void {
    document.getElementById(ElementId)?.scrollIntoView()
  }
  return (
    <>
      <StyledSpecificationsFaq>
        <div
          className="modal-body"
          style={{ height: '400px', overflow: 'auto' }}
        >
          <hr className="margin0top margin5bottom" />

          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('10')}>
              {WLTPFAQ1}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('11')}>
              {WLTPFAQ2}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('12')}>
              {WLTPFAQ3}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('13')}>
              {WLTPFAQ4}
            </a>
          </div>
          <div>
            <JATOIcon
              iconName={'custom_help_outline'}
              className="icon-style"
              iconSize={JATOTheme.fontSizes[3]}
            />
            <a className="link-text" onClick={() => scrollTop('14')}>
              {WLTPFAQ5}
            </a>
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="10">
            <h4>
              <strong>
                <span className="custom-span">{WLTPFAQ1}</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WLTPFAQAnswer1 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="11">
            <h4>
              <strong>
                <span className="custom-span">{WLTPFAQ2}</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WLTPFAQAnswer2 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="12">
            <h4>
              <strong>
                <span className="custom-span">{WLTPFAQ3}</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WLTPFAQAnswer3 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="13">
            <h4>
              <strong>
                <span className="custom-span">{WLTPFAQ4}</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: WLTPFAQAnswer4 }} />
          </div>
          <hr className="margin0top margin5bottom" />
          <div id="14">
            <h4>
              <strong>
                <span className="custom-span">{WLTPFAQ5}</span>
              </strong>
            </h4>
            <div dangerouslySetInnerHTML={{ __html: FAQAnswers5 }} />
          </div>
          <hr className="margin0top margin5bottom" />
        </div>
      </StyledSpecificationsFaq>
    </>
  )
}
