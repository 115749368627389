import ShareIcon from 'assets/icons/icon_share.svg'
import UnshareIcon from 'assets/icons/icon_unshare.svg'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React from 'react'
import ImgButton from '../../../CommonButtons/ImgButton'

export interface INewsShareButtonProps {
  article: NewsArticle
  title: string
  onChange: () => void
}

export const NewsShareButton: React.FC<INewsShareButtonProps> = ({
  article,
  title,
  onChange,
}: INewsShareButtonProps) => (
  <ImgButton
    id="newsShareButton"
    src={article.isShared ? UnshareIcon : ShareIcon}
    title={title}
    onClick={onChange}
  />
)
