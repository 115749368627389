import { AxiosResponse } from 'axios'
import { IBasket } from 'models/Basket/Basket'
import { mpBaseApi } from 'services/api'

class BasketManagerService {
  public getBasketData(): Promise<AxiosResponse<IBasket[]>> {
    const basketData = mpBaseApi.get<number, AxiosResponse<IBasket[]>>(
      `/api/basket/getbaskets`
    )
    return basketData
  }

  public addBasketData(basket: IBasket): Promise<AxiosResponse<void>> {
    const addBasketResponse = mpBaseApi.post<IBasket, AxiosResponse<void>>(
      `/api/basket/add`,
      basket
    )
    return addBasketResponse
  }

  public updateBasketData(basket: IBasket): Promise<AxiosResponse<void>> {
    const updateBasketResponse = mpBaseApi.post<IBasket, AxiosResponse<void>>(
      `/api/basket/update`,
      basket
    )
    return updateBasketResponse
  }

  public removeBasketData(
    basketId?: string | undefined
  ): Promise<AxiosResponse<unknown>> {
    const removeBasketResponse = mpBaseApi.post(
      `/api/basket/remove/${basketId}`,
      {}
    )
    return removeBasketResponse
  }
}

const basketManagerService = new BasketManagerService()
export default basketManagerService
