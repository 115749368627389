import styled from 'styled-components'

export const StyledNewsSearch = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;

  button {
    border-radius: 6px;
  }
`
