import {
  JATOBreadcrumbs,
  JATOBreadcrumbsItem,
} from '@jato/ui-component-library'
import { NewsBreadcrumbsItem } from 'models/News/NewsBreadcrumbsItem'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { StyledBreadcrumbs } from './Breadcrumbs.style'

interface INewsBreadcrumbs {
  breadcrumbItemList: NewsBreadcrumbsItem[]
}

export const Breadcrumbs: React.FC<INewsBreadcrumbs> = ({
  breadcrumbItemList,
}) => (
  <StyledBreadcrumbs>
    <JATOBreadcrumbs>
      {breadcrumbItemList.map((breadcrumb: NewsBreadcrumbsItem, index) => (
        <JATOBreadcrumbsItem
          key={uuid()}
          className={'newsBreadcrumbsItem_' + index}
          iconName={breadcrumb.iconName}
          title={breadcrumb.title}
          url={breadcrumb.url}
        />
      ))}
    </JATOBreadcrumbs>
  </StyledBreadcrumbs>
)
