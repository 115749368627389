import styled from 'styled-components'
import FormScreenImage from './Images/jatoform.jpg'

export const StyledJATOFormScreen = styled.div`
  .splashScreen {
    background: no-repeat url(${FormScreenImage}) center center;
    min-height: 770px;
    background-size: cover;
  }
`
