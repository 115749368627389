import { JATOIcon } from '@jato/ui-component-library'
import { queryClient } from 'app'
import { TextNavItem } from 'components/CommonNavItems/TextNavItem/TextNavItem'
import Loader from 'components/Loader'
import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import { filterScenarios } from 'helper/searchQueryHelper'
import { ReportParameters } from 'models/Reports/ReportParameters'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { mpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import { selectedFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledMonthlyPaymentsScenerios } from './MonthlyPaymentsScenerios.styles'
import { MonthlyPaymentsSceneriosBody } from './MonthlyPaymentsSceneriosBody'
import { MonthlyPaymentsSceneriosHeader } from './MonthlyPaymentsSceneriosHeader'
import { MonthlyPaymentDataSourceInfo } from './MonthlyPaymentDataSourceInfo'

export const MonthlyPaymentsSceneriosContainer: React.FC = () => {
  const mpVehicleData: MpVehicle[] = useAppSelector(mpVehicles)
  const selectedFiltersData = useAppSelector(selectedFilters)
  const { sceneriosCount } = useParams<ReportParameters>()
  const history = useHistory()

  if (mpVehicleData.length === 0) {
    history.push(RoutePaths.MonthlyPayments)
  }

  const [mpReportMetaData, setMpReportMetaData] = useState<any>(
    queryClient.getQueryData(['mpReportMetaData'])
  )

  const goToMonthlyPaymentReport = (): void => {
    history.push(RoutePaths.MonthlyPaymentsReport)
  }
  useEffect(() => {
    const mpReportMetaData = queryClient.getQueryData(['mpReportMetaData'])
    setMpReportMetaData(mpReportMetaData)
  })

  const filteredScenariosVehicles = filterScenarios(
    mpVehicleData,
    selectedFiltersData
  )

  return mpVehicleData && mpReportMetaData ? (
    <StyledMonthlyPaymentsScenerios>
      <div>
        <NavigationContainer
          left={<TextNavItem text="Monthly Payments" />}
          right={
            <span
              id="monthlyPaymentsReportScenariosCollapseButton"
              style={{ cursor: 'pointer', paddingLeft: '10px' }}
              title="Collapse scenerios"
              onClick={() => goToMonthlyPaymentReport()}
            >
              <JATOIcon iconName="baseline_fullscreen_exit" iconSize={24} />
            </span>
          }
        />
        <div className="ej-mpreport-panel">
          <div
            style={{
              minWidth: `${parseInt(sceneriosCount) * 220 + 300}px`,
            }}
          >
            <MonthlyPaymentsSceneriosHeader
              vehicles={filteredScenariosVehicles}
            />
            <MonthlyPaymentsSceneriosBody
              vehicles={filteredScenariosVehicles}
              mpReportMetaData={mpReportMetaData}
            />
          </div>
        </div>
      </div>
    </StyledMonthlyPaymentsScenerios>
  ) : (
    <Loader />
  )
}
