import { LoaderModal } from 'components/Loader/LoaderModal'
import { useUpdateBookmarkStatus, useUpdateSharedStatus } from 'hooks/news'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import NewsBookmarkButton from '../NewsBookmarkButton'
import NewsSelectArticleCheckbox from '../NewsSelectArticleCheckbox'
import NewsShareButton from '../NewsShareButton'
import { StyledNewsArticleActions } from './NewsArticleActions.styles'

export interface INewsArticleActionsProps {
  article: NewsArticle
  allowSelection?: boolean
}

export const NewsArticleActions: React.FC<INewsArticleActionsProps> = ({
  article,
  allowSelection = false,
}) => {
  const translations = useAppSelector(getTranslations)

  const { mutate: updateSharedStatus, isLoading: isUpdatingShareStatus } =
    useUpdateSharedStatus()
  const { mutate: updateBookmarkStatus, isLoading: isUpdatingBookmarkStatus } =
    useUpdateBookmarkStatus()

  return (
    <StyledNewsArticleActions>
      {allowSelection && <NewsSelectArticleCheckbox id={article.id} />}
      <NewsShareButton
        article={article}
        title={
          article.isShared ? translations.JNT_Unshared : translations.JNT_share
        }
        onChange={() => updateSharedStatus(article)}
      />
      <NewsBookmarkButton
        article={article}
        title={
          article.isBookMarked
            ? translations.JNT_UnFavourite
            : translations.JNT_Favourite
        }
        onChange={() => updateBookmarkStatus(article)}
      />

      <LoaderModal isOpen={isUpdatingShareStatus || isUpdatingBookmarkStatus} />
    </StyledNewsArticleActions>
  )
}
