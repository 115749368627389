import { queryClient } from 'app'
import {
  BasketInfo,
  BasketInfoRequest,
} from 'models/Specifications/Basket/BasketInfo'
import { SaveBasketRequest } from 'models/Specifications/Basket/SaveBasketRequest'
import { SaveBasketResponse } from 'models/Specifications/Basket/SaveBasketResponse'
import { UpdateBasketRequest } from 'models/Specifications/Basket/UpdateBasketRequest'

import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query'
import { useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  getSpecsVehicles,
} from 'redux/specifications/specificationsSlice'
import specificationsService from 'services/Specifications/SpecificationsService'

const BasketDataQueryKey = 'specsBasketData'

export const useGetBaskets = (): UseQueryResult<BasketInfo[]> =>
  useQuery<BasketInfo[]>(BasketDataQueryKey, () =>
    specificationsService.getBaskets()
  )

export const useSaveBasket = (): UseMutationResult<
  SaveBasketResponse,
  unknown,
  SaveBasketRequest
> => {
  const userData = useAppSelector(getSpecsUserState)
  const specsVehicles = useAppSelector(getSpecsVehicles)

  const dbName = userData?.settings?.lastSelectedMarket ?? ''

  return useMutation({
    mutationFn: (request: SaveBasketRequest) => {
      const basket: BasketInfoRequest = {
        basketName: request.name,
        benchmarkVehicleId: specsVehicles.benchmarkVehicleId,
        id: request.id ?? '',
        isCompareEquip: false,
        isRemoveCompareEquip: false,
        isShared: false,
        markets: [
          {
            databaseName: dbName,
            vehicles: specsVehicles.selectedVehicles.map((v) => ({
              vehicleId: v.vehicleId,
              vehicleUid: v.vehicleUid,
              makeGlobal: v.makeGlobal,
              modelGlobal: v.modelGlobal,
              derivativeGlobal: v.derivativeGlobal,
            })),
          },
        ],
        masterCompareVehicleId: '',
        detailedActionOptionBuildInfoList:
          request.detailedActionOptionBuildInfoList,
      }
      return specificationsService.saveBasket(basket)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(BasketDataQueryKey)
    },
  })
}

export const useRemoveBasket = (): UseMutationResult<
  number,
  unknown,
  BasketInfoRequest,
  unknown
> =>
  useMutation({
    mutationFn: (basket: BasketInfoRequest) =>
      specificationsService.removeBasket(basket),
    onSuccess: () => queryClient.invalidateQueries(BasketDataQueryKey),
  })

export const useUpdateBasket = (): UseMutationResult<
  SaveBasketResponse,
  unknown,
  UpdateBasketRequest,
  unknown
> =>
  useMutation({
    mutationFn: (updateBasketRequest: UpdateBasketRequest) =>
      specificationsService.updateBasket(updateBasketRequest),
    onSuccess: () => {
      queryClient.invalidateQueries(BasketDataQueryKey)
    },
  })
