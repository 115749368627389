import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesVehiclesVolumesType = styled.div`
  background-color: ${JATOTheme.colors.white};
  margin: 0 -15px;

  .volumesContainer {
    height: calc(100vh - 190px);
    overflow-y: auto;
    padding: 30px;
    padding-bottom: 0;
  }

  .jato-basic-card {
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.24),
      0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  button {
    font-weight: 500;
  }

  .selectionRadioGroup {
    background-color: ${JATOTheme.colors.midOffGrey};
    padding: 8px 24px 0;
    margin: 0 -24px;
  }

  .datasetsContainer {
    height: calc(100vh - 372px);
    overflow: auto;
    flex-wrap: nowrap;
    margin: 0 -24px -24px;
    padding: 12px 24px;
  }

  .jato-search-input-label {
    margin-bottom: 16px;
  }
`
