import { Loader } from 'components/Loader'
import { showErrorToast } from 'helper/toastHelper'
import {
  VolumesDataPointGroup,
  getActiveStep,
  getPathForStep,
  getUpdatedPeriodPoints,
} from 'helper/volumesHelper'
import { useLoadQueryById } from 'hooks/volumes'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  VolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'

export const VolumesQueryLoad: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const { queryId: queryIdParam } = useParams<{ queryId: string }>()
  const queryId = Number(queryIdParam)

  if (!queryId) {
    showErrorToast(
      translations.JNT_Error,
      translations.JNT_Volumes_Invalid_query_id
    )
    return <Redirect to={RoutePaths.Volumes} />
  }

  const { data: query, isFetching } = useLoadQueryById(queryId)

  useEffect(() => {
    if (isFetching) {
      return
    }

    if (query) {
      const queryState: VolumesQueryState = {
        databaseType: query.productGroup as VolumesDatabaseType,
        countries: query.countryList,
        dataSetIds: query.dataSetIds.map((id) => Number(id)),
        vehicleAttributes: [
          ...query.dataPoints,
          ...query.pricepointlist.map((dp) => ({
            ...dp,
            optype: VolumesDataPointGroup.Pricing,
          })),
        ],
        periods: getUpdatedPeriodPoints(query.periodPoints), // need to update periods sequence to sort out ordering issue for old queries
        segment_SegmentList: query.segment_SegmentList,
        segment_GroupID: query.segment_GroupID.toString(),
        bandingCurrency: query.bandingCurrency,
        vehicleTypeList: query.vehicleTypeList,
        outPutLangaugeId: query.outPutLangaugeId,
        dataSetPersist: query.dataSetPersist,
        outputPoints: query.outputPoints,
        regionPoints: query.regionPoints,
        analysisOptions: query.analysisOptions,
        outputOptions: query.outputOptions,
      }

      dispatch(setVolumesQueryState(queryState))

      const step = getActiveStep(queryState)
      history.push(getPathForStep(step))
    } else {
      showErrorToast(
        translations.JNT_Error,
        translations.JNT_Volumes_Outdated_Query_Error
      )
      history.push(RoutePaths.Volumes)
    }
  }, [query])

  return <Loader />
}
