import { JATOIcon } from '@jato/ui-component-library'
import { RelatedArticle } from 'models/News/Article/NewsArticleResponse'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledNewsRelatedArticles } from './NewsRelatedArticles.styles'

interface INewsRelatedArticlesProps {
  relatedArticleResponse: RelatedArticle[]
}

export const NewsRelatedArticles: React.FC<INewsRelatedArticlesProps> = ({
  relatedArticleResponse,
}: INewsRelatedArticlesProps) => {
  const history = useHistory()
  const translations = useAppSelector(getTranslations)
  return (
    <StyledNewsRelatedArticles>
      <div className="relatedSectionHeader">{translations.JNT_ART_Related}</div>
      <Container fluid>
        <Row>
          {relatedArticleResponse.map((relatedArticleObj, index) => (
            <Col md={3} key={index}>
              <div key={index} className="relatedYearDiv">
                <JATOIcon iconName="custom_calendar" iconSize={24} />
                <span className="relatedYearSpan">
                  {relatedArticleObj.year}
                </span>
              </div>

              {relatedArticleObj.relatedArticleList.map((article, index) => (
                <div key={index} className="relatedArticleListBox">
                  <div
                    className="relatedTableCell"
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <a
                      id="newsRelatedArticleLink"
                      onClick={() =>
                        history.push(`${RoutePaths.NewsArticles}/${article.id}`)
                      }
                    >
                      {article.headline}
                    </a>
                  </div>
                  <div className="relatedTableCell">
                    <span key={index}>{article.releaseDate}</span>
                  </div>
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </Container>
    </StyledNewsRelatedArticles>
  )
}
