import {
  ClipPath,
  Defs,
  Document,
  Font,
  G,
  Image,
  Page,
  Path,
  Polygon,
  Rect,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer'

import Html from 'react-pdf-html'

import { getCountryByCode } from 'helper/newsHelper'
import {
  fetchAllUpdatedContentPart,
  loadFont,
  pdfSvgs,
  styles,
} from 'helper/pdfExportHelper'
import 'jimp'
import { ContentParts, NewsArticle } from 'models/News/Article/NewsArticle'
import {
  getCategoriesBySubjects,
  getNewsCategoryTranslations,
} from 'models/News/NewsCategoryType'

import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { NewsCountry } from 'models/News/Homepage/NewsArticleRequest'

interface INewsArticlePdfDocumentProps {
  article: NewsArticle
  countries: NewsCountry[]
  translations: any
  languageId: number
}

export const NewsArticlePdfDocument: React.FC<INewsArticlePdfDocumentProps> = ({
  article,
  countries,
  translations,
  languageId,
}: INewsArticlePdfDocumentProps) => {
  Font.register({
    family: 'Roboto',
    fonts: [loadFont(languageId)],
  })
  const [articleContentParts, setContentParts] = useState<ContentParts[]>([])
  const categoryTranslations = getNewsCategoryTranslations(translations)

  useEffect(() => {
    const loadUpdatedContentParts = async (): Promise<void> => {
      const allContentParts = article.contentParts.map(
        (contentParts: ContentParts) => ({
          ...contentParts,
          articleId: article.id,
        })
      )

      const contentParts = await fetchAllUpdatedContentPart(allContentParts)
      setContentParts(contentParts)
    }
    loadUpdatedContentParts()
  }, [article])

  return (
    <>
      <Document pageMode="fullScreen">
        <Page key={uuid()} size="A4" style={styles.page}>
          <View style={styles.categorySection}>
            <Svg
              width={24}
              height={24}
              viewBox={
                pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                  .viewBox
              }
            >
              {pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                .paths &&
                pdfSvgs[
                  getCategoriesBySubjects([article.subjectCode ?? ''])[0]
                ].paths.map((path: any, index: number) => (
                  <Path
                    key={index}
                    d={path.d}
                    fill={path.fill}
                    fillRule={path.fillRule}
                  />
                ))}

              {pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                .g &&
                pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                  .g.path && (
                  <G>
                    <Path
                      d={
                        pdfSvgs[
                          getCategoriesBySubjects([
                            article.subjectCode ?? '',
                          ])[0]
                        ].g.path.d
                      }
                      fill={
                        pdfSvgs[
                          getCategoriesBySubjects([
                            article.subjectCode ?? '',
                          ])[0]
                        ].g.path.fill
                      }
                    />
                  </G>
                )}
              {pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                .defs &&
                pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                  .defs.clipPath &&
                pdfSvgs[getCategoriesBySubjects([article.subjectCode ?? ''])[0]]
                  .defs.clipPath.rect && (
                  <Defs>
                    <ClipPath>
                      <Rect
                        width={
                          pdfSvgs[
                            getCategoriesBySubjects([
                              article.subjectCode ?? '',
                            ])[0]
                          ].defs.clipPath.rect.width
                        }
                        height={
                          pdfSvgs[
                            getCategoriesBySubjects([
                              article.subjectCode ?? '',
                            ])[0]
                          ].defs.clipPath.rect.height
                        }
                        fill={
                          pdfSvgs[
                            getCategoriesBySubjects([
                              article.subjectCode ?? '',
                            ])[0]
                          ].defs.clipPath.rect.fill
                        }
                      />
                    </ClipPath>
                  </Defs>
                )}
            </Svg>
            <Text
              style={{
                color: '#0a3673',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              {categoryTranslations[
                getCategoriesBySubjects([article.subjectCode ?? ''])[0]
              ] ?? getCategoriesBySubjects([article.subjectCode ?? ''])[0]}
            </Text>
            <Svg
              width={110}
              height={25}
              viewBox="0 0 133.52 30.46"
              preserveAspectRatio="xMidYMid meet"
              style={{ marginLeft: '300px', zIndex: 997 }}
            >
              <G>
                <Path
                  fill="#191919"
                  d="M72.47,24.23H60.89l-2,6H51.3L62.18.23H71l11.07,30H74.43Zm-2-5.83L66.59,7.56,62.84,18.4ZM102.91,6.49H94.32V30.23H87.14V6.49H78.6V.23h24.31ZM105,15.23C105,6.56,110.53,0,119.19,0s14.33,6.56,14.33, 15.23-5.8,15.23-14.33,15.23C110.49,30.46,105,23.9,105,15.23Zm21.63,0c0-5-2.93-8.76-7.42-8.76-4.66,0-7.3,3.73-7.3,8.76s2.64,8.76,7.3,8.76S126.61,20.26,126.61,15.23Zm-91,14.31a11.83,11.83,0,0,0,4.76.92c5.86,0,9.23-3.6,9.23-9.91V.23H42.41V19.81c0,2.61-1,3.9-3.51,3.9A6.2,6.2,0,0,1,35.56,23Z"
                />
                <G>
                  <Rect
                    fill="#C40005"
                    y={0.23}
                    width={30}
                    height={30}
                    rx={1.65}
                  />
                  <Polygon
                    fill="#ffffff"
                    points="16.78 21 10.88 21 15.14 16.41 1.58 8.68 28.57 14.71 16.78 21"
                  />
                </G>
              </G>
            </Svg>
          </View>
          <View style={styles.headerSection}>
            <Text
              style={{
                color: '#0a3673',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontWeight: 500,
              }}
            >
              {article.headline}
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#0a3673',
                fontSize: '18px',
                fontWeight: 500,
                paddingTop: '10px',
              }}
            >
              {article.synopsis}
            </Text>
          </View>
          <View style={styles.metaDataSection}>
            <Text style={styles.newsMetadataBlock}>
              {translations.JNT_ART_Country}{' '}
              {
                getCountryByCode(article.countryCode, countries, translations)
                  .name
              }
            </Text>
            <Text style={styles.newsMetadataBlock}>
              {translations.JNT_ART_Author} {article.author}
            </Text>
            <Text style={styles.newsMetadataBlock}>
              {translations.JNT_NWS_Date}:{' '}
              {article.releaseDate.substring(0, 10)}
            </Text>
          </View>
          <View style={styles.contentSection}>
            {articleContentParts
              .filter(
                (contentParts: ContentParts) =>
                  contentParts.articleId === article.id
              )
              .map((content: ContentParts, index: number) =>
                content.type === 5 ? (
                  <Image
                    key={index}
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                    src={content.image}
                  />
                ) : (
                  <Html
                    key={index}
                    stylesheet={styles}
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {content.copy
                      .replaceAll('&#163;', '£')
                      .replaceAll('&#8364;', '€')}
                  </Html>
                )
              )}
          </View>
        </Page>
      </Document>
    </>
  )
}
