import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledINewsSavedSearchAndAlertGrid = styled.div`
  .searchLink {
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    color: ${JATOTheme.colors.blue};
  }
  .e-grid .e-pager {
    display: none;
`
