import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesPeriodSelectionsStep = styled.div`
  padding: 0 60px;

  .periodStepHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -24px;
    margin-left: -10px;
  }
  .periodStepContainer {
    height: calc(100vh - 406px);
    overflow: auto;
    margin: 0 -24px -24px;
    gap: 20px;
  }
  .periodStepContainer > div {
    width: calc(50% - 10px);
    margin: 0;
    font-size: 14px;
  }

  .periodSelectionText {
    font-weight: 500;
  }

  .periodsLoader {
    min-height: calc(100vh - 300px);
  }

  .periodsListItem {
    margin-bottom: -5px;
  }

  .dataPointListItem {
    margin-bottom: 8px;
  }

  .periodSelectionHeader {
    background-color: ${JATOTheme.colors.midOffGrey};
    padding: 8px 14px 0;
    font-weight: 500;
    min-height: 24px;
  }

  .dataPointSelectionContainer {
    height: calc(100vh - 464px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 22px;
  }

  .periodSelectionContainer {
    width: 100%;
    overflow: auto;
  }
`
