import { JATOLink } from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { getVehicleName } from 'helper/specificationsBuildRequestHelper'
import { useAddRecipeAndUpdateBrochure } from 'hooks/carSpecs'
import { VehicleHeaderInfo } from 'models/Specifications/BrochureResponse'
import { InstructionType } from 'models/Specifications/Options/DetailedAction'
import React, { useState } from 'react'
import { SpecificationsOptionsBuildModal } from './SpecificationsOptionsBuildModal'

export interface ISpecificationsOptionBuildButtonProps {
  iconSrc: string
  addedAsOption: boolean
  categoryName: string
  optionId: number
  vehicleHeader?: VehicleHeaderInfo
  optionName?: string
}

export const SpecificationsOptionBuildButton: React.FC<
  ISpecificationsOptionBuildButtonProps
> = ({
  iconSrc,
  addedAsOption,
  categoryName,
  optionId,
  vehicleHeader,
  optionName,
}: ISpecificationsOptionBuildButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    mutateAsync: addRecipeAndUpdateBrochure,
    isLoading: isLoadingBuildOptions,
  } = useAddRecipeAndUpdateBrochure()

  const unbuildOption = (): void => {
    addRecipeAndUpdateBrochure({
      userInstruction: InstructionType.UnbuildOption,
      optionId: optionId,
      vehicleId: vehicleHeader?.vehicleId ?? 0,
    })
  }

  return (
    <>
      <JATOLink
        id="specsReportOptionBuildButton"
        onClick={() => (addedAsOption ? unbuildOption() : setIsModalOpen(true))}
        style={{
          color: '#1677ff',
          fontSize: '14px',
          whiteSpace: 'normal',
          overflowWrap: 'break-word',
        }}
      >
        <img src={iconSrc} />
        {optionName && <span style={{ marginLeft: '5px' }}>{optionName}</span>}
      </JATOLink>
      {isModalOpen && (
        <SpecificationsOptionsBuildModal
          optionCategoryName={categoryName}
          optionId={optionId}
          vehicleId={vehicleHeader?.vehicleId ?? 0}
          title={getVehicleName(vehicleHeader?.vehiclePhotoPath)}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <LoaderModal isOpen={isLoadingBuildOptions} />
    </>
  )
}
