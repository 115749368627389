import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsArticleMenuItemButton = styled.div`
  button[disabled] {
    background-color: ${JATOTheme.colors.midDarkGrey};
    color: #666666;
    cursor: not-allowed;
  }
  .menuHeaderItem {
    color: #000000;
    font-size: ${JATOTheme.fontSizes[1]}px;
    margin-bottom: 0 !important;
    font-weight: 500;
    cursor: default;
    background-color: ${JATOTheme.colors.white};
    cursor: pointer;
    text-wrap: wrap;
  }

  .menuHeaderIcon {
    margin-right: ${JATOTheme.space[3]}px;
  }
`
