import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { L10n } from '@syncfusion/ej2-base'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import {
  defaultToDate,
  getDateRangeFilterValue,
  sixMonthFromDateOption,
  threeMonthFromDateOption,
  twelveMonthFromDateOption,
} from 'helper/newsHelper'
import moment from 'moment'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getNewsFilters, setNewsSelectedFilter } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsDateRangeFilter } from './NewsDateRangeFilter.styles'

interface INewsDateRangeFilterProps {
  filterName: string
  label: string
}

export const NewsDateRangeFilter: React.FC<INewsDateRangeFilterProps> = ({
  filterName,
  label,
}: INewsDateRangeFilterProps) => {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const newsFilters = useAppSelector(getNewsFilters)

  const getValue = (): Date[] =>
    getDateRangeFilterValue(newsFilters.selectedFilters[filterName])

  const onChange = (value: Date[]): void => {
    if (!value || value.length === 0) {
      // Handle the case when no dates are selected
      return
    }
    const fromDate = moment(value[0]).startOf('day').toDate()
    const toDate = moment(value[1]).endOf('day').toDate()
    const isDefault =
      moment(fromDate).isSame(threeMonthFromDateOption()) &&
      moment(toDate).isSame(defaultToDate())
    dispatch(
      setNewsSelectedFilter({
        [filterName]: isDefault ? [] : [JSON.stringify([fromDate, toDate])],
      })
    )
  }

  const presets = [
    {
      label: `${translations.JNT_Last_3_Months}`,
      start: threeMonthFromDateOption(),
      end: defaultToDate(),
    },
    {
      label: `${translations.JNT_Last_6_Months}`,
      start: sixMonthFromDateOption(),
      end: defaultToDate(),
    },
    {
      label: `${translations.JNT_Last_12_Months}`,
      start: twelveMonthFromDateOption(),
      end: defaultToDate(),
    },
  ]

  L10n.load({
    translatedTags: {
      daterangepicker: {
        customRange: translations.JNT_CustomRange,
        placeholder: translations.JNT_Select_Range,
      },
    },
  })

  return (
    <StyledNewsDateRangeFilter>
      <div className="dateRangeFilter">
        <JATOText as="div" fontSize={JATOTheme.fontSizes[1]}>
          {label}
        </JATOText>
        <DateRangePickerComponent
          locale="translatedTags"
          id="newsDateRangeFilter"
          format="dd/MM/yyyy"
          openOnFocus={true}
          showClearButton={false}
          presets={presets}
          value={getValue()}
          max={defaultToDate()}
          change={(e) => onChange(e.value)}
        />
      </div>
    </StyledNewsDateRangeFilter>
  )
}
