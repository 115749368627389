import { JATOButton, JATOIcon } from '@jato/ui-component-library'
import { GridComponent } from '@syncfusion/ej2-react-grids'
import { NewsPage } from 'models/News/NewsPageType'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getNewsSelectedArticles,
  setNewsSelectedArticles,
} from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledNewsArticleSelection } from './NewsArticleSelection.styles'

export const NewsArticleSelection: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const selectedArticles = useAppSelector(getNewsSelectedArticles)
  const translations = useAppSelector(getTranslations)

  const clearArticlesSelection = (): void => {
    dispatch(setNewsSelectedArticles([]))
    clearSelectionInGrid()
  }

  const clearSelectionInGrid = (): void => {
    const gridInstance: GridComponent = (document.getElementById('grid') as any)
      ?.ej2_instances[0]

    gridInstance && gridInstance.clearRowSelection()
  }

  useEffect(() => {
    clearArticlesSelection()
  }, [])

  const viewSelection = (): void => {
    const articleId = selectedArticles[0]
    history.push(
      `${RoutePaths.NewsArticles}/${NewsPage.ViewSelection}/${articleId}`
    )
  }

  return selectedArticles.length > 0 ? (
    <StyledNewsArticleSelection>
      <JATOButton
        id="newsViewSelectionButton"
        className="searchResultsButton"
        variant="ghost"
        onClick={viewSelection}
      >
        {translations.JNT_View_selection}
      </JATOButton>
      <JATOButton
        id="newsClearSelectionButton"
        className="searchResultsButton"
        variant="ghost"
        onClick={clearArticlesSelection}
      >
        {`${selectedArticles.length} ${translations.JNT_selected}`}
        <JATOIcon iconName="baseline_cancel" />
      </JATOButton>
    </StyledNewsArticleSelection>
  ) : (
    <></>
  )
}
