import { JATOVerticalSpacer } from '@jato/ui-component-library'
import { PhotoGallery } from 'components/PhotoGallery/PhotoGallery'
import { BrochureReturnObject } from 'models/Specifications/BrochureResponse'
import React from 'react'

interface IVehiclePhotoGallery {
  builtVehicle: BrochureReturnObject
}

export const SpecsPhotoGallery: React.FC<IVehiclePhotoGallery> = ({
  builtVehicle,
}: IVehiclePhotoGallery) => (
  <>
    <PhotoGallery
      id="specsReportPhotoGallery"
      imageUrlList={builtVehicle.vehiclePhotos}
    />
    <JATOVerticalSpacer />
  </>
)
