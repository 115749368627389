import styled from 'styled-components'

export const StyledMonthlyPaymentsSceneriosHeader = styled.div`
  border-bottom: 1px solid rgb(198, 198, 198);

  .tableStyle {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    table-layout: fixed;
    width: 100%;
    background-color: #ffffff;
    border-spacing: unset;
  }
  .tableRow {
    display: table-row;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  .tableCell {
    display: table-cell;
    box-sizing: border-box;
    padding: 0;
    background-color: #ffffff;
    vertical-align: middle;
    text-align: left;
    width: 220px;
  }

  .tableValue {
    padding: 8px 16px;
  }

  .tableCell:first-child {
    width: 300px;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: red;
  }
`
