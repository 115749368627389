export interface ExcelExportResult {
  success: boolean
  url?: string
  error?: string
}

export const getSuccessResult = (url: string): ExcelExportResult => ({
  success: true,
  url: url,
})

export const getErrorResult = (error: string): ExcelExportResult => ({
  success: false,
  error: error,
})
