import {
  createToast,
  JATOButton,
  JATOGroup,
  JATOTextInput,
} from '@jato/ui-component-library'
import { queryClient } from 'app'
import { IBasket } from 'models/Basket/Basket'
import { FilterNameType } from 'models/Filters/FilterNameType'
import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import { LoginResponse } from 'models/Login/LoginResponse'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { mpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import { selectedFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import basketManagerService from 'services/BasketManager/BasketManagerService'

interface ISaveBasketProps {
  baskets: IBasket[] | undefined
  onCloseEvent: () => void
}

export const SaveBasket: React.FC<ISaveBasketProps> = (
  props: ISaveBasketProps
) => {
  const [basketName, setBasketName] = useState('')
  const mpVehicleData: MpVehicle[] = useAppSelector(mpVehicles)
  const userData: LoginResponse = useAppSelector(selectUserData)
  const selectedFiltersData: ISelectedFilterOption[] =
    useAppSelector(selectedFilters)

  const isCountryExists = (
    selectedFiltersData: ISelectedFilterOption
  ): boolean => selectedFiltersData.filterName === FilterNameType.Country

  function addBasketData(newBasket: IBasket): Promise<number> {
    const doesBasketNameExist = (basket: IBasket): boolean =>
      basket.basketName.trim()?.toLowerCase() ===
      newBasket.basketName.trim()?.toLowerCase() // Trims start and end empty spaces to avoid duplicate basket names
    const basketIndex = props.baskets?.findIndex(doesBasketNameExist)
    if (basketIndex === -1) {
      return basketManagerService
        .addBasketData(newBasket)
        .then(() => {
          createToast({
            children: <div>Basket Added</div>,
          })
          return 1
        })
        .catch(() => {
          createToast({
            children: <div>Problem while adding</div>,
          })
          return 0
        })
    } else {
      createToast({
        children: <div>Basket Name Already Exists</div>,
      })
      const myPromise = new Promise<number>(function (resolve) {
        resolve(0)
      })

      return myPromise
    }
  }

  const { mutate: addbasketMutate } = useMutation({
    mutationFn: (newbasket: IBasket) => addBasketData(newbasket),
    onSuccess: (status: number) => {
      console.log(status)
      props.onCloseEvent()
      if (status === 1) {
        queryClient.invalidateQueries('mpBasketData')
      }
    },
  })

  const callAddBasketData = (): any => {
    const newbasket: IBasket = {
      basketName: basketName,
      displayMarketValue:
        selectedFiltersData.filter(isCountryExists)[0].displayValue,
      isShared: false,
      userClicks: 0,
      market: selectedFiltersData.filter(isCountryExists)[0].value,
      userId: userData.user.userId,
      userGroupId: userData.user.usrgrpid,
      updateDate: new Date(),
      lastAccessDate: new Date(),
      mpVehicleList: [...mpVehicleData],
    }

    return addbasketMutate(newbasket)
  }
  const handleSaveBasket = (): void => {
    callAddBasketData()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setBasketName(event.currentTarget.value)
  }

  return (
    <>
      <JATOGroup flexDirection="row">
        <JATOTextInput
          width={430}
          placeholder="Enter Basket Name"
          onChange={onChange}
          id={''}
        />
        <JATOButton
          id="monthlyPaymentsSaveBasketSaveButton"
          color="green"
          display="inline-flex"
          justifyContent="flex-start"
          textTransform="none"
          onClick={() => handleSaveBasket()}
          disabled={mpVehicleData.length <= 0 || basketName === ''}
        >
          Save Basket
        </JATOButton>
      </JATOGroup>
    </>
  )
}
