import styled from 'styled-components'
import Theme from 'theme'

export const StyledHelpMenu = styled.span`
  height: 24px;

  .dropdown {
    -webkit-animation: EbufM 0.15s ease-out;
    -webkit-animation: EbufM 0.15s ease-out;
    animation: EbufM 0.15s ease-out;
    display: none;
    outline: none;
    position: absolute;
    top: calc(100% + 4px);
    left: -202px;
    right: -27px;
    display: block;
    font-size: 12px;
    padding: 0;
  }

  .menuHeaderItem {
    color: #000000;
    font-size: ${Theme.fontSizes[1]}px;
    margin-bottom: 0 !important;
    font-weight: 500;
    cursor: default;
  }

  .menuHeaderIcon {
    margin-right: ${Theme.space[2]}px;
  }

  .menuItem {
    color: #000000;
    font-size: ${Theme.fontSizes[1]}px;
    margin-bottom: 0 !important;
    padding: 8px 20px;
    text-transform: none;
  }

  .menuLinkItem {
    margin-bottom: 0 !important;
  }
`
