import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { ExcelPageDataRequest } from 'models/excelExport/ExcelPageDataRequest'
import {
  SpecsExcelStyleType,
  getExcelVehicleName,
  getItemRow,
  getMaximumRowsLengthForOptionsExcel,
  getSpecsHeader,
} from '../excelExportHelper'
import {
  BrochureReturnObject,
  BuiltOption,
} from 'models/Specifications/BrochureResponse'

class ComparisonExcelTemplate {
  formateVehicleData: any[]
  nonBenchmarkVehicles: any[]
  benchmarkVehicle = {}
  constructor() {
    this.formateVehicleData = []
    this.nonBenchmarkVehicles = []
  }

  private getOptionRows = (
    maximumOptionRows: number,
    vehicleId: number,
    buildOptionList: BuiltOption[],
    translations: AppTextTranslations
  ): any => {
    let optionRows: any[] = []
    if (maximumOptionRows > 0) {
      optionRows.push(
        getItemRow(translations.JNT_added, SpecsExcelStyleType.CategoryHeader)
      )
      optionRows = optionRows.concat(
        this.getOptionsBenchmark(maximumOptionRows, vehicleId, buildOptionList)
      )
    }
    return optionRows
  }
  private getTitles = (translations: AppTextTranslations): any => {
    const titleRows = []
    titleRows.push(
      getItemRow(
        translations.JNT_Comparison,
        SpecsExcelStyleType.ItemComparisonTitle
      )
    )
    return titleRows
  }
  private getColumnTitles = (
    maximumOptionRows: number,
    excelPageDataRequest: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    const vehicle =
      excelPageDataRequest.brochureResponse?.brochureVehicles.find(
        (brochureReturnObject: BrochureReturnObject) =>
          brochureReturnObject.vehicleHeaderInfo.vehicleId ===
          excelPageDataRequest.benchmarkVehicleId
      )

    if (vehicle) {
      const column = {
        column: 1,
        rows: getSpecsHeader(
          getExcelVehicleName(
            vehicle.vehicleHeaderInfo,
            excelPageDataRequest.isLocal
          ),
          vehicle.vehicleHeaderInfo.displayPrice
        ),
      }

      Array.prototype.push.apply(
        column.rows,
        this.getOptionRows(
          maximumOptionRows,
          vehicle.vehicleHeaderInfo.vehicleId,
          vehicle.builtOptions,
          translations
        )
      )
      Array.prototype.push.apply(column.rows, this.getTitles(translations))
      return column
    }
  }

  private getOptions = (
    maximumOptionRows: number,
    vehicleId: number,
    buildOptionList: BuiltOption[]
  ): any => {
    const optionRows = []
    let primaryOptionInfos
    let secondaryOptionInfos
    let numberOfOptionPerVehicle = 0
    let difference

    if (maximumOptionRows > 0) {
      optionRows.push(getItemRow('', ''))

      primaryOptionInfos = buildOptionList.filter(
        (vehicle: BuiltOption) => vehicle.vehicleId === vehicleId
      )
      for (let i = 0; i < primaryOptionInfos.length; i++) {
        numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
        optionRows.push(
          getItemRow(
            `[${primaryOptionInfos[i].optionCode}] ${primaryOptionInfos[i].optionName} ${primaryOptionInfos[i].displayPrice}`
              .replace('\r', '')
              .replace('\n', ''),
            SpecsExcelStyleType.PrimaryOption
          )
        )
        secondaryOptionInfos = primaryOptionInfos[i].secondaryOptions
        for (let j = 0; j < secondaryOptionInfos.length; j++) {
          numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
          optionRows.push(
            getItemRow(
              `[${secondaryOptionInfos[j].optionCode}] ${secondaryOptionInfos[j].optionName} ${secondaryOptionInfos[j].displayPrice}`
                .replace('\r', '')
                .replace('\n', ''),
              SpecsExcelStyleType.SecondaryOption
            )
          )
        }
      }
      if (numberOfOptionPerVehicle < maximumOptionRows) {
        difference = maximumOptionRows - numberOfOptionPerVehicle
        for (let i = 0; i < difference; i++) {
          optionRows.push(getItemRow('', ''))
        }
      }
    }
    return optionRows
  }

  private getOptionsBenchmark = (
    maximumOptionRows: number,
    vehicleId: number,
    buildOptionList: BuiltOption[]
  ): any => {
    const optionRows = []
    let primaryOptionInfos
    let secondaryOptionInfos
    let numberOfOptionPerVehicle = 0
    let difference

    if (maximumOptionRows > 0) {
      primaryOptionInfos = buildOptionList.filter(
        (vehicle: BuiltOption) => vehicle.vehicleId === vehicleId
      )
      for (let i = 0; i < primaryOptionInfos.length; i++) {
        numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
        optionRows.push(
          getItemRow(
            `[${primaryOptionInfos[i].optionCode}] ${primaryOptionInfos[i].optionName} ${primaryOptionInfos[i].displayPrice}`
              .replace('\r', '')
              .replace('\n', ''),
            SpecsExcelStyleType.PrimaryOption
          )
        )
        secondaryOptionInfos = primaryOptionInfos[i].secondaryOptions
        for (let j = 0; j < secondaryOptionInfos.length; j++) {
          numberOfOptionPerVehicle = numberOfOptionPerVehicle + 1
          optionRows.push(
            getItemRow(
              `[${secondaryOptionInfos[j].optionCode}] ${secondaryOptionInfos[j].optionName} ${secondaryOptionInfos[j].displayPrice}`
                .replace('\r', '')
                .replace('\n', ''),
              SpecsExcelStyleType.SecondaryOption
            )
          )
        }
      }
      if (numberOfOptionPerVehicle < maximumOptionRows) {
        difference = maximumOptionRows - numberOfOptionPerVehicle
        for (let i = 0; i < difference; i++) {
          optionRows.push(getItemRow('', ''))
        }
      }
    }
    return optionRows
  }
  private getAdvantagePerColumn = (
    formattedVehicleData: any,
    vehicleId: number,
    translations: AppTextTranslations
  ): any => {
    const itemRows = []
    if (formattedVehicleData.length > 0) {
      itemRows.push(getItemRow('', SpecsExcelStyleType.ItemComparisonTitle))
      for (let i = 0; i < formattedVehicleData.length; i++) {
        const currentVehicle = formattedVehicleData[i]
        if (currentVehicle.vehicleHeaderInfo.vehicleId === vehicleId) {
          const catObjectKeys = Object.keys(formattedVehicleData[i].keyAdvData)
          for (let v = 0; v < catObjectKeys.length; v++) {
            const catIndex = catObjectKeys[v]
            itemRows.push(
              getItemRow(
                formattedVehicleData[i].keyAdvData[catIndex][0].categoryName,
                SpecsExcelStyleType.ItemTitle
              )
            )
            for (
              let w = 0;
              w < currentVehicle.keyAdvData[catIndex].length;
              w++
            ) {
              const currentRow = currentVehicle.keyAdvData[catIndex][w]
              if (
                currentRow.optionId !== 0 &&
                currentRow.isBuildableOption === true
              ) {
                itemRows.push(
                  getItemRow(
                    currentRow.keyAdvantageText
                      .replace('<sub>', '')
                      .replace('</sub>', '')
                      .replace('<sup>', '')
                      .replace('</sup>', '') +
                      ' - ' +
                      translations.JNT_option,
                    SpecsExcelStyleType.OptionsRowItem
                  )
                )
              } else {
                itemRows.push(
                  getItemRow(
                    currentRow.keyAdvantageText
                      .replace('<sub>', '')
                      .replace('</sub>', '')
                      .replace('<sup>', '')
                      .replace('</sup>', ''),
                    SpecsExcelStyleType.Item
                  )
                )
              }
            }
          }
        }
      }
    }

    return itemRows
  }

  private getColumnPerCar = (
    columnNumber: number,
    maximumOptionRows: number,
    vehicle: BrochureReturnObject | undefined,
    excelPageDataRequest: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    const column = {
      column: columnNumber,
      rows: [],
    }

    Array.prototype.push.apply(
      column.rows,
      getSpecsHeader(
        getExcelVehicleName(
          vehicle!.vehicleHeaderInfo,
          excelPageDataRequest.isLocal
        ),
        vehicle!.vehicleHeaderInfo.displayPrice
      )
    )
    Array.prototype.push.apply(
      column.rows,
      this.getOptions(
        maximumOptionRows,
        vehicle!.vehicleHeaderInfo.vehicleId,
        vehicle!.builtOptions
      )
    )
    Array.prototype.push.apply(
      column.rows,
      this.getAdvantagePerColumn(
        excelPageDataRequest.comparisonDataList,
        vehicle!.vehicleHeaderInfo.vehicleId,
        translations
      )
    )
    return column
  }

  private getOptionData = (translations: AppTextTranslations): void => {
    //Int based on vehicles create dictionary
    this.formateVehicleData = []
    this.formateVehicleData.push(
      {
        key: 'benchmarkAdv',
        text: translations.JNT_BenchmarkAdv + ':' + translations.JNT_StdEqui,
        value: [],
      },
      {
        key: 'benchmarkOptAdv',
        text: translations.JNT_BenchmarkAdv + ':' + translations.JNT_OptEqui,
        value: [],
      },
      {
        key: 'benchmarkDisAdv',
        text: translations.JNT_BenchmarkDisadv + ':' + translations.JNT_StdEqui,
        value: [],
      },
      {
        key: 'benchmarkOptDisAdv',
        text: translations.JNT_BenchmarkDisadv + ':' + translations.JNT_OptEqui,
        value: [],
      }
    )
  }

  public excelVehiclesComparisonBody = (
    sheetName: string,
    excelPageDataRequest: ExcelPageDataRequest,
    translations: AppTextTranslations
  ): any => {
    const table: any[] = []
    const body = {
      sheetName: sheetName,
      table: table,
    }
    const maximumOptionRows = getMaximumRowsLengthForOptionsExcel(
      excelPageDataRequest.brochureResponse?.brochureVehicles
    )
    let columnNumber
    this.getOptionData(translations)

    body.table.push(
      this.getColumnTitles(
        maximumOptionRows,
        excelPageDataRequest,
        translations
      )
    )
    const nonBenchMarkVehicles: BrochureReturnObject[] | undefined =
      excelPageDataRequest.brochureResponse!.brochureVehicles.filter(
        (vehicle: BrochureReturnObject) =>
          vehicle.vehicleHeaderInfo.vehicleId !==
            excelPageDataRequest.benchmarkVehicleId ?? []
      )

    for (let i = 0; i < nonBenchMarkVehicles.length; i++) {
      columnNumber = i + 2
      body.table.push(
        this.getColumnPerCar(
          columnNumber,
          maximumOptionRows,
          nonBenchMarkVehicles[i],
          excelPageDataRequest,
          translations
        )
      )
    }
    return [body]
  }
}

const comparisonExcelTemplate = new ComparisonExcelTemplate()
export default comparisonExcelTemplate
