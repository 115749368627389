import { Accordion } from 'components/Accordion/Accordion'
import MyPageBasketsSharedToYou from 'components/MyPage/MyPageBasketsSharedToYou'
import MyPageLatestIndustryVolumesInsights from 'components/MyPage/MyPageLatestIndustryVolumesInsights'
import MyPageMyNews from 'components/MyPage/MyPageMyNews'
import MyPageMySpecifications from 'components/MyPage/MyPageMySpecifications'
import MyPageMyVolumes from 'components/MyPage/MyPageMyVolumes'
import MyPageNavigation from 'components/MyPage/MyPageNavigation'
import MyPageQueriesSharedToYou from 'components/MyPage/MyPageQueriesSharedToYou'
import MyPageWelcome from 'components/MyPage/MyPageWelcome'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledMyPage } from './MyPage.styles'

export const MyPage: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledMyPage>
      <MyPageNavigation />
      <Container xl lg md sm xs className="myPageContainer">
        <Row>
          <Col sm={4}>
            <div className="accordion-container">
              <Accordion
                id="myPageWelcomeAccordion"
                titleText={translations.JNT_Portal_Login_Welcome}
                isOpen={false}
                titleHeaderWidth="100%"
              >
                <div className="accordion-content ">
                  <MyPageWelcome />
                </div>
              </Accordion>
            </div>
          </Col>
          <Col sm={4}>
            <div className="accordion-container">
              <Accordion
                id="myPageYourQueriesAccordion"
                titleText={translations.JNT_Portal_MJ_YourQueries}
                isOpen={false}
                titleHeaderWidth="100%"
              >
                <div className="accordion-content ">
                  <MyPageMyVolumes />
                </div>
              </Accordion>
            </div>
          </Col>
          <Col sm={4}>
            <div className="accordion-container">
              <Accordion
                id="myPageSharedQueriesAccordion"
                titleText={translations.JNT_Portal_MJN_SharedQueries}
                isOpen={false}
                titleHeaderWidth="100%"
              >
                <div className="accordion-content ">
                  <MyPageQueriesSharedToYou />
                </div>
              </Accordion>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <div className="accordion-container">
              <Accordion
                id="myPageMySearchesAccordion"
                titleText={translations.JNT_Portal_NAV_mySearches}
                isOpen={false}
                titleHeaderWidth="100%"
              >
                <div className="accordion-content ">
                  <MyPageMyNews />
                </div>
              </Accordion>
            </div>
          </Col>
          <Col sm={4}>
            <div className="accordion-container">
              <Accordion
                id="myPageYourBasketsAccordion"
                titleText={translations.JNT_Portal_MJ_YourBaskets}
                isOpen={false}
                titleHeaderWidth="100%"
              >
                <div className="accordion-content ">
                  <MyPageMySpecifications />
                </div>
              </Accordion>
            </div>
          </Col>
          <Col sm={4}>
            <div className="accordion-container">
              <Accordion
                id="myPageBasketsShareAccordion"
                titleText={translations.JNT_Portal_BAS_share}
                isOpen={false}
                titleHeaderWidth="100%"
              >
                <div className="accordion-content ">
                  <MyPageBasketsSharedToYou />
                </div>
              </Accordion>
            </div>
          </Col>
        </Row>
        <MyPageLatestIndustryVolumesInsights />
      </Container>
    </StyledMyPage>
  )
}
