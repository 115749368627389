import React from 'react'
import { StyledTerms } from './Terms.styles'
import { getTranslations } from 'redux/translations/translationsSlice'
import { useAppSelector } from 'redux/hook'

export const Terms: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  return (
    <StyledTerms>
      {' '}
      <div className="termHeading">
        <span>{translations.JNT_Portal_Term_Heading}</span>
      </div>
      <div>
        <p>{translations.JNT_Portal_Term_AgreementHeadline}</p>
        <p>{translations.JNT_Portal_Term_Agreement}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading1}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Portal_Term_Heading1Text,
          }}
        />
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading2}</p>
        <p>{translations.JNT_Portal_Term_Heading2Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading3}</p>
        <p>{translations.JNT_Portal_Term_Heading3_1Text}</p>
        <p>{translations.JNT_Portal_Term_Heading3_1_1Text}</p>
        <p>{translations.JNT_Portal_Term_Heading3_1_2Text}</p>
        <p>{translations.JNT_Portal_Term_Heading3_2Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading4}</p>
        <p>{translations.JNT_Portal_Term_Heading4_1Text}</p>
        <p>{translations.JNT_Portal_Term_Heading4_2Text}</p>
        <p>{translations.JNT_Portal_Term_Heading4_3Text}</p>
        <p>{translations.JNT_Portal_Term_Heading4_4Text}</p>
        <p>{translations.JNT_Portal_Term_Heading4_5Text}</p>
        <p>{translations.JNT_Portal_Term_Heading4_6Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading5}</p>
        <p>{translations.JNT_Portal_Term_Heading5Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading6}</p>
        <p>{translations.JNT_Portal_Term_Heading6Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading7}</p>
        <p>{translations.JNT_Portal_Term_Heading7Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading8}</p>
        <p>{translations.JNT_Portal_Term_Heading8Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading9}</p>
        <p>{translations.JNT_Portal_Term_Heading9Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading10}</p>
        <p>{translations.JNT_Portal_Term_Heading10Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading11}</p>
        <p>{translations.JNT_Portal_Term_Heading11Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading12}</p>
        <p>{translations.JNT_Portal_Term_Heading12Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading13}</p>
        <p>{translations.JNT_Portal_Term_Heading13Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading14}</p>
        <p>{translations.JNT_Portal_Term_Heading14Text}</p>
        <p className="termHeadLine">{translations.JNT_Portal_Term_Heading15}</p>
        <p>{translations.JNT_Portal_Term_Heading15Text}</p>
        <p>{translations.JNT_Portal_Login_DoYouAcceptTerm} </p>
        <p>{translations.JNT_Portal_Login_MustAcceptTermText}</p>
      </div>
    </StyledTerms>
  )
}
