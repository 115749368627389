import { newsNewBadgeEnabled } from 'config'
import { useRootPathResolver } from 'hooks/useRootPathResolver'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { Header, IRoute } from './Header'

const HeaderContainerWithRouter: React.FC = () => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const { guid, languageId } = commonUserData
  const translations = useAppSelector(getTranslations)
  const { isMyPage, isNews, isVolumes, isSpecifications, isMonthlyPayments } =
    useRootPathResolver()

  const navRoutes: IRoute[] = [
    {
      id: 'headerNavBarMyPageLink',
      name: translations.JNT_Nav_MyJatonet,
      path: `${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.MyPage}`,
      isActive: isMyPage,
    },
    {
      id: 'headerNavBarNewsLink',
      name: translations.JNT_Nav_News,
      path: `${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.News}`,
      isActive: isNews,
      badge: {
        badgeText: translations.JNT_New,
        isActive: newsNewBadgeEnabled,
      },
    },
    {
      id: 'headerNavBarVolumesLink',
      name: translations.JNT_Nav_Volumes,
      path: `${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.Volumes}`,
      isActive: isVolumes,
    },
    {
      id: 'headerNavBarSpecificationsLink',
      name: translations.JNT_Nav_Specs,
      path: `${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.Specifications}`,
      isActive: isSpecifications,
    },
    {
      id: 'headerNavBarMonthlyPaymentsLink',
      name: translations.JNT_Nav_MonthlyPayments,
      path: `${process.env.PUBLIC_URL}${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.MonthlyPayments}`,
      isActive: isMonthlyPayments,
    },
  ]

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>(`${languageId}`)

  const handleChangeLanguage = (language: string): void => {
    setLanguage(language)
  }

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <Header
      handleChangeLanguage={handleChangeLanguage}
      handleToggleDrawer={handleToggleDrawer}
      isDrawerOpen={isDrawerOpen}
      language={language}
      navRoutes={navRoutes}
    />
  )
}

export const HeaderContainer = withRouter(HeaderContainerWithRouter)
