import { WltpErrorMessage } from 'models/Specifications/GetBuiltVehiclesResponse'
import React from 'react'
import { StyledWltpErrors } from './WltpErrors.styles'

interface IWltpErrors {
  errors: WltpErrorMessage[]
}

export const WltpErrors: React.FC<IWltpErrors> = ({ errors }: IWltpErrors) => (
  <StyledWltpErrors>
    {
      <>
        <div className="errorHeading">OEM Message</div>
        <div className="errorMessage">{errors[0].errorMessage}</div>
      </>
    }
  </StyledWltpErrors>
)
