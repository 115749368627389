/**
 * Helper functions to format numbers and currency with variable decimal places
 */

import { mpCountryCodes } from 'config'
import { CurrencyUnits } from 'models/Filters/FilterUnits'

const getCurrency = (region: string): string => {
  switch (region) {
    case mpCountryCodes.France:
    case mpCountryCodes.Germany:
    case mpCountryCodes.Italy:
    case mpCountryCodes.Spain:
    case mpCountryCodes.Belgium:
    case mpCountryCodes.TheNetherlands:
      return CurrencyUnits.Euros
    default:
      return CurrencyUnits.GreatBritishPounds
  }
}

const getLocale = (region: string): string => {
  switch (region) {
    case mpCountryCodes.France:
      return 'fr-FR'
    case mpCountryCodes.Germany:
      return 'de-DE'
    case mpCountryCodes.Italy:
      return 'it-IT'
    case mpCountryCodes.Spain:
      return 'es-ES'
    case mpCountryCodes.Belgium:
      return 'nl-BE'
    case mpCountryCodes.TheNetherlands:
      return 'nl-NL'
    default:
      return 'en-GB'
  }
}

const format = (
  number: number | string,
  market: string,
  currency: boolean,
  precision: number
): string => {
  const region = !market ? 'GB' : market.toUpperCase()
  const currencyFormat = getCurrency(region)
  const locale = getLocale(region)

  const value = new Intl.NumberFormat(locale, {
    currency: currencyFormat,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    style: currency ? 'currency' : 'decimal',
    /**
     * Spanish (ES) locales require useGrouping to be true in order to display a
     * thousands separator for 4 digit values. The browser's Javascript engine
     * uses a spec for ES locales which states that separators are included
     * only when there are at least 5 digits.
     */
    // useGrouping: true, // Awaiting confirmation from Matt before enabling
  }).format(number as number)

  return value
  // return precision ? value : value.replace(/(\.|,)\d\d(?!.*(\.|,)\d\d)/, '')
}

/**
 * Format a number with market's currency symbol and variable decimal places
 *
 * ```
 * // For example:
 * formatCurrency(1234567.89, 'GB')    // £1,234,567
 * formatCurrency(1234567.89, 'GB', 2) // £1,234,567.89
 * formatCurrency(1234567.89, 'F', 0)  // 1 234 567 €
 * formatCurrency(1234567.89, 'F', 2)  // 1 234 567,89 €
 * formatCurrency(1234567.89, 'I', 0)  // 1.234.567 €
 * formatCurrency(1234567.89, 'I', 2)  // 1.234.567,89 €
 * ```
 */
export const formatCurrency = (
  number: number | string,
  market: string,
  precision = 0
): string => format(number, market, true, precision)

/**
 * Format a number without currency symbol and variable decimal places
 *
 * ```
 * // For example:
 * formatNumber(1234567.89, 'GB')    // 1,234,567
 * formatNumber(1234567.89, 'GB', 2) // 1,234,567.89
 * formatNumber(1234567.89, 'F', 0)  // 1 234 567
 * formatNumber(1234567.89, 'F', 2)  // 1 234 567,89
 * formatNumber(1234567.89, 'I', 0)  // 1.234.567
 * formatNumber(1234567.89, 'I', 2)  // 1.234.567,89
 * ```
 */
export const formatNumber = (
  number: number | string,
  market: string,
  precision = 0
): string => format(number, market, false, precision)
