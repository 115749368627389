import {
  JATOButton,
  JATOButtonGroup,
  JATOButtonIcon,
  JATOHeading,
  JATOModal,
} from '@jato/ui-component-library'
import React from 'react'
interface IProps {
  children: React.ReactNode
}

export interface THandleOpen {
  /** function to open modal */
  handleOpen(): void
}
export interface THandleClose {
  /** function to close modal */
  handleClose(): void
}

interface IModalComponentProps extends IProps {
  trigger?(props: THandleOpen): React.ReactNode
  isOpen?: boolean
  onClose?: () => void
  title?: string
  closeButton?: boolean
  dismissable?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'none'
  primaryButtonName?: string
  secondaryButtonName?: string
  disablePrimaryButton?: boolean
  disableSecondaryButton?: boolean
  onPrimaryClick?(props: THandleClose): void
  onSecondaryClick?(props: THandleClose): void
  children: React.ReactNode
}

export const CommonModal: React.FC<IModalComponentProps> = (
  props: IModalComponentProps
) => (
  <JATOModal
    trigger={props.trigger}
    isOpen={props.isOpen}
    onClose={props.onClose}
    dismissable={props.dismissable}
    maxWidth={props.maxWidth ? props.maxWidth : 'sm'}
    header={({ handleClose }) => (
      <>
        <JATOHeading as="h4" fontWeight="medium" display="flex">
          {props.title}
        </JATOHeading>
        {props.closeButton && (
          <JATOButtonIcon iconName="baseline_close" onClick={handleClose} />
        )}
      </>
    )}
    footer={
      // Add footer only if there are buttons
      props.primaryButtonName || props.secondaryButtonName
        ? ({ handleClose }) => (
            <JATOButtonGroup justifyContent="flex-end">
              {props.secondaryButtonName && (
                <JATOButton
                  onClick={() =>
                    props.onSecondaryClick &&
                    props.onSecondaryClick({
                      handleClose: () => handleClose && handleClose(),
                    })
                  }
                  variant="secondary"
                  size="large"
                  disabled={props.disableSecondaryButton}
                >
                  {props.secondaryButtonName}
                </JATOButton>
              )}
              {props.primaryButtonName && (
                <JATOButton
                  onClick={() =>
                    props.onPrimaryClick &&
                    props.onPrimaryClick({
                      handleClose: () => handleClose && handleClose(),
                    })
                  }
                  variant="primary"
                  size="large"
                  disabled={props.disablePrimaryButton}
                >
                  {props.primaryButtonName}
                </JATOButton>
              )}
            </JATOButtonGroup>
          )
        : undefined
    }
  >
    {props.children}
  </JATOModal>
)
