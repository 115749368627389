import { JATOButtonIcon } from '@jato/ui-component-library'
import NewsSearchAlertEditor from 'components/News/NewsSearchAlertEditor'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React, { useState } from 'react'
import { StyledNewsSavedSearchEditButton } from './NewsSavedSearchEditButton.styles'

export interface INewsSavedSearchEditButtonProps {
  searchRequest: SearchIndexRequest
}

export const NewsSavedSearchEditButton: React.FC<
  INewsSavedSearchEditButtonProps
> = ({ searchRequest }: INewsSavedSearchEditButtonProps) => {
  const [showEditSearchModal, setEditSearchModalOpen] = useState(false)

  return (
    <StyledNewsSavedSearchEditButton>
      <JATOButtonIcon
        id="newsSavedSearchEditButton"
        iconName="custom_edit"
        onClick={() => setEditSearchModalOpen((prevState) => !prevState)}
      />
      {showEditSearchModal && (
        <NewsSearchAlertEditor
          showWindow={showEditSearchModal}
          setWindowState={setEditSearchModalOpen}
          searchIndexRequest={searchRequest}
        />
      )}
    </StyledNewsSavedSearchEditButton>
  )
}
