import {
  JATOButton,
  JATOGroup,
  JATOSelect2,
  JATOText,
  JATOTextInput,
} from '@jato/ui-component-library'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { DpPartBetweenValue, getAllValuesDataPoint } from 'helper/volumesHelper'
import { useGetPricingCurrencyData } from 'hooks/volumes'
import { DpPoint } from 'models/Volumes/DpPoint'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  getVolumesUserState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import {
  IVolumesQuerySelectionPanelProps,
  IVolumesQuerySelectionPanelRef,
} from './VolumesQuerySelectionPanel'

const VolumesQueryNumericSelectionPanel: React.ForwardRefRenderFunction<
  IVolumesQuerySelectionPanelRef,
  IVolumesQuerySelectionPanelProps
> = ({ selectedItem }: IVolumesQuerySelectionPanelProps, ref) => {
  const dispatch = useAppDispatch()

  useImperativeHandle(ref, () => ({
    onSelectAll,
    onClearChoices,
  }))

  const translations = useAppSelector(getTranslations)
  const queryState = useAppSelector(getVolumesQueryState)
  const userState = useAppSelector(getVolumesUserState)

  const currencyEnabled = selectedItem?.dataPointGroup === 'VOL_Pricing'
  const { data: currencyData, isFetching } =
    useGetPricingCurrencyData(currencyEnabled)

  const getDefaultDataPoint = (): DpPoint => ({
    id: selectedItem?.dataPointName ?? '',
    iddesc: selectedItem?.longText ?? '',
    value: 'N',
    valuedesc: '',
    part: 0,
    state: false,
    minValue: 0,
    maxValue: 0,
    optype: selectedItem?.dataPointGroup ?? '',
  })

  const getDataPoint = (): DpPoint => {
    const existingDpPoint = queryState.vehicleAttributes?.find(
      (p) => p.id === selectedItem?.dataPointName
    )

    return existingDpPoint
      ? { ...existingDpPoint, optype: selectedItem?.dataPointGroup ?? '' }
      : getDefaultDataPoint()
  }

  const [dataPoint, setDataPoint] = useState(getDataPoint)

  useEffect(() => {
    setDataPoint(getDataPoint())
  }, [selectedItem])

  const onInvertChange = (): void => {
    setDataPoint({
      ...dataPoint,
      state: !dataPoint.state,
      minValue: 0,
      maxValue: 0,
    })
  }

  const onSelectedOptionChange = (value: number, description: string): void => {
    setDataPoint({
      ...dataPoint,
      part: value,
      valuedesc: description,
      minValue: 0,
      maxValue: 0,
    })
  }

  const onMinValueChange = (value: number): void => {
    setDataPoint({
      ...dataPoint,
      minValue: value,
    })
  }

  const onMaxValueChange = (value: number): void => {
    setDataPoint({
      ...dataPoint,
      maxValue: value,
    })
  }

  const onSelectAll = (): void => {
    const newDataPoints =
      queryState.vehicleAttributes?.filter(
        (p) => p.id !== selectedItem?.dataPointName
      ) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        vehicleAttributes: [
          ...newDataPoints,
          getAllValuesDataPoint(selectedItem),
        ],
      })
    )
  }

  const onClearChoices = (): void => {
    dispatch(
      setVolumesQueryState({
        ...queryState,
        vehicleAttributes:
          queryState.vehicleAttributes?.filter(
            (p) => p.id !== selectedItem?.dataPointName
          ) ?? [],
      })
    )
    setDataPoint(getDefaultDataPoint())
  }

  const onUpdateValue = (): void => {
    const { part, minValue, maxValue } = dataPoint
    if (part === DpPartBetweenValue && (minValue ?? 0) >= (maxValue ?? 0))
      return

    const newDataPoints =
      queryState.vehicleAttributes?.filter(
        (p) => p.id !== selectedItem?.dataPointName
      ) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        vehicleAttributes: [...newDataPoints, dataPoint],
      })
    )
  }

  const onChangeCurrency = (value: string): void => {
    dispatch(
      setVolumesQueryState({
        ...queryState,
        bandingCurrency: value,
      })
    )
  }

  const options = [
    {
      description: translations.JNT_Volumes_QG_NB_Equals,
      symbol: '=',
      part: 2,
      state: false,
    },
    {
      description: translations.JNT_Volumes_QG_NB_GreaterThan,
      symbol: '>',
      part: 3,
      state: false,
    },
    {
      description: translations.JNT_Volumes_QG_NB_LessThan,
      symbol: '<',
      part: 4,
      state: false,
    },
    {
      description: translations.JNT_Volumes_QG_NB_Between,
      symbol: translations.JNT_Volumes_QG_NB_Between,
      part: 5,
      state: false,
    },
    {
      description: translations.JNT_Volumes_QG_NB_Equal_NOT,
      symbol: '<>',
      part: 2,
      state: true,
    },
    {
      description: translations.JNT_Volumes_QG_NB_LessThan,
      symbol: '<',
      part: 3,
      state: true,
    },
    {
      description: translations.JNT_Volumes_QG_NB_GreaterThan,
      symbol: '>',
      part: 4,
      state: true,
    },
    {
      description: translations.JNT_Volumes_QG_NB_Between_NOT,
      symbol: translations.JNT_Volumes_QG_NB_Between_NOT,
      part: 5,
      state: true,
    },
  ]

  return (
    <>
      <JATOButton
        id="volumesQueryNumericSelectionPanelInvertButton"
        variant="ghost"
        style={{ justifyContent: 'center' }}
        onClick={onInvertChange}
      >
        {translations.JNT_Volumes_QG_NB_Invert}
      </JATOButton>
      <JATOGroup flexDirection="row" style={{ justifyContent: 'space-around' }}>
        {options
          .filter((o) => o.state === dataPoint.state)
          .map((o) => (
            <RadioWithGtm
              key={o.part}
              id="volumesQueryNumericSelectionPanelOption"
              value={o.symbol}
              label={o.description}
              parentLabel={selectedItem?.longText}
              checked={dataPoint.part === o.part}
              onChange={() => onSelectedOptionChange(o.part, o.description)}
            >
              {o.symbol}
            </RadioWithGtm>
          ))}
      </JATOGroup>
      <JATOGroup flexDirection="row" style={{ justifyContent: 'center' }}>
        {!!dataPoint.part && (
          <JATOTextInput
            id=""
            value={dataPoint.minValue}
            onBlur={onUpdateValue}
            onChange={(e) => onMinValueChange(Number(e.target.value))}
            type="number"
          />
        )}
        {dataPoint.part === DpPartBetweenValue && (
          <>
            <JATOText as="div">{translations.JNT_Volumes_QG_NB_And}</JATOText>
            <JATOTextInput
              id="maxValue"
              value={dataPoint.maxValue}
              onBlur={onUpdateValue}
              onChange={(e) => onMaxValueChange(Number(e.target.value))}
              type="number"
            />
          </>
        )}
      </JATOGroup>
      {currencyEnabled && currencyData && (
        <JATOSelect2
          id="volumesQueryNumericSelectionPanelCurrenciesSelect"
          value={
            currencyData.find(
              (c) =>
                c.dataPointValue ===
                (queryState.bandingCurrency ?? userState.currency)
            )?.description
          }
          dataSrc={currencyData.map((c) => ({
            value: c.dataPointValue,
            displayValue: c.description,
          }))}
          onChange={onChangeCurrency}
          width="100%"
          ignoreCase={true}
          displayOnlyUnselectedOptions={false}
          hideClearSelection={true}
        />
      )}
      <LoaderModal isOpen={isFetching} />
    </>
  )
}

export default React.forwardRef(VolumesQueryNumericSelectionPanel)
