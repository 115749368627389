import styled from 'styled-components'

export const StyledNewsSearchResults = styled.div`
  .jato-nav-bar {
    align-items: flex-start;
  }

  .topLeftGroup {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }
`
