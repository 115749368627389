import styled from 'styled-components'

export const StyledNewsRemoveShareButton = styled.div`
  button {
    width: 24px;
    height: 24px;
    border-radius: 6px;
  }
  button[disabled] {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    cursor: not-allowed;
  }
`
