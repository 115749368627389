import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import {
  AdvancedOptionFilterType,
  IOptionFilterType,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/hook'
import {
  getAdvancedFilters,
  setAdvancedFilters,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import { StyledSpecsAdvanceOptionFilter } from './SpecsAdvanceOptionFilter.styles'

interface IProps {
  onChange: (optionFilterType: IOptionFilterType) => void
}

export const SpecsAdvanceOptionFilter: React.FC<IProps> = ({
  onChange,
}: IProps) => {
  const dispatch = useDispatch()
  const translations = useAppSelector(getTranslations)

  const optionFilterTypes = new Map([
    [AdvancedOptionFilterType.Std, translations.JNT_Std],
    [AdvancedOptionFilterType.StdAndOptions, translations.JNT_StdAndOptions],
    [AdvancedOptionFilterType.OptionsOnly, translations.JNT_OptionsOnly],
  ])

  const advancedFilters = useAppSelector(getAdvancedFilters)
  const { lastSelectedOptionFilterType } = advancedFilters

  useEffect(() => {
    const optionFilterType = {
      value: lastSelectedOptionFilterType,
      text: optionFilterTypes.get(lastSelectedOptionFilterType) ?? '',
    }
    onChange(optionFilterType)
  }, [lastSelectedOptionFilterType])

  const onChangeInternal = (value: number, text: string): void => {
    const advancedFiltersUpdate = {
      ...advancedFilters,
      lastSelectedOptionFilterType: value,
    }
    dispatch(setAdvancedFilters(advancedFiltersUpdate))

    const optionFilterType = { value, text }
    onChange(optionFilterType)
  }

  return (
    <StyledSpecsAdvanceOptionFilter>
      {Array.from(optionFilterTypes).map(([optionValue, optionText]) => (
        <RadioWithGtm
          id="specsAdvancedFiltersOption"
          value={AdvancedOptionFilterType[optionValue]}
          checked={optionValue === lastSelectedOptionFilterType}
          onChange={() => onChangeInternal(optionValue, optionText)}
          key={uuid()}
          label={optionText}
        >
          <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
            {optionText}
          </JATOText>
        </RadioWithGtm>
      ))}
    </StyledSpecsAdvanceOptionFilter>
  )
}
