import { Accordion } from 'components/Accordion/Accordion'
import { AccordionToggle } from 'components/AccordionToggle/AccordionToggle'
import Loader from 'components/Loader'
import { removeDuplicates } from 'helper/arrayHelper'
import { mapOptionIcon } from 'helper/specificationsBuildRequestHelper'
import {
  getNoOptionsText,
  getOptionsTitleText,
} from 'helper/specificationsOptionsHelper'
import { useGetOptionals } from 'hooks/carSpecs'
import { OptionIconType } from 'models/Specifications/Options/OptionsPageResponse'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReportPageType } from 'router/RoutePaths'
import { v4 as uuid } from 'uuid'
import { SpecificationsOptionBuildButton } from '../SpecificationsOptions/SpecificationsOptionBuildButton'
import { StyledSpecificationsOptionsReport } from './SpecificationsOptionsReport.styles'

export const SpecificationsOptionsReport: React.FC = () => {
  const { page: optionsPage } = useParams<{ page: ReportPageType }>()
  const titleText = getOptionsTitleText(optionsPage)
  const noOptionsText = getNoOptionsText(optionsPage)
  const [openStatus, setOpenStatus] = useState(false)

  const {
    data: { uniqueCategories, vehicles } = {
      uniqueCategories: [],
      vehicles: [],
    },
    isFetching,
  } = useGetOptionals(optionsPage)

  const getOrderId = (category: string): number =>
    uniqueCategories.find((c) => c.translatedCategoryName === category)
      ?.optionOrderId ?? -1

  // filter out categories that are not present in data rows
  const filteredCategories = vehicles
    .flatMap((v) => v.rows)
    .flatMap((r) => r.translatedCategoryName)
    .filter(removeDuplicates)
    .toSorted((a, b) => getOrderId(a) - getOrderId(b))

  return (
    <StyledSpecificationsOptionsReport>
      <AccordionToggle
        id="specsReportAccordionToggle"
        titleText={titleText}
        isOpen={openStatus}
        titleHeaderWidth="72vw"
        onClick={() => setOpenStatus(!openStatus)}
      />
      {isFetching ? (
        <Loader style={{ minHeight: '150px' }} />
      ) : filteredCategories.length > 0 ? (
        filteredCategories.map((categoryName) => (
          <Accordion
            id="specsReportAccordion"
            titleText={categoryName}
            key={uuid()}
            isOpen={openStatus}
            titleHeaderWidth="72vw"
          >
            <table className="tableStyle" key={uuid()}>
              <tbody>
                <tr className="tableRow">
                  {vehicles.map(({ header, rows }) => (
                    <td key={uuid()} className="tableCell">
                      {rows
                        .filter(
                          (row) => row.translatedCategoryName === categoryName
                        )
                        .toSorted((a, b) => a.rowId - b.rowId)
                        .map((row) => (
                          <React.Fragment key={uuid()}>
                            <div key={uuid()} className="tableValueHeader">
                              <SpecificationsOptionBuildButton
                                categoryName={categoryName}
                                optionId={row.optionId}
                                vehicleHeader={header}
                                iconSrc={mapOptionIcon(row.status)}
                                addedAsOption={
                                  row.status === OptionIconType.AddedBuilt
                                }
                                optionName={`${row.optionName} ${row.displayPrice}`}
                              />
                            </div>
                            {row.content.content.map((node) => (
                              <div key={uuid()} className="tableValue">
                                {node}
                              </div>
                            ))}
                          </React.Fragment>
                        ))}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </Accordion>
        ))
      ) : (
        <React.Fragment key={uuid()}>
          <table>
            <tbody>
              <tr>
                <td>{noOptionsText}</td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      )}
    </StyledSpecificationsOptionsReport>
  )
}
