import {
  JATONavBar,
  JATONavGroup,
  JATONavItem,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { defaultArticlesInGridNumber, getArticles } from 'helper/newsHelper'
import { useSharedArticles } from 'hooks/news'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import NewsBreadcrumbs from '../NewsBreadcrumbs'
import NewsGrid, { NewsGridColumnType } from '../NewsGrid'
import { StyledNewsSharedArticleViewAll } from './NewsSharedArticleViewAll.styles'

export const NewsSharedArticleViewAll: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const translations = useAppSelector(getTranslations)
  const shareTabList: string[] = [
    translations.JNT_ArticleIHaveShared,
    translations.JNT_ArticleSharedWithMe,
  ]

  const [selectedMainTab, setMainTab] = useState<string>(shareTabList[0])

  const isShareByCustomerUserGroup =
    selectedMainTab === translations.JNT_ArticleSharedWithMe

  const { data: articlesResponse, isFetching } = useSharedArticles(
    isShareByCustomerUserGroup,
    defaultArticlesInGridNumber,
    currentPage,
    ['sharedDate desc']
  )

  const defaultColumns = [
    NewsGridColumnType.Headline,
    NewsGridColumnType.Author,
    NewsGridColumnType.Country,
    NewsGridColumnType.SharedBy,
    NewsGridColumnType.ReleaseDate,
    NewsGridColumnType.SharedDate,
  ]

  const gridColumns = isShareByCustomerUserGroup
    ? defaultColumns
    : [...defaultColumns, NewsGridColumnType.RemoveShareAction]

  return (
    <StyledNewsSharedArticleViewAll>
      <Container fluid>
        <Row>
          <Col md={12}>
            <NewsBreadcrumbs title={translations.JNT_SharedArticles} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <JATOVerticalSpacer height={5} />
            <JATONavBar
              left={() => (
                <JATONavGroup flexDirection="row" space={16}>
                  {shareTabList.map((tab: string, index: number) => (
                    <JATONavItem
                      key={uuid()}
                      size={'medium'}
                      isActive={tab === selectedMainTab}
                    >
                      <div
                        id={'newsSharedArticlesTab_' + index}
                        onClick={() => setMainTab(tab)}
                      >
                        {tab}
                      </div>
                    </JATONavItem>
                  ))}
                </JATONavGroup>
              )}
            />
            <JATOVerticalSpacer height={10} />
          </Col>
        </Row>
      </Container>

      {articlesResponse && (
        <NewsGrid
          articles={getArticles(articlesResponse.articleList)}
          currentPage={currentPage}
          totalNumber={articlesResponse.totalNumber}
          onPageChange={setCurrentPage}
          columns={gridColumns}
          isFetching={isFetching}
        />
      )}
      <LoaderModal isOpen={isFetching && !articlesResponse} />
    </StyledNewsSharedArticleViewAll>
  )
}
