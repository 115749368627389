import React, { useState } from 'react'
import { StyledHome } from './Home.styles'
import { SplashScreen } from 'components/Portal/SplashScreen'
import { Col, Container, Row } from 'react-grid-system'
import { SignIn } from 'components/Portal/SignIn'
import { JATOLink } from '@jato/ui-component-library'
import { useTranslations } from 'hooks/translation'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { SalesEnquiry } from 'components/Portal/SalesEnquiry'
import { CustomerSupport } from 'components/Portal/CustomerSupport'
import { ForgotPasswordReset } from 'components/Portal/ForgotPasswordReset'
import { useLocation } from 'react-router-dom'

export const Home: React.FC = () => {
  const { data: translations, isFetching } = useTranslations()
  const [modalSalesEnquiry, setModalSalesEnquiry] = useState(false)
  const [modalCustomerSupport, setModalCustomerSupport] = useState(false)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const passwordreset = searchParams.get('passwordreset') ?? false

  const [modalResetPassword, setModalResetPassword] = useState<boolean>(
    passwordreset === 'true'
  )

  return (
    <StyledHome>
      <Container xl lg md sm xs style={{ paddingTop: '40px' }}>
        <Row style={{ paddingTop: '20px' }}>
          <Col md={9} lg={9}>
            <SplashScreen />
          </Col>
          <Col md={3} lg={3} style={{ padding: 0 }}>
            {isFetching ? <Loader style={{ minHeight: '100%' }} /> : <SignIn />}
          </Col>
        </Row>
        <Row style={{ padding: '20px 0px' }}>
          <Col md={2} lg={2}>
            {translations && (
              <JATOLink
                id="logInSalesEnquiryLink"
                textTransform="none"
                onClick={() => setModalSalesEnquiry(true)}
              >
                {translations.appTextTranslation.JNT_Portal_Login_sales}
              </JATOLink>
            )}
          </Col>
          <Col md={10} lg={10}>
            {translations && (
              <JATOLink
                id="logInCustomerSupportLink"
                textTransform="none"
                onClick={() => setModalCustomerSupport(true)}
              >
                {translations.appTextTranslation.JNT_Portal_Login_Support}
              </JATOLink>
            )}
          </Col>
        </Row>
      </Container>

      {translations && (
        <CommonModal
          closeButton
          maxWidth="lg"
          isOpen={modalSalesEnquiry}
          onClose={() => setModalSalesEnquiry(false)}
          title={
            translations.appTextTranslation.JNT_Portal_SalesEnquiry_Heading
          }
        >
          <SalesEnquiry onClose={() => setModalSalesEnquiry(false)} />
        </CommonModal>
      )}
      {translations && (
        <CommonModal
          closeButton
          maxWidth="lg"
          isOpen={modalCustomerSupport}
          onClose={() => setModalCustomerSupport(false)}
          title={translations.appTextTranslation.JNT_Portal_TechSupport_Heading}
        >
          <CustomerSupport onClose={() => setModalCustomerSupport(false)} />
        </CommonModal>
      )}
      {translations && (
        <CommonModal
          closeButton
          maxWidth="md"
          isOpen={modalResetPassword}
          onClose={() => setModalResetPassword(false)}
          title={translations.appTextTranslation.JNT_Portal_ForgotPass_Reset}
        >
          <ForgotPasswordReset onClose={() => setModalResetPassword(false)} />
        </CommonModal>
      )}
    </StyledHome>
  )
}
