import NewsNavigation from 'components/News/NewsNavigation'
import DefaultLayout from 'layouts/Default'
import React from 'react'
import { StyledNewsLayout } from './styles'

interface IProps {
  children: React.ReactNode
}

export const NewsLayout: React.FC<IProps> = ({ children }: IProps) => (
  <DefaultLayout>
    <NewsNavigation />
    <StyledNewsLayout id="newsContainer">{children}</StyledNewsLayout>
  </DefaultLayout>
)

export default NewsLayout
