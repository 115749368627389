import { JATOButtonIcon, JATOTheme } from '@jato/ui-component-library'
import { CommonModal } from 'components/Modals/CommonModal'
import { WltpErrorMessage } from 'models/Specifications/GetBuiltVehiclesResponse'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { WltpErrors } from './WltpErrors'

interface IWltpErrors {
  modalTitle: string
  errors: WltpErrorMessage[]
}

export const OpenWltpErrorsButton: React.FC<IWltpErrors> = ({
  modalTitle,
  errors,
}: IWltpErrors) => {
  const translations = useAppSelector(getTranslations)
  return (
    <CommonModal
      closeButton
      title={modalTitle}
      trigger={({ handleOpen }) => (
        <JATOButtonIcon
          id="specsReportWltpErrorsButton"
          iconName="baseline_chat"
          iconFill={JATOTheme.colors.primary}
          title={translations.JNT_OEMErrorMessage}
          onClick={() => handleOpen()}
        />
      )}
    >
      <WltpErrors errors={errors} />
    </CommonModal>
  )
}
