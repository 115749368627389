import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { defaultGridPageSize } from 'config'
import { distanceMapper } from 'helper/distanceCodeMapper'
import { gtmLogMonthlyPaymentsFiltersChange } from 'helper/gtm'
import { FilterNameType } from 'models/Filters/FilterNameType'
import {
  IFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import { MileageUnits } from 'models/Filters/FilterUnits'
import { IMpLicensedCountry } from 'models/UserSettings/UserSettings'
import { RootState } from 'redux/store'
import { v4 as uuid } from 'uuid'
export const defaultSelectedDistanceUnits = {
  displayValue: MileageUnits.Miles,
  value: MileageUnits.Miles,
  key: uuid(),
  filterName: FilterNameType.MilesKilometers,
  singularFilterCategory: true,
}

interface FiltersState {
  pageSize: number
  pageSkip: number
  orderBy: string[]
  selectedFilterList: ISelectedFilterOption[]
  cachedOptions: IFilterOption[]
}

const initialState: FiltersState = {
  pageSize: defaultGridPageSize,
  pageSkip: 0,
  orderBy: ['make asc', 'model asc', 'price asc'],
  selectedFilterList: [defaultSelectedDistanceUnits],
  cachedOptions: [],
}

export const selectedFiltersSlice = createSlice({
  name: 'selectedFilters',
  initialState,
  reducers: {
    addFilter: (state, { payload }: PayloadAction<ISelectedFilterOption>) => {
      const newSelectedFilterList = [...state.selectedFilterList] //making a new array
      if (payload.singularFilterCategory) {
        const index = newSelectedFilterList.findIndex(
          (filter: ISelectedFilterOption) =>
            filter.filterName === payload.filterName
        )
        if (index !== -1) {
          newSelectedFilterList.splice(index, 1, payload)
        } else {
          newSelectedFilterList.push(payload)
        }

        gtmLogMonthlyPaymentsFiltersChange(newSelectedFilterList)

        return {
          ...state, //copying the original state
          selectedFilterList: newSelectedFilterList,
          pageSkip: 0, //reset to first page when filters changed
        }
      }

      const index = newSelectedFilterList.findIndex(
        (filter: ISelectedFilterOption) =>
          filter.filterName === payload.filterName && filter.key === payload.key
      )

      if (index === -1) {
        newSelectedFilterList.push(payload)

        gtmLogMonthlyPaymentsFiltersChange(newSelectedFilterList)

        return {
          ...state, //copying the original state
          selectedFilterList: newSelectedFilterList,
          pageSkip: 0, //reset to first page when filters changed
        }
      }
    },
    removeFilter: (
      state,
      { payload }: PayloadAction<ISelectedFilterOption>
    ) => {
      const newSelectedFilterList = state.selectedFilterList.filter(
        (f) => f.filterName !== payload.filterName || f.value !== payload.value
      )

      const isLastFilter =
        newSelectedFilterList[newSelectedFilterList.length - 1]?.filterName ===
        payload.filterName

      gtmLogMonthlyPaymentsFiltersChange(newSelectedFilterList)

      return {
        ...state, //copying the orignal state
        selectedFilterList: newSelectedFilterList,
        pageSkip: 0, //reset to first page when filters changed
        cachedOptions: isLastFilter ? state.cachedOptions : [], //reset cachedOptions if removed value is not from the last modified filter
      }
    },
    resetFilters: (
      state,
      { payload: lastSelectedMarket }: PayloadAction<IMpLicensedCountry>
    ) => {
      const defaultSelectedCountryFilter = {
        value: lastSelectedMarket?.countryCode,
        key: uuid(),
        filterName: FilterNameType.Country,
        displayValue: lastSelectedMarket?.countryName,
        singularFilterCategory: true,
        disableTag: true,
      }

      const distanceUnit = distanceMapper(lastSelectedMarket?.countryCode)

      const mileageFilter: ISelectedFilterOption = {
        ...defaultSelectedDistanceUnits,
        displayValue: distanceUnit,
        value: distanceUnit,
      }

      const newSelectedFilterList: ISelectedFilterOption[] = lastSelectedMarket
        ? [defaultSelectedCountryFilter, mileageFilter]
        : [mileageFilter]

      return {
        ...initialState, //copying the initial state
        selectedFilterList: newSelectedFilterList, //reassingning todos to new array
      }
    },
    setPageSkip: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      pageSkip: payload,
    }),
    setOrderBy: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      orderBy: payload,
    }),
    saveOptions: (state, { payload }: PayloadAction<IFilterOption[]>) => ({
      ...state,
      cachedOptions: payload,
    }),
  },
})

export const {
  addFilter,
  removeFilter,
  resetFilters,
  setPageSkip,
  setOrderBy,
  saveOptions,
} = selectedFiltersSlice.actions

export const selectedFilters = (state: RootState): ISelectedFilterOption[] =>
  state.monthlypayments.selectedFilters.selectedFilterList
export const getPageSkip = (state: RootState): number =>
  state.monthlypayments.selectedFilters.pageSkip
export const getPageSize = (state: RootState): number =>
  state.monthlypayments.selectedFilters.pageSize
export const getOrderBy = (state: RootState): string[] =>
  state.monthlypayments.selectedFilters.orderBy
export const getCachedOptions = (state: RootState): IFilterOption[] =>
  state.monthlypayments.selectedFilters.cachedOptions
export const getSelectedCountry = (
  state: RootState
): ISelectedFilterOption | undefined =>
  state.monthlypayments.selectedFilters.selectedFilterList.find(
    (filter: ISelectedFilterOption) =>
      filter.filterName === FilterNameType.Country
  )

export default selectedFiltersSlice.reducer
