import styled from 'styled-components'

export const StyledMonthlyPaymentsReport = styled.div`
  .ej-mpreport-panel {
    background-color: #ffffff;
    box-shadow:
      0 3px 6px rgb(0 0 0 / 16%),
      0 3px 6px rgb(0 0 0 / 23%);
    overflow: scroll;
    width: 100%;
    height: calc(100vh - 80px - var(--app-header-height));
  }
  .ej-mpreport-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    height: 30px;
    padding-top: 10px;
  }
  .ej-mpreport-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #1b1d1f;
  }

  .ej-panel-left {
    display: flex;
    justify-content: flex-end;
  }

  .addVehicle {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }

  .scrollable-container {
    overflow-y: auto;
    height: calc(100vh - 160px);
  }

  .radioGroup {
    padding: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;
  }
  .nav-container > div {
    gap: 15px;
  }
`
