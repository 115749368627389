import { JATOBasicCard } from '@jato/ui-component-library'
import {
  CarouselComponent,
  CarouselItemDirective,
  CarouselItemsDirective,
} from '@syncfusion/ej2-react-navigations'
import { carouselArticleSlideIntervalInMs } from 'helper/newsHelper'
import { useEnableRtl } from 'hooks/useEnableRtl'
import { INewsArticle } from 'models/News/INewsArticle'
import React from 'react'
import NewsCarouselItem from '../NewsCarouselItem'
import { StyledNewsCarousel } from './NewsCarousel.styles'

export interface INewsCarouselProps {
  articles: INewsArticle[]
}

export const NewsCarousel: React.FC<INewsCarouselProps> = ({
  articles,
}: INewsCarouselProps) => {
  const enableRtl = useEnableRtl()
  return (
    <StyledNewsCarousel>
      <JATOBasicCard className="newsCarousel">
        <CarouselComponent enableRtl={enableRtl}>
          <CarouselItemsDirective>
            {articles.map((article, index) => (
              <CarouselItemDirective
                interval={carouselArticleSlideIntervalInMs}
                key={index}
                template={() => <NewsCarouselItem article={article} />}
              />
            ))}
          </CarouselItemsDirective>
        </CarouselComponent>
      </JATOBasicCard>
    </StyledNewsCarousel>
  )
}
