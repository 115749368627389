import AddedAsOptionIcon from 'assets/icons/AddedAsOptionIcon.svg'
import AvailableAsOptionIcon from 'assets/icons/AvailableAsOptionIcon.svg'
import ExcludedOptionIcon from 'assets/icons/ExcludedOptionIcon.svg'
import NotAvailableIcon from 'assets/icons/NotAvailable.svg'
import StandardIcon from 'assets/icons/Standard.svg'
import {
  BrochureItem,
  BrochureResponse,
  BrochureReturnObject,
  BuiltOption,
} from 'models/Specifications/BrochureResponse'
import {
  BuiltVehicle,
  FormattedValue,
  VehicleHeader,
  VehiclePhotoPath,
} from 'models/Specifications/GetBuiltVehiclesResponse'
import {
  EbrochureItemWithLocation,
  LspBody,
  WltpRequest,
} from 'models/Specifications/GetWltpRequest'
import { OptionIconType } from 'models/Specifications/Options/OptionsPageResponse'
import {
  ICurrencyInfo,
  ILanguageInfo,
} from 'models/Specifications/SpecsUserSettings'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import moment from 'moment'
import { IMappingObject } from './IMappingObject'
import {
  getItemRow,
  SpecsExcelStyleType,
} from './excelExport/excelExportHelper'

export const getVehiclePriceId = (vehiclePriceSetting: any): string =>
  vehiclePriceSetting.isRetailPrice
    ? 'id_902'
    : vehiclePriceSetting.isBasePrice
      ? 'id_903'
      : vehiclePriceSetting.isCountrySpecificPrice
        ? 'id_904'
        : vehiclePriceSetting.isRetailPriceinclDelivery
          ? 'id_906'
          : 'id_902'

export const getCurrentLanguageCode = (
  languages: ILanguageInfo[],
  languageId: number
): string => {
  const languageInfo = languages.find((l) => l.languageId === languageId)

  return languageInfo?.languageCode ?? 'en'
}

export const getCurrencySymbol = (
  currencies: ICurrencyInfo[],
  currencyCode: string
): string => {
  const currencyInfo = currencies.find((c) => c.currencyCode === currencyCode)

  return currencyInfo?.currencySymbol ?? ''
}

type CategoryItem = IMappingObject<FormattedValue[]>
export type VehicleItem = IMappingObject<CategoryItem>

export const getVehicleItems = (vehicles: BuiltVehicle[]): VehicleItem[] =>
  vehicles.map((v) =>
    v.vehicleEbrochurePage.pageItem.reduce<VehicleItem>(
      (mappingObject, item) => ({
        ...mappingObject,
        [item.translatedCategoryName]: {
          ...(mappingObject[item.translatedCategoryName] || {}),
          [item.ebrochureText]: item.formattedValues,
        },
      }),
      {}
    )
  )

export const mapIconSrc = (iconCode: string): string => ExcludedOptionIcon

export const mapIcon = (item: BrochureItem): string | undefined => {
  if (item.dataValue == '-') return NotAvailableIcon

  if (
    item.conFormat == 'A' ||
    item.conFormat == '@' ||
    item.conFormat == 'A|S'
  ) {
    if (!item.dataValue) return NotAvailableIcon
    if (item.optionId === 0) return StandardIcon
    if (item.hasBuildableOption) return AvailableAsOptionIcon
    if (item.isAddedByOption) return AddedAsOptionIcon
  }
}

export const mapOptionIcon = (status: OptionIconType): string => {
  switch (status) {
    case OptionIconType.Standard:
      return StandardIcon
    case OptionIconType.Unavailable:
      return NotAvailableIcon
    case OptionIconType.Option:
      return AvailableAsOptionIcon
    case OptionIconType.AddedBuilt:
      return AddedAsOptionIcon
    case OptionIconType.ExcludedOption:
      return ExcludedOptionIcon
    default:
      return NotAvailableIcon
  }
}

export const wltpCategoryList = ['WLTP']

export enum SchemaType {
  VersionAvailability = 141,
  ExteriorTrimSchemaId = 31101,
  InteriorTrimSchemaId = 33901,
}

type WltpMappingItem = {
  location: string
  schemaIds: IMappingObject<number[]>
}
type WltpMappingObject = IMappingObject<WltpMappingItem>

const wltpMapping: WltpMappingObject = {
  JNT_WLTP_Emssions_Combustion: {
    location: 'I',
    schemaIds: {
      JNT_WLTP_Combined_high: [62204, 62205],
      JNT_WLTP_Combined: [62203],
      JNT_WLTP_LOW_MEDIUM: [62206, 62209, 62212, 62215],
    },
  },
  JNT_WLTP_Consumption_Combustion: {
    location: 'I',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption: [42022, 42023],
      JNT_WLTP_Combined_Consumption: [42005],
      JNT_WLTP_LOW_MEDIUM_Combined: [42026, 42032, 42038, 42044],
    },
  },
  JNT_WLTP_Emssions_Charge_Depleting: {
    location: 'D',
    schemaIds: {
      JNT_WLTP_Combined_high: [62204, 62205],
      JNT_WLTP_Combined: [62203],
      JNT_WLTP_LOW_MEDIUM: [62206, 62209, 62212, 62215],
    },
  },
  JNT_WLTP_Consumption_Charge_Depleting: {
    location: 'G',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption: [42022, 42023],
      JNT_WLTP_Combined_Consumption: [42005],
      JNT_WLTP_LOW_MEDIUM_Combined: [42026, 42032, 42038, 42044],
    },
  },
  JNT_WLTP_Emssions_Charge_Sustaining: {
    location: 'S',
    schemaIds: {
      JNT_WLTP_Combined_high: [62204, 62205],
      JNT_WLTP_Combined: [62203],
      JNT_WLTP_LOW_MEDIUM: [62206, 62209, 62212, 62215],
    },
  },
  JNT_WLTP_Consumption_Charge_Sustaining: {
    location: 'S',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption: [42022, 42023],
      JNT_WLTP_Combined_Consumption: [42005],
      JNT_WLTP_LOW_MEDIUM_Combined: [42026, 42032, 42038, 42044],
    },
  },
  JNT_WLTP_Emssions_UF_Weighted: {
    location: 'U',
    schemaIds: {
      JNT_WLTP_Combined_high: [62204, 62205],
      JNT_WLTP_Combined: [62203],
      JNT_WLTP_LOW_MEDIUM: [62206, 62209, 62212, 62215],
    },
  },
  JNT_WLTP_Consumption_UF_Weighted: {
    location: 'K',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption: [42022, 42023],
      JNT_WLTP_Combined_Consumption: [42005],
      JNT_WLTP_LOW_MEDIUM_Combined: [42026, 42032, 42038, 42044],
    },
  },
  JNT_WLTP_HEV_Consumption_Charge_Depleting: {
    location: 'D',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption_KWH_KM: [62804, 62805],
      JNT_WLTP_Combined_Consumption_KWH_KM: [62803],
      JNT_WLTP_CITY_LOW_MEDIUM_Combined: [62806, 62809, 62812, 62815, 62818],
    },
  },
  JNT_WLTP_PEV_HEV_Consumption: {
    location: 'P',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption_KWH_KM: [62804, 62805],
      JNT_WLTP_Combined_Consumption_KWH_KM: [62803],
      JNT_WLTP_CITY_LOW_MEDIUM_Combined: [62806, 62809, 62812, 62815, 62818],
    },
  },
  JNT_WLTP_HEV_UF_Weighted: {
    location: 'U',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption_KWH_KM: [62804, 62805],
      JNT_WLTP_Combined_Consumption_KWH_KM: [62803],
      JNT_WLTP_CITY_LOW_MEDIUM_Combined: [62806, 62809, 62812, 62815, 62818],
    },
  },
  JNT_WLTP_HEV_All: {
    location: 'A',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption_KM: [62904, 62905],
      JNT_WLTP_Combined_Consumption_KM: [62903],
      JNT_WLTP_CITY_LOW_MEDIUM_Combined_KM: [62909, 62915, 62921, 62927, 62933],
    },
  },
  JNT_WLTP_HEV_Equivalent: {
    location: 'E',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption_KM: [62904, 62905],
      JNT_WLTP_Combined_Consumption_KM: [62903],
      JNT_WLTP_CITY_LOW_MEDIUM_Combined_KM: [62909, 62915, 62921, 62927, 62933],
    },
  },
  JNT_WLTP_PEV_Range: {
    location: 'P',
    schemaIds: {
      JNT_WLTP_Combined_High_Consumption_KM: [62904, 62905],
      JNT_WLTP_Combined_Consumption_KM: [62903],
      JNT_WLTP_CITY_LOW_MEDIUM_Combined_KM: [62909, 62915, 62921, 62927, 62933],
    },
  },
}

export const getWltpVehicleData = (
  brochureVehicles: BrochureReturnObject[],
  wltpBrochureItems: EbrochureItemWithLocation[]
): [number, EbrochureItemWithLocation[]][] => {
  let vehicles = new Map<number, EbrochureItemWithLocation[]>()
  if (wltpBrochureItems.length > 0) {
    vehicles = brochureVehicles.reduce(
      (
        acc: Map<number, EbrochureItemWithLocation[]>,
        value: BrochureReturnObject
      ) => {
        acc.set(
          value.vehicleHeaderInfo.vehicleId,
          wltpBrochureItems
            .filter(
              (brochureItem: EbrochureItemWithLocation) =>
                brochureItem.vehicleId === value.vehicleHeaderInfo.vehicleId
            )
            .filter(
              (
                v: EbrochureItemWithLocation,
                i: number,
                arr: EbrochureItemWithLocation[]
              ) =>
                arr.findIndex(
                  (obj2: EbrochureItemWithLocation) =>
                    obj2.schemaId === v.schemaId
                ) === i
            )
        )
        return acc
      },
      new Map<number, EbrochureItemWithLocation[]>()
    )
    return Array.from(vehicles.entries())
  }

  brochureVehicles.map((v: BrochureReturnObject) => {
    vehicles.set(v.vehicleHeaderInfo.vehicleId, [])
    return vehicles
  })
  return Array.from(vehicles.entries())
}

type WltpCategoryItem = IMappingObject<any[]>
export type WltpVehicleItem = IMappingObject<WltpCategoryItem>

export const getWltpItems = (
  vehicles: [number, EbrochureItemWithLocation[]][]
): WltpVehicleItem[] => {
  const validCategorySet = getValidCategoryMap(vehicles)
  return vehicles.map(
    ([vehicleId, brochureItems]: [number, EbrochureItemWithLocation[]]) => {
      const vehicleItem: WltpVehicleItem = {}
      Object.keys(wltpMapping)
        .filter((category: string) =>
          Array.from(validCategorySet.keys()).includes(category)
        )
        .forEach((wltpCategoryName) => {
          const mappingItem = wltpMapping[wltpCategoryName]
          const categoryItem: WltpCategoryItem = {}
          Object.keys(mappingItem.schemaIds)
            .filter((characteristicName) =>
              Array.from(validCategorySet.get(wltpCategoryName)!).includes(
                characteristicName
              )
            )
            .forEach((characteristicName) => {
              const schemaIds = mappingItem.schemaIds[characteristicName]
              const filteredValues: EbrochureItemWithLocation[] =
                brochureItems.filter(
                  (v: EbrochureItemWithLocation) =>
                    v.location === mappingItem.location &&
                    schemaIds.includes(v.schemaId)
                )

              if (filteredValues.length > 0) {
                categoryItem[characteristicName] = filteredValues
              } else {
                categoryItem[characteristicName] = schemaIds.map(() => ({
                  dataValue: '-',
                }))
              }
            })
          if (Object.keys(categoryItem).length > 0)
            vehicleItem[wltpCategoryName] = categoryItem
        })
      return vehicleItem
    }
  )
}

const getValidCategoryMap = (
  vehicles: [number, EbrochureItemWithLocation[]][]
): Map<string, Set<string>> => {
  const validCategoryMap: Map<string, Set<string>> = new Map()
  vehicles.forEach(([vehicleId, brochureItems]) => {
    Object.entries(wltpMapping).forEach(([wltpCategoryName, mappingItem]) => {
      Object.entries(mappingItem.schemaIds).forEach(
        ([characteristicName, schemaIds]) => {
          const filteredValues = brochureItems.filter(
            (v) =>
              v.location === mappingItem.location &&
              schemaIds.includes(v.schemaId)
          )
          if (filteredValues.length > 0) {
            if (!validCategoryMap.has(wltpCategoryName)) {
              validCategoryMap.set(wltpCategoryName, new Set())
            }
            validCategoryMap.get(wltpCategoryName)!.add(characteristicName)
          }
        }
      )
    })
  })

  return validCategoryMap
}

export const getWltpTitlesForExcel = (
  wltpMapping: WltpVehicleItem,
  translations: AppTextTranslations
): any[] => {
  const rowTitles: any[] = []
  Object.keys(wltpMapping).forEach((wltpCategoryName) => {
    const mappingItem = wltpMapping[wltpCategoryName]
    rowTitles.push({
      value: translations[wltpCategoryName],
      styleName: 'itemTitle',
    })

    Object.keys(mappingItem).forEach((characteristicName) => {
      rowTitles.push({
        value: translations[characteristicName],
        styleName: 'itemValue',
      })
    })
  })

  return rowTitles
}

export const getWltpBrochureDataForExcel = (
  vehicleIndex: number,
  wltpVehicleItems: WltpVehicleItem[],
  translations: AppTextTranslations
): any[] => {
  const itemRows: any[] = []
  Object.keys(wltpVehicleItems[vehicleIndex]).forEach((wltpCategoryName) => {
    const mappingItem = wltpVehicleItems[vehicleIndex][wltpCategoryName]
    itemRows.push(getItemRow('', SpecsExcelStyleType.ItemTitle))

    Object.keys(mappingItem).forEach((characteristicName) => {
      const wltpValues: EbrochureItemWithLocation[] =
        mappingItem[characteristicName]

      itemRows.push(
        getItemRow(
          wltpValues
            .map((brochureItem: EbrochureItemWithLocation) =>
              brochureItem.dataValue !== '-'
                ? Number.parseFloat(brochureItem.dataValue).toFixed(1)
                : translations.JNT_notavailable
            )
            .join('/'),
          SpecsExcelStyleType.Item
        )
      )
    })
  })
  return itemRows
}

export const getBuildOptionListForExcel = (
  brochureVehicles: BrochureReturnObject[]
): BuiltOption[] => {
  const optionList: BuiltOption[] = []
  if (brochureVehicles.length > 0) {
    for (let i = 0; i < brochureVehicles.length; i++) {
      for (let j = 0; j < brochureVehicles[i].builtOptions.length; j++) {
        optionList.push(brochureVehicles[i].builtOptions[j])
      }
    }
  }
  return optionList
}

export const getVehicleName = (vehicle?: VehiclePhotoPath): string =>
  `${vehicle?.make} ${vehicle?.model} ${vehicle?.versionLevel}`

export const getSchemaDataValue = (
  vehicle: BrochureReturnObject,
  schemaId: number
): string => {
  const header = vehicle.vehicleHeaderInfo.vehicleHeaders.find(
    (v) => v.schemaId === schemaId
  )
  return header?.dataValue ?? ''
}

export const yyyymmdd = (): number => parseInt(moment().format('YYYYMMDD'))

const ColorOptionType = 'C'

export const getWltpOptionCodes = (builtOptions: BuiltOption[]): string[] =>
  builtOptions
    .map((x: BuiltOption) => ({ code: x.optionCode, optionType: x.optionType }))
    .concat(
      builtOptions.flatMap((z: BuiltOption) =>
        z.secondaryOptions.map((y: BuiltOption) => ({
          code: y.optionCode,
          optionType: y.optionType,
        }))
      )
    )
    .filter((op) => op.optionType !== ColorOptionType)
    .map((o) => o.code)

export const getWltpOptionTrimCodes = (
  builtOptions: BuiltOption[]
): [string, string] => {
  let exteriorColourCode = 'NA'
  let interiorTrimCode = 'NA'

  const updateCodes = (option: BuiltOption): void => {
    if (
      option.schemaIds?.includes(SchemaType.ExteriorTrimSchemaId) &&
      option.optionType === ColorOptionType
    ) {
      exteriorColourCode = option.optionCode
    } else if (
      option.schemaIds?.includes(SchemaType.InteriorTrimSchemaId) &&
      option.optionType === ColorOptionType
    ) {
      interiorTrimCode = option.optionCode
    }
  }

  builtOptions.forEach((builtOption) => {
    updateCodes(builtOption)
    builtOption.secondaryOptions?.forEach(updateCodes)
  })

  return [exteriorColourCode, interiorTrimCode]
}

export const getWltpRequestObject = (
  brochureResponse: BrochureResponse | undefined,
  languageId: number
): WltpRequest[] | undefined =>
  brochureResponse?.brochureVehicles.map(
    (brochureReturnObject: BrochureReturnObject) => {
      const [exteriorColourCode, interiorTrimCode] = getWltpOptionTrimCodes(
        brochureReturnObject.builtOptions
      )
      const wltpBody: LspBody = {
        jatoUid: parseInt(
          brochureReturnObject.vehicleHeaderInfo.vehicleId
            .toString()
            .substring(0, 7)
        ),
        jatoDataDate: parseInt(
          brochureReturnObject.vehicleHeaderInfo.vehicleId
            .toString()
            .substring(7)
        ),
        versionAvailability:
          brochureReturnObject.vehicleHeaderInfo.vehicleHeaders.find(
            (v: VehicleHeader) => v.schemaId === SchemaType.VersionAvailability
          )?.dataValue,
        date: yyyymmdd(),
        exteriorColourCode,
        interiorTrimCode,
        optionCodes: getWltpOptionCodes(brochureReturnObject.builtOptions),
      }

      return {
        vehicleId: brochureReturnObject.vehicleHeaderInfo.vehicleId,
        marketDatabase:
          brochureReturnObject.vehicleHeaderInfo.specsDatabaseName,
        defaultLanguageId: languageId,
        body: wltpBody,
      }
    }
  )

// common sorting function to ensure vehicles in the header and the body always have the same order
export const sortVehicles = <T>(
  vehicles: T[],
  getVehicleId: (vehicle: T) => number,
  benchmarkVehicleId: number
): T[] =>
  // benchmark vehicle always first, than sort by vehicleId
  vehicles.toSorted(
    (a, b) =>
      (getVehicleId(a) == benchmarkVehicleId
        ? Number.MIN_SAFE_INTEGER
        : getVehicleId(a)) -
      (getVehicleId(b) == benchmarkVehicleId
        ? Number.MIN_SAFE_INTEGER
        : getVehicleId(b))
  )
