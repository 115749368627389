import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsCarouselItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .newsCarouselHeader {
    padding: 24px 54px 0;
    display: flex;
    flex-direction: column;
  }

  .newsCarouselMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 26px 54px 16px;
    cursor: pointer;
  }

  .newsCarouselMain img {
    height: 220px;
    max-width: 350px;
  }

  .newsHeadline {
    font-size: 38px;
    color: #0a3673;
    font-weight: ${JATOTheme.fontWeights.medium};
    letter-spacing: -0.5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
    max-width: 350px;
  }

  .newsSubHeadline {
    font-size: 24px;
    color: #0a3673;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }

  .newsCarouselFooter {
    display: flex;
    background-color: ${JATOTheme.colors.lightGrey};
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 18px 18px;
    column-gap: 32px;
  }

  .newsCarouselFooterLink {
    font-size: 16px;
    font-weight: 500;
    color: ${JATOTheme.colors.black};
  }

  .newsCarouselFooterButton {
    text-transform: none;
    border-radius: 6px;
  }
`
