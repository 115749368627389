import { JATOIcon, JATOText, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { NewsAddNewAlertButton } from '../Buttons/NewsAlertButton/NewsAddNewAlertButton'
import { StyledNewsNoAlertFound } from './NewsNoAlertFound.styles'

export interface INewsNoAlertFoundProps {
  iconName: string
  text: string
}

export const NewsNoAlertFound: React.FC<INewsNoAlertFoundProps> = ({
  iconName,
  text,
}: INewsNoAlertFoundProps) => (
  <StyledNewsNoAlertFound>
    <div className="notFoundImage">
      <JATOIcon
        iconName={iconName}
        iconSize={90}
        iconFill={JATOTheme.colors.grey}
      />
    </div>
    <JATOText as="p" textAlign="center">
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </JATOText>
    <NewsAddNewAlertButton />
  </StyledNewsNoAlertFound>
)
