import { JATOSpinner } from '@jato/ui-component-library'
import { LogoVideosLoader } from 'components/TrainingVideos/TrainingVideos.styles'
import { TrainingVideoType } from 'models/Common/TrainingVideoType'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'
import React from 'react'
import { useQuery } from 'react-query'
import trainingVideosService from 'services/TrainingVideos/TrainingVideosService'
import { StyledNewsWelcomePopupVideo } from './NewsWelcomePopupVideo.styles'
import { defaultPlayerConfig } from 'config'
import { BitmovinPlayer } from 'bitmovin-player-react'

export const NewsWelcomePopupVideo: React.FC = () => {
  const { data: newsWelcomeVideo, isFetching } = useQuery<
    TrainingVideoResponse[]
  >(['newsWelcomeVideo'], async () => {
    const { data } = await trainingVideosService.getTrainingVideoList()
    const filtered = data.filter(
      (obj) =>
        obj.trainingVideoType === TrainingVideoType.NewsMonthlyPaymentsWelcome
    )
    return filtered
  })

  return (
    <StyledNewsWelcomePopupVideo>
      {isFetching ? (
        <LogoVideosLoader>
          <JATOSpinner spinnerSize={64} />
        </LogoVideosLoader>
      ) : (
        newsWelcomeVideo && (
          <BitmovinPlayer
            config={defaultPlayerConfig}
            source={{
              hls: newsWelcomeVideo[0].videoList[0].streamingEndPointAddress,
              poster: newsWelcomeVideo[0].videoList[0].posterAddress,
            }}
          />
        )
      )}
    </StyledNewsWelcomePopupVideo>
  )
}
