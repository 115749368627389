import { JATOTheme } from '@jato/ui-component-library'

/**
 * Use JATOTheme options as our base. Apart from this file, nothing in this
 * project should use JATOTheme directly and all theme variables should be
 * accessed via the Theme object below. This will allow us to add to and
 * override default JATOTheme values.
 */
const Theme = {
  borderWidths: JATOTheme.borderWidths,
  breakpoints: JATOTheme.breakpoints,
  colors: {
    ...JATOTheme.colors,
    /**
     * Insert additional colours here, for example...
     *
     * alertInfo: JATOTheme.colors.darkGrey
     * alertWarning: '#f96b0b'
     */
  },

  fonts: JATOTheme.fonts,
  fontSizes: JATOTheme.fontSizes,
  fontWeights: JATOTheme.fontWeights,
  lineHeights: JATOTheme.lineHeights,
  radii: JATOTheme.radii,
  shadows: JATOTheme.shadows,
  space: JATOTheme.space,
  zIndices: {
    ...JATOTheme.zIndices,
    header: 111,
  },
}

export default Theme
