import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesCountryGroupCreateEdit = styled.div`
  .jato-basic-card {
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.24),
      0px 1px 3px rgba(0, 0, 0, 0.12);
  }

  button {
    font-weight: 500;
  }

  .selectionRadioGroup {
    background-color: ${JATOTheme.colors.midOffGrey};
    padding: 8px 24px 0;
    margin: 0 -24px 12px;
  }

  .countriesContainer {
    height: calc(100vh - 360px);
    overflow: auto;
    margin-bottom: -24px;
  }
`
