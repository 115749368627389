import { JATOSelect } from '@jato/ui-component-library'
import { VolumesQuery } from 'models/Volumes/VolumesQuery'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledVolumesQueriesSelection } from './VolumesQueriesSelection.styles'

export interface IVolumesQueriesSelectionProps {
  title: string
  queries: VolumesQuery[]
}

export const VolumesQueriesSelection: React.FC<
  IVolumesQueriesSelectionProps
> = ({ title, queries }: IVolumesQueriesSelectionProps) => {
  const history = useHistory()
  const translations = useAppSelector(getTranslations)
  const queriesOptions = queries.map((item) => ({
    value: item.queryId.toString(),
    displayValue: item.queryShortDescription,
  }))

  return (
    <StyledVolumesQueriesSelection>
      <JATOSelect
        id="volumesQueriesSelect"
        width="100%"
        dataSrc={queriesOptions}
        placeholder={translations.JNT_Select}
        label={title}
        onChange={(queryId) =>
          history.push(`${RoutePaths.VolumesQuery}/${queryId}`)
        }
      />
    </StyledVolumesQueriesSelection>
  )
}
