import {
  SortDescriptorModel,
  SortDirection,
  Sorts,
} from '@syncfusion/ej2-grids'
import { VehicleOrderDirection } from 'models/VehicleSelection/VehicleOrderDirection'
import {
  mapToDisplayValueColumnName,
  mapToValueColumnName,
} from './displayValueMapper'

export const mapToSortDirection = (orderDirection: string): SortDirection => {
  switch (orderDirection) {
    case VehicleOrderDirection.Ascending:
      return 'Ascending'
    case VehicleOrderDirection.Descending:
      return 'Descending'
    default:
      return 'Ascending'
  }
}

export const mapToOrderDirection = (
  sortDirection?: string
): VehicleOrderDirection => {
  switch (sortDirection) {
    case 'ascending':
      return VehicleOrderDirection.Ascending
    case 'descending':
      return VehicleOrderDirection.Descending
    default:
      return VehicleOrderDirection.Ascending
  }
}

export const mapToColumns = (orderByField: string): SortDescriptorModel => {
  const columnDirection = orderByField.split(' ')
  return {
    field: mapToDisplayValueColumnName(columnDirection[0]),
    direction: mapToSortDirection(columnDirection[1]),
  }
}

export const mapSorted = (x: Sorts): string =>
  `${mapToValueColumnName(x.name)} ${mapToOrderDirection(x.direction)}`
