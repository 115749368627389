export enum InfoPopupType {
  VehicleFilters = 'VehicleFilters',
  Showroom = 'Showroom',
  MonthlyPaymentsFilters = 'MonthlyPaymentsFilters',
}

export type InfoPopupDetails = {
  title: string
  text: string
}
