import { IMappingObject } from 'helper/IMappingObject'
import { removeDuplicates } from 'helper/arrayHelper'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'

export enum NewsCategoryType {
  Unknown = 'Unknown',
  NewVehicles = 'NewVehicles',
  PricingAndSpecifications = 'PricingAndSpecifications',
  IndustryNews = 'IndustryNews',
  PromotionsAndIncentives = 'PromotionsAndIncentives',
  Volumes = 'Volumes',
  MonthlyPayments = 'MonthlyPayments',
  EditorsChoice = 'EditorsChoice',
}

export const selectableCategories = [
  NewsCategoryType.NewVehicles,
  NewsCategoryType.PricingAndSpecifications,
  NewsCategoryType.IndustryNews,
  NewsCategoryType.PromotionsAndIncentives,
  NewsCategoryType.Volumes,
  NewsCategoryType.MonthlyPayments,
]

export const getNewsCategoryTranslations = (
  t: AppTextTranslations
): IMappingObject => ({
  [NewsCategoryType.NewVehicles]: t.JNT_NEWS_NewVehicles,
  [NewsCategoryType.PricingAndSpecifications]: t.JNT_HD_SecPricingspec,
  [NewsCategoryType.IndustryNews]: t.JNT_ART_TopStory,
  [NewsCategoryType.PromotionsAndIncentives]: t.JNT_HD_SecPromotions,
  [NewsCategoryType.Volumes]: t.JNT_SUBJ_VSRE,
  [NewsCategoryType.MonthlyPayments]: t.JNT_Nav_MonthlyPayments,
  [NewsCategoryType.EditorsChoice]: t.JNT_Editors_Choice,
})

export const workflowSubjects = ['SUBJ_VSRE']

export const categoryToSubjectsMapping: IMappingObject<string[]> = {
  [NewsCategoryType.NewVehicles]: [
    'SUBJ_NEWV',
    'SUBJ_NEWM',
    'SUBJ_FACL',
    'SUBJ_SPED',
    'SUBJ_FMPL',
    'SUBJ_CONC',
  ],
  [NewsCategoryType.PricingAndSpecifications]: [
    'SUBJ_PRIC',
    'SUBJ_OPTS',
    'SUBJ_MODY',
    'SUBJ_SPEC',
  ],
  [NewsCategoryType.IndustryNews]: [
    'SUBJ_MKTG',
    'SUBJ_INEV',
    'SUBJ_CRES',
    'SUBJ_ECON',
    'SUBJ_LEGI',
  ],
  [NewsCategoryType.EditorsChoice]: ['SUBJ_EDIT'],
  [NewsCategoryType.PromotionsAndIncentives]: [
    'SUBJ_IDCR',
    'SUBJ_IPCR',
    'SUBJ_INCE',
    'SUBJ_CPRO',
    'SUBJ_IDCM',
    'SUBJ_IPCM',
  ],
  [NewsCategoryType.Volumes]: ['SUBJ_PMFC', 'SUBJ_VSRE'],
  [NewsCategoryType.MonthlyPayments]: ['SUBJ_MPBA'],
}

export const getCategoriesBySubjects = (
  subjects: string[]
): NewsCategoryType[] =>
  subjects
    .map(
      (subject) =>
        Object.values(NewsCategoryType).find(
          (category) => categoryToSubjectsMapping[category]?.includes(subject)
        ) ?? NewsCategoryType.Unknown
    )
    .filter(removeDuplicates)
