class SpecsExcelStyles {
  private getSaveId = (): any => ({
    name: 'saveId',
    width: 65,
    height: 20,
    fontFamily: 'Arial',
    fontsize: 12,
    backgroundColor: 'FF000000',
    fontColor: 'FFFFD100',
    bold: true,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'left',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getSaveIdValues = (): any => ({
    name: 'saveIdValues',
    width: 65,
    height: 20,
    fontFamily: 'Arial',
    fontsize: 12,
    backgroundColor: 'FFE2E2E2',
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getHeader = (): any => ({
    name: 'header',
    width: 65,
    height: 55,
    fontFamily: 'Arial',
    fontSize: 12,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })
  private getHeaderPrice = (): any => ({
    name: 'headerPrice',
    width: 65,
    height: 20,
    fontFamily: 'Arial',
    fontSize: 12,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
  })
  private getItemTitle = (): any => ({
    name: 'itemTitle',
    width: 45,
    height: 28.5,
    fontFamily: 'Arial',
    fontSize: 10.5,
    fontColor: 'ffffff',
    bold: true,
    backgroundColor: '8e8e8e',
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: '',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
  })

  private getOptionsRowItem = (): any => ({
    name: 'optionsRowItem',
    width: 65,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: '1DA1CE',
    bold: true,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'center',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getItemComparisonTitle = (): any => ({
    name: 'itemComparisonTitle',
    width: 45,
    height: 28.5,
    fontFamily: 'Arial',
    fontSize: 10.5,
    fontColor: 'ffffff',
    bold: true,
    backgroundColor: '383838',
    italic: false,
    alignment: {
      vertical: 'middle',
      horizontal: '',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
  })
  private getItem = (): any => ({
    name: 'item',
    width: 65,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'center',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getOptionsItem = (): any => ({
    name: 'optionsItem',
    width: 65,
    height: 48.5,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'left',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getOptionsItemBold = (): any => ({
    name: 'getOptionsItemBold',
    width: 65,
    height: 48.5,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'left',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })

  private getOptionsItemHighlight = (): any => ({
    name: 'optionsItemHighlight',
    width: 65,
    height: 48.5,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: '186ba0',
    bold: true,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'left',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })
  private getPrimaryOption = (): any => ({
    name: 'primaryOption',
    width: 45,
    fontFamily: 'Arial',
    fontSize: 10,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'left',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })
  private getSecondaryOption = (): any => ({
    name: 'secondaryOption',
    width: 45,
    fontFamily: 'Arial',
    fontSize: 9,
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'left',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })
  private getCategoryHeader = (): any => ({
    name: 'categoryHeader',
    width: 45,
    fontFamily: 'Arial',
    fontSize: 12,
    fontColor: 'FF000000',
    bold: true,
    italic: false,
    alignment: {
      vertical: '',
      horizontal: 'center',
      wrapText: false,
      indent: 0,
      textRotation: 0,
    },
  })
  private getItemValue = (): any => ({
    name: 'itemValue',
    width: 45,
    fontFamily: 'Arial',
    fontSize: 9,
    fontColor: 'FF000000',
    bold: false,
    italic: false,
    backgroundColor: 'e6e6e6',
    alignment: {
      vertical: '',
      horizontal: '',
      wrapText: true,
      indent: 0,
      textRotation: 0,
    },
  })
  public styles = (): any => [
    this.getSaveId(),
    this.getSaveIdValues(),
    this.getHeader(),
    this.getHeaderPrice(),
    this.getItemTitle(),
    this.getItemComparisonTitle(),
    this.getItem(),
    this.getOptionsItem(),
    this.getOptionsItemBold(),
    this.getOptionsItemHighlight(),
    this.getPrimaryOption(),
    this.getSecondaryOption(),
    this.getCategoryHeader(),
    this.getItemValue(),
    this.getOptionsRowItem(),
  ]
}

const specsExcelStyles = new SpecsExcelStyles()
export default specsExcelStyles
