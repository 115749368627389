import React from 'react'

interface IGtmProps {
  id?: string
  label?: string
  parentLabel?: string
  value?: string
  selected?: boolean
  /** skip capture of click event on element */
  skip?: boolean
  children: React.ReactNode
}

export const GtmWrapper: React.FC<IGtmProps> = ({
  id,
  label,
  parentLabel,
  value,
  selected,
  skip,
  children,
}: IGtmProps) => (
  <span
    data-gtm-id={id}
    data-gtm-label={label}
    data-gtm-parent-label={parentLabel}
    data-gtm-value={value}
    data-gtm-selected={selected}
    data-gtm-skip={skip}
  >
    {children}
  </span>
)
