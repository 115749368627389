import {
  JATOBasicCard,
  JATOButton,
  JATOButtonGroup,
  JATOGroup,
  JATOHeading,
  JATOIcon,
  JATOTheme,
  JATOTooltip,
} from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import Loader from 'components/Loader'
import {
  getActiveStep,
  getCountriesDisplayList,
  getDatasetFields,
  getPathForStep,
} from 'helper/volumesHelper'
import {
  useGetCountries,
  useGetCountryGroups,
  useGetVehicleVolumeGroups,
  useGetVehicleVolumeTypes,
} from 'hooks/volumes'
import { CountryGroup } from 'models/Volumes/CountryGroup'
import { UserValidData } from 'models/Volumes/UserValidData'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledVolumesCountryStep } from './VolumesCountryStep.styles'

enum CountrySelectionOption {
  Countries,
  CountryGroups,
}

export const VolumesCountryStep: React.FC = () => {
  const history = useHistory()
  const queryState = useAppSelector(getVolumesQueryState)

  // if step is not ready, redirect to last ready step
  if (!queryState.databaseType) {
    const step = getActiveStep(queryState)
    history.push(getPathForStep(step))
  }

  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)

  const { data: countries, isFetching: countriesFetching } = useGetCountries(
    queryState.databaseType
  )

  const { data: countryGroups, isFetching: groupsFetching } =
    useGetCountryGroups(queryState.databaseType)

  const { data: datasetGroups, isFetching: datasetGroupsFetching } =
    useGetVehicleVolumeGroups()

  const { data: datasets, isFetching: datasetsFetching } =
    useGetVehicleVolumeTypes()

  const isFetching =
    countriesFetching ||
    groupsFetching ||
    datasetGroupsFetching ||
    datasetsFetching

  const countrySelectionOptions = [
    {
      value: CountrySelectionOption.Countries,
      label: translations.JNT_Volumes_QD_Countries,
      visible: true,
      title: translations.JNT_Volumes_CountryHoverText,
    },
    {
      value: CountrySelectionOption.CountryGroups,
      label: translations.JNT_Volumes_QG_UsingCTYGRP,
      visible: !!countryGroups?.length,
      title: translations.JNT_Volumes_CountryGrpHoverText,
    },
  ]

  const [countrySelectionOption, setCountrySelectionOption] = useState(
    countrySelectionOptions[0].value
  )
  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    queryState.countries ?? []
  )
  const [selectedGroup, setSelectedGroup] = useState<string>()

  const onSelectionOptionChange = (value: CountrySelectionOption): void => {
    setCountrySelectionOption(value)
    setSelectedGroup(undefined)
  }

  const onSelectedCountriesChange = (countryCode: string): void => {
    const newValue = selectedCountries.includes(countryCode)
      ? selectedCountries.filter((c) => c != countryCode)
      : [...selectedCountries, countryCode]

    setSelectedCountries(newValue)
  }

  const onSelectedGroupChange = (countryGroup: CountryGroup): void => {
    const newValue = countryGroup.countries

    setSelectedCountries(newValue)
    setSelectedGroup(countryGroup.id)
  }

  const onSelectAllClick = (): void => {
    const allCountries = countries?.map((c) => c.countryCode) ?? []
    setSelectedCountries(allCountries)
  }

  const onClearChoicesClick = (): void => setSelectedCountries([])

  const onNext = (): void => history.push(RoutePaths.VolumesQueryDefinition)

  const onNextClick = (): void => {
    const dataSetIdsDefault =
      datasetGroups
        ?.find((g) => g.productGroup === queryState.databaseType)
        ?.dataSetItems.filter((d) => selectedCountries.includes(d.country))
        .map((d) => d.dataSetItemId) ?? []

    const dataSetIds =
      datasets
        ?.filter(
          (g) =>
            g.pgProductGroup === queryState.databaseType &&
            selectedCountries.includes(g.pCtyId) &&
            dataSetIdsDefault.includes(g.dstId)
        )
        .map((d: UserValidData) => d.dstId) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        countries: selectedCountries,
        ...getDatasetFields(dataSetIds, datasets),
      })
    )
    onNext()
  }

  return (
    <StyledVolumesCountryStep>
      {isFetching ? (
        <Loader style={{ minHeight: 'calc(100vh - 300px)' }} />
      ) : (
        <JATOBasicCard
          footer={() => (
            <JATOButtonGroup justifyContent="flex-end">
              {countrySelectionOption == CountrySelectionOption.Countries && (
                <>
                  <JATOButton
                    id="volumesCountryStepSelectAllButton"
                    variant="secondary"
                    onClick={onSelectAllClick}
                  >
                    {translations.JNT_Volumes_QG_SetQuerySelections}
                  </JATOButton>
                  <JATOButton
                    id="volumesCountryStepClearChoicesButton"
                    variant="secondary"
                    onClick={onClearChoicesClick}
                  >
                    {translations.JNT_Volumes_QG_ClearSelections}
                  </JATOButton>
                </>
              )}
              <JATOButton
                id="volumesCountryStepNextButton"
                variant="primary"
                disabled={selectedCountries.length == 0}
                onClick={onNextClick}
              >
                {translations.JNT_Volumes_QG_Next}
                <JATOIcon
                  iconName="baseline_arrow_forward"
                  iconSize={18}
                  style={{ marginLeft: JATOTheme.space[2] }}
                />
              </JATOButton>
              {countrySelectionOption ==
                CountrySelectionOption.CountryGroups && (
                <JATOButton
                  id="volumesCountryStepEditCountryGroupButton"
                  variant="primary"
                  disabled={selectedCountries.length == 0}
                  onClick={() =>
                    history.push(RoutePaths.VolumesMyCountryGrouping)
                  }
                >
                  {translations.JNT_Volumes_Edit}
                </JATOButton>
              )}
            </JATOButtonGroup>
          )}
        >
          <div className="countriesHeader">
            <JATOHeading as="h3" fontWeight="medium">
              {translations.JNT_Volumes_QM_CountrySelectMessage}
            </JATOHeading>

            <JATOTooltip
              title={translations.JNT_Volumes_QIO_ChooseMethod}
              arrow={true}
              placement="left-start"
              showOnlyOnOverflow={false}
            >
              <JATOIcon iconName="baseline_info" />
            </JATOTooltip>
          </div>
          <JATOGroup flexDirection="row" className="countriesRadioGroup">
            {countrySelectionOptions
              .filter((o) => o.visible)
              .map((o) => (
                <RadioWithGtm
                  key={o.value}
                  id="volumesCountryStepSelectionOption"
                  value={CountrySelectionOption[o.value]}
                  label={o.title}
                  checked={countrySelectionOption == o.value}
                  onChange={() => onSelectionOptionChange(o.value)}
                >
                  {o.label}
                </RadioWithGtm>
              ))}
          </JATOGroup>
          {countries && (
            <JATOGroup flexDirection="column" className="countriesContainer">
              {countrySelectionOption == CountrySelectionOption.Countries
                ? countries.map((c) => (
                    <CheckboxWithGtm
                      key={c.countryCode}
                      value={c.countryCode}
                      id="volumesCountryStepCountryCheckbox"
                      label={c.countryName}
                      checked={selectedCountries.includes(c.countryCode)}
                      onChange={() => onSelectedCountriesChange(c.countryCode)}
                    >
                      {c.countryName}
                    </CheckboxWithGtm>
                  ))
                : countryGroups?.map((c) => (
                    <JATOTooltip
                      title={getCountriesDisplayList(c.countries, countries)}
                      maxWidth={500}
                      textAlign="left"
                      placement="top-start"
                      showOnlyOnOverflow={false}
                      key={c.id}
                    >
                      <RadioWithGtm
                        id="volumesCountryStepCountryGroupOption"
                        value={c.countries.join()}
                        label={getCountriesDisplayList(c.countries, countries)}
                        checked={selectedGroup == c.id}
                        onChange={() => onSelectedGroupChange(c)}
                      >
                        {c.name}
                      </RadioWithGtm>
                    </JATOTooltip>
                  ))}
            </JATOGroup>
          )}
        </JATOBasicCard>
      )}
    </StyledVolumesCountryStep>
  )
}
