import {
  JATOButton,
  JATOSearchInput
} from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { gtmLogNewsSimpleSearch } from 'helper/gtm'
import {
  defaultToDate,
  NewsFilterName,
  twelveMonthFromDateOption,
} from 'helper/newsHelper'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { SearchState, setNewsSearchState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import NewsAdvancedSearchLink from '../NewsAdvancedSearch'
import { StyledNewsSearch } from './NewsSearch.styles'

export const NewsSearch: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)

  const [searchText, setSearchText] = useState('')
  const [exactMatch, setExactMatch] = useState(false)

  const onChange = (): void => {
    if (searchText) {
      const searchState: SearchState = {
        searchFields: {
          [NewsFilterName.SearchText]: [searchText],
          [NewsFilterName.ReleaseDate]: [
            JSON.stringify([twelveMonthFromDateOption(), defaultToDate()]),
          ],
        },
        isExactMatch: exactMatch,
        orderBy: searchText && !exactMatch ? [] : ['releaseDate desc'],
        currentPage: 1,
      }

      dispatch(setNewsSearchState(searchState))

      gtmLogNewsSimpleSearch(searchState)

      history.push(RoutePaths.NewsSearchResults)
    }
  }

  return (
    <StyledNewsSearch>
      <JATOSearchInput
        id="" // displayed as a tooltip on input, intentionally left blank
        hasIcon
        width={457}
        onChange={setSearchText}
        placeholder={translations.JNT_Type_here_to_search}
        onKeyDown={(e) => (e.key === 'Enter' ? onChange() : null)}
        value={searchText}
      />
      <CheckboxWithGtm
        id="newsSimpleSearchExactMatchCheckbox"
        label={translations.JNT_Exact_match}
        checked={exactMatch}
        onChange={() => setExactMatch(!exactMatch)}
      >
        {translations.JNT_Exact_match}
      </CheckboxWithGtm>
      <JATOButton
        size="large"
        id="newsSimpleSearchButton"
        type="button"
        variant="primary"
        onClick={onChange}
      >
        {translations.JNT_SRCH_Search}
      </JATOButton>
      <NewsAdvancedSearchLink />
    </StyledNewsSearch>
  )
}
