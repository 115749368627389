import { ISpecsUserSettings } from '../SpecsUserSettings'
import { AdvanceFilterInfoObject } from './AdvanceFiltersResponse'

export interface AdvanceFiltersRequest {
  companyId?: number
  specsDatabase?: any
  languageId?: number
  defaultLanguage?: number
  activeFilters?: IAdvancedFilterModel[]
  optionsFilterType?: AdvancedOptionFilterType
  selectedFilterItems?: AdvanceFilterInfoObject[]
  isFilterVehicle?: boolean
  userSettings?: ISpecsUserSettings
}

export enum AdvancedFilterType {
  List = 0,
  Range = 1,
  FreeSearch = 2,
}

export enum AdvancedOptionFilterType {
  Std = 0,
  StdAndOptions = 1,
  OptionsOnly = 2,
}

export interface IAdvancedFilterModel {
  filterName: string
  filterDisplayValue: string[]
  schemaId: number | undefined
  filteringType: AdvancedFilterType | undefined
  dataValue: string[]
  optionFilterType: number
  isElasticFilter?: boolean
}

export interface IOptionFilterType {
  value: AdvancedOptionFilterType
  text: string
}
