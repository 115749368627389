import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { NewsSearchAlertRemoveButton } from '../NewsAlertButton/NewsSearchAlertRemoveButton'
import { StyledNewsSavedSearchActions } from './NewsSavedSearchActions.styles'
import { NewsSavedSearchEditButton } from './NewsSavedSearchEditButton'

export interface INewsSavedSearchActionsProps {
  searchRequest: SearchIndexRequest
}

export const NewsSavedSearchActions: React.FC<INewsSavedSearchActionsProps> = ({
  searchRequest,
}) => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledNewsSavedSearchActions>
      <NewsSavedSearchEditButton searchRequest={searchRequest} />
      <NewsSearchAlertRemoveButton
        searchAlert={searchRequest}
        removeMessage={translations.JNT_RemoveSearchMessage}
      />
    </StyledNewsSavedSearchActions>
  )
}
