import styled from 'styled-components'

export const StyledVolumesNewsArticleLink = styled.div`
  .newsArticleLink {
    text-transform: none;
    font-size: 14px;
    white-space: normal;
    line-height: 1.4;
  }
`
export const StyledVolumesNewsArticle = styled.div`
  .jato-breadcrumbs,
  .newsCategoryAndArticleActions,
  .newsMetadataBlock,
  .newsRelatedArticles {
    display: none !important;
  }
`
