import {
  defaultMissingPhotoUrl,
  knowledgeArticleSearchUrl,
  mpKnowledgeArticleSearchUrl,
  newsKnowledgeArticleSearchUrl,
} from 'config'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import { NewsTranslationsText } from 'models/News/ArticleExport/NewsArticleExport'
import {
  NewsCountry,
  SearchIndexRequest,
} from 'models/News/Homepage/NewsArticleRequest'
import { INewsArticle } from 'models/News/INewsArticle'
import {
  categoryToSubjectsMapping,
  getCategoriesBySubjects,
} from 'models/News/NewsCategoryType'
import { NewsMakeModelsResponse } from 'models/News/NewsMakeModelsResponse'
import { ArticleDTO } from 'models/News/Search/ArticleDTO'

import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import moment from 'moment'
import { IMappingObject } from './IMappingObject'
import { firstOrDefault } from './arrayHelper'

export const newsInternationalCode = 'CTY_INT'
export const newsInternationalVolumesCode = 'CTY_INV'
export const englishLanguageId = 19

// Hiding 'VHT_BOTH' value because it's description ('All') is confusing
// Hiding 'VHT_Ameriv' based on comment from Philippe -
// "have tested it myself and it works fine with the exception of 'American Truck' - it seems to filter on American market vehicles
// which include Pick Up Trucks, SUVs and the like.  I feel that this category is an outlier to the general purpose of News."
export const hiddenVehicleTypes = ['VHT_BOTH', 'VHT_Ameriv']

export const getNewsAllCountriesItem = (
  translations: AppTextTranslations
): NewsCountry => ({
  code: newsInternationalCode,
  countryAbbreviation: newsInternationalCode.slice(4),
  name: translations.JNT_ART_AllCountry,
})

export const getAllCountries = (
  countries: NewsCountry[],
  translations: AppTextTranslations
): NewsCountry[] => [
  ...countries,
  {
    code: newsInternationalCode,
    countryAbbreviation: newsInternationalCode.slice(4),
    name: translations.JNT_International,
  },
  {
    code: newsInternationalVolumesCode,
    countryAbbreviation: newsInternationalVolumesCode.slice(4),
    name: translations.JNT_International_Volumes,
  },
]

export const getCountryByCode = (
  countryCode: string,
  countries: NewsCountry[],
  translations: AppTextTranslations
): NewsCountry =>
  getAllCountries(countries, translations).find(
    (c) => c.code === countryCode
  ) ?? {
    code: countryCode,
    countryAbbreviation: '',
    name: countryCode,
  }

export const getMainImageUrl = (
  imageList: string[],
  mainImageUrl: string
): string => {
  const imageUrl =
    imageList && imageList.length > 0
      ? imageList[0]
      : mainImageUrl ?? defaultMissingPhotoUrl
  return imageUrl
}

export const getMappedNewsTranslationsList = (
  translations: AppTextTranslations
): NewsTranslationsText[] => {
  const mappedList = Object.keys(translations).map((transKey: string) => ({
    uIResourceTag: transKey,
    uIResourceText: translations[transKey],
  }))

  return mappedList
}

export enum ArticleContentType {
  Image = 5,
  PriceTable = 20,
}

export const articlesMaxResults = 250
export const carouselArticlesNumber = 5
export const carouselArticleSlideIntervalInMs = 10000
export const homePageArticlesNumber = 20
export const defaultCardsNumber = 12
export const defaultArticlesInGridNumber = 20

const defaultNewsLoadInterval = { months: 3 } // duration in moment.js format
const sixMonthNewsLoadInterval = { months: 6 }
const twelveMonthNewsLoadInterval = { months: 12 }

export const threeMonthFromDateOption = (): Date =>
  moment().subtract(defaultNewsLoadInterval).startOf('day').toDate()
export const defaultToDate = (): Date => moment().endOf('day').toDate()

export const sixMonthFromDateOption = (): Date =>
  moment().subtract(sixMonthNewsLoadInterval).startOf('day').toDate()

export const twelveMonthFromDateOption = (): Date =>
  moment().subtract(twelveMonthNewsLoadInterval).startOf('day').toDate()

export const getArticles = (articles: NewsArticle[]): INewsArticle[] =>
  articles.map((article) => ({
    ...article,
    categories: getCategoriesBySubjects([article.subjectCode ?? '']),
    mainImageUrl: getMainImageUrl(article.imageLibrary, article.mainImageUrl),
    makesList: article.vehicle?.make,
    modelsList: article.vehicle?.model,
  }))

export const toNewsArticle = (dto: ArticleDTO): INewsArticle => ({
  categories: getCategoriesBySubjects(dto.subjects),
  mainImageUrl: getMainImageUrl(dto.imageList, dto.mainImageUrl),
  id: dto.articleId,
  languageId: dto.languageId,
  headline: dto.headline,
  synopsis: dto.synopsis,
  contentParts: [],
  author: dto.researcherName,
  releaseDate: moment(dto.releaseDate).format('DD/MM/YYYY'),
  imageLibrary: dto.imageList,
  countryCode: dto.vehicleModels.country,
  vehicle: {
    make: firstOrDefault(dto.vehicleModels.makes, ''),
    model: firstOrDefault(dto.vehicleModels.models, ''),
  },
  isBookMarked: dto.isBookmarked,
  isShared: dto.isShared,
  makesList: dto.vehicleModels.makes?.join(', '),
  modelsList: dto.vehicleModels.models?.join(', '),
})

export enum ArticleExportType {
  Pdf = 'pdf',
  Word = 'word',
  Print = 'print',
}

export enum NewsFilterName {
  Category = 'category',
  ReleaseDate = 'releaseDate',
  Make = 'makes',
  Model = 'models',
  SearchText = 'searchText',
  Country = 'country',
  Subjects = 'subjects',
  Group = 'group',
}

export enum NewsAlertSubscriptionType {
  None = -1,
  UponPublication = 0,
  Daily = 1,
  Weekly = 2,
}

export enum SiteRootType {
  MonthlyPayments = 'monthlypayments',
  Specifications = 'specifications',
  News = 'news',
  Home = 'login',
  MyPage = 'myPage',
}

export enum VehicleType {
  Cars = 'VHT_CAR',
  Commercial = 'VHT_COM',
}

export const getAlertsSubscriptionTypeName = (
  t: AppTextTranslations
): Map<NewsAlertSubscriptionType, string> =>
  new Map([
    [NewsAlertSubscriptionType.UponPublication, t.JNT_COM_UPON],
    [NewsAlertSubscriptionType.Daily, t.JNT_COM_DAILY],
    [NewsAlertSubscriptionType.Weekly, t.JNT_COM_WEEKLY],
  ])

export const getDateRangeFilterValue = (filterValues: string[]): Date[] => {
  const selectedValue = firstOrDefault(filterValues, '')
  return (
    (selectedValue && (JSON.parse(selectedValue) as Date[])) || [
      threeMonthFromDateOption(),
      defaultToDate(),
    ]
  )
}

export const getDateRangeAlertValue = (dateRangeAlert: string): Date[] =>
  (dateRangeAlert && (JSON.parse(dateRangeAlert) as Date[])) || [
    threeMonthFromDateOption(),
    defaultToDate(),
  ]

export interface DropdownOption {
  value: string
  displayValue: string
  group?: string
}

export const getMakesValueList = (
  makeModels: NewsMakeModelsResponse | undefined
): DropdownOption[] => {
  if (!makeModels) return []
  const makeList = makeModels.makeModelGroups.map((group) => ({
    value: group.make.key,
    displayValue: group.make.value,
  }))

  return makeList
}

export const getModelValueListForSelectedMake = (
  makeModels: NewsMakeModelsResponse | undefined,
  selectedMakes: string[]
): DropdownOption[] => {
  if (!makeModels) return []

  const modelsList: { value: string; displayValue: string }[] = []

  selectedMakes.forEach((selectedMake) => {
    const group = makeModels.makeModelGroups.find(
      (group) => group.make.key === selectedMake
    )

    const models = group?.models.map((model) => ({
      value: model.key,
      displayValue: model.value,
      group: `${group.make.value}  `,
    }))

    if (models) modelsList.push(...models)
  })

  return modelsList
}

export const getModelsFromSelectedAlerts = (
  modelKeys: string[],
  selectedMakes: string[],
  makeModels: NewsMakeModelsResponse | undefined
): string[] => {
  if (!makeModels) return []

  // Filter the makeModelGroups based on selectedMakes
  const selectedMakeModelGroups = makeModels.makeModelGroups.filter((group) =>
    selectedMakes.includes(group.make.key)
  )

  // Extract models from the selected groups
  let models: string[] = []
  for (const group of selectedMakeModelGroups) {
    models = models.concat(
      group.models
        .filter((model) => modelKeys.includes(model.key))
        .map((m) => m.key)
    )
  }

  return models
}

export const getPageTitle = (t: AppTextTranslations): IMappingObject => ({
  [SiteRootType.MonthlyPayments]: t.JNT_MPPageTitle,
  [SiteRootType.Specifications]: t.JNT_SpecsPageTitle,
  [SiteRootType.News]: t.JNT_NewsPageTitle,
})

export const getSubjects = (subjectCategoryCode: string): string[] => {
  if (
    subjectCategoryCode === null ||
    subjectCategoryCode === undefined ||
    subjectCategoryCode === ''
  )
    return []

  const categories = subjectCategoryCode.split(',')

  return categories.flatMap((category) => categoryToSubjectsMapping[category])
}

export const getDefaultNewsAlertOrSearch = (): SearchIndexRequest => {
  const request: SearchIndexRequest = {
    id: '',
    searchQuery: '',
    originalSearchQuery: '',
    countryList: [],
    subjectList: [],
    subjectCategoryCode: '',
    vehicleTypeList: [],
    makeList: [],
    modelList: [],
    bodyTypeList: [],
    fuelTypeList: [],
    emailFormat: null,
    isAttachment: null,
    status: true, // This field and isSubscribed field has to be in sync as it is being used to get valid subscriptions for customer
    isExactSearch: false,
    suspendedDate: null,
    subscriptionType: NewsAlertSubscriptionType.UponPublication,
    subscriptionId: null,
    subscriptionMakeModelList: [],
    subjectCountryList: [],
    queryId: null,
    description: '',
    startDate: new Date().toISOString(),
    loadCount: 0,
    isSubscribed: true,
    isShared: false,
    languageId: 19,
    isEpiServerCall: false,
    currentPage: 0,
    isMexicanIncentiveCall: false,
    numOfArticles: 0,
    sortbyfield: '',
  }

  return request
}

export const getColumnWidth = (widthPercentage: number): number => {
  const width = (window.innerWidth * widthPercentage) / 100
  return width
}

export const getMakeOrModelDisplayValues = (
  makeOrModelList: string[],
  makeOrModelAllValues: string
): string => {
  const makesOrModelsName =
    makeOrModelList.length > 0
      ? makeOrModelList.map((f) => f).join(',')
      : makeOrModelAllValues

  return makesOrModelsName
}

export const getCountryNamesFromCodes = (
  countryCodes: string[],
  countries: NewsCountry[],
  translations: AppTextTranslations
): string =>
  countryCodes
    .map((code) => getCountryByCode(code, countries, translations).name)
    .join(', ') || translations.JNT_ART_AllCountry

export const getVehicleTypeDescription = (
  t: AppTextTranslations
): IMappingObject => ({
  [VehicleType.Cars]: t.JNT_NEWS_VHT_CAR,
  [VehicleType.Commercial]: t.JNT_NEWS_VHT_COM,
})

export const getKnowledgeArticleSearchUrl = (): IMappingObject => ({
  [SiteRootType.MonthlyPayments]: mpKnowledgeArticleSearchUrl,
  [SiteRootType.Specifications]: knowledgeArticleSearchUrl,
  [SiteRootType.News]: newsKnowledgeArticleSearchUrl,
})
