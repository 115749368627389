import Loader from 'components/Loader'
import { isNullOrEmpty } from 'helper/arrayHelper'
import { useLoadAllQueries } from 'hooks/volumes'
import { VolumesUserState } from 'models/Login/VolumesLogin/VolumesUserState'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  getVolumesUserState,
} from 'redux/volumes/volumesSlice'
import VolumesQueriesSelection from '../VolumesQueriesSelection'
import { StyledVolumesQueriesContainer } from './VolumesQueriesContainer.styles'

export const VolumesQueriesContainer: React.FC = () => {
  const userData: VolumesUserState = useAppSelector(getVolumesUserState)
  const translations = useAppSelector(getTranslations)

  const { databaseType } = useAppSelector(getVolumesQueryState)
  const { data: queries, isFetching } = useLoadAllQueries(databaseType)

  const myQueries = queries?.filter((x) => x.qryOwnerId == userData.userId)
  const groupQueries = queries?.filter(
    (x) => x.qryShared && x.qryOwnerId !== userData.userId
  )

  return isFetching ? (
    <Loader style={{ minHeight: '100%' }} />
  ) : (
    <StyledVolumesQueriesContainer>
      {!isNullOrEmpty(myQueries) && (
        <VolumesQueriesSelection
          title={translations.JNT_Volumes_MJN_tabMyQuery}
          queries={myQueries!}
        />
      )}

      {!isNullOrEmpty(groupQueries) && (
        <VolumesQueriesSelection
          title={translations.JNT_Volumes_QRYIO_GroupQry}
          queries={groupQueries!}
        />
      )}
    </StyledVolumesQueriesContainer>
  )
}
