import styled from 'styled-components'

export const StyledPagination = styled.div`
  max-width: 500px;
  margin: auto;

  button {
    font-size: 14px;
  }
`
