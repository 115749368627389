import { AxiosResponse } from 'axios'
import { MpMetadataResponse } from 'models/VehicleSelection/MpMetadata'
import { MpVehiclesResponse } from 'models/VehicleSelection/MpVehiclesResponse'
import { IMpVehiclesSearchRequest } from 'models/VehicleSelection/MpVehiclesSearchRequest'
import { mpBaseApi } from 'services/api'
class MonthlyPaymentsReportService {
  public getMpReportMetaData(): Promise<MpMetadataResponse> {
    return mpBaseApi
      .get<any, AxiosResponse<MpMetadataResponse>>(`/api/Report/getreportmetadata`)
      .then((resp) => resp.data)
  }

  public getMpVehicleData(
    mpVehicleSearchRequest: IMpVehiclesSearchRequest
  ): Promise<MpVehiclesResponse> {
    return mpBaseApi
      .post<IMpVehiclesSearchRequest, AxiosResponse<MpVehiclesResponse>>(
        `/api/Report/getmpvehicledata`,
        mpVehicleSearchRequest
      )
      .then((resp) => resp.data)
  }
}

const monthlyPaymentsReportService = new MonthlyPaymentsReportService()
export default monthlyPaymentsReportService
