import { JATOSelect2 } from '@jato/ui-component-library'
import { DropdownOption } from 'helper/newsHelper'
import React, { useEffect } from 'react'
import { StyledNewsMultiSelect } from './NewsMultiSelect.styles'

interface INewsMultiSelectProps {
  id: string
  label: string
  onChange: (values: string) => void
  dropdownOptions: DropdownOption[]
  placeholder: string
  disabled?: boolean
  value: string
}

export const NewsMultiSelect: React.FC<INewsMultiSelectProps> = ({
  id,
  label,
  onChange,
  dropdownOptions,
  placeholder,
  disabled,
  value,
}: INewsMultiSelectProps) => {
  useEffect(() => {
    const filteredValues = value
      .split(',')
      .filter((v) => dropdownOptions.some((o) => o.value === v))
      .join(',')

    if (filteredValues !== value) {
      onChange(filteredValues)
    }
  }, [dropdownOptions])

  const onChangeInternal = (value: string): void => {
    const values = value ? value.split(',') : []

    // if all values from a group are selected, values will include group name instead of it's actual values, i.e. 'ALFA ROMEO:GIULIA,AUDI'
    const selectedOptions = dropdownOptions?.filter(
      (option) =>
        (option.group && values.includes(option.group)) ||
        values.includes(option.value)
    )

    const ungroupedValues = selectedOptions.map((o) => o.value).join(',')

    onChange(ungroupedValues)
  }

  return (
    <StyledNewsMultiSelect>
      <JATOSelect2
        id={id}
        label={label}
        dataSrc={dropdownOptions}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChangeInternal}
        width={320}
        ignoreCase={true}
        displayOnlyUnselectedOptions={false}
        isMultiSelect={true}
        clearAfterSelect={true}
        displayInlineValues={true}
        setAutoFocus={false}
        displaySelectAll={true}
        displayDeselectAll={true}
      />
    </StyledNewsMultiSelect>
  )
}
