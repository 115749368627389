import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsSearchOrderButtonSwitch } from './NewsSearchOrderButtonSwitch.styles'

export interface INewsSearchOrderButtonSwitchProps {
  checked?: boolean
  onChange: (checked: string[]) => void
}

export const NewsSearchOrderButtonSwitch: React.FC<
  INewsSearchOrderButtonSwitchProps
> = ({ checked, onChange }) => {
  const translations = useAppSelector(getTranslations)

  return (
    <StyledNewsSearchOrderButtonSwitch>
      <div className="e-btn-group">
        <input
          type="radio"
          id="newsSearchOrderMostRelevantButton"
          name="align"
          checked={checked}
          onChange={() => onChange([])}
        />
        <label className="e-btn" htmlFor="newsSearchOrderMostRelevantButton">
          {translations.JNT_Most_relevant}
        </label>
        <input
          type="radio"
          id="newsSearchOrderMostRecentButton"
          name="align"
          checked={!checked}
          onChange={() => onChange(['releaseDate desc'])}
        />
        <label className="e-btn" htmlFor="newsSearchOrderMostRecentButton">
          {translations.JNT_Most_recent}
        </label>
      </div>
    </StyledNewsSearchOrderButtonSwitch>
  )
}
