import { JATOHeading, JATOSpinner } from '@jato/ui-component-library'
import { BitmovinPlayer } from 'bitmovin-player-react'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import { LogoVideosLoader } from 'components/TrainingVideos/TrainingVideos.styles'
import { defaultPlayerConfig } from 'config'
import { TrainingVideoType } from 'models/Common/TrainingVideoType'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'
import React from 'react'
import { useQuery } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import trainingVideosService from 'services/TrainingVideos/TrainingVideosService'
import { StyledVolumesVideoTutorial } from './VolumesVideoTutorial.styles'

export const VolumesVideoTutorial: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const { isOesCustomer } = useAppSelector(getVolumesUserState)

  const { data: volumesVideo, isFetching } = useQuery<TrainingVideoResponse[]>(
    ['volumesVideo', isOesCustomer],
    async () => {
      const { data } = await trainingVideosService.getTrainingVideoList()
      const trainingVideoType: any = isOesCustomer
        ? TrainingVideoType.OesVolumes
        : TrainingVideoType.Volumes
      const filtered = data.filter(
        (obj) => obj.trainingVideoType === trainingVideoType
      )

      return filtered
    }
  )

  const bitmovinPlayer = React.useMemo(
    () =>
      volumesVideo && (
        <BitmovinPlayer
          config={defaultPlayerConfig}
          source={{
            hls: volumesVideo[0].videoList[0].streamingEndPointAddress,
            poster: volumesVideo[0].videoList[0].posterAddress,
          }}
        />
      ),
    [volumesVideo]
  )

  return (
    <StyledVolumesVideoTutorial>
      <JATOHeading as="h3" fontWeight="bold">
        {translations.JNT_Volumes_Video_Tutorial}
      </JATOHeading>
      {isFetching ? (
        <LogoVideosLoader>
          <JATOSpinner spinnerSize={64} />
        </LogoVideosLoader>
      ) : (
        <GtmWrapper id="volumesVideoTutorial">{bitmovinPlayer}</GtmWrapper>
      )}
    </StyledVolumesVideoTutorial>
  )
}
