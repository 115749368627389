import {
  JATOButton,
  JATOButtonGroup,
  JATOHeading,
  JATOIcon,
  JATOModal,
  JATOTheme,
} from '@jato/ui-component-library'
import { useUpdateUserSettings } from 'hooks/specsUserSettings'
import { ISpecsUserSettings } from 'models/Specifications/SpecsUserSettings'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getSpecsUserState } from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'

export const WelcomePopup: React.FC = () => {
  const specsUserState = useAppSelector(getSpecsUserState)
  const translations = useAppSelector(getTranslations)

  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const [showWelcomeInfo, setShowWelcomeInfo] = useState(
    !specsUserState.settings.hideWelcomeInfo ?? false
  )

  const onCloseButton = (): void => {
    const updatedSettings: ISpecsUserSettings = {
      ...specsUserState.settings,
      hideWelcomeInfo: true,
    }

    updateUserSettings(updatedSettings)

    setShowWelcomeInfo(false)
  }

  return (
    <JATOModal
      isOpen={showWelcomeInfo}
      maxWidth={'sm'}
      dismissable={false}
      header={() => (
        <>
          <JATOHeading
            as="h4"
            fontWeight="medium"
            display="flex"
            color={JATOTheme.colors.blue}
          >
            {translations.JNT_WelcomeHelpTitle}
          </JATOHeading>
          <JATOIcon style={{ paddingLeft: '10px' }} iconName="baseline_info" />
        </>
      )}
      footer={() => (
        <>
          <JATOButtonGroup justifyContent={'flex-end'}>
            <JATOButton
              id="specsWelcomePopupCloseButton"
              variant="primary"
              size="large"
              onClick={onCloseButton}
            >
              {translations.JNT_Close}
            </JATOButton>
          </JATOButtonGroup>
        </>
      )}
    >
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText1,
          }}
        />
      </p>
      <p>
        <strong>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_WelcomeHelpText2,
            }}
          />
        </strong>
      </p>
      <ul>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_WelcomeHelpText3,
            }}
          />
        </li>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_WelcomeHelpText4,
            }}
          />
        </li>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: translations.JNT_WelcomeHelpText5,
            }}
          />
        </li>
      </ul>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText6,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText7,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText8,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText9,
          }}
        />
      </p>
      <p>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.JNT_WelcomeHelpText10,
          }}
        />
      </p>
    </JATOModal>
  )
}
