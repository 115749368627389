import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesNavigation = styled.div`
  background-color: ${JATOTheme.colors.midOffGrey};
  padding-left: 15px;

  .jato-nav-bar {
    align-items: center;
  }

  .volumesMenuButton {
    background-color: ${JATOTheme.colors.midGrey};
    width: 31px;
    height: 31px;
    border-radius: 6px;
    margin: 5px 15px 4px;
  }

  .dropdown {
    -webkit-animation: EbufM 0.15s ease-out;
    -webkit-animation: EbufM 0.15s ease-out;
    animation: EbufM 0.15s ease-out;
    display: none;
    outline: none;
    position: absolute;
    left: -200px;
    right: -50px;
    display: block;
    font-size: 12px;
    padding: 0;
  }

  .rtl .dropdown {
    position: relative;
    top: 5px;
    left: 0px;
    right: 0px;
    direction: rtl;
  }
`
