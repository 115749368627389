import {
  JATOBasicCard,
  JATOButton,
  JATOButtonGroup,
  JATOGroup,
  JATOSelect,
  JATOSelect2,
  JATOText,
  JATOTheme
} from '@jato/ui-component-library'
import {
  ICurrencyInfo,
  ISpecsUserSettings,
} from 'models/Specifications/SpecsUserSettings'
import {
  getCurrencies,
  getSpecsUserState,
  resetFiltersToDefaults,
} from 'redux/specifications/specificationsSlice'

import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import { useUpdateUserSettings } from 'hooks/specsUserSettings'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

interface ISpecsSettings {
  onClose: () => void
}

export const SpecsSettings: React.FC<ISpecsSettings> = (params) => {
  const translations = useAppSelector(getTranslations)
  const specsUserState = useAppSelector(getSpecsUserState)
  const currencies = useAppSelector(getCurrencies)
  const { mutate: updateUserSettings } = useUpdateUserSettings()
  const dispatch = useAppDispatch()

  const priceOptions: string[] = [
    translations.JNT_BasePrice,
    translations.JNT_Country_Specific_Price,
    translations.JNT_RetailPrice,
    translations.JNT_RetailDeliveryPrice,
  ]

  const priceDropdownOptions = priceOptions.map((value) => ({ value }))

  const currencyDropdownOptions = currencies?.map(
    (currency: ICurrencyInfo) => ({
      ...currency,
      value: currency.description,
    })
  )

  const getSelectedCurrency = currencies?.find(
    (data: any) => data.currencyCode === specsUserState.settings.currencyCode
  )

  const getSelectedPrice = (): string | undefined => {
    if (specsUserState.settings.vehiclePriceSettings.isBasePrice) {
      return translations.JNT_BasePrice
    } else if (
      specsUserState.settings.vehiclePriceSettings.isCountrySpecificPrice
    ) {
      return translations.JNT_Country_Specific_Price
    } else if (specsUserState.settings.vehiclePriceSettings.isRetailPrice) {
      return translations.JNT_RetailPrice
    } else if (
      specsUserState.settings.vehiclePriceSettings.isRetailPriceinclDelivery
    ) {
      return translations.JNT_RetailDeliveryPrice
    }
  }

  const [displayLocalVehicleNames, setDisplayLocalVehicleNames] = useState(
    specsUserState.settings.isLocalOptions
  )

  const [excludeEmptyPrice, setExcludeEmptyPrice] = useState(
    specsUserState.settings.excludeEmptyPrice
  )

  const [outgoingModelYears, setOutgoingModelYears] = useState(
    specsUserState.settings.outgoingModelYears
  )

  const [selectedCurrency, setSelectedCurrency] = useState(getSelectedCurrency)

  const [selectedPrice, setSelectedPrice] = useState(getSelectedPrice())

  const handleSubmitSettings = (): void => {
    const updatedSettings: ISpecsUserSettings = {
      ...specsUserState.settings,
      isLocalOptions: displayLocalVehicleNames,
      vehiclePriceSettings: {
        isRetailPrice: selectedPrice == translations.JNT_RetailPrice,
        isBasePrice: selectedPrice == translations.JNT_BasePrice,
        isCountrySpecificPrice:
          selectedPrice == translations.JNT_Country_Specific_Price,
        isCountrySpecificPrice2: false,
        isRetailPriceinclDelivery:
          selectedPrice == translations.JNT_RetailDeliveryPrice,
      },
      excludeEmptyPrice: excludeEmptyPrice,
      outgoingModelYears: outgoingModelYears,
      currencyCode:
        selectedCurrency?.currencyCode ?? specsUserState.settings.currencyCode,
    }

    updateUserSettings(updatedSettings)
    dispatch(resetFiltersToDefaults())
    params.onClose()
  }

  const handleSelectedCurrency = (value: string): void => {
    const selectedCurrency = currencies?.find(
      (data: any) => data.description === value
    )
    setSelectedCurrency(selectedCurrency)
  }

  const handleSelectedPrice = (value: string): void => {
    const selectedPriceOption = priceOptions.find((data: any) => data === value)
    setSelectedPrice(selectedPriceOption)
  }

  return (
    <JATOBasicCard height="calc(100vh - 160px)">
      <JATOGroup flexDirection="column">
        <JATOGroup aria-label="Brands" role="group">
          <GtmWrapper id="specsSettingsCurrencySelect">
            <JATOSelect2
              label={translations.JNT_currency}
              width="100%"
              id="currencyDropdown"
              dataSrc={currencyDropdownOptions ?? []}
              value={selectedCurrency?.description}
              onChange={handleSelectedCurrency}
              hideClearSelection={true}
              ignoreCase={true}
              displayOnlyUnselectedOptions={true}
            />
          </GtmWrapper>
        </JATOGroup>
        <JATOGroup aria-label="Brands" role="group">
          <GtmWrapper id="specsSettingsCurrencySelect">
            <JATOSelect
              label={translations.JNT_price}
              width="100%"
              id="priceDropdown"
              dataSrc={
                priceDropdownOptions.filter((value) => value !== undefined) ??
                []
              }
              value={selectedPrice?.toString()}
              onChange={handleSelectedPrice}
            />
          </GtmWrapper>
        </JATOGroup>
        <JATOGroup flexDirection="column">
          <JATOGroup
            aria-label="Brands"
            role="group"
            style={{ marginBottom: '20px' }}
          >
            <CheckboxWithGtm
              id="specsSettingsDisplayLocalVehicleNamesCheckbox"
              checked={displayLocalVehicleNames}
              onChange={() =>
                setDisplayLocalVehicleNames(!displayLocalVehicleNames)
              }
              label={translations.JNT_local}
            >
              <JATOText marginRight={JATOTheme.space[6]} as="p" fontSize={14}>
                {translations.JNT_local}
              </JATOText>
            </CheckboxWithGtm>
          </JATOGroup>
          <JATOGroup
            aria-label="Brands"
            role="group"
            style={{ marginBottom: '20px' }}
          >
            <CheckboxWithGtm
              id="specsSettingsHideVehiclesWithoutPriceCheckbox"
              checked={excludeEmptyPrice}
              onChange={() => setExcludeEmptyPrice(!excludeEmptyPrice)}
              label={translations.JNT_WPrice}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {translations.JNT_WPrice}
              </JATOText>
            </CheckboxWithGtm>
          </JATOGroup>
          <JATOGroup
            aria-label="Brands"
            role="group"
            style={{ marginBottom: '20px' }}
          >
            <CheckboxWithGtm
              id="specsSettingsOutgoingModelYearCheckbox"
              checked={outgoingModelYears}
              onChange={() => setOutgoingModelYears(!outgoingModelYears)}
              label={translations.JNT_excludeold}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {translations.JNT_excludeold}
              </JATOText>
            </CheckboxWithGtm>
          </JATOGroup>
        </JATOGroup>
        <JATOButtonGroup justifyContent="flex-end">
          <JATOButton
            id="specsSettingsSubmitButton"
            textTransform="uppercase"
            onClick={() => handleSubmitSettings()}
            variant="primary"
          >
            {translations.JNT_Submit}
          </JATOButton>
        </JATOButtonGroup>
      </JATOGroup>
    </JATOBasicCard>
  )
}
