import {
  JATOLink,
  JATOList,
  JATOListItem,
  JATOSpinner,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { vehicleNewsArticleBaseUrl } from 'config'
import { CommonNewsVehicle } from 'models/News/Vehicle/CommonNewsVehicle'
import { VehicleNewsRequest } from 'models/VehicleNews/VehicleNewsRequest'
import { VehicleNewsResponse } from 'models/VehicleNews/VehicleNewsResponse'
import { MpVehiclesResponse } from 'models/VehicleSelection/MpVehiclesResponse'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import vehicleNewsService from 'services/VehicleNews/VehicleNewsService'
import { LogoNewsLoader, StyledVehicleNews } from './VehicleNews.styles'

export interface IVehicleGridProps {
  MpVehiclesResponse: MpVehiclesResponse
}

interface IVehicleNews {
  vehicle: CommonNewsVehicle
}

export const VehicleNews: React.FC<IVehicleNews> = ({
  vehicle,
}: IVehicleNews) => {
  const commonUserData = useAppSelector(selectCommonUserData)

  const vehicleNewsRequest: VehicleNewsRequest = {
    guid: commonUserData.guid,
    languageId: commonUserData.languageId,
    specsDb: vehicle.specsDb,
    vehicleId: vehicle.vehicleId,
    topCount: 5,
    isMonthlyPayment: vehicle.isMonthlyPayments,
  }

  const { data: vehicleNewsResponse, isLoading } =
    useQuery<VehicleNewsResponse>(
      ['fetchLatestNewsData', vehicle.vehicleId],
      async () => {
        const { data } =
          await vehicleNewsService.getLatesVehicleNewsArticles(
            vehicleNewsRequest
          )
        return data
      }
    )

  const viewAllNewsLink = `${vehicleNewsArticleBaseUrl}/${vehicleNewsRequest.guid}/${vehicleNewsRequest.languageId}/news?redirectUrl=/searchViewAll/${vehicle.make}/${vehicle.model}/${vehicle.countryCode}`

  const getVehicleNewsArticleUrl = (articleId: number): string =>
    `${vehicleNewsArticleBaseUrl}/${vehicleNewsRequest.guid}/${vehicleNewsRequest.languageId}/news?redirectUrl=/articles/${articleId}`

  return (
    <StyledVehicleNews>
      {isLoading ? (
        <LogoNewsLoader>
          <JATOSpinner spinnerSize={64} />
        </LogoNewsLoader>
      ) : (
        vehicleNewsResponse && (
          <div id="vehicle-grid-container">
            <JATOVerticalSpacer />
            {vehicleNewsResponse.articleData.length > 0 ? (
              <>
                <JATOList>
                  {vehicleNewsResponse.articleData.map((article, index) => (
                    <JATOListItem key={index} justifyContent="space-between">
                      <JATOLink
                        id="monthlyPaymentsReportVehicleNewsLink"
                        href={getVehicleNewsArticleUrl(article.id)}
                        target="_blank"
                      >
                        {article.headline}
                      </JATOLink>
                      <span>
                        {moment(Date.parse(article.concludeDate))
                          .utcOffset(0, true)
                          .format('DD-MM-yyyy')}
                      </span>
                    </JATOListItem>
                  ))}
                </JATOList>
                <div style={{ paddingLeft: '318px' }}>
                  <JATOLink
                    id="monthlyPaymentsReportViewAllNewsLink"
                    href={viewAllNewsLink}
                    target="_blank"
                  >
                    View All
                  </JATOLink>
                </div>
              </>
            ) : (
              vehicleNewsResponse.responseMessage
            )}
          </div>
        )
      )}
    </StyledVehicleNews>
  )
}
