import { JATOSelect2 } from '@jato/ui-component-library'
import {
  getNewsCategoryTranslations,
  selectableCategories,
} from 'models/News/NewsCategoryType'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsCategoriesSelect } from './NewsCategoriesSelect.styles'

interface INewsCategorySelectProps {
  value: string
  onChange: (value: string) => void
}

export const NewsCategoriesSelect: React.FC<INewsCategorySelectProps> = ({
  value,
  onChange,
}: INewsCategorySelectProps) => {
  const translations = useAppSelector(getTranslations)

  const categoryTranslations = getNewsCategoryTranslations(translations)

  const dropdownOptions = selectableCategories.map((c) => ({
    value: c,
    displayValue: categoryTranslations[c],
  }))

  return (
    <StyledNewsCategoriesSelect>
      <JATOSelect2
        id="newsCategoriesSelect"
        label={`${translations.JNT_News_Category}`}
        dataSrc={dropdownOptions}
        value={value}
        placeholder={translations.JNT_AllCategories}
        onChange={onChange}
        width={320}
        ignoreCase={true}
        displayOnlyUnselectedOptions={false}
        isMultiSelect={true}
        clearAfterSelect={true}
        displayInlineValues={true}
        setAutoFocus={false}
        displaySelectAll={true}
        displayDeselectAll={true}
      />
    </StyledNewsCategoriesSelect>
  )
}
