import { JATOLink } from '@jato/ui-component-library'
import { mpJatoSalesEnquiryLink } from 'config'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledNoLicense } from './NoLicense.styles'
import NoNewsLicenseImage from './images/NoAccessNews.jpg'
import NoMpLicenseImage from './images/NoLicenseMonthlyPayments.png'
import NoSpecsLicenseImage from './images/NoLicenseSpecifications.png'
import NoVolumesLicenseImage from './images/NoLicenseVolumes.png'
import dialogBackGroundImageMP from './images/no-access-dialog-background-1-mp.png'
import dialogBackGroundImageSpecs from './images/no-access-dialog-background-1.png'
import MyPageSalesEnquiryModal from 'components/MyPage/MyPageSalesEnquiryModal'

export const NoLicense: React.FC = () => {
  const location = useLocation()
  const translations = useAppSelector(getTranslations)

  const [isSalesEnquiryModalOpen, setSalesEnquiryModalOpen] = useState(false)

  const getNoAccessSignInText = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return "You're signed into JATONet"
      case RoutePaths.SpecsNoLicense:
        return translations.JNT_NoAccess_SignIn_Text
      case RoutePaths.NewsNoLicense:
        return translations.JNT_NoAccess_SignIn_Text
      case RoutePaths.VolumesNoLicense:
        return translations.JNT_NoAccess_SignIn_Text
    }

    return ''
  }

  const getNoAccessYourCompanyText = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return "You or Your company doesn't have any Monthly Payments Subscription."
      case RoutePaths.SpecsNoLicense:
        return translations.JNT_NoAccess_Your_Company
      case RoutePaths.NewsNoLicense:
        return translations.JNT_News_NoAccess_Your_Company
      case RoutePaths.VolumesNoLicense:
        return translations.JNT_Volumes_NoAccess_Your_Company
    }

    return ''
  }

  const getNoAccessLetsTalkText = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return "Let's Talk"
      case RoutePaths.SpecsNoLicense:
        return translations.JNT_NoAccess_Lets_Talk
      case RoutePaths.NewsNoLicense:
        return translations.JNT_NoAccess_Lets_Talk
      case RoutePaths.VolumesNoLicense:
        return translations.JNT_NoAccess_Lets_Talk
    }

    return ''
  }

  const getNoAccessCompleteYourRequestText = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return 'Complete the form in the link below and we’ll be in touch'
      case RoutePaths.SpecsNoLicense:
        return translations.JNT_NoAccess_Complete_Your_Request
      case RoutePaths.NewsNoLicense:
        return translations.JNT_NoAccess_Complete_Your_Request
      case RoutePaths.VolumesNoLicense:
        return translations.JNT_NoAccess_Complete_Your_Request
    }

    return ''
  }

  const getNoAccessSalesEnquiryText = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return 'Visit Monthly Payments page'
      case RoutePaths.SpecsNoLicense:
        return translations.JNT_NoAccess_Sales_Enquiry
      case RoutePaths.NewsNoLicense:
        return translations.JNT_NoAccess_Sales_Enquiry
      case RoutePaths.VolumesNoLicense:
        return translations.JNT_NoAccess_Sales_Enquiry
    }

    return ''
  }

  const getNoLicenseImage = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return NoMpLicenseImage
      case RoutePaths.SpecsNoLicense:
        return NoSpecsLicenseImage
      case RoutePaths.NewsNoLicense:
        return NoNewsLicenseImage
      case RoutePaths.VolumesNoLicense:
        return NoVolumesLicenseImage
    }

    return ''
  }

  const getDialogBackgroundImage = (): string => {
    switch (location.pathname) {
      case RoutePaths.MPNoLicense:
        return dialogBackGroundImageMP
      case RoutePaths.SpecsNoLicense:
        return dialogBackGroundImageSpecs
      case RoutePaths.NewsNoLicense:
        return dialogBackGroundImageSpecs
      case RoutePaths.VolumesNoLicense:
        return dialogBackGroundImageSpecs
    }

    return ''
  }

  return (
    <StyledNoLicense
      noLicenseImage={getNoLicenseImage()}
      backgroundImage={getDialogBackgroundImage()}
    >
      <div className="textPosition">
        <div className="noAccessDialog">
          <div className="noAccessTextSection">
            <div className="noAccessSigninText">{getNoAccessSignInText()}</div>
            <div className="noAccessYourCompany">
              {getNoAccessYourCompanyText()}
            </div>
            <div className="noAccessLetsTalk">{getNoAccessLetsTalkText()}</div>
            <div className="noAccessCompleteYourRequest">
              {getNoAccessCompleteYourRequestText()}
            </div>
            <div className="noAccessRectangleButton">
              {location.pathname === RoutePaths.MPNoLicense ? (
                <JATOLink
                  display="inline-flex"
                  href={mpJatoSalesEnquiryLink}
                  target="_blank"
                  textTransform="none"
                >
                  {getNoAccessSalesEnquiryText()}
                </JATOLink>
              ) : (
                <JATOLink
                  display="inline-flex"
                  onClick={() => setSalesEnquiryModalOpen(true)}
                  textTransform="none"
                >
                  {getNoAccessSalesEnquiryText()}
                </JATOLink>
              )}
            </div>
          </div>
        </div>
      </div>
      {isSalesEnquiryModalOpen && (
        <MyPageSalesEnquiryModal
          isOpen={isSalesEnquiryModalOpen}
          onClose={() => setSalesEnquiryModalOpen(false)}
        />
      )}
    </StyledNoLicense>
  )
}
