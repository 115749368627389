import { MonthlyPaymentsSceneriosContainer } from 'components/MonthlyPayments/MonthlyPaymentsReport/MonthlyPaymentsSceneriosContainer'
import { authRequired } from 'config'
import AutoLogin from 'pages/AutoLogin'
import { Logout } from 'pages/Logout'
import MonthlyPayments from 'pages/MonthlyPayments'
import MonthlyPaymentReportContainer from 'pages/MonthlyPaymentsReport'
import { NoLicense } from 'pages/NoLicense/NoLicense'
import NotFound from 'pages/NotFound'
import Specifications from 'pages/Specifications'
import SpecificationsReportContainer from 'pages/SpecificationsReport'

import { PrivatePolicy } from 'components/Portal/PrivatePolicy'
import { Terms } from 'components/Portal/Terms'
import { BasketLoad } from 'pages/BasketLoad'
import Home from 'pages/Home'
import MyPage from 'pages/MyPage'
import News from 'pages/News'
import NewsArticle from 'pages/NewsArticle'
import NewsPageComponent from 'pages/NewsPageComponent'
import { NewsSearchById } from 'pages/NewsSearchById/NewsSearchById'
import { NewsSearchViewAll } from 'pages/NewsSearchViewAll/NewsSearchViewAll'
import Volumes from 'pages/Volumes'
import VolumesMyCountryGrouping from 'pages/VolumesMyCountryGrouping'
import VolumesMyQueries from 'pages/VolumesMyQueries'
import VolumesQueryLoad from 'pages/VolumesQueryLoad'
import VolumesVehiclesVolumesType from 'pages/VolumesVehiclesVolumesType'
import React from 'react'
import { Redirect } from 'react-router'
import { Switch } from 'react-router-dom'
import { IRouteProps, PrivateRoute, Route } from 'router'
import { RoutePaths } from './RoutePaths'

/**
 * The <Route> and <PrivateRoute> components below do not support the `render`
 * prop. Please use the `component` prop instead.
 *
 * Good ✅: <Route path="/page" component={Page} />
 *  Bad 🚫: <Route path="/page" render={() => <Page />} />
 *
 * If necessary the a React <Route> component can be imported using a different
 * name if you need to use the `render` attribute. In this circumstance the
 * route will not be wrapped with a layout. See <Reroute> below as an example.
 */
export const Routes = (): React.ReactElement => {
  // Returns either a private or public route based on the `authRequired` config
  const ConditionalRoute = (props: IRouteProps): React.ReactElement =>
    authRequired ? <PrivateRoute {...props} /> : <Route {...props} />

  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => <Redirect to={RoutePaths.MyPage} />}
      />
      <ConditionalRoute
        exact
        path={RoutePaths.MyPage}
        component={MyPage}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.MonthlyPayments}
        component={MonthlyPayments}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.Specifications}
        component={Specifications}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.News}
        component={News}
        layout="news"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.NewsNoLicense}
        component={NoLicense}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.NewsArticles}/:articleId`}
        component={NewsArticle}
        layout="news"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.NewsArticles}/:newsPage/:articleId`}
        component={NewsArticle}
        layout="news"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.NewsSearchById}/:id`}
        component={NewsSearchById}
        layout="news"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.NewsSearchViewAll}/:make/:model/:countryCode`}
        component={NewsSearchViewAll}
        layout="news"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.News}/:newsPage`}
        component={NewsPageComponent}
        layout="news"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.MonthlyPaymentsReport}
        component={MonthlyPaymentReportContainer}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.MonthlyPaymentsReport}/:uid/:sceneriosCount/:dataDate`}
        component={MonthlyPaymentsSceneriosContainer}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.SpecsShowroom}
        component={SpecificationsReportContainer}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.SpecsComparisons}/:comparisonPage`}
        component={SpecificationsReportContainer}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.SpecsOptions}/:page`}
        component={SpecificationsReportContainer}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.SpecsBaskets}/:basketId`}
        component={BasketLoad}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.Volumes}
        component={Volumes}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.VolumesMyQueries}
        component={VolumesMyQueries}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.VolumesVehiclesVolumesType}
        component={VolumesVehiclesVolumesType}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.VolumesMyCountryGrouping}
        component={VolumesMyCountryGrouping}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.VolumesWizard}/:volumesStep`}
        component={Volumes}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={`${RoutePaths.VolumesQuery}/:queryId`}
        component={VolumesQueryLoad}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.MPNoLicense}
        component={NoLicense}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.SpecsNoLicense}
        component={NoLicense}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.NewsNoLicense}
        component={NoLicense}
        layout="default"
      />
      <ConditionalRoute
        exact
        path={RoutePaths.VolumesNoLicense}
        component={NoLicense}
        layout="default"
      />
      <Route exact path={RoutePaths.Home} component={Home} layout="home" />
      <Route exact path={RoutePaths.Terms} component={Terms} layout="home" />
      <Route
        exact
        path={RoutePaths.PrivacyPolicy}
        component={PrivatePolicy}
        layout="home"
      />
      <Route
        exact
        path={`${RoutePaths.Autologin}/:guid/:languageId/:path?`}
        component={AutoLogin}
        layout="guest"
      />
      <Route exact path={RoutePaths.Logout} component={Logout} />
      <Route path={RoutePaths.Autologout} component={Logout} />
      <Route component={NotFound} />
    </Switch>
  )
}
