import { queryClient } from 'app'
import { maxValuesClientSide } from 'config'
import { showErrorToast, showInfoToast } from 'helper/toastHelper'
import {
  MaxReportRows,
  getDataPointRequest,
  isUserSegment,
} from 'helper/volumesHelper'
import { LoginObject } from 'models/Login/LoginObject'
import { LoggedUserInfo } from 'models/Login/NewsLogin/LoggedUserInfo'
import { Countries } from 'models/Volumes/Countries'
import { CountryGroup } from 'models/Volumes/CountryGroup'
import { CreateOrUpdateVehicleVolumeRequest } from 'models/Volumes/CreateOrUpdateVehicleVolumeRequest'
import { DataPointRequest } from 'models/Volumes/DataPointRequest'
import { DataPointValues } from 'models/Volumes/DataPointValues'
import { GetDataPointValuesRequest } from 'models/Volumes/GetDataPointValuesRequest'
import { GetPeriodDataRequest } from 'models/Volumes/GetPeriodDataRequest'
import { GetReportRequest } from 'models/Volumes/GetReportRequest'
import { JATONetTreeview } from 'models/Volumes/JATONetTreeview'
import { OutputReportResponse } from 'models/Volumes/OutputReportResponse'
import { PeriodDataPoint } from 'models/Volumes/PeriodDataPoint'
import { QueryDefinitionTreeRequest } from 'models/Volumes/QueryDefinitionTreeRequest'
import { QueryExportResponse } from 'models/Volumes/QueryExportResponse'
import { SaveQueryRequest } from 'models/Volumes/SaveQueryRequest'
import { UserValidData } from 'models/Volumes/UserValidData'
import { VehicleVolumeGroups } from 'models/Volumes/VehicleVolumeGroups'
import { VehicleVolumeTypeResponse } from 'models/Volumes/VehicleVolumeTypeResponse'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import { VolumesExcelExportRequest } from 'models/Volumes/VolumesExcelExportRequest'
import { VolumesNewsArticleRequest } from 'models/Volumes/VolumesNewsArticleRequest'
import { VolumesNewsArticleResponse } from 'models/Volumes/VolumesNewsArticleResponse'
import { VolumesQuery } from 'models/Volumes/VolumesQuery'
import { VolumesSelection } from 'models/Volumes/VolumesSelection'
import { VolumesUserSettingsRequest } from 'models/Volumes/VolumesUserSettings'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query'
import {
  selectCommonUserData,
  setCommonUserState,
} from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  getVolumesUserState,
  setVolumesUserState,
} from 'redux/volumes/volumesSlice'
import newsService from 'services/News/NewsService'
import volumesService from 'services/Volumes/VolumesService'

const LoadQueryByIdQueryKey = 'loadQueryById'
const LoadAllQueriesQueryKey = 'loadAllQueries'
const GetCountriesQueryKey = 'getCountries'
const GetCountryGroupsQueryKey = 'getCountryGroups'
const GetVehicleVolumeGroupsQueryKey = 'getVehicleVolumeGroups'
const GetVehicleVolumeTypesQueryKey = 'getVehicleVolumeTypes'
const GetPeriodSelectionsQueryKey = 'getPeriodSelections'
const GetPeriodDataPointsQueryKey = 'getPeriodDataPoints'
const LoadQueryDefinitionTreeQueryKey = 'loadQueryDefinitionTree'
const GetDataPointValuesQueryKey = 'getDataPointValues'
const GetPricingCurrencyDataQueryKey = 'getPricingCurrencyData'
const GetReportQueryKey = 'getReport'
const GetVolumesNewsArticlesQueryKey = 'getVolumesNewsArticles'
export const NewsLoginQueryKey = 'newsLogin'

export const useLoadQueryById = (
  queryId: number
): UseQueryResult<DataPointRequest, unknown> =>
  useQuery<DataPointRequest>(
    [LoadQueryByIdQueryKey, queryId],
    () => volumesService.loadQueryById(queryId),
    { enabled: !!queryId }
  )

export const useLoadAllQueries = (
  databaseType?: VolumesDatabaseType
): UseQueryResult<VolumesQuery[], unknown> =>
  useQuery<VolumesQuery[]>([LoadAllQueriesQueryKey, databaseType], () =>
    volumesService.loadAllQueries(databaseType ?? 'All')
  )

export const useGetCountries = (
  databaseType?: VolumesDatabaseType,
  disabled?: boolean
): UseQueryResult<Countries[], unknown> =>
  useQuery<Countries[]>(
    [GetCountriesQueryKey, databaseType],
    () => volumesService.getCountryData(databaseType),
    { enabled: !!databaseType && !disabled, staleTime: Infinity }
  )

export const useRenameQuery = (): UseMutationResult<
  void,
  unknown,
  VolumesQuery,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: (updatedQuery: VolumesQuery) =>
      volumesService.renameQuery(
        updatedQuery.queryId,
        updatedQuery.queryShortDescription
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(LoadAllQueriesQueryKey)
    },
  })

  return mutation
}

export const useUpdateQuery = (): UseMutationResult<
  boolean,
  unknown,
  VolumesQuery,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: (updatedQuery: VolumesQuery) =>
      volumesService.updateQuery(updatedQuery.queryId, updatedQuery.qryShared),
    onSuccess: () => {
      queryClient.invalidateQueries(LoadAllQueriesQueryKey)
    },
  })

  return mutation
}

export const useDeleteQuery = (): UseMutationResult<
  boolean,
  unknown,
  VolumesQuery,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: (query: VolumesQuery) =>
      volumesService.deleteQuery(query.queryId),
    onSuccess: () => {
      queryClient.invalidateQueries(LoadAllQueriesQueryKey)
    },
  })

  return mutation
}

export const useGetCountryGroups = (
  databaseType?: VolumesDatabaseType
): UseQueryResult<CountryGroup[], unknown> =>
  useQuery<CountryGroup[]>(
    [GetCountryGroupsQueryKey, databaseType],
    () => volumesService.getCountryGroups(databaseType),
    { enabled: !!databaseType, staleTime: Infinity }
  )

export const useSaveCountryGroup = (): UseMutationResult<
  boolean,
  unknown,
  CountryGroup,
  unknown
> =>
  useMutation({
    mutationFn: (countryGroup: CountryGroup) =>
      volumesService.createEditCountryGroup(countryGroup),
    onSuccess: () => {
      queryClient.invalidateQueries(GetCountryGroupsQueryKey)
    },
  })

export const useSaveQuery = (
  queryName: string
): UseMutationResult<number, unknown, void, unknown> => {
  const queryState = useAppSelector(getVolumesQueryState)
  const userData = useAppSelector(getVolumesUserState)

  const query: SaveQueryRequest = {
    qryLongDesc: queryName,
    qryShortDesc: queryName,
    datapointRequest: getDataPointRequest(queryState, userData),
  }

  return useMutation({
    mutationFn: () => volumesService.saveQuery(query),
    onSuccess: () => {
      queryClient.invalidateQueries(LoadAllQueriesQueryKey)
    },
  })
}

export const useVolumesExportToExcel = (): UseMutationResult<
  QueryExportResponse,
  unknown,
  void,
  unknown
> => {
  const queryState = useAppSelector(getVolumesQueryState)
  const userData = useAppSelector(getVolumesUserState)

  const excelExportRequest: VolumesExcelExportRequest = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    languageId: userData.languageId,
    datapointRequest: getDataPointRequest(queryState, userData),
  }

  return useMutation({
    mutationFn: async () => {
      const excelResponse =
        await volumesService.exportQueryToExcel(excelExportRequest)
      return excelResponse
    },
  })
}

export const useDeleteCountryGroup = (): UseMutationResult<
  boolean,
  unknown,
  string,
  unknown
> =>
  useMutation({
    mutationFn: (id: string) => volumesService.deleteCountryGroup(id),
    onSuccess: () => {
      queryClient.invalidateQueries(GetCountryGroupsQueryKey)
    },
  })

export const useGetVehicleVolumeTypes = (
  disabled?: boolean
): UseQueryResult<UserValidData[], unknown> =>
  useQuery<UserValidData[]>(
    [GetVehicleVolumeTypesQueryKey],
    () => volumesService.getVehicleVolumeTypes(),
    { enabled: !disabled, staleTime: Infinity }
  )

export const useGetVehicleVolumeGroups = (): UseQueryResult<
  VehicleVolumeGroups[],
  unknown
> =>
  useQuery<VehicleVolumeGroups[]>(
    [GetVehicleVolumeGroupsQueryKey],
    () => volumesService.getVehicleVolumeGroups(),
    { staleTime: Infinity }
  )

export const useSaveVehicleVolumeGroup = (): UseMutationResult<
  VehicleVolumeTypeResponse,
  unknown,
  CreateOrUpdateVehicleVolumeRequest,
  unknown
> =>
  useMutation({
    mutationFn: (vehicleVolumeGroup: CreateOrUpdateVehicleVolumeRequest) =>
      volumesService.createEditVehicleVolumeGroup(vehicleVolumeGroup),
    onSuccess: () => {
      queryClient.invalidateQueries(GetVehicleVolumeGroupsQueryKey)
    },
  })

export const useRestoreToDefaultVehicleVolumeGroups = (): UseMutationResult<
  VehicleVolumeTypeResponse,
  unknown,
  VolumesDatabaseType,
  unknown
> =>
  useMutation({
    mutationFn: (databaseType: VolumesDatabaseType) =>
      volumesService.restoreToDefaultVehicleVolumeGroups(databaseType),
    onSuccess: () => {
      queryClient.invalidateQueries(GetVehicleVolumeGroupsQueryKey)
    },
  })

export const useGetPeriodSelections = (
  databaseType: VolumesDatabaseType
): UseQueryResult<VolumesSelection[], unknown> =>
  useQuery<VolumesSelection[]>([GetPeriodSelectionsQueryKey], () =>
    volumesService.getPeriodSelections(databaseType)
  )

export const useGetPeriodDataPoints = (): UseMutationResult<
  PeriodDataPoint[],
  unknown,
  GetPeriodDataRequest,
  unknown
> =>
  useMutation({
    mutationFn: (dataPointRequest: GetPeriodDataRequest) =>
      volumesService.getPeriodDataPoints(dataPointRequest),
    onSuccess: () => {
      queryClient.invalidateQueries(GetPeriodDataPointsQueryKey)
    },
  })

export const useGetReport = (): UseQueryResult<
  OutputReportResponse,
  unknown
> => {
  const queryState = useAppSelector(getVolumesQueryState)
  const userData = useAppSelector(getVolumesUserState)
  const translations = useAppSelector(getTranslations)

  const request: GetReportRequest = {
    languageId: userData.languageId,
    dataPointRequest: getDataPointRequest(queryState, userData),
  }

  return useQuery<OutputReportResponse>(
    [
      GetReportQueryKey,
      queryState.databaseType,
      queryState.dataSetIds,
      queryState.countries,
      queryState.vehicleAttributes,
      queryState.outputPoints,
      queryState.periods,
      queryState.analysisOptions,
    ],
    () => volumesService.getReport(request),
    {
      onSuccess: (data) => {
        if (data && data.dtable.length > MaxReportRows) {
          showInfoToast(
            translations.JNT_Warning,
            `${translations.JNT_Volumes_FP_WarningA} ${MaxReportRows} ${translations.JNT_Volumes_FP_WarningB}`
          )
        }
      },
      staleTime: Infinity,
    }
  )
}

export const useLoadQueryDefinitionTree = (): UseQueryResult<
  JATONetTreeview[],
  unknown
> => {
  const queryState = useAppSelector(getVolumesQueryState)

  const request: QueryDefinitionTreeRequest = {
    productGroup: queryState.databaseType ?? '',
    dataSetIds: queryState.dataSetIds ?? [],
    countryList: queryState.countries ?? [],
  }

  return useQuery<JATONetTreeview[]>(
    [
      LoadQueryDefinitionTreeQueryKey,
      queryState.databaseType,
      queryState.dataSetIds,
      queryState.countries,
    ],
    () => volumesService.loadQueryDefinitionTree(request)
  )
}

export const useGetDataPointValues = (
  selectedItem?: JATONetTreeview
): UseMutationResult<DataPointValues[], unknown, void, unknown> => {
  const item = selectedItem!
  const queryState = useAppSelector(getVolumesQueryState)
  const translations = useAppSelector(getTranslations)

  const getRequest = (): GetDataPointValuesRequest => {
    const request = {
      dataPointGroup:
        item.parentParentDataPointGroup ??
        item.parentDataPointGroup ??
        item.dataPointGroup,
      dataPointName: item.dataPointName,
      productGroup: queryState.databaseType ?? '',
      dataSetIds: queryState.dataSetIds ?? [],
      countryList: queryState.countries ?? [],
      dataPoints: queryState.vehicleAttributes ?? [],
      registrationTypeList: queryState.registrationTypeList ?? [],
      vehicleTypeList: queryState.vehicleTypeList ?? [],
      segment_GroupID: isUserSegment(item.dataPointName)
        ? item.dataUnit
        : queryState.segment_GroupID,
      segment_segSource: isUserSegment(item.dataPointName)
        ? item.dataMetaType
        : queryState.segment_segSource,
      segment_SegmentList: queryState.segment_SegmentList ?? [],
    }

    // workaround to set user segment value empty for data points to fetch correctly
    const userSegmentDataPoint = request.dataPoints.find((dp) =>
      isUserSegment(dp.id)
    )
    if (userSegmentDataPoint) {
      request.dataPoints = [
        ...request.dataPoints.filter((dp) => dp !== userSegmentDataPoint),
        { ...userSegmentDataPoint, value: '' },
      ]
    }

    return request
  }

  return useMutation({
    mutationKey: [GetDataPointValuesQueryKey],
    mutationFn: () => volumesService.getDataPointValues(getRequest()),
    onSuccess: (data) => {
      if (data.length >= maxValuesClientSide) {
        showInfoToast(
          '',
          `${translations.JNT_Volumes_MaxValuesToShowOnClientSide} ${maxValuesClientSide}`
        )
      }
    },
  })
}

export const useGetPricingCurrencyData = (
  enabled: boolean
): UseQueryResult<DataPointValues[], unknown> =>
  useQuery<DataPointValues[]>(
    [GetPricingCurrencyDataQueryKey],
    () => volumesService.getPricingCurrencyData(),
    { enabled: enabled, staleTime: Infinity }
  )

export const useGetVolumesNewsArticles = (
  request?: VolumesNewsArticleRequest
): UseQueryResult<VolumesNewsArticleResponse, unknown> => {
  const translations = useAppSelector(getTranslations)

  return useQuery<VolumesNewsArticleResponse>(
    [GetVolumesNewsArticlesQueryKey, request],
    () => volumesService.getVolumesNewsArticles(request),
    {
      onSuccess: (data) => {
        if (data && !data.isSuccess) {
          showErrorToast(translations.JNT_Error, data.responseMessage)
        }
      },
      enabled: !!request,
    }
  )
}

export const useUpdateUserSettings = (): UseMutationResult<
  boolean,
  unknown,
  VolumesUserSettingsRequest,
  unknown
> => {
  const dispatch = useAppDispatch()
  const commonUserData = useAppSelector(selectCommonUserData)
  const volumesUserState = useAppSelector(getVolumesUserState)

  const mutation = useMutation({
    mutationFn: (userSettings: VolumesUserSettingsRequest) =>
      volumesService.updateUserSettings(userSettings),
    onSuccess: (response, userSettings) => {
      const updatedUserState = {
        ...volumesUserState,
        languageId: userSettings.languageId,
        currency: userSettings.currency,
        hideWelcomeInfo: userSettings.hideWelcomeInfo,
        hideJATOAcademyPopUp: userSettings.hideJATOAcademyPopUp,
      }
      dispatch(setVolumesUserState(updatedUserState))
      dispatch(
        setCommonUserState({
          ...commonUserData,
          languageId: userSettings.languageId,
        })
      )
    },
  })

  return mutation
}

export const useNewsLogin = (): UseQueryResult<LoggedUserInfo, unknown> => {
  const commonUserData = useAppSelector(selectCommonUserData)
  const { languageId } = useAppSelector(selectCommonUserData)
  const { guid } = commonUserData

  const loginRequest: LoginObject = {
    username: '',
    password: '',
    languageId: languageId,
    guid: guid,
    isJatoNet: true,
    isJatoSpecs: false,
  }

  return useQuery(NewsLoginQueryKey, () => newsService.login(loginRequest), {
    staleTime: 30 * 60 * 1000, // 30 minutes
  })
}
