import { rtlLanguageIds, rtlMarkets } from 'config'
import { commaSeparatedStringToStringArray } from './stringHelper'

export const getSiteDirectionForMarket = (
  languageId: string,
  lastSelectedMarket: string | undefined
): 'rtl' | 'ltr' => {
  const direction = lastSelectedMarket
    ? commaSeparatedStringToStringArray(rtlLanguageIds).includes(languageId) &&
      commaSeparatedStringToStringArray(rtlMarkets).includes(lastSelectedMarket)
      ? 'rtl'
      : 'ltr'
    : 'ltr'

  return direction
}
