import {
  IAnnouncement,
  JATOAnnouncement,
  JATOIcon,
  JATOLink,
} from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import {
  mpAlertsBetaAnnouncementDaysShowAfterEnd,
  mpAlertsBetaAnnouncementTimeoutMs,
  mpAlertsBetaEndDate,
  mpAlertsBetaStartDate,
} from 'config'
import { useGetMpAlertsBetaState } from 'hooks/mpAlertsBeta'
import moment from 'moment'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { MpAlertsBetaWelcomePopupLink } from '../MpAlertsBetaWelcomePopupLink'
import { StyledMpAlertsBetaNavBarComponent } from './MpAlertsBetaNavBarComponent.styles'

const mpAlertsBetaAnnouncementId = 'mpAlertsBetaAnnouncement'
const CountDaysPlaceholder = '%COUNT_DAYS%'

export const MpAlertsBetaNavBarComponent: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const { data: mpAlertsBeta, isFetching } = useGetMpAlertsBetaState()

  const isEnabled = mpAlertsBeta?.isEnabled ?? false

  const announcementStart: IAnnouncement = {
    id: mpAlertsBetaAnnouncementId,
    title: '',
    variant: 'success',
    timeout: mpAlertsBetaAnnouncementTimeoutMs,
    children: (
      <div style={{ lineHeight: 1.5, textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <JATOIcon iconName="baseline_check" />
          <b>{translations.JNT_MpAlertsBeta_AnnouncementStartTitle}</b>
        </div>
        <div>{translations.JNT_MpAlertsBeta_AnnouncementStart}</div>
      </div>
    ),
  }
  const announcementMid: IAnnouncement = {
    id: mpAlertsBetaAnnouncementId,
    title: '',
    variant: 'default',
    timeout: mpAlertsBetaAnnouncementTimeoutMs,
    children: (
      <div style={{ lineHeight: 1.5, textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <JATOIcon iconName="baseline_info" />
          <b>{translations.JNT_MpAlertsBeta_AnnouncementMidTitle}</b>
        </div>
        <div>
          {translations.JNT_MpAlertsBeta_AnnouncementMid.replace(
            CountDaysPlaceholder,
            moment(mpAlertsBeta?.betaEndDateForUser)
              .diff(moment(), 'days')
              .toString()
          )}
        </div>
      </div>
    ),
  }
  const announcementEnd: IAnnouncement = {
    id: mpAlertsBetaAnnouncementId,
    title: '',
    variant: 'default',
    timeout: mpAlertsBetaAnnouncementTimeoutMs,
    children: (
      <div style={{ lineHeight: 1.5, textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <JATOIcon iconName="baseline_info" />
          <b>{translations.JNT_MpAlertsBeta_AnnouncementEndTitle}</b>
        </div>
        <div>
          {translations.JNT_MpAlertsBeta_AnnouncementEnd}{' '}
          <JATOLink
            href="mailto:enquiries@jato.com?subject=Monthly Payments quotation request"
            textTransform="none"
            style={{
              color: 'white',
              textDecoration: 'underline',
              fontWeight: 400,
            }}
          >
            {translations.JNT_MpAlertsBeta_AnnouncementEndLink}
          </JATOLink>
        </div>
      </div>
    ),
  }

  const getCurrentAnnouncement = (): IAnnouncement | null => {
    const { betaStartDateForUser, betaEndDateForUser } = mpAlertsBeta ?? {}

    const now = moment()
    const start = moment(betaStartDateForUser)
    const end = moment(betaEndDateForUser)
    const midpoint = end.clone().subtract(28, 'days').toDate()
    const afterEnd = end
      .clone()
      .add(mpAlertsBetaAnnouncementDaysShowAfterEnd, 'days')
      .toDate()
    return now.isBetween(start, midpoint)
      ? announcementStart
      : now.isBetween(midpoint, end)
        ? announcementMid
        : now.isBetween(end, afterEnd)
          ? announcementEnd
          : null
  }

  const checkDates = (): boolean => {
    const startDate = mpAlertsBetaStartDate
    const endDate = mpAlertsBeta?.betaEndDateForUser ?? mpAlertsBetaEndDate

    return moment().isBetween(moment(startDate).startOf('day'), moment(endDate))
  }

  useEffect(() => {
    if (isEnabled) {
      const announcement = getCurrentAnnouncement()
      announcement && JATOAnnouncement.Create(announcement)
    } else {
      JATOAnnouncement.Delete(mpAlertsBetaAnnouncementId)
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'MpAlertsBetaToggle',
        mpAlertsBetaEnabled: isEnabled,
      },
    })
  }, [isEnabled])

  return mpAlertsBeta && checkDates() ? (
    <StyledMpAlertsBetaNavBarComponent>
      {mpAlertsBeta.isActivated && <MpAlertsBetaWelcomePopupLink />}
      <LoaderModal isOpen={isFetching} />
    </StyledMpAlertsBetaNavBarComponent>
  ) : (
    <></>
  )
}
