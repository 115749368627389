import React from 'react'
import { StyledSplashScreen } from './SplashScreen.styles'
import { splashScreenUrl } from 'config'

export const SplashScreen: React.FC = () => (
  <StyledSplashScreen>
    <a href={splashScreenUrl} target="_blank" rel="noreferrer">
      <div className="splashScreen" />
    </a>
  </StyledSplashScreen>
)
