import { JATOGroup, JATOText, JATOTextInput } from '@jato/ui-component-library'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsAlertName } from './NewsSearchAlertName.styles'

interface INewsSearchAlertNameProps {
  onChange: (value: string) => void
  defaultName: string
  label: string
}

export const NewsSearchAlertName: React.FC<INewsSearchAlertNameProps> = ({
  onChange,
  defaultName,
  label,
}: INewsSearchAlertNameProps) => {
  const translations = useAppSelector(getTranslations)

  const [name, setName] = useState(defaultName)

  const onAlertNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const alertName = event.currentTarget.value
    setName(alertName)
    onChange(alertName)
  }

  return (
    <StyledNewsAlertName>
      <JATOText className="textAlign" fontSize={14} as="p">
        {`${label}*`}:
      </JATOText>
      <JATOGroup flexDirection="row">
        <JATOTextInput
          id="alertNameField"
          width={656}
          placeholder={translations.JNT_EnterAlertName}
          onChange={onAlertNameChange}
          value={name}
        />
      </JATOGroup>
    </StyledNewsAlertName>
  )
}
