import {
  JATOButtonIcon,
  JATOLink,
  JATOLogo,
  JATONavBar,
  JATONavGroup,
  JATONavItem,
} from '@jato/ui-component-library'
// import deFlag from 'assets/icons/de.svg'
import { authRequired } from 'config'
// import esFlag from 'assets/icons/es.svg'
// import frFlag from 'assets/icons/fr.svg'
// import itFlag from 'assets/icons/it.svg'
// import Drawer, { Position } from 'components/Drawer'
import { HelpMenu } from 'components/HelpMenu'
import MpAlertsBetaNavBarComponent from 'components/MpAlertsBeta/MpAlertsBetaNavBarComponent'
import { useGetMpAlertsBetaState } from 'hooks/mpAlertsBeta'
import { useRootPathResolver } from 'hooks/useRootPathResolver'
import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutePaths } from 'router/RoutePaths'
import Theme from 'theme'
import {
  StyledHorizontalNav,
  StyledHorizontalNavAction,
  StyledHorizontalNavBrand,
  StyledHorizontalNavInner,
  StyledHorizontalNavItems,
} from './Header.styles'

export interface IRoute {
  id: string
  /** Route name/label */
  name: string
  /** Route path */
  path: string
  isActive: boolean
  badge?: Badge
}

export interface Badge {
  badgeText: string
  isActive: boolean
}

export interface IHeaderProps {
  /** Callback function to use when the user changes language */
  handleChangeLanguage: (language: string) => void
  /** Callback function to use when the user clicks the drawer button */
  handleToggleDrawer: () => void
  /** Determines if the user is authenticated */
  /** Determines if the drawer is open/closed */
  isDrawerOpen: boolean
  /** Currently selected language */
  language: string
  /** Navigation routes to display in the header */
  navRoutes: IRoute[]
  // navMmRoutes: IRoute[]
}

export const Header: React.FC<IHeaderProps> = ({
  handleToggleDrawer,
  navRoutes,
}: // navMmRoutes,
IHeaderProps) => {
  const isAuthenticated = true
  const history = useHistory()
  const location = useLocation()
  const { isNews } = useRootPathResolver()

  const handleLogoutClick = async (): Promise<void> => {
    const returnUrl = `/${location.pathname.split('/')[1]}`
    history.push(`${RoutePaths.Logout}?returnUrl=${returnUrl}`)
  }

  const disableLink = (navroute: IRoute): boolean =>
    (navroute.path.includes(RoutePaths.MonthlyPayments) &&
      location.pathname.includes(RoutePaths.MPNoLicense)) ||
    (navroute.path.includes(RoutePaths.Specifications) &&
      location.pathname.includes(RoutePaths.SpecsNoLicense)) ||
    (navroute.path.includes(RoutePaths.News) &&
      location.pathname.includes(RoutePaths.NewsNoLicense)) ||
    (navroute.path.includes(RoutePaths.Volumes) &&
      location.pathname.includes(RoutePaths.VolumesNoLicense))

  const { data: mpAlertsBeta } = useGetMpAlertsBetaState()

  return (
    <Fragment>
      {/* Nav bar */}
      <StyledHorizontalNav>
        <Container xl lg md sm xs style={{ height: '100%' }}>
          <Row style={{ height: '100%' }}>
            <Col xs={12} style={{ height: '100%' }}>
              <StyledHorizontalNavInner>
                <StyledHorizontalNavAction>
                  <JATOButtonIcon
                    iconName="custom_nav"
                    onClick={handleToggleDrawer}
                  />
                </StyledHorizontalNavAction>
                <StyledHorizontalNavBrand>
                  <JATOLink
                    id="headerLogoLink"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    href={navRoutes[0].path}
                  >
                    <JATOLogo
                      style={{
                        zIndex: Theme.zIndices.drawer - 1,
                      }}
                    />
                  </JATOLink>
                </StyledHorizontalNavBrand>
                <StyledHorizontalNavItems>
                  <JATONavBar
                    left={() => (
                      <JATONavGroup>
                        {(!authRequired || isAuthenticated) &&
                          navRoutes.length &&
                          navRoutes.map((navroute) => (
                            <JATONavItem
                              isActive={navroute.isActive}
                              key={`navrout-key${navroute.name}`}
                              disabled={disableLink(navroute)}
                            >
                              <div className="nav-item-container">
                                {navroute.badge?.isActive ? (
                                  <div className="new-badge">
                                    {navroute.badge.badgeText}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {disableLink(navroute) ? (
                                  <a>{navroute.name}</a>
                                ) : (
                                  <a id={navroute.id} href={navroute.path}>
                                    {navroute.name}
                                  </a>
                                )}
                              </div>
                            </JATONavItem>
                          ))}
                      </JATONavGroup>
                    )}
                    right={() => (
                      <div className="rightNavGroup">
                        {isNews && mpAlertsBeta?.isEligible && (
                          <MpAlertsBetaNavBarComponent />
                        )}
                        <JATOButtonIcon
                          id="headerLogoutButton"
                          iconName="logout_outline"
                          title="Logout"
                          onClick={handleLogoutClick}
                          style={{ margin: '-0 -12px -3px 0' }}
                        />
                        <HelpMenu />
                      </div>
                    )}
                  />
                </StyledHorizontalNavItems>
              </StyledHorizontalNavInner>
            </Col>
          </Row>
        </Container>
      </StyledHorizontalNav>
    </Fragment>
  )
}
