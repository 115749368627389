import {
  ColumnDirective,
  ColumnsDirective,
  DataStateChangeEventArgs,
  Grid,
  GridComponent,
  Inject,
  Resize,
  RowDataBoundEventArgs,
  RowDeselectEventArgs,
  RowSelectEventArgs,
  Selection,
  Sort,
  SortSettingsModel,
} from '@syncfusion/ej2-react-grids'
import Pagination from 'components/Pagination'
import { mpVehicleSelectionLimit } from 'config'
import { formatCurrency } from 'helper/formatNumberValues'
import { mapSorted, mapToColumns } from 'helper/sortDirectionMapper'
import { FilterNameType } from 'models/Filters/FilterNameType'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import { MpVehiclesResponse } from 'models/VehicleSelection/MpVehiclesResponse'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  addMpVehicle,
  mpVehicles,
  removeMpVehicle,
} from 'redux/monthlypayments/mpVehiclesSlice'
import {
  getOrderBy,
  getPageSize,
  getPageSkip,
  selectedFilters,
  setOrderBy,
  setPageSkip,
} from 'redux/monthlypayments/selectedFiltersSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledVehicleGrid } from './VehicleGrid.styles'

export interface IVehicleGridProps {
  MpVehiclesResponse: MpVehiclesResponse
}

export const VehicleGrid: React.FC<IVehicleGridProps> = (
  props: IVehicleGridProps
) => {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const selectedMpVehicleData: MpVehicle[] = useAppSelector(mpVehicles)
  const pageSkip = useAppSelector(getPageSkip)
  const pageSize = useAppSelector(getPageSize)
  const orderBy = useAppSelector(getOrderBy)
  const filtersData = useAppSelector(selectedFilters)

  let gridInstance: Grid | null
  const gridData = {
    result: props.MpVehiclesResponse.vehicles,
    count: props.MpVehiclesResponse.count,
  }
  const totalPages = Math.ceil(props.MpVehiclesResponse.count / pageSize)
  const currentPage = pageSkip / pageSize + 1

  useEffect(() => {
    if (gridInstance) {
      gridInstance.dataSource = {
        result: props.MpVehiclesResponse.vehicles,
        count: props.MpVehiclesResponse.count,
      }
    }
  })

  const sortingOptions: SortSettingsModel = {
    columns: orderBy.map(mapToColumns),
    allowUnsort: false,
  }

  const rowSelected = (row: RowSelectEventArgs): void => {
    if (row.isInteracted) {
      const mpVehicleData = row.data as MpVehicle
      if (selectedMpVehicleData.length + 1 === mpVehicleSelectionLimit) {
        if (gridInstance) gridInstance.refresh()
      }
      dispatch(addMpVehicle(mpVehicleData))
    }
  }

  const rowDeselected = (row: RowDeselectEventArgs): void => {
    if (row.isInteracted) {
      if (gridInstance) {
        const selectedRows = selectedMpVehicleData.length - 1
        if (selectedRows === mpVehicleSelectionLimit - 1) gridInstance.refresh()
      }
      dispatch(removeMpVehicle(row.data as MpVehicle))
    }
  }

  const dataStateChange = (args: DataStateChangeEventArgs): void => {
    if (args.action?.requestType === 'sorting') {
      const orderBy = args.sorted?.map(mapSorted).reverse() ?? []
      dispatch(setOrderBy(orderBy))
    }
  }

  const onPageChange = (page: number): void => {
    dispatch(setPageSkip((page - 1) * pageSize))
  }

  const dataBound = (): void => {
    if (gridInstance) {
      const rowIndexes: number[] = []
      gridInstance.currentViewData.forEach((row, index) => {
        const vehicle = row as MpVehicle
        if (
          selectedMpVehicleData.some((v) => v.vehicleId === vehicle.vehicleId)
        ) {
          rowIndexes.push(index)
        }
      })

      gridInstance.selectRows(rowIndexes)
    }
  }

  const rowDataBound = (row: RowDataBoundEventArgs): void => {
    const vehicleData = row.data as MpVehicle
    if (
      selectedMpVehicleData.length >= mpVehicleSelectionLimit &&
      !selectedMpVehicleData.some((v) => v.vehicleId === vehicleData.vehicleId)
    ) {
      row.isSelectable = false
    }
  }

  const setTooltip = (): void => {
    const header = document.querySelector('.e-gridheader')
    header?.setAttribute('title', translations.JNT_GridSortMoreClick)
  }

  const paginationComponent = (
    <Pagination
      id="monthlyPaymentsGridPagination"
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
    />
  )

  const getMonthlyPayment = (_field: string, data: object): string => {
    const { scenarios, country } = data as MpVehicle
    let prices = scenarios.map((s) => s.regularMonthlyInstalmentAmountMsrp)

    const filter = filtersData.find(
      (item) =>
        item.filterName === FilterNameType.RegularMonthlyInstalmentAmount
    )

    if (filter) {
      const min = filter.min ?? 0
      const max = filter.max ?? 0
      prices = prices.filter((value) => value >= min && value <= max)
    }

    const minPrice = formatCurrency(Math.min(...prices), country, 0)
    const maxPrice = formatCurrency(Math.max(...prices), country, 0)
    return `<b>${minPrice} - ${maxPrice}</b>`
  }

  return (
    <StyledVehicleGrid>
      <div className="ej-grid-header">
        <span id="multiVehicleResult" className="vehicleCountMessage">
          {translations.JNT_Result} : {props.MpVehiclesResponse.count}{' '}
          {translations.JNT_vehicles}
        </span>
      </div>
      {paginationComponent}
      <GridComponent
        id="monthlyPaymentsGrid"
        ref={(g) => (gridInstance = g)}
        dataSource={gridData}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
        allowSorting={true}
        allowSelection={true}
        allowResizing={true}
        sortSettings={sortingOptions}
        rowSelected={rowSelected}
        rowDeselected={rowDeselected}
        dataStateChange={dataStateChange}
        rowDataBound={rowDataBound}
        dataBound={dataBound}
        created={setTooltip}
      >
        <ColumnsDirective>
          <ColumnDirective type="checkbox" width="50" />
          <ColumnDirective
            field="vehicleId"
            isPrimaryKey={true}
            width="100"
            textAlign="Left"
            visible={false}
          />
          <ColumnDirective field="uniqueIdentity" width="100" visible={false} />
          <ColumnDirective
            field="make"
            headerText="Make"
            width="100"
            textAlign="Left"
          />
          <ColumnDirective
            field="model"
            headerText="Model"
            width="100"
            textAlign="Left"
          />
          <ColumnDirective
            field="scenarios/regularMonthlyInstalmentAmountMsrp"
            headerText="Monthly Payment"
            width="120"
            allowSorting={false}
            valueAccessor={getMonthlyPayment}
            disableHtmlEncode={false}
          />
          <ColumnDirective
            field="versionName"
            headerText="Version"
            width="200"
            textAlign="Left"
          />
          <ColumnDirective
            field="bodyTypeDisplayValue"
            headerText="Body Style"
            width="100"
            textAlign="Left"
          />
          <ColumnDirective
            field="fuelTypeDisplayValue"
            headerText="Fuel type"
            width="100"
            textAlign="Left"
          />
          <ColumnDirective
            field="litres"
            headerText="Engine Litres"
            width="100"
            textAlign="Left"
          />
          <ColumnDirective
            field="drivenWheelsDisplayValue"
            headerText="Driven Wheels"
            width="100"
            textAlign="Left"
          />
          <ColumnDirective
            field="priceDisplayValue"
            headerText="Price"
            width="100"
          />
        </ColumnsDirective>
        <Inject services={[Sort, Selection, Resize]} />
      </GridComponent>
      {paginationComponent}
    </StyledVehicleGrid>
  )
}
