import styled from 'styled-components'
import Theme from 'theme'

export const StyledAccordionToggle = styled.div`
  .accordion {
    background-color: ${Theme.colors.midDarkGrey};
    font-weight: 500;
    width: 100%;
    padding: 6px 0px;
    cursor: pointer;
  }

  .accordionTitle {
    position: sticky;
    left: 20px;
  }

  .accordionTitleIcon {
    display: inline-block;
  }

  .accordionTitleText {
    display: inline-block;
    padding-left: 8px;
  }
`
