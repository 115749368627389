import { JATOAnnouncement, JATOLink } from '@jato/ui-component-library'
import addVehicleIcon from 'assets/icons/icon_add_vehicle_fill.svg'
import newSelectionIcon from 'assets/icons/icon_new_selection.svg'
import exportFileIcon from 'assets/icons/icon_xml_file_fill.svg'
import { ImageButtonNavItem } from 'components/CommonNavItems/ImageButtonNavItem/ImageButtonNavItem'
import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import { BasketNavItem } from 'components/Specifications/Basket/BasketNavItem'

import { LoaderModal } from 'components/Loader/LoaderModal'
import {
  useExportToExcel,
  useResetBuiltVehiclesAndFilters,
} from 'hooks/carSpecs'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { SpecsReportNavMenu } from '../SpecsNavItems/SpecsReportNavMenu/SpecsReportNavMenu'

export const SpecsReportNavigation: React.FC = ({}) => {
  const history = useHistory()
  const translations = useAppSelector(getTranslations)
  const exportSuccessMessage = translations.JNT_DownLoad.split('.')

  const { mutateAsync: exportToExcelAsync, isLoading: isLoadingExcel } =
    useExportToExcel()

  const { mutateAsync: resetBuiltVehiclesAndFilters } =
    useResetBuiltVehiclesAndFilters()

  const showExportSuccessMessage = (url: string): void =>
    JATOAnnouncement.Create({
      title: '',
      children: (
        <div>
          {exportSuccessMessage[0]}
          <JATOLink style={{ color: 'white', paddingLeft: '10px' }} href={url}>
            {exportSuccessMessage[1]}
          </JATOLink>
        </div>
      ),
    })

  const showExportErrorMessage = (error: string): void =>
    JATOAnnouncement.Create({
      title: error,
    })

  const exportSpecsReportToExcel = async (): Promise<void> => {
    const result = await exportToExcelAsync()

    result.success && result.url
      ? showExportSuccessMessage(result.url)
      : showExportErrorMessage(result.error ?? 'Unknown error')
  }

  const newSelection = async (): Promise<void> => {
    await resetBuiltVehiclesAndFilters()
    addMoreVehicles()
  }

  const addMoreVehicles = (): void => {
    history.push(RoutePaths.Specifications)
  }

  return (
    <Fragment>
      <NavigationContainer
        left={
          <>
            <SpecsReportNavMenu />
          </>
        }
        right={
          <>
            <ImageButtonNavItem
              onClick={addMoreVehicles}
              id="specsReportAddVehicleButton"
              src={addVehicleIcon}
              title={translations.JNT_AddVeh}
            />
            <ImageButtonNavItem
              onClick={newSelection}
              id="specsReportNewSelectionButton"
              src={newSelectionIcon}
              title={translations.JNT_NewSel}
            />
            <BasketNavItem />
            <ImageButtonNavItem
              id="specsReportExcelExportButton"
              onClick={exportSpecsReportToExcel}
              src={exportFileIcon}
              title={translations.JNT_ExcelExport}
            />
          </>
        }
      />
      <LoaderModal isOpen={isLoadingExcel} />
    </Fragment>
  )
}
