import styled from 'styled-components'
import Theme from 'theme'

export const StyledMonthlyPaymentsReportBody = styled.div`
  .tableStyle {
    display: table;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    table-layout: fixed;
    width: 100%;
    border-spacing: unset;
  }

  .tableRow {
    display: table-row;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  .tableRow:nth-child(odd) > .tableCell {
    background-color: ${Theme.colors.ultraLightGrey};
  }

  .tableRow:nth-child(even) > .tableCell {
    background-color: ${Theme.colors.white};
  }

  .tableCell {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
    text-align: left;
    width: 220px;
  }

  .tableValue {
    padding: 2px 10px 1px;
  }

  .tableCell:first-child {
    width: 300px;
  }

  .sticky {
    position: sticky;
    left: 0;
  }
`
