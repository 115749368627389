import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsCard = styled.div`
  .newsCard {
    max-width: calc(25vw - 26.75px);
    height: 377px;
    border-radius: 6px;
  }

  .newsCard > div {
    padding: 0;
  }

  .newsCardHeader {
    padding: 16px 16px 0;
    display: flex;
    justify-content: space-between;
  }

  .newsCardMain {
    height: 270px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${JATOTheme.colors.black};
  }

  .newsCardTop {
    display: flex;
    flex-direction: column;
    max-height: 166px;
    overflow: hidden;
  }

  .newsHeadline {
    font-size: 28px;
    font-weight: ${JATOTheme.fontWeights.medium};
    letter-spacing: -0.5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
  }

  .newsSubHeadline {
    font-size: 14px;
    font-weight: ${JATOTheme.fontWeights.medium};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .newsCardBottom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 16px 0;
    padding-top: 24px;
    border-top: 1px solid ${JATOTheme.colors.midDarkGrey};
  }

  .newsCardBottom img {
    max-height: 75px;
    max-width: 120px;
    max-width: calc(12vw - 40px);
  }

  .newsCardFooter {
    height: 67px;
    display: flex;
    background-color: ${JATOTheme.colors.lightGrey};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  .newsCardFooterLink {
    font-size: 12px;
    font-weight: 500;
    color: ${JATOTheme.colors.black};
  }

  .newsCardFooterButton {
    text-transform: none;
    font-weight: ${JATOTheme.fontWeights.medium};
    background-color: ${JATOTheme.colors.midGrey};
    border-radius: 6px;
  }
`
