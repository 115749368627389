import styled from 'styled-components'

export const StyledVolumesStartStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0 60px;

  .block {
    display: flex;
    justify-content: space-between;
  }

  .block:nth-child(odd) {
    flex-direction: row;
  }

  .block:nth-child(even) {
    flex-direction: row-reverse;
  }

  .block:nth-child(even) button {
    align-self: flex-end;
  }

  .blockText {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .blockText h3 {
    flex-basis: auto;
  }

  .blockText div {
    height: 80px;
  }

  .blockText button {
    border-radius: 4px;
  }
`
