import {
  JATOButton,
  JATOButtonGroup,
  JATOHeading,
  JATOIcon,
  JATOModal,
  JATOText,
  JATOTheme
} from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { InfoPopupDetails, InfoPopupType } from 'models/InfoPopup/InfoPopup'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

interface IProps {
  id?: string
  infoPopupType: InfoPopupType
}

export const InfoPopup: React.FC<IProps> = ({
  id = 'infoPopup',
  infoPopupType,
}: IProps) => {
  const translations = useAppSelector(getTranslations)

  const infoPopupMapping: Record<InfoPopupType, InfoPopupDetails> = {
    [InfoPopupType.VehicleFilters]: {
      title: translations.JNT_VehicleFiltersHelpTitle,
      text: translations.JNT_VehicleFiltersHelpText,
    },
    [InfoPopupType.Showroom]: {
      title: translations.JNT_ShowroomHelpTitle,
      text: translations.JNT_ShowroomHelpText,
    },
    [InfoPopupType.MonthlyPaymentsFilters]: {
      title: translations.JNT_MonthlyPaymentsFiltersTipTitle,
      text: translations.JNT_MonthlyPaymentsFiltersTipText,
    },
  }

  const popup = infoPopupMapping[infoPopupType]
  const cacheStateName = `${infoPopupType}DoNotShowAgain`

  const [showInfoTip, setShowInfoTip] = useState(
    localStorage.getItem(cacheStateName) !== 'true'
  )

  const onCheckboxChange = (): void => {
    localStorage.setItem(cacheStateName, 'true')

    setShowInfoTip(false)
  }

  const onCloseButton = (): void => {
    setShowInfoTip(false)
  }

  return (
    <JATOModal
      isOpen={showInfoTip}
      maxWidth={'sm'}
      header={() => (
        <>
          <JATOHeading as="h4" fontWeight="medium" display="flex">
            {popup.title}
          </JATOHeading>
          <JATOIcon style={{ paddingLeft: '10px' }} iconName="baseline_info" />
        </>
      )}
      footer={() => (
        <>
          <JATOButtonGroup justifyContent={'space-between'}>
            <CheckboxWithGtm
              checked={!showInfoTip}
              onChange={onCheckboxChange}
              id={id + 'DoNotShowAgainCheckbox'}
              label={translations.JNT_DoNotShowMeThisTipAgain}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {translations.JNT_DoNotShowMeThisTipAgain}
              </JATOText>
            </CheckboxWithGtm>
            <JATOButton
              id={id + 'CloseButton'}
              variant="primary"
              size="large"
              onClick={onCloseButton}
            >
              {translations.JNT_Close}
            </JATOButton>
          </JATOButtonGroup>
        </>
      )}
    >
      <div style={{ whiteSpace: 'pre-wrap' }}>{popup.text}</div>
    </JATOModal>
  )
}
