import SpecificationsReport from 'components/Specifications/SpecificationsReport'
import { SpecsReportNavigation } from 'components/Specifications/SpecificationsReport/SpecsReportNavigation'

import React from 'react'
import { Col, Container, Row } from 'react-grid-system'

import { StyledSpecificationsReport } from './SpecificationsReportContainer.styles'

export const SpecificationsReportContainer: React.FC = () => (
  <StyledSpecificationsReport>
    <Container xl lg md sm xs style={{ padding: 0 }}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <SpecsReportNavigation />
          <div className="ej-specsreport-panel">
            <SpecificationsReport />
          </div>
        </Col>
      </Row>
    </Container>
  </StyledSpecificationsReport>
)
