import { AxiosResponse } from 'axios'
import { LoginRequest } from 'models/Login/LoginRequest'
import { LoginResponse } from 'models/Login/LoginResponse'
import { mpBaseApi } from './api'

class AuthService {
  public getJatonetUserData(
    loginRequest: LoginRequest
  ): Promise<AxiosResponse<LoginResponse>> {
    const userData = mpBaseApi.post<LoginRequest, AxiosResponse<LoginResponse>>(
      `/api/LoginManager/login`,
      loginRequest
    )
    return userData
  }
}

const authService = new AuthService()
export default authService
