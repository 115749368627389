import { customGridFields, customGridFieldsReverse } from 'config'

export const mapToValueColumnName = (
  displayValueColumnName?: string
): string | undefined =>
  (displayValueColumnName && customGridFields[displayValueColumnName]) ??
  displayValueColumnName

export const mapToDisplayValueColumnName = (
  valueColumnName?: string
): string | undefined =>
  (valueColumnName && customGridFieldsReverse[valueColumnName]) ??
  valueColumnName
