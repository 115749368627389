import { JATOButtonIcon, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { resetMpVehicles } from 'redux/monthlypayments/mpVehiclesSlice'
import { resetFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import { selectUserData } from 'redux/monthlypayments/userDataSlice'
import { RoutePaths } from 'router/RoutePaths'

export const ResetMonthlyPaymentsNavItem: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const userData = useAppSelector(selectUserData)

  const onResetFilters = (): void => {
    dispatch(resetFilters(userData.user.settings.lastSelectedMarket))
    dispatch(resetMpVehicles(false))

    history.push(RoutePaths.MonthlyPayments)
  }

  return (
    <JATOButtonIcon
      id="monthlyPaymentsResetButton"
      title="Reset Filters and Selected Vehicles"
      className="reset-filters-icon"
      iconName="baseline_autorenew"
      iconFill={JATOTheme.colors.darkGrey}
      onClick={onResetFilters}
    />
  )
}
