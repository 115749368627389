import { JATOButtonIcon, JATOTheme } from '@jato/ui-component-library'
import { getTranslations } from 'redux/translations/translationsSlice'

import { ISpecsNavItemProps } from 'components/Specifications/SpecsNavigation/SpecsNavigation'
import React from 'react'
import { useAppSelector } from 'redux/hook'

export const SpecsUserSettingsNavItem: React.FC<ISpecsNavItemProps> = ({
  onChangeActive,
  isActive,
}: ISpecsNavItemProps) => {
  const translations = useAppSelector(getTranslations)

  return (
    <JATOButtonIcon
      id="specsUserSettingsButton"
      title={translations?.JNT_userset}
      iconName="custom_feature"
      onClick={() => onChangeActive(!isActive)}
      iconFill={isActive ? JATOTheme.colors.primary : undefined}
    />
  )
}
