import { JATOBasicCard, JATOButton } from '@jato/ui-component-library'
import { Flag } from 'components/Flag/Flag'
import Loader from 'components/Loader'
import { InfoPopup } from 'components/Modals/InfoPopup/InfoPopup'
import { SpecsJATOAcademyPopUp } from 'components/Modals/SpecsJATOAcademyPopUp/SpecsJATOAcademyPopUp'
import { WelcomePopup } from 'components/Modals/WelcomePopup/WelcomePopup'
import SelectedFilters from 'components/MonthlyPayments/SelectedFilter'
import { NavigationContainer } from 'components/NavigationContainer/NavigationContainer'
import { SpecsSettings } from 'components/Specifications/Settings/SpecsSettings'
import { SpecFilters } from 'components/Specifications/SpecFilters'
import { SpecificationsGrid } from 'components/Specifications/SpecificationsGrid'
import { SpecsAdvanceFiltersContainer } from 'components/Specifications/SpecsAdvanceFilter/SpecsAdvanceFiltersContainer'
import { SpecsNavigation } from 'components/Specifications/SpecsNavigation/SpecsNavigation'
import { getCountryCodeFromMarket } from 'helper/countryCodeMapper'
import {
  gtmLogSpecsAdvancedFiltersChange,
  gtmLogSpecsFiltersChange,
} from 'helper/gtm'
import {
  getKeyTextFilters,
  getRangeFilters,
  mapToLocal,
  specFilterNamesToFacetFields,
  specificationsFilters,
} from 'helper/specificationsFiltersHelper'
import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import { FilterParentType, FilterType } from 'models/Filters/FilterType'
import { InfoPopupType } from 'models/InfoPopup/InfoPopup'
import { GetVehiclesRequest } from 'models/Specifications/GetVehiclesRequest'
import { GetVehiclesResponse } from 'models/Specifications/GetVehiclesResponse'
import { VehicleSpecification } from 'models/Specifications/VehicleSpecification'
import { SpecsUserShipLog } from 'models/UserShipLog/UserShipLog'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import {
  getAdvancedFilters,
  getCombinedAdvancedFilters,
  getSpecFilters,
  getSpecsUserState,
  getSpecsVehicles,
  setAdvancedFilters,
  setSpecFilters,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import specificationsService from 'services/Specifications/SpecificationsService'
import userShipLogService from 'services/UserShipLog/UserShipLogService'
import { StyledSpecifications } from './Specifications.styles'

export enum SpecsSidePanels {
  SpecsFilters = 1,
  AdvancedFilters = 2,
  UserSettings = 3,
}

export const Specifications: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const hideFilters = Boolean(
    JSON.parse(searchParams.get('hideFilters') ?? 'true')
  )

  const commonUserData = useAppSelector(selectCommonUserData)

  const specFilters = useAppSelector(getSpecFilters)
  const specsUserState = useAppSelector(getSpecsUserState)
  const specsVehicles = useAppSelector(getSpecsVehicles)
  const selectedVehicles = specsVehicles.selectedVehicles

  const specsAdvancedFilters = useAppSelector(getAdvancedFilters)
  const combinedAdvancedFilters = useAppSelector(getCombinedAdvancedFilters)
  const translations = useAppSelector(getTranslations)
  const isLocal = specsUserState.settings.isLocalOptions
  const market = specsUserState?.settings?.lastSelectedMarket
  const marketKeyText = specsUserState?.specsDbMarkets?.find(
    (m) => m.key === market
  )

  const getVehiclesRequest: GetVehiclesRequest = {
    guid: commonUserData.guid,
    languageId: commonUserData.languageId,
    isAggregation: true,

    pageSize: specFilters.pageSize,
    pageNumber: specFilters.pageSkip / specFilters.pageSize,
    orderBy: isLocal ? mapToLocal(specFilters.orderBy) : specFilters.orderBy,

    specsDbList: market ? [{ key: `${market}` }] : [],
    advanceFilters: combinedAdvancedFilters,

    ...getKeyTextFilters(specFilters.filters),
    ...getRangeFilters(specFilters.filters),
  }

  const { data: vehiclesResponse, isFetching } = useQuery<GetVehiclesResponse>(
    [
      'getVehiclesForSpecs',
      specFilters,
      specsUserState,
      specsAdvancedFilters.filters,
    ],
    () => specificationsService.getVehicles(getVehiclesRequest),
    { enabled: !!specsUserState.guid }
  )

  const specFiltersFlat = hideFilters
    ? Object.values(specFilters.filters)
        .flat()
        .filter((f) => {
          if (
            specificationsFilters.find(
              (sf) => sf.name === f.filterName && sf.type === FilterType.Slider
            )
          )
            return true

          const fieldName = specFilterNamesToFacetFields[f.filterName]
          const facets =
            vehiclesResponse?.facets &&
            vehiclesResponse?.facets[fieldName].map((f) => f.key)

          return facets?.includes(f.value) ?? false
        })
    : Object.values(specFilters.filters).flat()

  const advancedFiltersFlat = Object.values(specsAdvancedFilters.filters).flat()
  const selectedFilters = specFiltersFlat.concat(advancedFiltersFlat)

  const { mutate: logSpecificationsClick } = useMutation(
    (log: SpecsUserShipLog) =>
      userShipLogService.logSpecsViewSelectedVersionClick(log)
  )

  function logSpecificationsSelectedVersion(): void {
    const vehicles = specsVehicles.selectedVehicles.map(
      (vehicleSpecs: VehicleSpecification) => vehicleSpecs.vehicleId
    )
    logSpecificationsClick({
      user: specsUserState,
      vehicles: vehicles,
    })
  }

  const viewSelectionVersion = (): void => {
    logSpecificationsSelectedVersion()
    history.push(RoutePaths.SpecsShowroom)
  }

  const handleRemoveFilter = (filter: ISelectedFilterOption): void => {
    switch (filter.filterParentType) {
      case FilterParentType.Specifications:
        return handleRemoveSpecFilter(filter)
      case FilterParentType.SpecAdvancedFilters:
        return handleRemoveAdvancedFilter(filter)
    }
  }

  const handleRemoveSpecFilter = (filter: ISelectedFilterOption): void => {
    const options =
      specFilters.filters[filter.filterName]?.filter(
        (f) => f.value !== filter.value
      ) ?? []

    const updatedFilters = {
      ...specFilters.filters,
      [filter.filterName]: options,
    }

    const specFiltersUpdate = {
      ...specFilters,
      filters: updatedFilters,
      pageSkip: 0, //reset to first page when filters changed
      cachedOptions: [],
    }
    dispatch(setSpecFilters(specFiltersUpdate))

    gtmLogSpecsFiltersChange(updatedFilters)
  }

  const handleRemoveAdvancedFilter = (filter: ISelectedFilterOption): void => {
    const options =
      specsAdvancedFilters.filters[filter.filterName]?.filter(
        (f) => f.value !== filter.value
      ) ?? []

    const updatedFilters = {
      ...specsAdvancedFilters.filters,
      [filter.filterName]: options,
    }

    const specAdvancedFiltersUpdate = {
      ...specsAdvancedFilters,
      filters: updatedFilters,
    }
    dispatch(setAdvancedFilters(specAdvancedFiltersUpdate))

    gtmLogSpecsAdvancedFiltersChange(updatedFilters)
  }

  const [activePanel, setActivePanel] = useState(SpecsSidePanels.SpecsFilters)

  const showUserSettings = activePanel === SpecsSidePanels.UserSettings
  const showAdvFilters = activePanel === SpecsSidePanels.AdvancedFilters
  const showSpecFilters =
    activePanel === SpecsSidePanels.SpecsFilters ||
    activePanel === SpecsSidePanels.AdvancedFilters

  return (
    <StyledSpecifications>
      <Container xl lg md sm xs style={{ padding: 0 }}>
        <Row>
          <Col md={3} lg={3} style={{ padding: '0 10px', minHeight: '400' }}>
            <SpecsNavigation
              activePanel={activePanel}
              setActivePanel={setActivePanel}
            />
            <div className="ej-panel scrollable-container">
              {showUserSettings && (
                <SpecsSettings
                  onClose={() => setActivePanel(SpecsSidePanels.SpecsFilters)}
                />
              )}
              {showAdvFilters && <SpecsAdvanceFiltersContainer />}
              {showSpecFilters && (
                <JATOBasicCard>
                  <SpecFilters
                    facetsData={vehiclesResponse?.facets}
                    rangeFilters={vehiclesResponse?.rangeFilters}
                  />
                </JATOBasicCard>
              )}
            </div>
          </Col>
          <Col md={9} lg={9} style={{ padding: 0 }}>
            <NavigationContainer
              left={
                market && (
                  <Flag
                    countryCode={getCountryCodeFromMarket(market)}
                    countryDescription={marketKeyText?.text}
                  />
                )
              }
              right={
                <JATOButton
                  size="medium"
                  onClick={() => viewSelectionVersion()}
                  disabled={selectedVehicles?.length === 0}
                  id="specsViewSelectedVersionButton"
                >
                  {translations.JNT_VSelVersion}
                </JATOButton>
              }
            />
            <div className="ej-panel scrollable-container" id="ej-panel">
              <Container xl lg md sm xs style={{ padding: '0 20px' }}>
                <Row>
                  <Col>
                    <SelectedFilters
                      id="specsSelectedFilters"
                      selectedFilters={selectedFilters}
                      handleRemoveFilter={handleRemoveFilter}
                    />
                  </Col>
                </Row>
              </Container>
              <Container xl lg md sm xs style={{ padding: '0 20px 3px' }}>
                <Row>
                  <Col md={12}>
                    {isFetching ? (
                      <Loader />
                    ) : (
                      vehiclesResponse && (
                        <div id="vehicle-grid-container">
                          <SpecificationsGrid
                            items={vehiclesResponse.results}
                            totalCount={vehiclesResponse.total}
                          />
                        </div>
                      )
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <InfoPopup
        id="specsVehicleFiltersInfoPopup"
        infoPopupType={InfoPopupType.VehicleFilters}
      />
      <WelcomePopup />
      <SpecsJATOAcademyPopUp />
    </StyledSpecifications>
  )
}
