import { LoaderModal } from 'components/Loader/LoaderModal'
import { IMappingObject } from 'helper/IMappingObject'
import {
  NewsFilterName,
  defaultToDate,
  twelveMonthFromDateOption,
} from 'helper/newsHelper'
import { useSearchById, useUpdateSearchAndAlert } from 'hooks/news'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch } from 'redux/hook'
import { setNewsSearchState } from 'redux/news/newsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledNewsSearchById } from './NewsSearchById.styles'

export const NewsSearchById: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { data: searchRequest, isFetching } = useSearchById(id)
  const { mutate: updateSearchAndAlert } = useUpdateSearchAndAlert()

  const loadSearchResults = (request: SearchIndexRequest): void => {
    const searchFields: IMappingObject<string[]> = {
      [NewsFilterName.SearchText]: [request.searchQuery],
      [NewsFilterName.Category]: request.subjectCategoryCode
        ? request.subjectCategoryCode.split(',')
        : [],
      [NewsFilterName.Subjects]: request.subjectList,
      [NewsFilterName.Country]: request.countryList,
      [NewsFilterName.Make]: request.makeList,
      [NewsFilterName.Model]: request.modelList,
      [NewsFilterName.ReleaseDate]: [
        JSON.stringify([twelveMonthFromDateOption(), defaultToDate()]),
      ],
      [NewsFilterName.Group]: request.vehicleTypeList,
    }

    updateSearchAndAlert({
      ...request,
      loadCount: (request.loadCount ?? 0) + 1,
      lastRun: new Date().toISOString(),
    })

    const { searchQuery, isExactSearch } = request
    dispatch(
      setNewsSearchState({
        searchFields: searchFields,
        isExactMatch: request.isExactSearch,
        orderBy: searchQuery && !isExactSearch ? [] : ['releaseDate desc'],
        currentPage: 1,
      })
    )

    history.push(RoutePaths.NewsSearchResults)
  }

  useEffect(() => {
    if (searchRequest) {
      loadSearchResults(searchRequest)
    }
  }, [searchRequest])

  return (
    <StyledNewsSearchById>
      <LoaderModal isOpen={isFetching} />
    </StyledNewsSearchById>
  )
}
