import { createToast } from '@jato/ui-component-library'

export const showInfoToast = (title: string, detail: string): void =>
  createToast({
    title: title,
    detail: detail,
    iconName: 'baseline_info',
    variant: 'default',
    isDismissible: true,
  })

export const showSuccessToast = (title: string, detail: string): void =>
  createToast({
    title: title,
    detail: detail,
    iconName: 'baseline_info',
    variant: 'success',
    isDismissible: true,
  })

export const showErrorToast = (title: string, detail: string): void =>
  createToast({
    title: title,
    detail: detail,
    iconName: 'baseline_error',
    variant: 'error',
    isDismissible: true,
  })
