import { JATOButton, JATOIcon } from '@jato/ui-component-library'
import { NewsPage } from 'models/News/NewsPageType'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getNewsSelectedArticles } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledNewsViewSelection } from './NewsViewSelection.styles'

export const NewsViewSelection: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>()
  const history = useHistory()
  const translations = useAppSelector(getTranslations)
  const selectedArticles = useAppSelector(getNewsSelectedArticles)
  const currentIndex = selectedArticles.findIndex((id) => id === +articleId)

  const onPreviousClick = (): void => {
    const articleId = selectedArticles[currentIndex - 1]
    history.push(
      `${RoutePaths.NewsArticles}/${NewsPage.ViewSelection}/${articleId}`
    )
  }

  const onNextClick = (): void => {
    const articleId = selectedArticles[currentIndex + 1]
    history.push(
      `${RoutePaths.NewsArticles}/${NewsPage.ViewSelection}/${articleId}`
    )
  }

  return (
    <StyledNewsViewSelection>
      <JATOButton
        id="newsViewSelectionPreviousButton"
        variant="ghost"
        disabled={currentIndex <= 0}
        onClick={onPreviousClick}
      >
        <JATOIcon iconName="baseline_keyboard_arrow_left" />
        {translations.JNT_PagingPreviousText}
      </JATOButton>
      <JATOButton
        id="newsViewSelectionNextButton"
        variant="ghost"
        disabled={currentIndex === selectedArticles.length - 1}
        onClick={onNextClick}
      >
        {translations.JNT_PagingNextText}
        <JATOIcon iconName="baseline_keyboard_arrow_right" />
      </JATOButton>
    </StyledNewsViewSelection>
  )
}
