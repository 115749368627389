import { createGlobalStyle } from 'styled-components'
import Theme from 'theme'

export const GlobalStyle = createGlobalStyle`
  :root {
    /* Mobile header height is 64px */
    --app-header-height: ${Theme.space[4] * 8}px;
    @media (min-width: ${Theme.breakpoints[2]}px) {
      /* Desktop header height is 80px  */
      --app-header-height: ${Theme.space[2] * 10}px;
    }
  }
  html {
    scroll-behavior: smooth;
  }
  body {
    background-color: ${Theme.colors.ultraLightGrey};
    font-family: ${Theme.fonts.body};
    margin: 0;
  }
`
