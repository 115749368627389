import styled from 'styled-components'

export const StyledWltpErrors = styled.div`
  .errorHeading {
    border-bottom: 2px solid #ddd;
    padding: 8px;
    font-weight: 500;
  }

  .errorMessage {
    padding: 8px;
  }
`
