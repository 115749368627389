import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsArticle = styled.div`
  .newsDetailHeadline {
    color: #0a3673;
    font-family: Roboto;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: -0.022em;
  }
  .newsDetailHeading4 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.017em;
  }
  .newsDetailSynposis {
    color: #0a3673;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.017em;
  }

  .table td,
  .table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .table tr:nth-child(even) {
    background-color: #fcfcfc;
  }

  .table th {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .incentives_daily_table,
  .incentives_event_table,
  .incentives_table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: transparent;
  }

  .incentives_daily_row_center_content {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    vertical-align: middle;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0px;
  }

  .incentives_daily_row_left_content {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-weight: 700;
    vertical-align: middle;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Roboto;
    text-align: center;
  }

  .incentives_daily_model {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-weight: 700;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Roboto;
    background-color: ${JATOTheme.colors.midGrey};
    text-align: center;
  }

  .incentives_daily_row_center_heading {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-weight: 700;
    vertical-align: middle;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-family: Roboto;
    background-color: ${JATOTheme.colors.midGrey};
    text-align: center;
  }

  .incentives_daily_row_left_heading {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-weight: 700;
    vertical-align: middle;
    border-left: 1px solid #ddd;
    width: 20%;
    border-bottom: 1px solid #ddd;
    font-family: Roboto;
    background-color: #f9f9f9;
    background-color: ${JATOTheme.colors.midGrey};
    text-align: center;
  }

  .newsCategoryAndArticleActions {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
`
