import styled from 'styled-components'

export const StyledNewsAddNewAlertButton = styled.div`
  .newAlertButton {
    gap: 5px;
    border-radius: 5px;
  }

  .newAlertDiv {
    display: flex;
    justify-content: flex-end;
  }
`
