import axios from 'axios'
import { baseSpecsAPIUrl, exportMicroserviceUrl, mpbaseAPIUrlNew } from 'config'
import { logError } from 'helper/loggingService'
import { RoutePaths } from 'router/RoutePaths'

// !!! IMPORTANT !!!
// Anything using redux/store should be put in separate file, i.e. 'apiWithAuth.ts'
// Importing redux/store here creates circular dependencies and errors during store initialization

const mpBaseApi = axios.create({
  baseURL: mpbaseAPIUrlNew,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

mpBaseApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    logError(err.response)

    if (err.response && err.response.status === 401) {
      window.location.href =
        `${process.env.PUBLIC_URL}${RoutePaths.Autologout}` +
        (window.location.pathname.includes(RoutePaths.MonthlyPayments)
          ? `?returnUrl=${RoutePaths.MonthlyPayments}`
          : '')
    }

    return Promise.reject({
      message: err.response?.data?.error ?? err.response?.statusText,
      status: err.response?.status,
    })
  }
)
const specificationsApi = axios.create({
  baseURL: baseSpecsAPIUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

specificationsApi.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (err.response.status === 401) {
      window.location.href = `${process.env.PUBLIC_URL}${RoutePaths.Autologout}?returnUrl=${RoutePaths.Specifications}`
    }
  }
)

const microserviceApi = axios.create({
  baseURL: exportMicroserviceUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

export { microserviceApi, mpBaseApi, specificationsApi }

