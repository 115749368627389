import { JATOTheme } from '@jato/ui-component-library'
// import '@syncfusion/ej2-base/styles/material.css'
// import '@syncfusion/ej2-buttons/styles/material.css'
// import '@syncfusion/ej2-inputs/styles/material.css'
// import '@syncfusion/ej2-popups/styles/material.css'
// import '@syncfusion/ej2-react-calendars/styles/material.css'
import styled from 'styled-components'

export interface IDatePickerStyles {
  isDisabled?: boolean
}

export const StyledDatePicker = styled.span<IDatePickerStyles>(
  {
    color: JATOTheme.colors.darkGrey,
    position: 'relative',
    '.calendar-icon': {
      position: 'absolute',
      color: JATOTheme.colors.darkGrey,
      right: '5px',
    },
    '.datePicker': {
      display: 'block',
      boxSizing: 'border-box',
      color: `${JATOTheme.colors.darkGrey} !important`,
      fontFamily: `${JATOTheme.fonts.body} !important`,
      border: `${JATOTheme.borderWidths[0]}px solid ${JATOTheme.colors.midGrey} !important`,
      borderRadius: `${JATOTheme.radii[1]}px !important`,
      backgroundColor: `${JATOTheme.colors.white} !important`,
      padding: '5px 16px !important',
      fontSize: `${JATOTheme.fontSizes[1]}px !important`,
      lineHeight: '2.5 !important',
    },

    '.e-input-group-icon.e-date-icon, .e-control-wrapper .e-input-group-icon.e-date-icon, e-clear-icon, .e-float-input .e-clear-icon, .e-float-input.e-control-wrapper .e-clear-icon, .e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon':
      {
        display: 'none',
      },
    '.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after':
      {
        background: 'none',
      },
    '.e-input-group:not(.e-float-icon-left), input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left)':
      {
        borderBottom: 'none',
      },
    'input.e-input::selection, textarea.e-input::selection, .e-input-group input.e-input::selection, .e-input-group.e-control-wrapper input.e-input::selection, .e-float-input input::selection, .e-float-input.e-control-wrapper input::selection, .e-input-group textarea.e-input::selection, .e-input-group.e-control-wrapper textarea.e-input::selection, .e-float-input textarea::selection, .e-float-input.e-control-wrapper textarea::selection':
      {
        background: JATOTheme.colors.darkGrey,
      },
  },
  ({ isDisabled }: IDatePickerStyles) => ({
    opacity: isDisabled ? 0.3 : 1,
  })
)

export const StyledLabel = styled.label({
  ':hover': {
    '.react-datepicker__input-container': {
      boxShadow: `${JATOTheme.shadows[1]} !important`,
    },
    '.datePicker': {
      boxShadow: `${JATOTheme.shadows[1]} !important`,
    },
  },
})
