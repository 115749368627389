import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'
import Theme from 'theme'

export const StyledDrawerHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  & + .profile {
    h5 {
      font-size: ${Theme.fontSizes[1]}px !important;
    }
  }
`

export const StyledHorizontalNav = styled.header`
  background-color: ${Theme.colors.white};
  border-bottom: 1px solid ${Theme.colors.grey};
  height: var(--app-header-height);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${Theme.zIndices.header};
`

export const StyledHorizontalNavAction = styled.div`
  height: ${Theme.space[4]}px;
  margin-right: ${Theme.space[2]}px;
  width: ${Theme.space[4]}px;
  .jato-button-icon {
    margin: 0;
  }
`

export const StyledHorizontalNavBrand = styled.div`
  display: flex;
  margin-right: ${Theme.space[4]}px;
  @media (min-width: ${Theme.breakpoints[2]}px) {
    align-self: flex-end;
    margin-bottom: ${Theme.space[1] * 7}px;
  }
`

export const StyledHorizontalNavInner = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;

  .rightNavGroup {
    display: flex;
    align-items: end;
    gap: 20px;
  }
`
