import React from 'react'
import { StyledForgotPasswordLink } from './ForgotPasswordLink.styles'
import {
  JATOGroup,
  JATOTextInput,
  JATOText,
  JATOTheme,
  JATOButton,
  JATOAnnouncement,
} from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { getTranslations } from 'redux/translations/translationsSlice'
import { useAppSelector } from 'redux/hook'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ForgotPasswordLinkRequest } from 'models/Portal/ForgotPassword/ForgotPasswordModel'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useGetPasswordResetLink } from 'hooks/portal'

export interface IForgotPasswordLinkProps {
  onClose: () => void
}

export const ForgotPasswordLink: React.FC<IForgotPasswordLinkProps> = ({
  onClose,
}: IForgotPasswordLinkProps) => {
  const translations = useAppSelector(getTranslations)
  const { languageId } = useAppSelector(selectCommonUserData)
  const { mutateAsync: getPasswordResetLink } = useGetPasswordResetLink()

  const formik = useFormik<ForgotPasswordLinkRequest>({
    initialValues: {
      username: '',
      languageId: languageId,
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .email(translations.JNT_Portal_Form_Email_Field_Required),
    }),
    onSubmit: async (values: ForgotPasswordLinkRequest) => {
      console.log(values)
      const passwordResetLinkResponse = await getPasswordResetLink(values)
      if (passwordResetLinkResponse.isSuccess) {
        JATOAnnouncement.Create({
          title: '',
          children: (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  translations.JNT_Portal_ForgotPassword_Confirm_Message.replace(
                    '<br/>',
                    ''
                  ),
              }}
            />
          ),
        })

        onClose()
      }
    },
  })

  return (
    <StyledForgotPasswordLink>
      <>
        {formik.isSubmitting && <LoaderModal isOpen={true} />}
        <div
          style={{ paddingBottom: '30px' }}
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Portal_ForgotPass_Desc,
          }}
        />
        <form onSubmit={formik.handleSubmit}>
          <JATOGroup flexDirection="row">
            <div>
              <JATOTextInput
                id="username"
                name="username"
                label={translations.JNT_Portal_CustReg_Email}
                title={translations.JNT_Portal_CustReg_Email}
                type="text"
                width={400}
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              {formik.touched.username && formik.errors.username ? (
                <JATOText
                  as="span"
                  fontSize={JATOTheme.fontSizes[1]}
                  color={JATOTheme.colors.primary}
                  marginTop={JATOTheme.space[2]}
                >
                  {formik.errors.username}
                </JATOText>
              ) : null}
            </div>
          </JATOGroup>
          <JATOGroup flexDirection="row" style={{ justifyContent: 'flex-end' }}>
            <JATOButton
              id="btnForgotPassword"
              size="large"
              variant="primary"
              type="submit"
              disabled={!(formik.dirty && formik.isValid)}
            >
              {translations.JNT_Portal_Sales_btnSubmit}
            </JATOButton>
          </JATOGroup>
        </form>
      </>
    </StyledForgotPasswordLink>
  )
}
