import React from 'react'
import { StyledLanguageSelection } from './LanguageSelection.styles'
import { JATOSelect } from '@jato/ui-component-library'
import { useGetLanguageAndCurrency } from 'hooks/portal'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  selectCommonUserData,
  setCommonUserState,
} from 'redux/commonUserData/commonUserDataSlice'

export const LanguageSelection: React.FC = () => {
  const dispatch = useAppDispatch()
  const commonUserData = useAppSelector(selectCommonUserData)

  const { data: languageData, isLoading } = useGetLanguageAndCurrency()

  const onChange = (languageId: number): void => {
    dispatch(setCommonUserState({ ...commonUserData, languageId: languageId }))
  }
  return (
    <StyledLanguageSelection>
      {!isLoading && (
        <JATOSelect
          id={'portalLanguage'}
          width={'250px'}
          dataSrc={
            languageData?.languageList !== undefined
              ? languageData?.languageList.map((l) => ({
                  value: l.id.toString(),
                  displayValue: l.language,
                }))
              : []
          }
          value={commonUserData.languageId.toString()}
          onChange={(value) => onChange(Number(value))}
          isMultiSelect={false}
          clearAfterSelect={true}
        />
      )}
    </StyledLanguageSelection>
  )
}
