import { IFilterOption } from 'models/Filters/FilterOption'

const defaultCountryCode = 'GB'
const defaultCountryName = 'UK'

export const countryCodeMapper = (jaasCountryCode?: string): string => {
  let jatonetCountryCode = ''
  switch (jaasCountryCode) {
    case 'GB':
      jatonetCountryCode = 'GB'
      break
    case 'IT':
      jatonetCountryCode = 'I'
      break
    case 'FR':
      jatonetCountryCode = 'F'
      break
    case 'DE':
      jatonetCountryCode = 'D'
      break
    case 'ES':
      jatonetCountryCode = 'E'
      break
    case 'BE':
      jatonetCountryCode = 'B'
      break
    case 'NL':
      jatonetCountryCode = 'NL'
      break
    default:
      jatonetCountryCode = defaultCountryCode
  }

  return jatonetCountryCode
}

export const getCountryCode = (countryOption?: IFilterOption): string =>
  countryCodeMapper(countryOption?.value)

export const getCountryDescription = (countryOption?: IFilterOption): string =>
  `${countryOption?.displayValue ?? defaultCountryName} Cars`

export const getCountryCodeFromMarket = (market: string): string =>
  market.slice(3, market.indexOf('_'))
