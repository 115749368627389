import {
  JATOButton,
  JATOHeading,
  JATOIcon,
  JATOModal,
  JATOText,
  JATOTheme,
} from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { useUpdateHideMonthlyPaymentsPopup } from 'hooks/news'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getNewsUserState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'

export const NewsMonthlyPaymentsPopup: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const {
    userSpecificSettings: { hideMonthlyPaymentsPopup },
  } = useAppSelector(getNewsUserState)

  const { mutate: updateHideMonthlyPaymentsPopup } =
    useUpdateHideMonthlyPaymentsPopup()

  const onCloseButton = (): void => {
    doNotShowAgain && updateHideMonthlyPaymentsPopup(true)
  }

  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  return (
    <JATOModal
      isOpen={!hideMonthlyPaymentsPopup}
      maxWidth={'lg'}
      dismissable={false}
      header={() => (
        <>
          <JATOHeading as="h3" fontWeight="medium" display="flex">
            {translations.JNT_News_MP_Disclaimer_Title}
          </JATOHeading>
          <JATOIcon
            style={{ paddingLeft: '10px' }}
            iconName="baseline_error_outline"
          />
        </>
      )}
      footer={({ handleClose }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CheckboxWithGtm
            checked={doNotShowAgain}
            onChange={() => setDoNotShowAgain(!doNotShowAgain)}
            id="newsMonthlyPaymentsPopupDoNotShowAgainCheckbox"
            label={translations.JNT_Do_not_show_me_this_again}
          >
            <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
              {translations.JNT_Do_not_show_me_this_again}
            </JATOText>
          </CheckboxWithGtm>
          <JATOButton
            id="newsMonthlyPaymentsPopupCloseButton"
            variant="primary"
            size="large"
            onClick={() => {
              onCloseButton()
              handleClose && handleClose()
            }}
          >
            {translations.JNT_Close}
          </JATOButton>
        </div>
      )}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: translations.JNT_News_MP_Disclaimer_Text,
        }}
      />
    </JATOModal>
  )
}
