import styled from 'styled-components'

export const StyledNavigation = styled.div`
  .nav-container {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow:
      0 3px 6px rgb(0 0 0 / 16%),
      0 3px 6px rgb(0 0 0 / 23%);
  }

  .nav-container > div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .nav-left {
    justify-content: flex-start;
  }

  .nav-right {
    justify-content: flex-end;
  }
`
