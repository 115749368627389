import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import { MpFilters } from 'components/MonthlyPayments/Filters/MpFilters'
import { IMappingObject } from 'helper/IMappingObject'
import { formatString } from 'helper/stringHelper'
import { IFilterCategory } from 'models/Filters/FilterGroups'
import { FilterNameType } from 'models/Filters/FilterNameType'
import {
  IFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import { MileageUnits } from 'models/Filters/FilterUnits'
import { ValueWithUnit } from 'models/Filters/ValueWithUnit'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { selectedFilters } from 'redux/monthlypayments/selectedFiltersSlice'
interface IMilesKilometersFilter {
  id: string
  title: string
  singularCategory: boolean | undefined
  options: IFilterOption[]
  handleAddFilter: (filterOption: ISelectedFilterOption) => void
  handleRemoveFilter: (filterOption: ISelectedFilterOption) => void
  childFilters?: IFilterCategory[]
  isRadio: boolean
}

const unitLabels: IMappingObject = {
  [MileageUnits.Kilometres]: 'Kms',
  [MileageUnits.Miles]: 'Miles',
}

export const MilesKilometersFilter: React.FC<IMilesKilometersFilter> = (
  params
) => {
  const filtersData: ISelectedFilterOption[] = useAppSelector(selectedFilters)
  const milesKilometersFilter = filtersData.find(
    (filter) => filter.filterName === FilterNameType.MilesKilometers
  )

  const [selectedUnit, setSelectedUnit] = useState(
    milesKilometersFilter?.value ?? ''
  )

  const getCheckedState = (index: number): boolean => {
    const checkboxFilter: ISelectedFilterOption | undefined = filtersData.find(
      (item: ISelectedFilterOption) =>
        item.value == params.options[index]?.value
    )
    return checkboxFilter !== undefined
  }

  const getSelectedFilterForCategory = (
    filterName: string
  ): ISelectedFilterOption | undefined => {
    const item: ISelectedFilterOption | undefined = filtersData.find(
      (item) => item.filterName === filterName
    )

    return item
  }

  const getFiltersValue = (filter: IFilterCategory): string => {
    const concatenatedValues = filtersData
      .filter((f) => f.filterName === filter.name)
      .map((f) => f.value)
      .join(',')

    return concatenatedValues
  }

  const onUnitChange = (option: IFilterOption): void => {
    setSelectedUnit(option.value)

    params.handleAddFilter({
      ...option,
      singularFilterCategory: params.singularCategory ?? false,
    })

    updateChildFilters(option.value)
  }

  const updateChildFilters = (newUnit: string): void => {
    // trying to convert already selected filter values to the new unit
    params.childFilters?.forEach((filter) => {
      const oldFilters = filtersData.filter((f) => f.filterName === filter.name)

      for (const oldFilter of oldFilters) {
        params.handleRemoveFilter(oldFilter)
        const oldUnit = getUnit(oldFilter.value)

        if (oldUnit != newUnit) {
          const oldOptions = getOptionsByUnit(filter.options, oldUnit)
          const newOptions = getOptionsByUnit(filter.options, newUnit)

          // if units lists have the same size, we assume the corresponding value will be by the same index in another list
          // otherwise can't convert, just reset previous value
          if (oldOptions.length === newOptions.length) {
            const index = oldOptions.findIndex(
              (o) => o.value === oldFilter.value
            )

            params.handleAddFilter({
              ...oldFilter,
              value: newOptions[index].value,
              displayValue: newOptions[index].displayValue,
              label: formatString(oldFilter.filterName, unitLabels[newUnit]),
            })
          }
        }
      }
    })
  }

  const getUnit = (value: string): string => {
    const { unit } = ValueWithUnit.parse(value)
    return unit
  }

  const getFormattedName = (name: string): string =>
    formatString(name, unitLabels[selectedUnit])

  const getOptionsByUnit = (
    options: IFilterOption[],
    unitName: string
  ): IFilterOption[] =>
    options
      .filter((option) => getUnit(option.value) === unitName)
      .map((option) => ({
        ...option,
        label: formatString(option.filterName, unitLabels[unitName]),
      }))

  return (
    <>
      <JATOText fontSize={14} as="p">
        {params.title}:
      </JATOText>
      <div style={{ display: 'inline-block' }}>
        {params.options.map((option, index) =>
          params.isRadio ? (
            <RadioWithGtm
              checked={getCheckedState(index)}
              onChange={() => onUnitChange(option)}
              key={option.displayValue.toLowerCase()}
              id={params.id}
              name={option.displayValue}
              value={option.value}
              label={option.displayValue}
              parentLabel={FilterNameType.MilesKilometers}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {option.displayValue}
              </JATOText>
            </RadioWithGtm>
          ) : (
            <CheckboxWithGtm
              checked={getCheckedState(index)}
              onChange={() => onUnitChange(option)}
              key={option.displayValue.toLowerCase()}
              id={params.id}
              name={option.displayValue}
              value={option.value}
              label={option.displayValue}
              parentLabel={FilterNameType.MilesKilometers}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {option.displayValue}
              </JATOText>
            </CheckboxWithGtm>
          )
        )}
      </div>
      <br />
      <br />

      {params.childFilters?.map((filter: IFilterCategory) => (
        <MpFilters
          key={filter.id}
          id={params.id}
          label={getFormattedName(filter.name)}
          name={filter.name}
          filterType={filter.filterType}
          options={getOptionsByUnit(filter.options, selectedUnit)}
          value={getFiltersValue(filter)}
          selectedFilter={getSelectedFilterForCategory(filter.name)}
          singularCategory={filter.singularCategory}
        />
      ))}
    </>
  )
}
