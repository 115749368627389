import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VolumesDataPointGroup } from 'helper/volumesHelper'
import {
  defaultVolumesUserState,
  VolumesUserState,
} from 'models/Login/VolumesLogin/VolumesUserState'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { DpPoint } from 'models/Volumes/DpPoint'
import { OutputPoint } from 'models/Volumes/OutputPoint'
import { PeriodDef } from 'models/Volumes/PeriodDef'
import { QueryExportResponse } from 'models/Volumes/QueryExportResponse'
import { Region } from 'models/Volumes/Region'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import { RootState } from 'redux/store'

export interface VolumesQueryState {
  databaseType?: VolumesDatabaseType
  countries?: string[]
  dataSetIds?: number[]
  registrationTypeList?: string[]
  vehicleTypeList?: string[]
  segment_GroupID?: string
  segment_segSource?: string
  segment_SegmentList?: string[]
  outputPoints?: OutputPoint[]
  vehicleAttributes?: DpPoint[]
  periods?: PeriodDef[]
  bandingCurrency?: string
  outPutLangaugeId?: number
  dataSetPersist?: string[]
  regionPoints?: Region[]
  analysisOptions?: string[]
  outputOptions?: string
}

interface VolumesState {
  userState: VolumesUserState
  query: VolumesQueryState
  excelExportResponse: QueryExportResponse
}

const getDefaultVolumesQueryState = (
  translations?: AppTextTranslations
): VolumesQueryState => ({
  outputPoints: [
    {
      id: VolumesDataPointGroup.VehicleType,
      iddesc: translations?.JNT_Volumes_QG_Dataset ?? 'Vehicle/Volumes type',
      optype: VolumesDataPointGroup.VehicleType,
    },
  ],
})

const defaultExcelExportResponseState: QueryExportResponse = {
  userId: '0',
  success: false,
  message: '',
  exportedFilePath: '',
}

const initialState: VolumesState = {
  userState: defaultVolumesUserState,
  query: getDefaultVolumesQueryState(),
  excelExportResponse: defaultExcelExportResponseState,
}

export const volumesSlice = createSlice({
  name: 'volumes',
  initialState,
  reducers: {
    setVolumesUserState: (
      state,
      { payload }: PayloadAction<VolumesUserState>
    ) => ({
      ...state,
      userState: payload,
    }),
    resetVolumesUserState: (state) => ({
      ...state,
      userState: defaultVolumesUserState,
    }),
    setVolumesQueryState: (
      state,
      { payload }: PayloadAction<VolumesQueryState>
    ) => ({
      ...state,
      query: payload,
    }),
    resetVolumesQueryState: (
      state,
      { payload: translations }: PayloadAction<AppTextTranslations>
    ) => ({
      ...state,
      query: getDefaultVolumesQueryState(translations),
    }),
    setExcelExportResponseState: (
      state,
      { payload }: PayloadAction<QueryExportResponse>
    ) => ({
      ...state,
      excelExportResponse: payload,
    }),
    resetExcelExportResponseState: (state) => ({
      ...state,
      excelExportResponse: defaultExcelExportResponseState,
    }),
  },

})

export const {
  setVolumesUserState,
  resetVolumesUserState,
  setVolumesQueryState,
  resetVolumesQueryState,
  setExcelExportResponseState,
  resetExcelExportResponseState,
} = volumesSlice.actions

export const getVolumesUserState = (state: RootState): VolumesUserState =>
  state.volumes.userState

export const getVolumesQueryState = (state: RootState): VolumesQueryState =>
  state.volumes.query

export const getExcelExportResponseState = (
  state: RootState
): QueryExportResponse => state.volumes.excelExportResponse

export default volumesSlice.reducer
