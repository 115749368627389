import { ICheckboxProps, JATOCheckbox } from '@jato/ui-component-library'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { GtmWrapper } from './GtmWrapper'

interface IGtmCheckboxProps extends ICheckboxProps {
  parentLabel?: string
}

export const CheckboxWithGtm: React.FC<IGtmCheckboxProps> = (
  props: IGtmCheckboxProps
) => (
  <GtmWrapper
    id={props.id}
    label={props.label}
    parentLabel={props.parentLabel}
    value={props.value}
    selected={!props.checked}
  >
    <JATOCheckbox {...props} id={uuid()} />
  </GtmWrapper>
)
