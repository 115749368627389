import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsArticleTabHeader = styled.div`
  .buttonGroup {
    border: 1px solid ${JATOTheme.colors.midDarkGrey};
    border-radius: 5px;
  }

  .buttonGroup :not(:last-child) {
    border-right: 1px solid ${JATOTheme.colors.midDarkGrey};
  }

  .buttonGroup :last-child {
    border-right: 0;
  }
  .buttonStatus {
    font-weight: ${JATOTheme.fontWeights.bold};
  }
  .buttonActiveStatus {
    color: #1677ff;
    font-weight: ${JATOTheme.fontWeights.bold};
  }
`
