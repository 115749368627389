import {
  JATOAnnouncement,
  JATOButton,
  JATOButtonIcon,
  JATOGroup,
  JATOLink,
  JATOSelect,
  JATOText,
  JATOTextarea,
  JATOTextInput,
  JATOTheme,
} from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { useFormik } from 'formik'
import {
  useLoadCompanies,
  useLoadCustomerCountries,
  useLoadRegistrationInfoData,
  useRegisterCustomer,
} from 'hooks/portal'
import { CompanyState } from 'models/Portal/CustomerRegistration/CompanyState'
import { CountryState } from 'models/Portal/CustomerRegistration/CountryState'
import { UserDetailState } from 'models/Portal/CustomerRegistration/CustomerRegistrationDetail'
import { Language } from 'models/Portal/CustomerRegistration/Language'
import { RegisterInfor } from 'models/Portal/CustomerRegistration/RegistorInfr'
import React, { useState } from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import * as Yup from 'yup'
import { StyledCustomerRegistration } from './CustomerRegistration.styles'

import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { validatePassword } from 'helper/portalHelper'
import moment from 'moment'
import { Col, Container, Row } from 'react-grid-system'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { JATOFormScreen } from './JATOFormScreen'
import { PasswordValidationMessage } from './PasswordValidationMessage'
export interface ICustomerRegistration {
  onClose: (showSalesEnquiry?: boolean) => void
}
export const CustomerRegistration: React.FC<ICustomerRegistration> = ({
  onClose,
}: ICustomerRegistration) => {
  const [customerCountries, setCustomerCountries] = useState<CountryState[]>([])
  const [customerCompanies, setCustomerCompanies] = useState<CompanyState[]>([])
  const [type, setType] = useState('password')
  const [icon, setIcon] = useState('baseline_lock')
  const translations = useAppSelector(getTranslations)
  const { languageId } = useAppSelector(selectCommonUserData)
  const { data: registrationInfoData, isLoading } =
    useLoadRegistrationInfoData()

  const { mutateAsync: loadCustomerCountries } = useLoadCustomerCountries()

  const { mutateAsync: loadCustomerCompanies } = useLoadCompanies()

  const { mutateAsync: registerCustomer } = useRegisterCustomer()

  const handleEmailAddress = async (e: React.FocusEvent): Promise<void> => {
    await formik.handleBlur(e)

    if (!formik.errors.emailAddress) {
      const countryData = await loadCustomerCountries({
        emailAddress: formik.values.emailAddress,
        languageId: formik.values.languageId.toString(),
      })
      setCustomerCountries(countryData)
    }
  }

  const handleToggle = (): void => {
    if (type === 'password') {
      setIcon('baseline_lock_open')
      setType('text')
    } else {
      setIcon('baseline_lock')
      setType('password')
    }
  }

  const handleCompanyCountryChange = async (value: string): Promise<void> => {
    await formik.setFieldValue('companyCountry', value)
    const companyData = await loadCustomerCompanies({
      emailAddress: formik.values.emailAddress,
      companyCountry: value,
      languageId: formik.values.languageId.toString(),
    })
    setCustomerCompanies(companyData)
  }

  const formik = useFormik<UserDetailState>({
    initialValues: {
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      companyCountry: '',
      companyName: '',
      position: '',
      companyAddress: '',
      currency: 'EUR',
      languageId: languageId,
      industry: '',
      telNumber: '',
      useJatoNetFor: '',
      password: '',
      confirmPassword: '',
      lgId: 0,
      info: false,
      reg4Site: 1,
      acceptdate: moment().format('YYYY-MM-DD'),
      pwDate: moment().format('YYYY-MM-DD'),
      regdate: moment().format('YYYY-MM-DD'),
      isCustomer: true,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(translations.JNT_Portal_Form_Field_Required),
      firstName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      lastName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      emailAddress: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .email(translations.JNT_Portal_Form_Email_Field_Required),
      companyName: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      companyAddress: Yup.string()
        .max(200, ({ max }) => `only ${max} characters allowed`)
        .required(translations.JNT_Portal_Form_Field_Required),
      companyCountry: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      position: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      industry: Yup.string().required(
        translations.JNT_Portal_Form_Field_Required
      ),
      telNumber: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .test(
          'PhoneCheck',
          translations.JNT_Portal_Invalid_Phone_Number,
          function (value) {
            return isValidPhoneNumber(value)
          }
        ),
      password: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .min(8, translations.JNT_Portal_CustReg_Password_MinLength_Message)
        .test(
          'validatePassword',
          translations.JNT_Portal_ModPwd_Desc,
          validatePassword
        ),
      confirmPassword: Yup.string()
        .required(translations.JNT_Portal_CustReg_Password_NoMatch_Message)
        // use oneOf to match one of the values inside the array.
        // use "ref" to get the value of passwrod.
        .oneOf(
          [Yup.ref('password')],
          translations.JNT_Portal_CustReg_Password_NoMatch_Message
        ),
      useJatoNetFor: Yup.string().max(
        200,
        ({ max }) => `only ${max} characters allowed`
      ),
    }),
    onSubmit: async (values: UserDetailState) => {
      const [lgId, companyName] = values.companyName.split('-')
      const response = await registerCustomer({
        ...values,
        lgId: Number(lgId),
        companyName: companyName,
      })
      if (response.isSuccess) {
        JATOAnnouncement.Create({
          title: '',
          children: (
            <div
              dangerouslySetInnerHTML={{ __html: response.responseMessage }}
            />
          ),
        })
        onClose()
      }
    },
  })
  return (
    <StyledCustomerRegistration>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Container xl lg md sm xs style={{ padding: '0px 10px' }}>
            <Row>
              <Col md={3} lg={3} style={{ padding: '0px 0px' }}>
                <JATOFormScreen />
              </Col>
              <Col md={9} lg={9} style={{ paddingLeft: '20px' }}>
                {formik.isSubmitting && <LoaderModal isOpen={true} />}
                <span
                  dangerouslySetInnerHTML={{
                    __html: translations.JNT_Portal_CustReg_Desc.substring(
                      0,
                      translations.JNT_Portal_CustReg_Desc.indexOf('<a')
                    ),
                  }}
                />
                <JATOLink textTransform="none" onClick={() => onClose(true)}>
                  {translations.JNT_COM_CLICK}
                </JATOLink>

                <form onSubmit={formik.handleSubmit}>
                  <JATOGroup flexDirection="row" style={{ paddingTop: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                      }}
                    >
                      <div>
                        <JATOSelect
                          id="title"
                          label={translations.JNT_Portal_CustReg_Title}
                          dataSrc={
                            registrationInfoData?.title !== undefined
                              ? registrationInfoData?.title.map(
                                  (l: RegisterInfor) => ({
                                    value: l.description,
                                    displayValue: l.description,
                                  })
                                )
                              : []
                          }
                          value={formik.values.title}
                          onChange={(value) =>
                            formik.setFieldValue('title', value)
                          }
                          onBlur={formik.handleBlur}
                          isMultiSelect={false}
                          clearAfterSelect={true}
                          width="100px"
                        />
                        {formik.touched.title && formik.errors.title ? (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[1]}
                            color={JATOTheme.colors.primary}
                            marginTop={JATOTheme.space[2]}
                          >
                            {formik.errors.title}
                          </JATOText>
                        ) : null}
                      </div>
                      <div>
                        <JATOTextInput
                          id="firstName"
                          name="firstName"
                          label={translations.JNT_Portal_CustReg_Name}
                          title={translations.JNT_Portal_CustReg_Name}
                          type="text"
                          width={250}
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[1]}
                            color={JATOTheme.colors.primary}
                            marginTop={JATOTheme.space[2]}
                          >
                            {formik.errors.firstName}
                          </JATOText>
                        ) : null}
                      </div>
                    </div>

                    <div>
                      <JATOTextInput
                        id="lastName"
                        name="lastName"
                        label={translations.JNT_Portal_CustReg_Surname}
                        title={translations.JNT_Portal_CustReg_Surname}
                        type="text"
                        width={410}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.lastName}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="emailAddress"
                        name="emailAddress"
                        label={translations.JNT_Portal_CustReg_Email}
                        title={translations.JNT_Portal_CustReg_Email}
                        type="text"
                        width={330}
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        onBlur={handleEmailAddress}
                        autoComplete="off"
                      />

                      {formik.touched.emailAddress &&
                      formik.errors.emailAddress ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.emailAddress}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOButtonIcon
                        style={{ paddingTop: '40px' }}
                        title={
                          translations.JNT_Portal_Form_Company_Mail_Required
                        }
                        iconName="baseline_info"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                    >
                      <div>
                        <JATOSelect
                          id="companyCountry"
                          label={translations.JNT_Portal_CustReg_Cntry}
                          dataSrc={customerCountries.map((l: CountryState) => ({
                            value: l.countryCode,
                            displayValue: l.countryName,
                          }))}
                          value={formik.values.companyCountry}
                          onChange={(value) =>
                            handleCompanyCountryChange(value)
                          }
                          onBlur={formik.handleBlur}
                          isMultiSelect={false}
                          clearAfterSelect={true}
                          width="410px"
                        />
                        {formik.touched.companyCountry &&
                        formik.errors.companyCountry ? (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[1]}
                            color={JATOTheme.colors.primary}
                            marginTop={JATOTheme.space[2]}
                          >
                            {formik.errors.companyCountry}
                          </JATOText>
                        ) : null}
                      </div>
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOSelect
                        id="companyName"
                        label={translations.JNT_Portal_CustReg_Company}
                        dataSrc={customerCompanies.map((l: CompanyState) => ({
                          value: `${l.lgId}-${l.companyName}`,
                          displayValue: l.companyName,
                        }))}
                        value={formik.values.companyName}
                        onChange={(value) =>
                          formik.setFieldValue('companyName', value)
                        }
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="370px"
                      />
                      {formik.touched.companyName &&
                      formik.errors.companyName ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyName}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOSelect
                        id="position"
                        label={translations.JNT_Portal_CustReg_Position}
                        dataSrc={
                          registrationInfoData?.positions
                            ? registrationInfoData.positions.map(
                                (l: RegisterInfor) => ({
                                  value: ` ${l.description}`,
                                  displayValue: l.description,
                                })
                              )
                            : []
                        }
                        value={formik.values.position}
                        onChange={(value) =>
                          formik.setFieldValue('position', value)
                        }
                        onBlur={formik.handleBlur}
                        isMultiSelect={false}
                        clearAfterSelect={true}
                        width="410px"
                      />
                      {formik.touched.position && formik.errors.position ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.position}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div style={{ width: '370px' }}>
                      <JATOTextarea
                        id="companyAddress"
                        name="companyAddress"
                        rows={3}
                        label={translations.JNT_Portal_CustReg_Address}
                        title={translations.JNT_Portal_CustReg_Address}
                        value={formik.values.companyAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.companyAddress &&
                      formik.errors.companyAddress ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.companyAddress}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <div style={{ paddingBottom: '10px' }}>
                        <JATOSelect
                          id="languageId"
                          label={translations.JNT_Portal_CustReg_Lang}
                          dataSrc={
                            registrationInfoData?.preferredLanguage
                              ? registrationInfoData?.preferredLanguage.map(
                                  (l: Language) => ({
                                    value: l.languageId.toString(),
                                    displayValue: l.languageName,
                                  })
                                )
                              : []
                          }
                          value={formik.values.languageId.toString()}
                          onChange={(value) =>
                            formik.setFieldValue('languageId', value)
                          }
                          onBlur={formik.handleBlur}
                          isMultiSelect={false}
                          clearAfterSelect={true}
                          width="410px"
                        />
                      </div>
                      <div>
                        <JATOSelect
                          id="industry"
                          label={translations.JNT_Portal_CustReg_Industry}
                          dataSrc={
                            registrationInfoData?.industry
                              ? registrationInfoData?.industry.map(
                                  (l: RegisterInfor) => ({
                                    value: l.description,
                                    displayValue: l.description,
                                  })
                                )
                              : []
                          }
                          value={formik.values.industry}
                          onChange={(value) =>
                            formik.setFieldValue('industry', value)
                          }
                          onBlur={formik.handleBlur}
                          isMultiSelect={false}
                          clearAfterSelect={true}
                          width="410px"
                        />
                        {formik.touched.industry && formik.errors.industry ? (
                          <JATOText
                            as="span"
                            fontSize={JATOTheme.fontSizes[1]}
                            color={JATOTheme.colors.primary}
                            marginTop={JATOTheme.space[2]}
                          >
                            {formik.errors.industry}
                          </JATOText>
                        ) : null}
                      </div>
                    </div>
                  </JATOGroup>

                  <JATOGroup flexDirection="row">
                    <div>
                      <div className="label">
                        {translations.JNT_Portal_CustReg_TelNumber}
                      </div>

                      <PhoneInput
                        international
                        id="telNumber"
                        name="telNumber"
                        placeholder="Enter phone number"
                        defaultCountry="GB"
                        value={formik.values.telNumber}
                        type="text"
                        style={{ width: '370px' }}
                        onChange={(e) => formik.setFieldValue('telNumber', e)}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.telNumber && formik.errors.telNumber ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          display="flex"
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.telNumber}
                        </JATOText>
                      ) : null}
                    </div>
                    <div style={{ width: '410px' }}>
                      <JATOTextarea
                        id="useJatoNetFor"
                        name="useJatoNetFor"
                        rows={3}
                        label={translations.JNT_Portal_CustReg_UseJatoNetFor}
                        title={translations.JNT_Portal_CustReg_UseJatoNetFor}
                        value={formik.values.useJatoNetFor}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.useJatoNetFor &&
                      formik.errors.useJatoNetFor ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          display="flex"
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.useJatoNetFor}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup flexDirection="row">
                    <div>
                      <JATOTextInput
                        id="password"
                        name="password"
                        label={translations.JNT_Portal_Login_Pwd}
                        title={translations.JNT_Portal_Login_Pwd}
                        type={type}
                        width={330}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete={
                          window.navigator.userAgent.indexOf('Chrome') !== -1
                            ? 'new-password'
                            : 'off'
                        }
                      />

                      {formik.touched.password && formik.errors.password ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          display="flex"
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.password}
                        </JATOText>
                      ) : null}
                    </div>
                    <div>
                      <JATOButtonIcon
                        style={{ paddingTop: '40px' }}
                        iconName={icon}
                        title={
                          type === 'password'
                            ? translations.JNT_Portal_Show_Password
                            : translations.JNT_Portal_Hide_Password
                        }
                        onClick={handleToggle}
                      />
                    </div>
                    <div>
                      <JATOTextInput
                        id="confirmPassword"
                        name="confirmPassword"
                        label={translations.JNT_Portal_Login_PasswordConfirm}
                        title={translations.JNT_Portal_Login_PasswordConfirm}
                        type={type}
                        width={410}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <JATOText
                          as="span"
                          fontSize={JATOTheme.fontSizes[1]}
                          color={JATOTheme.colors.primary}
                          display="flex"
                          marginTop={JATOTheme.space[2]}
                        >
                          {formik.errors.confirmPassword}
                        </JATOText>
                      ) : null}
                    </div>
                  </JATOGroup>
                  <JATOGroup style={{ margin: `${JATOTheme.space[4]}px 0` }}>
                    <PasswordValidationMessage
                      password={formik.values.password}
                    />
                  </JATOGroup>
                  <JATOGroup
                    flexDirection="row"
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CheckboxWithGtm
                      id="customerRegistrationInfoCheckbox"
                      name="info"
                      label={translations.JNT_Portal_CustReg_Info}
                      title={translations.JNT_Portal_CustReg_Info}
                      type="checkbox"
                      width={400}
                      value="info"
                      onChange={formik.handleChange}
                    >
                      {translations.JNT_Portal_CustReg_Info}
                    </CheckboxWithGtm>
                    <JATOButton
                      id="btnCustomerRegistration"
                      size="large"
                      variant="primary"
                      type="submit"
                      disabled={!(formik.dirty && formik.isValid)}
                    >
                      {translations.JNT_Portal_CustReg_btnReg}
                    </JATOButton>
                  </JATOGroup>
                </form>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </StyledCustomerRegistration>
  )
}
