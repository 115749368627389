import styled from 'styled-components'

export const StyledVolumesReportStep = styled.div`
  .grid-container {
    overflow-x: auto;
  }

  .reportStepHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .reportStepHeader > div {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .e-pager .e-currentitem,
  .e-pager .e-currentitem:hover {
    background: #c40005;
    color: #fff;
    opacity: 1;
  }

  .e-pager .e-currentitem.e-numericitem.e-focused {
    background: #c40005;
  }
`
