import styled from 'styled-components'

export interface StyledNoLicenseProps {
  noLicenseImage: string
  backgroundImage: string
}

export const StyledNoLicense = styled.div<StyledNoLicenseProps>`
   {
    box-sizing: border-box;
    background-image: url('${(props) => props.noLicenseImage}');
    background-size: 100%;
    min-height: calc(100vh - 80px - var(--app-header-height));
    height: 800px;
    background-repeat: no-repeat;
  }

  .textPosition {
    position: absolute;
    filter: none;
    -webkit-filter: blur(0px);
    top: 120px;
    left: 300px;
    font-size: 20px;
    width: 1024px;
    height: 600px;
    padding: 0 139px 0 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;   
  }

  .noAccessDialog {
    background-image: url('${(props) => props.backgroundImage}');
    min-height: 600px;
    width: 399px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .noAccessTextSection {
    min-height: 600px;
    position: relative;
    left: 500px;
    top: 85px;
    z-index: 100;
  }

  .noAccessSigninText {
    width: 310px;
    height: 47px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .noAccessYourCompany {
    width: 438px;
    height: 78px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .noAccessLetsTalk {
    width: 438px;
    height: 40px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .noAccessCompleteYourRequest {
    width: 412px;
    height: 24px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  .noAccessRectangleButton {
    padding-top: 30px;
  }
`
