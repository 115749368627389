import { FilterNameType } from 'models/Filters/FilterNameType'
import {
  IAdvancedFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import { DataLayerVariableName } from 'models/GoogleTagManagerLogs/DataLayerVariableName'
import { EventType } from 'models/GoogleTagManagerLogs/EventTypes'
import { AdvancedSearchModel } from 'models/News/AdvancedSearchModel'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import { getCategoriesBySubjects } from 'models/News/NewsCategoryType'
import { VehicleSpecification } from 'models/Specifications/VehicleSpecification'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import TagManager from 'react-gtm-module'
import { SearchState } from 'redux/news/newsSlice'
import { VolumesQueryState } from 'redux/volumes/volumesSlice'
import { removeDuplicates } from './arrayHelper'
import { IMappingObject } from './IMappingObject'
import { NewsFilterName } from './newsHelper'

export const gtmLogEmailDomain = (email?: string): void => {
  const emailDomain = email?.split('@').pop()
  emailDomain &&
    TagManager.dataLayer({
      dataLayer: {
        event: EventType.EmailDomainChange,
        [DataLayerVariableName.EmailDomain]: emailDomain,
      },
    })
}

export const gtmLogLanguageId = (languageId: number): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.LanguageIdChange,
      [DataLayerVariableName.LanguageId]: languageId,
    },
  })
}

export const gtmLogMarketChange = (market: string): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.MarketChange,
      [DataLayerVariableName.LastSelectedMarket]: market,
    },
  })
}

export const gtmLogSelectedValueChange = (
  id: string,
  label?: string,
  parentLabel?: string,
  value?: string,
  isSelected?: boolean
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.SelectedValueChange,
      [DataLayerVariableName.ElementId]: id,
      [DataLayerVariableName.ElementLabel]: label,
      [DataLayerVariableName.ElementParentLabel]: parentLabel,
      [DataLayerVariableName.ElementValue]: value,
      [DataLayerVariableName.IsSelected]: isSelected,
    },
  })
}

export const gtmLogNewsSimpleSearch = (searchState: SearchState): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.NewsSimpleSearch,
      [DataLayerVariableName.SearchText]:
        searchState.searchFields[NewsFilterName.SearchText].join(),
      [DataLayerVariableName.ExactMatch]: searchState.isExactMatch,
    },
  })
}

export const gtmLogNewsAdvancedSearch = (model: AdvancedSearchModel): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.NewsAdvancedSearch,
      [DataLayerVariableName.SearchText]: model.searchText,
      [DataLayerVariableName.ExactMatch]: model.isExactMatch,
      [DataLayerVariableName.Makes]: model.makes,
      [DataLayerVariableName.Models]: model.models,
      [DataLayerVariableName.Countries]: model.countries,
      [DataLayerVariableName.Categories]: model.categories,
      [DataLayerVariableName.Group]: model.group,
    },
  })
}

export const gtmLogNewsFiltersChange = (
  filters: IMappingObject<string[]>
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.NewsFiltersChange,
      [DataLayerVariableName.Makes]: filters[NewsFilterName.Make]?.join(),
      [DataLayerVariableName.Models]: filters[NewsFilterName.Model]?.join(),
      [DataLayerVariableName.Countries]:
        filters[NewsFilterName.Country]?.join(),
      [DataLayerVariableName.Categories]:
        filters[NewsFilterName.Category]?.join(),
      [DataLayerVariableName.Group]: filters[NewsFilterName.Group]?.join(),
    },
  })
}

export const gtmLogNewsSearchAlertCreateEdit = (
  payload: SearchIndexRequest
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: !!payload.id
        ? EventType.NewsSearchAlertEdit
        : EventType.NewsSearchAlertCreate,
      [DataLayerVariableName.SearchText]: payload.searchQuery,
      [DataLayerVariableName.ExactMatch]: payload.isExactSearch,
      [DataLayerVariableName.Makes]: payload.makeList.join(),
      [DataLayerVariableName.Models]: payload.modelList.join(),
      [DataLayerVariableName.Countries]: payload.countryList.join(),
      [DataLayerVariableName.Categories]: payload.subjectCategoryCode,
      [DataLayerVariableName.Group]: payload.vehicleTypeList.join(),
      [DataLayerVariableName.IsShared]: payload.isShared,
      [DataLayerVariableName.IsAlert]: payload.isSubscribed,
      [DataLayerVariableName.SubscriptionType]: payload.subscriptionType,
    },
  })
}

export const gtmLogNewsArticleView = (article: NewsArticle): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.NewsArticleView,
      [DataLayerVariableName.Subjects]: article.subjects?.join(),
      [DataLayerVariableName.Categories]: getCategoriesBySubjects(
        article.subjects ?? []
      ).join(),
      [DataLayerVariableName.Countries]: article.countryCode,
      [DataLayerVariableName.Makes]: article.vehicle.make,
      [DataLayerVariableName.Models]: article.vehicle.model,
      [DataLayerVariableName.Group]: article.vehicle.vehType,
    },
  })
}

export const gtmLogVolumesReportView = (
  queryState: VolumesQueryState
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.VolumesReportView,
      [DataLayerVariableName.DatabaseType]: queryState.databaseType,
      [DataLayerVariableName.Countries]: queryState.countries?.join(),
      [DataLayerVariableName.Makes]: queryState.vehicleAttributes?.find(
        (v) => v.id === 'MAKE'
      )?.value,
      [DataLayerVariableName.Models]: queryState.vehicleAttributes?.find(
        (v) => v.id === 'MODEL'
      )?.value,
      [DataLayerVariableName.FilterName]: queryState.vehicleAttributes
        ?.map((v) => v.iddesc)
        .join(';'),
      [DataLayerVariableName.Values]: queryState.vehicleAttributes
        ?.map((v) => v.value)
        .join(';'),
      [DataLayerVariableName.DisplayValues]: queryState.vehicleAttributes
        ?.map((v) =>
          [v.valuedesc, v.minValue, v.maxValue].filter((x) => x).join('|')
        )
        .join(';'),
      [DataLayerVariableName.DataSets]: queryState.dataSetIds?.join(),
      [DataLayerVariableName.Periods]: queryState.periods
        ?.map((p) => p.periodName)
        .join(),
    },
  })
}

export const gtmLogMonthlyPaymentsFiltersChange = (
  filters: ISelectedFilterOption[]
): void => {
  const filtered = filters.filter(
    (f) =>
      f.filterName !== FilterNameType.Country &&
      f.filterName !== FilterNameType.MilesKilometers
  )
  const grouped = Object.groupBy(filtered, ({ filterName }) => filterName)

  TagManager.dataLayer({
    dataLayer: {
      event: EventType.MonthlyPaymentsFiltersChange,
      [DataLayerVariableName.FilterName]: Object.keys(grouped).join(';'),
      [DataLayerVariableName.Values]: Object.values(grouped)
        .map((f) => f?.map((o) => o.value).join(','))
        .join(';'),
      [DataLayerVariableName.DisplayValues]: Object.values(grouped)
        .map((f) => f?.map((o) => o.displayValue).join(','))
        .join(';'),
    },
  })
}

export const gtmLogMonthlyPaymentsReportView = (
  vehicles: MpVehicle[]
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.MonthlyPaymentsReportView,
      [DataLayerVariableName.Countries]: vehicles
        .map((v) => v.country)
        .filter(removeDuplicates)
        .join(),
      [DataLayerVariableName.Makes]: vehicles
        .map((v) => v.make)
        .filter(removeDuplicates)
        .join(),
      [DataLayerVariableName.Models]: vehicles
        .map((v) => v.model)
        .filter(removeDuplicates)
        .join(),
    },
  })
}

export const gtmLogSpecsReportView = (
  vehicles: VehicleSpecification[]
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.SpecsReportView,
      [DataLayerVariableName.Countries]: vehicles
        .map((v) => v.country)
        .filter(removeDuplicates)
        .join(),
      [DataLayerVariableName.Makes]: vehicles
        .map((v) => v.makeGlobal)
        .filter(removeDuplicates)
        .join(),
      [DataLayerVariableName.Models]: vehicles
        .map((v) => v.modelGlobal)
        .filter(removeDuplicates)
        .join(),
    },
  })
}

export const gtmLogSpecsFiltersChange = (
  filters: IMappingObject<ISelectedFilterOption[]>
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.SpecsFiltersChange,
      [DataLayerVariableName.FilterName]: Object.keys(filters).join(';'),
      [DataLayerVariableName.Values]: Object.values(filters)
        .map((f) => f.map((o) => o.value).join(','))
        .join(';'),
      [DataLayerVariableName.DisplayValues]: Object.values(filters)
        .map((f) => f.map((o) => o.displayValue).join(','))
        .join(';'),
    },
  })
}

export const gtmLogSpecsAdvancedFiltersChange = (
  filters: IMappingObject<IAdvancedFilterOption[]>
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.SpecsAdvancedFiltersChange,
      [DataLayerVariableName.FilterName]: Object.keys(filters).join(';'),
      [DataLayerVariableName.Values]: Object.values(filters)
        .map((f) => f.map((o) => o.value).join(','))
        .join(';'),
      [DataLayerVariableName.DisplayValues]: Object.values(filters)
        .map((f) => f.map((o) => o.displayValue).join(','))
        .join(';'),
    },
  })
}

export const gtmLogSpecsOptionBuild = (optionId: number): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.SpecsOptionBuild,
      [DataLayerVariableName.OptionId]: optionId,
    },
  })
}

export const gtmLogSpecsOptionUnbuild = (optionId: number): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventType.SpecsOptionUnbuild,
      [DataLayerVariableName.OptionId]: optionId,
    },
  })
}
