import { JATOButton } from '@jato/ui-component-library'
import TrashIcon from 'assets/icons/icon_trash.svg'
import { ModalComponent } from 'components/ModalComponent'
import { useRemoveSearchAndAlert } from 'hooks/news'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsSearchAlertRemoveButton } from './NewsSearchAlertRemoveButton.styles'

export interface INewsSearchAlertRemoveButtonProps {
  searchAlert: SearchIndexRequest
  removeMessage: string
}

export const NewsSearchAlertRemoveButton: React.FC<
  INewsSearchAlertRemoveButtonProps
> = ({
  searchAlert: searchRequest,
  removeMessage,
}: INewsSearchAlertRemoveButtonProps) => {
  const translations = useAppSelector(getTranslations)

  const { mutate: removeSearchAndAlert } = useRemoveSearchAndAlert()
  const [isRemoveAlertModalVisible, setRemoveAlertModalVisible] =
    useState<boolean>(false)

  const removeSavedSearchesAndAlerts = (): void => {
    removeSearchAndAlert(searchRequest)
    setRemoveAlertModalVisible(false)
  }

  return (
    <StyledNewsSearchAlertRemoveButton>
      <JATOButton
        id="newsSearchAlertRemoveButton"
        variant="ghost"
        display="flex"
        onClick={() => setRemoveAlertModalVisible(true)}
      >
        <img src={TrashIcon} width="16" />
      </JATOButton>
      <ModalComponent
        modalVisible={isRemoveAlertModalVisible}
        closeButton
        title={`${translations.JNT_delete} - ${searchRequest.description}`}
        primaryButtonName={translations.JNT_delete}
        onSecondaryClick={() => setRemoveAlertModalVisible(false)}
        secondaryButtonName=""
        onPrimaryClick={removeSavedSearchesAndAlerts}
        isPrimaryButtonLoading={false}
        primaryButton
      >
        {removeMessage}
      </ModalComponent>
    </StyledNewsSearchAlertRemoveButton>
  )
}
