import { JATOButton, JATOHeading, JATOText } from '@jato/ui-component-library'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  getVolumesUserState,
  resetExcelExportResponseState,
  resetVolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledVolumesStartStep } from './VolumesStartStep.styles'

export const VolumesStartStep: React.FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const userState = useAppSelector(getVolumesUserState)
  const queryState = useAppSelector(getVolumesQueryState)

  const blocks = [
    {
      type: VolumesDatabaseType.ModelMix,
      title: translations.JNT_Volumes_QG_M,
      text: translations.JNT_Volumes_QG4_M_DESC,
      disabled: !userState.isVolumesLicenecM,
    },
    {
      type: VolumesDatabaseType.SourceData,
      title: translations.JNT_Volumes_QG_V,
      text: translations.JNT_Volumes_QG4_V_DESC,
      disabled: !userState.isVolumesLicenecV,
    },
    {
      type: VolumesDatabaseType.ModelMixSpecs,
      title: translations.JNT_Volumes_QG_X,
      text: translations.JNT_Volumes_QG4_X_DESC,
      disabled: !userState.isVolumesLicenecX,
    },
  ]

  useEffect(() => {
    dispatch(resetVolumesQueryState(translations))
    dispatch(resetExcelExportResponseState())
  }, [])

  const onNext = (): void => history.push(RoutePaths.VolumesCountry)

  const onClick = (type: VolumesDatabaseType): void => {
    dispatch(
      setVolumesQueryState({
        ...queryState,
        databaseType: type,
      })
    )
    onNext()
  }

  return (
    <StyledVolumesStartStep>
      {blocks.map((block, index) => (
        <div key={index} className="block">
          <div className="blockText">
            <JATOHeading as="h3" fontWeight="medium">
              {block.title}
            </JATOHeading>
            <JATOText as="div" fontSize={14} fontWeight="regular">
              <span
                dangerouslySetInnerHTML={{
                  __html: block.text,
                }}
              />
            </JATOText>
            <JATOButton
              id={'volumesStartStepButton_' + block.type}
              variant="primary"
              disabled={block.disabled}
              onClick={() => onClick(block.type)}
            >
              {translations.JNT_Volumes_Start}
            </JATOButton>
          </div>
        </div>
      ))}
    </StyledVolumesStartStep>
  )
}
