import { JATOTooltip } from '@jato/ui-component-library'
import AvailableAsOptionIcon from 'assets/icons/AvailableAsOptionIcon.svg'
import StandardIcon from 'assets/icons/Standard.svg'
import { Accordion } from 'components/Accordion/Accordion'
import { AccordionToggle } from 'components/AccordionToggle/AccordionToggle'
import {
  getComparisonsTitleText,
  organizeComparisonsByCategory,
} from 'helper/specificationsComparisonsHelper'
import { useGetBrochureVehicles, useVehicleComparisons } from 'hooks/carSpecs'
import { VehicleHeaderInfo } from 'models/Specifications/BrochureResponse'
import { VehicleComparisonInfo } from 'models/Specifications/GetVehicleComparisonResponse'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  getSpecsVehicles,
} from 'redux/specifications/specificationsSlice'
import { ReportPageType } from 'router/RoutePaths'
import { v4 as uuid } from 'uuid'
import { SpecificationsOptionBuildButton } from '../SpecificationsOptions/SpecificationsOptionBuildButton'
import { StyledSpecificationsComparisonsReport } from './SpecificationsComparisonsReport.styles'

export const SpecificationsComparisonsReport: React.FC = () => {
  const { comparisonPage } = useParams<{ comparisonPage: ReportPageType }>()

  const { benchmarkVehicleId } = useAppSelector(getSpecsVehicles)
  const { carspecsDefaultSetting } = useAppSelector(getSpecsUserState)

  const { data: brochureResponse } = useGetBrochureVehicles()

  const [openStatus, setOpenStatus] = useState(false)

  const titleText = getComparisonsTitleText(comparisonPage)

  const { data: response } = useVehicleComparisons(comparisonPage)

  const nonBenchmarkVehicleIds =
    brochureResponse?.brochureVehicles
      .map((v) => v.vehicleHeaderInfo.vehicleId)
      .filter((id) => id !== benchmarkVehicleId) ?? []

  const comparisonsByCategory = organizeComparisonsByCategory(
    response,
    nonBenchmarkVehicleIds
  )

  const getVehicleHeader = (
    comparison: VehicleComparisonInfo
  ): VehicleHeaderInfo | undefined => {
    const vehicleId =
      carspecsDefaultSetting.showCompetitorAdvantages &&
      comparisonPage === ReportPageType.DisAdvantages
        ? benchmarkVehicleId
        : comparison.vehicleId

    return brochureResponse?.brochureVehicles
      .map((v) => v.vehicleHeaderInfo)
      .find((v) => v.vehicleId == vehicleId)
  }

  return (
    <StyledSpecificationsComparisonsReport>
      <AccordionToggle
        id="specsReportAccordionToggle"
        titleText={titleText}
        isOpen={openStatus}
        titleHeaderWidth="72vw"
        onClick={() => setOpenStatus(!openStatus)}
      />
      {Array.from(comparisonsByCategory.entries()).map(
        ([categoryName, categoryData]) => (
          <Accordion
            id="specsReportAccordion"
            titleText={categoryName}
            key={uuid()}
            isOpen={openStatus}
            titleHeaderWidth="72vw"
          >
            <table className="tableStyle" key={uuid()}>
              <tbody>
                <tr className="tableRow">
                  {Array.from(categoryData.vehicleInfo.entries()).map(
                    ([, comparisons]) => (
                      <td key={uuid()} className="tableCell">
                        {comparisons.map((comparison) => (
                          <div key={uuid()} className="cellContent">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: comparison.keyAdvantageText,
                              }}
                              key={uuid()}
                            />
                            {comparison.optionId > 0 &&
                            comparison.isBuildableOption &&
                            comparison.keyAdvantageText !== '' ? (
                              <JATOTooltip
                                title={comparison.hoverOverText}
                                placement="top-start"
                                offset={[5, 10]}
                                showOnlyOnOverflow={false}
                                textAlign="left"
                                key={comparison.optionId + '_tooltip'}
                              >
                                <SpecificationsOptionBuildButton
                                  categoryName={comparison.categoryName}
                                  optionId={comparison.optionId}
                                  vehicleHeader={getVehicleHeader(comparison)}
                                  iconSrc={AvailableAsOptionIcon}
                                  addedAsOption={false}
                                />
                              </JATOTooltip>
                            ) : (
                              <img src={StandardIcon} key={uuid()} />
                            )}
                          </div>
                        ))}
                      </td>
                    )
                  )}
                </tr>
              </tbody>
            </table>
          </Accordion>
        )
      )}
    </StyledSpecificationsComparisonsReport>
  )
}
