import React from 'react'
import { StyledPrivatePolicy } from './PrivatePolicy.styles'
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations'
import { JATOLink } from '@jato/ui-component-library'
import { getPrivacySectionDetails } from 'helper/portalHelper'
import { v4 as uuid } from 'uuid'
import { getTranslations } from 'redux/translations/translationsSlice'
import { useAppSelector } from 'redux/hook'

export const PrivatePolicy: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const scrollTop = (ElementId: string): void => {
    console.log(ElementId)
    document.getElementById(ElementId)?.scrollIntoView()
  }

  const getContent = (contentItems: string): JSX.Element => {
    const element = (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="linkSection">
          {Object.keys(getPrivacySectionDetails[contentItems]).map(
            (contentItemKey: string) => (
              <p key={uuid()}>
                <JATOLink
                  textTransform="none"
                  onClick={() => scrollTop(`${contentItemKey}`)}
                >
                  {translations[contentItemKey]}
                </JATOLink>
              </p>
            )
          )}
        </div>
        <div>
          {Object.keys(getPrivacySectionDetails[contentItems]).map(
            (contentItemKey: string) => (
              <div key={uuid()} id={`${contentItemKey}`}>
                <p className="termHeadLine">{translations[contentItemKey]}</p>
                {getPrivacySectionDetails[contentItems][contentItemKey].map(
                  (content: string) => (
                    <div
                      style={{ fontSize: 14 }}
                      key={uuid()}
                      dangerouslySetInnerHTML={{
                        __html: translations ? translations[content] : '',
                      }}
                    />
                  )
                )}
              </div>
            )
          )}
        </div>
      </div>
    )

    return element
  }

  return (
    <StyledPrivatePolicy>
      <TabComponent
        animation={{
          previous: { effect: 'None' },
          next: { effect: 'None' },
        }}
      >
        <TabItemsDirective>
          {Object.keys(getPrivacySectionDetails).map((headerKey: string) => (
            <TabItemDirective
              cssClass="tabItem"
              key={uuid()}
              header={{
                text: translations[headerKey],
              }}
              content={() => getContent(headerKey)}
            />
          ))}
        </TabItemsDirective>
      </TabComponent>
    </StyledPrivatePolicy>
  )
}
