import styled from 'styled-components'
import SplashScreenImage from './Images/jato-brand-new.png'

export const StyledSplashScreen = styled.div`
  .splashScreen {
    background: no-repeat url(${SplashScreenImage}) center center;
    min-height: 620px;
    background-size: cover;
    cursor: pointer;
  }
`
