import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import mpVehiclesReducer from 'redux/monthlypayments/mpVehiclesSlice'
import selectedFiltersReducer from 'redux/monthlypayments/selectedFiltersSlice'
import userDataReducer from 'redux/monthlypayments/userDataSlice'
import translationsReducer from 'redux/translations/translationsSlice'
import storageLocal from 'redux-persist/lib/storage'
//import storageSession from 'redux-persist/lib/storage/session'
import commonUserDataReducer from 'redux/commonUserData/commonUserDataSlice'
import newsReducer from 'redux/news/newsSlice'
import specificationsReducer from 'redux/specifications/specificationsSlice'
import volumesReducer from 'redux/volumes/volumesSlice'

const persistConfig = {
  key: 'root',
  storage: storageLocal,
}

const rootReducer = combineReducers({
  commonUserData: commonUserDataReducer,
  translations: translationsReducer,
  monthlypayments: combineReducers({
    userData: userDataReducer,
    selectedFilters: selectedFiltersReducer,
    mpVehicles: mpVehiclesReducer,
  }),
  specifications: specificationsReducer,
  news: newsReducer,
  volumes: volumesReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
