import { mpReportSpecsSection } from 'config'
import {
  getColumnPerCar,
  getColumnTitles,
} from 'helper/excelExport/excelExportHelper'
import { getGroupedMetaDataRows } from 'helper/getGroupedMetaDataRows'
import { getVehicleReportFilter } from 'helper/getVehicleReportFilter'
import { ExcelExportBody } from 'models/excelExport/ExcelExportBody'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'

export const MonthlyPaymentsPrepareExcelData = (
  vehicles: any,
  mpReportMetaData: any,
  styleSheetName: string
): ExcelExportBody[] => {
  const body: ExcelExportBody = {
    sheetName: styleSheetName,
    table: [],
  }
  let columnNumber
  const rowsMetaData: any =
    vehicles && vehicles.length > 0 && vehicles[0].scenarios.length > 0
      ? getGroupedMetaDataRows(vehicles)
      : undefined
  const scenariosList: any[] = []
  const specsList: any[] = []
  for (let j = 0; j < vehicles.length; j++) {
    for (let k = 0; k < vehicles[j].scenarios.length; k++) {
      const scenerio = {
        ...vehicles[j].scenarios[k],
        uniqueIdentity: vehicles[j].uniqueIdentity,
        dataDate: vehicles[j].dataDate,
      }
      scenariosList.push(scenerio)
      specsList.push(vehicles[j])
    }
  }

  const specFieldNames = mpReportSpecsSection.metadataRowNames.filter(
    (fieldName) => specsList.some((spec) => spec[fieldName] != null)
  )

  body.table.push(
    getColumnTitles(specFieldNames, rowsMetaData, mpReportMetaData)
  )

  for (let i = 0; i < scenariosList.length; i++) {
    columnNumber = i + 2
    body.table.push(
      getColumnPerCar(
        columnNumber,
        vehicles.filter((vehicle: MpVehicle) =>
          getVehicleReportFilter(scenariosList[i], vehicle)
        )[0],
        scenariosList[i],
        specsList[i],
        specFieldNames,
        rowsMetaData,
        mpReportMetaData
      )
    )
  }
  return [body]
}
