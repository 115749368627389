import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import { FilterType } from 'models/Filters/FilterType'
import { AdvancedFilterType } from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import { KeyText } from 'models/Specifications/KeyText'
import { PriceFilterStats } from 'models/Specifications/PriceFilterStats'
import { IVehiclePriceSettings } from 'models/Specifications/SpecsUserSettings'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { IMappingObject } from './IMappingObject'
import {
  CO2TonnesCountryList,
  EU28PlusWltpCountries,
} from './vehicleSelectionConstants'

export const marketFilterName = 'Market'
export const makeFilterName = 'Make'
export const modelFilterName = 'Model'
const segmentationFilterName = 'Segmentation'
const bodyTypeFilterName = 'Body type'
const powerTrainTypeFilterName = 'Powertrain type'
const wltpFilterName = 'WLTP Combined CO2'
const noOfDoorsFilterName = 'No.of doors'
const transmissionFilterName = 'Transmission'
const fuelTypeFilterName = 'Fuel type'
const trimLevelFilterName = 'Trim level'
const driveWheelFilterName = 'Drive wheel'
const wheelBaseFilterName = 'Wheel Base'
const modelYearFilterName = 'Model Year'
const engineLitresFilterName = 'Engine litres'
const powerKwFilterName = 'Power KW'
const powerHpFilterName = 'Power HP/PS'
const co2FilterName = 'CO2'
const usaCo2FilterName = 'CO2 (Annual Tons)'
const priceFilterName = 'Price'

export const specificationsFilters = [
  { name: marketFilterName, type: FilterType.Market },
  {
    name: makeFilterName,
    type: FilterType.MultiSelect,
    schemaId: 111,
    localSchemaId: 128,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: modelFilterName,
    type: FilterType.MultiSelect,
    schemaId: 112,
    localSchemaId: 129,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: segmentationFilterName,
    type: FilterType.MultiSelect,
    schemaId: 176,
    localSchemaId: 176,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: bodyTypeFilterName,
    type: FilterType.MultiSelect,
    schemaId: 603,
    localSchemaId: 606,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: powerTrainTypeFilterName,
    type: FilterType.MultiSelect,
    schemaId: 48602,
    localSchemaId: 48602,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: wltpFilterName,
    type: FilterType.Slider,
    schemaId: 7603,
    localSchemaId: 7603,
    advancedFilterType: AdvancedFilterType.Range,
  },
  {
    name: transmissionFilterName,
    type: FilterType.MultiSelect,
    schemaId: 20624,
    localSchemaId: 20624,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: fuelTypeFilterName,
    type: FilterType.MultiSelect,
    schemaId: 8702,
    localSchemaId: 8702,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: wheelBaseFilterName,
    type: FilterType.MultiSelect,
    schemaId: 608,
    localSchemaId: 608,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: modelYearFilterName,
    type: FilterType.MultiSelect,
    schemaId: 108,
    localSchemaId: 108,
    advancedFilterType: AdvancedFilterType.List,
  },
  {
    name: powerKwFilterName,
    type: FilterType.Slider,
    schemaId: 15303,
    localSchemaId: 15303,
    advancedFilterType: AdvancedFilterType.Range,
  },
  {
    name: powerHpFilterName,
    type: FilterType.Slider,
    schemaId: 15304,
    localSchemaId: 15304,
    advancedFilterType: AdvancedFilterType.Range,
  },
  {
    name: co2FilterName,
    type: FilterType.Slider,
    schemaId: 7603,
    localSchemaId: 7603,
    advancedFilterType: AdvancedFilterType.Range,
  },
  {
    name: usaCo2FilterName,
    type: FilterType.Slider,
    schemaId: 7618,
    localSchemaId: 7618,
    advancedFilterType: AdvancedFilterType.Range,
  },
]

export const specFilterNamesToFacetFields: IMappingObject = {
  [makeFilterName]: 'makefilterlist',
  [modelFilterName]: 'makeModelFilterList',
  [segmentationFilterName]: 'regionalsegmentlist',
  [bodyTypeFilterName]: 'bodyTypefilterlist',
  [powerTrainTypeFilterName]: 'powertraintypelist',
  [wltpFilterName]: 'wltpcombinedCo2readingList',
  [noOfDoorsFilterName]: 'doorfilterlist',
  [transmissionFilterName]: 'transmissionfilterlist',
  [fuelTypeFilterName]: 'fuelTypefilterlist',
  [trimLevelFilterName]: 'trimlevelfilterlist',
  [driveWheelFilterName]: 'drivewheelsfilterlist',
  [wheelBaseFilterName]: 'wheelbasefilterlist',
  [modelYearFilterName]: 'modelyearfilterlist',
  [engineLitresFilterName]: 'enginelitreslist',
  [powerKwFilterName]: 'powerkwfilter',
  [powerHpFilterName]: 'powerhpsfilter',
  [co2FilterName]: 'co2readinglist',
  [usaCo2FilterName]: 'usaco2readinglist',
  [priceFilterName]: 'pricestatsfilter',
}

const keyTextFilterNamesToRequestFields: IMappingObject = {
  [makeFilterName]: 'makeList',
  [modelFilterName]: 'modelList',
  [segmentationFilterName]: 'regionalSegmentList',
  [bodyTypeFilterName]: 'bodyTypeList',
  [powerTrainTypeFilterName]: 'powertraintypeList',
  [noOfDoorsFilterName]: 'doorList',
  [transmissionFilterName]: 'transmissionList',
  [fuelTypeFilterName]: 'fuelTypeList',
  [trimLevelFilterName]: 'trimLevelList',
  [driveWheelFilterName]: 'driveWheelList',
  [wheelBaseFilterName]: 'wheelBaseList',
  [modelYearFilterName]: 'modelYearList',
}

const rangeFilterNamesToRequestFields: IMappingObject = {
  [wltpFilterName]: 'wltpCombinedCo2ReadingFilter',
  [engineLitresFilterName]: 'enginelitreFilter',
  [powerKwFilterName]: 'powerKwFilter',
  [powerHpFilterName]: 'powerHpFilter',
  [co2FilterName]: 'co2readingFilter',
  [usaCo2FilterName]: 'usaCo2readingFilter',
  [priceFilterName]: 'pricestatsFilter',
}

export const specFilterNamesToTranslationTags: IMappingObject = {
  [marketFilterName]: 'JNT_Market',
  [makeFilterName]: 'JNT_make',
  [modelFilterName]: 'JNT_model',
  [segmentationFilterName]: 'JNT_Segment',
  [bodyTypeFilterName]: 'JNT_body',
  [powerTrainTypeFilterName]: 'JNT_PtrainType',
  [wltpFilterName]: 'JNT_Wltp_Combined_CO2',
  [noOfDoorsFilterName]: 'JNT_NumOfDoor',
  [transmissionFilterName]: 'JNT_trans',
  [fuelTypeFilterName]: 'JNT_fueltype',
  [trimLevelFilterName]: 'JNT_trimlevel',
  [driveWheelFilterName]: 'JNT_Drivewheel',
  [wheelBaseFilterName]: 'JNT_WheelBase',
  [modelYearFilterName]: 'JNT_ModelYear',
  [engineLitresFilterName]: 'JNT_Enginelit',
  [powerKwFilterName]: 'JNT_PKW',
  [powerHpFilterName]: 'JNT_PHP',
  [co2FilterName]: 'JNT_CO2',
  [priceFilterName]: 'JNT_price',
}

export const getKeyTextFilters = (
  filters: IMappingObject<ISelectedFilterOption[]>
): IMappingObject<KeyText[]> => {
  const result: IMappingObject<KeyText[]> = {}

  Object.entries(keyTextFilterNamesToRequestFields).forEach(
    ([filterName, fieldName]) => {
      const selectedOptions = filters[filterName]

      if (selectedOptions && selectedOptions.length > 0) {
        result[fieldName] = selectedOptions.map((o) => ({ key: o.value }))
      }
    }
  )

  return result
}

export const getRangeFilters = (
  filters: IMappingObject<ISelectedFilterOption[]>
): IMappingObject<PriceFilterStats> => {
  const result: IMappingObject<PriceFilterStats> = {}

  Object.entries(rangeFilterNamesToRequestFields).forEach(
    ([filterName, fieldName]) => {
      const selectedOptions = filters[filterName]

      if (selectedOptions && selectedOptions.length == 1) {
        result[fieldName] = {
          min: selectedOptions[0].min ?? 0,
          max: selectedOptions[0].max ?? 0,
        }
      }
    }
  )

  return result
}

const columnsToExcludeMapping: IMappingObject<(market: string) => boolean> = {
  wltpCombinedCo2Reading: (market) => !EU28PlusWltpCountries.includes(market),
  co2reading: (market) => CO2TonnesCountryList.includes(market),
  usaCo2reading: (market) => !CO2TonnesCountryList.includes(market),
}

const filtersToExcludeMapping: IMappingObject<(market: string) => boolean> = {
  [wltpFilterName]: (market) => !EU28PlusWltpCountries.includes(market),
  [co2FilterName]: (market) => CO2TonnesCountryList.includes(market),
  [usaCo2FilterName]: (market) => !CO2TonnesCountryList.includes(market),
}

export const getColumnsToExclude = (lastSelectedMarket: string): string[] =>
  Object.keys(columnsToExcludeMapping).filter((key) =>
    columnsToExcludeMapping[key](lastSelectedMarket)
  )

export const getFiltersToExclude = (lastSelectedMarket: string): string[] =>
  Object.keys(filtersToExcludeMapping).filter((key) =>
    filtersToExcludeMapping[key](lastSelectedMarket)
  )

const globalToLocal: IMappingObject = {
  makeGlobal: 'makeLocal',
  modelGlobal: 'modelLocal',
  derivativeGlobal: 'derivativeLocal',
  bodyCodeGlobal: 'bodyCodeLocal',
}

export const mapToLocal = (orderBy: string[]): string[] =>
  orderBy.map((orderByField) =>
    orderByField
      .split(' ')
      .map((field) => (globalToLocal[field] ? globalToLocal[field] : field))
      .join(' ')
  )

export const getSpecificationDisplayPrice = (
  vehiclePriceSettings: IVehiclePriceSettings,
  translations: AppTextTranslations
): string =>
  vehiclePriceSettings.isRetailPrice
    ? translations.JNT_RetailPrice
    : vehiclePriceSettings.isBasePrice
      ? translations.JNT_BasePrice
      : vehiclePriceSettings.isCountrySpecificPrice
        ? translations.JNT_Country_Specific_Price
        : vehiclePriceSettings.isRetailPriceinclDelivery
          ? translations.JNT_RetailDeliveryPrice
          : translations.JNT_price

export const hideFilterForQuadricycleView = [wheelBaseFilterName]
