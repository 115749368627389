import Loader from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { ModalComponent } from 'components/ModalComponent'
import { removeDuplicates } from 'helper/arrayHelper'
import {
  useAddRecipeAndUpdateBrochure,
  useGetBrochureVehicles,
  useGetOptionBuildRulesQuery,
} from 'hooks/carSpecs'
import { InstructionType } from 'models/Specifications/Options/DetailedAction'
import { SecondaryOptionsData } from 'models/Specifications/Options/OptionsBuildRequest'
import {
  ConditionsV2,
  RuleType,
} from 'models/Specifications/Options/OptionsBuildRuleResponse'
import { SpecsUserShipLog } from 'models/UserShipLog/UserShipLog'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { getSpecsUserState } from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import userShipLogService from 'services/UserShipLog/UserShipLogService'
import { SpecificationsOptionBuild } from './SpecificationsOptionBuild'
export interface ISpecsOptionBuildModalProps {
  title: string
  optionCategoryName: string
  optionId: number
  vehicleId: number
  isOpen: boolean
  onClose: () => void
}

export const SpecificationsOptionsBuildModal: React.FC<
  ISpecsOptionBuildModalProps
> = ({
  title,
  optionCategoryName,
  optionId,
  vehicleId,
  isOpen,
  onClose,
}: ISpecsOptionBuildModalProps) => {
  const translations = useAppSelector(getTranslations)
  const specsUserData = useAppSelector(getSpecsUserState)
  const { data: brochureResponse } = useGetBrochureVehicles()

  // contains options which are being built in current session, updated as option tree is traversed with BFS approach
  const [optionQueue, setOptionQueue] = useState([optionId])
  const [currentOptionIndex, setCurrentOptionIndex] = useState(-1)

  // initially contains choiceOptions.requires list, reduced as choices are made by user
  const [choicesToProcess, setChoicesToProcess] = useState<number[][]>([])
  const [currentChoice, setCurrentChoice] = useState<number[]>([])
  const [selectedOptionId, setSelectedOptionId] = useState<number>()

  const [conditions, setConditions] = useState<ConditionsV2[]>([])

  const [secondaries, setSecondaries] = useState<SecondaryOptionsData[]>([])
  // Build button should be pressed at least once (when there are no choices to make)
  const [readyToBuild, setReadyToBuild] = useState(false)

  const { data: { tree, ledger } = { tree: [], ledger: [] }, isLoading } =
    useGetOptionBuildRulesQuery(optionId, vehicleId)

  const {
    mutateAsync: addRecipeAndUpdateBrochure,
    isLoading: isLoadingBuildOptions,
  } = useAddRecipeAndUpdateBrochure()

  const { mutate: logSpecsBuildOptionClick } = useMutation(
    (log: SpecsUserShipLog) => userShipLogService.logSpecsBuildOptionClick(log)
  )

  useEffect(() => {
    if (choicesToProcess.length > 0) {
      processNextChoice()
    } else if (conditions.length > 0) {
      processNextCondition()
    } else if (currentOptionIndex < optionQueue.length - 1) {
      processNextOption()
    } else if (readyToBuild) {
      addRecipeAndUpdateBrochure({
        userInstruction: InstructionType.BuildOption,
        optionId: optionId,
        vehicleId: vehicleId,
        secondariesData: secondaries,
      }).then(onCloseOptionsBuild)
    }
  }, [
    isLoading,
    choicesToProcess,
    conditions,
    currentOptionIndex,
    readyToBuild,
  ])

  const processNextOption = (): void => {
    if (isLoading || (currentOptionIndex > -1 && !readyToBuild)) return

    const newOptionIndex = currentOptionIndex + 1

    const option = tree.find((o) => o.optionId == optionQueue[newOptionIndex])

    // add all included options
    const includes = option?.choiceOptions?.Includes?.flat() ?? []
    addOptionsToBuild(includes, true)

    const requires = option?.choiceOptions?.requires ?? []
    setChoicesToProcess(requires)

    setConditions(option?.conditions ?? [])

    setCurrentOptionIndex(newOptionIndex)
  }

  const processNextChoice = (): void => {
    // take the first option from every list
    // if at least 2 are different - present the choice to the user
    let currentChoice = choicesToProcess
      .filter((arr) => arr.length > 0)
      .map((arr) => arr[0])
      .filter(removeDuplicates)

    // check if there is option that was previously selected - if yes, autoselect it
    const previouslySelected = currentChoice.find((optionId) =>
      optionQueue.includes(optionId)
    )
    if (previouslySelected) {
      currentChoice = [previouslySelected]
      setChoicesToProcess(
        choicesToProcess
          .filter((x) => x[0] === previouslySelected)
          .filter((x) => x.length > 1)
          .map((x) => x.slice(1))
      )
    } else if (currentChoice.length == 1) {
      addOptionsToBuild(currentChoice, false)
      setChoicesToProcess(
        choicesToProcess.filter((x) => x.length > 1).map((x) => x.slice(1))
      )
    }
    setCurrentChoice(currentChoice)
    setSelectedOptionId(undefined)
  }

  const processNextCondition = (): void => {
    const condition = conditions[0]
    if (evaluateCondition(condition)) {
      switch (condition.ruleType) {
        case RuleType.Includes:
          addOptionsToBuild(condition.permutations.flat(), true)
          break
        case RuleType.requires:
          setChoicesToProcess(condition.permutations)
          break
      }
    }
    setConditions(conditions.slice(1))
  }

  const addOptionsToBuild = (
    optionIds: number[],
    isIncludes: boolean
  ): void => {
    const newIds = optionIds.filter((id) => !optionQueue.includes(id))

    setOptionQueue([...optionQueue, ...newIds])

    setSecondaries([
      ...secondaries,
      ...newIds.map((optionId) => ({ optionId, sourceIsIncludes: isIncludes })),
    ])
  }

  const evaluateCondition = (condition: ConditionsV2): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const array = getAllBuiltOptions() //This array is being used in eval(condition.conditionToEvaluate), Please don't remove it
    const isValidCondition =
      condition.conditionToEvaluate.match(/NULL/g) === null

    return isValidCondition && eval(condition.conditionToEvaluate)
  }

  const getAllBuiltOptions = (): number[] => {
    const primaryOptions =
      brochureResponse?.brochureVehicles.find(
        (v) => v.vehicleHeaderInfo.vehicleId == vehicleId
      )?.builtOptions ?? []

    const previouslyBuiltOptions = primaryOptions
      .concat(primaryOptions.flatMap((o) => o.secondaryOptions))
      .map((o) => o.optionId)

    return [...previouslyBuiltOptions, ...optionQueue]
  }

  const onBuildClick = (): void => {
    //if there is current choice made, process it
    if (currentChoice.length > 1 && selectedOptionId) {
      addOptionsToBuild([selectedOptionId], false)
      setChoicesToProcess(
        choicesToProcess
          .filter((x) => x[0] === selectedOptionId)
          .filter((x) => x.length > 1)
          .map((x) => x.slice(1))
      )
    }

    setReadyToBuild(true)
  }

  const onCloseOptionsBuild = (): void => {
    logSpecsBuildOptionClick({
      user: specsUserData,
      optionInfo: {
        vehicleId: vehicleId,
        optionId: optionId,
      },
    })
    onClose()
  }

  return (
    <>
      <ModalComponent
        modalVisible={isOpen}
        maxWidth="md"
        closeButton
        primaryButton
        primaryButtonName={translations.JNT_Build}
        title={title}
        onSecondaryClick={onClose}
        disablePrimaryButton={currentChoice.length > 1 && !selectedOptionId}
        onPrimaryClick={onBuildClick}
      >
        {isLoading ? (
          <Loader style={{ minHeight: '150px' }} />
        ) : (
          <SpecificationsOptionBuild
            optionCategoryName={optionCategoryName}
            currentOptionId={optionQueue[currentOptionIndex]}
            tree={tree}
            ledger={ledger}
            requireChoices={currentChoice}
            selectedOptionId={selectedOptionId}
            setSelectedOptionId={setSelectedOptionId}
          />
        )}
      </ModalComponent>
      <LoaderModal isOpen={isLoadingBuildOptions} />
    </>
  )
}
