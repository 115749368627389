import {
  JATOGroup,
  JATOTag,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import React from 'react'
import { StyledSelectedFilters } from './SelectedFilters.styles'

export interface ISelectedFiltersProps {
  id?: string
  selectedFilters: ISelectedFilterOption[]
  handleRemoveFilter: (filterOption: ISelectedFilterOption) => void
}

export const SelectedFilters: React.FC<ISelectedFiltersProps> = ({
  id = 'selectedFilters',
  selectedFilters,
  handleRemoveFilter,
}: ISelectedFiltersProps) => (
  <StyledSelectedFilters>
    <JATOGroup flexDirection="row">
      {selectedFilters.length > 0 && <JATOVerticalSpacer height={5} />}
      {selectedFilters.map((filter: ISelectedFilterOption) => {
        const label = filter.label ?? filter.filterName
        return (
          <JATOTag
            id={id + 'Tag'}
            key={filter.key}
            isDismissible
            variant="secondary"
            value={filter.value}
            onClick={() => handleRemoveFilter(filter)}
          >
            {label}:{filter.displayValue ?? filter.value}
          </JATOTag>
        )
      })}
    </JATOGroup>
  </StyledSelectedFilters>
)
