import styled from 'styled-components'

export const StyledWelcomInfo = styled.div`
  .modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }

  .modal-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #1b1d1f;
  }
  .modal-body {
    position: relative;
    padding: 15px;
  }
  .icon-style {
    color: #4392b5;
    margin: 2;
  }
  .link-text {
    color: #4392b5;
    cursor: pointer;
    display: inline;
  }
  .custom-span {
    font-size: 16px;
    font-family: 'Roboto Light';
    color: #c40005;
  }
`
