import styled from 'styled-components'

export const StyledBasket = styled.div`
  .pointer {
    cursor: 'pointer';
  }
  .e-grid .e-altrow {
    background-color: #fafafa;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #c40005;
    border-color: transparent;
    color: #fff;
  }

  .e-pager .e-currentitem,
  .e-pager .e-currentitem {
    background: #c40005;
    color: #fff;
    opacity: 1;
  }
`
