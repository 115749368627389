import React from 'react'
import { StyledNavigation } from './NavigationContainer.styles'

export interface INavigationContainerProps {
  left: React.ReactNode
  right: React.ReactNode
}

export const NavigationContainer: React.FC<INavigationContainerProps> = ({
  left: left,
  right: right,
}: INavigationContainerProps) => (
  <>
    <StyledNavigation>
      <div className="nav-container">
        <div className="nav-left">{left}</div>
        <div className="nav-right">{right}</div>
      </div>
    </StyledNavigation>
  </>
)
