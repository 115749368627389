import { JATODrawer, JATOHeading, JATOLink } from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import NewsCategoriesSelect from 'components/News/Alerts/NewsCategoriesSelect'
import NewsCountriesSelect from 'components/News/Alerts/NewsCountriesSelect'
import NewsMultiSelect from 'components/News/Alerts/NewsMultiSelect'
import NewsVehicleTypeSelect from 'components/News/Alerts/NewsVehicleTypeSelect'
import {
  NewsFilterName,
  getMakesValueList,
  getModelValueListForSelectedMake,
} from 'helper/newsHelper'
import { useGetMakesModelsByCountryCode } from 'hooks/news'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getNewsFilters,
  resetNewsFiltersToDefaults,
  setNewsSelectedFilter,
} from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { NewsDateRangeFilter } from '../NewsDateRangeFilter'
import { StyledNewsFiltersPanel } from './NewsFiltersPanel.styles'

export interface INewsFiltersPanelProps {
  isOpen: boolean
  onClose: () => void
}

export const NewsFiltersPanel: React.FC<INewsFiltersPanelProps> = ({
  isOpen,
  onClose,
}: INewsFiltersPanelProps) => {
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const newsFilters = useAppSelector(getNewsFilters)

  const resetFilters = (): void => {
    dispatch(resetNewsFiltersToDefaults())
  }

  const getValue = (filterName: string): string =>
    newsFilters.selectedFilters[filterName]?.join(',') ?? ''

  const onChange =
    (filterName: string) =>
    (value: string): void => {
      dispatch(
        setNewsSelectedFilter({
          [filterName]: value ? value.split(',') : [],
        })
      )
    }

  const onChangeCountry = (value: string): void => {
    dispatch(
      setNewsSelectedFilter({
        [NewsFilterName.Country]: value ? value.split(',') : [],
      })
    )
  }

  const { data: makesModelsResponse, isFetching: isFetchingMakesModels } =
    useGetMakesModelsByCountryCode(
      newsFilters.selectedFilters[NewsFilterName.Country] ?? []
    )

  const getSelectedMakes = (): string[] =>
    newsFilters.selectedFilters[NewsFilterName.Make] ?? []

  return (
    <StyledNewsFiltersPanel>
      <JATODrawer
        hasLightbox
        drawerWidth={450}
        isDrawerOpen={isOpen}
        onClose={onClose}
        header={() => (
          <div style={{ display: 'flex' }}>
            <JATOHeading as="h3">{translations.JNT_Filters}</JATOHeading>
            <JATOLink
              id="newsResetFiltersLink"
              textTransform="none"
              onClick={resetFilters}
            >
              {translations.JNT_Reset_All}
            </JATOLink>
          </div>
        )}
      >
        <div className="filtersContainer">
          <NewsCategoriesSelect
            value={getValue(NewsFilterName.Category)}
            onChange={onChange(NewsFilterName.Category)}
          />
          <NewsDateRangeFilter
            filterName={NewsFilterName.ReleaseDate}
            label={translations.JNT_PubDate}
          />
          <NewsVehicleTypeSelect
            value={getValue(NewsFilterName.Group)}
            onChange={onChange(NewsFilterName.Group)}
          />
          <NewsCountriesSelect
            value={getValue(NewsFilterName.Country)}
            onChange={onChangeCountry}
          />
          <NewsMultiSelect
            id="newsFiltersMakesSelect"
            label={`${translations.JNT_make}`}
            value={getValue(NewsFilterName.Make)}
            onChange={onChange(NewsFilterName.Make)}
            dropdownOptions={getMakesValueList(makesModelsResponse)}
            placeholder={translations.JNT_AllMakes}
          />
          <NewsMultiSelect
            id="newsFiltersModelsSelect"
            label={translations.JNT_model}
            value={getValue(NewsFilterName.Model)}
            onChange={onChange(NewsFilterName.Model)}
            dropdownOptions={getModelValueListForSelectedMake(
              makesModelsResponse,
              getSelectedMakes()
            )}
            disabled={getSelectedMakes().length === 0}
            placeholder={translations.JNT_AllModels}
          />
        </div>
      </JATODrawer>
      <LoaderModal isOpen={isOpen && isFetchingMakesModels} />
    </StyledNewsFiltersPanel>
  )
}
