import {
  BrochureReturnObject,
  BuiltOption,
  VehicleHeaderInfo,
} from 'models/Specifications/BrochureResponse'
import { ISpecsUserState } from 'models/Specifications/GetLicenceInfoResponse'
import { GetVehicleComparisonRequest } from 'models/Specifications/GetVehicleComparisonRequest'
import { removeDuplicates } from './arrayHelper'

export const getVehicleComparisonRequest = (
  vehicleHeaders: VehicleHeaderInfo[],
  benchmarkVehicleId: number,
  displayBuiltOptions: BuiltOption[] | undefined,
  specsUserData: ISpecsUserState
): GetVehicleComparisonRequest => {
  const databaseName = specsUserData?.settings?.lastSelectedMarket ?? ''

  const getVehicleComparisonRequest: GetVehicleComparisonRequest =
    vehicleHeaders.map((vehicleHeader: VehicleHeaderInfo) => ({
      VehicleName: `${vehicleHeader.vehiclePhotoPath.make} ${vehicleHeader.vehiclePhotoPath.model} - ${vehicleHeader.vehiclePhotoPath.yearModel} ${vehicleHeader.vehiclePhotoPath.versionLevel}`,
      VehicleId: vehicleHeader.vehicleId,

      OptionIds: getAllBuiltOptionsOnVehicle(
        vehicleHeader.vehicleId,
        displayBuiltOptions
      ),
      IsBenchmark: benchmarkVehicleId == vehicleHeader.vehicleId,
      SpecsDatabaseName: databaseName,
    }))

  return getVehicleComparisonRequest
}

const getAllBuiltOptionsOnVehicle = (
  vehicleId: any,
  displayBuiltOptions: BuiltOption[] | undefined
): any[] => {
  const builtOptions: number[] = []
  if (displayBuiltOptions && displayBuiltOptions.length > 0) {
    const vehicleOptions = displayBuiltOptions!.filter(function (
      value: BuiltOption
    ) {
      return value.vehicleId === vehicleId
    })
    vehicleOptions.forEach(function (optionCollection: BuiltOption) {
      builtOptions.push(Number(optionCollection.optionId))
      optionCollection.secondaryOptions.forEach(function (
        secondaryOption: BuiltOption
      ) {
        builtOptions.push(Number(secondaryOption.optionId))
      })
    })
  }

  return builtOptions
}

export const getCharacteristics = (
  categoryName: string,
  brochureVehicles: BrochureReturnObject | undefined
): string[] =>
  brochureVehicles?.brochureRows
    .filter((br) => br.translatedCategoryName == categoryName)
    .toSorted((a, b) => a.rowOrderId - b.rowOrderId)
    .map((br) => br.ebrochureText)
    .filter(removeDuplicates) ?? []
