import { IMappingObject } from 'helper/IMappingObject'
import {
  getNewsCategoryTranslations,
  NewsCategoryType,
} from 'models/News/NewsCategoryType'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'

enum PageType {
  CornerstoneReports = 'CornerstoneReports',
  WorkflowReports = 'WorkflowReports',
  SharedArticles = 'Shared',
  BookMarkArticles = 'Favourites',
  SearchResults = 'SearchResults',
  ViewSelection = 'ViewSelection',
  SavedSearches = 'SavedSearches',
  Alerts = 'Alerts',
}

export type NewsPage = NewsCategoryType | PageType
export const NewsPage = { ...NewsCategoryType, ...PageType }

export const getNewsPageTranslations = (
  t: AppTextTranslations
): IMappingObject => ({
  ...getNewsCategoryTranslations(t),
  [NewsPage.CornerstoneReports]: t.JNT_Link_Cornerstone,
  [NewsPage.WorkflowReports]: t.JNT_Link_WorkflowArt,
  [NewsPage.SharedArticles]: t.JNT_SharedArticles,
  [NewsPage.BookMarkArticles]: t.JNT_Favourites,
  [NewsPage.SearchResults]: t.JNT_Search_results,
})

const QuLevelModel = 'MO'
const QuLevelMake = 'MA'

export const getQuLevelForNewsPage = (category: string): string => {
  switch (category) {
    case NewsPage.CornerstoneReports:
      return QuLevelModel
    case NewsPage.WorkflowReports:
      return QuLevelMake
    default:
      return ''
  }
}
