import { CommonModal } from 'components/Modals/CommonModal'
import { SalesEnquiry } from 'components/Portal/SalesEnquiry'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledMyPageSalesEnquiryModal } from './MyPageSalesEnquiryModal.styles'

interface IModalProps {
  isOpen?: boolean
  onClose?: () => void
}

export const MyPageSalesEnquiryModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
}: IModalProps) => {
  const translations = useAppSelector(getTranslations)

  return (
    <CommonModal
      closeButton
      maxWidth="lg"
      isOpen={isOpen}
      onClose={onClose}
      title={translations.JNT_Portal_SalesEnquiry_Heading}
    >
      <StyledMyPageSalesEnquiryModal>
        <SalesEnquiry onClose={() => onClose && onClose()} />
      </StyledMyPageSalesEnquiryModal>
    </CommonModal>
  )
}
