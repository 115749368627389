import {
  JATOBasicCard,
  JATOButton,
  JATOButtonGroup,
  JATOGroup,
  JATOHeading,
  JATOSearchInput,
  JATOTheme
} from '@jato/ui-component-library'
import SaveIcon from 'assets/icons/icon_save.svg'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import Loader from 'components/Loader'
import VolumesDatabaseTypeSelection from 'components/Volumes/VolumesDatabaseTypeSelection'
import VolumesNavigation from 'components/Volumes/VolumesNavigation'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import {
  useGetVehicleVolumeGroups,
  useGetVehicleVolumeTypes,
  useRestoreToDefaultVehicleVolumeGroups,
  useSaveVehicleVolumeGroup,
} from 'hooks/volumes'
import { CreateOrUpdateVehicleVolumeRequest } from 'models/Volumes/CreateOrUpdateVehicleVolumeRequest'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledVolumesVehiclesVolumesType } from './VolumesVehiclesVolumesType.styles'

export const VolumesVehiclesVolumesType: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const [databaseType, setDatabaseType] = useState(VolumesDatabaseType.ModelMix)
  const [searchText, setSearchText] = useState('')
  const [selectedDatasets, setSelectedDatasets] = useState<number[]>([])

  const { data: groups, isFetching: isFetchingGroups } =
    useGetVehicleVolumeGroups()
  const { data: datasets, isFetching: isFetchingDatasets } =
    useGetVehicleVolumeTypes()
  const { mutateAsync: saveGroup, isLoading: isSaving } =
    useSaveVehicleVolumeGroup()
  const { mutateAsync: restoreToDefault, isLoading: isUpdating } =
    useRestoreToDefaultVehicleVolumeGroups()

  const isFetching =
    isFetchingGroups || isFetchingDatasets || isSaving || isUpdating

  useEffect(() => {
    const datasets =
      groups
        ?.find((g) => g.productGroup === databaseType)
        ?.dataSetItems.map((d) => d.dataSetItemId) ?? []

    setSelectedDatasets(datasets)
  }, [groups, databaseType])

  const onSelectionOptionChange = (databaseType: VolumesDatabaseType): void => {
    setDatabaseType(databaseType)
  }

  const onSelectedDatasetsChange = (dataSetId: number): void => {
    const datasets = selectedDatasets.includes(dataSetId)
      ? selectedDatasets.filter((d) => d != dataSetId)
      : [...selectedDatasets, dataSetId]

    setSelectedDatasets(datasets)
  }

  const onReturnToDefaultClick = async (): Promise<void> => {
    const { isSuccess, responseMessage } = await restoreToDefault(databaseType)
    if (isSuccess) {
      showSuccessToast(
        translations.JNT_Success,
        translations.JNT_UpdatedSuccess
      )
    } else {
      showErrorToast(translations.JNT_Error, responseMessage)
    }
  }

  const onSaveClick = async (): Promise<void> => {
    const request: CreateOrUpdateVehicleVolumeRequest = {
      productGroup: databaseType,
      dataSetItems:
        datasets
          ?.filter((d) => d.pgProductGroup == databaseType)
          .filter((d) => selectedDatasets.includes(d.dstId))
          .map((d) => ({ dataSetItemId: d.dstId, country: d.pCtyId })) ?? [],
    }
    const { isSuccess, responseMessage } = await saveGroup(request)
    if (isSuccess) {
      showSuccessToast(translations.JNT_Success, translations.JNT_SavedSuccess)
    } else {
      showErrorToast(translations.JNT_Error, responseMessage)
    }
  }

  return (
    <StyledVolumesVehiclesVolumesType>
      <VolumesNavigation title={translations.JNT_Volumes_QG_Dataset} />
      <div className="volumesContainer">
        <JATOBasicCard
          footer={() => (
            <JATOButtonGroup justifyContent="flex-end">
              <JATOButton
                id="volumesVehiclesVolumesTypeReturnToDefaultButton"
                variant="secondary"
                onClick={onReturnToDefaultClick}
              >
                {translations.JNT_Volumes_Return_to_default}
              </JATOButton>
              <JATOButton
                id="volumesVehiclesVolumesTypeSaveButton"
                variant="primary"
                onClick={onSaveClick}
              >
                {translations.JNT_save}
                <img
                  src={SaveIcon}
                  style={{ marginLeft: JATOTheme.space[2] }}
                />
              </JATOButton>
            </JATOButtonGroup>
          )}
        >
          <JATOHeading as="h3" fontWeight="medium" marginTop="-24px">
            {translations.JNT_Volumes_QG_Dataset}
          </JATOHeading>
          <JATOGroup flexDirection="row" className="selectionRadioGroup">
            <VolumesDatabaseTypeSelection
              value={databaseType}
              onChange={onSelectionOptionChange}
            />
          </JATOGroup>

          <JATOGroup flexDirection="column" className="datasetsContainer">
            <JATOSearchInput
              id="volumesVehiclesVolumesTypeSearchInput"
              hasIcon
              width={450}
              onChange={setSearchText}
              placeholder={translations.JNT_SRCH_Search}
              value={searchText}
            />
            {isFetching ? (
              <Loader style={{ minHeight: 'calc(100vh - 450px)' }} />
            ) : (
              datasets &&
              datasets
                .filter((t) => t.pgProductGroup == databaseType)
                .filter((t) =>
                  t.dsShortDescrip
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                .sort((a, b) =>
                  a.dsShortDescrip.localeCompare(b.dsShortDescrip)
                )
                .map((t) => (
                  <CheckboxWithGtm
                    key={t.dstId}
                    id="volumesVehiclesVolumesTypeCheckbox"
                    value={t.dstId.toString()}
                    label={t.dsShortDescrip}
                    checked={selectedDatasets.includes(t.dstId)}
                    onChange={() => onSelectedDatasetsChange(t.dstId)}
                  >
                    {t.dsShortDescrip}
                  </CheckboxWithGtm>
                ))
            )}
          </JATOGroup>
        </JATOBasicCard>
      </div>
    </StyledVolumesVehiclesVolumesType>
  )
}
