import { JATOIcon } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

interface INewsMetadataDateProps {
  date: string
}

export const NewsMetadataDate: React.FC<INewsMetadataDateProps> = ({
  date,
}: INewsMetadataDateProps) => {
  const translations = useAppSelector(getTranslations)
  const formattedDate = date.substring(0, 10)
  return (
    <>
      <JATOIcon iconName="custom_calendar" iconSize={14} />
      <span>
        {translations.JNT_NWS_Date}: {formattedDate}
      </span>
    </>
  )
}
