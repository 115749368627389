import { JATOButton } from '@jato/ui-component-library'
import TrashIcon from 'assets/icons/icon_trash.svg'
import React from 'react'
import { StyledNewsRemoveShareButton } from './NewsRemoveShareButton.styles'

export interface INewsRemoveShareButtonProps {
  onChange: () => void
}

export const NewsRemoveShareButton: React.FC<INewsRemoveShareButtonProps> = ({
  onChange,
}: INewsRemoveShareButtonProps) => (
  <StyledNewsRemoveShareButton>
    <JATOButton
      id="newsRemoveShareButton"
      variant="ghost"
      display="flex"
      onClick={onChange}
    >
      <img src={TrashIcon} width="16" />
    </JATOButton>
  </StyledNewsRemoveShareButton>
)
