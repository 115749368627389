import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsGrid = styled.div`
  .articleLink {
    font-size: 14px;
    font-weight: 500;
    color: ${JATOTheme.colors.blue};
    text-transform: none;
    text-decoration: none;
    display: block;
  }

  .subHeadlineLink {
    font-size: 12px;
    font-weight: 400;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: white;
    border-color: transparent;
    color: black;
  }

  .e-headerchkcelldiv {
    display: none;
  }
`
