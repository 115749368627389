import { DropdownWithGtm } from 'components/Gtm/DropdownWithGtm'
import { MultiSelectWithGtm } from 'components/Gtm/MultiSelectWithGtm'
import { SliderWithGtm } from 'components/Gtm/SliderWithGtm'
import { fluid } from 'config'
import { gtmLogSpecsFiltersChange } from 'helper/gtm'
import { IMappingObject } from 'helper/IMappingObject'
import {
  getFiltersToExclude,
  hideFilterForQuadricycleView,
  makeFilterName,
  marketFilterName,
  modelFilterName,
  specFilterNamesToFacetFields,
  specFilterNamesToTranslationTags,
  specificationsFilters,
} from 'helper/specificationsFiltersHelper'
import { useUpdateUserSettings } from 'hooks/specsUserSettings'
import {
  IFilterOption
} from 'models/Filters/FilterOption'
import { FilterParentType, FilterType } from 'models/Filters/FilterType'
import { KeyText } from 'models/Specifications/KeyText'
import { PriceFilterStats } from 'models/Specifications/PriceFilterStats'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getAdvancedFilters,
  getSpecFilters,
  getSpecsUserState,
  resetFiltersToDefaults,
  setSpecFilters,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'

interface ISpecFiltersProps {
  facetsData?: IMappingObject<KeyText[]>
  rangeFilters?: IMappingObject<PriceFilterStats>
}

export const SpecFilters: React.FC<ISpecFiltersProps> = (params) => {
  const dispatch = useAppDispatch()
  const specFilters = useAppSelector(getSpecFilters)
  const specsAdvancedFilters = useAppSelector(getAdvancedFilters)
  const specsUserState = useAppSelector(getSpecsUserState)
  const translations = useAppSelector(getTranslations)
  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const hideFilters = Boolean(
    JSON.parse(searchParams.get('hideFilters') ?? 'true')
  )

  const compareByDisplayValue = (a: IFilterOption, b: IFilterOption): number =>
    a.displayValue < b.displayValue
      ? -1
      : a.displayValue > b.displayValue
        ? 1
        : 0

  const compareByGroupAndDisplayValue = (
    a: IFilterOption,
    b: IFilterOption
  ): number =>
    a.group == undefined || b.group == undefined || a.group == b.group
      ? compareByDisplayValue(a, b)
      : a.group < b.group
        ? -1
        : 1

  const getMarketFilterOptions = (): IFilterOption[] =>
    specsUserState.specsDbMarkets?.map((facet: KeyText) => ({
      key: uuid(),
      value: facet.key,
      displayValue: facet.text ?? facet.key,
      filterParentType: FilterParentType.Specifications,
      filterName: marketFilterName,
      count: facet.count,
    }))

  const getSpecFilterOptions = (filterName: string): IFilterOption[] => {
    const hasCachedOptions =
      specFilters.cachedOptions[0]?.filterName === filterName
    if (hasCachedOptions) {
      return specFilters.cachedOptions
    }

    const fieldName = specFilterNamesToFacetFields[filterName]
    const facets = params?.facetsData && params.facetsData[fieldName]

    const options: IFilterOption[] =
      facets?.map((facet: KeyText) => ({
        key: uuid(),
        value: facet.key,
        displayValue: facet.text ?? facet.key,
        filterParentType: FilterParentType.Specifications,
        filterName: filterName,
        group: facet.make ? `${facet.make}  ` : undefined,
        count: facet.count,
      })) ?? []

    if (!hideFilters) {
      // add options which are already selected but missing in facets
      specFilters.filters[filterName]
        ?.filter((f) => !options.some((o) => o.value === f.value))
        .forEach((f) => {
          options.push({ ...f, key: uuid(), count: 0 })
        })
    }

    return options.sort(compareByGroupAndDisplayValue)
  }

  const getSliderOptions = (
    filterName: string
  ): PriceFilterStats | undefined => {
    const fieldName = specFilterNamesToFacetFields[filterName]

    const values =
      specFilters.filters[filterName] &&
      specFilters.filters[filterName][0]?.value
        .split('|')
        .map((value) => Number(value))

    return (
      (values && { min: values[0], max: values[1] }) ??
      (params?.rangeFilters && params.rangeFilters[fieldName])
    )
  }

  const getFilterValue = (filterName: string): string =>
    hideFilters
      ? getFilterValueHide(filterName)
      : getFilterValueShow(filterName)

  const getFilterValueShow = (filterName: string): string =>
    specFilters.filters[filterName]?.map((f) => f.value).join(',')

  const getFilterValueHide = (filterName: string): string => {
    const fieldName = specFilterNamesToFacetFields[filterName]
    const facets =
      params?.facetsData && params.facetsData[fieldName].map((f) => f.key)

    return specFilters.filters[filterName]
      ?.map((f) => f.value)
      .filter((v) => facets?.includes(v))
      .join(',')
  }

  const setFiltersState = (
    filterName: string,
    selectedOptions: IFilterOption[],
    options: IFilterOption[]
  ): void => {
    const updatedFilters = {
      ...specFilters.filters,
      [filterName]: selectedOptions,
    }

    const specFiltersUpdate = {
      ...specFilters,
      filters: updatedFilters,
      pageSkip: 0, //reset to first page when filters changed
      cachedOptions: selectedOptions.length == 0 ? [] : options,
    }

    dispatch(setSpecFilters(specFiltersUpdate))
    gtmLogSpecsFiltersChange(updatedFilters)
  }

  const onChangeMarket = (selectedMarket: string): void => {
    dispatch(resetFiltersToDefaults())

    const userSettingsUpdate = {
      ...specsUserState.settings,
      lastSelectedMarket: selectedMarket,
    }

    updateUserSettings(userSettingsUpdate)
  }

  const onChangeSlider = (filterName: string, value: number[]): void => {
    const [min, max] = value

    const selectedRangeFilter = {
      key: uuid(),
      displayValue: `${min} | ${max}`,
      value: `${min}|${max}`,
      filterName: filterName,
      filterParentType: FilterParentType.Specifications,
      max: max,
      min: min,
    }

    const updatedFilters = {
      ...specFilters.filters,
      [filterName]: [selectedRangeFilter],
    }

    const specFiltersUpdate = {
      ...specFilters,
      filters: updatedFilters,
      pageSkip: 0, //reset to first page when filters changed
      cachedOptions: [],
    }

    dispatch(setSpecFilters(specFiltersUpdate))

    gtmLogSpecsFiltersChange(updatedFilters)
  }

  const isFilterDisabled = (filterName: string): boolean => {
    //disable Model filter if no Makes are selected
    const selectedMakes = specFilters.filters[makeFilterName] ?? []
    return filterName === modelFilterName && selectedMakes.length == 0
  }

  const getTranslatedLabel = (filterName: string): string => {
    const tag = specFilterNamesToTranslationTags[filterName]
    return translations[tag] ?? filterName
  }

  const filtersToExclude = getFiltersToExclude(
    specsUserState?.settings?.lastSelectedMarket ?? ''
  )

  const filteredSpecificationsFilters = specificationsFilters.filter(
    (f) =>
      !filtersToExclude.includes(f.name) &&
      (!specsUserState?.isQuadricycleCustomer ||
        !hideFilterForQuadricycleView.includes(f.name))
  )

  return (
    <Container fluid={fluid}>
      <Row>
        <Col>
          <div id="filter-container">
            {filteredSpecificationsFilters.map((filter) => {
              switch (filter.type) {
                case FilterType.Market:
                  return (
                    <DropdownWithGtm
                      key={filter.name}
                      label={getTranslatedLabel(filter.name)}
                      filterName={filter.name}
                      id="specsFilters"
                      options={getMarketFilterOptions()}
                      value={specsUserState?.settings?.lastSelectedMarket}
                      onChange={onChangeMarket}
                      showSelectedValuesAsTags={false}
                    />
                  )
                case FilterType.MultiSelect:
                  const options = getSpecFilterOptions(filter.name)
                  return (
                    <MultiSelectWithGtm
                      key={filter.name}
                      id="specsFilters"
                      label={getTranslatedLabel(filter.name)}
                      filterName={filter.name}
                      value={getFilterValue(filter.name)}
                      options={options}
                      showCount={true}
                      isDisabled={isFilterDisabled(filter.name)}
                      handleOnChange={(selectedOptions) =>
                        setFiltersState(filter.name, selectedOptions, options)
                      }
                    />
                  )
                case FilterType.Slider:
                  return (
                    <SliderWithGtm
                      key={filter.name}
                      id="specsFilters"
                      label={getTranslatedLabel(filter.name)}
                      filterName={filter.name}
                      value={getSliderOptions(filter.name)}
                      onChange={(value) => onChangeSlider(filter.name, value)}
                    />
                  )
              }
            })}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
