import { JATOButton } from '@jato/ui-component-library'
import TrashIcon from 'assets/icons/icon_trash.svg'
import React from 'react'
import { StyledNewsRemoveBookmarkButton } from './NewsRemoveBookmarkButton.styles'

export interface INewsBookmarkButtonProps {
  onChange: () => void
}

export const NewsRemoveBookmarkButton: React.FC<INewsBookmarkButtonProps> = ({
  onChange,
}: INewsBookmarkButtonProps) => (
  <StyledNewsRemoveBookmarkButton>
    <JATOButton
      id="newsRemoveBookmarkButton"
      variant="ghost"
      display="flex"
      onClick={onChange}
    >
      <img src={TrashIcon} width="16" />
    </JATOButton>
  </StyledNewsRemoveBookmarkButton>
)
