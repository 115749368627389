import React, { useState } from 'react'
import {
  JATOGroup,
  JATOTextInput,
  JATOText,
  JATOTheme,
  JATOButton,
  JATOAnnouncement,
  JATOButtonIcon,
} from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { getTranslations } from 'redux/translations/translationsSlice'
import { useAppSelector } from 'redux/hook'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useResetPassword } from 'hooks/portal'
import { StyledForgotPasswordReset } from './ForgotPasswordReset.styles'
import { ForgotPasswordResetRequest } from 'models/Portal/ForgotPassword/ForgotPasswordResetModel'
import { useLocation } from 'react-router-dom'
import { minLength, validatePassword } from 'helper/portalHelper'
import { PasswordValidationMessage } from './PasswordValidationMessage'

export interface IForgotPasswordResetProps {
  onClose: () => void
}

export const ForgotPasswordReset: React.FC<IForgotPasswordResetProps> = ({
  onClose,
}: IForgotPasswordResetProps) => {
  const translations = useAppSelector(getTranslations)
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const guid = searchParams.get('guid') ?? ''
  const auto = searchParams.get('auto') ?? false

  const { languageId } = useAppSelector(selectCommonUserData)

  const { mutateAsync: resetPassword } = useResetPassword()

  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik<ForgotPasswordResetRequest>({
    initialValues: {
      guid: guid,
      languageId: languageId,
      auto: auto === 'true',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(translations.JNT_Portal_Form_Field_Required)
        .min(
          minLength,
          translations.JNT_Portal_CustReg_Password_MinLength_Message
        )
        .test(
          'validatePassword',
          translations.JNT_Portal_ModPwd_Desc,
          validatePassword
        ),
      confirmPassword: Yup.string()
        .required(translations.JNT_Portal_CustReg_Password_NoMatch_Message)
        // use oneOf to match one of the values inside the array.
        // use "ref" to get the value of passwrod.
        .oneOf(
          [Yup.ref('password')],
          translations.JNT_Portal_CustReg_Password_NoMatch_Message
        ),
    }),
    onSubmit: async (values: ForgotPasswordResetRequest) => {
      const passwordResetResponse = await resetPassword(values)

      if (passwordResetResponse.isSuccess) {
        JATOAnnouncement.Create({
          title: '',
          children: (
            <div
              dangerouslySetInnerHTML={{
                __html: passwordResetResponse.responseMessage,
              }}
            />
          ),
        })
        onClose()
      }
    },
  })

  return (
    <StyledForgotPasswordReset>
      <>
        {formik.isSubmitting && <LoaderModal isOpen={true} />}
        <div
          style={{ paddingBottom: '30px' }}
          dangerouslySetInnerHTML={{
            __html: translations.JNT_Portal_ModPwd_Desc,
          }}
        />
        <form onSubmit={formik.handleSubmit}>
          <JATOGroup flexDirection="column">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <JATOTextInput
                  id="password"
                  name="password"
                  label={translations.JNT_Portal_Login_Pwd}
                  title={translations.JNT_Portal_Login_Pwd}
                  type={showPassword ? 'text' : 'password'}
                  width={400}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                {formik.touched.password && formik.errors.password ? (
                  <JATOText
                    as="span"
                    fontSize={JATOTheme.fontSizes[1]}
                    color={JATOTheme.colors.primary}
                    marginTop={JATOTheme.space[2]}
                  >
                    {formik.errors.password}
                  </JATOText>
                ) : null}
              </div>
              <JATOButtonIcon
                iconName={
                  !showPassword ? 'baseline_lock' : 'baseline_lock_open'
                }
                title={
                  !showPassword
                    ? translations.JNT_Portal_Show_Password
                    : translations.JNT_Portal_Hide_Password
                }
                className="showPasswordButton"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <div>
              <JATOTextInput
                id="confirmPassword"
                name="confirmPassword"
                label={translations.JNT_Portal_Login_PasswordConfirm}
                title={translations.JNT_Portal_Login_PasswordConfirm}
                type={showPassword ? 'text' : 'password'}
                width={400}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <JATOText
                  as="span"
                  fontSize={JATOTheme.fontSizes[1]}
                  color={JATOTheme.colors.primary}
                  marginTop={JATOTheme.space[2]}
                >
                  {formik.errors.confirmPassword}
                </JATOText>
              ) : null}
            </div>
          </JATOGroup>
          <JATOGroup style={{ margin: `${JATOTheme.space[4]}px 0` }}>
            <PasswordValidationMessage password={formik.values.password} />
          </JATOGroup>
          <JATOGroup flexDirection="row" style={{ justifyContent: 'flex-end' }}>
            <JATOButton
              id="btnResetPassword"
              size="large"
              variant="primary"
              type="submit"
              disabled={!(formik.dirty && formik.isValid)}
            >
              {translations.JNT_Portal_ResetPassword_Reset_Label}
            </JATOButton>
          </JATOGroup>
        </form>
      </>
    </StyledForgotPasswordReset>
  )
}
