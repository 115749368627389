import { IMappingObject } from 'helper/IMappingObject'
import { NewsFilterName } from 'helper/newsHelper'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch } from 'redux/hook'
import { setNewsSearchState } from 'redux/news/newsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledNewsSearchViewAll } from './NewsSearchViewAll.styles'

export const NewsSearchViewAll: React.FC = () => {
  const { make, model, countryCode } = useParams<{
    make: string
    model: string
    countryCode: string
  }>()

  const dispatch = useAppDispatch()
  const history = useHistory()

  const searchFields: IMappingObject<string[]> = {
    [NewsFilterName.Country]: [countryCode],
    [NewsFilterName.Make]: [make],
    [NewsFilterName.Model]: [model],
  }

  dispatch(
    setNewsSearchState({
      searchFields: searchFields,
      isExactMatch: false,
      orderBy: ['releaseDate desc'],
      currentPage: 1,
    })
  )

  history.push(RoutePaths.NewsSearchResults)

  return <StyledNewsSearchViewAll />
}
