import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { DatePickerContainer } from 'components/DatePickerContainer/DatePickerContainer'
import React from 'react'
import { StyledNewsStartDateSelect } from './NewsStartDateSelect.styles'

interface INewsStartDateSelectProps {
  onChange: (value: string) => void
  selectedDate: Date
  label: string
}

export const NewsStartDateSelect: React.FC<INewsStartDateSelectProps> = ({
  onChange,
  selectedDate,
  label,
}: INewsStartDateSelectProps) => {
  const handleSetSelectedDate = (validFromDate: Date | null): void => {
    const modifiedDate = validFromDate
    if (modifiedDate) {
      const dateNow = new Date()
      modifiedDate.setHours(dateNow.getHours())
      modifiedDate.setMinutes(dateNow.getMinutes())
      modifiedDate.setSeconds(dateNow.getSeconds())
      const value = modifiedDate.toISOString()
      onChange(value)
    }
  }

  return (
    <StyledNewsStartDateSelect>
      <div className="startDateSelect">
        <JATOText as="div" fontSize={JATOTheme.fontSizes[1]}>
          {`${label}*`}
        </JATOText>
        <DatePickerContainer
          id="newsStartDatePicker"
          onDateSelected={handleSetSelectedDate}
          initialDate={selectedDate}
        />
      </div>
    </StyledNewsStartDateSelect>
  )
}
