import { JATOLink, JATOText } from '@jato/ui-component-library'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import MyPageChangePasswordModal from '../MyPageChangePasswordModal'
import MyPageSalesEnquiryModal from '../MyPageSalesEnquiryModal'
import MyPageYourCommentsModal from '../MyPageYourCommentsModal'
import MyPortalSettingsModal from '../MyPortalSettingsModal'
import { StyledMyPageWelcome } from './MyPageWelcome.styles'
import { technicalSupportUrl } from 'config'

export const MyPageWelcome: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const userData = useAppSelector(getVolumesUserState)

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false)
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false)
  const [isSalesEnquiryModalOpen, setSalesEnquiryModalOpen] = useState(false)
  const [isYourCommentsModalOpen, setYourCommentsModalOpen] = useState(false)

  return (
    <StyledMyPageWelcome>
      <JATOText as="p" fontSize={16} fontWeight="medium" textTransform="none">
        {userData.firstName} {userData.lastName}
      </JATOText>
      <JATOLink
        id="myPageChangePasswordLink"
        display="block"
        textTransform="none"
        onClick={() => setChangePasswordModalOpen(true)}
      >
        {translations.JNT_Portal_MJ_ChangePassword}
      </JATOLink>
      <JATOLink
        id="myPageChangeLanguageLink"
        display="block"
        textTransform="none"
        onClick={() => setSettingsModalOpen(true)}
      >
        {translations.JNT_Portal_MJ_ChangeLanguage}
      </JATOLink>
      <JATOLink
        id="myPageSalesEnquiryLink"
        display="block"
        textTransform="none"
        onClick={() => setSalesEnquiryModalOpen(true)}
      >
        {translations.JNT_Portal_Login_sales}
      </JATOLink>
      <JATOLink
        id="myPageCustomerSupportLink"
        display="block"
        textTransform="none"
        target="_blank"
        href={technicalSupportUrl}
      >
        {translations.JNT_Portal_MJ_TechnicalSupport}
      </JATOLink>
      <JATOLink
        id="myPageYourCommentsLink"
        display="block"
        textTransform="none"
        onClick={() => setYourCommentsModalOpen(true)}
      >
        {translations.JNT_Portal_MJ_YourComments}
      </JATOLink>
      {isChangePasswordModalOpen && (
        <MyPageChangePasswordModal
          isOpen={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
        />
      )}
      {isSettingsModalOpen && (
        <MyPortalSettingsModal
          isOpen={isSettingsModalOpen}
          onClose={() => setSettingsModalOpen(false)}
        />
      )}
      {isSalesEnquiryModalOpen && (
        <MyPageSalesEnquiryModal
          isOpen={isSalesEnquiryModalOpen}
          onClose={() => setSalesEnquiryModalOpen(false)}
        />
      )}
      {isYourCommentsModalOpen && (
        <MyPageYourCommentsModal
          isOpen={isYourCommentsModalOpen}
          onClose={() => setYourCommentsModalOpen(false)}
        />
      )}
    </StyledMyPageWelcome>
  )
}
