import { JATOButton } from '@jato/ui-component-library'
import { CommonModal, THandleClose } from 'components/Modals/CommonModal'
import { gtmLogNewsSearchAlertCreateEdit } from 'helper/gtm'
import { NewsAlertSubscriptionType, NewsFilterName } from 'helper/newsHelper'
import { showErrorToast, showSuccessToast } from 'helper/toastHelper'
import { useSaveSearchAndAlert } from 'hooks/news'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import { categoryToSubjectsMapping } from 'models/News/NewsCategoryType'
import { SaveSearchOptionsModel } from 'models/News/SaveSearchOptionsModel'
import React, { useState } from 'react'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getNewsSearchState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { NewsSaveSearch } from './NewsSaveSearch'
import { StyledNewsSaveSearchButton } from './NewsSaveSearchButton.styles'

const defaultModel: SaveSearchOptionsModel = {
  name: '',
  isShared: false,
  createAlert: false,
  subscriptionType: NewsAlertSubscriptionType.UponPublication,
}

export const NewsSaveSearchButton: React.FC = () => {
  const [model, setModel] = useState(defaultModel)
  const translations = useAppSelector(getTranslations)
  const commonUserData = useAppSelector(selectCommonUserData)
  const { searchFields, isExactMatch } = useAppSelector(getNewsSearchState)

  const getSubjectsList = (): string[] => {
    const selectedCategories = searchFields[NewsFilterName.Category] ?? []

    return selectedCategories?.flatMap(
      (category) => categoryToSubjectsMapping[category]
    )
  }

  const { mutateAsync: saveSearchAndAlert } = useSaveSearchAndAlert()

  const onSaveSearchClick = async ({
    handleClose,
  }: THandleClose): Promise<void> => {
    const request: SearchIndexRequest = {
      searchQuery: searchFields[NewsFilterName.SearchText]?.join(' '),
      countryList: searchFields[NewsFilterName.Country],
      subjectList: getSubjectsList(),
      subjectCategoryCode: searchFields[NewsFilterName.Category]?.join(','),
      vehicleTypeList: searchFields[NewsFilterName.Group],
      makeList: searchFields[NewsFilterName.Make],
      modelList: searchFields[NewsFilterName.Model],
      isExactSearch: isExactMatch,
      subscriptionType: model.createAlert
        ? model.subscriptionType
        : NewsAlertSubscriptionType.None,
      description: model.name,
      startDate: model.createAlert ? new Date().toISOString() : undefined,
      isSubscribed: model.createAlert,
      status: model.createAlert, // This field and isSubscribed field has to be in sync as it is being used to get valid subscriptions for customer
      isShared: model.isShared,
      languageId: commonUserData.languageId,
    }
    const response = await saveSearchAndAlert(request)

    if (response.isSuccess) {
      showSuccessToast(translations.JNT_Success, translations.JNT_Search_saved)
      gtmLogNewsSearchAlertCreateEdit(request)
      setModel(defaultModel)
      handleClose()
    } else {
      showErrorToast(
        translations.JNT_Error,
        translations.JNT_Name_already_exists
      )
    }
  }

  return (
    <StyledNewsSaveSearchButton>
      <CommonModal
        maxWidth="xs"
        closeButton
        title={
          model.createAlert
            ? translations.JNT_Create_New_Alert
            : translations.JNT_Save_search
        }
        trigger={({ handleOpen }) => (
          <JATOButton
            id="newsSaveSearchButton"
            className="searchResultsButton"
            variant="ghost"
            onClick={handleOpen}
          >
            {translations.JNT_Save_search_and_create_alert}
          </JATOButton>
        )}
        primaryButtonName={
          model.createAlert
            ? translations.JNT_Create_Alert
            : translations.JNT_Save_search
        }
        disablePrimaryButton={!model.name}
        onPrimaryClick={onSaveSearchClick}
      >
        <NewsSaveSearch model={model} onChange={setModel} />
      </CommonModal>
    </StyledNewsSaveSearchButton>
  )
}
