import { JATOIcon, JATOText, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { StyledNewsNoItemFound } from './NewsNoItemFound.styles'

export interface INewsNoItemFoundProps {
  iconName: string
  text: string
}

export const NewsNoItemFound: React.FC<INewsNoItemFoundProps> = ({
  iconName,
  text,
}: INewsNoItemFoundProps) => (
  <StyledNewsNoItemFound>
    <div className="notFoundImage">
      <JATOIcon
        iconName={iconName}
        iconSize={90}
        iconFill={JATOTheme.colors.grey}
      />
    </div>
    <JATOText as="div" textAlign="center">
      <div
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </JATOText>
  </StyledNewsNoItemFound>
)
