import { JATOButton } from '@jato/ui-component-library'
import { INewsArticle } from 'models/News/INewsArticle'
import { getNewsCategoryTranslations } from 'models/News/NewsCategoryType'
import { NewsPage } from 'models/News/NewsPageType'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import NewsCategory from '../NewsCategory'
import NewsMetadata from '../NewsMetadata'
import { StyledNewsCarouselItem } from './NewsCarouselItem.styles'

interface INewsCarouselItemProps {
  article: INewsArticle
}

export const NewsCarouselItem: React.FC<INewsCarouselItemProps> = ({
  article,
}: INewsCarouselItemProps) => {
  const history = useHistory()
  const { newsPage } = useParams<{ newsPage: NewsPage }>()

  const translations = useAppSelector(getTranslations)
  const categoryTranslations = getNewsCategoryTranslations(translations)

  const firstCategory = article.categories[0]
  const categoryName = categoryTranslations[firstCategory] ?? firstCategory

  const parentPage = newsPage ?? firstCategory

  const goToNewsArticlePage = (article: INewsArticle): void => {
    history.push(`${RoutePaths.NewsArticles}/${parentPage}/${article.id}`)
  }

  return (
    <StyledNewsCarouselItem>
      <div className="newsCarouselHeader">
        <NewsCategory categories={article.categories} />
      </div>
      <div
        id="newsCarouselOpenArticleLink"
        className="newsCarouselMain"
        onClick={() => goToNewsArticlePage(article)}
      >
        <div>
          <div className="newsHeadline">{article.headline}</div>
          <div className="newsSubHeadline">{article.synopsis}</div>
        </div>
        <img src={article.mainImageUrl} />
        <NewsMetadata
          author={article.author}
          countryCode={article.countryCode}
          date={article.releaseDate}
        />
      </div>
      <div className="newsCarouselFooter">
        <Link
          id="newsCarouselViewAllLink"
          to={`${RoutePaths.News}/${firstCategory}`}
          className="newsCarouselFooterLink"
        >
          {translations.JNT_TLE_ViewAll} {categoryName.toLowerCase()}
        </Link>
        <JATOButton
          id="newsCarouselReadMoreButton"
          variant="primary"
          className="newsCarouselFooterButton"
          onClick={() => goToNewsArticlePage(article)}
        >
          {translations.JNT_News_ReadMore}
        </JATOButton>
      </div>
    </StyledNewsCarouselItem>
  )
}
