import React from 'react'
import { StyledNewsMetadata } from './NewsMetadata.styles'
import { NewsMetadataAuthor } from './NewsMetadataAuthor'
import { NewsMetadataCountry } from './NewsMetadataCountry'
import { NewsMetadataDate } from './NewsMetadataDate'

interface INewsMetadataProps {
  author: string
  countryCode: string
  date: string
}

export const NewsMetadata: React.FC<INewsMetadataProps> = ({
  author,
  countryCode,
  date,
}: INewsMetadataProps) => (
  <StyledNewsMetadata>
    <div className="newsMetadataBlock">
      <NewsMetadataCountry countryCode={countryCode} />
    </div>
    <div className="newsMetadataBlock">
      <NewsMetadataAuthor author={author} />
    </div>
    <div className="newsMetadataBlock">
      <NewsMetadataDate date={date} />
    </div>
  </StyledNewsMetadata>
)
