import { ModalComponent } from 'components/ModalComponent'
import React from 'react'
import { NewsArticlePhotoGallery } from './NewsArticlePhotoGallery'

interface IModalProps {
  articleImageList: string[]
  isOpen: boolean
  title: string
  onClose: () => void
}

export const NewsArticlePhotoGalleryModal: React.FC<IModalProps> = ({
  articleImageList,
  isOpen,
  title,
  onClose,
}: IModalProps) => (
  <ModalComponent
    modalVisible={isOpen}
    closeButton
    title={title}
    onSecondaryClick={onClose}
  >
    <NewsArticlePhotoGallery imageUrlList={articleImageList} />
  </ModalComponent>
)
