import { JATOVerticalSpacer } from '@jato/ui-component-library'
import {
  option_text,
  OptionTextInfo,
  VisualizeOptionsV3,
} from 'models/Specifications/Options/OptionsBuildRuleResponse'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import { StyledSpecificationsOptionBuild } from './SpecificationsOptionBuild.styles'
import { SpecificationsOptionsLevel } from './SpecificationsOptionsLevel'

interface ISpecificationsOptionBuildProps {
  optionCategoryName: string
  currentOptionId: number
  tree: VisualizeOptionsV3[]
  ledger: OptionTextInfo[]
  requireChoices: number[]
  selectedOptionId?: number
  setSelectedOptionId: (selectedOptionId: number) => void
}
export const SpecificationsOptionBuild: React.FC<
  ISpecificationsOptionBuildProps
> = ({
  optionCategoryName,
  currentOptionId,
  tree,
  ledger,
  requireChoices,
  selectedOptionId,
  setSelectedOptionId,
}: ISpecificationsOptionBuildProps) => {
  const translations = useAppSelector(getTranslations)
  const currentOption = ledger.find((item) => item.optionId === currentOptionId)
  const requireOptionInfos = ledger.filter((o) =>
    requireChoices.includes(o.optionId)
  )
  const includedOptions =
    tree.find((item) => item.optionId === currentOptionId)?.choiceOptions
      .Includes ?? []
  const includedOptionInfos = ledger.filter((o) =>
    includedOptions.flat().includes(o.optionId)
  ) //.slice(0, 1) //CarSpecs display only first of included options
  const excludedOptions =
    tree.find((item) => item.optionId === currentOptionId)?.excludedOptions ??
    []
  const excludedOptionInfos = ledger.filter((o) =>
    excludedOptions.includes(o.optionId)
  )

  return (
    <StyledSpecificationsOptionBuild>
      <div key={uuid()} style={{ fontWeight: 500 }}>
        {optionCategoryName}
      </div>
      <JATOVerticalSpacer />
      {currentOption ? (
        <>
          <div key={uuid()}>
            {currentOption.optionCode}-{currentOption.translatedOptionText} -{' '}
            {currentOption.optionPrices.defaultMarketDisplayPrice}
          </div>
          <JATOVerticalSpacer height={5} />
          {currentOption.optionDescription.map((od: option_text) => (
            <div key={uuid()}>
              <div key={uuid()}>{od.content}</div>
              <JATOVerticalSpacer height={5} />
            </div>
          ))}
        </>
      ) : (
        `Missing info for ${currentOptionId}`
      )}
      <JATOVerticalSpacer />
      {requireOptionInfos && requireOptionInfos.length > 0 && (
        <>
          <SpecificationsOptionsLevel
            key={uuid()}
            optionLevelClassName="requiresLevel"
            optionLevelText={translations.JNT_Requires}
            optionInfos={requireOptionInfos}
            parentOption={currentOption}
            selectedOptionId={selectedOptionId}
            setSelectedOptionId={setSelectedOptionId}
            isRadioButtonOptionAvailable={true}
          />
          <JATOVerticalSpacer />
        </>
      )}

      {includedOptionInfos && includedOptionInfos.length > 0 && (
        <>
          <SpecificationsOptionsLevel
            key={uuid()}
            optionLevelClassName="includesLevel"
            optionLevelText={translations.JNT_Includes}
            optionInfos={includedOptionInfos}
            isRadioButtonOptionAvailable={false}
          />
          <JATOVerticalSpacer />
        </>
      )}

      {excludedOptionInfos && excludedOptionInfos.length > 0 && (
        <SpecificationsOptionsLevel
          key={uuid()}
          optionLevelClassName="excludesLevel"
          optionLevelText={translations.JNT_Excludes}
          optionInfos={excludedOptionInfos}
          isRadioButtonOptionAvailable={false}
        />
      )}
    </StyledSpecificationsOptionBuild>
  )
}
