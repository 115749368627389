import Loader from 'components/Loader'
import {
  NewsSavedSearchAndAlertColumnType,
  NewsSavedSearchAndAlertGrid,
} from 'components/News/NewsSavedSearchAndAlert/NewsSavedSearchAndAlertGrid'
import { useSavedSearch } from 'hooks/news'
import React from 'react'
import { StyledMyPageMyNews } from './MyPageMyNews.styles'

export const MyPageMyNews: React.FC = () => {
  const { data: savedSearches, isFetching } = useSavedSearch(false)

  return (
    <StyledMyPageMyNews>
      {isFetching ? (
        <Loader style={{ minHeight: '275px' }} />
      ) : (
        <NewsSavedSearchAndAlertGrid
          savedSearches={savedSearches ?? []}
          columns={[
            NewsSavedSearchAndAlertColumnType.Name,
            NewsSavedSearchAndAlertColumnType.LastRunDate,
          ]}
        />
      )}
    </StyledMyPageMyNews>
  )
}
