import { JATOSelect2 } from '@jato/ui-component-library'

import { getAllCountries } from 'helper/newsHelper'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getNewsCountries } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsCountriesSelect } from './NewsCountriesSelect.styles'

interface INewsCountriesSelectProps {
  value: string
  onChange: (value: string) => void
  isRequired?: boolean
}

export const NewsCountriesSelect: React.FC<INewsCountriesSelectProps> = ({
  value,
  onChange,
  isRequired,
}: INewsCountriesSelectProps) => {
  const translations = useAppSelector(getTranslations)
  const countries = useAppSelector(getNewsCountries)

  const dropdownOptions = getAllCountries(countries, translations).map((c) => ({
    value: c.code,
    displayValue: c.name,
  }))

  return (
    <StyledNewsCountriesSelect>
      <JATOSelect2
        id="newsCountriesSelect"
        label={`${translations.JNT_Country}${isRequired ? ' *' : ''}`}
        value={value}
        placeholder={translations.JNT_ART_AllCountry}
        dataSrc={dropdownOptions}
        onChange={onChange}
        width={320}
        ignoreCase={true}
        displayOnlyUnselectedOptions={false}
        isMultiSelect={true}
        clearAfterSelect={true}
        displayInlineValues={true}
        setAutoFocus={false}
      />
    </StyledNewsCountriesSelect>
  )
}
