import { AxiosResponse } from 'axios'
import { IUserSettings } from 'models/UserSettings/UserSettings'
import { mpBaseApi } from 'services/api'

class UserSettingsManagerService {
  public getUserSettingsData(): Promise<AxiosResponse<IUserSettings[]>> {
    const userSettingsData = mpBaseApi.get(`/api/UserSettings/getuserSettings`)
    return userSettingsData
  }

  public addUserSettingsData(
    userSettings: IUserSettings
  ): Promise<AxiosResponse<void>> {
    const addUserSettingsResponse = mpBaseApi.post<
      IUserSettings,
      AxiosResponse<void>
    >(`/api/UserSettings/add`, userSettings)
    return addUserSettingsResponse
  }

  public updateUserSettingsData(
    userSettings: IUserSettings
  ): Promise<AxiosResponse<void>> {
    const updateUserSettingsResponse = mpBaseApi.post<
      IUserSettings,
      AxiosResponse<void>
    >(`/api/UserSettings/update`, userSettings)
    return updateUserSettingsResponse
  }

  public removeUserSettingsData(
    id?: string | undefined
  ): Promise<AxiosResponse<unknown>> {
    const removeUserSettingsResponse = mpBaseApi.post(
      `/api/UserSettings/remove/${id}`,
      {}
    )
    return removeUserSettingsResponse
  }
}

const userSettingsManagerService = new UserSettingsManagerService()
export default userSettingsManagerService
