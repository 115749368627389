import { JATOLink } from '@jato/ui-component-library'
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  GridComponent,
  Inject,
  PageSettingsModel,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids'
import { Pagination } from 'components/Pagination/Pagination'
import { BasketInfo } from 'models/Specifications/Basket/BasketInfo'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledMyPageSpecsBasketsGrid } from './MyPageSpecsBasketsGrid.styles'

export interface IProps {
  baskets: BasketInfo[]
}

export const MyPageSpecsBasketsGrid: React.FC<IProps> = ({
  baskets,
}: IProps) => {
  const history = useHistory()
  const translations = useAppSelector(getTranslations)

  const commonUserData = useAppSelector(selectCommonUserData)
  const { guid, languageId } = commonUserData

  const [currentPage, setCurrentPage] = useState(1)

  const pageSize = 6
  const defaultColumnAlign = 'Left'
  const defaultClipMode = 'Clip'

  const totalPages = baskets ? Math.ceil(baskets.length / pageSize) : 0

  const indexOfLastItem = currentPage * pageSize
  const indexOfFirstItem = indexOfLastItem - pageSize
  const currentItems = baskets
    ? baskets.slice(indexOfFirstItem, indexOfLastItem)
    : []

  const loadBasket = (basket: BasketInfo): void => {
    history.push(
      `${RoutePaths.Autologin}/${guid}/${languageId}${RoutePaths.Specifications}?redirectUrl=/baskets/${basket.id}`
    )
  }

  const getFormattedDate = (_field: string, data: object): string => {
    const { lastAccessDate } = data as BasketInfo
    switch (_field) {
      case 'lastAccessDate':
        return lastAccessDate
          ? new Date(lastAccessDate).toLocaleDateString('en-GB')
          : ''
      default:
        return ''
    }
  }

  const basketNameGridTemplate = (basket: BasketInfo): JSX.Element => (
    <JATOLink
      onClick={() => loadBasket(basket)}
      className="basketLink"
      textTransform="inherit"
    >
      {basket.basketName}
    </JATOLink>
  )

  const columnDefinitions = [
    {
      field: 'basketName',
      headerText: translations.JNT_name,
      template: basketNameGridTemplate,
    },
    {
      field: 'lastAccessDate',
      headerText: translations.JNT_lastran,
      valueAccessor: getFormattedDate,
    },
  ]

  const pageSettings: PageSettingsModel = {
    pageSize: pageSize,
  }

  const paginationComponent = (
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={setCurrentPage}
    />
  )

  return (
    <StyledMyPageSpecsBasketsGrid>
      {paginationComponent}
      <GridComponent
        id="grid"
        dataSource={currentItems}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
        allowResizing={true}
        pageSettings={pageSettings}
        allowSorting={true}
      >
        <ColumnsDirective>
          {columnDefinitions.map((c, index) => (
            <ColumnDirective
              key={index}
              field={c.field}
              headerText={c.headerText}
              textAlign={defaultColumnAlign}
              clipMode={defaultClipMode}
              valueAccessor={c.valueAccessor}
              template={c.template}
            />
          ))}
        </ColumnsDirective>
        <Inject services={[Edit, CommandColumn, Resize, Sort]} />
      </GridComponent>
      {paginationComponent}
    </StyledMyPageSpecsBasketsGrid>
  )
}
