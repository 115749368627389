import { useLocation } from 'react-router-dom'
import { RoutePaths } from 'router/RoutePaths'

interface IRootPathResolver {
  isMyPage: boolean
  isNews: boolean
  isVolumes: boolean
  isSpecifications: boolean
  isMonthlyPayments: boolean
  routeName: string
}

export const useRootPathResolver = (): IRootPathResolver => {
  const location = useLocation()

  const isMyPage = location.pathname.startsWith(RoutePaths.MyPage)
  const isNews = location.pathname.startsWith(RoutePaths.News)
  const isVolumes = location.pathname.startsWith(RoutePaths.Volumes)
  const isSpecifications = location.pathname.startsWith(
    RoutePaths.Specifications
  )
  const isMonthlyPayments = location.pathname.startsWith(
    RoutePaths.MonthlyPayments
  )

  const routeName = location.pathname.split('/')[1]

  return {
    isMyPage,
    isNews,
    isVolumes,
    isSpecifications,
    isMonthlyPayments,
    routeName,
  }
}
