import {
  JATOButton,
  JATOButtonGroup,
  JATOButtonIcon,
  JATOHeading,
  JATOModal,
} from '@jato/ui-component-library'
import { useDisableBodyScroll } from 'helper/disableScrollHook'
import React from 'react'
interface IProps {
  children: React.ReactNode
}

interface IModalComponentProps extends IProps {
  modalVisible: boolean
  closeButton?: boolean
  primaryButton?: boolean
  disablePrimaryButton?: boolean
  title?: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'none'
  primaryButtonName?: string
  isPrimaryButtonLoading?: boolean
  secondaryButtonName?: string
  dismissable?: boolean
  onPrimaryClick?(): void
  onSecondaryClick(): void
  children: React.ReactNode
}

export const ModalComponent: React.FC<IModalComponentProps> = (
  props: IModalComponentProps
) => {
  // Disable scroll of background/give focus to modal component
  useDisableBodyScroll(props.modalVisible)

  return (
    <JATOModal
      maxWidth={props.maxWidth ? props.maxWidth : 'sm'}
      isOpen={props.modalVisible}
      onClose={props.onSecondaryClick}
      header={() => (
        <>
          <JATOHeading as="h4" fontWeight="medium" display="flex">
            {props.title}
          </JATOHeading>
          {props.closeButton && (
            <JATOButtonIcon
              disabled={props.isPrimaryButtonLoading}
              iconName="baseline_close"
              onClick={props.onSecondaryClick}
            />
          )}
        </>
      )}
      footer={() =>
        props.primaryButton && (
          <JATOButtonGroup justifyContent="flex-end">
            <JATOButton
              onClick={props.onPrimaryClick}
              variant="primary"
              size="large"
              style={{ borderRadius: 5 }}
              disabled={props.disablePrimaryButton}
            >
              {props.primaryButtonName}
            </JATOButton>
          </JATOButtonGroup>
        )
      }
      dismissable={props.dismissable}
    >
      {props.children}
    </JATOModal>
  )
}
