import { signalRUrl } from 'config'
import { showErrorToast } from 'helper/toastHelper'
import { store } from 'redux/store'
import { setExcelExportResponseState } from 'redux/volumes/volumesSlice'
import { HubConnectionBuilder } from '@microsoft/signalr'

class SignalRService {
  public setUpSignalRConnection(userId: number): void {
    const connection = new HubConnectionBuilder()
      .withUrl(`${signalRUrl}/?userId=${userId}`)
      .withAutomaticReconnect()
      .build()

    connection.on('broadCastExportMessage', (exportMessage: any) => {
      if (exportMessage.success) {
        store.dispatch(
          setExcelExportResponseState({
            success: exportMessage.success,
            message: exportMessage.message,
            exportedFilePath: exportMessage.exportedFilePath,
            userId: exportMessage.userId,
          })
        )
      } else {
        showErrorToast('Excel export error', exportMessage.Message)
      }
    })

    connection
      .start()
      .then(function () {
        console.log('connected')
      })
      .catch(function () {
        console.log('Could not connect')
      })
  }
}

const signalRService = new SignalRService()
export default signalRService
