import { JATOIcon, JATOTheme } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsFaq } from './NewsFaq.styles'

interface FaqSection {
  title: string
  content: string
}

export const NewsFaq: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const faqSections: FaqSection[] = [
    {
      title: translations.JNT_News_FAQ_Q1,
      content: translations.JNT_News_FAQ_A1,
    },
    {
      title: translations.JNT_News_FAQ_Q2,
      content: translations.JNT_News_FAQ_A2,
    },
    {
      title: translations.JNT_News_FAQ_Q3,
      content: translations.JNT_News_FAQ_A3,
    },
    {
      title: translations.JNT_News_FAQ_Q4,
      content: translations.JNT_News_FAQ_A4,
    },
    {
      title: translations.JNT_News_FAQ_Q5,
      content: translations.JNT_News_FAQ_A5,
    },
    {
      title: translations.JNT_News_FAQ_Q6,
      content: translations.JNT_News_FAQ_A6,
    },
    {
      title: translations.JNT_News_FAQ_Q7,
      content: translations.JNT_News_FAQ_A7,
    },
    {
      title: translations.JNT_News_FAQ_Q8,
      content: translations.JNT_News_FAQ_A8,
    },
    {
      title: translations.JNT_News_FAQ_Q9,
      content: translations.JNT_News_FAQ_A9,
    },
    {
      title: translations.JNT_News_FAQ_Q10,
      content: translations.JNT_News_FAQ_A10,
    },
    {
      title: translations.JNT_News_FAQ_Q11,
      content: translations.JNT_News_FAQ_A11,
    },
    {
      title: translations.JNT_News_FAQ_Q12,
      content: translations.JNT_News_FAQ_A12,
    },
  ]

  function scrollTop(ElementId: string): void {
    document.getElementById(ElementId)?.scrollIntoView()
  }

  return (
    <StyledNewsFaq>
      <hr />
      {faqSections.map((s, index) => (
        <div key={index}>
          <JATOIcon
            iconName="custom_help_outline"
            className="icon-style"
            iconSize={JATOTheme.fontSizes[3]}
          />
          <a
            id={'newsFaqSectionLink_' + index}
            className="link-text"
            onClick={() => scrollTop(`${index}`)}
          >
            {s.title}
          </a>
        </div>
      ))}
      <hr />
      {faqSections.map((s, index) => (
        <div key={index} id={`${index}`}>
          <h4>
            <strong>
              <span className="custom-span">{s.title}</span>
            </strong>
          </h4>
          <div dangerouslySetInnerHTML={{ __html: s.content }} />
          <hr />
        </div>
      ))}
    </StyledNewsFaq>
  )
}
