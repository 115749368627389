import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsRelatedArticles = styled.div`
  .relatedSectionHeader {
    background-color: ${JATOTheme.colors.midGrey};
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.017em;
    margin-top: 30px;
    height: 34px;
    left: 30px;
    padding: 10px;
  }

  .relatedYearSpan {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.017em;
    height: 24px;
    padding-left: 5px;
  }

  .relatedTableCell {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0px;
    color: #1677ff;
  }

  .relatedYearDiv {
    display: flex;
    flexdirection: row;
    padding: 10px 0px 10px 0px;
  }
  .relatedArticleListBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
