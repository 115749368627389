import { JATOText, JATOTheme } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import NewsCard from 'components/News/NewsCard'
import { useGetArticlesByCategory } from 'hooks/news'
import { NewsCategoryType } from 'models/News/NewsCategoryType'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledMpAlertsBetaHomePageComponent } from './MpAlertsBetaHomePageComponent.styles'

export const MpAlertsBetaHomePageComponent: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const { data: mpAlerts, isLoading: mpAlertsLoading } =
    useGetArticlesByCategory(NewsCategoryType.MonthlyPayments, 4, 1, true)

  return (
    <StyledMpAlertsBetaHomePageComponent>
      {mpAlertsLoading ? (
        <Loader className="mpAlertsLoader" />
      ) : (
        <>
          <JATOText
            as="div"
            fontSize={20}
            color={JATOTheme.colors.primary}
            className="mpAlertsHeading"
          >
            {translations.JNT_MpAlertsBeta_HomePageTitle}
          </JATOText>
          <div className="mpAlerts">
            {mpAlerts?.articles.map((article) => (
              <NewsCard key={article.id} article={article} />
            ))}
          </div>
        </>
      )}
    </StyledMpAlertsBetaHomePageComponent>
  )
}
