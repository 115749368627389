import styled from 'styled-components'
import Theme from 'theme'

export const StyledSpecificationsDataDefinitionTooltip = styled.div`
  .tooltipReadMoreLink {
    text-decoration: underline;
    color: inherit;
    font-weight: bold;
  }
  
`
