import { JATOModal, JATOSpinner } from '@jato/ui-component-library'
import React from 'react'

interface LoaderProps {
  isOpen: boolean
}

export const LoaderModal: React.FC<LoaderProps> = ({ isOpen }: LoaderProps) =>
  isOpen ? (
    <JATOModal isOpen={isOpen} dismissable={false} className="loaderModal">
      <JATOSpinner spinnerSize={64} />
    </JATOModal>
  ) : (
    <></>
  )
