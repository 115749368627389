/* eslint react/prop-types: 0 */
import { JATOAnnouncement, JATOHeading } from '@jato/ui-component-library'
import {
  Aggregate,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnDirective,
  ColumnDragEventArgs,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  PagerComponent,
  Reorder,
  Resize,
  Scroll,
  Search,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids'
import Loader from 'components/Loader'
import { isNullOrEmpty } from 'helper/arrayHelper'
import {
  MaxReportRows,
  PeriodSelectionTypes,
  defaultNumberOfReportRows,
  formatNumericDataWithCommas,
  getActiveStep,
  getColourCodeDictionary,
  getFormattedValue,
  getOutputPoints,
  getPathForStep,
  volumesColourMapping,
} from 'helper/volumesHelper'
import { useGetReport } from 'hooks/volumes'
import { DataRow } from 'models/Volumes/OutputReportResponse'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'

import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { gtmLogVolumesReportView } from 'helper/gtm'
import { OutputPoint } from 'models/Volumes/OutputPoint'
import { OutPutReportColumnDef } from 'models/Volumes/OutputReportColumnDef'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getExcelExportResponseState,
  getVolumesQueryState,
  resetExcelExportResponseState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import VolumesReportActions from '../VolumesReportActions'
import { StyledVolumesReportStep } from './VolumesReportStep.styles'

export const VolumesReportStep: React.FC = () => {
  let gridInstance: GridComponent | null
  let pagerInstance: PagerComponent | null
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryState = useAppSelector(getVolumesQueryState)
  const translations = useAppSelector(getTranslations)
  const excelExportResponse = useAppSelector(getExcelExportResponseState)

  // if step is not ready, redirect to last ready step
  if (isNullOrEmpty(queryState.periods)) {
    const step = getActiveStep(queryState)
    history.push(getPathForStep(step))
  }
  const defaultColumnWidth = '100'

  const { data, isFetching } = useGetReport()
  const dataSource = data?.dtable.slice(0, MaxReportRows)

  const getSortedColumnDefs = (): OutPutReportColumnDef[] => {
    const defs = data?.columnDefs ?? []

    const groupedDefs = defs.reduce(
      (groups, item) => ({
        ...groups,
        [item.caption]: [...(groups[item.caption] || []), item],
      }),
      {} as { [key: string]: OutPutReportColumnDef[] }
    )
    const result = Object.values(groupedDefs)
      .flatMap((defGroup) =>
        defGroup!.map((def, index) => ({
          index: def.caption === '' ? -1 : index,
          ordinal: def.ordinal,
          def: def,
        }))
      )
      .sort((a, b) => a.index - b.index || a.ordinal - b.ordinal)
      .map((d) => d.def)

    return result
  }

  const getColourCodeForRowCell = (dataObject: any): any => {
    if (dataObject[dataObject.column.field] < 0) {
      return PeriodSelectionTypes.NegativeData
    }

    const columnDef: any = data?.columnDefs.find(
      (columnDef) => columnDef.field === dataObject.column.field
    )

    if (columnDef) {
      if (columnDef.caption === 'ValueForAnalysis' && dataObject['sti']) {
        const rowColorDictionary = getColourCodeDictionary(dataObject['sti'])
        return rowColorDictionary[dataObject.column.index]
      }

      return columnDef.colorCode
    }
    return PeriodSelectionTypes.ActualData
  }

  const colourCodedTextTemplate = (dataObject: any): JSX.Element => {
    const colourCode = getColourCodeForRowCell(dataObject)
    const colour = colourCode
      ? volumesColourMapping.find((c) => c.type === colourCode)?.colour
      : 'black'

    return (
      <div style={{ color: colour }}>
        {getFormattedValue(
          dataObject.column.field,
          dataObject[dataObject.column.field],
          data
        )}
      </div>
    )
  }

  const valueAccessor = (_field: string, dataObject: object): any => {
    const row = dataObject as DataRow
    const value = row[_field]
    return getFormattedValue(_field, value, data) ?? value
  }

  const dataBound = (): void => {
    if (pagerInstance && gridInstance) {
      pagerInstance.totalRecordsCount =
        gridInstance.pagerModule.pagerObj.totalRecordsCount
      pagerInstance.pageCount = gridInstance.pageSettings.pageCount ?? 0
      pagerInstance.pageSize = gridInstance.pageSettings.pageSize ?? 0
      pagerInstance.currentPage = gridInstance.pageSettings.currentPage ?? 0
      pagerInstance.refresh()
      document.getElementsByClassName('volumesContainer')[0].scrollTo(0, 0)
    }
  }

  const pageClick = (args: any): void => {
    if (gridInstance) {
      gridInstance.goToPage(args.currentPage)
    }
  }

  const columnDrop = (args: ColumnDragEventArgs): void => {
    if (gridInstance && args) {
      const convertedOutputPoints: any = getOutputPoints(queryState)
      const draggedItem = convertedOutputPoints.find(
        (item: OutputPoint) => item.iddesc === args.column?.headerText
      )

      const targetItem = convertedOutputPoints.find(
        (item: OutputPoint) =>
          item.iddesc === (args.target as HTMLDivElement).innerText
      )
      if (targetItem && draggedItem) {
        const remainingItems: OutputPoint[] | undefined =
          queryState.outputPoints!.filter(
            (item: OutputPoint) => item.iddesc !== args.column?.headerText
          )
        const newIndex = convertedOutputPoints.indexOf(targetItem)
        const reorderedItems: OutputPoint[] = [
          ...remainingItems.slice(0, newIndex),
          draggedItem,
          ...remainingItems.slice(newIndex),
        ]
        dispatch(
          setVolumesQueryState({
            ...queryState,
            outputPoints: reorderedItems,
          })
        )
      }
    }
  }

  const footerSum = (props: any): any => {
    const defs = data?.columnDefs ?? []
    const [fieldValue, aggregateType] = Object.keys(props)[0].split('-')
    const columnDef = defs.filter(
      (c: OutPutReportColumnDef) => c.field === fieldValue.trim()
    )[0]
    const colorCode = volumesColourMapping.find(
      (c) => c.type === columnDef.colorCode
    )?.colour
    return (
      <span style={{ color: colorCode }}>
        {formatNumericDataWithCommas(props.Sum)}
      </span>
    )
  }

  useEffect(() => {
    const onCloseAnnouncementBar = (): void => {
      dispatch(resetExcelExportResponseState())
    }

    if (excelExportResponse && excelExportResponse.success) {
      JATOAnnouncement.Create({
        title: '',
        children: (
          <div>
            {translations.JNT_Volumes_BAS_Exportreport}
            <a
              href={excelExportResponse.exportedFilePath}
              id="volumesReportStepExcelFileLink"
            >
              <b> {translations.JNT_Volumes_BAS_Clickheretext} </b>
            </a>
          </div>
        ),
        onClose: onCloseAnnouncementBar,
      })
    }
  }, [excelExportResponse])

  useEffect(() => {
    gtmLogVolumesReportView(queryState)
  }, [])

  const renderColumnHeaderTooltipText = (args: any): void => {
    const tooltip =
      args.event.view.document.getElementById('Tooltip').ej2_instances[0]
    tooltip.content = args.target.innerText
  }

  return (
    <StyledVolumesReportStep>
      <div className="reportStepHeader">
        <JATOHeading as="h3" fontWeight="medium" className="reportHeading">
          {translations.JNT_Volumes_QG4_Final}
        </JATOHeading>
        <VolumesReportActions />
      </div>
      <div className="grid-container">
        {data && !isFetching ? (
          <>
            <PagerComponent
              ref={(pager) => (pagerInstance = pager)}
              click={pageClick}
            />
            <TooltipComponent
              id="Tooltip"
              beforeRender={renderColumnHeaderTooltipText}
              position="BottomCenter"
              target=".e-headercelldiv"
            >
              <GridComponent
                id="grid"
                ref={(g) => (gridInstance = g)}
                dataSource={dataSource}
                loadingIndicator={{ indicatorType: 'Shimmer' }}
                allowResizing={true}
                allowSorting={true}
                allowReordering={true}
                allowPaging={true}
                pageSettings={{ pageSize: defaultNumberOfReportRows }}
                toolbar={['Search']}
                width="100%"
                dataBound={dataBound}
                columnDrop={columnDrop}
              >
                <ColumnsDirective>
                  {getSortedColumnDefs().map((c, index) => (
                    <ColumnDirective
                      key={index}
                      field={c.field}
                      headerText={c.columnName}
                      width={defaultColumnWidth}
                      textAlign={c.dataType === 'String' ? 'Left' : 'Right'}
                      clipMode="Clip"
                      isPrimaryKey={c.field === 'sti'}
                      visible={c.field !== 'sti'}
                      allowSorting={true}
                      valueAccessor={valueAccessor}
                      allowReordering={
                        c.caption === '' && c.field !== 'currency'
                          ? true
                          : false
                      }
                      template={
                        c.caption === '' ? null : colourCodedTextTemplate
                      }
                    />
                  ))}
                </ColumnsDirective>
                <AggregatesDirective>
                  <AggregateDirective>
                    <AggregateColumnsDirective>
                      {getSortedColumnDefs()
                        .filter(
                          (c: OutPutReportColumnDef) =>
                            c.caption === 'ValueForAnalysis'
                        )
                        .map((c, index) => (
                          <AggregateColumnDirective
                            key={index}
                            field={c.field}
                            type="Sum"
                            footerTemplate={footerSum}
                          />
                        ))}
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                </AggregatesDirective>
                <Inject
                  services={[
                    Resize,
                    Sort,
                    Scroll,
                    Reorder,
                    Search,
                    Toolbar,
                    Page,
                    Aggregate,
                  ]}
                />
              </GridComponent>
            </TooltipComponent>
          </>
        ) : (
          <Loader style={{ minHeight: 'calc(100vh - 340px)' }} />
        )}
      </div>
    </StyledVolumesReportStep>
  )
}
