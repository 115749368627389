import { mpCountryCodes } from 'config'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import { formatCurrency } from './formatNumberValues'

export const getReferencePrice = (vehicle: MpVehicle): string => {
  switch (vehicle.country) {
    case mpCountryCodes.France:
    case mpCountryCodes.Germany:
    case mpCountryCodes.Spain:
    case mpCountryCodes.Belgium:
    case mpCountryCodes.TheNetherlands:
      return vehicle.price.toString()
    case mpCountryCodes.GreatBritain:
      return vehicle.onTheRoadPrice.toString()
    case mpCountryCodes.Italy:
      return vehicle.priceIncludingDelivery.toString()
    default:
      return vehicle.price.toString()
  }
}

export const getReferencePriceFormatted = (vehicle: MpVehicle): string => {
  const price = getReferencePrice(vehicle)
  return formatCurrency(price, vehicle.country)
}
