import styled from 'styled-components'

export const StyledMpAlertsBetaNavBarComponent = styled.span`
  .activateMpAlertsBeta {
    color: #1677ff;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
  }

  .mpAlertsBetaHelp {
    color: #1677ff;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    margin-right: 10px;
  }
`
