import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { appInsights } from 'ApplicationInsightsService'
import {
  azureAppInsightsEnableCustomExceptionsLogging,
  azureAppInsightsEnableCustomTraceLogging,
} from 'config'

export const logError = (message: any, request?: object): void => {
  if (!appInsights || !azureAppInsightsEnableCustomExceptionsLogging) return
  const errorMessage =
    message !== undefined
      ? `JATONet Error: ${JSON.stringify(message)}`
      : 'JATONet Error: No response from the server.'
  const requestMessage = request
    ? `for request: ${JSON.stringify(request)}`
    : ''
  appInsights.trackException({
    exception: new Error(`${errorMessage} ${requestMessage}`),
    severityLevel: SeverityLevel.Error,
  })
}

export const logTrace = (
  message: string,
  request: string,
  response: string
): void => {
  if (!appInsights || !azureAppInsightsEnableCustomTraceLogging) return
  appInsights.trackTrace({
    message: `${message}. Request: ${request}. Response: ${response}`,
    severityLevel: SeverityLevel.Information,
  })
}
