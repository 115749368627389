import { JATOButtonGroup, JATONavBar } from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import NewsArticleSelection from 'components/News/Buttons/NewsArticleSelection'
import NewsPageViewSwitch from 'components/News/Buttons/NewsPageViewSwitch'
import NewsBreadcrumbs from 'components/News/NewsBreadcrumbs'
import NewsCards from 'components/News/NewsCards'
import NewsGrid, { NewsGridColumnType } from 'components/News/NewsGrid'
import {
  defaultArticlesInGridNumber,
  defaultCardsNumber,
} from 'helper/newsHelper'
import {
  clearArticlesByCategoryCache,
  useGetArticlesByCategory,
} from 'hooks/news'
import { NewsPage, getNewsPageTranslations } from 'models/News/NewsPageType'
import { NewsPageViewType } from 'models/News/NewsPageViewType'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getNewsUserState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsViewAll } from './NewsViewAll.styles'

export const NewsViewAll: React.FC = () => {
  const { newsPage } = useParams<{ newsPage: NewsPage }>()
  const [currentPage, setCurrentPage] = useState(1)
  const newsUserState = useAppSelector(getNewsUserState)
  const translations = useAppSelector(getTranslations)
  const newsPageTranslations = getNewsPageTranslations(translations)

  const pageName = newsPageTranslations[newsPage] ?? newsPage

  const { pageViewType } = newsUserState.userSpecificSettings
  const isCardsView = pageViewType === NewsPageViewType.Cards
  const isGridView = pageViewType === NewsPageViewType.Grid

  const numOfArticles = isGridView
    ? defaultArticlesInGridNumber
    : defaultCardsNumber

  const { data: articlesResponse, isFetching } = useGetArticlesByCategory(
    newsPage,
    numOfArticles,
    currentPage,
    false,
    ['releaseDate desc']
  )

  const defaultColumns = [
    NewsGridColumnType.Headline,
    NewsGridColumnType.Author,
    NewsGridColumnType.Country,
    NewsGridColumnType.ReleaseDate,
    NewsGridColumnType.Actions,
  ]

  const gridColumns =
    newsPage == NewsPage.WorkflowReports ||
    newsPage == NewsPage.CornerstoneReports
      ? defaultColumns
      : [NewsGridColumnType.Manufacturer, ...defaultColumns]

  useEffect(() => {
    // reset to first page because results count is changing
    setCurrentPage(1)
  }, [newsPage, pageViewType])

  useEffect(() => {
    if (pageViewType === NewsPageViewType.Grid) {
      clearArticlesByCategoryCache()
    }
  }, [pageViewType])

  return (
    <StyledNewsViewAll>
      <Container fluid>
        <Row>
          <Col md={12}>
            <JATONavBar
              left={() => <NewsBreadcrumbs title={pageName} />}
              right={() => (
                <JATOButtonGroup space={4}>
                  <NewsArticleSelection />
                  <NewsPageViewSwitch />
                </JATOButtonGroup>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {isGridView && articlesResponse && (
              <NewsGrid
                articles={articlesResponse.articles}
                totalNumber={articlesResponse.totalNumber}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                columns={gridColumns}
                allowSelection={true}
                isFetching={isFetching}
              />
            )}
            {isCardsView && articlesResponse && (
              <NewsCards
                articles={articlesResponse.articles}
                totalNumber={articlesResponse.totalNumber}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                allowSelection={true}
                isFetching={isFetching}
              />
            )}
          </Col>
        </Row>
      </Container>
      <LoaderModal isOpen={isFetching && !articlesResponse} />
    </StyledNewsViewAll>
  )
}
