import { JATOButtonIcon, JATOTheme } from '@jato/ui-component-library'
import { useResetBuiltVehiclesAndFilters } from 'hooks/carSpecs'
import React from 'react'

export const ResetSpecificationsNavItem: React.FC = () => {
  const { mutateAsync: resetBuiltVehiclesAndFilters } =
    useResetBuiltVehiclesAndFilters()

  const onResetFilters = async (): Promise<void> => {
    await resetBuiltVehiclesAndFilters()
  }

  return (
    <JATOButtonIcon
      id="specsResetButton"
      title="Reset Filters and Selected Vehicles"
      className="reset-filters-icon"
      iconName="baseline_autorenew"
      iconFill={JATOTheme.colors.darkGrey}
      onClick={onResetFilters}
    />
  )
}
