import { JATOText, JATOTheme } from '@jato/ui-component-library'
import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import { ISelectedFilterOption } from 'models/Filters/FilterOption'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { StyledMpInsuranceFilter } from './MpInsuranceFilter.styles'

interface IProps {
  id: string
  filterName: string
  value?: string
  handleAddFilter: (filterOption: ISelectedFilterOption) => void
  handleRemoveFilter: (filterOption: ISelectedFilterOption) => void
}

export const MpInsuranceFilter: React.FC<IProps> = ({
  id,
  filterName,
  value,
  handleAddFilter,
  handleRemoveFilter,
}: IProps) => {
  const optionFilterTypes = new Map([
    ['Y', 'Yes'],
    ['-', 'No'],
    ['?', 'Unknown'],
    ['', 'Not Coded'],
  ])

  const onChange = (newValue: string, text: string): void => {
    const option: ISelectedFilterOption = {
      key: uuid(),
      displayValue: text,
      value: newValue,
      filterName: filterName,
      singularFilterCategory: true,
    }

    if (newValue == '') {
      handleRemoveFilter({ ...option, value: value ?? '' })
    } else {
      handleAddFilter(option)
    }
  }

  return (
    <StyledMpInsuranceFilter>
      <JATOText fontSize={14} as="p">
        {filterName}
      </JATOText>
      <div className="radioGroup">
        {Array.from(optionFilterTypes).map(([optionValue, optionText]) => (
          <RadioWithGtm
            checked={optionValue === (value ?? '')}
            onChange={() => onChange(optionValue, optionText)}
            key={uuid()}
            id={id}
            value={optionValue}
            label={optionText}
            parentLabel={filterName}
          >
            <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
              {optionText}
            </JATOText>
          </RadioWithGtm>
        ))}
      </div>
    </StyledMpInsuranceFilter>
  )
}
