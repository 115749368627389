import { JATOIcon } from '@jato/ui-component-library'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'

interface INewsMetadataAuthorProps {
  author: string
}

export const NewsMetadataAuthor: React.FC<INewsMetadataAuthorProps> = ({
  author,
}: INewsMetadataAuthorProps) => {
  const translations = useAppSelector(getTranslations)
  return (
    <>
      <JATOIcon iconName="baseline_person" iconSize={14} />
      <span>
        {translations.JNT_ART_Author} {author}
      </span>
    </>
  )
}
