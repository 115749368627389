import styled from 'styled-components'
import Theme from 'theme'

export const StyledSpecsVehicleNews = styled.div`
  article {
    background: linear-gradient(
      to bottom,
      ${Theme.colors.white},
      ${Theme.colors.midGrey}
    );
  position: relative;
`

export const LogoNewsLoader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 25vh;
`
