import styled from 'styled-components'
import Theme from 'theme'

export const StyledSpecificationsGrid = styled.div`
  article {
    background: linear-gradient(
      to bottom,
      ${Theme.colors.white},
      ${Theme.colors.midGrey}
    );
    div:nth-child(3) {
      /* Remove JATOBasicCard footer's top border */
      border-top: none;

      /* Position button 24px from bottom of card */
      button {
        position: absolute;
        bottom: ${Theme.space[4]}px;
      }
    }
  }
  position: relative;
  .ej-grid-header {
    text-align: center;
    box-sizing: border-box;
    font-weight: 400;
    padding-top: 10px;
  }
  .disablecheckbox {
    pointer-events: none;
    opacity: 0.5;
  }
  .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
    padding: 0 1.8em 0 0.6em;
    visibility: hidden;
  }

  .e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: white;
    border-color: transparent;
    color: black;
  }

  .e-pager .e-currentitem,
  .e-pager .e-currentitem {
    background: #c40005;
    color: #fff;
    opacity: 1;
  }
  .e-pager .e-currentitem.e-numericitem.e-focused {
    background: #c40005;
    box-shadow: none;
  }

  .vehicleCountMessage {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  .e-grid.e-responsive .e-gridclip .e-headercelldiv:has(+ .e-descending),
  .e-grid.e-responsive .e-gridclip .e-headercelldiv:has(+ .e-ascending) {
    text-overflow: ellipsis;
  }

  .e-grid .e-rowcell {
    padding: 2px 5px;
  }

  .e-grid .e-headercell {
    padding: 0 5px;
  }

  .e-grid .e-gridheader tr th:last-child {
    padding-right: 5px;
  }

  .e-grid:not(.e-grid-min-height)
    .e-gridcontent
    tr
    td:not(.e-indentcell):first-child:empty,
  .e-grid:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
    height: unset;
  }

  .e-grid .e-altrow {
    background-color: ${Theme.colors.ultraLightGrey};
  }
`
