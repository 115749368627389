import VolumesCountryStep from 'components/Volumes/VolumesCountryStep'
import VolumesNavigation from 'components/Volumes/VolumesNavigation'
import VolumesNewsArticles from 'components/Volumes/VolumesNewsArticles'
import VolumesPeriodSelectionsStep from 'components/Volumes/VolumesPeriodSelectionsStep'
import VolumesQueriesContainer from 'components/Volumes/VolumesQueriesContainer'
import VolumesQueryDefinitionStep from 'components/Volumes/VolumesQueryDefinitionStep'
import VolumesQueryDescription from 'components/Volumes/VolumesQueryDescription'
import VolumesReportStep from 'components/Volumes/VolumesReportStep'
import VolumesStartStep from 'components/Volumes/VolumesStartStep'
import VolumesStepper from 'components/Volumes/VolumesStepper'
import VolumesVideoTutorial from 'components/Volumes/VolumesVideoTutorial'
import { isNullOrEmpty } from 'helper/arrayHelper'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesQueryState } from 'redux/volumes/volumesSlice'
import { VolumesPageType } from 'router/RoutePaths'
import { StyledVolumes } from './Volumes.styles'
import { VolumesWelcomePopup } from 'components/Modals/VolumesWelcomePopup/VolumesWelcomePopup'
import { VolumesJATOAcademyPopUp } from 'components/Modals/VolumesJATOAcademyPopUp/VolumesJATOAcademyPopUp'

export const Volumes: React.FC = () => {
  const { volumesStep = VolumesPageType.StartReset } = useParams<{
    volumesStep: VolumesPageType
  }>()

  const translations = useAppSelector(getTranslations)
  const queryState = useAppSelector(getVolumesQueryState)

  const getCurrentStepComponent = (): JSX.Element => {
    switch (volumesStep) {
      case VolumesPageType.StartReset:
        return <VolumesStartStep />
      case VolumesPageType.Country:
        return <VolumesCountryStep />
      case VolumesPageType.QueryDefinition:
        return <VolumesQueryDefinitionStep />
      case VolumesPageType.PeriodSelections:
        return <VolumesPeriodSelectionsStep />
      case VolumesPageType.Report:
        return <VolumesReportStep />
      default:
        return <VolumesStartStep />
    }
  }

  return (
    <StyledVolumes>
      <VolumesNavigation title={translations.JNT_Volumes_Nav_QueryManager} />
      <Container xl lg md sm xs className="volumesContainer">
        <Row>
          <Col sm={10}>
            <VolumesStepper />
            {getCurrentStepComponent()}
          </Col>
          <Col sm={2}>
            <div className="volumesSidePanel">
              {!isNullOrEmpty(queryState.countries) && <VolumesNewsArticles />}
              <VolumesQueryDescription />
              <VolumesVideoTutorial />
              <VolumesQueriesContainer />
            </div>
          </Col>
        </Row>
      </Container>
      <VolumesWelcomePopup />
      <VolumesJATOAcademyPopUp />
    </StyledVolumes>
  )
}
