import { NewsArticleContent } from 'components/News/NewsArticleDetail/NewsArticleContent'

import { NewsFlatMetadata } from 'components/News/NewsMetadata/NewsFlatMetadata'
import { NewsArticle } from 'models/News/Article/NewsArticle'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { v4 as uuid } from 'uuid'
import { StyledMyPageNewsArticle } from './MyPageNewsArticle.styles'

interface INewsArticleProps {
  article: NewsArticle
}

export const MyPageNewsArticle: React.FC<INewsArticleProps> = ({
  article,
}: INewsArticleProps) => (
  <StyledMyPageNewsArticle>
    <Container fluid>
      <Row>
        <Col md={12}>
          <div className="newsDetailHeadline" id="mainArticle">
            {article.headline}
          </div>
          <NewsFlatMetadata
            author={article.author}
            countryCode={article.countryCode}
            date={article.releaseDate}
          />
          <div className="newsDetailSynposis">{article.synopsis}</div>
          <NewsArticleContent
            key={uuid()}
            contentParts={article.contentParts}
          />
        </Col>
      </Row>
    </Container>
  </StyledMyPageNewsArticle>
)
