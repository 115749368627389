import { JATOButton, JATOButtonIcon } from '@jato/ui-component-library'
import NotAvailableIcon from 'assets/icons/NotAvailable.svg'
import { Accordion } from 'components/Accordion/Accordion'
import { Loader } from 'components/Loader'
import {
  getWltpItems,
  getWltpVehicleData,
  WltpVehicleItem,
} from 'helper/specificationsBuildRequestHelper'
import { useGetBrochureVehicles, useWltpData } from 'hooks/carSpecs'
import { EbrochureItemWithLocation } from 'models/Specifications/GetWltpRequest'
import React, { Fragment, useState } from 'react'
import { useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  getSpecsVehicles,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { SpecificationsFaqModal } from '../SpecificationsFaq/SpecificationsFaqModal'

export const WltpSection: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const specsUserData = useAppSelector(getSpecsUserState)
  const { wltpBrochureItems } = useAppSelector(getSpecsVehicles)

  const { lastSelectedMarket, wltpValidMarketList } = specsUserData.settings

  const { mutate: getWltpData, isLoading } = useWltpData()
  const { data: { brochureVehicles } = { brochureVehicles: [] } } =
    useGetBrochureVehicles()

  const wltpItems: WltpVehicleItem[] =
    brochureVehicles.length > 0
      ? getWltpItems(getWltpVehicleData(brochureVehicles, wltpBrochureItems))
      : []

  const getCategories = (): string[] =>
    wltpBrochureItems.length > 0 && wltpItems.length > 0
      ? Object.keys(wltpItems[0])
      : []

  const getCharacteristics = (categoryName: string): string[] =>
    Object.keys(wltpItems[0][categoryName])

  const showWltpButton =
    lastSelectedMarket && wltpValidMarketList.includes(lastSelectedMarket)

  const renderFormattedValue = (
    value: EbrochureItemWithLocation,
    index: number
  ): JSX.Element => (
    <>
      <span key={index} className="wltpCell">
        {value.dataValue === '-' ? (
          <img
            src={NotAvailableIcon}
            title={translations['JNT_Specs_Not_Available']}
          />
        ) : (
          <a className="wltpValue">
            {Number.parseFloat(value.dataValue).toFixed(1)}
          </a>
        )}
      </span>{' '}
    </>
  )

  const renderWltpHeader = (): JSX.Element => (
    <>
      <JATOButtonIcon
        id="specsReportWltpInfoButton"
        iconName="baseline_info"
        className="wltpInfo"
        onClick={onWltpInfoClick}
        disabled={isLoading}
      />
      {showWltpButton && (
        <JATOButton
          size="small"
          className="wltpButton"
          id="specsReportWltpButton"
          onClick={onWltpClick}
        >
          {translations.JNT_WLTP_BUTTON_TEXT}
        </JATOButton>
      )}
    </>
  )

  const onWltpInfoClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setShowWltpFAQ(true)
  }

  const onWltpClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()
    setOpenWltpSection(true)
    getWltpData()
  }

  const [showWltpFAQ, setShowWltpFAQ] = useState(false)
  const [openWltpSection, setOpenWltpSection] = useState(false)

  return (
    <>
      <Accordion
        id="specsReportWltpAccordion"
        titleText={translations.JNT_WLTP}
        isOpen={openWltpSection}
        titleHeaderWidth="72vw"
        extendHeader={renderWltpHeader()}
      >
        {isLoading && <Loader className="wltpLoader" />}
        {!isLoading &&
          getCategories().map((categoryName: string, index) => (
            <Fragment key={categoryName}>
              <div className="wltpCategory sticky">
                {translations[categoryName]}
              </div>
              <table className="tableStyle" key={index}>
                <tbody>
                  {getCharacteristics(categoryName).map(
                    (characteristicName, index) => (
                      <tr className="tableRow" key={index}>
                        <td key={index} className="tableCell sticky">
                          <div style={{ paddingLeft: '30px' }}>
                            {translations[characteristicName]}
                          </div>
                        </td>

                        {wltpItems.map((item, index) => (
                          <td key={index} className="tableCell">
                            {item[categoryName][characteristicName].map(
                              (v: EbrochureItemWithLocation, i: number) =>
                                renderFormattedValue(v, i)
                            )}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Fragment>
          ))}
      </Accordion>

      <SpecificationsFaqModal
        isOpen={showWltpFAQ}
        onClose={() => setShowWltpFAQ(false)}
      />
    </>
  )
}
