import styled from 'styled-components'

export const StyledSliderFilter = styled.div`
  .sliderWrapper {
    padding: 8px 8px 16px;
  }

  .rc-slider-track {
    height: 2px;
    background-color: #c40005;
  }

  .rc-slider-rail {
    height: 2px;
  }

  .rc-slider-handle {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: #c40005;
    border-radius: 0;
    border-color: #c40005 !important;
    box-shadow: none !important;
  }
`
