import { JATOIcon } from '@jato/ui-component-library'
import React, { useEffect, useState } from 'react'
import { StyledAccordion } from './Accordion.styles'

interface IAccordionProps {
  id?: string
  titleText: string
  titleHeaderWidth?: string
  isOpen: boolean
  children?: React.ReactNode
  extendHeader?: React.ReactNode
  titleBackgroundColour?: string
}

export const Accordion: React.FC<IAccordionProps> = ({
  id = 'accordion',
  titleText,
  titleHeaderWidth,
  isOpen,
  children,
  extendHeader,
  titleBackgroundColour,
}: IAccordionProps) => {
  const [accordionStatus, setAccordionStatus] = useState<boolean>(isOpen)

  useEffect(() => {
    setAccordionStatus(isOpen)
  }, [isOpen])

  const iconName = accordionStatus
    ? 'baseline_expand_more'
    : 'baseline_navigate_next'

  return (
    <StyledAccordion>
      <div
        className="accordion"
        onClick={() => setAccordionStatus(!accordionStatus)}
      >
        <div
          id={id + 'Title'}
          className="accordionTitle"
          style={{
            width: titleHeaderWidth ?? '100%',
            backgroundColor: titleBackgroundColour,
          }}
        >
          <div id={id + 'TitleIcon'} className="accordionTitleIcon">
            <JATOIcon iconName={iconName} iconSize={18} />
          </div>
          <div className="accordionTitleText">{titleText}</div>
          {extendHeader}
        </div>
      </div>
      {accordionStatus && children}
    </StyledAccordion>
  )
}
