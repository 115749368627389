import { AxiosResponse } from 'axios'
import { LoginRequest } from 'models/Login/LoginRequest'
import { VolumesUserState } from 'models/Login/VolumesLogin/VolumesUserState'
import {
  AccountLockRequest,
  AccountLockResponse,
} from 'models/Portal/AccountLockModel'
import {
  ActivateUserAccountRequest,
  ActivateUserAccountResponse,
} from 'models/Portal/ActivateUserAccount'
import {
  ChangePasswordRequest,
  PasswordResponse,
} from 'models/Portal/ChangePasswordRequest'
import { CompanyState } from 'models/Portal/CustomerRegistration/CompanyState'
import { CountryState } from 'models/Portal/CustomerRegistration/CountryState'
import {
  CheckUserNameRequest,
  CustomerCountryRequest,
  UserDetailState,
} from 'models/Portal/CustomerRegistration/CustomerRegistrationDetail'
import { CustomerData } from 'models/Portal/CustomerRegistration/CustomerRegistrationInfoResponse'
import {
  CustomerSupportInfoRequest,
  CustomerSupportRequest,
} from 'models/Portal/CustomerSupport/CustomerSupportModel'
import {
  ForgotPasswordLinkRequest,
  ForgotPasswordLinkResponse,
} from 'models/Portal/ForgotPassword/ForgotPasswordModel'
import { ForgotPasswordResetRequest } from 'models/Portal/ForgotPassword/ForgotPasswordResetModel'
import { GetLanguageAndCurrencyResponse } from 'models/Portal/GetLanguageAndCurrencyResponse'
import { LoadInfoDataRequest } from 'models/Portal/LoadInfoDataRequest'
import { RecaptchaRequest, RecaptchaResponse } from 'models/Portal/Recaptcha'
import {
  SalesEnquiryInfoRequest,
  SalesEnquiryRequest,
} from 'models/Portal/SalesEnquiry/SalesEnquiryModel'
import { VersionSettings } from 'models/Portal/VersionSettings'
import { YourCommentsState } from 'models/Portal/YourCommentsState'
import { volumesApi } from 'services/apiWithAuth'

class PortalService {
  public getLanguageAndCurrencyData(): Promise<GetLanguageAndCurrencyResponse> {
    return volumesApi.get(`JATONetPortal`).then((response) => response.data)
  }

  public getVersionSettings(): Promise<VersionSettings> {
    return volumesApi.get(`VersionSetting`).then((response) => response.data)
  }

  public getRecaptchaData(
    recaptchaRequest: RecaptchaRequest
  ): Promise<RecaptchaResponse> {
    return volumesApi
      .post<RecaptchaRequest, AxiosResponse<RecaptchaResponse>>(
        `RegCaptcha/RegCaptcha`,
        recaptchaRequest
      )
      .then((response) => response.data)
  }

  public login(loginRequest: LoginRequest): Promise<VolumesUserState> {
    return volumesApi
      .post<LoginRequest, AxiosResponse<VolumesUserState>>(
        `Login`,
        loginRequest
      )
      .then((response) => response.data)
  }

  public saveAcceptLicenseSetting(): Promise<boolean> {
    return volumesApi.put(`SaveAcceptLicence`).then((response) => response.data)
  }

  public activateUserAccount(
    activateUserAccountRequest: ActivateUserAccountRequest
  ): Promise<ActivateUserAccountResponse> {
    return volumesApi
      .post<
        ActivateUserAccountRequest,
        AxiosResponse<ActivateUserAccountResponse>
      >(`ActivateUserAccount`, activateUserAccountRequest)
      .then((response) => response.data)
  }
  public loadRegistrationInfoData(): Promise<CustomerData> {
    return volumesApi
      .get(`LoadRegistrationInfoData/load`)
      .then((response) => response.data)
  }

  public loadCustomerCountries(
    customerCountryRequest: CustomerCountryRequest
  ): Promise<CountryState[]> {
    return volumesApi
      .post<CustomerCountryRequest, AxiosResponse<CountryState[]>>(
        `LoadCustomerCountries/load`,
        customerCountryRequest
      )
      .then((response) => response.data)
  }

  public loadCompanies(
    customerCompanyRequest: CustomerCountryRequest
  ): Promise<CompanyState[]> {
    return volumesApi
      .post<CustomerCountryRequest, AxiosResponse<CompanyState[]>>(
        `LoadCompanies/load`,
        customerCompanyRequest
      )
      .then((response) => response.data)
  }

  public registerCustomer(customerDetail: UserDetailState): Promise<boolean> {
    return volumesApi
      .post<UserDetailState, AxiosResponse<boolean>>(
        `CustomerRegister/register`,
        customerDetail
      )
      .then((response) => response.data)
  }

  public checkUserName(
    checkUserNameRequest: CheckUserNameRequest
  ): Promise<boolean> {
    return volumesApi
      .post<CheckUserNameRequest, AxiosResponse<boolean>>(
        `CheckUserName/userNameExist`,
        checkUserNameRequest
      )
      .then((response) => response.data)
  }

  public loadSalesEnquiryInfoData(
    salesEnquiryRequest: SalesEnquiryInfoRequest
  ): Promise<CustomerData> {
    return volumesApi
      .post<SalesEnquiryInfoRequest, AxiosResponse<CustomerData>>(
        `LoadSalesInfoData/load`,
        salesEnquiryRequest
      )
      .then((response) => response.data)
  }

  public submitSalesEnquiry(
    salesEnquiryRequest: SalesEnquiryRequest
  ): Promise<boolean> {
    return volumesApi
      .post<SalesEnquiryRequest, AxiosResponse<boolean>>(
        `InsertSalesEnquiry/submit`,
        salesEnquiryRequest
      )
      .then((response) => response.data)
  }

  public loadSupportInfoData(
    customerSupportInfoRequest: CustomerSupportInfoRequest
  ): Promise<CustomerData> {
    return volumesApi
      .post<CustomerSupportInfoRequest, AxiosResponse<CustomerData>>(
        `LoadSupportInfoData/load`,
        customerSupportInfoRequest
      )
      .then((response) => response.data)
  }

  public submitCustomerSupportRequest(
    customerSupportRequest: CustomerSupportRequest
  ): Promise<boolean> {
    return volumesApi
      .post<CustomerSupportRequest, AxiosResponse<boolean>>(
        `InsertTechnicalSupport/submit`,
        customerSupportRequest
      )
      .then((response) => response.data)
  }

  public getPasswordResetLink(
    forgotPasswordLinkRequest: ForgotPasswordLinkRequest
  ): Promise<ForgotPasswordLinkResponse> {
    return volumesApi
      .post<
        ForgotPasswordLinkRequest,
        AxiosResponse<ForgotPasswordLinkResponse>
      >(`ManagePassword`, forgotPasswordLinkRequest)
      .then((response) => response.data)
  }

  public resetPassword(
    forgotPasswordResetRequest: ForgotPasswordResetRequest
  ): Promise<ForgotPasswordLinkResponse> {
    return volumesApi
      .post<
        ForgotPasswordResetRequest,
        AxiosResponse<ForgotPasswordLinkResponse>
      >(`ResetPassword`, forgotPasswordResetRequest)
      .then((response) => response.data)
  }

  public validateUserGuid(guid: string): Promise<boolean> {
    return volumesApi
      .get(`ValidateUserGuid/${guid}`)
      .then((response) => response.data)
  }

  public logOutAll(): Promise<boolean> {
    return volumesApi
      .post(`LogOutUser/LogOutUser`)
      .then((response) => response.data)
  }

  public lockAccount(
    accountLockRequest: AccountLockRequest
  ): Promise<AccountLockResponse> {
    return volumesApi
      .post<AccountLockRequest, AxiosResponse<AccountLockResponse>>(
        `UpdateUserAccount`,
        accountLockRequest
      )
      .then((response) => response.data)
  }

  public getCountries(request: LoadInfoDataRequest): Promise<CountryState[]> {
    return volumesApi
      .post<LoadInfoDataRequest, AxiosResponse<CountryState[]>>(
        'LoadInfoData/load',
        request
      )
      .then((response) => response.data)
  }

  public submitComments(request: YourCommentsState): Promise<boolean> {
    return volumesApi
      .post<YourCommentsState, AxiosResponse<boolean>>(
        'InsertComments/submit',
        request
      )
      .then((response) => response.data)
  }

  public changePassword(
    request: ChangePasswordRequest
  ): Promise<PasswordResponse> {
    return volumesApi
      .post<ChangePasswordRequest, AxiosResponse<PasswordResponse>>(
        'ChangePassword',
        request
      )
      .then((response) => response.data)
  }
}

const portalService = new PortalService()
export default portalService
