import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsArticleContent = styled.div`
  .contentBoxShadow {
    box-shadow: 0px 0px 2px 2px ${JATOTheme.colors.midDarkGrey};
  }

  .mp-basic-article-header {
    margin: 30px 0 15px;
    font-size: 16px;
  }

  .mp-basic-article-table {
    font-size: 24px;
    color: #424242;
    width: 100%;
    border: 1px solid #d9d9d9;
    text-align: right;
    border-spacing: unset;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  .mp-basic-article-table tr:first-child {
    background-color: #f0f0f0;
  }

  .mp-basic-article-table tr:nth-child(even) {
    background-color: #ffffff;
  }

  .mp-basic-article-table th:first-child {
    text-align: left;
  }

  .mp-basic-article-table th:nth-child(2) {
    text-align: left;
  }

  .mp-basic-article-table td:nth-child(2) {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }

  .mp-basic-article-table th {
    border: none;
    padding: 12px;
    font-weight: 500;
    border-bottom: 1px solid #d9d9d9;
  }

  .mp-basic-article-table td {
    border: none;
    padding: 16px 12px;
    font-weight: 500;
    border-bottom: 1px solid #d9d9d9;
  }

  .mp-basic-article-table a {
    font-size: 20px;
    color: #1677ff;
    text-decoration: underline;
  }

  .mp-basic-article-table .increase td:nth-last-child(-n + 3):after {
    content: '';
    background: url(images/mp-increase.png) no-repeat;
    display: inline-block;
    height: 14px;
    width: 15px;
    margin-left: 12px;
  }

  .mp-basic-article-table .decrease td:nth-last-child(-n + 3):after {
    content: '';
    background: url(images/mp-decrease.png) no-repeat;
    display: inline-block;
    height: 14px;
    width: 15px;
    margin-left: 12px;
  }

  .legend-increase {
    margin-bottom: 8px;
  }

  .legend-increase:before {
    content: '';
    background: url(images/mp-increase.png) no-repeat;
    display: inline-block;
    height: 14px;
    width: 15px;
    margin-right: 8px;
  }

  .legend-decrease:before {
    content: '';
    background: url(images/mp-decrease.png) no-repeat;
    display: inline-block;
    height: 14px;
    width: 15px;
    margin-right: 8px;
  }
`
