import { JATOVerticalSpacer } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { NewsArticleBreadcrumbs } from 'components/News/NewsArticleDetail/NewsArticleBreadcrumbs'
import { NewsArticleContent } from 'components/News/NewsArticleDetail/NewsArticleContent'
import { NewsArticleImages } from 'components/News/NewsArticleDetail/NewsArticleImages'
import NewsCategory from 'components/News/NewsCategory'
import NewsRelatedArticles from 'components/News/NewsRelatedArticles'
import { useGetNewsArticleByArticleId } from 'hooks/news'

import NewsArticleActions from 'components/News/Buttons/NewsArticleActions'
import NewsExportPdfButton from 'components/News/Buttons/NewsExportPdfButton'
import NewsPhotoGalleryButton from 'components/News/Buttons/NewsPhotoGalleryButton'
import { NewsFlatMetadata } from 'components/News/NewsMetadata/NewsFlatMetadata'
import { NewsMonthlyPaymentsFooter } from 'components/News/NewsMonthlyPaymentsFooter/NewsMonthlyPaymentsFooter'
import { NewsMonthlyPaymentsPopup } from 'components/News/NewsMonthlyPaymentsPopup/NewsMonthlyPaymentsPopup'
import { defaultMissingPhotoUrl } from 'config'
import { gtmLogNewsArticleView } from 'helper/gtm'
import { NewsArticleResponse } from 'models/News/Article/NewsArticleResponse'
import {
  NewsCategoryType,
  getCategoriesBySubjects,
} from 'models/News/NewsCategoryType'
import { NewsPage } from 'models/News/NewsPageType'
import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useParams } from 'react-router-dom'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import { v4 as uuid } from 'uuid'
import { StyledNewsArticle } from './NewsArticle.styles'

interface INewsArticleProps {
  articleId: number
  isVolumesNewsArticle?: boolean
}

export const NewsArticle: React.FC<INewsArticleProps> = ({
  articleId: propsId,
  isVolumesNewsArticle,
}: INewsArticleProps) => {
  const { newsPage } = useParams<{ newsPage: NewsPage }>()
  const { articleId: paramsId } = useParams<{ articleId: string }>()
  const commonUserData = useAppSelector(selectCommonUserData)
  const articleId = propsId ?? +paramsId
  const { data: articleResponse, isFetching } = useGetNewsArticleByArticleId(
    {
      id: articleId,
      languageId: commonUserData.languageId,
    },
    isVolumesNewsArticle
  )

  const subjects = articleResponse?.article?.subjects ?? []
  const categories = getCategoriesBySubjects(subjects)
  const firstCategory = categories[0]

  const breadcrumbPage =
    newsPage === NewsPage.ViewSelection
      ? firstCategory
      : newsPage ?? firstCategory

  const hasValidImages = (articleResponse: NewsArticleResponse): boolean =>
    articleResponse.article.imageLibrary.length > 0 &&
    !articleResponse.article.imageLibrary.includes(defaultMissingPhotoUrl)

  useEffect(() => {
    if (articleResponse) {
      gtmLogNewsArticleView(articleResponse.article)
    }
  }, [articleResponse])

  useEffect(() => {
    document.getElementById('newsContainer')?.scrollTo(0, 0)
  }, [articleId])

  useEffect(() => {
    const newsContainer = document.getElementById('newsContainer')
    if (newsContainer)
      newsContainer.style.height =
        firstCategory === NewsCategoryType.MonthlyPayments
          ? 'calc(100vh - 327px)'
          : ''
    return () => {
      if (newsContainer) newsContainer.style.height = ''
    }
  }, [firstCategory])

  return (
    <StyledNewsArticle>
      <Container fluid>
        <Row>
          <Col md={12}>
            {isFetching && <Loader />}
            {!isFetching && articleResponse && articleResponse.isSuccess && (
              <Container fluid>
                <Row>
                  <Col md={12}>
                    <NewsArticleBreadcrumbs
                      newsPage={breadcrumbPage}
                      title={articleResponse.article.headline}
                    />
                    <JATOVerticalSpacer height={16} />
                    <div className="newsCategoryAndArticleActions">
                      <NewsCategory categories={categories} />
                      <NewsExportPdfButton article={articleResponse.article} />
                      {hasValidImages(articleResponse) && (
                        <NewsPhotoGalleryButton
                          article={articleResponse.article}
                        />
                      )}
                      <NewsArticleActions article={articleResponse.article} />
                    </div>
                    <JATOVerticalSpacer height={5} />
                    <div className="newsDetailHeadline" id="mainArticle">
                      {articleResponse.article.headline}
                    </div>
                    <NewsFlatMetadata
                      author={articleResponse.article.author}
                      countryCode={articleResponse.article.countryCode}
                      date={articleResponse.article.releaseDate}
                    />
                    <div className="newsDetailSynposis">
                      {articleResponse.article.synopsis}
                    </div>
                    <NewsArticleImages
                      imageArticleList={
                        articleResponse.article.imageArticleList
                      }
                    />
                    <NewsArticleContent
                      key={uuid()}
                      contentParts={articleResponse.article.contentParts}
                    />
                  </Col>
                </Row>
                <Row className="newsRelatedArticles">
                  <Col md={12}>
                    {articleResponse.relatedArticleList && (
                      <NewsRelatedArticles
                        relatedArticleResponse={
                          articleResponse.relatedArticleList
                        }
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
      </Container>
      {firstCategory == NewsCategoryType.MonthlyPayments && (
        <>
          <NewsMonthlyPaymentsPopup />
          <NewsMonthlyPaymentsFooter />
        </>
      )}
    </StyledNewsArticle>
  )
}
