import {
  JATOBasicCard,
  JATOButton,
  JATOButtonGroup,
  JATOGroup,
  JATOHeading,
  JATOIcon,
  JATOTheme,
  JATOTooltip,
} from '@jato/ui-component-library'
import { isNullOrEmpty } from 'helper/arrayHelper'
import {
  VolumesDataPointGroup,
  getActiveStep,
  getOutputPoint,
  getPathForStep,
} from 'helper/volumesHelper'
import { JATONetTreeview } from 'models/Volumes/JATONetTreeview'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  setVolumesQueryState
} from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import VolumesQueryCountrySelectionPanel from './VolumesQueryCountrySelectionPanel'
import VolumesQueryDatasetSelectionPanel from './VolumesQueryDatasetSelectionPanel'
import { StyledVolumesQueryDefinitionStep } from './VolumesQueryDefinitionStep.styles'
import VolumesQueryNumericSelectionPanel from './VolumesQueryNumericSelectionPanel'
import VolumesQuerySelectionPanel, {
  IVolumesQuerySelectionPanelRef,
} from './VolumesQuerySelectionPanel'
import { VolumesQueryTreePanel } from './VolumesQueryTreePanel'

export const VolumesQueryDefinitionStep: React.FC = () => {
  const history = useHistory()
  const queryState = useAppSelector(getVolumesQueryState)

  // if step is not ready, redirect to last ready step
  if (isNullOrEmpty(queryState.countries)) {
    const step = getActiveStep(queryState)
    history.push(getPathForStep(step))
  }

  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)

  const selectionPanelRef = useRef<IVolumesQuerySelectionPanelRef>(null)
  const [selectedItem, setSelectedItem] = useState<JATONetTreeview>()

  const onCheckedChange = (item: JATONetTreeview): void => {
    if (
      item.dataPointGroup === VolumesDataPointGroup.Country ||
      item.dataPointGroup === VolumesDataPointGroup.VehicleType
    ) {
      const outputPoints = queryState.outputPoints ?? []
      const update = outputPoints.some((o) => o.id === item.dataPointGroup)
        ? outputPoints.filter((o) => o.id !== item.dataPointGroup)
        : [...outputPoints, getOutputPoint(item)]
      dispatch(
        setVolumesQueryState({
          ...queryState,
          outputPoints: update,
        })
      )
      return
    }

    const isChecked = queryState.vehicleAttributes?.some(
      (dp) => dp.id === item.dataPointName
    )
    isChecked ? onClearChoicesClick(item) : onSelectAllClick()
  }

  const onSelectAllClick = (): void => {
    selectionPanelRef.current?.onSelectAll()
  }

  const onClearChoicesClick = (item: JATONetTreeview | undefined): void => {
    selectionPanelRef.current?.onClearChoices(item)
  }

  const onNext = (): void => history.push(RoutePaths.VolumesPeriodSelections)

  const onNextClick = (): void => {
    onNext()
  }

  return (
    <StyledVolumesQueryDefinitionStep>
      <JATOBasicCard
        footer={() => (
          <JATOButtonGroup justifyContent="flex-end">
            <JATOButton
              id="volumesQueryDefinitionStepSelectAllButton"
              variant="secondary"
              disabled={
                selectedItem?.dataPointGroup ===
                  VolumesDataPointGroup.VolumeBanding ||
                selectedItem?.dataPointGroup === VolumesDataPointGroup.Pricing
              }
              onClick={onSelectAllClick}
            >
              {translations.JNT_Volumes_QG_SetQuerySelections}
            </JATOButton>
            <JATOButton
              id="volumesQueryDefinitionStepClearChoicesButton"
              variant="secondary"
              onClick={() => onClearChoicesClick(selectedItem)}
            >
              {translations.JNT_Volumes_QG_ClearSelections}
            </JATOButton>
            <JATOButton
              id="volumesQueryDefinitionStepNextButton"
              variant="primary"
              disabled={
                isNullOrEmpty(queryState.vehicleAttributes) &&
                isNullOrEmpty(queryState.outputPoints)
              }
              onClick={onNextClick}
            >
              {translations.JNT_Volumes_QG_Next}
              <JATOIcon
                iconName="baseline_arrow_forward"
                iconSize={18}
                style={{ marginLeft: JATOTheme.space[2] }}
              />
            </JATOButton>
          </JATOButtonGroup>
        )}
      >
        <div className="queryStepHeader">
          <JATOHeading as="h3" fontWeight="medium">
            {translations.JNT_Volumes_QG_TabQuerySelections}
          </JATOHeading>

          <JATOTooltip
            title={translations.JNT_Volumes_QIO_QueryDefinitionInfo}
            arrow={true}
            placement="left-start"
            showOnlyOnOverflow={false}
          >
            <JATOIcon iconName="baseline_info" />
          </JATOTooltip>
        </div>
        <JATOGroup flexDirection="row" className="queryStepContainer">
          <div>
            <div className="subheader">
              {translations.JNT_Volumes_QG_TabQuerySelections}
            </div>

            <VolumesQueryTreePanel
              onSelectItem={setSelectedItem}
              onCheckedChange={onCheckedChange}
            />
          </div>
          <div>
            <div className="subheader">{selectedItem?.longText ?? ''}</div>
            <div className="selectionPanel">
              {selectedItem?.dataPointGroup ===
              VolumesDataPointGroup.Country ? (
                <VolumesQueryCountrySelectionPanel ref={selectionPanelRef} />
              ) : selectedItem?.dataPointGroup ===
                VolumesDataPointGroup.VehicleType ? (
                <VolumesQueryDatasetSelectionPanel ref={selectionPanelRef} />
              ) : selectedItem?.dataMetaType === 'N' ? (
                <VolumesQueryNumericSelectionPanel
                  ref={selectionPanelRef}
                  selectedItem={selectedItem}
                />
              ) : (
                <VolumesQuerySelectionPanel
                  ref={selectionPanelRef}
                  selectedItem={selectedItem}
                />
              )}
            </div>
          </div>
        </JATOGroup>
      </JATOBasicCard>
    </StyledVolumesQueryDefinitionStep>
  )
}
