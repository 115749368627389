import styled from 'styled-components'
import Theme from 'theme'

export const StyledLayout = styled.div`
  /* Top padding to allow space for the app header */
  padding-top: var(--app-header-height);

  #layout-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - (var(--app-header-height) + 136px));
    @media (max-width: ${Theme.breakpoints[1] - 1}px) {
      height: calc(100vh - var(--app-header-height) - 116px);
    }
  }

  #content-col,
  #filter-col,
  #footer-col {
    padding: 0px !important;
  }

  #content-row {
    flex: 0 1 auto !important;
  }

  #filter-row {
    flex: 1 !important;
  }

  #footer-row {
    flex: 1 !important;
  }
`
