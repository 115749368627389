import { AxiosResponse } from 'axios'
import { maxValuesClientSide } from 'config'
import { logTrace } from 'helper/loggingService'
import { LoginObject } from 'models/Login/LoginObject'
import { VolumesUserState } from 'models/Login/VolumesLogin/VolumesUserState'
import { Countries } from 'models/Volumes/Countries'
import { CountryGroup } from 'models/Volumes/CountryGroup'
import { CreateOrUpdateVehicleVolumeRequest } from 'models/Volumes/CreateOrUpdateVehicleVolumeRequest'
import { DataPointRequest } from 'models/Volumes/DataPointRequest'
import { DataPointValues } from 'models/Volumes/DataPointValues'
import { GetDataPointValuesRequest } from 'models/Volumes/GetDataPointValuesRequest'
import { GetPeriodDataRequest } from 'models/Volumes/GetPeriodDataRequest'
import { GetReportRequest } from 'models/Volumes/GetReportRequest'
import { JATONetTreeview } from 'models/Volumes/JATONetTreeview'
import { OutputReportResponse } from 'models/Volumes/OutputReportResponse'
import { PeriodDataPoint } from 'models/Volumes/PeriodDataPoint'
import { QueryDefinitionTreeRequest } from 'models/Volumes/QueryDefinitionTreeRequest'
import { QueryExportResponse } from 'models/Volumes/QueryExportResponse'
import { SaveQueryRequest } from 'models/Volumes/SaveQueryRequest'
import { UserValidData } from 'models/Volumes/UserValidData'
import { VehicleVolumeGroups } from 'models/Volumes/VehicleVolumeGroups'
import { VehicleVolumeTypeResponse } from 'models/Volumes/VehicleVolumeTypeResponse'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import { VolumesExcelExportRequest } from 'models/Volumes/VolumesExcelExportRequest'
import { VolumesNewsArticleRequest } from 'models/Volumes/VolumesNewsArticleRequest'
import { VolumesNewsArticleResponse } from 'models/Volumes/VolumesNewsArticleResponse'
import { VolumesQuery } from 'models/Volumes/VolumesQuery'
import { VolumesSelection } from 'models/Volumes/VolumesSelection'
import { VolumesUserSettingsRequest } from 'models/Volumes/VolumesUserSettings'
import { newsApi, volumesApi } from 'services/apiWithAuth'

class VolumesService {
  public login(loginRequest: LoginObject): Promise<VolumesUserState> {
    return volumesApi
      .post<LoginObject, AxiosResponse<VolumesUserState>>(
        `/volumeslogin`,
        loginRequest
      )
      .then((response) => {
        let requestString = ''
        if (loginRequest.username) {
          requestString = `username: ${loginRequest.username}`
        } else {
          requestString = `guid: ${loginRequest.guid}`
        }

        logTrace(
          'Successful login call',
          requestString,
          `userId: ${response.data.userId}`
        )

        return response.data
      })
  }

  public updateUserSettings(
    userSettings: VolumesUserSettingsRequest
  ): Promise<boolean> {
    return volumesApi
      .put<VolumesUserSettingsRequest, AxiosResponse<boolean>>(
        `/JATONetPortal/UpdateUserDetails`,
        userSettings
      )
      .then((resp) => resp.data)
  }

  public loadQueryById(queryId: number): Promise<DataPointRequest> {
    return volumesApi
      .get<DataPointRequest>(`/ManageVolumeQuery/GetQueryData/${queryId}`)
      .then((response) => response.data)
  }

  public getCountryData(
    databaseType?: VolumesDatabaseType
  ): Promise<Countries[]> {
    return volumesApi
      .get<Countries[]>(`/Volumes/GetUserCountries/${databaseType}`)
      .then((response) => response.data)
  }

  public loadAllQueries(databaseType: string): Promise<VolumesQuery[]> {
    return volumesApi
      .get<VolumesQuery[]>(`/ManageVolumeQuery/GetAllQueries/${databaseType}`)
      .then((response) => response.data)
  }

  public renameQuery(queryId: number, newName: string): Promise<void> {
    return volumesApi
      .post<void>(`/ManageVolumeQuery/RenameVolumesQuery/${queryId}/${newName}`)
      .then((response) => response.data)
  }

  public updateQuery(queryId: number, qryShared: boolean): Promise<boolean> {
    return volumesApi
      .put<VolumesQuery, AxiosResponse<boolean>>(
        `/ManageVolumeQuery/UpdateVolumeQuery/${queryId}/${qryShared}`
      )
      .then((response) => response.data)
  }

  public deleteQuery(queryId: number): Promise<boolean> {
    return volumesApi
      .delete<VolumesQuery, AxiosResponse<boolean>>(
        `/ManageVolumeQuery/QueryDelete/${queryId}`
      )
      .then((response) => response.data)
  }

  public getCountryGroups(
    databaseType?: VolumesDatabaseType
  ): Promise<CountryGroup[]> {
    return volumesApi
      .get<CountryGroup[]>(`/CountryGroups/list/${databaseType}`)
      .then((response) => response.data)
  }

  public createEditCountryGroup(countryGroup: CountryGroup): Promise<boolean> {
    return volumesApi
      .post<CountryGroup, AxiosResponse<boolean>>(
        `/CountryGroups/`,
        countryGroup
      )
      .then((response) => response.data)
  }

  public deleteCountryGroup(id: string): Promise<boolean> {
    return volumesApi
      .delete<CountryGroup, AxiosResponse<boolean>>(`/CountryGroups/${id}`)
      .then((response) => response.data)
  }

  public getVehicleVolumeTypes(): Promise<UserValidData[]> {
    return volumesApi
      .get<UserValidData[]>(`/Volumes/GetVehicleAndVolumesType`)
      .then((response) => response.data)
  }

  public getVehicleVolumeGroups(): Promise<VehicleVolumeGroups[]> {
    return volumesApi
      .get<VehicleVolumeGroups[]>(`/FetchVehicleVolumeGroupsByLicense`)
      .then((response) => response.data)
  }

  public createEditVehicleVolumeGroup(
    vehicleVolumeGroup: CreateOrUpdateVehicleVolumeRequest
  ): Promise<VehicleVolumeTypeResponse> {
    return volumesApi
      .post<
        CreateOrUpdateVehicleVolumeRequest,
        AxiosResponse<VehicleVolumeTypeResponse>
      >(`/createOrUpdateVehicleVolumeGroups`, vehicleVolumeGroup)
      .then((response) => response.data)
  }

  public restoreToDefaultVehicleVolumeGroups(
    databaseType: VolumesDatabaseType
  ): Promise<VehicleVolumeTypeResponse> {
    return volumesApi
      .post<VehicleVolumeTypeResponse>(
        `/restoreToDefaultVehicleVolumeGroups/${databaseType}`
      )
      .then((response) => response.data)
  }

  public getPeriodSelections(
    databaseType: VolumesDatabaseType
  ): Promise<VolumesSelection[]> {
    return volumesApi
      .get<VolumesSelection[]>(
        `/PeriodSelection/GetPeriodSelectionList/${databaseType}`
      )
      .then((response) => response.data)
  }

  public getPeriodDataPoints(
    dataPointRequest: GetPeriodDataRequest
  ): Promise<PeriodDataPoint[]> {
    return volumesApi
      .post<GetPeriodDataRequest, AxiosResponse<PeriodDataPoint[]>>(
        `/PeriodSelection/GetPeriodDataPointList`,
        dataPointRequest
      )
      .then((response) => response.data)
  }

  public getReport(request: GetReportRequest): Promise<OutputReportResponse> {
    return volumesApi
      .post<GetReportRequest, AxiosResponse<OutputReportResponse>>(
        `/OutputReport/GetReport`,
        request
      )
      .then((response) => response.data)
  }

  public saveQuery(request: SaveQueryRequest): Promise<number> {
    return volumesApi
      .post<SaveQueryRequest, AxiosResponse<number>>(
        `/ManageVolumeQuery`,
        request
      )
      .then((response) => response.data)
  }

  public exportQueryToExcel(
    request: VolumesExcelExportRequest
  ): Promise<QueryExportResponse> {
    return volumesApi
      .post<VolumesExcelExportRequest, AxiosResponse<QueryExportResponse>>(
        `/SaveQueryRequestForExport`,
        request
      )
      .then((response) => response.data)
  }

  public loadQueryDefinitionTree(
    request: QueryDefinitionTreeRequest
  ): Promise<JATONetTreeview[]> {
    return volumesApi
      .post<QueryDefinitionTreeRequest, AxiosResponse<JATONetTreeview[]>>(
        `/LoadVolQueryDefOptions`,
        request
      )
      .then((response) => response.data)
  }

  public getDataPointValues(
    request: GetDataPointValuesRequest
  ): Promise<DataPointValues[]> {
    return volumesApi
      .post<GetDataPointValuesRequest, AxiosResponse<DataPointValues[]>>(
        `VolQueryDefOptions/PostDataPointValues/${maxValuesClientSide}`,
        request
      )
      .then((response) => response.data)
  }

  public getPricingCurrencyData(): Promise<DataPointValues[]> {
    return volumesApi
      .get<DataPointValues[]>(`PricingCurrencyData/GetPricingCurrencyData`)
      .then((response) => response.data)
  }

  public getVolumesNewsArticles(
    request?: VolumesNewsArticleRequest
  ): Promise<VolumesNewsArticleResponse> {
    return newsApi
      .post<
        VolumesNewsArticleRequest,
        AxiosResponse<VolumesNewsArticleResponse>
      >('volumesNewsArticles', request)
      .then((response) => response?.data)
  }
}

const volumesService = new VolumesService()
export default volumesService
