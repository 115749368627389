import {
  createToast,
  JATOButtonIcon,
  JATOIcon,
  JATOLink,
  JATOTooltip,
} from '@jato/ui-component-library'
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Sort,
  SortSettingsModel,
} from '@syncfusion/ej2-react-grids'
import { Loader } from 'components/Loader'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { useResetBuiltVehiclesAndFilters } from 'hooks/carSpecs'
import {
  useGetBaskets,
  useRemoveBasket,
  useUpdateBasket,
} from 'hooks/specsBasketManagement'
import {
  BasketInfo,
  BasketVehicle,
  MarketInfo,
} from 'models/Specifications/Basket/BasketInfo'
import { SaveBasketResponse } from 'models/Specifications/Basket/SaveBasketResponse'
import { UpdateBasketRequest } from 'models/Specifications/Basket/UpdateBasketRequest'
import { ISpecsUserState } from 'models/Specifications/GetLicenceInfoResponse'
import { SpecsUserShipLog } from 'models/UserShipLog/UserShipLog'
import React, { useState } from 'react'
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getSpecsUserState,
  setSpecsUserState,
} from 'redux/specifications/specificationsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import userShipLogService from 'services/UserShipLog/UserShipLogService'
import { StyledBaskets } from './Baskets.styles'
import { SaveBasketModal } from './SaveBasketModal'

interface IBasketsProps {
  isUserBaskets: boolean
}

export const Baskets: React.FC<IBasketsProps> = (props: IBasketsProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(getSpecsUserState)
  const translations = useAppSelector(getTranslations)

  const { data: baskets, isLoading } = useGetBaskets()
  const { mutateAsync: removeBasket, isLoading: isRemoving } = useRemoveBasket()
  const { mutateAsync: updateBasket, isLoading: isUpdating } = useUpdateBasket()

  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedBasket, setSelectedBasket] = useState<BasketInfo>()

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'lastAccessDate', direction: 'Descending' }],
  }

  const { mutate: logSpecificationsClick } = useMutation(
    (log: SpecsUserShipLog) =>
      userShipLogService.logSpecsViewSelectedVersionClick(log)
  )

  const { mutateAsync: resetBuiltVehiclesAndFilters } =
    useResetBuiltVehiclesAndFilters()

  function logOnBasketClick(basketInfo: BasketInfo): void {
    const updateUserState: ISpecsUserState = {
      ...userData,
      settings: {
        ...userData.settings,
        lastSelectedMarket: basketInfo.markets[0].databaseName,
      },
    }

    dispatch(setSpecsUserState(updateUserState))

    const vehicles = basketInfo.markets
      .map((market: MarketInfo) =>
        market.vehicles.map(
          (basketVehicle: BasketVehicle) => basketVehicle.vehicleId
        )
      )
      .flatMap((x) => x)

    logSpecificationsClick({
      user: updateUserState,
      vehicles,
    })
  }

  const queryCellInfo = (args: any): void => {
    if (args.column.field == 'markets') {
      const flagName = args.data.markets[0].databaseName
        .split('_')[0]
        .replace('SSC', '')
        .replace('NSC', '')

      args.cell.innerHTML = `<img src="${process.env.PUBLIC_URL}/images/specifications/countryflags/${flagName}.png" style="width: 32px;"/>`
    } else if (args.column.field == 'basketName') {
      const basket = args.data as BasketInfo
      render(
        <JATOTooltip
          title={
            basket &&
            basket.markets[0].vehicles.map((v, index) => (
              <div key={index} style={{ padding: '5px 10px' }}>
                {basket.benchmarkVehicleId === v.vehicleId && (
                  <JATOIcon
                    iconName="custom_star"
                    iconSize={14}
                    style={{ verticalAlign: 'top', margin: '0 2px 0 -16px' }}
                  />
                )}
                {v.makeGlobal} {v.modelGlobal} {v.derivativeGlobal}
              </div>
            ))
          }
          maxWidth={500}
          placement="top-start"
          offset={[5, 10]}
          showOnlyOnOverflow={false}
          textAlign="left"
          key={basket.id}
        >
          <JATOLink
            display="inline-flex"
            textTransform="none"
            id="specsBasketsGridOpenLink"
            onClick={() => {
              onBasketClick(basket)
            }}
          >
            {basket.basketName}
          </JATOLink>
        </JATOTooltip>,
        args.cell
      )
    } else if (args.column.field == 'isShared') {
      const basket = args.data as BasketInfo
      render(
        <JATOButtonIcon
          id="specsBasketsGridShareButton"
          title={basket.isShared ? 'Un-share' : 'Share'}
          iconName={basket.isShared ? 'baseline_link_off' : 'baseline_link'}
          disabled={userData.userId !== basket.userId}
          onClick={() => {
            updateShareBasket(basket, !basket.isShared)
          }}
        />,
        args.cell
      )
    } else if (args.column.headerText == translations.JNT_rename) {
      const basket = args.data as BasketInfo
      render(
        <JATOButtonIcon
          id="specsBasketsGridRenameButton"
          title={translations.JNT_rename}
          iconName="custom_edit"
          disabled={userData.userId !== basket.userId}
          onClick={() => {
            setSelectedBasket(basket)
            setShowEditModal(true)
          }}
        />,
        args.cell
      )
    } else if (args.column.headerText == translations.JNT_delete) {
      const basket = args.data as BasketInfo
      render(
        <JATOButtonIcon
          id="specsBasketsGridDeleteButton"
          title={translations.JNT_delete}
          iconName="baseline_delete_outline"
          disabled={userData.userId !== basket.userId}
          onClick={() => removeBasketData(basket)}
        />,
        args.cell
      )
    }
  }

  const updateShareBasket = (basket: BasketInfo, isShare: boolean): void => {
    const newBasket = { ...basket }
    newBasket.isShared = isShare
    const basketUpdate: UpdateBasketRequest = {
      basketData: newBasket,
      updateSharekey: true,
    }
    updateBasket(basketUpdate).then(() => {
      createToast({
        children: <div>{translations.JNT_UpdatedSuccess}</div>,
      })
    })
  }

  const onBasketClick = async (basket: BasketInfo): Promise<void> => {
    basket.lastAccessDate = new Date()
    const basketUpdate: UpdateBasketRequest = {
      basketData: basket,
      updateLastAccessKey: true,
    }

    await resetBuiltVehiclesAndFilters()

    updateBasket(basketUpdate).then((response: SaveBasketResponse) => {
      if (response.responseCode === '110') {
        createToast({ children: response.responseMessage, variant: 'error' })
      } else {
        logOnBasketClick(basket)
        history.push(`${RoutePaths.SpecsBaskets}/${basket.id}`)
      }
    })
  }

  const removeBasketData = (basket: BasketInfo): void => {
    if (window.confirm('Are you sure you want to delete basket?')) {
      removeBasket(basket).then(() => {
        createToast({
          children: <div>{translations.JNT_DeletedSuccess}</div>,
        })
      })
    }
  }

  const getBasketsForTab = (): any => {
    if (props.isUserBaskets) {
      return baskets?.filter(
        (basket: BasketInfo) => basket.userId === userData.userId
      )
    } else {
      return baskets?.filter(
        (basket: BasketInfo) =>
          basket.userId !== userData.userId && basket.isShared
      )
    }
  }

  const setDateFormat = {
    type: 'date',
    format: 'dd-MM-yyyy',
  }

  return isLoading ? (
    <Loader style={{ minHeight: '100%' }} />
  ) : (
    <StyledBaskets>
      <GridComponent
        id="specsBasketsGrid"
        dataSource={getBasketsForTab()}
        allowSorting={true}
        sortSettings={sortingOptions}
        queryCellInfo={queryCellInfo}
        loadingIndicator={{ indicatorType: 'Shimmer' }}
      >
        <ColumnsDirective>
          <ColumnDirective field="id" visible={false} isPrimaryKey={true} />
          <ColumnDirective
            field="markets"
            width="30"
            headerText=""
            allowEditing={false}
            allowSorting={false}
          />
          <ColumnDirective
            field="basketName"
            width="200"
            headerText={translations.JNT_name}
            allowSorting={false}
          />
          <ColumnDirective
            field="createdDate"
            width="110"
            headerText={translations.JNT_qrycreate}
            allowEditing={false}
            type="date"
            allowSorting={false}
            format={setDateFormat}
          />
          <ColumnDirective
            field="lastAccessDate"
            width="110"
            headerText={translations.JNT_lastran}
            allowEditing={false}
            allowSorting={false}
            type="date"
            format={setDateFormat}
          />
          <ColumnDirective
            headerText={translations.JNT_rename}
            width="80"
            allowEditing={false}
            allowSorting={false}
          />
          <ColumnDirective
            field="isShared"
            headerText={translations.JNT_share}
            width="80"
            allowEditing={false}
            allowSorting={false}
          />
          <ColumnDirective
            headerText={translations.JNT_delete}
            width="80"
            allowEditing={false}
            allowSorting={false}
          />
        </ColumnsDirective>
        <Inject services={[Sort]} />
      </GridComponent>
      {showEditModal && (
        <SaveBasketModal
          basketId={selectedBasket?.id}
          isRename={true}
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
        />
      )}
      <LoaderModal isOpen={isRemoving || isUpdating} />
    </StyledBaskets>
  )
}
