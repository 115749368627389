import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledVolumesQueryDescription = styled.div`
  .accordion-container {
    border: 1px solid ${JATOTheme.colors.grey};
    font-size: 14px;
  }

  .accordion-content {
    word-wrap: break-word;
    line-height: 1.6;
  }

  .itemTitle {
    font-weight: 500;
    padding: 6px 16px;
    background-color: ${JATOTheme.colors.midGrey};
  }

  .itemContent {
    overflow-wrap: 'break-word';
    padding: 6px 16px;
  }
`
