import { JATOText, JATOTheme } from '@jato/ui-component-library'
import 'rc-slider/assets/index.css'
import React, { useEffect, useState } from 'react'
import { StyledSliderFilter } from './SliderFilter.styles'
import TooltipSlider from './TooltipSlider'

export interface ISliderFilter {
  label?: string
  filterName?: string
  value?: SliderValue
  onChange: (value: number[]) => void
  tooltipFormatter?: (value: SliderValue) => string
}

export interface SliderValue {
  min: number
  max: number
}

export const SliderFilter: React.FC<ISliderFilter> = ({
  label,
  filterName,
  value,
  onChange,
  tooltipFormatter,
}: ISliderFilter) => {
  const [internalValue, setInternalValue] = useState<SliderValue>(
    value ? value : { min: 0, max: 0 }
  )

  useEffect(() => {
    setInternalValue(value ? value : { min: 0, max: 0 })
  }, [value])

  const formattedTooltip = tooltipFormatter
    ? tooltipFormatter(internalValue)
    : `${internalValue.min} - ${internalValue.max}`

  return value ? (
    <StyledSliderFilter>
      <JATOText as="div" fontSize={JATOTheme.fontSizes[1]} color="inherit">
        {label ?? filterName}
      </JATOText>
      <div className="sliderWrapper">
        <TooltipSlider
          range
          value={[internalValue.min, internalValue.max]}
          min={value.min}
          max={value.max}
          onChange={(args: any) => {
            setInternalValue({ min: args[0], max: args[1] })
          }}
          onAfterChange={(args: any) => {
            onChange(args)
          }}
          tipFormatter={() => formattedTooltip}
        />
      </div>
    </StyledSliderFilter>
  ) : (
    <></>
  )
}
