import { JATOTheme } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledNewsSaveSearchButton = styled.div`
  .searchResultsButton {
    text-transform: none;
    font-weight: ${JATOTheme.fontWeights.medium};
    background-color: ${JATOTheme.colors.midGrey};
    border-radius: 6px;
    column-gap: 8px;
  }
`
