import { KeyText } from 'models/Specifications/KeyText'
import { UserSettings, defaultUserSettings } from './UserSettings'

export interface UserState {
  userId: number
  guid: string
  firstName: string
  lastName: string
  password: string
  usrgrpid: number | null
  alreadyLoggedIn: boolean
  isauthenticated: boolean
  role: boolean
  languageId: number
  currency: string
  textTag: string
  textTags: string[]
  issuperuser: boolean
  issalesperson: boolean
  captchaError: string
  isLicencedNews: boolean
  isJatoUser: boolean
  licenceStatus: string
  isadmin: boolean
  acceptLicence: number
  responseMessage: string
  countrycode: string
  cOM_MainSalesName: string
  cOM_MainSalesEmail: string
  cOM_MainSalesTele: string
  releaseDateStart: string
  releaseDateEnd: string
  isVolumesLicenecM: boolean
  isVolumesLicenecV: boolean
  isVolumesLicenecX: boolean
  isVolumesLicenecF: boolean
  isSpecsLicence: boolean
  isMexicoIncentives: boolean
  isMexicoInvSubscription: boolean
  mexicoInvSubsId: string
  dataSetIds: string[]
  dataPointName: string
  segmentIds: string[]
  rowFilter: string
  vehicleTypeList: string[]
  dataPointAllowId: number
  registationTypes: string[]
  isExcelExportCall: boolean
  newsProductList: string[]
  userSpecificSettings: UserSettings
  specsDbMarkets: KeyText[]
  token: string
  newsFirstUsedDate?: string
  isMonthlyPayments: boolean
  email?: string
}

export const defaultUserState: UserState = {
  userId: 0,
  guid: '',
  firstName: '',
  lastName: '',
  password: '',
  usrgrpid: null,
  alreadyLoggedIn: false,
  isauthenticated: false,
  role: false,
  languageId: 0,
  currency: '',
  textTag: '',
  textTags: [],
  issuperuser: false,
  issalesperson: false,
  captchaError: '',
  isLicencedNews: false,
  isJatoUser: false,
  licenceStatus: '',
  isadmin: false,
  acceptLicence: 0,
  responseMessage: '',
  countrycode: '',
  cOM_MainSalesName: '',
  cOM_MainSalesEmail: '',
  cOM_MainSalesTele: '',
  releaseDateStart: '',
  releaseDateEnd: '',
  isVolumesLicenecM: false,
  isVolumesLicenecV: false,
  isVolumesLicenecX: false,
  isVolumesLicenecF: false,
  isSpecsLicence: false,
  isMexicoIncentives: false,
  isMexicoInvSubscription: false,
  mexicoInvSubsId: '',
  dataSetIds: [],
  dataPointName: '',
  segmentIds: [],
  rowFilter: '',
  vehicleTypeList: [],
  dataPointAllowId: 0,
  registationTypes: [],
  isExcelExportCall: false,
  newsProductList: [],
  userSpecificSettings: defaultUserSettings,
  specsDbMarkets: [],
  token: '',
  isMonthlyPayments: false,
}
