import styled from 'styled-components'

export const StyledPhotoGallery = styled.div`
  .template-carousel-section .carousel-sample {
    margin: 0 auto 2em;
    max-width: 500px;
    height: 500px;
  }

  .templateCarousel .e-carousel-items,
  .templateCarousel .e-carousel-navigators {
    height: calc(100% - 12rem);
  }

  .templateCarousel .e-carousel-navigators .e-previous,
  .templateCarousel .e-carousel-navigators .e-next,
  .templateCarousel .e-carousel-navigators .nav-btn {
    padding: 0;
  }

  .templateCarousel .e-carousel-navigators .nav-btn:active,
  .templateCarousel .e-carousel-navigators .nav-btn:focus,
  .templateCarousel .e-carousel-navigators .nav-btn:hover {
    background-color: transparent !important;
    color: inherit;
  }

  .templateCarousel .e-carousel-navigators svg {
    fill: none;
    stroke: currentColor;
    stroke-linecap: square;
    stroke-width: 8px;
    height: 2rem;
    vertical-align: middle;
    width: 2rem;
  }

  .templateCarousel .e-carousel-navigators .e-previous svg {
    transform: rotate(180deg);
  }

  .templateCarousel
    .e-carousel-indicators
    .e-indicator-bars
    .e-indicator-bar
    .indicator {
    background-color: #ececec;
    border-radius: 0.25rem;
    cursor: pointer;
    height: 3.5rem;
    margin: 0.5rem;
    width: 5rem;
  }

  .templateCarousel
    .e-carousel-indicators
    .e-indicator-bars
    .e-indicator-bar.e-active
    .indicator {
    background-color: #3c78ef;
  }

  .e-carousel .e-carousel-indicators {
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 48px;
    padding: 0;
    pointer-events: none;
    width: 100%;
    z-index: 1;
  }

  @media screen and (max-width: 480px) {
    .template-carousel-section .carousel-sample {
      width: 100%;
      height: 250px;
    }

    .templateCarousel .img-container {
      margin: 12px;
    }
  }
`
