import {
  JATONavBar,
  JATONavGroup,
  JATONavItem,
  JATOVerticalSpacer,
} from '@jato/ui-component-library'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { useSavedSearch } from 'hooks/news'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { v4 as uuid } from 'uuid'
import NewsBreadcrumbs from '../NewsBreadcrumbs'
import { NewsNoItemFound } from '../NewsNoItemFound/NewsNoItemFound'
import {
  NewsSavedSearchAndAlertColumnType,
  NewsSavedSearchAndAlertGrid,
} from './NewsSavedSearchAndAlertGrid'
import { StyledNewsSavedSearchViewAll } from './NewsSavedSearchViewAll.styles'

export const NewsSavedSearchViewAll: React.FC = () => {
  const translations = useAppSelector(getTranslations)
  const shareTabList: string[] = [
    translations.JNT_SRCH_SavebyMe,
    translations.JNT_SRCH_SavebyGroup,
  ]

  const [selectedMainTab, setMainTab] = useState<string>(shareTabList[0])

  const isSharedByOrganization =
    selectedMainTab === translations.JNT_SRCH_SavebyGroup

  const { data: savedSearches, isFetching } = useSavedSearch(
    isSharedByOrganization
  )

  const defaultColumns = [
    NewsSavedSearchAndAlertColumnType.Name,
    NewsSavedSearchAndAlertColumnType.Category,
    NewsSavedSearchAndAlertColumnType.Country,
    NewsSavedSearchAndAlertColumnType.LastRunDate,
    NewsSavedSearchAndAlertColumnType.Make,
    NewsSavedSearchAndAlertColumnType.Model,
    NewsSavedSearchAndAlertColumnType.VehicleType,
    NewsSavedSearchAndAlertColumnType.Language,
  ]

  const gridColumns = isSharedByOrganization
    ? defaultColumns
    : [...defaultColumns, NewsSavedSearchAndAlertColumnType.SearchActions]

  return (
    <StyledNewsSavedSearchViewAll>
      <Container fluid>
        <Row>
          <Col md={12}>
            <NewsBreadcrumbs title={translations.JNT_SavedSearches} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <JATOVerticalSpacer height={5} />
            <JATONavBar
              left={() => (
                <JATONavGroup flexDirection="row" space={16}>
                  {shareTabList.map((tab: string, index: number) => (
                    <JATONavItem
                      key={uuid()}
                      size={'medium'}
                      isActive={tab === selectedMainTab}
                    >
                      <div
                        id={'newsSavedSearchesTab_' + index}
                        onClick={() => setMainTab(tab)}
                      >
                        {tab}
                      </div>
                    </JATONavItem>
                  ))}
                </JATONavGroup>
              )}
            />
            <JATOVerticalSpacer height={10} />
          </Col>
        </Row>
      </Container>
      {savedSearches && savedSearches.length > 0 && (
        <>
          <NewsSavedSearchAndAlertGrid
            savedSearches={savedSearches}
            columns={gridColumns}
          />
        </>
      )}

      {savedSearches && savedSearches.length == 0 && (
        <NewsNoItemFound
          iconName="custom_search"
          text={translations.JNT_NoSavedSearch}
        />
      )}
      <LoaderModal isOpen={isFetching} />
    </StyledNewsSavedSearchViewAll>
  )
}
