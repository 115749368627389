import { AxiosResponse } from 'axios'
import { TranslationsRequest } from 'models/Translations/TranslationsRequest'
import { TranslationsResponse } from 'models/Translations/TranslationsResponse'
import { specificationsApi } from 'services/api'

class TraslationService {
  public async getTranslations(
    translationsRequest: TranslationsRequest
  ): Promise<TranslationsResponse> {
    const response = await specificationsApi.post<
      TranslationsRequest,
      AxiosResponse<TranslationsResponse>
    >(`/translationManager`, translationsRequest)

    return response.data
  }
}

const translationService = new TraslationService()
export default translationService
