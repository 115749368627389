import { getCountryByCode } from 'helper/newsHelper'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getNewsCountries } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'

interface INewsMetadataCountryProps {
  countryCode: string
}

export const NewsMetadataCountry: React.FC<INewsMetadataCountryProps> = ({
  countryCode,
}: INewsMetadataCountryProps) => {
  const translations = useAppSelector(getTranslations)
  const countries = useAppSelector(getNewsCountries)

  const { countryAbbreviation, name: countryName } = getCountryByCode(
    countryCode,
    countries,
    translations
  )
  const flagIconName = countryAbbreviation || 'xx'
  const flagIconSrc = `${process.env.PUBLIC_URL}/images/circle-flags/${flagIconName}.svg`

  return (
    <>
      <img src={flagIconSrc} />
      <span>
        {translations.JNT_ART_Country} {countryName}
      </span>
    </>
  )
}
