export enum EventType {
  EmailDomainChange = 'EmailDomainChange',
  LanguageIdChange = 'LanguageIdChange',
  MarketChange = 'MarketChange',
  SelectedValueChange = 'SelectedValueChange',
  NewsSimpleSearch = 'NewsSimpleSearch',
  NewsAdvancedSearch = 'NewsAdvancedSearch',
  NewsFiltersChange = 'NewsFiltersChange',
  NewsSearchAlertCreate = 'NewsSearchAlertCreate',
  NewsSearchAlertEdit = 'NewsSearchAlertEdit',
  NewsArticleView = 'NewsArticleView',
  VolumesReportView = 'VolumesReportView',
  MonthlyPaymentsFiltersChange = 'MonthlyPaymentsFiltersChange',
  MonthlyPaymentsReportView = 'MonthlyPaymentsReportView',
  SpecsReportView = 'SpecsReportView',
  SpecsFiltersChange = 'SpecsFiltersChange',
  SpecsAdvancedFiltersChange = 'SpecsAdvancedFiltersChange',
  SpecsOptionBuild = 'SpecsOptionBuild',
  SpecsOptionUnbuild = 'SpecsOptionUnbuild',
}
