import { CurrencyUnits } from 'models/Filters/FilterUnits'

export const currencyCodeMapper = (countryCode: string): any => {
  switch (countryCode ?? 'GB') {
    case 'GB':
      return CurrencyUnits.GreatBritishPounds
    default:
      return CurrencyUnits.Euros
  }
}
