import { NewsPageViewType } from 'models/News/NewsPageViewType'

export interface UserSettings {
  id: string
  userId: number
  countryCode: string
  languageId: number
  lastShareNotifiedId: string
  shareNotificationCount: number
  lastUpdated: string
  pageViewType: NewsPageViewType
  pageSize: number
  isDisableBreakingAlerts: boolean
  hideWelcomeInfo: boolean
  hideMonthlyPaymentsPopup: boolean
  hideMonthlyPaymentsLicensePopup: boolean
  hasMonthlyPaymentsTrial: boolean
  monthlyPaymentsTrialStartDate: string
  monthlyPaymentsTrialEndDate: string
  hideMonthlyPaymentsTrialWelcomePopup: boolean
  hideMonthlyPaymentsTrialEndPopup: boolean
}

export const defaultUserSettings: UserSettings = {
  id: '',
  userId: 0,
  countryCode: '',
  languageId: 0,
  lastShareNotifiedId: '',
  shareNotificationCount: 0,
  lastUpdated: '',
  pageViewType: NewsPageViewType.Cards,
  pageSize: 0,
  isDisableBreakingAlerts: false,
  hideWelcomeInfo: false,
  hideMonthlyPaymentsPopup: false,
  hideMonthlyPaymentsLicensePopup: false,
  hasMonthlyPaymentsTrial: false,
  monthlyPaymentsTrialStartDate: '',
  monthlyPaymentsTrialEndDate: '',
  hideMonthlyPaymentsTrialWelcomePopup: false,
  hideMonthlyPaymentsTrialEndPopup: false,
}
