import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import { RootState } from 'redux/store'

interface SelectedVehiclesState {
  selectedMpVehiclesList: MpVehicle[]
}

const initialState: SelectedVehiclesState = {
  selectedMpVehiclesList: [],
}

export const mpVehiclesSlice = createSlice({
  name: 'mpVehicles',
  initialState,
  reducers: {
    addMpVehicle: (state, { payload }: PayloadAction<MpVehicle>) => {
      state.selectedMpVehiclesList.push(payload)
    },
    removeMpVehicle: (state, { payload }: PayloadAction<MpVehicle>) => ({
      ...state,
      selectedMpVehiclesList: state.selectedMpVehiclesList.filter(
        (v: MpVehicle) => v.vehicleId !== payload.vehicleId
      ),
    }),
    resetMpVehicles: (state, {}: PayloadAction<boolean>) => ({
      ...state, //copying the orignal state
      selectedMpVehiclesList: [], //reassingning todos to new array
    }),
    setMpVehicles: (state, { payload }: PayloadAction<MpVehicle[]>) => ({
      ...state,
      selectedMpVehiclesList: payload,
    }),
  },
})

export const { addMpVehicle, removeMpVehicle, resetMpVehicles, setMpVehicles } =
  mpVehiclesSlice.actions

export const mpVehicles = (state: RootState): MpVehicle[] =>
  state.monthlypayments.mpVehicles.selectedMpVehiclesList

export default mpVehiclesSlice.reducer
