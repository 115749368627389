import {
  JATOBasicCard,
  JATOButton,
  JATOButtonGroup,
  JATOGroup,
  JATOHeading,
  JATOTheme
} from '@jato/ui-component-library'
import SaveIcon from 'assets/icons/icon_save.svg'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import Loader from 'components/Loader'
import VolumesDatabaseTypeSelection from 'components/Volumes/VolumesDatabaseTypeSelection'
import { useGetCountries } from 'hooks/volumes'
import { CountryGroup } from 'models/Volumes/CountryGroup'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledVolumesCountryGroupCreateEdit } from './VolumesCountryGroupCreateEdit.styles'
import { VolumesSaveCountryGroupModal } from './VolumesSaveCountryGroupModal'

export interface IVolumesCountryGroupCreateEditProps {
  databaseType: VolumesDatabaseType
  setDatabaseType: (value: VolumesDatabaseType) => void
  countryGroup: CountryGroup
  onClose: () => void
}

export const VolumesCountryGroupCreateEdit: React.FC<
  IVolumesCountryGroupCreateEditProps
> = ({
  databaseType,
  setDatabaseType,
  countryGroup,
  onClose,
}: IVolumesCountryGroupCreateEditProps) => {
  const translations = useAppSelector(getTranslations)

  const { data: countries, isFetching } = useGetCountries(databaseType)

  const [model, setModel] = useState(countryGroup)
  const [showSaveModal, setShowSaveModal] = useState(false)

  const onSelectionOptionChange = (value: VolumesDatabaseType): void => {
    setDatabaseType(value)
    setModel({ ...model, productGroup: value, countries: [] })
  }

  const onSelectedCountriesChange = (countryCode: string): void => {
    setModel({
      ...model,
      countries: model.countries.includes(countryCode)
        ? model.countries.filter((c) => c != countryCode)
        : [...model.countries, countryCode],
    })
  }

  const onSelectAllClick = (): void => {
    setModel({
      ...model,
      countries: countries?.map((c) => c.countryCode) ?? [],
    })
  }

  const onClearChoicesClick = (): void => {
    setModel({ ...model, countries: [] })
  }

  const onSaveClick = (): void => {
    setShowSaveModal(true)
  }

  const onCloseSaveModal = (isSuccess: boolean): void => {
    setShowSaveModal(false)
    isSuccess && onClose()
  }

  return (
    <StyledVolumesCountryGroupCreateEdit>
      {isFetching ? (
        <Loader style={{ minHeight: 'calc(100vh - 300px)' }} />
      ) : (
        <JATOBasicCard
          footer={() => (
            <JATOButtonGroup justifyContent="flex-end">
              <JATOButton
                id="volumesCountryGroupCreateEditCancelButton"
                variant="secondary"
                onClick={onClose}
              >
                {translations.JNT_COM_CANCEL}
              </JATOButton>
              <JATOButton
                id="volumesCountryGroupCreateEditSelectAllButton"
                variant="secondary"
                onClick={onSelectAllClick}
              >
                {translations.JNT_Volumes_QG_SetQuerySelections}
              </JATOButton>
              <JATOButton
                id="volumesCountryGroupCreateEditClearChoicesButton"
                variant="secondary"
                onClick={onClearChoicesClick}
              >
                {translations.JNT_Volumes_QG_ClearSelections}
              </JATOButton>
              <JATOButton
                id="volumesCountryGroupCreateEditSaveButton"
                variant="primary"
                disabled={model.countries.length == 0}
                onClick={onSaveClick}
              >
                {translations.JNT_save}
                <img
                  src={SaveIcon}
                  style={{ marginLeft: JATOTheme.space[2] }}
                />
              </JATOButton>
            </JATOButtonGroup>
          )}
        >
          <JATOHeading as="h3" fontWeight="medium" marginTop="-24px">
            {translations.JNT_Volumes_QM_CountrySelectMessage}
          </JATOHeading>
          <JATOGroup flexDirection="row" className="selectionRadioGroup">
            <VolumesDatabaseTypeSelection
              value={databaseType}
              onChange={onSelectionOptionChange}
            />
          </JATOGroup>
          {countries && (
            <JATOGroup flexDirection="column" className="countriesContainer">
              {countries.map((c) => (
                <CheckboxWithGtm
                  key={c.countryCode}
                  id="volumesCountryGroupCreateEditCheckbox"
                  value={c.countryCode}
                  label={c.countryName}
                  checked={model.countries.includes(c.countryCode)}
                  onChange={() => onSelectedCountriesChange(c.countryCode)}
                >
                  {c.countryName}
                </CheckboxWithGtm>
              ))}
            </JATOGroup>
          )}
        </JATOBasicCard>
      )}
      {showSaveModal && (
        <VolumesSaveCountryGroupModal
          isOpen={showSaveModal}
          onClose={onCloseSaveModal}
          countryGroup={model}
        />
      )}
    </StyledVolumesCountryGroupCreateEdit>
  )
}
