import React from 'react'

interface INewsArticleChartProps {
  imageData: string
}

export const NewsArticleChart: React.FC<INewsArticleChartProps> = ({
  imageData,
}: INewsArticleChartProps) => {
  const imageBinary = `data:image/gif;base64,${imageData}`
  return <img src={imageBinary} />
}
