export enum FilterType {
  SearchBoxInput = 'SearchBoxInput',
  DropdownWithSearch = 'DropdownWithSearch',
  Slider = 'Slider',
  MultiSelect = 'MultiSelect',
  MilesKilometersFilter = 'MilesKilometersFilter',
  Market = 'Market',
  Insurance = 'Insurance',
}

export enum FilterParentType {
  Specifications = 'Specifications',
  SpecAdvancedFilters = 'SpecAdvancedFilters',
}

export enum FilterValueType {
  String = 'String',
  Number = 'Number',
  ValueWithUnit = 'ValueWithUnit',
  Range = 'Range',
}
