import App from 'app'
import { Provider } from 'react-redux'
// import reportWebVitals from './reportWebVitals'
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'redux/store'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const tagManagerArgs = {
  gtmId: window.config.gtmId,
  auth: window.config.gtmAuth,
  preview: window.config.gtmPreview,
}

TagManager.initialize(tagManagerArgs)

if (process.env.NODE_ENV !== 'production') {
  import('hide-cra-error-overlay').then(({ initHideOverlay }) =>
    initHideOverlay()
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}
          useRecaptchaNet={true}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
