import { JATOSpinner } from '@jato/ui-component-library'
import React from 'react'
import styled from 'styled-components'

const LogoLoader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`

interface LoaderProps {
  style?: React.CSSProperties
  className?: string
}

export const Loader: React.FC<LoaderProps> = ({
  style,
  className,
}: LoaderProps) => (
  <LogoLoader style={style} className={className}>
    <JATOSpinner spinnerSize={64} />
  </LogoLoader>
)
