export const firstOrDefault = <T>(arr: T[], defaultValue: T): T =>
  arr && arr.length > 0 ? arr[0] : defaultValue

export const removeDuplicates = <T>(val: T, index: number, arr: T[]): boolean =>
  arr.indexOf(val) === index

export const arraysEqual = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false

  const sorted1 = [...arr1].sort()
  const sorted2 = [...arr2].sort()

  return sorted1.every((value, index) => value === sorted2[index])
}

export const isNullOrEmpty = <T>(arr?: T[]): boolean => !arr || arr.length === 0
