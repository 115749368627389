import { VolumesUserState } from 'models/Login/VolumesLogin/VolumesUserState'
import { Countries } from 'models/Volumes/Countries'
import { DpPoint } from 'models/Volumes/DpPoint'
import { JATONetTreeview } from 'models/Volumes/JATONetTreeview'
import { OutputPoint } from 'models/Volumes/OutputPoint'
import { OutputReportResponse } from 'models/Volumes/OutputReportResponse'
import { UserValidData } from 'models/Volumes/UserValidData'
import { VolumesQueryState } from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import { isNullOrEmpty, removeDuplicates } from './arrayHelper'
import { Region } from 'models/Volumes/Region'
import { PeriodDef } from 'models/Volumes/PeriodDef'

export enum VolumesSteps {
  StartReset = 0,
  Country = 1,
  QueryDefinition = 2,
  PeriodsSelections = 3,
  Report = 4,
}

export enum VolumesQueryColumnType {
  QueryId,
  SearchName,
  DateCreated,
  DateModified,
  LastRan,
  Share,
  Delete,
  Rename,
}

export enum VolumesDataPointGroup {
  Country = 'COUNTRY',
  VehicleType = 'VEHICLETYPE',
  Pricing = 'VOL_Pricing',
  VolumeBanding = 'VOLBAND',
}

export enum PeriodSelectionTypes {
  ActualData = 'B',
  CombinedData = 'C',
  EstimatedData = 'E',
  ForecastData = 'F',
  NegativeData = 'N',
}

export const volumesColourMapping = [
  { colour: 'black', type: PeriodSelectionTypes.ActualData },
  { colour: 'blue', type: PeriodSelectionTypes.ForecastData },
  { colour: '#00cc66', type: PeriodSelectionTypes.EstimatedData },
  { colour: '#c40005', type: PeriodSelectionTypes.CombinedData },
  { colour: 'red', type: PeriodSelectionTypes.NegativeData },
]

export const defaultExpandedNodes = [
  'VEH_HIERARCHY',
  'VEHICLE_FTRGRP',
  'Version',
]
export const hideCheckboxNodes = [
  'SEGMENT_GROUP',
  'VOLBAND',
  'VEHICLE_FTRGRP',
  'REGION_GROUP',
]

export const AllValues = '(All)'
export const DpPartBetweenValue = 5

export const defaultNumberOfQueries = 6
export const defaultNumberOfReportRows = 30

export const MaxPeriodsSectionsAllowed = 100

export const MaxReportRows = 20000

const paths = {
  [VolumesSteps.StartReset]: RoutePaths.VolumesStartReset,
  [VolumesSteps.Country]: RoutePaths.VolumesCountry,
  [VolumesSteps.QueryDefinition]: RoutePaths.VolumesQueryDefinition,
  [VolumesSteps.PeriodsSelections]: RoutePaths.VolumesPeriodSelections,
  [VolumesSteps.Report]: RoutePaths.VolumesReport,
}

export const getPathForStep = (step: VolumesSteps): string =>
  paths[step] ?? RoutePaths.VolumesStartReset

export const getActiveStep = (queryState: VolumesQueryState): number => {
  if (!queryState.databaseType) return VolumesSteps.StartReset
  if (isNullOrEmpty(queryState.countries)) return VolumesSteps.Country
  if (
    isNullOrEmpty(queryState.vehicleAttributes) &&
    isNullOrEmpty(queryState.outputPoints)
  )
    return VolumesSteps.QueryDefinition
  if (isNullOrEmpty(queryState.periods)) return VolumesSteps.PeriodsSelections
  return VolumesSteps.Report
}

export const getCountriesDisplayList = (
  countryCodes: string[],
  countries: Countries[]
): string =>
  countries
    .filter((c) => countryCodes.includes(c.countryCode))
    .map((c) => c.countryName)
    .join(', ')

export const getCountryName = (
  countryCode: string,
  countries?: Countries[]
): string => {
  const country = countries?.find((c) => c.countryCode === countryCode)
  return country?.countryName ?? ''
}

export const getDatasetFields = (
  dataSetIds: number[],
  datasets?: UserValidData[]
): {
  dataSetIds: number[]
  registrationTypeList?: string[]
  vehicleTypeList?: string[]
  dataSetPersist?: string[]
} => {
  const selectedDatasets = datasets?.filter((d) => dataSetIds.includes(d.dstId))

  const registrationTypeList = selectedDatasets
    ?.flatMap((d) => d.dstRegTypes.split(','))
    .filter(removeDuplicates)
  const vehicleTypeList = selectedDatasets
    ?.map((d) => d.dsVehicleType)
    .filter(removeDuplicates)
  const dataSetPersist = selectedDatasets
    ?.map((d) => `${d.pCtyId},${d.dbJoin}`)
    .filter(removeDuplicates)

  return {
    dataSetIds,
    registrationTypeList,
    vehicleTypeList,
    dataSetPersist,
  }
}

export const getOutputPoint = (item: JATONetTreeview): OutputPoint => ({
  id: item.dataPointName,
  iddesc: item.longText,
  optype: item.dataPointGroup,
})

export const getOutputPoints = (
  queryState: VolumesQueryState
): OutputPoint[] => {
  const outputPoints = queryState.outputPoints ?? []
  const dataPoints = queryState.vehicleAttributes ?? []

  const converted = dataPoints
    .filter((dp) => !outputPoints.some((o) => o.id === dp.id))
    .map(({ id, iddesc, optype }) => ({ id, iddesc, optype }))

  const defaultOutPutPoints = [
    VolumesDataPointGroup.Country.toString(),
    VolumesDataPointGroup.VehicleType.toString(),
  ]

  const removedOutPutPoints = outputPoints.filter(
    (o) =>
      !dataPoints.some((dp) => o.id === dp.id) &&
      !defaultOutPutPoints.includes(o.id)
  )

  const finalOutPutPoints =
    removedOutPutPoints.length > 0
      ? outputPoints.filter(
          (op: OutputPoint) => !removedOutPutPoints.includes(op)
        )
      : outputPoints

  return [...finalOutPutPoints, ...converted]
}

export const getRegionPoints = (queryState: VolumesQueryState): Region[] => {
  const dataPoints = queryState.vehicleAttributes ?? []

  const regionPoints: Region[] = dataPoints
    .filter((p: DpPoint) => p.id.startsWith('Region_L'))
    .map((dp: DpPoint) => ({
      regionLevel: +dp.id.replace('Region_L', ''),
      regionChecked: false,
      regionSelectedData: dp.valuedesc === AllValues ? [] : dp.value.split(','),
    }))

  return regionPoints ?? []
}

export const isUserSegment = (dataPointName?: string): boolean =>
  dataPointName?.startsWith('USERSEGID') ?? false

export const getSegmentationFields = (
  selectedValues: string[],
  item?: JATONetTreeview
): {
  segment_GroupID?: string
  segment_segSource?: string
  segment_SegmentList?: string[]
} => {
  if (!isUserSegment(item?.dataPointName)) return {}

  return {
    segment_GroupID: selectedValues.length > 0 ? item?.dataUnit : undefined,
    segment_segSource:
      selectedValues.length > 0 ? item?.dataMetaType : undefined,
    segment_SegmentList: selectedValues,
  }
}

export const getAllValuesDataPoint = (
  selectedItem?: JATONetTreeview
): DpPoint => ({
  id: selectedItem?.dataPointName ?? '',
  iddesc: selectedItem?.longText ?? '',
  value: '',
  valuedesc: AllValues,
  part: 1,
  state: false,
  minValue: 0,
  maxValue: 0,
  optype: selectedItem?.dataPointGroup ?? '',
})

export const getDataPointRequest = (
  queryState: VolumesQueryState,
  userData: VolumesUserState
) => ({
  segment_SegmentList: queryState?.segment_SegmentList || [],
  segment_GroupID: Number(queryState?.segment_GroupID) || 0,
  bandingCurrency: queryState?.bandingCurrency || userData.currency,
  pricepointlist:
    queryState.vehicleAttributes?.filter(
      (dp) =>
        dp.optype === VolumesDataPointGroup.Pricing &&
        dp.valuedesc !== AllValues
    ) ?? [],
  vehicleTypeList: queryState?.vehicleTypeList || [],
  outPutLangaugeId: userData.languageId,
  dataSetPersist: queryState?.dataSetPersist || [],
  outputPoints: getOutputPoints(queryState),
  regionPoints: getRegionPoints(queryState),
  analysisOptions: queryState?.analysisOptions || [],
  outputOptions: queryState?.outputOptions || '',
  productGroup: queryState.databaseType?.toString() || '',
  dataSetIds: queryState.dataSetIds || [],
  countryList: queryState.countries || [],
  dataPoints:
    queryState.vehicleAttributes?.filter(
      (dp) => dp.optype !== VolumesDataPointGroup.Pricing
    ) || [],
  periodPoints: queryState.periods || [],
})

export const formatPercentageReportData = (num: any) => {
  // Check if it's a number and not null/undefined
  if (/^-?\d+\.\d{3,}$/.test(num.toString())) {
    // Round to two decimal places and add '%'
    return `${num.toFixed(2)}%`
  } else {
    // If it has two or fewer decimal places, just append '%' without changing the value
    return `${num}%`
  }
}

export const formatNumericDataWithCommas = (num: any): string => {
  const roundedNum = Math.round(num * 100) / 100

  const numStr = roundedNum.toString()

  const [integerPart, decimalPart] = numStr.split('.')

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart
}

export const getFormattedValue = (
  field: string,
  value: any,
  data: OutputReportResponse | undefined
): any => {
  let formattedValue = null

  if (typeof value === 'number') {
    // Column names (specifically the 'Caption') that include the 'share' or 'percent' keyword are formatted as percentages. Using the 'caption' property for identification as it's consistent across translations.
    if (
      data &&
      data.columnDefs.filter(
        (columnDef) =>
          (columnDef.caption.toLowerCase().includes('share') ||
            columnDef.caption.toLowerCase().includes('percent')) &&
          columnDef.field === field
      )?.length > 0
    ) {
      formattedValue = formatPercentageReportData(value)
    } else {
      // Format any other larger numeric data with commas
      formattedValue = formatNumericDataWithCommas(value)
    }
  }

  return formattedValue
}

export const getColourCodeDictionary = (
  rowData: string
): Record<string, string> => {
  const colorDictionary: Record<string, string> = {}

  const rowItems = rowData.split(';').filter((row) => row.trim() !== '')

  for (const item of rowItems) {
    const [colorCode, color] = item.split('-').map((entry) => entry.trim())
    colorDictionary[color] = colorCode
  }

  return colorDictionary
}

export const getUpdatedPeriodPoints = (periodDef: PeriodDef[]): PeriodDef[] => {
  let index = periodDef.length - 1
  return periodDef.map((period: PeriodDef) => ({
    ...period,
    periodSequence: index--,
  }))
}
