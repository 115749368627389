import {
  JATONavBar,
  JATONavGroup,
  JATONavMenuDropdown,
} from '@jato/ui-component-library'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import MyPortalSettingsModal from '../MyPortalSettingsModal'
import { StyledMyPageNavigation } from './MyPageNavigation.styles'

export const MyPageNavigation: React.FC = () => {
  const translations = useAppSelector(getTranslations)

  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false)

  return (
    <StyledMyPageNavigation>
      <JATONavBar
        left={() => (
          <JATONavGroup flexDirection="row" space={16}>
            <JATONavMenuDropdown
              trigger={{
                content: translations.JNT_Portal_NAV_personal,
              }}
              items={[
                {
                  id: 'myPortalSettingsMenuItem',
                  content: translations.JNT_Portal_NAV_portal,
                  onClick: (e) => {
                    e.preventDefault()
                    setSettingsModalOpen(true)
                  },
                  isActive: true,
                  path: '',
                },
              ]}
              variant="desktop"
            />
          </JATONavGroup>
        )}
      />
      {isSettingsModalOpen && (
        <MyPortalSettingsModal
          isOpen={isSettingsModalOpen}
          onClose={() => setSettingsModalOpen(false)}
        />
      )}
    </StyledMyPageNavigation>
  )
}
