import { translationtaglist } from 'config'
import { TranslationsRequest } from 'models/Translations/TranslationsRequest'
import { TranslationsResponse } from 'models/Translations/TranslationsResponse'
import { useQuery, UseQueryResult } from 'react-query'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { setTranslations } from 'redux/translations/translationsSlice'
import translationService from 'services/common/TranslationService'

const TranslationsStateQueryKey = 'tranlationsState'

export const useTranslations = (): UseQueryResult<
  TranslationsResponse,
  unknown
> => {
  const dispatch = useAppDispatch()
  const { languageId } = useAppSelector(selectCommonUserData)
  const translationsRequest: TranslationsRequest = {
    languageId: languageId,
    textId: translationtaglist,
  }
  return useQuery(
    [TranslationsStateQueryKey, translationsRequest.languageId],
    () => translationService.getTranslations(translationsRequest),
    {
      // Need to refresh translations response each time changing language
      staleTime: 0,
      onSuccess: (data: TranslationsResponse) => {
        dispatch(setTranslations(data.appTextTranslation))
      },
    }
  )
}
