import { IMappingObject } from 'helper/IMappingObject'
import { specificationsFilters } from 'helper/specificationsFiltersHelper'
import {
  IAdvancedFilterOption,
  ISelectedFilterOption,
} from 'models/Filters/FilterOption'
import {
  AdvancedFilterType,
  AdvancedOptionFilterType,
  IAdvancedFilterModel,
} from 'models/Specifications/AdvanceFilters/AdvanceFiltersRequest'
import { AdvanceFilterInfoObject } from 'models/Specifications/AdvanceFilters/AdvanceFiltersResponse'

export const buildCombinedAdvancedFilters = (
  advancedFilters: IMappingObject<IAdvancedFilterOption[]>,
  specFilters: IMappingObject<ISelectedFilterOption[]>,
  isLocalOptions: boolean,
  lastSelectedOptionFilterType: AdvancedOptionFilterType,
  mustIncludeAdvancedFilters = true
): IAdvancedFilterModel[] => {
  const mappedAdvancedFilters = mapAdvancedFilters(advancedFilters)

  if (mustIncludeAdvancedFilters && mappedAdvancedFilters.length == 0) {
    return []
  }

  const mappedSpecFilters = mapSpecFilters(
    specFilters,
    isLocalOptions,
    lastSelectedOptionFilterType
  )

  return mappedAdvancedFilters.concat(mappedSpecFilters)
}

const getAdvancedFilterValues = (
  options: ISelectedFilterOption[],
  type: AdvancedFilterType
): string[] =>
  type == AdvancedFilterType.Range
    ? options[0].value.split('|')
    : type == AdvancedFilterType.FreeSearch
      ? options[0].value.split(',')
      : options.map((o) => o.value)

export const getAdvanceFiltersSchemaIds = (
  advancedFilters: AdvanceFilterInfoObject[]
): any => {
  const schemaIdsSelected = advancedFilters.sort((aF) => aF.schemaId).join(',')

  return schemaIdsSelected
}

const mapAdvancedFilters = (
  advancedFilters: IMappingObject<IAdvancedFilterOption[]>
): IAdvancedFilterModel[] =>
  Object.keys(advancedFilters)
    .filter((filterName) => advancedFilters[filterName].length > 0)
    .map((filterName) => {
      const filterOption = advancedFilters[filterName][0]
      const schemaId = filterOption.schemaId
      const type = filterOption.filteringType
      const optionFilterType = filterOption.optionFilterType
      const values = getAdvancedFilterValues(advancedFilters[filterName], type)

      return {
        filterName: filterName,
        schemaId: schemaId,
        filteringType: type,
        optionFilterType: optionFilterType,
        filterDisplayValue: values,
        dataValue: values,
      }
    })

const mapSpecFilters = (
  specFilters: IMappingObject<ISelectedFilterOption[]>,
  isLocalOptions: boolean,
  lastSelectedOptionFilterType: AdvancedOptionFilterType
): IAdvancedFilterModel[] =>
  Object.keys(specFilters)
    .filter((filterName) => specFilters[filterName].length > 0)
    .map((filterName) => {
      const filter = specificationsFilters.find((f) => f.name == filterName)

      const schemaId =
        (isLocalOptions ? filter?.localSchemaId : filter?.schemaId) ?? 0
      const type = filter?.advancedFilterType ?? 0
      const values = getAdvancedFilterValues(specFilters[filterName], type)

      return {
        filterName: filterName,
        schemaId: schemaId,
        filteringType: type,
        optionFilterType: lastSelectedOptionFilterType,
        filterDisplayValue: values,
        dataValue: values,
        isElasticFilter: true,
      }
    })

export const getFiltersSearchTypes = (
  specsFiltersMapped: IMappingObject<ISelectedFilterOption[]>,
  advancedFiltersMapped: IMappingObject<IAdvancedFilterOption[]>
): string => {
  const specsFilters = mapSpecFilters(specsFiltersMapped, false, 0)
  const advancedFilters = mapAdvancedFilters(advancedFiltersMapped)
  let labelMessage = 'SearchTypes:'
  if (specsFilters.filter((f) => f.isElasticFilter).length > 0) {
    labelMessage += 'ES'
  }

  if (advancedFilters.length > 0) {
    labelMessage += 'AS,'
  }

  return labelMessage
}

export const getFilterSchemaIds = (
  specsFiltersMapped: IMappingObject<ISelectedFilterOption[]>,
  isLocalOptions: boolean
): string => {
  const specsFilters = mapSpecFilters(specsFiltersMapped, isLocalOptions, 0)

  return specsFilters
    .map((filter) => filter.schemaId)
    .sort((a, b) => {
      if (a !== undefined && b !== undefined) {
        return a - b
      }
      return 0
    })
    .join(',')
}

export const getAdvancedFilterSchemaIdsFromMappedObject = (
  advancedFiltersMapped: IMappingObject<IAdvancedFilterOption[]>
): string => {
  const specsFilters = mapAdvancedFilters(advancedFiltersMapped)

  return specsFilters
    .map((filter) => filter.schemaId)
    .sort((a, b) => {
      if (a !== undefined && b !== undefined) {
        return a - b
      }
      return 0
    })
    .join(',')
}
