import { INewsArticle } from './INewsArticle'

export interface INewsArticleResult {
  articles: INewsArticle[]
  totalNumber: number
}

export const emptyNewsArticleResult: INewsArticleResult = {
  articles: [],
  totalNumber: 0,
}
