import styled from 'styled-components'

export const StyledSignIn = styled.div`
  display: flex;
  width: 75%;
  flex-direction: column;

  .SignUp {
    padding-top: 280px;
  }
`
