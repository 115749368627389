import { JATOFooter } from '@jato/ui-component-library'
import styled from 'styled-components'

export const StyledFooter = styled(JATOFooter)`
  flex-shrink: 0;
  padding: 4px 24px 5px;
  align-items: center;

  i {
    font-style: normal;
    padding: 0 10px;
  }
`
