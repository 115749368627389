import { useRootPathResolver } from 'hooks/useRootPathResolver'
import { TrainingVideoType } from 'models/Common/TrainingVideoType'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'
import React, { useState } from 'react'
import { StyledTrainingVideos } from './TrainingVideos.styles'

import {
  PlayerConfig,
  SourceConfig,
} from 'bitmovin-player/modules/bitmovinplayer-core'

import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.css'

import { BitmovinPlayer } from 'bitmovin-player-react'
import { defaultPlayerConfig } from 'config'

export interface ITrainingVideosProps {
  videoResponse: TrainingVideoResponse[]
}
export interface ITrainingVideo {
  name: string
  posterAddress: string
  streamingEndPointAddress: string
  streamingSourceType: string
}

export interface IVideoPlayerProps {
  config: PlayerConfig
  source: SourceConfig
}

export const TrainingVideos: React.FC<ITrainingVideosProps> = ({
  videoResponse,
}: ITrainingVideosProps) => {
  const { isSpecifications, isMonthlyPayments, isVolumes } =
    useRootPathResolver()
  const getDefaultPlaySource = (): SourceConfig => {
    const defaultVideo = videoResponse[0].videoList[0]
    const defaultPlaySource: SourceConfig = {
      hls: defaultVideo.streamingEndPointAddress,
      poster: defaultVideo.posterAddress,
    }

    if (isSpecifications) {
      const trainingVideo: ITrainingVideo =
        videoResponse.find(
          (x) => x.trainingVideoType === TrainingVideoType.Specifications
        )?.videoList[0] || videoResponse[0].videoList[0]
      return {
        hls: trainingVideo.streamingEndPointAddress,
        poster: trainingVideo.posterAddress,
      }
    } else if (isMonthlyPayments) {
      const trainingVideo: ITrainingVideo =
        videoResponse.find(
          (x) => x.trainingVideoType === TrainingVideoType.MonthlyPayments
        )?.videoList[0] || videoResponse[0].videoList[0]
      return {
        hls: trainingVideo.streamingEndPointAddress,
        poster: trainingVideo.posterAddress,
      }
    } else if (isVolumes) {
      const trainingVideo: ITrainingVideo =
        videoResponse.find(
          (x) => x.trainingVideoType === TrainingVideoType.Volumes
        )?.videoList[0] || videoResponse[0].videoList[0]
      return {
        hls: trainingVideo.streamingEndPointAddress,
        poster: trainingVideo.posterAddress,
      }
    } else {
      return defaultPlaySource // default/news video
    }
  }

  const [videoPlayerProps, setVideoPlayerProps] = useState<IVideoPlayerProps>({
    source: getDefaultPlaySource(),
    config: { ...defaultPlayerConfig, playback: { autoplay: true } },
  })

  const handleChangeTrainingVideoEvent = (
    trainingVideo: ITrainingVideo
  ): void => {
    const updateVideoPlayerProps: IVideoPlayerProps = {
      ...videoPlayerProps,
      source: {
        hls: trainingVideo.streamingEndPointAddress,
        poster: trainingVideo.posterAddress,
      },
    }
    setVideoPlayerProps(updateVideoPlayerProps)
  }

  return (
    <StyledTrainingVideos>
      <BitmovinPlayer
        config={videoPlayerProps.config}
        source={videoPlayerProps.source}
      />
      <div className="thumbnailcontainer">
        {videoResponse.map((videos, index) => (
          <a
            id={
              'trainingVideosThumbnailLink_' + videos.trainingVideoCategoryName
            }
            className="item"
            key={index}
          >
            <img
              src={videos.videoList[0].posterAddress}
              title={videos.videoList[0].name}
              onClick={() =>
                handleChangeTrainingVideoEvent(videos.videoList[0])
              }
            />
          </a>
        ))}
      </div>
    </StyledTrainingVideos>
  )
}
