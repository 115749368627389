import type { SliderProps } from 'rc-slider'
import Slider from 'rc-slider'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import raf from 'rc-util/lib/raf'
import * as React from 'react'

const HandleTooltip = (props: {
  value: number
  children: React.ReactElement
  tipFormatter?: (value: number) => React.ReactNode
}): JSX.Element => {
  const {
    value,
    children,
    tipFormatter = (val) => `${val} %`,
    ...restProps
  } = props

  const tooltipRef = React.useRef<any>()
  const rafRef = React.useRef<number | null>(null)

  const cancelKeepAlign = (): void => {
    raf.cancel(rafRef.current!)
  }

  const keepAlign = (): void => {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign()
    })
  }

  React.useEffect(() => {
    keepAlign()

    return cancelKeepAlign
  }, [value])

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      ref={tooltipRef}
      trigger={'hover'}
      {...restProps}
    >
      {children}
    </Tooltip>
  )
}

const TooltipSlider = ({
  tipFormatter,
  ...props
}: SliderProps & {
  tipFormatter?: (value: number) => React.ReactNode
}): JSX.Element => {
  const tipHandleRender: SliderProps['handleRender'] = (node, handleProps) => (
    <HandleTooltip value={handleProps.value} tipFormatter={tipFormatter}>
      {node}
    </HandleTooltip>
  )

  return <Slider {...props} handleRender={tipHandleRender} />
}

export default TooltipSlider
