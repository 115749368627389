import { JATOTheme } from '@jato/ui-component-library'
import { IMappingObject } from './IMappingObject'

export const LicenceExpiredStatus = '0'

export const getPrivacySectionDetails: IMappingObject<
  IMappingObject<string[]>
> = {
  ['JNT_Portal_Login_OverviewHead']: {
    ['JNT_Portal_Login_OverviewSubHead']: [
      'JNT_Portal_Login_OverviewSubHeadText',
    ],
    ['JNT_Portal_Login_OverviewSubHead1']: [
      'JNT_Portal_Login_OverviewSubHead1TextHtml',
      'JNT_Portal_Login_OverviewSubHead1Text',
    ],
    ['JNT_Portal_Login_OverviewSubHead2']: [
      'JNT_Portal_Login_OverviewSubHead2TextHtml',
    ],
    ['JNT_Portal_Login_OverviewSubHead3']: [
      'JNT_Portal_Login_OverviewSubHead3TextHtml',
      'JNT_Portal_Login_OverviewSubHead3TextHtml1',
    ],
    ['JNT_Portal_Login_OverviewSubHead4']: [
      'JNT_Portal_Login_OverviewSubHead4Text',
    ],
  },
  ['JNT_Portal_Login_PrivacyHead']: {
    ['JNT_Portal_Login_PrivacySubHead']: ['JNT_Portal_Login_PrivacyText'],
    ['JNT_Portal_Login_PrivacySubHead1']: [
      'JNT_Portal_Login_PrivacyText1',
      'JNT_Portal_Login_PrivacyText2',
      'JNT_Portal_Login_PrivacyText3Html',
      'JNT_Portal_Login_PrivacyText4Html',
      'JNT_Portal_Login_PrivacyText5Html',
    ],
    ['JNT_Portal_Login_PrivacySubHead2']: ['JNT_Portal_Login_PrivacySub2Text'],
    ['JNT_Portal_Login_PrivacySubHead3']: [
      'JNT_Portal_Login_PrivacySub3Text',
      'JNT_Portal_Login_PrivacySub3TextHtml',
    ],
    ['JNT_Portal_Login_PrivacySubHead4']: [
      'JNT_Portal_Login_PrivacySub4Text',
      'JNT_Portal_Login_PrivacySub4TextHtml',
      'JNT_Portal_Login_PrivacySub4TextHtml1',
      'JNT_Portal_Login_PrivacySub4TextHtml2',
      'JNT_Portal_Login_PrivacyTab',
    ],
  },
  ['JNT_Portal_Login_MarketingHead']: {
    ['JNT_Portal_Login_MarketingSubHead']: [
      'JNT_Portal_Login_MarketingSubHeadText',
    ],
    ['JNT_Portal_Login_MarketingSubHead1']: [
      'JNT_Portal_Login_MarketingSubHead1Text',
    ],
    ['JNT_Portal_Login_MarketingSubHead2']: [
      'JNT_Portal_Login_MarketingSubHead2Text',
    ],
  },
  ['JNT_Portal_Login_CookieHead']: {
    ['JNT_Portal_Login_CookieSubHead']: ['JNT_Portal_Login_CookieSubHeadText'],
    ['JNT_Portal_Login_CookieSubHead1']: [
      'JNT_Portal_Login_CookieSubHead1Text',
    ],
  },
  ['JNT_Portal_Login_DataHead']: {
    ['JNT_Portal_Login_DataSubHead']: ['JNT_Portal_Login_DataSubHeadText'],
    ['JNT_Portal_Login_DataSubHead1']: [
      'JNT_Portal_Login_DataSubHead1Text',
      'JNT_Portal_Login_DataSubHead1TextHtml',
    ],
    ['JNT_Portal_Login_DataSubHead2']: ['JNT_Portal_Login_DataSubHead2Text'],
    ['JNT_Portal_Login_DataSubHead3']: [
      'JNT_Portal_Login_DataSubHead3TextHtml',
      'JNT_Portal_Login_DataSubHead3Text',
    ],
    ['JNT_Portal_Login_DataSubHead4']: [
      'JNT_Portal_Login_DataSubHead4Text',
      'JNT_Portal_Login_DataSubHead4Text1',
      'JNT_Portal_Login_DataSubHead4TextHtml',
      'JNT_Portal_Login_DataSubHead4TextHtml1',
    ],
    ['JNT_Portal_Login_DataSubHead5']: ['JNT_Portal_Login_DataSubHead5Text'],
    ['JNT_Portal_Login_DataSubHead6']: ['JNT_Portal_Login_DataSubHead6Text'],
    ['JNT_Portal_Login_DataSubHead7']: ['JNT_Portal_Login_DataSubHead7Text'],
  },
  ['JNT_Portal_Login_GlossaryHead']: {
    ['JNT_Portal_Login_GlossarySubHead']: [
      'JNT_Portal_Login_GlossarySubHeadTextHtml',
    ],
    ['JNT_Portal_Login_GlossarySubHead1']: [
      'JNT_Portal_Login_GlossarySubHead1TextHtml',
    ],
    ['JNT_Portal_Login_GlossarySubHead2']: [
      'JNT_Portal_Login_GlossarySubHead2TextHtml',
      'JNT_Portal_Login_GlossarySubHead2TextHtml1',
    ],
  },
}

export const minLength = 8

export enum ValidationResult {
  Neutral,
  Success,
  Fail,
}

export const hasUppercase = (value: string): boolean => /[A-Z]/.test(value)
export const hasLowercase = (value: string): boolean => /[a-z]/.test(value)
export const hasNumber = (value: string): boolean => /[\d]/.test(value)
export const hasSpecialChars = (value: string): boolean =>
  /[@$£!%*#?><^.(),"~/&+_=-]/.test(value)

export const validatePassword = (value: string): boolean => {
  const conditions = [hasLowercase, hasUppercase, hasNumber, hasSpecialChars]

  const passwordCriteriaCount = conditions.reduce(
    (acc, condition) => acc + (condition(value) ? 1 : 0),
    0
  )
  return passwordCriteriaCount >= 3
}

export const getValidationResult = (
  value: string,
  condition: (value: string) => boolean
): ValidationResult =>
  condition(value)
    ? ValidationResult.Success
    : value.length === 0 || validatePassword(value)
      ? ValidationResult.Neutral
      : ValidationResult.Fail

export const getMinLengthValidationResult = (
  value: string
): ValidationResult =>
  value.length >= minLength
    ? ValidationResult.Success
    : value.length === 0
      ? ValidationResult.Neutral
      : ValidationResult.Fail

export const getTotalValidationResult = (value: string): ValidationResult =>
  validatePassword(value)
    ? ValidationResult.Success
    : value.length === 0
      ? ValidationResult.Neutral
      : ValidationResult.Fail

export const getColor = (validationResult: ValidationResult): string =>
  validationResult === ValidationResult.Success
    ? JATOTheme.colors.green
    : validationResult === ValidationResult.Neutral
      ? JATOTheme.colors.grey
      : JATOTheme.colors.primary
