import Loader from 'components/Loader'
import { validateMetadata } from 'helper/mpMetadataHelper'
import { showErrorToast } from 'helper/toastHelper'
import React from 'react'
import { useQuery } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import monthlyPaymentsReportService from 'services/MonthlyPaymentsReport/MonthlyPaymentsReportService'
import { MonthlyPaymentsReportBody } from './MonthlyPaymentsReportBody'
import { MonthlyPaymentsReportHeader } from './MonthlyPaymentsReportHeader'

interface IMonthlyPaymentsReport {
  vehicles: any
}

export const MonthlyPaymentsReport: React.FC<IMonthlyPaymentsReport> = ({
  vehicles,
}: IMonthlyPaymentsReport) => {
  const translations = useAppSelector(getTranslations)

  const { data: mpReportMetaData } = useQuery<any>(
    ['mpReportMetaData'],
    async () => await monthlyPaymentsReportService.getMpReportMetaData(),
    {
      onSuccess: (data) => {
        validateMetadata(data?.metadata)
      },
      onError: (error) =>
        showErrorToast(
          translations.JNT_Error,
          (error as any)?.response?.data?.message ?? translations.JNT_Error
        ),
      staleTime: Infinity,
    }
  )

  return vehicles && mpReportMetaData ? (
    <div
      style={{
        minWidth: `${vehicles.length * 220 + 300}px`,
      }}
    >
      <MonthlyPaymentsReportHeader vehicles={vehicles} />
      <MonthlyPaymentsReportBody
        vehicles={vehicles}
        mpReportMetaData={mpReportMetaData}
      />
    </div>
  ) : (
    <Loader />
  )
}
