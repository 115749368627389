import {
  createToast,
  JATOButtonIcon,
  JATOIcon,
  JATOLink,
  JATOList,
  JATOListItem,
} from '@jato/ui-component-library'
import {
  CommandColumn,
  CommandModel,
  Edit,
  EditSettingsModel,
  PageSettingsModel,
} from '@syncfusion/ej2-grids'
import {
  ActionEventArgs,
  ColumnDirective,
  ColumnsDirective,
  DeleteEventArgs,
  GridComponent,
  Inject,
  Sort,
  SortSettingsModel,
} from '@syncfusion/ej2-react-grids'
import { queryClient } from 'app'
import { ModalComponent } from 'components/ModalComponent'
import { countryCodeMapper } from 'helper/countryCodeMapper'
import { IBasket } from 'models/Basket/Basket'
import { LoginResponse } from 'models/Login/LoginResponse'
import { IMpLicensedCountry } from 'models/UserSettings/UserSettings'
import { MPUserShipLog } from 'models/UserShipLog/UserShipLog'
import { MpVehicle } from 'models/VehicleSelection/MpVehicle'
import React, { useState } from 'react'
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  resetMpVehicles,
  setMpVehicles,
} from 'redux/monthlypayments/mpVehiclesSlice'
import { resetFilters } from 'redux/monthlypayments/selectedFiltersSlice'
import {
  resetLastSelectedMarket,
  selectUserData,
} from 'redux/monthlypayments/userDataSlice'
import { RoutePaths } from 'router/RoutePaths'
import basketManagerService from 'services/BasketManager/BasketManagerService'
import userShipLogService from 'services/UserShipLog/UserShipLogService'
import { StyledBasket } from './Basket.styles'

interface IBasketProps {
  baskets: IBasket[] | undefined
  onCloseEvent: () => void
  isUserBaskets: boolean
}

export const Basket: React.FC<IBasketProps> = (props: IBasketProps) => {
  interface IBasketUpdate {
    basket: IBasket
    displayToast: boolean
  }

  const dispatch = useAppDispatch()
  const history = useHistory()
  const userData: LoginResponse = useAppSelector(selectUserData)
  const [mpVehicleList, setMpVehicleList] = useState<MpVehicle[]>([])
  const [basketName, setBasketName] = useState('')
  const [status, setStatus] = React.useState({ hideDialog: false })
  const [updatedBasketToastText, setUpdatedBasketToastText] =
    useState('Basket Updated')

  const pageSettings: PageSettingsModel = { pageSize: 6 }

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowDeleting: true,
    showDeleteConfirmDialog: true,
  }

  const sortingOptions: SortSettingsModel = {
    columns: [{ field: 'lastAccessDate', direction: 'Descending' }],
  }

  const editCommands: CommandModel[] = [
    {
      type: 'Edit',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' },
    },
    {
      type: 'Save',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-update e-icons',
      },
    },
    {
      type: 'Cancel',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' },
    },
  ]
  const deleteCommand: CommandModel[] = [
    {
      type: 'Delete',
      buttonOption: {
        cssClass: 'e-flat',
        iconCss: 'e-delete e-icons',
      },
    },
  ]

  const handleClick = (basket: IBasket): void => {
    setBasketName(basket.basketName)
    setMpVehicleList(basket.mpVehicleList)
    setStatus({ hideDialog: true })
  }
  const dialogClose = (): void => {
    setStatus({ hideDialog: false })
  }

  const handleOnSaveBasketPopUp = (): void => {
    console.log('test')
  }

  const queryCellInfo = (args: any): void => {
    if (args.column.field == 'market') {
      if (args.data.market == 'UK') {
        args.cell.innerHTML = `<img src="${process.env.PUBLIC_URL}/images/specifications/countryflags/GB.png" style="width: 32px;">`
      } else {
        args.cell.innerHTML = `<img src="${
          process.env.PUBLIC_URL
        }/images/specifications/countryflags/${countryCodeMapper(
          args.data.market
        )}.png" style="width: 32px;">`
      }
    } else if (args.column.field == 'basketName') {
      const basket = args.data as IBasket
      render(
        <div>
          <JATOLink
            id="monthlyPaymentsBasketsGridOpenLink"
            display="inline-flex"
            textTransform="none"
            onClick={() => {
              getMpDetailsForBasket(basket)
            }}
          >
            {basket.basketName}
          </JATOLink>
          <span
            id="monthlyPaymentsBasketsGridVehicleInfoButton"
            title="Basket vehicle info"
            style={{ cursor: 'pointer', color: '#4392B5' }}
            onClick={() => {
              handleClick(basket)
            }}
          >
            <JATOIcon iconName="baseline_info" iconSize={20} />
          </span>
        </div>,
        args.cell
      )
    } else if (args.column.field == 'isShared') {
      const basket = args.data as IBasket
      render(
        <JATOButtonIcon
          id="monthlyPaymentsBasketsGridShareButton"
          title={basket.isShared ? 'Un-share' : 'Share'}
          iconName={basket.isShared ? 'baseline_link_off' : 'baseline_link'}
          disabled={userData.user.userId !== basket.userId}
          onClick={() => {
            updateShareBasket(basket, !basket.isShared)
          }}
        />,
        args.cell
      )
    }
  }

  const updateShareBasket = (basket: IBasket, isShare: boolean): void => {
    const newBasket = { ...basket }
    newBasket.isShared = isShare
    setUpdatedBasketToastText('Basket Updated')
    const basketUpdate: IBasketUpdate = {
      basket: newBasket,
      displayToast: true,
    }
    updateBasketDataMutation.mutate(basketUpdate)
  }

  const { mutate: logMonthlyPaymentsClick } = useMutation(
    (log: MPUserShipLog) => userShipLogService.logViewMonthlyPaymentsClick(log)
  )

  const getMpDetailsForBasket = (basket: IBasket): void => {
    dispatch(resetMpVehicles(false))
    const lastSelectedMarket: IMpLicensedCountry = {
      countryCode: basket.market,
      countryName: basket.displayMarketValue,
    }
    dispatch(resetFilters(lastSelectedMarket))

    dispatch(resetLastSelectedMarket(lastSelectedMarket))
    dispatch(setMpVehicles(basket.mpVehicleList))
    basket.lastAccessDate = new Date()
    basket.userClicks++
    const basketUpdate: IBasketUpdate = {
      basket: basket,
      displayToast: false,
    }
    updateBasketDataMutation.mutate(basketUpdate)

    logMonthlyPaymentsClick({
      user: userData.user,
      vehicles: basket.mpVehicleList,
      countryCode: lastSelectedMarket.countryCode,
    })

    props.onCloseEvent()
    history.push(RoutePaths.MonthlyPaymentsReport)
  }

  const updateBasketData = (
    updateBasket: IBasket,
    displayToast: boolean
  ): Promise<void> =>
    basketManagerService
      .updateBasketData(updateBasket)
      .then(() => {
        if (displayToast) {
          createToast({
            children: <div>{updatedBasketToastText}</div>,
          })
        }
      })
      .catch(() => {
        createToast({
          children: <div>There was a problem updating the basket</div>,
        })
      })

  const updateBasketDataMutation = useMutation({
    mutationFn: (updateBasket: IBasketUpdate) =>
      updateBasketData(updateBasket.basket, updateBasket.displayToast),
    onSuccess: () => {
      queryClient.invalidateQueries('mpBasketData')
    },
  })

  const removeBasketData = (basket: IBasket | undefined): Promise<void> =>
    basketManagerService
      .removeBasketData(basket?.id)
      .then(() => {
        createToast({
          children: <div>Basket Removed!!!</div>,
        })
      })
      .catch(() => {
        createToast({
          children: <div>Problem while removing!!!</div>,
        })
      })

  const { mutate: removeBasketDataMutate } = useMutation({
    mutationFn: (basket: IBasket | undefined) => removeBasketData(basket),
    onSuccess: () => queryClient.invalidateQueries('mpBasketData'),
  })

  const callRemoveBasketData = (basket: IBasket): any =>
    removeBasketDataMutate(basket)

  const autoComplete = (args: ActionEventArgs): void => {
    if (args.requestType === 'save') {
      const basket = args.data as IBasket
      basket.basketName.trim()
      if (doesBasketNameExist(basket)) {
        setUpdatedBasketToastText('Basket Name Already Exists')
        const basketUpdate: IBasketUpdate = {
          basket: args.previousData as IBasket,
          displayToast: true,
        }
        updateBasketDataMutation.mutate(basketUpdate)
      } else {
        setUpdatedBasketToastText('Basket Updated')
        const basketUpdate: IBasketUpdate = {
          basket: basket,
          displayToast: true,
        }
        updateBasketDataMutation.mutate(basketUpdate)
      }
    }
    if (args.requestType === 'delete') {
      const deleteRow = args as DeleteEventArgs
      const deleteData = deleteRow.data as IBasket[]
      if (deleteData) callRemoveBasketData(deleteData[0])
    }
  }

  const getBasketsForTab = (): any => {
    if (props.isUserBaskets) {
      return props.baskets?.filter(
        (basket: IBasket) => basket.userId === userData.user.userId
      )
    } else {
      return props.baskets?.filter(
        (basket: IBasket) => basket.userId !== userData.user.userId
      )
    }
  }

  function doesBasketNameExist(editedBasket: IBasket): boolean {
    const duplicateCheck = (basket: IBasket): boolean =>
      basket.basketName.trim()?.toLowerCase() ===
      editedBasket.basketName.trim()?.toLocaleLowerCase()

    const filteredList = props.baskets?.filter(duplicateCheck)
    if (filteredList !== undefined && filteredList.length > 1) {
      return true
    } else {
      return false
    }
  }

  const setDateFormat = {
    type: 'date',
    format: 'dd-MM-yyyy',
  }

  return (
    <StyledBasket>
      <div id="dialog-target">
        <GridComponent
          id="monthlyPaymentsBasketsGrid"
          dataSource={getBasketsForTab()}
          allowPaging={true}
          allowSorting={true}
          sortSettings={sortingOptions}
          pageSettings={pageSettings}
          editSettings={editOptions}
          queryCellInfo={queryCellInfo.bind(this)}
          actionComplete={autoComplete}
          loadingIndicator={{ indicatorType: 'Shimmer' }}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" visible={false} isPrimaryKey={true} />
            <ColumnDirective
              field="market"
              width="100"
              headerText="Country"
              allowEditing={false}
              allowSorting={false}
            />
            <ColumnDirective
              field="basketName"
              width="110"
              headerText="Basket Name"
              allowSorting={false}
            />
            <ColumnDirective
              field="updateDate"
              width="110"
              headerText="Date Created"
              allowEditing={false}
              type="date"
              allowSorting={false}
              format={setDateFormat}
            />
            <ColumnDirective
              field="lastAccessDate"
              width="110"
              headerText="Date last ran"
              allowEditing={false}
              allowSorting={false}
              type="date"
              format={setDateFormat}
            />
            <ColumnDirective
              headerText="Rename"
              width="80"
              commands={editCommands}
              allowSorting={false}
            />{' '}
            <ColumnDirective
              field="isShared"
              headerText="Share"
              width="80"
              allowEditing={false}
              allowSorting={false}
            />
            <ColumnDirective
              headerText="Delete"
              width="80"
              commands={deleteCommand}
            />{' '}
          </ColumnsDirective>
          <Inject services={[Edit, CommandColumn, Sort]} />
        </GridComponent>
        <ModalComponent
          maxWidth="sm"
          modalVisible={status.hideDialog}
          closeButton
          title={basketName}
          primaryButtonName=""
          onSecondaryClick={dialogClose}
          secondaryButtonName=""
          onPrimaryClick={handleOnSaveBasketPopUp}
          isPrimaryButtonLoading={false}
        >
          <JATOList>
            {mpVehicleList.map((mpVehicle, index) => (
              <JATOListItem key={index} justifyContent="space-between">
                <span>
                  {mpVehicle.make} {mpVehicle.model} {mpVehicle.versionName}
                </span>
              </JATOListItem>
            ))}
          </JATOList>
        </ModalComponent>
      </div>
    </StyledBasket>
  )
}
