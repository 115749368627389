import { JATOTheme } from '@jato/ui-component-library'
import { Accordion } from 'components/Accordion/Accordion'
import Loader from 'components/Loader'
import {
  AllValues,
  DpPartBetweenValue,
  getCountryName,
} from 'helper/volumesHelper'
import { useGetCountries, useGetVehicleVolumeTypes } from 'hooks/volumes'
import { DpPoint } from 'models/Volumes/DpPoint'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesQueryState } from 'redux/volumes/volumesSlice'
import { StyledVolumesQueryDescription } from './VolumesQueryDescription.styles'

export const VolumesQueryDescription: React.FC = () => {
  const queryState = useAppSelector(getVolumesQueryState)
  const translations = useAppSelector(getTranslations)

  const customTitles: Record<string, string> = {
    databaseType: translations.JNT_Volumes_QD_DatabaseType,
    countries: translations.JNT_Volumes_QD_Countries,
    dataSetIds: translations.JNT_Volumes_QG_DQ_Dataset,
    periods: translations.JNT_Volumes_QD_Periods,
  }

  const { data: countries, isFetching: isFetchingCountries } = useGetCountries(
    queryState.databaseType,
    !queryState.countries
  )
  const { data: datasets, isFetching: isFetchingDatasets } =
    useGetVehicleVolumeTypes(!queryState.dataSetIds)

  const isFetching =
    (isFetchingCountries || isFetchingDatasets) &&
    queryState.dataSetIds &&
    queryState.countries

  const databaseTypes: { value: string; label: string }[] = [
    {
      value: VolumesDatabaseType.ModelMixSpecs,
      label: translations.JNT_Volumes_QG_X,
    },
    {
      value: VolumesDatabaseType.ModelMix,
      label: translations.JNT_Volumes_QG_M,
    },
    {
      value: VolumesDatabaseType.SourceData,
      label: translations.JNT_Volumes_QG_V,
    },
  ]

  const getCustomTitle = (key: string): string => {
    if (key === 'periods') {
      return queryState.periods !== undefined && queryState.periods.length > 0
        ? customTitles[key] + ' (' + queryState.periods!.length + ')'
        : customTitles[key]
    }

    return customTitles[key] || key
  }

  const renderValue = (key: string): string => {
    switch (key) {
      case 'countries':
        return (
          queryState.countries
            ?.map((c) => getCountryName(c, countries))
            .join(', ') ?? ''
        )
      case 'databaseType':
        return (
          databaseTypes.find((type) => type.value === queryState.databaseType)
            ?.label ?? ''
        )
      case 'dataSetIds':
        return (
          datasets
            ?.filter(
              (d) =>
                d.pgProductGroup == queryState.databaseType &&
                queryState.dataSetIds?.includes(d.dstId)
            )
            .map((d) => d.dsShortDescrip)
            .join(', ') ?? ''
        )
      case 'periods':
        return queryState.periods!.map((p) => p.periodName).join(', ') ?? ''
      default:
        return ''
    }
  }

  const renderDataPointValue = (dp: DpPoint): string => {
    let result = dp.valuedesc

    if (dp.value === 'N' && dp.valuedesc !== AllValues) {
      result += ` ${dp.minValue}`
    }
    if (dp.part === DpPartBetweenValue) {
      result += ` ${translations.JNT_Volumes_QG_NB_And} ${dp.maxValue}`
    }

    return result
  }

  return Object.keys(queryState).length === 0 ? (
    <></>
  ) : isFetching ? (
    <Loader style={{ minHeight: '150px' }} />
  ) : (
    <StyledVolumesQueryDescription>
      <div className="accordion-container">
        <Accordion
          id="volumesQueryDescriptionAccordion"
          titleText={translations.JNT_Volumes_QD_QueryDescription}
          isOpen={true}
          titleHeaderWidth="100%"
          titleBackgroundColour={JATOTheme.colors.grey}
        >
          <div className="accordion-content ">
            {Object.keys(queryState)
              .filter((key) => Object.hasOwn(customTitles, key))
              .map((key) => (
                <React.Fragment key={key}>
                  <div className="itemTitle">{getCustomTitle(key)}:</div>
                  <div className="itemContent">{renderValue(key)}</div>
                </React.Fragment>
              ))}
            {queryState.vehicleAttributes?.map((dp) => (
              <React.Fragment key={dp.id}>
                <div className="itemTitle">{dp.iddesc}:</div>
                <div className="itemContent">{renderDataPointValue(dp)}</div>
              </React.Fragment>
            ))}
          </div>
        </Accordion>
      </div>
    </StyledVolumesQueryDescription>
  )
}
