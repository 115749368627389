import {
  JATOButton,
  JATOLink,
  JATOModal,
  JATOSpinner,
  JATOText,
  JATOTheme,
} from '@jato/ui-component-library'
import JATOAcademyLogoIcon from 'assets/icons/JATOAcademyLogo.svg'
import { BitmovinPlayer } from 'bitmovin-player-react'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LogoVideosLoader } from 'components/TrainingVideos/TrainingVideos.styles'
import { defaultPlayerConfig, jatoAcademyUrl } from 'config'
import { TrainingVideoType } from 'models/Common/TrainingVideoType'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import trainingVideosService from 'services/TrainingVideos/TrainingVideosService'

export interface IJATOAcademyPopUp {
  id?: string
  showJATOAcademyVideo: boolean
  onCloseJatoAcademyButton(doNotShowAgain: boolean): void
}

export const JATOAcademyPopUp: React.FC<IJATOAcademyPopUp> = ({
  id = 'jatoAcademyPopup',
  showJATOAcademyVideo,
  onCloseJatoAcademyButton,
}: IJATOAcademyPopUp) => {
  const translations = useAppSelector(getTranslations)

  const [doNotShowAgain, setDoNotShowAgain] = useState(false)

  const { data: jatoAcademyTrainingVideo, isFetching } = useQuery<
    TrainingVideoResponse[]
  >(['jatoAcademyTrainingVideo'], async () => {
    const { data } = await trainingVideosService.getTrainingVideoList()
    const filtered = data.filter(
      (obj) =>
        obj.trainingVideoType === TrainingVideoType.JATOAcademyTrainingVideo
    )
    return filtered
  })

  const onCloseButton = (): void => {
    onCloseJatoAcademyButton(doNotShowAgain)
  }

  return (
    <JATOModal
      isOpen={showJATOAcademyVideo}
      maxWidth={'sm'}
      dismissable={false}
      header={() => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <img src={JATOAcademyLogoIcon} width="100" />
        </div>
      )}
      footer={() => (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <CheckboxWithGtm
              checked={doNotShowAgain}
              onChange={() => setDoNotShowAgain(!doNotShowAgain)}
              id={id + 'DoNotShowAgainCheckbox'}
              label={translations.JNT_Do_not_show_me_this_again}
            >
              <JATOText marginRight={JATOTheme.space[3]} as="p" fontSize={14}>
                {translations.JNT_Do_not_show_me_this_again}
              </JATOText>
            </CheckboxWithGtm>
            <JATOButton
              id={id + 'CloseButton'}
              variant="primary"
              size="large"
              onClick={onCloseButton}
            >
              {translations.JNT_Close}
            </JATOButton>
          </div>
        </>
      )}
    >
      {isFetching ? (
        <LogoVideosLoader>
          <JATOSpinner spinnerSize={64} />
        </LogoVideosLoader>
      ) : (
        <>
          {jatoAcademyTrainingVideo && (
            <BitmovinPlayer
              config={defaultPlayerConfig}
              source={{
                hls: jatoAcademyTrainingVideo[0].videoList[0]
                  .streamingEndPointAddress,
              }}
            />
          )}
          <p>
            {translations.JNT_Volumes_Academy_JATONet}
            <JATOLink
              id={id + 'Link'}
              href={jatoAcademyUrl}
              target="_blank"
              textTransform="none"
            >
              {translations.JNT_Volumes_Academy_Clickhere}
            </JATOLink>
          </p>
        </>
      )}
    </JATOModal>
  )
}
