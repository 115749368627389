import styled from 'styled-components'
import Theme from 'theme'

export const StyledSpecificationsReportBody = styled.div`
  .tableStyle {
    display: table;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
    table-layout: fixed;
    width: 100%;
    border-spacing: unset;
  }

  .tableRow {
    display: table-row;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  .tableRow:nth-child(odd) > .tableCell {
    background-color: ${Theme.colors.ultraLightGrey};
  }

  .tableRow:nth-child(even) > .tableCell {
    background-color: ${Theme.colors.white};
  }

  .tableCell {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
    text-align: left;
    width: 220px;
  }

  .tableValue {
    padding: 2px 10px 1px;
  }

  .tableCell:first-child {
    width: 300px;
  }

  .sticky {
    position: sticky;
    left: 0;
  }

  img {
    vertical-align: middle;
  }

  .wltpInfo {
    vertical-align: middle;
    margin-left: 16px;
    color: #1677ff;
  }

  .wltpButton {
    margin-left: 20px;
    border-radius: 6px;
    color: #1677ff;
    border-color: #1677ff;
    font-size: 14px;
    font-weight: 500;
  }

  .wltpButton:focus {
    box-shadow: unset;
  }

  .wltpCategory {
    font-weight: 500;
    padding: 8px 30px;
    width: 90vw;
  }

  .wltpLoader {
    min-height: 25vh;
    width: calc(100vw - 80px);
    position: sticky;
    left: 0;
  }

  .wltpValue {
    background-color: #7c7c7c;
    text-decoration: none;
    border-radius: 5px;
    padding: 2px 5px 1px;
    color: #fff;
    border-width: 0 4px 4px 0;
  }

  .wltpValue:hover {
    color: #fff;
    background-color: #000;
  }

  .wltpCell:nth-last-child(n + 2)::after {
    content: '/';
    margin: 0 10px;
  }

  .characteristic {
    padding-left: 30px;
    cursor: pointer;
    z-index: 1;
  }

  .tooltipDataDefinition {
    width: 600px;
    font-size: 12px;
    background-color: #444444;
  }
`
