import { JATOSpinner } from '@jato/ui-component-library'
import { TrainingVideoResponse } from 'models/TrainingVideos/TrainingVideoResponse'
import React from 'react'
import { useQuery } from 'react-query'
import trainingVideosService from 'services/TrainingVideos/TrainingVideosService'
import { TrainingVideos } from './TrainingVideos'
import { LogoVideosLoader, StyledTrainingVideos } from './TrainingVideos.styles'

export const TrainingVideosContainer: React.FC = () => {
  const { data: videoResponse, isFetching } = useQuery<TrainingVideoResponse[]>(
    ['videoResponse'],
    async () => {
      const { data } = await trainingVideosService.getTrainingVideoList()
      const filtered = data.filter(
        (obj) => obj.trainingVideoCategoryName !== 'OesVolumes'
      )
      return filtered
    }
  )

  return (
    <StyledTrainingVideos>
      {isFetching ? (
        <LogoVideosLoader>
          <JATOSpinner spinnerSize={64} />
        </LogoVideosLoader>
      ) : (
        videoResponse && <TrainingVideos videoResponse={videoResponse} />
      )}
    </StyledTrainingVideos>
  )
}
