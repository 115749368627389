import styled from 'styled-components'
export interface StyledAutoLoginProps {
  loadingImage: string
}

export const StyledAutoLogin = styled.div<StyledAutoLoginProps>`
   {
    box-sizing: border-box;
    background-image: url('${(props) => props.loadingImage}');
    background-size: 100%;
    height: 900px;
    background-repeat: no-repeat;
  }
`
