import { JATOGroup, JATOSelect } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { useGetLanguageAndCurrency } from 'hooks/portal'
import { useUpdateUserSettings } from 'hooks/volumes'
import React, { useState } from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import { StyledMyPortalSettings } from './MyPortalSettingsModal.styles'

interface IModalProps {
  isOpen?: boolean
  onClose?: () => void
}

export const MyPortalSettingsModal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
}: IModalProps) => {
  const translations = useAppSelector(getTranslations)
  const userSettings = useAppSelector(getVolumesUserState)

  const { mutate: updateUserSettings } = useUpdateUserSettings()

  const { data, isFetching } = useGetLanguageAndCurrency()

  const languageOptions =
    data?.languageList.map((l) => ({
      value: l.id.toString(),
      displayValue: l.language,
    })) ?? []

  const currencyOptions =
    data?.currencyList.map((c) => ({
      value: c.currencyCode,
      displayValue: c.description,
    })) ?? []

  const [selectedLanguageId, setSelectedLanguageId] = useState(
    userSettings.languageId
  )
  const [selectedCurrency, setSelectedCurrency] = useState(
    userSettings.currency
  )

  const onSaveClick = (): void => {
    updateUserSettings({
      ...userSettings,
      languageId: selectedLanguageId,
      currency: selectedCurrency,
    })

    onClose && onClose()
  }

  return (
    <CommonModal
      closeButton
      maxWidth="xs"
      isOpen={isOpen}
      onClose={onClose}
      title={translations.JNT_Portal_NAV_portal}
      primaryButtonName={translations.JNT_save}
      onPrimaryClick={onSaveClick}
    >
      <StyledMyPortalSettings>
        {isFetching ? (
          <Loader style={{ minHeight: '178px' }} />
        ) : (
          <JATOGroup style={{ gap: '20px' }}>
            <JATOSelect
              id="myPageSettingsLanguageDropdown"
              label={translations.JNT_Portal_PS_Lang}
              width="100%"
              dataSrc={languageOptions}
              value={userSettings.languageId.toString()}
              onChange={(item) => setSelectedLanguageId(+item)}
            />
            <JATOSelect
              id="myPageSettingsCurrencyDropdown"
              label={translations.JNT_Portal_PS_Curr}
              width="100%"
              dataSrc={currencyOptions}
              value={userSettings.currency}
              onChange={(item) => setSelectedCurrency(item)}
            />
          </JATOGroup>
        )}
      </StyledMyPortalSettings>
    </CommonModal>
  )
}
