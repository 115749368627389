import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { LoaderModal } from 'components/Loader/LoaderModal'
import { getDatasetFields } from 'helper/volumesHelper'
import {
  useGetCountries,
  useGetVehicleVolumeGroups,
  useGetVehicleVolumeTypes,
} from 'hooks/volumes'
import { Countries } from 'models/Volumes/Countries'
import React, { useImperativeHandle, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import {
  getVolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { IVolumesQuerySelectionPanelRef } from './VolumesQuerySelectionPanel'

const VolumesQueryCountrySelectionPanel: React.ForwardRefRenderFunction<
  IVolumesQuerySelectionPanelRef,
  unknown
> = (_: unknown, ref) => {
  const dispatch = useAppDispatch()

  useImperativeHandle(ref, () => ({
    onSelectAll,
    onClearChoices,
  }))

  const queryState = useAppSelector(getVolumesQueryState)

  const { data: countries, isFetching: countriesFetching } = useGetCountries(
    queryState.databaseType
  )
  const { data: datasetGroups, isFetching: datasetGroupsFetching } =
    useGetVehicleVolumeGroups()
  const { data: datasets, isFetching: datasetsFetching } =
    useGetVehicleVolumeTypes()

  const isFetching =
    countriesFetching || datasetGroupsFetching || datasetsFetching

  const [displayedCountries] = useState<string[]>(queryState.countries ?? [])

  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    queryState.countries ?? []
  )

  const getDisplayedCountries = (): Countries[] =>
    countries?.filter((c) => displayedCountries.includes(c.countryCode)) ?? []

  const onSelectedCountriesChange = (countryCode: string): void => {
    const newSelection = selectedCountries.includes(countryCode)
      ? selectedCountries.filter((c) => c != countryCode)
      : [...selectedCountries, countryCode]

    setSelectedCountries(newSelection)

    if (newSelection.length > 0) {
      const dataSetIds =
        datasetGroups
          ?.find((g) => g.productGroup === queryState.databaseType)
          ?.dataSetItems.filter((d) => newSelection.includes(d.country))
          .map((d) => d.dataSetItemId) ?? []

      dispatch(
        setVolumesQueryState({
          ...queryState,
          countries: newSelection,
          ...getDatasetFields(dataSetIds, datasets),
        })
      )
    }
  }

  const onSelectAll = (): void => {
    const newSelection = displayedCountries

    setSelectedCountries(newSelection)

    const dataSetIds =
      datasetGroups
        ?.find((g) => g.productGroup === queryState.databaseType)
        ?.dataSetItems.map((d) => d.dataSetItemId) ?? []

    dispatch(
      setVolumesQueryState({
        ...queryState,
        countries: newSelection,
        ...getDatasetFields(dataSetIds, datasets),
      })
    )
  }

  const onClearChoices = (): void => {
    setSelectedCountries([])
  }

  return (
    <>
      {getDisplayedCountries().map((c) => (
        <CheckboxWithGtm
          key={c.countryCode}
          id="volumesQueryCountrySelectionPanelCheckbox"
          value={c.countryCode}
          label={c.countryName}
          checked={selectedCountries.includes(c.countryCode)}
          onChange={() => onSelectedCountriesChange(c.countryCode)}
        >
          {c.countryName}
        </CheckboxWithGtm>
      ))}

      <LoaderModal isOpen={isFetching} />
    </>
  )
}

export default React.forwardRef(VolumesQueryCountrySelectionPanel)
