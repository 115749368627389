import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppTextTranslations } from 'models/Translations/AppTextTranslations'
import { RootState } from 'redux/store'

const initialState = new AppTextTranslations()

export const translationsSlice = createSlice({
  name: 'translationsData',
  initialState,
  reducers: {
    setTranslations: (
      state,
      { payload }: PayloadAction<AppTextTranslations>
    ) => ({ ...initialState, ...payload }),
  },
})

export const { setTranslations } = translationsSlice.actions

export const getTranslations = (state: RootState): AppTextTranslations =>
  state.translations

export default translationsSlice.reducer
