import { JATOIcon, JATOText, JATOTooltip } from '@jato/ui-component-library'
import ElectricVehicleIcon from 'assets/icons/electric_vehicle.svg'
import IndustryNewsIcon from 'assets/icons/industry_news.svg'
import MonthlyPaymentsIcon from 'assets/icons/MP_alert.svg'
import NewVehiclesIcon from 'assets/icons/new_vehicles.svg'
import PromotionsAndIncentivesIcon from 'assets/icons/promotions.svg'
import { capitalizeFirstLetter } from 'helper/stringHelper'
import {
  getNewsCategoryTranslations,
  NewsCategoryType,
} from 'models/News/NewsCategoryType'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledNewsCategory } from './NewsCategory.styles'

interface INewsCategoryProps {
  categories: NewsCategoryType[]
}

export const NewsCategory: React.FC<INewsCategoryProps> = ({
  categories,
}: INewsCategoryProps) => {
  const translations = useAppSelector(getTranslations)
  const categoryTranslations = getNewsCategoryTranslations(translations)

  const categoryNames = categories
    .map((category) => categoryTranslations[category] ?? category)
    .join(', ')
  const firstCategory = categoryTranslations[categories[0]] ?? categories[0]

  const getCategoryIcon = (category: NewsCategoryType): JSX.Element => {
    switch (category) {
      case NewsCategoryType.PricingAndSpecifications:
        return <JATOIcon iconName="baseline_assessment" />
      case NewsCategoryType.NewVehicles:
        return <img src={NewVehiclesIcon} />
      case NewsCategoryType.IndustryNews:
        return <img src={IndustryNewsIcon} />
      case NewsCategoryType.EditorsChoice:
        return <img src={NewVehiclesIcon} />
      case NewsCategoryType.PromotionsAndIncentives:
        return <img src={PromotionsAndIncentivesIcon} />
      case NewsCategoryType.Volumes:
        return <img src={ElectricVehicleIcon} style={{ margin: '3px 3px 0' }} />
      case NewsCategoryType.MonthlyPayments:
        return <img src={MonthlyPaymentsIcon} />
      default:
        return <></>
    }
  }

  return (
    <StyledNewsCategory>
      {getCategoryIcon(categories[0])}
      <JATOTooltip title={categoryNames} showOnlyOnOverflow={false}>
        <JATOText
          as="div"
          fontWeight="medium"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {capitalizeFirstLetter(firstCategory)}
        </JATOText>
      </JATOTooltip>
    </StyledNewsCategory>
  )
}
