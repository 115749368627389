import { JATOLink, JATOText } from '@jato/ui-component-library'
import Loader from 'components/Loader'
import { CommonModal } from 'components/Modals/CommonModal'
import { useGetVolumesNewsArticles } from 'hooks/volumes'
import { VolumesNewsArticleRequest } from 'models/Volumes/VolumesNewsArticleRequest'
import NewsArticle from 'pages/NewsArticle'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  StyledVolumesNewsArticle,
  StyledVolumesNewsArticleLink,
} from './VolumesNewsArticleLink.styles'

interface IVolumesNewsArticleLinkProps {
  title: string
  request: VolumesNewsArticleRequest
}

export const VolumesNewsArticleLink: React.FC<IVolumesNewsArticleLinkProps> = ({
  title,
  request,
}: IVolumesNewsArticleLinkProps) => {
  const translations = useAppSelector(getTranslations)

  const [newsRequest, setNewsRequest] =
    React.useState<VolumesNewsArticleRequest>()

  const { data, isFetching } = useGetVolumesNewsArticles(newsRequest)

  const onOpen = (handleOpen: () => void): void => {
    setNewsRequest(request)
    handleOpen()
  }

  return (
    <StyledVolumesNewsArticleLink>
      <CommonModal
        maxWidth="lg"
        closeButton
        title={title}
        trigger={({ handleOpen }) => (
          <JATOLink
            id="volumesNewsArticleLink"
            onClick={() => onOpen(handleOpen)}
            className="newsArticleLink"
          >
            {title}
          </JATOLink>
        )}
        onClose={() => setNewsRequest(undefined)}
        primaryButtonName={translations.JNT_Close}
        onPrimaryClick={({ handleClose }) => handleClose()}
      >
        {isFetching ? (
          <Loader style={{ minHeight: '150px' }} />
        ) : data && data.articles && data.articles.length > 0 ? (
          <StyledVolumesNewsArticle>
            <NewsArticle
              articleId={data.articles[0].articleId}
              isVolumesNewsArticle={true}
            />
          </StyledVolumesNewsArticle>
        ) : (
          <JATOText as="div" textAlign="center">
            {translations.JNT_SRCH_NoArticlesFound}
          </JATOText>
        )}
      </CommonModal>
    </StyledVolumesNewsArticleLink>
  )
}
