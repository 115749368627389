/// <reference types="styled-components/cssprop" />
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBasketShopping,
  faCompressArrowsAlt,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faFileExcel,
  faImage,
  faNewspaper,
  faSquarePlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import {
  JATOAnnouncements,
  JATOToastContainer,
} from '@jato/ui-component-library'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from 'ApplicationInsightsService'
import Loader from 'components/Loader'
import { basename } from 'config'
import 'i18n'
import React, { Suspense } from 'react'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'router'
import { IConfig } from 'types/IConfig'
import './App.css'
import { GlobalStyle } from './App.styles'

library.add(
  faFileExcel,
  faSquarePlus,
  faImage,
  faTrashCan,
  faNewspaper,
  faBasketShopping,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faCompressArrowsAlt
)
/**
 * Version 7 of `react-grid-system` adds a new screen class `xxl` for very
 * large screens. This has consequences for our app so we need to opt out.
 */
setConfiguration({ maxScreenClass: 'xl' })
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      retry: 2,
      // Double delay (starting at 1000ms) with each attempt, but not exceed 30 seconds
      retryDelay: (attemptIndex) =>
        Math.min(1000 * 2 ** attemptIndex, 30 * 1000),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
})

// Merge our Window interface into the global scope
declare global {
  interface Window {
    config: IConfig
    amp: any
    Jimp: any
  }
}

export const amp = window.amp

export const App: React.FC = () => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <ScreenClassProvider>
      <Suspense fallback={<Loader />}>
        <GlobalStyle />
        <div className="announcements-container">
          <JATOAnnouncements />
        </div>
        <JATOToastContainer
          autoClose={3000}
          closeOnClick={true}
          draggable={true}
          hideProgressBar={true}
          newestOnTop={true}
          pauseOnFocusLoss={false}
          pauseOnHover={true}
          position="bottom-center"
        />
        <QueryClientProvider client={queryClient}>
          <Router basename={basename}>
            <Routes />
          </Router>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Suspense>
    </ScreenClassProvider>
  </AppInsightsContext.Provider>
)

export default App
