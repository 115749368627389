import {
  JATOBasicCard,
  JATOButton,
  JATOButtonGroup,
  JATOGroup,
  JATOHeading,
  JATOIcon,
  JATOText,
  JATOTheme,
  JATOTooltip,
} from '@jato/ui-component-library'
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { CheckboxWithGtm } from 'components/Gtm/CheckboxWithGtm'
import { GtmWrapper } from 'components/Gtm/GtmWrapper'
import Loader from 'components/Loader'
import { isNullOrEmpty } from 'helper/arrayHelper'
import { showErrorToast } from 'helper/toastHelper'
import {
  getActiveStep,
  getPathForStep,
  MaxPeriodsSectionsAllowed,
  volumesColourMapping,
} from 'helper/volumesHelper'
import { useGetPeriodDataPoints, useGetPeriodSelections } from 'hooks/volumes'
import { PeriodDataPoint } from 'models/Volumes/PeriodDataPoint'
import { PeriodDef } from 'models/Volumes/PeriodDef'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import { VolumesSelection } from 'models/Volumes/VolumesSelection'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesQueryState,
  setVolumesQueryState,
} from 'redux/volumes/volumesSlice'
import { RoutePaths } from 'router/RoutePaths'
import { StyledVolumesPeriodSelectionsStep } from './VolumesPeriodSelectionsStep.styles'

export const VolumesPeriodSelectionsStep: React.FC = () => {
  const history = useHistory()
  const queryState = useAppSelector(getVolumesQueryState)

  // if step is not ready, redirect to last ready step
  if (
    isNullOrEmpty(queryState.vehicleAttributes) &&
    isNullOrEmpty(queryState.outputPoints)
  ) {
    const step = getActiveStep(queryState)
    history.push(getPathForStep(step))
  }

  const translations = useAppSelector(getTranslations)

  const dispatch = useAppDispatch()

  const { data: periodSelections, isFetching } = useGetPeriodSelections(
    queryState.databaseType || VolumesDatabaseType.ModelMixSpecs
  )

  const { mutateAsync: getPeriodDataPoints, isLoading: isUpdating } =
    useGetPeriodDataPoints()

  const [selectedPeriod, setSelectedPeriod] = useState<
    VolumesSelection | undefined
  >(undefined)

  const [periodDataPoints, setPeriodDataPoints] = useState<PeriodDataPoint[]>(
    []
  )

  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  const getPeriodName = (period: PeriodDataPoint): string =>
    selectedPeriod?.dataPointGroup === '6' ||
    selectedPeriod?.dataPointGroup === '7' ||
    selectedPeriod?.dataPointGroup === '8'
      ? period.dgDescription + ' ' + selectedPeriod.longText
      : period.dgDescription

  const createNewPeriodDefinition = (
    dataPoint: PeriodDataPoint
  ): PeriodDef => ({
    periodIdentifier: dataPoint.dgGroupId,
    periodName: getPeriodName(dataPoint),
    concatString: '',
    periodSequence: periodDataPoints.findIndex(
      (dp) => dp.dgGroupId === dataPoint.dgGroupId
    ),
    periodGroup: selectedPeriod?.dataPointGroup || '',
  })

  const onPeriodSelectionClick = async (
    period: VolumesSelection
  ): Promise<void> => {
    setSelectedPeriod(period)
    const dataPoints = await getPeriodDataPoints({
      productGroup: queryState.databaseType || '',
      dataSetIds: queryState.dataSetIds || [],
      periodGroup: period.dataPointGroup + '_0',
    })

    setPeriodDataPoints(dataPoints)
    setSelectedIndex(-1)
  }

  const onDataPointSelectionClick = (
    dataPoint: PeriodDataPoint,
    index: number,
    multiSelect: boolean
  ): void => {
    const selectedDataPoints =
      multiSelect && selectedIndex !== -1
        ? periodDataPoints.slice(
            Math.min(index, selectedIndex),
            Math.max(index, selectedIndex) + 1
          )
        : [dataPoint]

    const currentPeriods = queryState.periods ?? []

    const isSelected = currentPeriods.some(
      (period) => period.periodIdentifier === dataPoint.dgGroupId
    )

    let updatedPeriods: PeriodDef[] = []
    if (isSelected) {
      // Remove selected datapoints
      updatedPeriods = currentPeriods.filter(
        (period) =>
          !selectedDataPoints.some(
            (dp) => period.periodIdentifier === dp.dgGroupId
          )
      )
    } else {
      updatedPeriods = currentPeriods.map((period: PeriodDef) => {
        const periodSequence: number =
          period.periodGroup === selectedPeriod?.dataPointGroup
            ? periodDataPoints.findIndex(
                (periodDataPoint: PeriodDataPoint) =>
                  periodDataPoint.dgGroupId.indexOf(
                    period.periodIdentifier.substring(1)
                  ) !== -1
              )
            : period.periodSequence

        return {
          periodIdentifier: period.periodIdentifier,
          periodName: period.periodName,
          concatString: period.concatString,
          periodSequence: periodSequence,
          periodGroup: period.periodGroup,
        }
      })

      // Add selected datapoints if doesn't exist
      selectedDataPoints
        .filter(
          (dp) =>
            !currentPeriods.some(
              (period) => period.periodIdentifier === dp.dgGroupId
            )
        )
        .forEach((dp) => {
          const newPeriod = createNewPeriodDefinition(dp)
          updatedPeriods = [...updatedPeriods, newPeriod]
        })
    }

    updatedPeriods.sort(comparePeriods)

    // Dispatch the query update with the new or updated periods list
    dispatch(
      setVolumesQueryState({
        ...queryState,
        periods: updatedPeriods,
      })
    )
    setSelectedIndex(index)
  }

  const getPeriodSelectedState = (period: PeriodDataPoint): boolean =>
    (queryState.periods &&
      queryState.periods.filter(
        (dp) => dp.periodIdentifier === period.dgGroupId
      )?.length > 0) ||
    false

  const onSelectAllClick = (): void => {
    const newPeriods = queryState.periods ? [...queryState.periods] : []

    periodDataPoints.forEach((dataPoint) => {
      const exists = newPeriods.some(
        (period) => period.periodIdentifier === dataPoint.dgGroupId
      )
      if (!exists) {
        const newPeriod = createNewPeriodDefinition(dataPoint)

        newPeriods.push(newPeriod)
      }
    })
    newPeriods.sort(comparePeriods)

    dispatch(
      setVolumesQueryState({
        ...queryState,
        periods: newPeriods,
      })
    )
  }

  const onClearChoicesClick = (): void => {
    const remainingPeriods = queryState.periods?.filter(
      (dataPoint) => selectedPeriod?.dataPointGroup !== dataPoint.periodGroup
    )

    dispatch(
      setVolumesQueryState({
        ...queryState,
        periods: remainingPeriods,
      })
    )

    setSelectedIndex(-1)
  }

  const onNextClick = (): void => {
    if (
      queryState.periods !== undefined &&
      queryState.periods?.length > MaxPeriodsSectionsAllowed
    ) {
      showErrorToast(
        translations.JNT_Error,
        translations.JNT_Volumes_PS_MaxPeriodsSectionsAllowedError +
          ' ' +
          MaxPeriodsSectionsAllowed
      )

      return
    }

    history.push(RoutePaths.VolumesReport)
  }

  const getTextColour = (period: PeriodDataPoint): string => {
    const selectionType = period.dgGroupId.slice(0, 1)

    return (
      volumesColourMapping.find((c) => c.type === selectionType)?.colour ||
      'black'
    )
  }

  const comparePeriods = (a: PeriodDef, b: PeriodDef): number => {
    const currentPeriods = queryState.periods ?? []
    const aGroupOrder = currentPeriods.findIndex(
      (p) => p.periodGroup === a.periodGroup
    )
    const bGroupOrder = currentPeriods.findIndex(
      (p) => p.periodGroup === b.periodGroup
    )
    // preserve original group order and sort from older to newer inside group
    // new groups will be added to the end of the list
    return aGroupOrder === bGroupOrder
      ? b.periodSequence - a.periodSequence
      : aGroupOrder === -1
        ? 1
        : bGroupOrder === -1
          ? -1
          : aGroupOrder - bGroupOrder
  }

  return (
    <StyledVolumesPeriodSelectionsStep>
      {isFetching ? (
        <Loader className="periodsLoader" />
      ) : (
        <JATOBasicCard
          footer={() => (
            <JATOButtonGroup justifyContent="flex-end">
              <JATOButton
                id="volumesPeriodSelectionsStepSelectAllButton"
                variant="secondary"
                onClick={onSelectAllClick}
                disabled={selectedPeriod === undefined}
              >
                {translations.JNT_Volumes_QG_SetQuerySelections}
              </JATOButton>
              <JATOButton
                id="volumesPeriodSelectionsStepClearChoicesButton"
                variant="secondary"
                onClick={onClearChoicesClick}
                disabled={selectedPeriod === undefined}
              >
                {translations.JNT_Volumes_QG_ClearSelections}
              </JATOButton>
              <JATOButton
                id="volumesPeriodSelectionsStepNextButton"
                variant="primary"
                onClick={onNextClick}
                disabled={
                  !queryState.periods || queryState.periods?.length === 0
                }
              >
                {translations.JNT_Volumes_QG_Next}
                <JATOIcon
                  iconName="baseline_arrow_forward"
                  iconSize={18}
                  style={{ marginLeft: JATOTheme.space[2] }}
                />
              </JATOButton>
            </JATOButtonGroup>
          )}
        >
          <div className="periodStepHeader">
            <JATOHeading as="h3" fontWeight="medium">
              {translations.JNT_Volumes_PS_PeriodSelections}
            </JATOHeading>
            <JATOTooltip
              title={
                <div>
                  {translations.JNT_Volumes_PS_SelectAPeriod}.
                  {translations.JNT_Volumes_PS_SelectAPeriod2}
                  <br />
                  {translations.JNT_Volumes_PS_SelectAPeriod3}
                  <br />
                  {translations.JNT_Volumes_PS_SelectAPeriod4}
                  <br />
                  {translations.JNT_Volumes_PS_SelectAPeriod5}
                  <br />
                  {translations.JNT_Volumes_PS_SelectAPeriod6}
                </div>
              }
              arrow={true}
              placement="left-start"
              showOnlyOnOverflow={false}
            >
              <JATOIcon iconName="baseline_info" />
            </JATOTooltip>
          </div>
          <JATOGroup flexDirection="row" className="periodStepContainer">
            <div>
              <div className="periodSelectionHeader">
                {translations.JNT_Volumes_PS_PeriodSelections}
              </div>
              <div className="periodSelectionContainer">
                <List dense={true}>
                  {periodSelections?.map((periodSelection, index) => (
                    <ListItem
                      disablePadding
                      key={index}
                      className="periodsListItem"
                    >
                      <GtmWrapper
                        id="volumesPeriodSelectionsGroupButton"
                        label={periodSelection.longText}
                        value={periodSelection.dataPointGroup}
                      >
                        <ListItemButton
                          onClick={() =>
                            onPeriodSelectionClick(periodSelection)
                          }
                        >
                          <JATOText as={'div'} className="periodSelectionText">
                            {periodSelection.longText}
                          </JATOText>
                        </ListItemButton>
                      </GtmWrapper>
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
            <div>
              <div className="periodSelectionHeader">
                {selectedPeriod?.longText}
              </div>
              {isUpdating ? (
                <Loader className="periodsLoader" />
              ) : (
                <div className="dataPointSelectionContainer">
                  <List dense={true}>
                    {periodDataPoints?.map((dataPoint, index) => (
                      <ListItem
                        disablePadding
                        key={index}
                        className="dataPointListItem"
                      >
                        <CheckboxWithGtm
                          onClick={(e) =>
                            onDataPointSelectionClick(
                              dataPoint,
                              index,
                              e.shiftKey
                            )
                          }
                          id="volumesPeriodSelectionsCheckbox"
                          value={dataPoint.dgGroupId}
                          label={dataPoint.dgDescription}
                          checked={getPeriodSelectedState(dataPoint)}
                        >
                          <ListItemText
                            primary={getPeriodName(dataPoint)}
                            style={{ color: getTextColour(dataPoint) }}
                          />
                        </CheckboxWithGtm>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </div>
          </JATOGroup>
        </JATOBasicCard>
      )}
    </StyledVolumesPeriodSelectionsStep>
  )
}
