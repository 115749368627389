import { RadioWithGtm } from 'components/Gtm/RadioWithGtm'
import { VolumesDatabaseType } from 'models/Volumes/VolumesDatabaseType'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { StyledVolumesDatabaseTypeSelection } from './VolumesDatabaseTypeSelection.styles'

interface IVolumesDatabaseTypeSelectionProps {
  value: VolumesDatabaseType
  onChange: (value: VolumesDatabaseType) => void
  isCountryGrouping?: boolean
}

export const VolumesDatabaseTypeSelection: React.FC<
  IVolumesDatabaseTypeSelectionProps
> = ({
  value,
  onChange,
  isCountryGrouping,
}: IVolumesDatabaseTypeSelectionProps) => {
  const translations = useAppSelector(getTranslations)

  const selectionOptions = [
    {
      value: VolumesDatabaseType.ModelMix,
      label: translations.JNT_Volumes_QG_M,
      visible: true,
    },
    {
      value: VolumesDatabaseType.SourceData,
      label: translations.JNT_Volumes_QG_V,
      visible: true,
    },
    {
      value: VolumesDatabaseType.ModelMixSpecs,
      label: translations.JNT_Volumes_QG_X,
      visible: !isCountryGrouping,
    },
  ]

  return (
    <StyledVolumesDatabaseTypeSelection>
      {selectionOptions
        .filter((o) => o.visible)
        .map((o) => (
          <RadioWithGtm
            key={o.value}
            id="volumesDatabaseTypeSelectionOption"
            value={o.value}
            label={o.label}
            checked={value == o.value}
            onChange={() => onChange(o.value)}
          >
            {o.label}
          </RadioWithGtm>
        ))}
    </StyledVolumesDatabaseTypeSelection>
  )
}
