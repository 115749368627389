import { numberOfAllowedAttempts, salesEnquiryEmail } from 'config'
import { LicenceExpiredStatus } from 'helper/portalHelper'
import { showErrorToast } from 'helper/toastHelper'
import { LoginRequest } from 'models/Login/LoginRequest'
import { PortalLoginResponse } from 'models/Login/PortalLogin/PortalLoginResponse'
import {
  ChangePasswordRequest,
  PasswordResponse,
} from 'models/Portal/ChangePasswordRequest'
import { CompanyState } from 'models/Portal/CustomerRegistration/CompanyState'
import { CountryState } from 'models/Portal/CustomerRegistration/CountryState'
import {
  CheckUserNameRequest,
  CustomerCountryRequest,
  UserDetailState,
} from 'models/Portal/CustomerRegistration/CustomerRegistrationDetail'
import {
  CustomerData,
  CustomerRegistrationResponse,
} from 'models/Portal/CustomerRegistration/CustomerRegistrationInfoResponse'
import {
  CustomerSupportRequest,
  CustomerSupportResponse,
} from 'models/Portal/CustomerSupport/CustomerSupportModel'
import {
  ForgotPasswordLinkRequest,
  ForgotPasswordLinkResponse,
} from 'models/Portal/ForgotPassword/ForgotPasswordModel'
import { ForgotPasswordResetRequest } from 'models/Portal/ForgotPassword/ForgotPasswordResetModel'
import { GetLanguageAndCurrencyResponse } from 'models/Portal/GetLanguageAndCurrencyResponse'
import {
  SalesEnquiryRequest,
  SalesEnquiryResponse,
} from 'models/Portal/SalesEnquiry/SalesEnquiryModel'
import { YourCommentsState } from 'models/Portal/YourCommentsState'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query'
import {
  resetInvalidPasswordAttempts,
  selectCommonUserData,
  setInvalidPasswordAttempts,
} from 'redux/commonUserData/commonUserDataSlice'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import {
  getVolumesUserState,
  setVolumesUserState,
} from 'redux/volumes/volumesSlice'
import portalService from 'services/Portal/PortalService'

const PortalLanguageAndCurrencyQueryKey = 'portalLanguageAndCurrency'
const LoadRegistrationInfoDataQueryKey = 'loadRegistrationInfoData'
const LoadSalesEnquiryInfoDataQueryKey = 'loadSalesEnquiryInfoData'
const LoadCustomerSupportInfoDataQueryKey = 'loadCustomerSupportInfoData'

export const useGetLanguageAndCurrency = (): UseQueryResult<
  GetLanguageAndCurrencyResponse,
  unknown
> =>
  useQuery([PortalLanguageAndCurrencyQueryKey], () =>
    portalService.getLanguageAndCurrencyData()
  )

export const usePortalLogin = (): UseMutationResult<
  PortalLoginResponse | undefined,
  unknown,
  LoginRequest,
  unknown
> => {
  const translations = useAppSelector(getTranslations)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dispatch = useAppDispatch()
  const { invalidPasswordAttempts } = useAppSelector(selectCommonUserData)

  return useMutation({
    mutationFn: async (loginRequest: LoginRequest) => {
      if (invalidPasswordAttempts >= numberOfAllowedAttempts) {
        const accountLockResponse = await portalService.lockAccount({
          userAccount: loginRequest.username,
          lockstate: true,
        })
        if (accountLockResponse.isSuccess) {
          dispatch(resetInvalidPasswordAttempts())
          showErrorToast(
            '',
            translations.JNT_Portal_Login_AccountLocked.replace('<br />', '')
          )
          return {
            recaptchaResponse: {
              isSuccess: false,
              responseCode: '9999',
              responseText: accountLockResponse.responseMessage,
            },
          }
        }
      }

      if (!executeRecaptcha) {
        showErrorToast(
          'Google Recaptcha error',
          'Execute recaptcha not yet available'
        )

        return {
          recaptchaResponse: {
            isSuccess: false,
            responseCode: '9999',
            responseText: 'Execute recaptcha not yet available',
          },
        }
      }

      const captchaToken = await executeRecaptcha('login')
      const captchaResponse = await portalService.getRecaptchaData({
        captcha: '',
        captchaToken: captchaToken,
        captchaType: 'recaptchaV3',
      })

      if (captchaResponse.isSuccess) {
        // Need to check if user is here for activating account
        if (loginRequest.guid) {
          const activateUserAccountResponse =
            await portalService.activateUserAccount({
              username: loginRequest.username,
              password: loginRequest.password,
              guid: loginRequest.guid,
            })
          if (!activateUserAccountResponse.isSuccess) {
            if (!activateUserAccountResponse.isValidCredentials) {
              showErrorToast(
                '',
                translations.JNT_Portal_Login_AuthenticationError
              )
              dispatch(setInvalidPasswordAttempts(invalidPasswordAttempts + 1))
            } else {
              showErrorToast(
                '',
                translations.JNT_Portal_Activate_Account_Link_Expired
              )
            }
            return {
              recaptchaResponse: {
                isSuccess: false,
                responseCode: '9999',
                responseText: 'Problem while activating user account',
              },
            }
          }
        }
        const data = await portalService.login(loginRequest)

        if (
          data.isAuthenticated &&
          data.licenceStatus === LicenceExpiredStatus
        ) {
          return {
            volumesUserState: data,
            recaptchaResponse: {
              isSuccess: false,
              responseCode: '9999',
              responseText:
                translations.JNT_Portal_LicenceExpired_Message.replace(
                  '{0}',
                  data.firstName
                )
                  .replace('{1}', data.lastName)
                  .replace('{2}', salesEnquiryEmail),
            },
          }
        }

        if (!data.isAuthenticated && data.isUserLocked) {
          showErrorToast('', translations.JNT_Portal_Login_Locked)
          return {
            recaptchaResponse: {
              isSuccess: false,
              responseCode: '9999',
              responseText: translations.JNT_Portal_Login_Locked,
            },
          }
        }

        if (!data.isAuthenticated) {
          dispatch(setInvalidPasswordAttempts(invalidPasswordAttempts + 1))

          showErrorToast('', translations.JNT_Portal_Login_AuthenticationError)
          return {
            recaptchaResponse: {
              isSuccess: false,
              responseCode: '9999',
              responseText: translations.JNT_Portal_Login_AuthenticationError,
            },
          }
        }

        dispatch(resetInvalidPasswordAttempts())

        return {
          volumesUserState: data,
        }
      } else {
        showErrorToast(
          'Google Recaptcha error',
          translations.JNT_Portal_Login_AccountLocked.replace('<br/>', '')
        )
        return {
          recaptchaResponse: {
            isSuccess: false,
            responseCode: captchaResponse.responseCode,
            responseText: captchaResponse.responseText,
          },
        }
      }
    },
  })
}

export const useUpdateAcceptLicenceData = (): UseMutationResult<
  boolean,
  unknown,
  void,
  unknown
> => {
  const dispatch = useAppDispatch()
  const volumesUserState = useAppSelector(getVolumesUserState)
  const mutation = useMutation({
    mutationFn: () => portalService.saveAcceptLicenseSetting(),
    onSuccess: () => {
      const updatedUserState = {
        ...volumesUserState,
        acceptLicence: 1,
      }
      dispatch(setVolumesUserState(updatedUserState))
    },
  })
  return mutation
}

export const useLoadRegistrationInfoData = (): UseQueryResult<
  CustomerData,
  unknown
> =>
  useQuery([LoadRegistrationInfoDataQueryKey], () =>
    portalService.loadRegistrationInfoData()
  )

export const useLoadCustomerCountries = (): UseMutationResult<
  CountryState[],
  unknown,
  CustomerCountryRequest,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: async (customerCountryRequest: CustomerCountryRequest) =>
      portalService.loadCustomerCountries(customerCountryRequest),
  })
  return mutation
}

export const useLoadCompanies = (): UseMutationResult<
  CompanyState[],
  unknown,
  CustomerCountryRequest,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: async (customerCountryRequest: CustomerCountryRequest) =>
      portalService.loadCompanies(customerCountryRequest),
  })
  return mutation
}

export const useCheckUserName = (): UseMutationResult<
  boolean,
  unknown,
  CheckUserNameRequest,
  unknown
> => {
  const mutation = useMutation({
    mutationFn: async (checkUserNameRequest: CheckUserNameRequest) =>
      portalService.checkUserName(checkUserNameRequest),
  })
  return mutation
}

export const useRegisterCustomer = (): UseMutationResult<
  CustomerRegistrationResponse,
  unknown,
  UserDetailState,
  unknown
> => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const translations = useAppSelector(getTranslations)
  return useMutation({
    mutationFn: async (customerDetail: UserDetailState) => {
      if (!executeRecaptcha) {
        showErrorToast(
          'Google Recaptcha error',
          'Execute recaptcha not yet available'
        )
        return {
          isSuccess: false,
          responseMessage:
            'Google Recaptcha error - Execute recaptcha not yet available',
        }
      }
      const checkUserName = await portalService.checkUserName({
        lgId: customerDetail.lgId.toString(),
        emailAddress: customerDetail.emailAddress,
      })

      if (checkUserName) {
        const captchaToken = await executeRecaptcha('customerRegistration')
        const captchaResponse = await portalService.getRecaptchaData({
          captcha: '',
          captchaToken: captchaToken,
          captchaType: 'recaptchaV3',
        })

        if (captchaResponse.isSuccess) {
          const registrationResponse =
            await portalService.registerCustomer(customerDetail)
          if (registrationResponse) {
            return {
              isSuccess: registrationResponse,
              responseMessage: translations.JNT_Portal_Pop_regSuccess10,
            }
          } else {
            return {
              isSuccess: registrationResponse,
              responseMessage: translations.JNT_Portal_PopError_RegFailure,
            }
          }
        } else {
          showErrorToast('', captchaResponse.responseText)
          return {
            isSuccess: false,
            responseMessage: captchaResponse.responseText,
          }
        }
      } else {
        showErrorToast(
          '',
          translations.JNT_Portal_CustReg_UserName_Exist_Message
        )
        return {
          isSuccess: false,
          responseMessage:
            translations.JNT_Portal_CustReg_UserName_Exist_Message,
        }
      }
    },
  })
}

export const useLoadSalesEnquiryInfoData = (): UseQueryResult<CustomerData> => {
  const { languageId } = useAppSelector(selectCommonUserData)
  return useQuery([LoadSalesEnquiryInfoDataQueryKey], () =>
    portalService.loadSalesEnquiryInfoData({ languageId: languageId })
  )
}

export const useSubmitSalesEnquiry = (): UseMutationResult<
  SalesEnquiryResponse,
  unknown,
  SalesEnquiryRequest,
  unknown
> => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const translations = useAppSelector(getTranslations)
  return useMutation({
    mutationFn: async (salesEnquiryRequest: SalesEnquiryRequest) => {
      if (!executeRecaptcha) {
        showErrorToast(
          'Google Recaptcha error',
          'Execute recaptcha not yet available'
        )
        return {
          responseMessage:
            'Google Recaptcha error - Execute recaptcha not yet available',
        }
      }
      const captchaToken = await executeRecaptcha('salesEnquiry')
      const captchaResponse = await portalService.getRecaptchaData({
        captcha: '',
        captchaToken: captchaToken,
        captchaType: 'recaptchaV3',
      })

      if (captchaResponse.isSuccess) {
        const salesEnquiryResponse =
          await portalService.submitSalesEnquiry(salesEnquiryRequest)
        if (salesEnquiryResponse) {
          return {
            isSalesEnquirySuccess: salesEnquiryResponse,
            responseMessage: translations.JNT_Portal_Pop_QueryUpdated,
          }
        } else {
          return {
            isSalesEnquirySuccess: salesEnquiryResponse,
            responseMessage: translations.JNT_Portal_PopError_RegFailure,
          }
        }
      } else {
        showErrorToast('', captchaResponse.responseText)
        return {
          responseMessage: captchaResponse.responseText,
        }
      }
    },
  })
}

export const useLoadSupportInfoData = (): UseQueryResult<CustomerData> => {
  const { languageId } = useAppSelector(selectCommonUserData)
  return useQuery([LoadCustomerSupportInfoDataQueryKey], () =>
    portalService.loadSupportInfoData({ languageId: languageId })
  )
}

export const useSubmitCustomerSupportQuery = (): UseMutationResult<
  CustomerSupportResponse,
  unknown,
  CustomerSupportRequest,
  unknown
> => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const translations = useAppSelector(getTranslations)
  return useMutation({
    mutationFn: async (customerSupportRequest: CustomerSupportRequest) => {
      if (!executeRecaptcha) {
        showErrorToast(
          'Google Recaptcha error',
          'Execute recaptcha not yet available'
        )
        return {
          responseMessage:
            'Google Recaptcha error - Execute recaptcha not yet available',
        }
      }
      const captchaToken = await executeRecaptcha('customerSupport')
      const captchaResponse = await portalService.getRecaptchaData({
        captcha: '',
        captchaToken: captchaToken,
        captchaType: 'recaptchaV3',
      })

      if (captchaResponse.isSuccess) {
        const customerSupportResponse =
          await portalService.submitCustomerSupportRequest(
            customerSupportRequest
          )
        if (customerSupportResponse) {
          return {
            isCustomerSupportSuccess: customerSupportResponse,
            responseMessage: translations.JNT_Portal_Pop_QueryUpdated,
          }
        } else {
          showErrorToast('', translations.JNT_Portal_PopError_RegFailure)
          return {
            isCustomerSupportSuccess: customerSupportResponse,
            responseMessage: translations.JNT_Portal_PopError_RegFailure,
          }
        }
      } else {
        showErrorToast('', captchaResponse.responseText)
        return {
          responseMessage: captchaResponse.responseText,
        }
      }
    },
  })
}

export const useGetPasswordResetLink = (): UseMutationResult<
  ForgotPasswordLinkResponse,
  unknown,
  ForgotPasswordLinkRequest,
  unknown
> => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const translations = useAppSelector(getTranslations)

  return useMutation({
    mutationFn: async (
      forgotPasswordLinkRequest: ForgotPasswordLinkRequest
    ) => {
      if (!executeRecaptcha) {
        showErrorToast(
          'Google Recaptcha error',
          'Execute recaptcha not yet available'
        )
        return {
          isSuccess: false,
          responseMessage:
            'Google Recaptcha error - Execute recaptcha not yet available',
        }
      }
      const captchaToken = await executeRecaptcha('forgotPasswordResetLink')
      const captchaResponse = await portalService.getRecaptchaData({
        captcha: '',
        captchaToken: captchaToken,
        captchaType: 'recaptchaV3',
      })

      if (captchaResponse.isSuccess) {
        const passwordResetLinkResponse =
          await portalService.getPasswordResetLink(forgotPasswordLinkRequest)
        if (passwordResetLinkResponse.isSuccess) {
          return {
            isSuccess: passwordResetLinkResponse.isSuccess,
            responseMessage: translations.JNT_Portal_Pop_QueryUpdated,
          }
        } else {
          showErrorToast('', passwordResetLinkResponse.responseMessage)
          return {
            isSuccess: passwordResetLinkResponse.isSuccess,
            responseMessage: translations.JNT_Portal_PopError_RegFailure,
          }
        }
      } else {
        showErrorToast('', captchaResponse.responseText)
        return {
          isSuccess: false,
          responseMessage: captchaResponse.responseText,
        }
      }
    },
  })
}

export const useResetPassword = (): UseMutationResult<
  ForgotPasswordLinkResponse,
  unknown,
  ForgotPasswordResetRequest,
  unknown
> => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  return useMutation({
    mutationFn: async (
      forgotPasswordResetRequest: ForgotPasswordResetRequest
    ) => {
      if (!executeRecaptcha) {
        showErrorToast(
          'Google Recaptcha error',
          'Execute recaptcha not yet available'
        )
        return {
          isSuccess: false,
          responseMessage:
            'Google Recaptcha error - Execute recaptcha not yet available',
        }
      }
      const captchaToken = await executeRecaptcha('forgotPasswordReset')
      const captchaResponse = await portalService.getRecaptchaData({
        captcha: '',
        captchaToken: captchaToken,
        captchaType: 'recaptchaV3',
      })

      if (captchaResponse.isSuccess) {
        const passwordResetResponse = await portalService.resetPassword(
          forgotPasswordResetRequest
        )
        if (passwordResetResponse.isSuccess) {
          return {
            isSuccess: passwordResetResponse.isSuccess,
            responseMessage: passwordResetResponse.responseMessage,
          }
        } else {
          showErrorToast('', passwordResetResponse.responseMessage)
          return {
            isSuccess: passwordResetResponse.isSuccess,
            responseMessage: passwordResetResponse.responseMessage,
          }
        }
      } else {
        showErrorToast('Google Recaptcha error', captchaResponse.responseText)
        return {
          isSuccess: false,
          responseMessage: captchaResponse.responseText,
        }
      }
    },
  })
}

export const useLogOutAll = (): UseMutationResult<
  boolean,
  unknown,
  void,
  unknown
> => {
  const { guid } = useAppSelector(selectCommonUserData)
  return useMutation({
    mutationFn: async () => {
      if (guid) {
        const isValidGuid = await portalService.validateUserGuid(guid)
        if (isValidGuid) {
          return await portalService.logOutAll()
        }
      }

      return false
    },
  })
}

export const useValidateUserGuid = (): UseMutationResult<
  boolean,
  unknown,
  string,
  unknown
> =>
  useMutation({
    mutationFn: async (guid: string) =>
      await portalService.validateUserGuid(guid),
  })

export const useGetCountries = (
  languageId: string
): UseQueryResult<CountryState[], unknown> =>
  useQuery([languageId], () => portalService.getCountries({ languageId }))

export const useSubmitComments = (): UseMutationResult<
  boolean,
  unknown,
  YourCommentsState,
  unknown
> =>
  useMutation({
    mutationFn: async (request: YourCommentsState) =>
      await portalService.submitComments(request),
  })

export const useChangePassword = (): UseMutationResult<
  PasswordResponse,
  unknown,
  ChangePasswordRequest,
  unknown
> =>
  useMutation({
    mutationFn: async (request: ChangePasswordRequest) =>
      await portalService.changePassword(request),
  })
