import { JATOTheme } from '@jato/ui-component-library'
import { Accordion } from 'components/Accordion/Accordion'
import VolumesNavigation from 'components/Volumes/VolumesNavigation'
import { VolumesQueriesGrid } from 'components/Volumes/VolumesQueriesGrid/VolumesQueriesGrid'
import { VolumesQueryColumnType } from 'helper/volumesHelper'
import { useLoadAllQueries } from 'hooks/volumes'
import { VolumesUserState } from 'models/Login/VolumesLogin/VolumesUserState'
import React from 'react'
import { useAppSelector } from 'redux/hook'
import { getTranslations } from 'redux/translations/translationsSlice'
import { getVolumesUserState } from 'redux/volumes/volumesSlice'
import { StyledVolumesMyQueries } from './VolumesMyQueries.styles'

export const VolumesMyQueries: React.FC = () => {
  const userData: VolumesUserState = useAppSelector(getVolumesUserState)
  const translations = useAppSelector(getTranslations)

  const { data: queries } = useLoadAllQueries()

  const myQueries = queries?.filter((x) => x.qryOwnerId == userData.userId)
  const groupQueries = queries?.filter(
    (x) => x.qryShared && x.qryOwnerId != userData.userId
  )

  return (
    <StyledVolumesMyQueries>
      <VolumesNavigation title={translations.JNT_Volumes_MJ_QueryName} />
      <div className="volumesContainer">
        <Accordion
          id="volumesMyQueriesYourQueriesAccordion"
          titleText={translations.JNT_Volumes_MQ_Query_Your_Queries}
          isOpen={true}
          titleHeaderWidth="100%"
          titleBackgroundColour={JATOTheme.colors.grey}
        >
          <VolumesQueriesGrid
            id="volumesYourQueriesGrid"
            queries={myQueries}
            columns={[
              VolumesQueryColumnType.QueryId,
              VolumesQueryColumnType.SearchName,
              VolumesQueryColumnType.DateCreated,
              VolumesQueryColumnType.DateModified,
              VolumesQueryColumnType.LastRan,
              VolumesQueryColumnType.Share,
              VolumesQueryColumnType.Delete,
              VolumesQueryColumnType.Rename,
            ]}
          />
        </Accordion>

        <br />

        <Accordion
          id="volumesMyQueriesSharedQueriesAccordion"
          titleText={translations.JNT_Volumes_MQ_Query_Queries_Shared_With_You}
          isOpen={true}
          titleHeaderWidth="100%"
          titleBackgroundColour={JATOTheme.colors.grey}
        >
          <VolumesQueriesGrid
            id="volumesSharedQueriesGrid"
            queries={groupQueries}
            columns={[
              VolumesQueryColumnType.QueryId,
              VolumesQueryColumnType.SearchName,
              VolumesQueryColumnType.DateCreated,
              VolumesQueryColumnType.DateModified,
              VolumesQueryColumnType.LastRan,
            ]}
          />
        </Accordion>
      </div>
    </StyledVolumesMyQueries>
  )
}
