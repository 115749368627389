import { JATOButtonIcon } from '@jato/ui-component-library'
import NewsSearchAlertEditor from 'components/News/NewsSearchAlertEditor'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import React, { useState } from 'react'
import { StyledNewsAlertEditButton } from './NewsAlertEditButton.styles'

export interface INewsAlertEditButtonProps {
  searchRequest: SearchIndexRequest
}

export const NewsAlertEditButton: React.FC<INewsAlertEditButtonProps> = ({
  searchRequest,
}: INewsAlertEditButtonProps) => {
  const [showCreateAlertWindow, setCreateAlertWindowVisible] = useState(false)

  return (
    <StyledNewsAlertEditButton>
      <JATOButtonIcon
        id="newsAlertEditButton"
        iconName="custom_edit"
        onClick={() => setCreateAlertWindowVisible((prevState) => !prevState)}
      />
      {showCreateAlertWindow && (
        <NewsSearchAlertEditor
          showWindow={showCreateAlertWindow}
          setWindowState={setCreateAlertWindowVisible}
          searchIndexRequest={searchRequest}
        />
      )}
    </StyledNewsAlertEditButton>
  )
}
