import React from 'react'
import { StyledImgButton } from './ImgButton.styles'

export interface IImgButtonProps {
  id: string
  src: string
  title: string
  onClick: () => void
}

export const ImgButton: React.FC<IImgButtonProps> = ({
  id,
  src,
  title,
  onClick,
}: IImgButtonProps) => (
  <StyledImgButton onClick={onClick} id={id} src={src} title={title} />
)
