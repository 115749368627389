import styled from 'styled-components'

export const StyledSpecificationsReport = styled.div`
  .ej-specsreport-panel {
    background-color: #ffffff;
    box-shadow:
      0 3px 6px rgb(0 0 0 / 16%),
      0 3px 6px rgb(0 0 0 / 23%);
    overflow: scroll;
    width: 100%;
    height: calc(100vh - 80px - var(--app-header-height));
  }
  .ej-specsreport-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    height: 30px;
    padding-top: 10px;
  }
  .ej-specsreport-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #1b1d1f;
  }

  .ej-panel-left {
    display: flex;
    justify-content: flex-end;
  }

  .basket-icon-container {
    cursor: pointer;
    position: relative;
    outline: none;
    box-shadow: none;
    z-index: -1;
  }
`
