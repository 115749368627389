import styled from 'styled-components'

export const StyledNewsAdvancedSearch = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;

  .searchText {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
  }

  .searchText .jato-checkbox {
    margin-top: 30px;
  }
`
