import { mpReportGroupingAndOrderLayout, mpReportSpecsSection } from 'config'
import { removeDuplicates } from './arrayHelper'
import { logError } from './loggingService'

export const validateMetadata = (metadataObject: any): void => {
  if (!metadataObject) {
    logError('Missing metadata object')
  }

  const expectedMetadata = [
    'country',
    ...mpReportSpecsSection.metadataRowNames,
    ...mpReportGroupingAndOrderLayout
      .filter((m) => !m.isCategoryTitleRow)
      .map((m) => m.metadataRowName),
  ]

  const missingMetadata = expectedMetadata
    .filter((m) => !metadataObject.hasOwnProperty(m))
    .filter(removeDuplicates)

  if (missingMetadata.length > 0) {
    logError(`Missing metadata properties: ${missingMetadata.join()}`)
  }
}
