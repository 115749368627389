import { queryClient } from 'app'
import { MpAlertsBetaState } from 'models/Login/NewsLogin/MpAlertsBetaState'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query'
import { selectCommonUserData } from 'redux/commonUserData/commonUserDataSlice'
import { useAppSelector } from 'redux/hook'
import mpAlertsBetaService from 'services/News/MpAlertsBetaService'
import {
  ArticlesByCategoryQueryKey,
  HomepageArticlesQueryKey,
  SearchArticlesQueryKey,
} from './news'
import { useRootPathResolver } from './useRootPathResolver'

const MpAlertsBetaStateQueryKey = 'mpAlertsBetaState'

export const useGetMpAlertsBetaState = (): UseQueryResult<
  MpAlertsBetaState,
  unknown
> => {
  const { isNews } = useRootPathResolver()
  const { guid } = useAppSelector(selectCommonUserData)
  const isAuthenticated = !!guid

  return useQuery(
    [MpAlertsBetaStateQueryKey],
    () => mpAlertsBetaService.getMpAlertsBetaState(),
    { enabled: isNews && isAuthenticated }
  )
}

export const useActivateMpAlertsBeta = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> =>
  useMutation({
    mutationFn: (isActivated: boolean) =>
      mpAlertsBetaService.activate(isActivated),
    onSuccess: (isSuccess: boolean, isActivated: boolean) => {
      isSuccess && queryClient.invalidateQueries(MpAlertsBetaStateQueryKey)
      if (isSuccess && isActivated) {
        queryClient.invalidateQueries(HomepageArticlesQueryKey)
        queryClient.invalidateQueries(ArticlesByCategoryQueryKey)
        queryClient.invalidateQueries(SearchArticlesQueryKey)
      }
    },
  })

export const useEnableMpAlertsBeta = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> =>
  useMutation({
    mutationFn: (isEnabled: boolean) => mpAlertsBetaService.enable(isEnabled),
    onSuccess: (isSuccess: boolean) => {
      if (isSuccess) {
        queryClient.invalidateQueries(MpAlertsBetaStateQueryKey)
        queryClient.invalidateQueries(HomepageArticlesQueryKey)
        queryClient.invalidateQueries(ArticlesByCategoryQueryKey)
        queryClient.invalidateQueries(SearchArticlesQueryKey)
      }
    },
  })

export const useHideMpAlertsBetaWelcomePopup = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> =>
  useMutation({
    mutationFn: (isHidden: boolean) =>
      mpAlertsBetaService.hideWelcomePopup(isHidden),
    onSuccess: (isSuccess: boolean) => {
      isSuccess && queryClient.invalidateQueries(MpAlertsBetaStateQueryKey)
    },
  })

export const useHideMpAlertsBetaActivationPopup = (): UseMutationResult<
  boolean,
  unknown,
  boolean,
  unknown
> =>
  useMutation({
    mutationFn: (isHidden: boolean) =>
      mpAlertsBetaService.hideActivationPopup(isHidden),
    onSuccess: (isSuccess: boolean) => {
      isSuccess && queryClient.invalidateQueries(MpAlertsBetaStateQueryKey)
    },
  })
