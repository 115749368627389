import styled from 'styled-components'

export const StyledMonthlyPaymentsScenerios = styled.div`
  .ej-mpreport-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    height: 40px;
    padding-top: 10px;
  }
  .ej-mpreport-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: #1b1d1f;
  }

  .ej-mpreport-panel {
    background-color: #ffffff;
    box-shadow:
      0 3px 6px rgb(0 0 0 / 16%),
      0 3px 6px rgb(0 0 0 / 23%);
    overflow: scroll;
    height: calc(100vh - 80px - var(--app-header-height));
  }

  .ej-panel-left {
    display: flex;
    justify-content: flex-end;
  }
  .nav-container > div {
    gap: 5px;
  }
`
