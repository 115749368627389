import { JATOLink } from '@jato/ui-component-library'
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  Inject,
  Page,
  PageSettingsModel,
  Resize,
} from '@syncfusion/ej2-react-grids'
import Loader from 'components/Loader'
import { IMappingObject } from 'helper/IMappingObject'
import {
  NewsFilterName,
  defaultArticlesInGridNumber,
  defaultToDate,
  getAlertsSubscriptionTypeName,
  getColumnWidth,
  getCountryNamesFromCodes,
  getMakeOrModelDisplayValues,
  twelveMonthFromDateOption,
} from 'helper/newsHelper'
import {
  useGetLanguagesByCountryCode,
  useGetVehicleTypes,
  useUpdateSearchAndAlert,
} from 'hooks/news'
import { SearchIndexRequest } from 'models/News/Homepage/NewsArticleRequest'
import {
  getCategoriesBySubjects,
  getNewsCategoryTranslations,
} from 'models/News/NewsCategoryType'
import { NewsLanguageByCountryCode } from 'models/News/NewsLanguageByCountryCode'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { getNewsCountries, setNewsSearchState } from 'redux/news/newsSlice'
import { getTranslations } from 'redux/translations/translationsSlice'
import { RoutePaths } from 'router/RoutePaths'
import { NewsAlertActions } from '../Buttons/NewsAlertButton/NewsAlertActions'
import { NewsAlertStatusToggleButton } from '../Buttons/NewsAlertButton/NewsAlertStatusToggleButton'
import { NewsSavedSearchActions } from '../Buttons/NewsSavedSearchButton/NewsSavedSearchActions'
import NewsPagination from '../NewsPagination'
import { StyledINewsSavedSearchAndAlertGrid } from './NewsSavedSearchAndAlertGrid.styles'

export enum NewsSavedSearchAndAlertColumnType {
  Id,
  Name,
  AlertName,
  Category,
  Make,
  Model,
  VehicleType,
  Country,
  Language,
  Frequency,
  AlertStartDate,
  Status,
  SearchActions,
  AlertActions,
  LastRunDate,
}

export interface INewsSavedSearchAndAlertGrid {
  savedSearches: SearchIndexRequest[]
  columns: NewsSavedSearchAndAlertColumnType[]
}

export const NewsSavedSearchAndAlertGrid: React.FC<
  INewsSavedSearchAndAlertGrid
> = ({ savedSearches, columns }: INewsSavedSearchAndAlertGrid) => {
  const [currentPage, setCurrentPage] = useState(1)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const translations = useAppSelector(getTranslations)
  const countries = useAppSelector(getNewsCountries)
  const categoryTranslations = getNewsCategoryTranslations(translations)

  const { data: languages } = useGetLanguagesByCountryCode()

  const { data: vehicleTypes } = useGetVehicleTypes()

  const { mutate: updateSearchAndAlert } = useUpdateSearchAndAlert()

  let gridInstance: Grid | null

  const getValueAccessor =
    (getDisplayValueCallback: (value: any) => string) =>
    (_field: string, data: object): string =>
      getDisplayValueCallback((data as any)[_field])

  const getMakesDisplayValue = (makeList: string[]): string =>
    getMakeOrModelDisplayValues(makeList, translations.JNT_AllMakes)

  const getModelsDisplayValue = (makeList: string[]): string =>
    getMakeOrModelDisplayValues(makeList, translations.JNT_AllModels)

  const getCountriesDisplayValue = (countryList: string[]): string =>
    getCountryNamesFromCodes(countryList, countries, translations)

  const getVehicleTypeDisplayValue = (vehicleTypeList: string[]): string =>
    vehicleTypeList.length > 0
      ? vehicleTypeList
          .map((v) => vehicleTypes?.find((t) => t.key === v)?.sourceText ?? v)
          .join(',')
      : translations.JNT_AllTypes

  const getFrequencyDisplayValue = (subscriptionType: number): string =>
    getAlertsSubscriptionTypeName(translations).get(subscriptionType) ?? ''

  const getLanguageDisplayValue = (languageId: number): string =>
    languages
      ? languages.filter(
          (x: NewsLanguageByCountryCode) => x.languageId == languageId
        )[0]?.languageName ?? languageId
      : ''

  const getCategoryDisplayValue = (subjectList: string[]): string =>
    subjectList.length > 0
      ? getCategoriesBySubjects(subjectList)
          .map((category) => categoryTranslations[category] ?? category)
          .join(', ')
      : translations.JNT_AllCategories

  const getFormattedDate = (_field: string, data: object): string => {
    const { lastRun, startDate } = data as SearchIndexRequest
    switch (_field) {
      case 'lastRun':
        return lastRun?.substring(0, 10) ?? ''
      case 'startDate':
        return startDate?.substring(0, 10) ?? ''
      default:
        return ''
    }
  }

  const alertActionsTemplate = (
    searchRequest: SearchIndexRequest
  ): JSX.Element => <NewsAlertActions searchRequest={searchRequest} />

  const savedSearchActionsTemplate = (
    searchRequest: SearchIndexRequest
  ): JSX.Element => <NewsSavedSearchActions searchRequest={searchRequest} />

  const statusToggleTemplate = (
    searchRequest: SearchIndexRequest
  ): JSX.Element => (
    <NewsAlertStatusToggleButton searchRequest={searchRequest} />
  )

  const searchLinkTemplate = (
    searchRequest: SearchIndexRequest
  ): JSX.Element => (
    <JATOLink
      id="newsSavedSearchGridLink"
      onClick={() => loadSearchResults(searchRequest)}
      className="searchLink"
    >
      {searchRequest.description}
    </JATOLink>
  )

  const loadSearchResults = (request: SearchIndexRequest): void => {
    const searchFields: IMappingObject<string[]> = {
      [NewsFilterName.SearchText]: [request.searchQuery],
      [NewsFilterName.Category]: request.subjectCategoryCode
        ? request.subjectCategoryCode.split(',')
        : [],
      [NewsFilterName.Subjects]: request.subjectList,
      [NewsFilterName.Country]: request.countryList,
      [NewsFilterName.Make]: request.makeList,
      [NewsFilterName.Model]: request.modelList,
      [NewsFilterName.ReleaseDate]: [
        JSON.stringify([twelveMonthFromDateOption(), defaultToDate()]),
      ],
      [NewsFilterName.Group]: request.vehicleTypeList,
    }

    updateSearchAndAlert({
      ...request,
      loadCount: (request.loadCount ?? 0) + 1,
      lastRun: new Date().toISOString(),
    })

    const { searchQuery, isExactSearch } = request
    dispatch(
      setNewsSearchState({
        searchFields: searchFields,
        isExactMatch: request.isExactSearch,
        orderBy: searchQuery && !isExactSearch ? [] : ['releaseDate desc'],
        currentPage: 1,
      })
    )

    history.push(RoutePaths.NewsSearchResults)
  }
  const defaultColumnWidthPercentage = 5
  const defaultColumnAlign = 'Left'
  const defaultClipMode = 'Clip'

  const columnDefinitions = [
    {
      id: NewsSavedSearchAndAlertColumnType.Id,
      field: 'id',
      isPrimaryKey: true,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Name,
      field: 'description',
      headerText: translations.JNT_MJN_QrySDesc,
      template: searchLinkTemplate,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.AlertName,
      field: 'description',
      headerText: translations.JNT_AlertName,
      template: searchLinkTemplate,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Category,
      field: 'subjectList',
      headerText: translations.JNT_News_Category,
      valueAccessor: getValueAccessor(getCategoryDisplayValue),
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Country,
      field: 'countryList',
      headerText: translations.JNT_Country,
      valueAccessor: getValueAccessor(getCountriesDisplayValue),
    },
    {
      id: NewsSavedSearchAndAlertColumnType.LastRunDate,
      field: 'lastRun',
      headerText: translations.JNT_NWS_Date,
      valueAccessor: getFormattedDate,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Make,
      field: 'makeList',
      headerText: translations.JNT_make,
      width: getColumnWidth(6),
      valueAccessor: getValueAccessor(getMakesDisplayValue),
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Model,
      field: 'modelList',
      headerText: translations.JNT_model,
      width: getColumnWidth(6),
      valueAccessor: getValueAccessor(getModelsDisplayValue),
    },
    {
      id: NewsSavedSearchAndAlertColumnType.VehicleType,
      field: 'vehicleTypeList',
      headerText: translations.JNT_VehicleType,
      width: getColumnWidth(6),
      valueAccessor: getValueAccessor(getVehicleTypeDisplayValue),
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Language,
      field: 'languageId',
      headerText: translations.JNT_Language,
      width: getColumnWidth(8),
      valueAccessor: getValueAccessor(getLanguageDisplayValue),
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Frequency,
      field: 'subscriptionType',
      headerText: translations.JNT_SRCH_EmailSubscription,
      valueAccessor: getValueAccessor(getFrequencyDisplayValue),
    },

    {
      id: NewsSavedSearchAndAlertColumnType.AlertStartDate,
      field: 'startDate',
      headerText: translations.JNT_AlertStartDate,
      width: getColumnWidth(6),
      valueAccessor: getFormattedDate,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.Status,
      field: 'isSubscribed',
      headerText: translations.JNT_Status,
      template: statusToggleTemplate,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.AlertActions,
      headerText: translations.JNT_Action,
      width: getColumnWidth(3),
      template: alertActionsTemplate,
    },
    {
      id: NewsSavedSearchAndAlertColumnType.SearchActions,
      headerText: translations.JNT_Action,
      width: getColumnWidth(3),
      template: savedSearchActionsTemplate,
    },
  ]
  const onPageChange = (page: number): void => {
    if (gridInstance) {
      gridInstance.pageSettings.currentPage = page
      setCurrentPage(page)
    }
  }

  const pageSettings: PageSettingsModel = {
    pageSize: defaultArticlesInGridNumber,
  }

  const totalPages = Math.ceil(
    savedSearches.length / defaultArticlesInGridNumber
  )

  const paginationComponent = (
    <NewsPagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={(page) => onPageChange(page)}
    />
  )

  useEffect(() => {
    gridInstance && gridInstance.refresh()
  }, [columns])

  return (
    <StyledINewsSavedSearchAndAlertGrid>
      {paginationComponent}
      {languages ? (
        <GridComponent
          ref={(g) => (gridInstance = g)}
          id="grid"
          dataSource={savedSearches}
          loadingIndicator={{ indicatorType: 'Shimmer' }}
          allowResizing={true}
          pageSettings={pageSettings}
          allowPaging={true}
        >
          <ColumnsDirective>
            {columnDefinitions.map((c, index) => (
              <ColumnDirective
                key={index}
                field={c.field}
                headerText={c.headerText}
                width={c.width ?? getColumnWidth(defaultColumnWidthPercentage)}
                textAlign={defaultColumnAlign}
                clipMode={defaultClipMode}
                isPrimaryKey={c.isPrimaryKey ?? false}
                visible={columns.includes(c.id)}
                valueAccessor={c.valueAccessor}
                template={c.template}
              />
            ))}
          </ColumnsDirective>
          <Inject services={[Resize, Page]} />
        </GridComponent>
      ) : (
        <Loader style={{ minHeight: '70vh' }} />
      )}
      {paginationComponent}
    </StyledINewsSavedSearchAndAlertGrid>
  )
}
