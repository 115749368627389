import { SliderFilter } from 'components/MonthlyPayments/Filters/SliderFilter'
import { ISliderFilter } from 'components/MonthlyPayments/Filters/SliderFilter/SliderFilter'
import { gtmLogSelectedValueChange } from 'helper/gtm'
import React from 'react'
import { GtmWrapper } from './GtmWrapper'

interface IGtmSliderProps extends ISliderFilter {
  id: string
}

export const SliderWithGtm: React.FC<IGtmSliderProps> = (
  props: IGtmSliderProps
) => {
  const onChangeInternal = (values: number[]): void => {
    const { id, filterName, onChange } = props
    const [min, max] = values

    const displayValue = `${min} | ${max}`
    const value = `${min}|${max}`

    gtmLogSelectedValueChange(id, displayValue, filterName, value, true)

    onChange(values)
  }

  return (
    <GtmWrapper skip>
      <SliderFilter {...props} onChange={onChangeInternal} />
    </GtmWrapper>
  )
}
