import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { gtmLogNewsFiltersChange } from 'helper/gtm'
import { IMappingObject } from 'helper/IMappingObject'
import { defaultUserState, UserState } from 'models/Login/NewsLogin/UserState'
import { NewsCountry } from 'models/News/Homepage/NewsArticleRequest'

import { RootState } from 'redux/store'

interface FiltersState {
  selectedFilters: IMappingObject<string[]>
}

export interface SearchState {
  searchFields: IMappingObject<string[]>
  isExactMatch: boolean
  orderBy: string[]
  currentPage: number
}

interface NewsState {
  userState: UserState
  filters: FiltersState
  search: SearchState
  countries: NewsCountry[]
  selectedArticles: number[]
}

const defaultFiltersState: FiltersState = {
  selectedFilters: {},
}

const defaultSearchState: SearchState = {
  searchFields: {},
  isExactMatch: true,
  orderBy: [],
  currentPage: 1,
}

const initialState: NewsState = {
  userState: defaultUserState,
  filters: defaultFiltersState,
  search: defaultSearchState,
  countries: [],
  selectedArticles: [],
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsUserState: (state, { payload }: PayloadAction<UserState>) => ({
      ...state,
      userState: payload,
    }),
    resetNewsUserState: (state) => ({
      ...state,
      userState: defaultUserState,
    }),
    setNewsCountries: (state, { payload }: PayloadAction<NewsCountry[]>) => ({
      ...state,
      countries: payload,
    }),
    setNewsFilters: (state, { payload }: PayloadAction<FiltersState>) => ({
      ...state,
      filters: payload,
    }),
    resetNewsFiltersToDefaults: (state) => ({
      ...state,
      filters: defaultFiltersState,
    }),
    setNewsSelectedFilter: (
      state,
      { payload }: PayloadAction<IMappingObject<string[]>>
    ) => {
      const selectedFilters = {
        ...state.filters.selectedFilters,
        ...payload,
      }
      gtmLogNewsFiltersChange(selectedFilters)
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedFilters: selectedFilters,
        },
      }
    },
    setNewsSearchState: (state, { payload }: PayloadAction<SearchState>) => ({
      ...state,
      search: payload,
    }),
    setNewsSelectedArticles: (state, { payload }: PayloadAction<number[]>) => ({
      ...state,
      selectedArticles: payload,
    }),
  },
})

export const {
  setNewsUserState,
  resetNewsUserState,
  setNewsCountries,
  setNewsFilters,
  resetNewsFiltersToDefaults,
  setNewsSelectedFilter,
  setNewsSearchState,
  setNewsSelectedArticles,
} = newsSlice.actions

export const getNewsUserState = (state: RootState): UserState =>
  state.news.userState

export const getNewsCountries = (state: RootState): NewsCountry[] =>
  state.news.countries

export const getNewsFilters = (state: RootState): FiltersState =>
  state.news.filters

export const getNewsSelectedFilters = (
  state: RootState
): IMappingObject<string[]> => state.news.filters.selectedFilters

export const getNewsSearchState = (state: RootState): SearchState =>
  state.news.search

export const getNewsSelectedArticles = (state: RootState): number[] =>
  state.news.selectedArticles

export default newsSlice.reducer
