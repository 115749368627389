import styled from 'styled-components'
import Theme from 'theme'

export const StyledSelectedFilters = styled.div`
  article {
    background: linear-gradient(
      to bottom,
      ${Theme.colors.white},
      ${Theme.colors.midGrey}
    );
  position: relative;
`
